import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-mobile-faq',
  templateUrl: './mobile-faq.component.html',
  styleUrls: ['./mobile-faq.component.css']
})
export class MobileFaqComponent implements OnInit {

  paymentSelected = false;
  bookingSelected = false;
  businessSelected = false;
  technicalSelected = false;

  constructor() { }

  ngOnInit() {
    // Automatically click the PAYMENT button on load
    const paymentBtn = document.getElementById('paymentBtn');
    paymentBtn.click();
  }

  collapse() {
    $(document).ready(function () {
      $("button").find("button").on("click", "button", function () {
        $('.text-center.in').collapse('hide');
      });
    });
  }

  paymentToggle() {
    this.paymentSelected = true;
    this.bookingSelected = false;
    this.businessSelected = false;
    this.technicalSelected = false;
  }

  bookingToggle() {
    this.paymentSelected = false;
    this.bookingSelected = true;
    this.businessSelected = false;
    this.technicalSelected = false;
  }

  businessToggle() {
    this.paymentSelected = false;
    this.bookingSelected = false;
    this.businessSelected = true;
    this.technicalSelected = false;
  }

  technicalToggle() {
    this.paymentSelected = false;
    this.bookingSelected = false;
    this.businessSelected = false;
    this.technicalSelected = true;
  }
}
