import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css', '../../css/style.css', '../../css/v-shortcodes.css']
})
export class FooterComponent implements OnInit {

  constructor(private route: Router) { }

  ngOnInit() {
  }

  navigation() {
    this.route.navigateByUrl('/logon');
  }

}
