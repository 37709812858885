import { Component, OnInit } from '@angular/core';

import * as firebase from 'firebase/app';
import { AngularFireDatabase } from 'angularfire2/database';

import { AdminService } from '../../services/admin.service';

declare var $: any;

@Component({
  selector: 'app-quote-admin',
  templateUrl: './quote-admin.component.html',
  styleUrls: ['./quote-admin.component.css', '../admin-portal/admin-portal.component.css']
})
export class QuoteAdminComponent implements OnInit {

  expressQuotation : any = [];
  expressQuotes : any;

  webQuotion : any = [];
  webQuotes : any;

  itemizeQuotation : any = [];
  itemQuotes : any;

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.webQuotes = firebase.database().ref('webBookingQuote');
    this.webQuotes.on('value', (dataSnapshot) => {
      //this.persons = [];

      dataSnapshot.forEach((childSnapshot) => {
        let quotes_data;
        quotes_data = childSnapshot.val();
        //console.log(request_data);
        this.webQuotion.push(quotes_data);
        this.webQuotion.reverse();
      });
      this.webQuotes.off();
      this.webQuotion.reverse();
    });
    this.expressQuote();
    this.itemsQuote();

  }

  public itemsQuote() {
    this.itemQuotes = firebase.database().ref('Itemized-Quotes');
    this.itemQuotes.on('value', (dataSnapshot) => {
      //this.persons = [];

      dataSnapshot.forEach((childSnapshot) => {
        let quotes_data;
        quotes_data = childSnapshot.val();
        this.itemizeQuotation.push(quotes_data);
      });
      this.itemQuotes.off();
    });
  }

  public expressQuote() {
    this.expressQuotes = firebase.database().ref('Express_Quotes');
    this.expressQuotes.on('value', (dataSnapshot) => {
      //this.persons = [];

      dataSnapshot.forEach((childSnapshot) => {
        let quotes_data;
        quotes_data = childSnapshot.val();
        this.expressQuotation.push(quotes_data);
      });
      this.expressQuotes.off();
    });
  }

  public removeQuotes() {
    firebase.database().ref('webBookingQuote').remove();
  }
  public showTabByRole(role):boolean{
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let result=false;
    userInfo.roles.map(r=>{
      if(role===r.code){
        result=true;
      }
    })
    return result;
  }

}
