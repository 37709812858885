import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterbyusername'
})
export class FilterbyusernamePipe implements PipeTransform {
  /**
   *
   * @param items | The Array to lood from
   * @param field | The searching Index in the items[]
   * @param value | The matching response from the Array
   */
  transform(items: any[], field: string, value: string): any[] {
    if (!items) { return []; }

    if (!field || !value) { return items; }

    return items.filter(singleItem => {
      // Check if accountDTO of singleItem exists
      if (singleItem.accountDTO) {
        // Check if owner with provided field exists
        if (singleItem.accountDTO.owner[field]) {
          return singleItem.accountDTO.owner[field].toLowerCase().includes(value.toLowerCase());
        }
      } else {
        // This is a dummy return
          return 'No item found';
      }
    });
  }
}
