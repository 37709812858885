import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Response } from '@angular/http'
import { Router } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { HaversineService, GeoCoord } from 'ng2-haversine';
import { BookingService } from '../../services/booking.service';
import { ViewEncapsulation } from '@angular/core';
declare var $: any;
import * as firebase from 'firebase/app';
import { LoaderService } from 'src/app/services/loader.service';


@Component({
  selector: 'app-add-bucket-booking',
  templateUrl: './add-bucket-booking.component.html',
  styleUrls: ['./add-bucket-booking.component.css']
})
export class AddBucketBookingComponent implements OnInit {

  public userSettings2: any = {
    showRecentSearch: false,
    geoCountryRestriction: ['ZA'],
    inputPlaceholderText: 'Enter pick-up address.',
    geoTypes: ['address', 'establishment']
  };

  public userSettings: any = {
    showRecentSearch: false,
    geoCountryRestriction: ['ZA'],
    inputPlaceholderText: 'Enter drop-Off address.',
    geoTypes: ['address', 'establishment']
  };

  //location input
  pickUpNotMsg: string;
  pickUpAddress: string;
  dropOffAddress: string;
  dropOffNotMsg: string = '';
  dropOffNotFound: boolean;
  pickUpNotFound: boolean;
  pickUpLat: any;
  pickUpLong: any;

  pickUpCoordinate = [];
  dropOffCoordinate = [];

  pickUpCoordinateLocation: any = {};
  dropOffCoordinateLocation: any = {};

  dropOffLat: number;
  dropOffLong: number;

  vehicleType: any;

  userLat: number;
  userLong: number;

  location: any = {};
  dir = undefined;
  priceBox = undefined;

  distance: number;
  loader: boolean = false;

  totalExpressPrice: number;
  distanceOfTrip: number;
  tempAmount: number;

  vehicleTypeData: any;
  vehicleOid: string;
  errorMessage: string;
  vehicleTypeRate: any = [];

  addrressData: any;
  pickUpChecked: any;

  contact: Contact = {
    name: '',
    surname: '',
    mobile: '',
    email: '',
    labours: 0
  };

  dropContact: Contact = {
    name: '',
    surname: '',
    mobile: '',
    email: '',
    labours: 0
  };

  disablePickUp: boolean = false;
  dropOffChecked: boolean = false;
  datePipe: DatePipe;

  bookingTime: any;
  bookingDate: any;
  comment: string;
  dropOffFloors: number = 0;
  floorsPickUp: number = 0;
  labour: number = 0;
  hour: number;

  price: number = 0.0;
  availableQuotes: boolean;

  promoStatus: string;
  terms: boolean;
  termsControl: boolean = false;
  today = new Date();
  yesterday = new Date(this.today);
  promoCode: string;
  promoCodeAmnt: number = 0.0;
  msg: string;
  retail: any;
  bucketBooking: any = {};
  load: number = 1;
  canopy: boolean = false;
  err: any;
  bookingPrice: any;


  public min = new Date(this.yesterday.setDate(this.today.getDate() - 1));
  public max = new Date(2020, 3, 21, 20, 30);

  reloading: boolean = false;
  constructor(private router: Router, private sharedService: SharedService, private _haversineService: HaversineService,
    private bookService: BookingService,private loadservice:LoaderService) { }

  ngOnInit() {
    this.retail = JSON.parse(localStorage.getItem('retail'));
    //console.log(this.retail);
    this.reloadCss();
    this.vehicleTypeRate = this.retail.typeRates;



    this.bucketBooking = JSON.parse(localStorage.getItem('selectedRetailBucketBooking'));


    this.vehicleType = this.bucketBooking.vehicleType;
    this.pickUpAddress = this.bucketBooking.bookings[0].pickUpAddress;

    //this.bucketBooking.bookings[0].pickUpCoordinate.coordinates[0]
    this.pickUpCoordinate = [this.bucketBooking.bookings[0].pickUpCoordinate.coordinates[0],
    this.bucketBooking.bookings[0].pickUpCoordinate.coordinates[1]];
    this.pickUpCoordinateLocation = {
      "coordinates": this.pickUpCoordinate,
      "address": this.pickUpAddress
    };
    console.log(this.bucketBooking);
  }

  public reloadCss() {
    //this.loadservice.a();
    var links = document.getElementsByTagName("link");
    for (var cl in links) {
      var link = links[cl];
      if (link.rel === "stylesheet")
        link.href += "";
    }
    //this.loadservice.a();
  }

  autoCompleteCallback1(selectedData: any) {
    //do any necessery stuff.

    if (!selectedData) {
      this.pickUpNotMsg = 'Location not found, Please enter nearest location.';
      this.pickUpNotFound = false;
    } else {
      this.pickUpAddress = selectedData.data.description;
      this.pickUpLat = selectedData.data.geometry.location.lat;
      this.pickUpLong = selectedData.data.geometry.location.lng;
      //this.bucketBooking.bookings[0].pickUpCoordinate.coordinates[0]
      this.pickUpCoordinate = [selectedData.data.geometry.location.lat, selectedData.data.geometry.location.lng];
      this.pickUpCoordinateLocation = {
        "coordinates": this.pickUpCoordinate,
        "address": this.pickUpAddress
      };

      //console.log(this.pickUpCoordinateLocation);
    }

  }

  autoCompleteCallback2(selectedData: any) {
    // do any necessery stuff.
    //console.log(selectedData);
    if (!selectedData) {
      this.dropOffNotMsg = 'Location not found, Please enter nearest location.';
      this.dropOffNotFound = false;
    } else {
      this.dropOffAddress = selectedData.data.description;
      this.dropOffLat = selectedData.data.geometry.location.lat;
      this.dropOffLong = selectedData.data.geometry.location.lng;
      this.dropOffCoordinate = [selectedData.data.geometry.location.lat, selectedData.data.geometry.location.lng];
      this.dropOffCoordinateLocation = {
        "coordinates": this.dropOffCoordinate,
        "address": this.dropOffAddress
      };
      //console.log(this.dropOffCoordinateLocation);
    }

  }

  onSelectChange(vehicleType) {
    //console.log(this.vehicleType);

  }

  public checkedPickup(pickUp: any) {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    if (pickUp) {
      this.disablePickUp = true;
      this.contact.name = object.owner.firstName;
      this.contact.email = object.owner.email;
      this.contact.mobile = object.owner.mobile;
      this.contact.surname = object.owner.surname;
      //this.contact.

    } else {
      this.disablePickUp = false;
      this.contact.name = '';
      this.contact.email = '';
      this.contact.mobile = '';
      this.contact.surname = '';
    }
  }

  /*open() {
    this.datePickerDirective.api.open();
  }*/
  public checkedDropUp(dropOff: any) {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    if (dropOff) {
      this.dropOffChecked = true;
      this.dropContact.name = object.owner.firstName;
      this.dropContact.email = object.owner.email;
      this.dropContact.mobile = object.owner.mobile;
      this.dropContact.surname = object.owner.surname;
    } else {
      this.dropOffChecked = false;
      this.dropContact.name = '';
      this.dropContact.email = '';
      this.dropContact.mobile = '';
      this.dropContact.surname = '';
    }
  }

  makePayment() {

    var datePipe = new DatePipe("en-US");
    let value = datePipe.transform(this.bookingTime, 'HH:mm a');

  }

  //geting quotation
  getDistance(): number {


    let pickCordinate: GeoCoord = {
      latitude: this.bucketBooking.bookings[0].pickUpCoordinate.coordinates[0],
      longitude: this.bucketBooking.bookings[0].pickUpCoordinate.coordinates[1]
    };

    console.log(pickCordinate);
    let dropCordinate: GeoCoord = {
      latitude: this.dropOffLat,
      longitude: this.dropOffLong
    };

    let kilometers = this._haversineService.getDistanceInKilometers(pickCordinate, dropCordinate);

    return kilometers;
  }

  public getQuote() {
    if (this.pickUpAddress != null && this.dropOffAddress != null) {
      if (this.vehicleType != null) {
        this.distance = Math.round(this.getDistance());
        localStorage.setItem('distance', this.distance + "");
        var object = JSON.parse(localStorage.getItem('userInfo'));
        this.bookService.getRetailVehicleRate(this.retail.oid, this.vehicleType)
          .subscribe(
            (res: Response) => {
              // console.log({bookingData});
              var bookingData = {
                "distance": this.distance,
                "retailOid": this.retail.oid,
                "totalPrice": 0,
                "vehicleType": this.vehicleType
              };
              // console.log({bookingData});
              this.bookService.getRetailbookingPrice(bookingData, object.token)
              .subscribe(
                (response: Response) => {
                  this.bookingPrice = response.json();
                //  console.log(this.bookingPrice)
                 this.sharedService.setTotalAmount(this.bookingPrice.totalPrice);
                 this.tempAmount = this.bookingPrice.totalPrice;//this.totalExpressPrice;
                 this.price = this.bookingPrice.totalPrice;//this.totalExpressPrice;
                 this.availableQuotes = true;
                 this.priceBox = true;
                 this.dir = {
                   origin: { lat: this.pickUpCoordinate[0], lng: this.pickUpCoordinate[1] },
                   destination: { lat: this.dropOffCoordinate[0], lng: this.dropOffCoordinate[1] }
                 };
                 var quotes = firebase.database().ref('webBookingQuote');
                 var today = new Date();
                 var dd = today.getDate();
                 var mm = today.getMonth() + 1;
                 let mon;
                 let day_;
                 var yyyy = today.getFullYear();
                 if (dd < 10) {
                   day_ = '0' + dd;
                 } else {
                   day_ = dd;
                 }
                 if (mm < 10) {
                   mon = '0' + mm;
                 } else {
                   mon = mm;
                 }
                 var todayD = day_ + '/' + mon + '/' + yyyy;
                 var timestamp = new Date().valueOf();
                 this.loadservice.removeloader();
                 window.scrollTo(0, 0)
                },
                (err) => {
                  console.log(err)
                }
              );
            },
            (err) => {
              this.msg = err.json().message;
              $('#results').modal('show');
            }
          )
      } else {
        this.msg = "Please select the vehicle type.";
        $('#results').modal('show');
      }
    } else {
      this.errorMessage = "Please enter all addresses.";
      this.msg = "Please enter all addresses.";
      $('#results').modal('show');
    }
  }

  public openPopup() {
    var options = "location=no,clearcache=no,toolbar=no";
    var paymentWindow = window.open("https://www.droppa.co.za/droppa/termsAndCondition", '_blank', options);
  }

  enterPromoCode() {
    $('#promoCodeModal').modal('show');
  }

  /**
 * promo code ng-on-change listener
 */
  usedTotal: number;
  public promo(promoValue) {

    if (promoValue != null) {
      if (promoValue.length == 6) {
        var object = JSON.parse(localStorage.getItem('userInfo'));
        // console.log(object);
        var promo = firebase.database().ref('retail/' + object.owner.retailId + '/promoCode/' + promoValue.toUpperCase());
        //console.log(promo);
        this.promoStatus = "Searching, please wait.";
        promo.on('value', (datasnapshot) => {
          // console.log(datasnapshot);
          if (datasnapshot.val()) {
            // console.log(datasnapshot.val().price + " " + datasnapshot.val().used);
            this.usedTotal = datasnapshot.val().used;
            if (datasnapshot.val().status == "Active") {
              this.tempAmount = ((this.addrressData.price + ((this.floorsPickUp * 20) +
                (this.dropOffFloors * 20)) + (this.contact.labours * 75)) * this.load) - datasnapshot.val().price;
              this.promoCodeAmnt = datasnapshot.val().price;
              this.promoStatus = "Discount of R" + datasnapshot.val().price + " granted.";
              promo.off();

              var promoUpdate = firebase.database().ref('retail/' + object.owner.retailId + '/promoCode/' + promoValue.toUpperCase());
              promoUpdate.update({ used: this.usedTotal + 1 });
              promoUpdate.off();
            } else {
              this.promoStatus = "Promo code is deactivated.";
            }

          } else {
            this.promoStatus = "Invalid Code, no match found.";
          }

        });


        promo

      } else {
        this.promoStatus = "";
      }
    }

  }

  public goToEntry() {
    this.priceBox = false;
  }

  decrementLabour(labour) {
    if (this.availableQuotes) {
      if (labour > 0) {
        this.contact.labours -= 1;
        this.onLabour(this.contact.labours);
      } else {
        this.contact.labours = 0;
        this.onLabour(this.contact.labours);
      }
    } else {
      this.msg = "Please get the quotes first.";
      $('#results').modal('show');
    }

  }

  incrementLabour(labour) {
    if (this.availableQuotes) {
      if (labour >= 0) {
        this.contact.labours += 1;
        this.onLabour(this.contact.labours);
      } else {
        this.contact.labours = 0;
        this.onLabour(this.contact.labours);
      }
    } else {
      this.msg = "Please get the quotes first.";
      $('#results').modal('show');
    }

  }

  incrementPickFloor(floorsPickUp) {
    if (this.availableQuotes) {
      if (this.floorsPickUp >= 0) {
        this.floorsPickUp += 1;
        this.onPickFloor(this.floorsPickUp);
      } else {
        this.floorsPickUp = 0;
        this.onPickFloor(this.floorsPickUp);
      }
    } else {
      this.msg = "Please get the quotes first.";
      $('#results').modal('show');
    }
  }

  decrementPickFloor(floorsPickUp) {
    if (this.availableQuotes) {
      if (this.floorsPickUp > 0) {
        this.floorsPickUp -= 1;
        this.onPickFloor(this.floorsPickUp);
      } else {
        this.floorsPickUp = 0;
        this.onPickFloor(this.floorsPickUp);
      }
    } else {
      this.msg = "Please get the quotes first.";
      $('#results').modal('show');
    }

  }

  decrementDropFloor(dropOffFloors) {
    if (this.availableQuotes) {
      if (this.dropOffFloors > 0) {
        this.dropOffFloors -= 1;
        this.onDropFloor(this.dropOffFloors);
      } else {
        this.dropOffFloors = 0;
        this.onDropFloor(this.dropOffFloors);
      }
    } else {
      this.msg = "Please get the quotes first.";
      $('#results').modal('show');
    }
  }

  incrementDropFloor(dropOffFloors) {
    if (this.availableQuotes) {
      if (this.dropOffFloors >= 0) {
        this.dropOffFloors += 1;
        this.onDropFloor(this.dropOffFloors);
      } else {
        this.dropOffFloors = 0;
        this.onDropFloor(this.dropOffFloors);
      }
    } else {
      this.msg = "Please get the quotes first.";
      $('#results').modal('show');
    }
  }

  public onPickFloor(floor: number) {

    if (floor != null) {
      if (floor >= 0) {
        this.tempAmount = ((this.addrressData.price + ((this.floorsPickUp * 20) +
          (this.dropOffFloors * 20)) + (this.contact.labours * 75)) * this.load) - this.promoCodeAmnt;
      }

    } else {
      this.tempAmount = ((this.addrressData.price + ((this.floorsPickUp * 20) +
        (this.dropOffFloors * 20)) + (this.contact.labours * 75)) * this.load) - this.promoCodeAmnt;
    }
  }

  public onDropFloor(floorNo: number) {
    //console.log(floorNo);
    if (floorNo != null) {
      if (floorNo >= 0) {
        this.tempAmount = ((this.addrressData.price + ((this.floorsPickUp * 20) +
          (this.dropOffFloors * 20)) + (this.contact.labours * 75)) * this.load) - this.promoCodeAmnt;
      }
    } else {
      this.tempAmount = ((this.addrressData.price + ((this.floorsPickUp * 20) +
        (this.dropOffFloors * 20)) + (this.contact.labours * 75)) * this.load) - this.promoCodeAmnt;

    }
  }

  public onLabour(labour: number) {

    if (labour != null) {
      if (labour >= 0) {
        this.tempAmount = ((this.addrressData.price + ((this.floorsPickUp * 20) +
          (this.dropOffFloors * 20)) + (this.contact.labours * 75)) * this.load) - this.promoCodeAmnt;
      }

    } else {
      this.tempAmount = ((this.addrressData.price + ((this.floorsPickUp * 20) +
        (this.dropOffFloors * 20)) + (this.contact.labours * 75)) * this.load) - this.promoCodeAmnt;
    }
  }

  public createDrop() {

    var object = JSON.parse(localStorage.getItem('userInfo'));
    var token = object.token;//localStorage.getItem('token');

    var dateValue = this.bookingDate;

    var datePipe = new DatePipe("en-US");
    let value = datePipe.transform(this.bucketBooking.date, 'yyyy-MM-dd');
    var timestamp = new Date().valueOf();

    var customer = {
      "oid": object.owner.retailId
    }

    var bookingData = {
      "dropOff": {
        "firstName": this.dropContact.name,
        "lastName": this.dropContact.surname != null ? this.dropContact.surname : object.owner.surname,
        "phone": this.dropContact.mobile,
        "email": object.owner.email
      },
      "pickUp": {
        "firstName": this.contact.name,
        "lastName": this.contact.surname != null ? this.contact.surname : object.owner.surname,
        "phone": this.contact.mobile,
        "email": object.owner.email
      },

      "customer": object.owner,
      "price": this.tempAmount,
      "vehicleType": this.vehicleType,
      "pickUpAddress": this.pickUpAddress,
      "pickUpTime": this.bucketBooking.time,
      "phoneNo": object.owner.mobile,
      "pickUpDate": this.bucketBooking.bookings[0].pickUpDate,
      "dropOffAddress": this.dropOffAddress,
      "comment": this.comment,
      "labour": this.contact.labours,
      "timestamp": timestamp,
      "status": "BUCKET",
      "dropFloors": this.dropOffFloors,
      "pickUpFloors": this.floorsPickUp,
      "pickUpCoordinate": this.pickUpCoordinateLocation,
      "dropOffCoordinate": this.dropOffCoordinateLocation,
      "load": this.load == 0 ? 1 : this.load,
      "canopy": this.canopy,
      "dropOid": null,
      "distance" : this.distanceOfTrip
    };
    localStorage.setItem('bookingDataObject', JSON.stringify(bookingData));
    var obect = {
      "pickUpAddress": this.pickUpCoordinateLocation,
      "dropOffAddress": this.dropOffCoordinateLocation,
      "price": this.tempAmount + this.promoCodeAmnt,
      "vehicleType": this.vehicleType
    };
    localStorage.setItem('addressData', JSON.stringify(obect));

    var addbookingObj = {
      "bucketBookingOid": this.bucketBooking.oid,
      "booking": bookingData
    };

    if (this.availableQuotes) {
      this.loadservice.addloader();
      this.bookService.addToBucketsBook(addbookingObj, token)
        .subscribe(
          (res: Response) => {
            this.loadservice.removeloader();
            localStorage.setItem('selectedRetailBucketBooking', JSON.stringify(res));
            this.msg = 'Booking successful Added to the Bucket!';
            $('#Bookingresults').modal('show');

          },
          (error: Response) => {
            this.loadservice.removeloader();
            this.err = error;
            this.msg = this.err.error.message != null ? this.err.error.message : 'Error occured while making a booking, Please try again.';
            $('#results').modal('show');
          }
        );
    } else {
      this.msg = "Please get the quotes first.";
      $('#results').modal('show');
    }

  }

  decrementLoads() {
    if (this.availableQuotes) {
      if (this.load > 0) {
        this.load -= 1;
        this.onLoads(this.load);
      } else {
        this.load = 0;
        this.onLoads(this.load);
      }
    } else {
      this.msg = "Please get the quotes first.";
      $('#results').modal('show');
    }
  }

  incrementLoads() {
    if (this.availableQuotes) {
      if (this.load >= 0) {
        this.load += 1;
        this.onLoads(this.load);
      } else {
        this.load = 0;
        this.onLoads(this.load);
      }
    } else {
      this.msg = "Please get the quotes first.";
      $('#results').modal('show');
    }
  }

  public onLoads(loadNo: number) {
    // console.log(loadNo);
    if (loadNo != null) {
      if (loadNo > 0) {
        //this.tempAmount = this.tempAmount * loadNo;
        this.tempAmount = this.addrressData.price * loadNo;
      }
    } else {
      //this.tempAmount = this.tempAmount * loadNo;
      this.tempAmount = this.addrressData.price * loadNo;
    }
  }

  public checkedTerms(terms) {
    //console.log(terms);
    if (terms) {
      this.termsControl = true;
    } else {
      this.termsControl = false;
    }
  }

  public checkedCanopy(canopy: any) {
    if (canopy) {
      this.canopy = true;
      // console.log(canopy);
    } else {
      this.canopy = false;
      // console.log(canopy);
    }
  }

  public goUserHome() {
    this.router.navigate(['/bucket-booking-review']);
  }

}
interface Contact {
  name: string;
  surname: string;
  mobile: string;
  email: string;
  labours: number;
}
