import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BookingService } from 'src/app/services/booking.service';
import { LoaderService } from 'src/app/services/loader.service';
declare var $: any;

@Component({
  selector: 'app-elite-thank-you-page',
  templateUrl: './elite-thank-you-page.component.html',
  styleUrls: ['./elite-thank-you-page.component.css']
})
export class EliteThankYouPageComponent implements OnInit {

  rentalOid: any;
  addrressData: any;
  invoiceOption: boolean;
  invoiceControl: boolean = false;
  companyNameMsg: string = '';
  companyAddressMsg: string = '';
  vatNoMsg: string = '';
  companyName: any;
  vatNo: number;
  companyAddress: any;
  loader: boolean = false;
  trackNo: any;
  userObj: any;
  addressData = {
    from: '',
    branch: ''
  }

  constructor(private bookService: BookingService, private route: ActivatedRoute, private router: Router,private loadservice:LoaderService) {
    this.route.params.subscribe(params => {
      this.rentalOid = params['rentalOid'];
    });
  }

  ngOnInit() {
    this.userObj = JSON.parse(localStorage.getItem('userInfo'));

    this.bookService.getRentalByOid(this.userObj.token, this.rentalOid).subscribe(
      response => {

        this.addrressData = response;
      }, error => {
        console.log(error);
        console.log('Error block');
      }, () => {
      }
    )
  }

  public attachInvoiceOrNot(invoiceOption) {
    if (invoiceOption) {
      this.invoiceControl = true;
    }
    else {
      this.invoiceControl = false;
    }
  }

  public onHome() {
    this.router.navigateByUrl('/user');
  }

  public modelCompanyName(companyName) {
    if (this.invoiceControl == true) {
      if (companyName == null || companyName == '') {
        this.companyNameMsg = 'Please enter the company name.';
      }
      else {
        this.companyNameMsg = '';
      }
    }
  }

  public modelVATNo(vatNo) {
    if (this.invoiceControl == true) {
      if (vatNo == null || vatNo == '') {
        this.vatNoMsg = 'Please enter the VAT No.';
      }
      else {
        this.vatNoMsg = '';
      }
    }
  }

  public sendOptionalInvoice() {
    var token = localStorage.getItem('token');
    if (this.invoiceControl) {
      if (this.companyName == null || this.companyName == '') {
        this.companyNameMsg = 'Please enter the company name.';
      }
      else if (this.vatNo == null || this.vatNo == undefined || this.vatNo.toString() == "") {
        this.vatNoMsg = 'Please enter the VAT No.';
      }
      else {
        var invoiceObj = {
          "companyName": this.companyName,
          "rentalOid": this.addrressData.oid,
          "vatNo": this.vatNo,
        }
        this.loadservice.addloader();
        this.bookService.eliteInvoice(token, invoiceObj).subscribe(
          response => {
            this.loadservice.removeloader();
          }, (error) => {
            console.log(error);
            this.loadservice.removeloader();
          }, () => {
            $('#optionalInvoice').modal('show');
          }
        )
      }
    }
    else {
      $('#optionalInvoice').modal('show');
    }
  }

}
