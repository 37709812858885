import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { from } from 'rxjs';
import { NgForm } from '@angular/forms';
import { AdminService } from '../../services/admin.service';
import { DatePipe } from '@angular/common';
import { formatDate } from "@angular/common";
import { LoaderService } from 'src/app/services/loader.service';



@Component({
  selector: 'app-droppa-stats',
  templateUrl: './droppa-stats.component.html',
  styleUrls: ['./droppa-stats.component.css', '../admin-portal/admin-portal.component.css']
})
export class DroppaStatsComponent implements OnInit {

  totalBookings: any;
  totalBookingAmt: any;

  totalCountIOS: any;
  totalCountAndroid: any;
  totalCountWeb: any;

  totalIOSAmt: any;
  totalAndroidAmt: any;
  totalWebAmt: any;

  totalCountGauteng: any;
  totalCountWesternCape: any;
  totalCountKZN: any;

  totalAmountGauteng: any;
  totalAmountWestenCape: any;
  totalAmountKZN: any;

  totalCashCount: any;
  totalOnlineCount: any;
  totalRetailCount: any;

  totalCashBookings: any;
  totalOnlineBookings: any;
  totalRetailBookings: any;

  totalCashAmt: any;
  totalOnlineAmt: any;
  totalRetailAmt: any;

  completedBookings: any;
  intransitBookings: any;
  awaitingBookings: any;

  loader: boolean;
  adminUser: any;
  users: any = [];

  startDate: any;
  endDate: any;
  datePipe = new DatePipe('en-US');
  metricks: any = [];
  filterQuery: any;

  allBookings: any;
  bookingData: any;
  bookings: any = [];

  // FLASH ROAD
  dailyFlashRoad
  dailyFlashRoadCount
  monthlyFlashRoad
  monthlyFlashRoadCount
  totalFlashRoad
  weeklyFlashRoad
  weeklyFlashRoadCount

  // FLASH FLIGHT
  dailyFlashFlight
  dailyFlashFlightCount
  monthlyFlashFlight
  monthlyFlashFlightCount
  totalFlashFlight
  weeklyFlashFlight
  weeklyFlashFlightCount

  // DASH
  dailyDash: any;
  dailyDashCount: any;
  monthlyDash
  monthlyDashCount
  totalDash
  weeklyDash
  weeklyDashCount

  // BUDGET
  dailyFlashRoadBdgtCourier
  dailyFlashRoadBdgtCourierCount
  monthlyFlashRoadBdgtCourier
  monthlyFlashRoadBdgtCourierCount
  totalFlashRoadBdgtCourier
  weeklyFlashRoadBdgtCourier
  weeklyFlashRoadBdgtCourierCount

  // COURIER
  dailyFlashRoadExpCourier
  dailyFlashRoadExpCourierCount
  monthlyFlashRoadExpCourier
  monthlyFlashRoadExpCourierCount
  totalFlashRoadExpCourier
  weeklyFlashRoadExpCourier
  weeklyFlashRoadExpCourierCount

  constructor(private adminService: AdminService, private router: Router,private loadservice:LoaderService) { }

  ngOnInit() {
    window.scroll(0, 0);
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    this.users = JSON.parse(localStorage.getItem('allUsers'));
    // this.getTotalBookings(this.adminUser.token);
    this.getBookings();
    // this.tempBookings();
  }

  public getBookings() {
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    this.loadservice.addloader();
    this.adminService.getAllBookings(this.adminUser.token)
      .subscribe(
        (res) => {
          this.loadservice.removeloader();
          this.bookings = res.reverse();
          // console.log(this.bookings)
        },
        (err) => {
          this.loadservice.removeloader();
        }
      );
  }

  public getTotalBookings(token) {
    this.adminService.getTotalAllBookings(token)
      .subscribe(
        (res) => {
          this.metricks = res;
          // console.log({ res });
          // console.log(this.metricks);
          // localStorage.setItem('bookingMetrix', JSON.stringify(res));

          //DASH
          this.dailyDash = res.bookingMatrixDTO.dailyDash;
          this.monthlyDash = res.bookingMatrixDTO.monthlyDash;
          this.totalDash = res.bookingMatrixDTO.totalDash;
          this.weeklyDash = res.bookingMatrixDTO.weeklyDash;
          this.dailyDashCount = res.bookingMatrixDTO.dailyDashCount;
          this.monthlyDashCount = res.bookingMatrixDTO.monthlyDashCount;
          this.weeklyDashCount = res.bookingMatrixDTO.weeklyDashCount;
          // console.log('DASH ' + this.dailyDash + ' ' + this.monthlyDash + ' ' + this.totalDash + ' ' + this.weeklyDash);
          //console.log('DASH ' + this.dailyDashCount + this.monthlyDashCount + this.weeklyDashCount);

          // ROAD
          this.dailyFlashRoad = res.bookingMatrixDTO.dailyFlashRoad;
          this.monthlyFlashRoad = res.bookingMatrixDTO.monthlyFlashRoad;
          this.totalFlashRoad = res.bookingMatrixDTO.totalFlashRoad;
          this.weeklyFlashRoad = res.bookingMatrixDTO.weeklyFlashRoad;
          this.dailyFlashRoadCount = res.bookingMatrixDTO.dailyFlashRoadCount;
          this.monthlyFlashRoadCount = res.bookingMatrixDTO.monthlyFlashRoadCount;
          this.weeklyFlashRoadCount = res.bookingMatrixDTO.weeklyFlashRoadCount;
          // console.log('ROAD ' + this.dailyFlashRoad + ' ' + this.monthlyFlashRoad + ' ' + this.totalFlashRoad + ' ' + this.weeklyFlashRoad);
          //console.log('ROAD ' + this.dailyFlashRoadCount + this.monthlyFlashRoadCount + this.weeklyFlashRoadCount);

          // FLIGHT
          this.dailyFlashFlight = res.bookingMatrixDTO.dailyFlashFlight;
          this.monthlyFlashFlight = res.bookingMatrixDTO.monthlyFlashFlight;
          this.totalFlashFlight = res.bookingMatrixDTO.totalFlashFlight;
          this.weeklyFlashFlight = res.bookingMatrixDTO.weeklyFlashFlight;
          this.dailyFlashFlightCount = res.bookingMatrixDTO.dailyFlashFlightCount;
          this.monthlyFlashFlightCount = res.bookingMatrixDTO.monthlyFlashFlightCount;
          this.weeklyFlashFlightCount = res.bookingMatrixDTO.weeklyFlashFlightCount;
          // console.log('FLIGHT ' + this.dailyFlashFlight + ' ' + this.monthlyFlashFlight + ' ' + this.totalFlashFlight + ' ' + this.weeklyFlashFlight);
          //console.log('FLIGHT ' + this.dailyFlashFlightCount + this.monthlyFlashFlightCount + this.weeklyFlashFlightCount);

          // BUDGET
          this.dailyFlashRoadBdgtCourier = res.bookingMatrixDTO.dailyFlashRoadBdgtCourier;
          this.monthlyFlashRoadBdgtCourier = res.bookingMatrixDTO.monthlyFlashRoadBdgtCourier;
          this.totalFlashRoadBdgtCourier = res.bookingMatrixDTO.totalFlashRoadBdgtCourier;
          this.weeklyFlashRoadBdgtCourier = res.bookingMatrixDTO.weeklyFlashRoadBdgtCourier;
          this.dailyFlashRoadBdgtCourierCount = res.bookingMatrixDTO.dailyFlashRoadBdgtCourierCount;
          this.monthlyFlashRoadBdgtCourierCount = res.bookingMatrixDTO.monthlyFlashRoadBdgtCourierCount;
          this.weeklyFlashRoadBdgtCourierCount = res.bookingMatrixDTO.weeklyFlashRoadBdgtCourierCount;
          // console.log('BUDGET ' + this.dailyFlashRoadBdgtCourier + ' ' + this.monthlyFlashRoadBdgtCourier + ' ' + this.totalFlashRoadBdgtCourier + ' ' + this.weeklyFlashRoadBdgtCourier);
          //console.log('BUDGET ' + this.dailyFlashRoadBdgtCourierCount + this.monthlyFlashRoadBdgtCourierCount + this.weeklyFlashRoadBdgtCourierCount);

          // COURIER
          this.dailyFlashRoadExpCourier = res.bookingMatrixDTO.dailyFlashRoadExpCourier;
          this.monthlyFlashRoadExpCourier = res.bookingMatrixDTO.monthlyFlashRoadExpCourier;
          this.totalFlashRoadExpCourier = res.bookingMatrixDTO.totalFlashRoadExpCourier;
          this.weeklyFlashRoadExpCourier = res.bookingMatrixDTO.weeklyFlashRoadExpCourier;
          this.dailyFlashRoadExpCourierCount = res.bookingMatrixDTO.dailyFlashRoadExpCourierCount;
          this.monthlyFlashRoadExpCourierCount = res.bookingMatrixDTO.monthlyFlashRoadExpCourierCount;
          this.weeklyFlashRoadExpCourierCount = res.bookingMatrixDTO.weeklyFlashRoadExpCourierCount;
          // console.log('COURIER ' + this.dailyFlashRoadExpCourier + ' ' + this.monthlyFlashRoadExpCourier + ' ' + this.totalFlashRoadExpCourier + ' ' + this.weeklyFlashRoadExpCourier);
          //console.log('COURIER ' + this.dailyFlashRoadExpCourierCount + this.monthlyFlashRoadExpCourierCount + this.weeklyFlashRoadExpCourierCount);

          this.totalBookings = res.bookingMatrixDTO.count;
          this.totalBookingAmt = res.bookingMatrixDTO.total;

          this.totalCountIOS = res.bookingMatrixDTO.totalIosBookings;
          this.totalCountAndroid = res.bookingMatrixDTO.totalAndroidBookings;
          this.totalCountWeb = res.bookingMatrixDTO.totalWeb;

          this.totalIOSAmt = res.bookingMatrixDTO.totalIosAmt;
          this.totalAndroidAmt = res.bookingMatrixDTO.totalAndroidAmt;
          this.totalWebAmt = res.bookingMatrixDTO.totalWebAmt;

          this.totalCountGauteng = res.bookingMatrixDTO.totalGauteng
          this.totalCountWesternCape = res.bookingMatrixDTO.totalWesternCape
          this.totalCountKZN = res.bookingMatrixDTO.totalKzn;

          this.totalAmountGauteng = res.bookingMatrixDTO.totalAntGauteng;
          this.totalAmountWestenCape = res.bookingMatrixDTO.totalAntWesternCape;
          this.totalAmountKZN = res.bookingMatrixDTO.totalAntKzn;

          this.totalCashCount = res.bookingMatrixDTO.cashBookingCount;
          this.totalOnlineCount = res.bookingMatrixDTO.onlineBookingsCount;
          this.totalRetailCount = res.bookingMatrixDTO.bucketCount;

          this.totalCashAmt = res.bookingMatrixDTO.totalCash;
          this.totalOnlineAmt = res.bookingMatrixDTO.totalOnline;
          this.totalRetailAmt = res.bookingMatrixDTO.totalRetail;

          this.completedBookings = res.bookingMatrixDTO.completedBookings;
          this.intransitBookings = res.bookingMatrixDTO.inTransitBookings;
          this.awaitingBookings = res.bookingMatrixDTO.awaitingDriverBookings;


          //this.filterMethod();
        },
        (err) => {
          this.loadservice.removeloader();
          if (err.statusText == 'Unknown Error' || err.statusText == 'Internal Server Error') {
            this.router.navigate(['/logon']);
          }
        }
      );

  }

  filterMethodTest() {

    const format = 'yyyy-MM-dd';
    const locale = 'en-US';
    const formattedDate = formatDate(this.startDate, format, locale);
    const startDate = formatDate(this.startDate, format, locale);
    const endDate = formatDate(this.endDate, format, locale);
    let total = 0;
    let filteredBooking = [];

    const selectedMembers = this.bookings.filter((m: any) => {
      return m.pickUpDate >= startDate && m.pickUpDate <= endDate;
    });

    // this.totalBookingAmt += m.price;
    //   // return m.pickUpDate >= startDate && m.pickUpDate <= endDate;

    //   if ((m.pickUpDate >= startDate) && (m.pickUpDate <= endDate)) {
    //     filteredBooking.push(m);
    //     total += m.price;
    //   }
    // console.log({ 'Total Amount 01': this.totalBookingAmt, 'Total Amount 02': total });

    this.getTotalForPlatformsFromFilter(selectedMembers);
    this.getTotalForPaymentTypeFilter(selectedMembers);
    this.getTotalForStatusFilter(selectedMembers);
    this.getTotalForProvinceTypeFilter(selectedMembers);
    this.getCourierForStatusFiler(selectedMembers);
    this.displaytotalFilteredAmount(selectedMembers);
  }

  /**
   *
   */
  public displaytotalFilteredAmount(filtered) {
    let totalPrice = 0.00;

    filtered.filter((_results: any) => {
      totalPrice += _results.price;
    });

    this.totalBookingAmt = totalPrice;
  }

  getTotalForPlatformsFromFilter(filteredObject) {

    //console.log(filteredObject.price);
    let totalIos = [];
    let totalWeb = [];
    let totalAndroid = [];

    let totalIosAmt = 0.00;
    let totalAndroidAmt = 0.00;
    let totalWebAmt = 0.00;

    let bookings = [];
    let totalPrice = 0.00;

    filteredObject.filter((item) => {
      switch (item.platform) {
        case "iOS": {
          totalIos.push(item)
          totalIosAmt = (
            totalIosAmt + item.price
          );
          break;
        }
        case "web": {
          totalWeb.push(item)
          totalWebAmt = (
            totalWebAmt + item.price
          );
          break;
        }
        case "Android": {
          totalAndroid.push(item)
          totalAndroidAmt = (
            totalAndroidAmt + item.price
          );
          break;
        }
        default: {
          //statements;
          break;
        }
          return (item.platform);
      }


    });

    // console.log(totalIos.length);
    // console.log(totalWeb.length);
    // console.log(totalAndroid.length);

    this.totalCountIOS = totalIos.length;
    this.totalIOSAmt = totalIosAmt;

    this.totalCountWeb = totalWeb.length;
    this.totalWebAmt = totalWebAmt;

    this.totalCountAndroid = totalAndroid.length;
    this.totalAndroidAmt = totalAndroidAmt;

    this.totalBookings = bookings;
    this.totalBookingAmt = totalPrice;
    // console.log(totalWeb.length);
    // console.log(totalAndroid.length);
    // console.log({totalIosAmt});
    // console.log({totalWebAmt});
    // console.log({totalAndroid});
  }

  getTotalForPaymentTypeFilter(filteredObject) {
    let totalCash = [];
    let totalOnline = [];
    let totalRetail = [];

    let totalCashAmt = 0.00;
    let totalOnlineAmt = 0.00;
    let totaRetailAmt = 0.00;

    filteredObject.filter((item) => {
      switch (item.paymentType) {
        case "CASH_PAY": {
          totalCash.push(item)
          totalCashAmt = (
            totalCashAmt + item.price
          );

          break;
        }
        case "ONLINE_PAY": {
          totalOnline.push(item)
          totalOnlineAmt = (
            totalOnlineAmt + item.price
          );
          break;
        }
        case "RETAIL_PAY": {
          totalRetail.push(item)
          totaRetailAmt = (
            totaRetailAmt + item.price
          );
          break;
        }
        default: {
          //statements;
          break;
        }
          return (item.platform);
      }

    });

    this.totalCashCount = totalCash.length;
    this.totalCashAmt = totalCashAmt;

    this.totalOnlineCount = totalOnline.length;
    this.totalOnlineAmt = totalOnlineAmt;

    this.totalRetailCount = totalRetail.length;
    this.totalRetailAmt = totaRetailAmt
  }

  getTotalForProvinceTypeFilter(filteredObject) {
    let totalGauteng = [];
    let totalWestenCape = [];
    let totalKZN = [];

    let totalGautengAmt = 0.00;
    let totalWesternCapeAmt = 0.00;
    let totalKZNAmt = 0.00;

    filteredObject.filter((item) => {
      switch (item.province) {
        case "GAUTENG": {
          totalGauteng.push(item)
          totalGautengAmt = (
            totalGautengAmt + item.price
          );
          break;
        }
        case "KWA_ZULU_NATAL": {
          totalWestenCape.push(item)
          totalWesternCapeAmt = (
            totalWesternCapeAmt + item.price
          );
          break;
        }
        case "WESTERN_CAPE": {
          totalKZN.push(item)
          totalKZNAmt = (
            totalKZNAmt + item.price
          );

          break;
        }
        default: {
          //statements;
          break;
        }
          return (item.platform);
      }
    });

    this.totalAmountKZN = totalKZNAmt;
    this.totalCountKZN = totalKZN.length;

    this.totalCountWesternCape = totalWestenCape.length;
    this.totalAmountWestenCape = totalWesternCapeAmt;

    this.totalCountGauteng = totalGauteng.length;
    this.totalAmountGauteng = totalGautengAmt;

    //console.log("totalKZNAmt : ", totalKZNAmt);
    // console.log(" this.totalAmountKZN : ",  this.totalAmountKZN);


    // console.log("totalWestenCape : ", totalWestenCape.length);
    // console.log("totalGauteng : ", totalGauteng.length);
    // console.log({ totalKZNAmt });
    // console.log({ totalWesternCapeAmt });
    // console.log({ totalGautengAmt });
  }

  getTotalForStatusFilter(filteredObject) {
    let complete = [];
    let intrasit = [];
    let awaitingDriver = [];

    filteredObject.filter((item) => {
      switch (item.status) {
        case "Booking successfully complete": {
          complete.push(item)
          break;
        }
        case "Driver in transit": {
          intrasit.push(item)
          break;
        }
        case "Awaiting driver to be assigned": {
          awaitingDriver.push(item)
          break;
        }
        default: {
          //statements;
          break;
        }
      }
      // console.log(item.platform)
      return (item.platform);
    });

    this.completedBookings = complete.length;
    this.intransitBookings = intrasit.length;
    this.awaitingBookings = awaitingDriver.length;
  }

  getCourierForStatusFiler(filteredObject) {
    let totalFlashRoad = [];
    let totalDash = [];
    let totalBudgetCourier = [];
    let totalExpressCourier = [];

    let totalRoadBookings = 0;

    let totalDashBookings = 0;
    let totalBudgetBookings = 0;
    let totalCourierBookings = 0;

    let totalRoadBookingAmt = 0.00;

    let totalDashBookingsAmt = 0.00;
    let totalBudgetBookingsAmt = 0.00;
    let totalCourierBookingsAmt = 0.00;

    filteredObject.filter((item) => {
      switch (item.type) {
        case "FLASH": {
          totalFlashRoad.push(item);
          totalRoadBookingAmt = (
            item.price
          );
          //  console.log(totalRoadBookingAmt)
          break;
        }
        case "DASH": {
          totalDash.push(item);
          totalDashBookingsAmt = (
            item.price
          );
          //  console.log(totalDashBookingsAmt)
          break;
        }
        case "EXPRESS_COURIER": {
          totalExpressCourier.push(item);
          totalCourierBookingsAmt = (
            item.price
          );
          //  console.log(totalCourierBookingsAmt)
          break;
        }
        case "BUDGET_COURIER": {
          totalBudgetCourier.push(item);
          totalBudgetBookingsAmt = (
            item.price
          );
          //  console.log(totalBudgetBookingsAmt)
          break;
        }
      }
    });

    this.totalDash = totalFlashRoad.length;
    this.totalFlashRoad = totalFlashRoad.length;
    this.totalFlashRoadBdgtCourier = totalBudgetCourier.length;
    this.totalFlashRoadExpCourier = totalExpressCourier.length;

  }

  public refreshAllStats(): void {
    window.location.reload();
  }
  public showTabByRole(role):boolean{
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let result=false;
    userInfo.roles.map(r=>{
      if(role===r.code){
        result=true;
      }
    })
    return result;
  }
}
