import {DatePipe} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {AdminService} from 'src/app/services/admin.service';
import {LoaderService} from 'src/app/services/loader.service';
import {SharedService} from '../../services/shared.service';

declare var $: any;

@Component({
  selector: 'app-dumps',
  templateUrl: './dumps.component.html',
  styleUrls: ['./dumps.component.css',  '../admin-portal/admin-portal.component.css']
})
export class DumpsComponent implements OnInit {

  bookingStartDate: any;
  bookingEndDate: any;
  admin: any;
  loader: boolean;

  bucketStartDate: any;
  bucketEndDate: any;

  driverStartDate: any;
  driverEndDate: any;

  financeReportStartDate: any;
  financeReportEndDate: any;

  personStartDate: string | number | Date;
  personEndDate: string | number | Date;

  retailStartDate: any;
  retailEndDate: any;

  invoiceRetailStartDate: any
  invoiceRetailEndDate: any;

  courierStartDate: any;
  courierEndDate: any;

  walletStartDate: any;
  walletEndDate: any;

  bookingReportStartDate: any;
  bookingReportEndDate: any;

  spendType = '';

  individualCheck = false;
  businessCheck = false;
  retailSpendingStartDate: any;
  retailSpendingEndDate: any;
  maxRetailDate = new Date();

  registeredIndividualCheck = false;
  registeredBusinessCheck = false;

  retailErrorMessage = '';

  registeredIndividualBeginning: any;
  registeredIndividualEnd: any;
  registeredRetailBeginning: any;
  registeredRetailEnd: any;

  paymentStartDate: any;
  paymentEndDate: any;
  paymentOzowCheck: any;
  paymentPayfastCheck: any;

  constructor(private adminService: AdminService, private sharedService: SharedService, private loadservice: LoaderService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.admin = JSON.parse(localStorage.getItem('userInfo'));
  }
  public downloadBookingReportsDumps() {

    var currentDate = new Date(this.bookingReportStartDate);
    let date = currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate();
    let monthNo = (currentDate.getMonth() + 1) <= 9 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
    var dropTime = currentDate.getFullYear() + '-' + monthNo + '-' + date + 'T' + '00' + ':' + '00' + ':' + '00';
    this.bookingReportStartDate = dropTime;

    var currentDate2 = new Date(this.bookingReportEndDate);
    let date2 = currentDate2.getDate() <= 9 ? '0' + currentDate2.getDate() : currentDate2.getDate();
    let monthNo2 = (currentDate2.getMonth() + 1) <= 9 ? '0' + (currentDate2.getMonth() + 1) : (currentDate2.getMonth() + 1);
    var dropTime2 = currentDate2.getFullYear() + '-' + monthNo2 + '-' + date2 + 'T' + '23' + ':' + '59' + ':' + '59';
    this.bookingReportEndDate = dropTime2;

    var dumpsDate = {
      'startDate': this.bookingReportStartDate,
      'endDate': this.bookingReportEndDate
    };

    this.loadservice.addloader();
    this.adminService.downloadBookingReportsDumps(this.admin.token, dumpsDate).subscribe(
      response => {
        this.loadservice.removeloader();
        this.downloadBookingFile(response,"booking-report")
      }, error => {
        this.loadservice.removeloader();
        console.log(error);
      }, () => {
      }
    )

  }

  public downloadBookingDumps() {

    var currentDate = new Date(this.bookingStartDate);
    let date = currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate();
    let monthNo = (currentDate.getMonth() + 1) <= 9 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
    var dropTime = currentDate.getFullYear() + '-' + monthNo + '-' + date + 'T' + '00' + ':' + '00' + ':' + '00';
    this.bookingStartDate = dropTime;

    var currentDate2 = new Date(this.bookingEndDate);
    let date2 = currentDate2.getDate() <= 9 ? '0' + currentDate2.getDate() : currentDate2.getDate();
    let monthNo2 = (currentDate2.getMonth() + 1) <= 9 ? '0' + (currentDate2.getMonth() + 1) : (currentDate2.getMonth() + 1);
    var dropTime2 = currentDate2.getFullYear() + '-' + monthNo2 + '-' + date2 + 'T' + '23' + ':' + '59' + ':' + '59';
    this.bookingEndDate = dropTime2;

    var dumpsDate = {
      'startDate': this.bookingStartDate,
      'endDate': this.bookingEndDate
    };

    this.loadservice.addloader();
    this.adminService.downloadBookingDumps(this.admin.token, dumpsDate).subscribe(
      response => {


        this.loadservice.removeloader();
        this.downloadBookingJson(response);
      }, error => {
        this.loadservice.removeloader();
        console.log(error);
      }, () => {
      }
    )

  }

  public downloadBucketDumps() {

    var currentDate = new Date(this.bucketStartDate);
    let date = currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate();
    let monthNo = (currentDate.getMonth() + 1) <= 9 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
    var dropTime = currentDate.getFullYear() + '-' + monthNo + '-' + date + 'T' + '00' + ':' + '00' + ':' + '00';
    this.bucketStartDate = dropTime;

    var currentDate2 = new Date(this.bucketEndDate);
    let date2 = currentDate2.getDate() <= 9 ? '0' + currentDate2.getDate() : currentDate2.getDate();
    let monthNo2 = (currentDate2.getMonth() + 1) <= 9 ? '0' + (currentDate2.getMonth() + 1) : (currentDate2.getMonth() + 1);
    var dropTime2 = currentDate2.getFullYear() + '-' + monthNo2 + '-' + date2 + 'T' + '23' + ':' + '59' + ':' + '59';
    this.bucketEndDate = dropTime2;

    var dumpsDate = {
      'startDate': this.bucketStartDate,
      'endDate': this.bucketEndDate
    };

    this.loadservice.addloader();
    this.adminService.downloadBucketDumps(this.admin.token, dumpsDate).subscribe(
      response => {
        console.log("boking dumps ",response);


        this.loadservice.removeloader();
        // this.downloadBookingFile(response,"bucket-dumps")
        this.downloadBucketJson(response);
      }, error => {
        this.loadservice.removeloader();
        console.log(error);
      }, () => {
      }
    )

  }

  public downloadDriverDumps() {

    var currentDate = new Date(this.driverStartDate);
    let date = currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate();
    let monthNo = (currentDate.getMonth() + 1) <= 9 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
    var dropTime = currentDate.getFullYear() + '-' + monthNo + '-' + date + 'T' + '00' + ':' + '00' + ':' + '00';
    this.driverStartDate = dropTime;

    var currentDate2 = new Date(this.driverEndDate);
    let date2 = currentDate2.getDate() <= 9 ? '0' + currentDate2.getDate() : currentDate2.getDate();
    let monthNo2 = (currentDate2.getMonth() + 1) <= 9 ? '0' + (currentDate2.getMonth() + 1) : (currentDate2.getMonth() + 1);
    var dropTime2 = currentDate2.getFullYear() + '-' + monthNo2 + '-' + date2 + 'T' + '23' + ':' + '59' + ':' + '59';
    this.driverEndDate = dropTime2;

    var dumpsDate = {
      'startDate': this.driverStartDate,
      'endDate': this.driverEndDate
    };

    this.loadservice.addloader();
    this.adminService.downloadDriverDumps(this.admin.token, dumpsDate).subscribe(
      response => {
        this.loadservice.removeloader();
        this.downloadDriverJson(response);
      }, error => {
        this.loadservice.removeloader();
        console.log(error);
      }, () => {
        // console.log('success method hit')
      }
    )
  }

  public downloadfinanceReportDumps() {
    var currentDate = new Date(this.financeReportStartDate);
    let date = currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate();
    let monthNo = (currentDate.getMonth() + 1) <= 9 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
    var dropTime = currentDate.getFullYear() + '-' + monthNo + '-' + date + 'T' + '00' + ':' + '00' + ':' + '00';
    this.financeReportStartDate = dropTime;

    var currentDate2 = new Date(this.financeReportEndDate);
    let date2 = currentDate2.getDate() <= 9 ? '0' + currentDate2.getDate() : currentDate2.getDate();
    let monthNo2 = (currentDate2.getMonth() + 1) <= 9 ? '0' + (currentDate2.getMonth() + 1) : (currentDate2.getMonth() + 1);
    var dropTime2 = currentDate2.getFullYear() + '-' + monthNo2 + '-' + date2 + 'T' + '23' + ':' + '59' + ':' + '59';
    this.financeReportEndDate = dropTime2;

    var dumpsDate = {
      'startDate': this.financeReportStartDate,
      'endDate': this.financeReportEndDate
    };

    this.loadservice.addloader();
    this.adminService.downloadFinanceReportDumps(this.admin.token, dumpsDate).subscribe(
      response => {
        this.loadservice.removeloader();
        this.downloadFinanceReportJson(response);
      }, error => {
        this.loadservice.removeloader();
        console.log(error);
      }, () => {

      }
    )
  }

  public downloadPersonsDumps() {
    var currentDate = new Date(this.personStartDate);
    let date = currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate();
    let monthNo = (currentDate.getMonth() + 1) <= 9 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
    var dropTime = currentDate.getFullYear() + '-' + monthNo + '-' + date + 'T' + '00' + ':' + '00' + ':' + '00';
    this.personStartDate = dropTime;

    var currentDate2 = new Date(this.personEndDate);
    let date2 = currentDate2.getDate() <= 9 ? '0' + currentDate2.getDate() : currentDate2.getDate();
    let monthNo2 = (currentDate2.getMonth() + 1) <= 9 ? '0' + (currentDate2.getMonth() + 1) : (currentDate2.getMonth() + 1);
    var dropTime2 = currentDate2.getFullYear() + '-' + monthNo2 + '-' + date2 + 'T' + '23' + ':' + '59' + ':' + '59';
    this.personEndDate = dropTime2;

    var dumpsDate = {
      'startDate': this.personStartDate,
      'endDate': this.personEndDate
    };

    this.loadservice.addloader();
    this.adminService.downloadPersonsDumps(this.admin.token, dumpsDate).subscribe(
      response => {
        this.loadservice.removeloader();
        this.downloadCustomersJson(response);
      }, error => {
        this.loadservice.removeloader();
        console.log(error);
      }, () => {
        // console.log('success method hit')
      }
    )
  }

  public downloadRetailsDumps() {
    var currentDate = new Date(this.retailStartDate);
    let date = currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate();
    let monthNo = (currentDate.getMonth() + 1) <= 9 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
    var dropTime = currentDate.getFullYear() + '-' + monthNo + '-' + date + 'T' + '00' + ':' + '00' + ':' + '00';
    this.retailStartDate = dropTime;

    var currentDate2 = new Date(this.retailEndDate);
    let date2 = currentDate2.getDate() <= 9 ? '0' + currentDate2.getDate() : currentDate2.getDate();
    let monthNo2 = (currentDate2.getMonth() + 1) <= 9 ? '0' + (currentDate2.getMonth() + 1) : (currentDate2.getMonth() + 1);
    var dropTime2 = currentDate2.getFullYear() + '-' + monthNo2 + '-' + date2 + 'T' + '23' + ':' + '59' + ':' + '59';
    this.retailEndDate = dropTime2;

    var dumpsDate = {
      'startDate': this.retailStartDate,
      'endDate': this.retailEndDate
    };

    this.loadservice.addloader();
    this.adminService.downloadRetailDumps(this.admin.token, dumpsDate).subscribe(
      response => {
        this.loadservice.removeloader();
        this.downloadRetailJson(response);
        console.log(response);
      }, error => {
        this.loadservice.removeloader();
        console.log(error);
      }, () => {
        // console.log('success method hit')
      }
    );
  }

  public invoiceOutstandingRetails() {
    var currentDate = new Date(this.invoiceRetailStartDate);
    let date = currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate();
    let monthNo = (currentDate.getMonth() + 1) <= 9 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
    var dropTime = currentDate.getFullYear() + '-' + monthNo + '-' + date + 'T' + '00' + ':' + '00' + ':' + '00';
    this.invoiceRetailStartDate = dropTime;

    var currentDate2 = new Date(this.invoiceRetailEndDate);
    let date2 = currentDate2.getDate() <= 9 ? '0' + currentDate2.getDate() : currentDate2.getDate();
    let monthNo2 = (currentDate2.getMonth() + 1) <= 9 ? '0' + (currentDate2.getMonth() + 1) : (currentDate2.getMonth() + 1);
    var dropTime2 = currentDate2.getFullYear() + '-' + monthNo2 + '-' + date2 + 'T' + '23' + ':' + '59' + ':' + '59';
    this.invoiceRetailEndDate = dropTime2;

    var dumpsDate = {
      'startDate': this.invoiceRetailStartDate,
      'endDate': this.invoiceRetailEndDate
    };

    this.loadservice.addloader();
    this.adminService.outstandingRetailInvoices(this.admin.token, dumpsDate).subscribe(
      response => {
        console.log(response.json());
        this.loadservice.removeloader();
        this.downloadOutstandingRetailInvoices(response.json());
      }, error => {
        this.loadservice.removeloader();
        console.log(error);
      }, () => {

      }
    );
  }

  public downloadCourierBookings() {
    var currentDate = new Date(this.courierStartDate);
    let date = currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate();
    let monthNo = (currentDate.getMonth() + 1) <= 9 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
    var dropTime = currentDate.getFullYear() + '-' + monthNo + '-' + date + 'T' + '00' + ':' + '00' + ':' + '00';
    this.courierStartDate = dropTime;

    var currentDate2 = new Date(this.courierEndDate);
    let date2 = currentDate2.getDate() <= 9 ? '0' + currentDate2.getDate() : currentDate2.getDate();
    let monthNo2 = (currentDate2.getMonth() + 1) <= 9 ? '0' + (currentDate2.getMonth() + 1) : (currentDate2.getMonth() + 1);
    var dropTime2 = currentDate2.getFullYear() + '-' + monthNo2 + '-' + date2 + 'T' + '23' + ':' + '59' + ':' + '59';
    this.courierEndDate = dropTime2;

    var dumpsDate = {
      'startDate': this.courierStartDate,
      'endDate': this.courierEndDate
    };

    console.log(dumpsDate);

    this.loadservice.addloader();
    this.adminService.downloadCourierDumps(this.admin.token, dumpsDate).subscribe(
      response => {
        console.log(response);
        this.loadservice.removeloader();
        this.downloadCourierDumps(response);
      }, error => {
        this.loadservice.removeloader();
        console.log(error);
      }, () => {
        // console.log('success method hit')
      }
    );
  }

  public downloadWalletDumps() {
    var currentDate = new Date(this.walletStartDate);
    let date = currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate();
    let monthNo = (currentDate.getMonth() + 1) <= 9 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
    var dropTime = currentDate.getFullYear() + '-' + monthNo + '-' + date + 'T' + '00' + ':' + '00' + ':' + '00';
    this.walletStartDate = dropTime;

    var currentDate2 = new Date(this.walletEndDate);
    let date2 = currentDate2.getDate() <= 9 ? '0' + currentDate2.getDate() : currentDate2.getDate();
    let monthNo2 = (currentDate2.getMonth() + 1) <= 9 ? '0' + (currentDate2.getMonth() + 1) : (currentDate2.getMonth() + 1);
    var dropTime2 = currentDate2.getFullYear() + '-' + monthNo2 + '-' + date2 + 'T' + '23' + ':' + '59' + ':' + '59';
    this.walletEndDate = dropTime2;

    var dumpsDate = {
      'startDate': this.walletStartDate,
      'endDate': this.walletEndDate
    };

    console.log(dumpsDate);

    this.loadservice.addloader();
    this.adminService.getAllWalletTransactionsDumps(this.admin.token, dumpsDate).subscribe(
      response => {
        console.log(response);
        this.loadservice.removeloader();
        this.downloadWalletJson(response);
      }, error => {
        this.loadservice.removeloader();
        console.log(error);
      }, () => {
        // console.log('success method hit')
      }
    );
  }

  public downloadBookingJson(myJson){
    var sJson = JSON.stringify(myJson);
    var element = document.createElement('a');
    // var capturedDate = new Date();
    // capturedDate.getDate();
    var currentDate = new Date();
    var datePipe = new DatePipe("en-US");
    let value = datePipe.transform(currentDate, 'yyyy-MM-dd');
    element.setAttribute('href', "data:text/json;charset=UTF-8," + encodeURIComponent(sJson));
    element.setAttribute('download', value + " - booking-dumps.json");
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click(); // simulate click
    document.body.removeChild(element);
  }

  public downloadBucketJson(myJson){
    var sJson = JSON.stringify(myJson);
    var element = document.createElement('a');
    // var capturedDate = new Date();
    // capturedDate.getDate();
    var currentDate = new Date();
    var datePipe = new DatePipe("en-US");
    let value = datePipe.transform(currentDate, 'yyyy-MM-dd');
    element.setAttribute('href', "data:text/json;charset=UTF-8," + encodeURIComponent(sJson));
    element.setAttribute('download', value + " - bucket-dumps.json");
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click(); // simulate click
    document.body.removeChild(element);
  }

  public downloadDriverJson(myJson){
    var sJson = JSON.stringify(myJson);
    var element = document.createElement('a');
    // var capturedDate = new Date();
    // capturedDate.getDate();
    var currentDate = new Date();
    var datePipe = new DatePipe("en-US");
    let value = datePipe.transform(currentDate, 'yyyy-MM-dd');
    element.setAttribute('href', "data:text/json;charset=UTF-8," + encodeURIComponent(sJson));
    element.setAttribute('download', value + " - driver-dumps.json");
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click(); // simulate click
    document.body.removeChild(element);
  }

  public downloadFinanceReportJson(myJson){
    var sJson = JSON.stringify(myJson);
    var element = document.createElement('a');
    // var capturedDate = new Date();
    // capturedDate.getDate();
    var currentDate = new Date();
    var datePipe = new DatePipe("en-US");
    let value = datePipe.transform(currentDate, 'yyyy-MM-dd');
    element.setAttribute('href', "data:text/json;charset=UTF-8," + encodeURIComponent(sJson));
    element.setAttribute('download', value + " - finance-report-dumps.json");
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click(); // simulate click
    document.body.removeChild(element);
  }

  public downloadCustomersJson(myJson){
    var sJson = JSON.stringify(myJson);
    var element = document.createElement('a');
    // var capturedDate = new Date();
    // capturedDate.getDate();
    var currentDate = new Date();
    var datePipe = new DatePipe("en-US");
    let value = datePipe.transform(currentDate, 'yyyy-MM-dd');
    element.setAttribute('href', "data:text/json;charset=UTF-8," + encodeURIComponent(sJson));
    element.setAttribute('download', value + " - customers-dumps.json");
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click(); // simulate click
    document.body.removeChild(element);
  }

  public downloadRetailJson(myJson){
    var sJson = JSON.stringify(myJson);
    var element = document.createElement('a');
    // var capturedDate = new Date();
    // capturedDate.getDate();
    var currentDate = new Date();
    var datePipe = new DatePipe("en-US");
    let value = datePipe.transform(currentDate, 'yyyy-MM-dd');
    element.setAttribute('href', "data:text/json;charset=UTF-8," + encodeURIComponent(sJson));
    element.setAttribute('download', value + " - retail-dumps.json");
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click(); // simulate click
    document.body.removeChild(element);
  }

  public downloadOutstandingRetailInvoices(myJson){
    var sJson = JSON.stringify(myJson);
    var element = document.createElement('a');
    var currentDate = new Date();
    var datePipe = new DatePipe("en-US");
    let value = datePipe.transform(currentDate, 'yyyy-MM-dd');
    element.setAttribute('href', "data:text/json;charset=UTF-8," + encodeURIComponent(sJson));
    element.setAttribute('download', value + " - outstanding-retail-invoice-dumps.json");
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click(); // simulate click
    document.body.removeChild(element);
  }

  public downloadCourierDumps(myJson){
    var sJson = JSON.stringify(myJson);
    var element = document.createElement('a');
    // var capturedDate = new Date();
    // capturedDate.getDate();
    var currentDate = new Date();
    var datePipe = new DatePipe("en-US");
    let value = datePipe.transform(currentDate, 'yyyy-MM-dd');
    element.setAttribute('href', "data:text/json;charset=UTF-8," + encodeURIComponent(sJson));
    element.setAttribute('download', value + " - courier-booking-dumps.json");
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click(); // simulate click
    document.body.removeChild(element);
  }

  public downloadWalletJson(myJson){
    var sJson = JSON.stringify(myJson);
    var element = document.createElement('a');
    var currentDate = new Date();
    var datePipe = new DatePipe("en-US");
    let value = datePipe.transform(currentDate, 'yyyy-MM-dd');
    element.setAttribute('href', "data:text/json;charset=UTF-8," + encodeURIComponent(sJson));
    element.setAttribute('download', value + " - wallet-dumps.json");
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click(); // simulate click
    document.body.removeChild(element);
  }
  downloadBookingFile(data: any,filename) {
    const replacer = (key, value) => (value === null ? '' : value);
    const header = Object.keys(data[0]);

    const csv = data.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(','));
    // const csv = data.map((row) => header.map((fieldName:any) =>isNaN(fieldName)? JSON.stringify(row[fieldName], replacer):fieldName).join(','));

    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');

    console.log(csvArray);
    const a = document.createElement('a');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    var currentDate = new Date();
    var datePipe = new DatePipe("en-US");
    let value = datePipe.transform(currentDate, 'yyyy-MM-dd');
    a.download = value + '-' +filename+ '.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
  public showTabByRole(role):boolean{
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let result=false;
    userInfo.roles.map(r=>{
      if(role===r.code){
        result=true;
      }
    })
    return result;
  }

  switchSpendingSelection(individual: boolean) {
    if (individual && this.individualCheck) {
      this.businessCheck = false;
    }
    if (!individual && this.businessCheck) {
      this.individualCheck = false;
    }
  }

  checkIfGotStartMonth(event) {
    this.retailSpendingStartDate = new Date(event);
    if (this.retailSpendingEndDate !== undefined) {
      if (this.retailSpendingStartDate.getMonth() > this.retailSpendingEndDate.getMonth()) {
        this.retailSpendingEndDate = undefined;
      }
    }
  }

  checkIfGotEndMonth(event: Date) {
    this.retailSpendingEndDate = new Date(event.getFullYear(), event.getMonth() + 1, 0);
    if ((this.retailSpendingEndDate.getMonth() > (this.retailSpendingStartDate.getMonth() + 2)) || (this.retailSpendingEndDate.getMonth() < this.retailSpendingStartDate.getMonth())) {
    //   show error
      this.retailSpendingEndDate = undefined;
    }
  }

  public downloadSpendingReport() {
    if (this.spendType === '') {
      return;
    }
    const dumpsDate = {
      startDate: this.retailSpendingStartDate.getFullYear() + '-' +
        ((this.retailSpendingStartDate.getMonth() + 1) <= 9 ? '0' + (this.retailSpendingStartDate.getMonth() + 1) : (this.retailSpendingStartDate.getMonth() + 1)) + '-' +
        (this.retailSpendingStartDate.getDate() <= 9 ? '0' + this.retailSpendingStartDate.getDate() : this.retailSpendingStartDate.getDate()) + 'T' + '00' + ':' + '00' + ':' + '00',

      endDate: this.retailSpendingEndDate.getFullYear() + '-' +
        ((this.retailSpendingEndDate.getMonth() + 1) <= 9 ? '0' + (this.retailSpendingEndDate.getMonth() + 1) : (this.retailSpendingEndDate.getMonth() + 1)) + '-' +
        (this.retailSpendingEndDate.getDate() <= 9 ? '0' + this.retailSpendingEndDate.getDate() : this.retailSpendingEndDate.getDate()) + 'T' + '23' + ':' + '59' + ':' + '59',
    };

    this.loadservice.addloader();
    this.adminService.createSpendingReport(this.admin.token, dumpsDate, this.spendType).subscribe(
      (response: any) => {
        this.loadservice.removeloader();
        if (response.downloadPdfURL != null) {
          const retailSpendingSpreadSheet = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + response.downloadPdfURL;
          const downloadRetailSpending = document.createElement('a');
          downloadRetailSpending.href = retailSpendingSpreadSheet;
          const firstLetter = this.spendType.charAt(0);
          const firstLetterCap = firstLetter.toUpperCase();
          const remainingLetters = this.spendType.slice(1);
          const capitalizedWord = firstLetterCap + remainingLetters.toLowerCase();

          downloadRetailSpending.download = capitalizedWord +
            ' Spending [' +
            this.retailSpendingStartDate.getFullYear() + '-' + (this.retailSpendingStartDate.getMonth() + 1) +
            ' - ' +
            this.retailSpendingEndDate.getFullYear() + '-' + (this.retailSpendingEndDate.getMonth() + 1) +
            '].xlsx';
          downloadRetailSpending.click();
          downloadRetailSpending.remove();
          this.retailSpendingStartDate = undefined;
          this.retailSpendingEndDate = undefined;
        } else {
          this.retailErrorMessage = 'Error while downloading.';
          $('#retailSpendError').modal('show');
        }
      },
        error => {
        this.loadservice.removeloader();
        console.log(error);
          this.retailErrorMessage = error.error.message;
        $('#retailSpendError').modal('show');
      }, () => {
      }
    );
  }

  public downloadIndividualSpendingReport() {
    if (this.spendType !== 'INDIVIDUAL') {
      return;
    }
    const dumpsDate = {
      startDate: this.retailSpendingStartDate.getFullYear() + '-' +
        ((this.retailSpendingStartDate.getMonth() + 1) <= 9 ? '0' + (this.retailSpendingStartDate.getMonth() + 1) : (this.retailSpendingStartDate.getMonth() + 1)) + '-' +
        (this.retailSpendingStartDate.getDate() <= 9 ? '0' + this.retailSpendingStartDate.getDate() : this.retailSpendingStartDate.getDate()) + 'T' + '00' + ':' + '00' + ':' + '00',

      endDate: this.retailSpendingEndDate.getFullYear() + '-' +
        ((this.retailSpendingEndDate.getMonth() + 1) <= 9 ? '0' + (this.retailSpendingEndDate.getMonth() + 1) : (this.retailSpendingEndDate.getMonth() + 1)) + '-' +
        (this.retailSpendingEndDate.getDate() <= 9 ? '0' + this.retailSpendingEndDate.getDate() : this.retailSpendingEndDate.getDate()) + 'T' + '23' + ':' + '59' + ':' + '59',
    };

    this.loadservice.addloader();
    this.adminService.createIndividualSpendingDumps(this.admin.token, dumpsDate).subscribe(
      (response: any) => {
        this.loadservice.removeloader();
        if (response.downloadPdfURL != null) {
          const retailSpendingSpreadSheet = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + response.downloadPdfURL;
          const downloadRetailSpending = document.createElement('a');
          downloadRetailSpending.href = retailSpendingSpreadSheet;
          downloadRetailSpending.download =
            'Individual Spending [' +
            this.retailSpendingStartDate.getFullYear() + '-' + (this.retailSpendingStartDate.getMonth() + 1) +
            ' - ' +
            this.retailSpendingEndDate.getFullYear() + '-' + (this.retailSpendingEndDate.getMonth() + 1) +
            '].xlsx';
          downloadRetailSpending.click();
          downloadRetailSpending.remove();
          this.retailSpendingStartDate = undefined;
          this.retailSpendingEndDate = undefined;
        } else {
          $('#retailSpendError').modal('show');
          this.retailErrorMessage = 'Error while downloading.';
        }
      },
      error => {
        this.loadservice.removeloader();
        console.log(error);
        $('#retailSpendError').modal('show');
        this.retailErrorMessage = error.error.message;
      }, () => {
      }
    );
  }

  switchRegisteredSelection(individual: boolean) {
    if (individual && this.registeredIndividualCheck) {
      this.registeredBusinessCheck = false;
    }
    if (!individual && this.registeredBusinessCheck) {
      this.registeredIndividualCheck = false;
    }
  }

  getIndividualStartMonth(event) {
    this.registeredIndividualBeginning = new Date(event);
  }

  setIndividualEndMonth(event: Date) {
    // Set last day of the month
    this.registeredIndividualEnd = new Date(event.getFullYear(), event.getMonth() + 1, 0);
  }

  public downloadRegisteredIndividualSpendingReport() {
    if (!this.registeredIndividualCheck) {
      return;
    }
    const dates = {
      startDate: this.registeredIndividualBeginning.getFullYear() + '-' +
        ((this.registeredIndividualBeginning.getMonth() + 1) <= 9 ? '0' + (this.registeredIndividualBeginning.getMonth() + 1) : (this.registeredIndividualBeginning.getMonth() + 1)) + '-' +
        (this.registeredIndividualBeginning.getDate() <= 9 ? '0' + this.registeredIndividualBeginning.getDate() : this.registeredIndividualBeginning.getDate()) + 'T' + '00' + ':' + '00' + ':' + '00',

      endDate: this.registeredIndividualEnd.getFullYear() + '-' +
        ((this.registeredIndividualEnd.getMonth() + 1) <= 9 ? '0' + (this.registeredIndividualEnd.getMonth() + 1) : (this.registeredIndividualEnd.getMonth() + 1)) + '-' +
        (this.registeredIndividualEnd.getDate() <= 9 ? '0' + this.registeredIndividualEnd.getDate() : this.registeredIndividualEnd.getDate()) + 'T' + '23' + ':' + '59' + ':' + '59',
    };

    this.loadservice.addloader();
    this.adminService.createRegisteredIndividualSpendingReport(this.admin.token, dates).subscribe(
      (response: any) => {
        this.loadservice.removeloader();
        if (response.downloadPdfURL != null) {
          const registeredIndividualSpendingSpreadSheet = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + response.downloadPdfURL;
          const downloadSpending = document.createElement('a');
          downloadSpending.href = registeredIndividualSpendingSpreadSheet;
          downloadSpending.download =
            'Registered Individuals [' +
            this.registeredIndividualBeginning.getFullYear() + '-' + (this.registeredIndividualBeginning.getMonth() + 1) +
            ' - ' +
            this.registeredIndividualEnd.getFullYear() + '-' + (this.registeredIndividualEnd.getMonth() + 1) +
            '].xlsx';
          downloadSpending.click();
          downloadSpending.remove();
          this.registeredIndividualBeginning = undefined;
          this.registeredIndividualEnd = undefined;
        } else {
          $('#retailSpendError').modal('show');
          this.retailErrorMessage = 'Error while downloading.';
        }
      },
      error => {
        this.loadservice.removeloader();
        console.log(error);
        $('#retailSpendError').modal('show');
        this.retailErrorMessage = error.error.message;
      }, () => {
      }
    );
  }

  getBusinessStartMonth(event) {
    this.registeredRetailBeginning = new Date(event);
  }

  setBusinessEndMonth(event: Date) {
    // Set last day of the month
    this.registeredRetailEnd = new Date(event.getFullYear(), event.getMonth() + 1, 0);
  }

  public downloadRegisteredBusinessSpendingReport() {
    if (!this.registeredBusinessCheck) {
      return;
    }
    const dates = {
      startDate: this.registeredIndividualBeginning.getFullYear() + '-' +
        ((this.registeredIndividualBeginning.getMonth() + 1) <= 9 ? '0' + (this.registeredIndividualBeginning.getMonth() + 1) : (this.registeredIndividualBeginning.getMonth() + 1)) + '-' +
        (this.registeredIndividualBeginning.getDate() <= 9 ? '0' + this.registeredIndividualBeginning.getDate() : this.registeredIndividualBeginning.getDate()) + 'T' + '00' + ':' + '00' + ':' + '00',

      endDate: this.registeredIndividualEnd.getFullYear() + '-' +
        ((this.registeredIndividualEnd.getMonth() + 1) <= 9 ? '0' + (this.registeredIndividualEnd.getMonth() + 1) : (this.registeredIndividualEnd.getMonth() + 1)) + '-' +
        (this.registeredIndividualEnd.getDate() <= 9 ? '0' + this.registeredIndividualEnd.getDate() : this.registeredIndividualEnd.getDate()) + 'T' + '23' + ':' + '59' + ':' + '59',
    };

    this.loadservice.addloader();
    this.adminService.createRegisteredBusinessSpendingReport(this.admin.token, dates).subscribe(
      (response: any) => {
        this.loadservice.removeloader();
        if (response.downloadPdfURL != null) {
          const registeredBusinessSpendingSpreadSheet = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + response.downloadPdfURL;
          const downloadSpending = document.createElement('a');
          downloadSpending.href = registeredBusinessSpendingSpreadSheet;
          downloadSpending.download =
            'Registered Businesses [' +
            this.registeredIndividualBeginning.getFullYear() + '-' + (this.registeredIndividualBeginning.getMonth() + 1) +
            ' - ' +
            this.registeredIndividualEnd.getFullYear() + '-' + (this.registeredIndividualEnd.getMonth() + 1) +
            '].xlsx';
          downloadSpending.click();
          downloadSpending.remove();
          this.registeredRetailBeginning = undefined;
          this.registeredRetailEnd = undefined;
        } else {
          $('#retailSpendError').modal('show');
          this.retailErrorMessage = 'Error while downloading.';
        }
      },
      error => {
        this.loadservice.removeloader();
        console.log(error);
        $('#retailSpendError').modal('show');
        this.retailErrorMessage = error.error.message;
      }, () => {
      }
    );
  }

  downloadPaymentData() {
    console.log(this.paymentOzowCheck);
    console.log(this.paymentPayfastCheck);

    const serviceProvider = [];

    if(this.paymentOzowCheck)
      serviceProvider.push('OZOW');

    if(this.paymentPayfastCheck)
      serviceProvider.push('PAYFAST');



    const formattedFromDate = this.paymentStartDate.toISOString().slice(0, 16);
    const formattedToDate = this.paymentEndDate.toISOString().slice(0, 16);

    const dumpBody = {
      serviceProvider: serviceProvider,
      startTime: formattedFromDate,
      endTime: formattedToDate,
    }

    this.adminService.getPaymentDumps(this.admin.token, dumpBody)
      .subscribe(
        response => {
          console.log(response);
          this.downloadFile(response.downloadPdfURL)
          this.loadservice.removeloader()
        }, error => {
          console.log(error);
          this.loadservice.removeloader()
        }
      );
  }


  public downloadFile(base64String: string) {
    var element = document.createElement('a');
    var currentDate = new Date();
    var datePipe = new DatePipe("en-US");
    let value = datePipe.transform(currentDate, 'yyyy-MM-dd');

    var mimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"; // Excel MIME type

    element.setAttribute('href', 'data:' + mimeType + ';base64,' + base64String);
    element.setAttribute('download', value + " - Payment Merchants.xlsx");
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
}
