import { Component, OnInit } from '@angular/core';
import {AdminService} from '../../services/admin.service';

@Component({
  selector: 'app-uber-booking-details',
  templateUrl: './uber-booking-details.component.html',
  styleUrls: ['./uber-booking-details.component.css']
})
export class UberBookingDetailsComponent implements OnInit {

  user: any;
  uberBooking: any;
  driver: any;

  constructor(private adminService: AdminService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.user = JSON.parse(localStorage.getItem('userInfo'));
    this.uberBooking = JSON.parse(localStorage.getItem('selectedUberBooking'));
    // console.log(this.uberBooking);
    if (this.uberBooking.driverOid != null) {
      this.adminService.getDriver(this.user.token, this.uberBooking.driverOid).subscribe(
        (res) => {
          this.driver = res;
        }
      );
    }
  }
}
