import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BookingService } from 'src/app/services/booking.service';

@Component({
  selector: 'app-view-wallet-transactions',
  templateUrl: './view-wallet-transactions.component.html',
  styleUrls: ['./view-wallet-transactions.component.css']
})
export class ViewWalletTransactionsComponent implements OnInit {

  user: any;
  walletID: string;
  history: any = [];

  constructor(private bookingService: BookingService, private route: Router) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('userInfo'));
    this.walletID = localStorage.getItem('walletID');
    this.viewAllTransactions();
  }

  public viewAllTransactions() {
    this.bookingService.getTransactions(this.user.token, this.walletID).subscribe(
      response => {
        this.history = response.reverse();
      }, error => {
        console.log(error);
        if (error.status == 500) {
          this.route.navigateByUrl('/retail-profile');
        }
      }, () => {
        // console.log('');
      }
    )
  }

}
