import { Component, OnInit, ViewChild } from '@angular/core';
import { StarRatingComponent } from 'ng-starrating';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

declare var $: any;

@Component({
  selector: 'app-rating-page',
  templateUrl: './rating-page.component.html',
  styleUrls: ['./rating-page.component.css']
})
export class RatingPageComponent implements OnInit {

  @ViewChild('mainForm', { static: false }) mainForm: NgForm;

  content: any = "";
  message: any = "";
  bookingOid: any;
  booking: any;
  sub: any;
  score: number = 1;

  constructor(private route: ActivatedRoute, private userService: UserService, private routing: Router) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.bookingOid = params['bookingOid'];
      console.log(this.bookingOid);
      this.userService.getBooking(this.bookingOid).subscribe(
        (res) => {
          this.booking = res.json();
        }, (err) => {
        }
      );
    });
  }

  onRate($event: { oldValue: number, newValue: number, starRating: StarRatingComponent }) {
    this.score = $event.newValue;
  }

  sendFeedback() {

    var rating = {
      "hexId": this.booking.driverOid,
      "bookingOid": this.booking.oid,
      "rating": this.score,
      "score": this.score,
      "comments": this.content
    };

    this.userService.rateBookings(rating).subscribe(
      (response) => {
        this.message = 'Thank you for your feedback.';
        $('#response').modal('show');
      }, (error) => {
        this.routing.navigateByUrl('/');
      }
    );


  }

}
