import {Injectable} from '@angular/core';
import {Http, Headers, RequestOptions} from '@angular/http';
import {Observable} from 'rxjs/Observable';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {SharedService} from './shared.service';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class AdminService {

  private foundBookings = new BehaviorSubject([]);
  private retailCompany = new BehaviorSubject({});
  currentRetailCompany = this.retailCompany.asObservable();
  currentBookings = this.foundBookings.asObservable();

  constructor(private http: Http, private httpClient: HttpClient, private sharedService: SharedService) {
  }

  changeBookings(bookings: []) {
    this.foundBookings.next(bookings)
    console.log("these is what the booking are", bookings);
  }

  changeRetailer(retail: {}) {
    this.retailCompany.next(retail);
    console.log("this is the current retail.....", retail);
  }

  public saveAlert(token, alertObj): Observable<any> {

    var userToken = "Bearer " + token;
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Authorization", userToken);
    let options = new RequestOptions({headers: headers});
    return this.http.post(this.sharedService.getUrl() + "admin/alert/message", alertObj, options);
  }

  public deleteAlert(token, alertOid): Observable<any> {

    var userToken = "Bearer " + token;
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Authorization", userToken);
    let options = new RequestOptions({headers: headers});
    return this.http.post(this.sharedService.getUrl() + "admin/alert/message/delete/" + alertOid, '', options);
  }

  public getAlerts(token): Observable<any> {

    var userToken = "Bearer " + token;
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Authorization", userToken);
    let options = new RequestOptions({headers: headers});
    return this.http.get(this.sharedService.getUrl() + "admin/alert/message", options);
  }

  public getContractOwnersList(token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'owner/driver/contract/owners',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getAgentsList(token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'owner/driver/agents',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getDriverList(token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'owner/driver/list',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getAllUsers(token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'admin/accounts',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getUsers(token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'admin/accounts/limits',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getUsersAll(token, filter): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'admin/accounts/filtered ', filter,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }


  public getUsersFiltered(token, filter): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'admin/accounts/filter', filter,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getAPIUsers(token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'admin/api/users',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public companyInfo(company: any, user: any, token: string): Observable<any> {

    var companyData = {
      'registrationNumber': company.companyReg,
      'name': company.companyName,
      'province': company.province,
      'contact': {
        'oid': user.owner.oid,
        'deviceId': user.owner.deviceId,
        'mobile': user.owner.mobile,
        'firstName': user.owner.firstName,
        'surname': user.owner.surname,
        'rsaId': user.owner.rsaId,
        'email': user.owner.email
      },
      'owner': {
        'oid': user.owner.oid,
        'deviceId': user.owner.deviceId,
        'mobile': user.owner.mobile,
        'firstName': user.owner.firstName,
        'surname': user.owner.surname,
        'rsaId': user.owner.rsaId,
        'email': user.owner.email
      }
    };

    var vehicleData = {
      'registrationNumber': company.registration,
      'vinNumber': company.vinNo,
      'make': company.make,
      'model': company.model,
      'color': company.Vcolor,
      'type': company.type,
      'companyId': user.owner.deviceId,
      'hasCanopy': company.hasCanopy,
      "diskDate": company.diskDate
    };

    var data = {
      'vehicle': vehicleData,
      'company': companyData
    }

    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'parties/company/info', data,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getAllCompanies(token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'parties/companies',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getAllCompaniesFilter(token, filter): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'parties/companies/filter', filter,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getAllVehicles(token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'vehicles/all/vehicles',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getAllVehiclesFilter(token, filter): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'vehicles/all/vehicles/filter', filter,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getVehicleByVinOrReg(token, vinOrReg): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'vehicles/get/vehicle/' + vinOrReg,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getAllDrivers(token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'parties/drivers',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getAllDriversFiltered(token, filter): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'parties/drivers/filter/v2', filter,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getDriversForVehicle(token, DriverOID) {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'parties/drivers/' + DriverOID,
      {
        headers: new HttpHeaders()
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getAllPayment(token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'payments/requests',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public POIweatherStatus(token, status): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'parties/weather/get/status/' + status,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getAllItems(token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'goods/items',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getretailDrivers(token, retailOID): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'parties/retails/drivers/' + retailOID,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getBucketBookings(token, retailOid): Observable<any> {
    var userToken = "Bearer " + token;
    return this.httpClient.get(this.sharedService.getUrl() + "bookings/buckets/retail/" + retailOid,
      {
        headers: new HttpHeaders().set("Accept", 'application/json').set('Content-Type', 'application/json')
          .set("Authorization", userToken)
      });
  }

  public getRetailHistory(token, retailOID): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'bookings/buckets/history/' + retailOID,
      {
        headers: new HttpHeaders()
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public deleteAccount(token, deleteData): Observable<any> {
    var userToken = 'Bearer ' + token;
    const header: HttpHeaders = new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken);
    const httpOptions = {
      headers: header,
      body: deleteData
    };
    return this.httpClient.delete(this.sharedService.getUrl() + 'parties/retails/drivers', httpOptions);
  }

  public getAllDeliveries(token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'admin/accounts',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getAllCategories(token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'goods/categories',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getAllWeightings(token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'goods/weightings',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getAllRetails(token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'parties/retails',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }


  public getAllRetailsPaginated(token, filter): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'parties/retails/filter',filter,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public removeTypeRate(token, data: any): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'parties/retails/typeRate/remove', data,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public activateRetail(token, retailOid: string): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'parties/retails/activate/' + retailOid, '',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public changeWeatherStatus(token, weather): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'parties/swift/weather/change/status', weather,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public pushExpress(token, data): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/buckets/express/push', data,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public suspendRetail(token, retailOid: string): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'parties/retails/suspend/' + retailOid, '',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }


  public changeUserStatus(token, serviceId: string, status: string): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'admin/api/users/' + serviceId + '/' + status, '',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public activateApiUser(token, personId: string): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'admin/add/api/users/' + personId, '',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public turnSwiftToBucket(token, trackNo: string): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'admin/swift/to/dash/' + trackNo, '',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getAllDrops(token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'drops',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public sendBulkSms(token, content): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'parties/bulk/sms', content,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public sendBulkSMSByNumber(token, content) {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'parties/custom/sms', content,
      {
        headers: new HttpHeaders()
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getAllBookings(token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'drops/all/bookings',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getAllCutomerBookings(token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'drops/all/bookings/ops',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getAllBookingsOprFiltered(token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'drops/all/bookings',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getPaginatedBookings(token, filter): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/all/bookings/filter', filter,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getAllRetailBookings(token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'drops/all/retail/bookings',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getExceptionBookings(token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'drops/all/bookings/status',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getTenderBookings(token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'bookings/all/tender',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getRetailBookingsByDate(token, data): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'drops/filter/retail/bookings', data,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getUberBookings(token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'bookings/uber',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public createInvoice(token, invoiceData): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'parties/invoice', invoiceData,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }


  public getCompanyInfor(token, owneroid): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'parties/company/invoice/list/', owneroid,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }


  public getVehicleRates(token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'vehicles/vehicleTypeRate/all',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public addTypeRate(token, typeRate): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'vehicles/vehicleTypeRate', typeRate,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public updateTypeRate(token, typeRate): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'vehicles/update', typeRate,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public addCategory(token, category): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'goods/categories', category,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getWeighting(token, code): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'goods/categories/filters/' + code,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getUserByNumber(mobile, token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'parties/person/filters/' + mobile,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getDriverByNumber(mobile, token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'parties/drivers/mobile/filter/' + mobile,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getCompanyByNumber(regNumber, token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'parties/companies/' + regNumber + '/filters',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }


  public searchCompanyByMobile(ownerMobile, token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'parties/companies/filters/' + ownerMobile,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public addItems(token, item): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'goods/categories/items', item,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public updateTDiskDate(token, vehicleOid, diskDate): Observable<any> {

    var data = {
      "vehicleOid": vehicleOid,
      "date": diskDate
    }

    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'vehicles/update/licence/disk', data,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public updateWeighting(token, code, updateCategoryRate): Observable<any> {
    var userToken = 'Bearer ' + token;
    var fieldName = 'categoryRate';
    return this.httpClient.get(this.sharedService.getUrl() + 'goods/weightings/update/' + code + '/' + fieldName + '/' + updateCategoryRate,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getAllDocument(token, personOid): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'parties/persons/document/all/' + personOid,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getRoles(token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'admin/roles',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getUserByEmail(token, email): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'admin/accounts/' + email,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getUserByAvator(token, oid): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'parties/persons/avatars/' + oid,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public addRole(token, userOid, roleOid): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'admin/accounts/' + userOid + '/roles/' + roleOid, '',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public blockUser(token, email): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'admin/block/' + email, '',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }


  public blockUserMessege(token, data): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'admin/block', data,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public activateUser(token, email): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'admin/activate/' + email, '',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getDriver(token, oid): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'parties/drivers/' + oid,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public cancelBooking(token, obj): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'drops/book/change/status', obj,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getDriverByMobile(token, mobile): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'parties/drivers/mobile/filter/' + mobile,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
      });
  }

  public assignDriverBooking(token, obj): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/control/status', obj,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
      });
  }

  public assignDriverToBucketBooking(token, bucketOID, driverOID): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/buckets/accept/' + bucketOID + '/' + driverOID, '',
      {
        headers: new HttpHeaders()
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getSpreadSheets(token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'bookings/spreadsheets',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getSpreadSheetsFiltered(token, filter): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/spreadsheets/filtered', filter,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public updateVehicle(token, vehicleOid, vehicleType): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'vehicles/update/' + vehicleOid + '/' + vehicleType, '',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public updateVehicleFromAdminPortal(token: string, bookingOid: string, vehicleType: string) {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'drops/bookings/vehicleType/update/' + bookingOid + '/' + vehicleType, '',
      {
        headers: new HttpHeaders()
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public updateVehicleReg(token, vehicleOid, vehicleReg): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'vehicles/update/' + vehicleOid + '/reg/' + vehicleReg, '',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public completeBooking(token, bookingOid): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'drops/book/complete/' + bookingOid, '',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public rebook(bookingOid: string, token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'drops/recreate/booking/' + bookingOid, '',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public vehicleTypeRates(): Observable<any> {
    return this.httpClient.get(this.sharedService.getUrl() + 'vehicles/vehicleTypeRate/all',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
      });
  }

  public book(bookData: any, token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'drops/bookings/' + bookData.oid, '',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
      });
  }

  public bankAccount(bankData: any, token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'parties/companies/banks', bankData,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public updateBankAccount(bankData: any, bankID, token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.put(this.sharedService.getUrl() + 'parties/companies/banks/updates/' + bankID, bankData,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getBankAccounts(token: string): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'parties/companies/banks',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public retriveBooking(token, userOid): Observable<any> {

    var userToken = "Bearer " + token;
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Authorization", userToken);
    let options = new RequestOptions({headers: headers});
    return this.http.get(this.sharedService.getUrl() + "drops/bookings/all/" + userOid, options);
  }

  public getUnPayedBooking(token: string): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'drops/bookings/all/unpaid',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getBundleBookingPayment(token: string): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'bookings/bundle/bookings/payment',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getBundleBookingByCompanies(token: string): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'bookings/companies/payments',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getCompanyBookings(token: string, companyOid: string): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'bookings/companies/' + companyOid + '/unpaid',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getUpdatedBookings(token: string, driverOid: string): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'bookings/drivers/unpayed/' + driverOid,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getUnPayedBucket(token: string): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'drops/bookings/unpaid',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getBucketBooking(token: string): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'bookings/buckets/unpaid',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }


  public removeBookings(token: string): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'drops/bookings/remove', '',
      {
        headers: new HttpHeaders()
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public updatePayedBookings(token: string, bookingOid: string): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'drops/bookings/payed/' + bookingOid, '',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public updateBookingsPayments(token: string, bookingDetails: any): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'drops/bookings/payments/confirmations', bookingDetails,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public updatePayedBucketBookings(token: string, bookingOid: string): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/buckets/payed/' + bookingOid, '',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public runPaymentSync(token: string, dates: any): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/payments', dates,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getBankAccount(token: string, companyId): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'parties/companies/banks/' + companyId,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public updateDriver(location: string, driverOid: string, token: string): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'parties/driver/location/' + driverOid + '/' + location, '',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getDriversBooking(driverOid: string, token: string): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'drops/bookings/driver/' + driverOid,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getOwner(token: string, ownerId: string): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'parties/persons/' + ownerId,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public updateBooking(updateObj: any, token: string): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'drops/bookings/reschedule', updateObj,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public sendDriversInvoice(token: string, invoiceObject: any): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/drivers/invoices', invoiceObject,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      }
    );
  }

  public getRetailByOwner(token: string, mobile: string): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'parties/retails/owner/mobile/' + mobile,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getAllBucketBooking(token: string): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'bookings/buckets',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getAllPaginationBucketBooking(token: string, filter): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/all/buckets/bookings/filter', filter,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public createEmptyBucket(token: string): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/empty/buckets', "",
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }


  public getTotalAllBookings(token: string): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'bookings/matrix',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getAllRentalStats(token: string): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'rentals/stats',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }


  public assignToRetail(token: string, driverOid: string, retailOid: string): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'parties/retails/add/driver/' + retailOid + '/' + driverOid,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public downloadBookingSpreadsheet(token) {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'bookings/download/spreadsheet',
      {
        headers: new HttpHeaders()
          .set('Authorization', userToken),
        responseType: 'blob'
      });
  }

  public addProvince(token: string, driverOid: string, province: string): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'parties/drivers/province/' + driverOid + '/' + province, '',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public updateProvince(token: string, bookingOid: string, province: string): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'drops/bookings/province/' + bookingOid + '/' + province, '',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      }
    );
  }

  public updateBucketProvince(token: string, bookingOid: string, province: string): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/buckets/updates/' + bookingOid + '/' + province, '',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      }
    );
  }

  public sendRetailInvoice(token, retailOid): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/retail/invoices/' + retailOid, '',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public testData(token, serviceId, data): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'admin/api/test/credentials/' + serviceId, data,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }


  public copyBookings(token, newRetailOwnerMobile, retailOid): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'parties/retail/' + retailOid + '/to/' + newRetailOwnerMobile, '',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public sendRetailInvoiceByDate(token, sendInvoice): Observable<any> {
    var usertoken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/retail/range/invoices/', sendInvoice,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', usertoken)
      });
  }

  public sendMessage(token, smsBody): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'parties/custom/message/', smsBody,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
      });
  }

  public updateMessage(token, smsBody): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'parties/custom/message/update/', smsBody,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
      });
  }

  public getMessages(token, type): Observable<any> {
    var usertoken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'parties/custom/message/' + type,
      {
        headers: new HttpHeaders()
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json')
          .set('Authorization', usertoken)
      });
  }

  public completeBucketBookings(token, bucketOid): Observable<any> {
    var adminToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/buckets/complete/admin/' + bucketOid, '',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', adminToken)
      });
  }

  public updateBucketBookingDateAndTime(token, bucketObj): Observable<any> {
    var adminToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/bucket/updates/dates', bucketObj,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', adminToken)
      });
  }

  public updateBucketBookingPrice(token, bucketObj): Observable<any> {
    var adminToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/bucket/updates/prices', bucketObj,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', adminToken)
      });
  }

  public trackDriver(token): Observable<any> {
    var usertoken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'parties/drivers/locations',
      {
        headers: new HttpHeaders()
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json')
          .set('Authorization', usertoken)
      });
  }

  public trackDriverFiltered(token, filter): Observable<any> {
    var usertoken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'parties/drivers/locations/filtered', filter,
      {
        headers: new HttpHeaders()
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json')
          .set('Authorization', usertoken)
      });
  }

  public cancelBucketBooking(token, bucketOid): Observable<any> {
    var adminToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/buckets/cancel/' + bucketOid, '',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', adminToken)
      });
  }

  // Bill Instant Service
  public downloadInvoice(userToken, invoiceData) {
    const token = 'Bearer ' + userToken;
    return this.httpClient.post(this.sharedService.getUrl() + 'parties/manual/invoice', invoiceData,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', token)
      });
  }

  public sendStatement(token, retailOid) {
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);
    let options = new RequestOptions({headers: headers});
    return this.http.post(this.sharedService.getUrl() + 'bookings/invoices/statement/' + retailOid, '', options);
  }

  public sendStatementByDate(token, stateBody): Observable<any> {
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);
    let options = new RequestOptions({headers: headers});
    return this.http.post(this.sharedService.getUrl() + 'bookings/invoices/ranges/statement', stateBody, options);
  }

  public getInvoices(token): Observable<any> {
    var usertoken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'bookings/invoices',
      {
        headers: new HttpHeaders()
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json')
          .set('Authorization', usertoken)
      });
  }

  public updateInvoice(token, invoiceBody) {
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);
    const options = new RequestOptions({headers: headers});
    return this.http.post(this.sharedService.getUrl() + 'bookings/update/invoices/', invoiceBody, options);
  }

  public recreateBucketBooking(token, bucketOid): Observable<any> {
    var adminToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/buckets/recreate/' + bucketOid, '',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', adminToken)
      });
  }

  public getDriverRatings(token): Observable<any> {
    var usertoken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'parties/realtime/drivers/ratings',
      {
        headers: new HttpHeaders()
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json')
          .set('Authorization', usertoken)
      });
  }

  public getDriverRatingsFiltered(token, filter): Observable<any> {
    var usertoken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'parties/realtime/drivers/ratings/filtered', filter,
      {
        headers: new HttpHeaders()
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json')
          .set('Authorization', usertoken)
      });
  }

  public suspendDriverAccounts(token): Observable<any> {
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);
    const options = new RequestOptions({headers: headers});
    return this.http.post(this.sharedService.getUrl() + 'parties/suspend/drivers', '', options);
  }

  public AddVATNumber(token, retailOid, vatNo): Observable<any> {
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);
    const options = new RequestOptions({headers: headers});
    return this.http.post(this.sharedService.getUrl() + 'parties/retails/vatNo/update/' + retailOid + '/' + vatNo, '', options);
  }

  public sendInvoiceByDate(token, invoiceBody): Observable<any> {
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);
    const options = new RequestOptions({headers: headers});
    return this.http.post(this.sharedService.getUrl() + 'parties/retail/invoicing/dates', invoiceBody, options);
  }

  public deleteDuplicateDrivers(token, oid): Observable<any> {
    var userToken = 'Bearer ' + token;
    const header: HttpHeaders = new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken);
    const httpOptions = {
      headers: header
    };
    return this.httpClient.delete(this.sharedService.getUrl() + 'parties/driver/location/' + oid, httpOptions);
  }

  public updateCompanyProvice(token, companyOid, province): Observable<any> {
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);
    const options = new RequestOptions({headers: headers});
    return this.http.post(this.sharedService.getUrl() + 'parties/company/' + companyOid + '/update/' + province, '', options);
  }

  public getExpressBookings(token): Observable<any> {
    var usertoken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'bookings/express/elements',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', usertoken)
      });
  }

  public getExpressBookingsPagination(token, filter): Observable<any> {
    var usertoken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/express/elements/filter', filter,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', usertoken)
      });
  }


  public retailChangeVehicleType(token, vehicleType, bookingOid): Observable<any> {
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);
    const options = new RequestOptions({headers: headers});
    return this.http.post(this.sharedService.getUrl() + 'bookings/change/' + vehicleType + '/' + bookingOid, '', options);
  }

  public moveBookingFromBucket(token, bucketOid, bookingOid): Observable<any> {
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);
    const options = new RequestOptions({headers: headers});
    return this.http.post(this.sharedService.getUrl() + 'bookings/add/express/' + bookingOid + '/' + bucketOid, '', options);
  }

  public moveBookingToExpress(token, bookingOid, bucketOid): Observable<any> {
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);
    const options = new RequestOptions({headers: headers});
    return this.http.post(this.sharedService.getUrl() + 'bookings/move/to/express/' + bookingOid + '/' + bucketOid, '', options);
  }

  public moveToSkynet(token, bookingObj, bucketOid): Observable<any> {
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);
    const options = new RequestOptions({headers: headers});
    return this.http.post(this.sharedService.getUrl() + 'bookings/create/waybill', bookingObj, options);
  }

  public skynetRetail(token, skynetRetailBody): Observable<any> {
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);
    const options = new RequestOptions({headers: headers});
    return this.http.post(this.sharedService.getUrl() + "parties/retails/tags", skynetRetailBody, options)
  }

  public skynetDriver(token, driverOID, skynet): Observable<any> {
    var skynetDriverBody = {
      "targetID": driverOID,
      "tag": skynet
    }
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);
    const options = new RequestOptions({headers: headers});
    return this.http.post(this.sharedService.getUrl() + "parties/drivers/retails/tags", skynetDriverBody, options)
  }

  public captureWarehouses(token, warehouesBody): Observable<any> {
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);
    const options = new RequestOptions({headers: headers});
    return this.http.post(this.sharedService.getUrl() + "parties/warehouses", warehouesBody, options)
  }

  public getPOI(token): Observable<any> {
    var usertoken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'parties/point/of/interest',
      {
        headers: new HttpHeaders()
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json')
          .set('Authorization', usertoken)
      });
  }

  public postalCodeCheck(postalCode): Observable<any> {
    return this.httpClient.get(this.sharedService.getUrl() + 'parties/suburb/' + postalCode,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
      })
  }

  public addPOI(token, poiBody): Observable<any> {
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);
    const options = new RequestOptions({headers: headers});
    return this.http.post(this.sharedService.getUrl() + "parties/point/of/interest", poiBody, options)
  }

  public retrieveWayBillStatus(token, waybillNum): Observable<any> {
    var usertoken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'bookings/track/skynet/' + waybillNum,
      {
        headers: new HttpHeaders()
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json')
          .set('Authorization', usertoken)
      });
  }

  public getBookingByTrackNumber(trackNumber): Observable<any> {
    return this.httpClient.get(this.sharedService.getUrl() + 'sessions/track/booking/' + trackNumber,
      {
        headers: new HttpHeaders()
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json')
      });
  }

  public getAllWalletTransactions(token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'parties/wallets',
      {
        headers: new HttpHeaders()
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getAllWalletTransactionsFiltered(token, filter): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'parties/wallets/filtered', filter,
      {
        headers: new HttpHeaders()
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getAllWalletTransactionsDumps(token, date): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'parties/wallets',
      {
        headers: new HttpHeaders()
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getTransactions(token, walletID): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'parties/wallets/transactions/' + walletID,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
      })
  }

  public retailTag(token, tagBody): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'parties/update/retails/invoice/auth', tagBody,
      {
        headers: new HttpHeaders()
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public completeLegFlight(token, tagBody): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/air/element/completed', tagBody,
      {
        headers: new HttpHeaders()
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public updateWallet(token, walletBody): Observable<any> {
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Authorization", userToken);
    let options = new RequestOptions({headers: headers});
    return this.http.post(this.sharedService.getUrl() + 'parties/wallet/update', walletBody, options);
  }

  public invoiceCustomer(token, trackNo): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/tax/invoice/' + trackNo, '',
      {
        headers: new HttpHeaders()
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      }
    )
  }

  public changeFromRetailToParter(token, accountOid): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'admin/change/retail/role/' + accountOid, '',
      {
        headers: new HttpHeaders()
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      }
    )
  }

  public captureAddressOnRetail(token, retailOid, addressBody): Observable<any> {
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Authorization", userToken);
    let options = new RequestOptions({headers: headers});
    return this.http.post(this.sharedService.getUrl() + 'parties/retails/' + retailOid + '/address', addressBody, options);
  }


  public retailItemsTag(token, tagBody): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'parties/update/retails/add/parcels/auth', tagBody,
      {
        headers: new HttpHeaders()
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public downloadBookingDumps(token, dateBody): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'dumps/bookings', dateBody,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  public downloadBookingReportsDumps(token, dateBody): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'dumps/bookings/reports', dateBody,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  public downloadBucketDumps(token, dateBody): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'dumps/bucket/bookings', dateBody,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  public downloadDriverDumps(token, dateBody): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'dumps/drivers', dateBody,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  public downloadFinanceReportDumps(token, dateBody): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'dumps/finance/bookings/report', dateBody,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  public downloadPersonsDumps(token, dateBody): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'dumps/persons', dateBody,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  public downloadRetailDumps(token, dateBody): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'dumps/retails', dateBody,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }


  public downloadWalletDumps(token, dumbBody): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'dumps/user/download-transactions', dumbBody,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  public downloadCourierDumps(token, dateBody): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'dumps/courier/bookings', dateBody,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  public createSpendingReport(token, dateBody, type): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'dumps/spending/' + type, dateBody,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  public createRetailSpendingDumps(token, dateBody): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'dumps/retail/spent', dateBody,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  public createIndividualSpendingDumps(token, dateBody): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'dumps/clients/spending', dateBody,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  public createRegisteredIndividualSpendingReport(token, dateBody): Observable<any> {
    const userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'dumps/new/clients/spending', dateBody,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: userToken
        }
      }
    );
  }

  public createRegisteredBusinessSpendingReport(token, dateBody): Observable<any> {
    const userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'dumps/new/retail/spending', dateBody,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: userToken
        }
      }
    );
  }

  public getPaymentDumps(token, dumpBody): Observable<any> {
    const userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'dumps/export/all/payments/merchants', dumpBody,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: userToken
        }
      }
    );
  }

  public downloadRetailSpendingDumps(token, fileName): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'dumps/download/retail/spendind/' + fileName,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  // Call this service on the outstanding page
  public outstandingRetailInvoices(token, dateBody): Observable<any> {
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Authorization", userToken);
    let options = new RequestOptions({headers: headers});
    return this.http.post(this.sharedService.getUrl() + 'bookings/buckets/payments', dateBody, options);
  }

  public assignAccountManager(token, retailOid, oid): Observable<any> {
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Authorization", userToken);
    let options = new RequestOptions({headers: headers});
    return this.http.post(this.sharedService.getUrl() + 'parties/retail/account/' + oid + '/manager/' + retailOid, '', options);
  }

  public assingStoreName(token, oid, storeName) {
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Authorization", userToken);
    let options = new RequestOptions({headers: headers});
    return this.http.post(this.sharedService.getUrl() + 'admin/update/store/' + oid + '/name/' + storeName, '', options);
  }

  public addEventWebHook(token, webhook) {
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Authorization", userToken);
    let options = new RequestOptions({headers: headers});
    return this.http.post(this.sharedService.getUrl() + 'admin/add/event/webhook', webhook, options);
  }

  // Elite Service
  public getAllRentals(token, filter): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'rentals/filter', filter, {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)});
  }

  public saveEliteVehicles(vehicleTypes): Observable<any> {
    return this.httpClient.post(this.sharedService.getUrl() + 'rentals', vehicleTypes, {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')})
  }

  public getEliteBranches(): Observable<any> {
    return this.httpClient.get(this.sharedService.getUrl() + 'rentals/branch', {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')});
  }

  public saveEliteBranches(branch): Observable<any> {
    return this.httpClient.post(this.sharedService.getUrl() + 'rentals/branch', branch, {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')});
  }

  public getEliteBranchesByProvince(province): Observable<any> {
    return this.httpClient.get(this.sharedService.getUrl() + 'rentals/branch/' + province, {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')});
  }

  // Returns rentals made using the branch (EAST RAND rentals, WEST RAND rental etc etc...)
  public getEliteBranchesByCategory(token, branchOid): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'rentals/by/category/' + branchOid, {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)});
  }

  public getEliteVehicleByCustomer(customerOid): Observable<any> {
    return this.httpClient.get(this.sharedService.getUrl() + 'rentals/by/customer/' + customerOid, {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')});
  }

  public getEliteVehicleByUnit(unit): Observable<any> {
    return this.httpClient.get(this.sharedService.getUrl() + 'rentals/by/units/' + unit, {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')});
  }

  public getEliteQuotes(): Observable<any> {
    return this.httpClient.get(this.sharedService.getUrl() + 'rentals/quotes', {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')});
  }

  public getEliteRates(): Observable<any> {
    return this.httpClient.get(this.sharedService.getUrl() + 'rentals/rates', {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')});
  }

  public saveEliteRates(token, body): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'rentals/rates', body, {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)});
  }

  public getEliteRatesByCategoryName(token, categoryName): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'rentals/rates/' + categoryName, {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)});
  }

  public pushEliteRental(token, rentalOid): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'rentals/push/' + rentalOid, '', {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)});
  }

  // Elite Service

  public getVodacomPromoCodes(token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'bookings/promotion', {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)});
  }

  public saveVodacomPromoCodes(token, promoBody): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/promotion', promoBody, {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)});
  }

  public updateVodacomPromoCodes(token, code, status): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/promotion/' + code + '/' + status, '', {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)});
  }

  public getShortFalls(token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'bookings/shortfalls', {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)});
  }

  public getShortFallsFiltered(token, filter): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/shortfalls/filtered', filter, {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)});
  }

  public getShortFallWaybillEvents(token, waybill): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'bookings/event/' + waybill, {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)});
  }

  // OPS services
  //done
  public changeUserMobileNum(token, userOid, userNumber): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'parties/' + userOid + '/update/' + userNumber, '', {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)});
  }

  //done
  public updateUserEmail(token, userOid, userEmail): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'parties/' + userOid + '/update/email', userEmail, {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)});
  }

  //done
  public removeDriverFromVehicle(token, driverOid): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'parties/remove/driver/' + driverOid, '', {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)});
  }

  //done
  public removeVehicleFromCompany(token, vehicleOid, companyOid): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'parties/remove/' + vehicleOid + '/' + companyOid, '', {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)});
  }

  //done
  public enableUserAccount(token, accountOid): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'admin/enable/' + accountOid, '', {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)});
  }

  //done
  public updateBookingPrice(token, bookingOid, newPrice): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/' + bookingOid + '/updates/price', newPrice, {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)});
  }

  // OPS services

  public createCSVBooking(token, ownerOid, bookingData): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/upload/csv/' + ownerOid, bookingData, {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)});
  }

  public createCSVQuotes(token, ownerOid, bookingData, serviceProvicer, serviceType): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/upload/csv/quotes/' + serviceProvicer + '/' + serviceType, bookingData, {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)});
  }

  public downloadCsvQuotes(createdQuotes, token) {
    const userToken = 'Bearer ' + token;
    return this.httpClient.post(`${this.sharedService.getUrl()}bookings/download/csv/quotes`, createdQuotes, {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)});
  }

  public createCSVQuotesBooking(token, ownerOid, bookingData): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/upload/quotes/csv/' + ownerOid, bookingData, {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)});
  }

  public validateCSVBooking(token, bookingData): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/varify/bulk/booking', bookingData, {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)});
  }

  public downloadTemplate(token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'bookings/template/bulk/booking', {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)});
  }

  public listAvailPaymentOptions(token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'admin/payment/options', {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)});
  }

  public addPaymentType(token, paymentDTO): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'admin/payment/options', paymentDTO, {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)});
  }

  public updatePaymentType(token, updatePaymentDTO): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.put(this.sharedService.getUrl() + 'admin/payment/options', updatePaymentDTO, {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)});
  }

  public getUserDetails(token, username): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'sessions/user/account/' + username, {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)});
  }

  public addRetailBranch(token, retailOid, retailBranchDTO): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'parties/retails/' + retailOid + '/branch', retailBranchDTO, {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)});
  }

  public changeCircleRate(token, retailOid): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'parties/change/route/optimize/' + retailOid, '', {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)});
  }

  public deleteBranch(token, retailOid, branchName): Observable<any> {
    var userToken = 'Bearer ' + token;
    const header: HttpHeaders = new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken);
    const httpOptions = {headers: header};
    return this.httpClient.delete(this.sharedService.getUrl() + 'parties/retails/' + retailOid + '/' + branchName, httpOptions);
  }

  public moveElementToBooking(token, newBucketOid, elementTrackNo): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/remove/' + newBucketOid + '/' + elementTrackNo, '', {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    });
  }

  public deleteBooking(token, bookingObj, bucketOid): Observable<any> {
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);
    const options = new RequestOptions({headers: headers});
    return this.http.post(this.sharedService.getUrl() + 'bookings/delete/elements', bookingObj, options);
  }

  public getPromoCodes(token): Observable<any> {
    var userToken = "Bearer " + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'bookings/promotion', {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    });
  }

  public getReferralCodes(token): Observable<any> {
    var userToken = "Bearer " + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'bookings/list/referral/codes', {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    });
  }

  public addPromoCode(token, promotionCodeDTO): Observable<any> {
    var userToken = "Bearer " + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/promotion', promotionCodeDTO, {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    });
  }

  public addReferralCode(token, referralCodeDTO): Observable<any> {
    var userToken = "Bearer " + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/create/referral/Code', referralCodeDTO, {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    });
  }

  public getPromoCodeByStatus(token, promoCode): Observable<any> {
    var userToken = "Bearer " + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'bookings/promotion/' + promoCode, {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    })
  }

  public updatePromoCode(token, promoCode, promoStatus, promoType): Observable<any> {
    var userToken = "Bearer " + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/promotion/' + promoCode + '/' + promoStatus + '/' + promoType, '', {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    })
  }

  public updateReferralCode(token, promoCode, promoStatus): Observable<any> {
    var userToken = "Bearer " + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/referral/Code/status/' + promoCode + '/' + promoStatus, '', {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    })
  }

  public standardBankService(token, PaymentDateDTO): Observable<any> {
    var userToken = "Bearer " + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/standard/bank/payments', PaymentDateDTO, {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    })
  }

  public getAdminActivities(token): any {
    var userToken = "Bearer " + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'bookings/status/changes', {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    })
  }

  public getAdminActivitiesFilter(token, filter): any {
    var userToken = "Bearer " + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/status/changes/filter', filter, {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    })
  }

  public getServerTime(token): any {
    var userToken = "Bearer " + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'drops/get/date/today', {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    });
  }

  public allowRetailPromo(token, promoCode, promoStatus, retailId, promoType): any {
    console.log(retailId.toString());
    const userToken = "Bearer " + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/retail/promotion/' + promoCode + '/' + promoStatus + '/' + retailId + '/' + promoType, retailId, {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    });
  }

  public createRetailPromo(token, promotion, retailId): any {
    const userToken = "Bearer " + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/promotion/retail/' + retailId, promotion, {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    });
  }

  public getRetailPromo(token, retailOid) {
    const userToken = "Bearer " + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'bookings/get/Retail/promotion/' + retailOid, {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    });
  }

  public canAssignOwnDriver(token, retailOid) {
    const userToken = "Bearer " + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'admin/assign/drive/role/' + retailOid, '', {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    });
  }

  public addUpdateWayBill(token, trackNumber, waybillNumber) {
    const userToken = "Bearer " + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'drops/manual/waybill/' + trackNumber + '/' + waybillNumber, '', {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    });
  }

  public updateWaybillTrackingStatus(token, waybillNumber) {
    const userToken = "Bearer " + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/buckets/track/manual/waybills/' + waybillNumber, '', {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    });
  }

  public changeShortfallStatus(token, bookingOid) {
    const userToken = "Bearer " + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/shortfall/status/change/' + bookingOid, '', {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    });
  }

  public changeBookingStatus(token, bookingData) {
    const userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/control/status', bookingData, {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    });
  }

  public changeBookingIsItemPickedStatus(token, bookingID) {
    const userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/element/collected/' + bookingID, '', {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    });
  }

  public changeBucketPaymentType(token, paymentType, bookingOid) {
    const userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/change/payment/' + paymentType + '/' + bookingOid, '', {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    });
  }

  public changeBucketStatus(token, status: any, trackNo: any): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/change/buckets/' + trackNo + '/status/' + status, '',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public addRetailPaymentTerms(token, paymentTerm: any): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'admin/payment/term', paymentTerm,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getTerminalCount() {
    return this.httpClient.get(this.sharedService.getUrl() + 'pudo/terminals/count', {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
    });
  }

  public changeRetailPriority(token, changePriority: any): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'admin/change/retail/priority', changePriority,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getIndividualPriorityBookings(token) {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'admin/priority/bookings', {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    });
  }

  public getBusinessPriorityBookings(token) {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'admin/priority/buckets', {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    });
  }

  getRetailDiscountsRates(id: any, token): Observable<any> {
    const userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'admin/discount/rates/' + id, {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    });
  }

  createDiscount(token, discountForm: any): Observable<any> {
    const userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'admin/create/discount/rates', discountForm, {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    });
  }

  getCarriers(token): Observable<any> {
    const userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'admin/all/integrated/company/', {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    });
  }

  createConfiguration(token, data: any): Observable<any> {
    const userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'admin/create/integrated/company', data, {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    });
  }

  updateConfiguration(token, data: any, companyId: string): Observable<any> {
    const userToken = 'Bearer ' + token;
    return this.httpClient.put(this.sharedService.getUrl() + 'admin/update/integrated/company/' + companyId, data, {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    });
  }

  downloadMarketingSubscribers(token: string, filter: any): Observable<any> {
   const userToken = 'Bearer ' + token;
   return this.httpClient.post( this.sharedService.getUrl() + 'dumps/export/all/subscribes', filter, {
     headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
   });
  }

  downloadF8HUBReport( token: string, filter: any): Observable<any>  {
    const userToken = 'Bearer ' + token;
    return this.httpClient.post( this.sharedService.getUrl() + 'dumps/export/sub/retail/profit', filter, {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    });
  }
}
