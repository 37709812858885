import { DatePipe } from '@angular/common';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Parcel } from 'src/app/models/parcel';
import { BookingService } from 'src/app/services/booking.service';
import { SharedService } from 'src/app/services/shared.service';
import * as firebase from "firebase";
import { LoaderService } from 'src/app/services/loader.service';
declare var $: any;

@Component({
  selector: 'app-confirm-single-courier',
  templateUrl: './confirm-single-courier.component.html',
  styleUrls: ['./confirm-single-courier.component.css']
})
export class ConfirmSingleCourierComponent implements OnInit {

  @ViewChild('m_id', { static: false }) merchant_id: ElementRef;
  @ViewChild('m_key', { static: false }) merchant_key : ElementRef;
  @ViewChild('return_url', { static: false }) return_url : ElementRef;
  @ViewChild('cancel_url', { static: false }) cancel_url : ElementRef;
  @ViewChild('notify_url', { static: false }) notify_url : ElementRef;
  @ViewChild('name_first', { static: false }) name_first : ElementRef;
  @ViewChild('name_last', { static: false }) name_last : ElementRef;
  @ViewChild('email_address', { static: false }) email_address : ElementRef;
  @ViewChild('cell_number', { static: false }) cell_number : ElementRef;
  @ViewChild('m_payment_id', { static: false }) m_payment_id : ElementRef;
  @ViewChild('amount', { static: false }) amount : ElementRef;
  @ViewChild('item_name', { static: false }) item_name : ElementRef;
  @ViewChild('item_description', { static: false }) item_description : ElementRef;
  @ViewChild('custom_int1', { static: false }) custom_int1 : ElementRef;
  @ViewChild('confirmation_address', { static: false }) confirmation_address : ElementRef;

  @ViewChild('submitButton', { static: false }) submitButton: ElementRef;

  // Ozow Payment Elements
  @ViewChild('SiteCode', { static: false}) SiteCode: ElementRef;
  @ViewChild('CountryCode', { static: false}) CountryCode: ElementRef;
  @ViewChild('CurrencyCode', { static: false}) CurrencyCode: ElementRef;
  @ViewChild('Amount', { static: false}) Amount: ElementRef;
  @ViewChild('TransactionReference', { static: false}) TransactionReference: ElementRef;
  @ViewChild('BankReference', { static: false}) BankReference: ElementRef;
  @ViewChild('Customer', { static: false }) Customer: ElementRef;
  @ViewChild('Optional1', { static: false }) Optional1: ElementRef;
  @ViewChild('CancelUrl', { static: false}) CancelUrl: ElementRef;
  @ViewChild('ErrorUrl', { static: false}) ErrorUrl: ElementRef;
  @ViewChild('SuccessUrl', { static: false}) SuccessUrl: ElementRef;
  @ViewChild('NotifyUrl', { static: false}) NotifyUrl: ElementRef;
  @ViewChild('IsTest', { static: false}) IsTest: ElementRef;
  @ViewChild('HashCheck', { static: false}) HashCheck: ElementRef;

  user: any;
  retail: any;
  bookingData: any;
  bucketBooking: any;
  vehicle: string;
  booking: any = {};
  bookingTime: any;
  bookingDate: any;
  comment: string;
  noBookings: number = 1;
  firstName: string;
  phone: string;
  mobile: any;
  dropContactFirstname: any;
  dropContactPhone: any;
  pickUpAddress: string;
  dropOffAddress: string;
  tempAmount: number;
  firstDropPrice: number;
  bucketStatus: string;
  loader: boolean = false;
  message: string;
  numberOfLabours: number = 0;
  isBalancePositive: boolean = false;
  bucketBookingPrice: number;
  bookingObj: any;
  payFastResponse: any;
  url: string;
  ozowUrl: string;
  pracels: Parcel[] = [];
  pracel: Parcel;
  breadthMsg: string;
  massMsg: string;
  heightMsg: string;
  lengthMsg: string;
  canUseCard: any;
  canAddParcels: any;

  bookingType: string;

  promoCode = '';
  promoCodeMsg = '';
  promoCodeValid: boolean;
  promoCodeAmount = 0;

  switchPromoBtn = false;

  checkPayFast = false;
  checkOzow = false;
  checkWallet = false;
  checkPaygate = false;

  constructor(private bookService: BookingService, private router: Router, private sharedService: SharedService,private loadservice:LoaderService) {

    this.url = this.sharedService.getPayFastUrl();
    this.ozowUrl = this.sharedService.getOzowUrl();

  }

  ngOnInit() {
    this.vehicle = localStorage.getItem('vehicleType');
    this.user = JSON.parse(localStorage.getItem('userInfo'));
    this.retail = JSON.parse(localStorage.getItem('retail'));
    this.bookingObj = JSON.parse(localStorage.getItem('bookingObj'));
    // console.log(this.bookingObj);
    this.canUseCard = this.retail.canInvoice;
    this.bucketStatus = this.bookingObj.status;
    this.pickUpAddress = this.bookingObj.pickUpAddress;
    this.firstName = this.bookingObj.pickUp.firstName;
    this.phone = this.bookingObj.pickUp.phone;
    this.bookingDate = this.bookingObj.pickUpDate;
    this.bookingTime = this.bookingObj.pickUpTime;
    this.dropOffAddress = this.bookingObj.dropOffAddress;
    this.dropContactFirstname = this.bookingObj.dropOff.firstName;
    this.mobile = this.bookingObj.dropOff.phone;
    this.tempAmount = this.bookingObj.price;
    this.bookingType = this.bookingObj.type;
    this.getPaymentOptions();
  }

  public getPaymentOptions() {
    this.bookService.listAvailPaymentOptions(this.user.token).subscribe(response => {
        response.forEach(element => {
          if (element.optionType == 'PAYFAST') {
            this.checkPayFast = element.isActive;
          }
          else if (element.optionType == 'OZOW') {
            this.checkOzow = element.isActive;
          }
          else if (element.optionType == 'WALLET') {
            this.checkWallet = element.isActive;
          }
          else if (element.optionType == 'PAYGATE') {
            this.checkPaygate = element.isActive;
          }
        });
      },
      error => {console.log(error)}
    );
  }

  public onHome() {
    this.router.navigateByUrl('/retail-profile');
  }

  public backToBooking() {
    if (this.retail.canAddParcels) {
      // if user has a tag
      this.router.navigate(['/create-retail-book']);
    } else {
      // if user has no tag
      this.router.navigate(['/create-retail-booking']);
    }
  }

  promoTargetAmount = 0;
  promoAmount = 0;

  public processPromoCode() {
    this.promoCodeMsg = '';
    if (this.promoCode == null || this.promoCode === '') {
      this.promoCodeMsg = 'Please enter a promo code.';
      // $('#promoActive').modal('show');
    } else {
      const object = JSON.parse(localStorage.getItem('userInfo'));

      const UsePromoCodeDTO = {
        code:	this.promoCode.toUpperCase(),
        promoType: '',
        targetAmount: this.bookingObj.price,
        retailOid: this.retail.oid,
        // bookingOid: this.bookingObj.oid
      };

      if (this.bookingObj.type === 'EXPRESS_COURIER' || this.bookingObj.type === 'BUDGET_COURIER' ) {
        UsePromoCodeDTO.promoType = 'COURIER';
      } else if (this.bookingObj.type === 'FLASH') {
        UsePromoCodeDTO.promoType = 'FLASH';
      }

      // Test code
      /*this.promoCodeAmount = 100;
      this.loadservice.a();
      this.switchPromoBtn = true;
      this.promoCodeValid = true;
      this.promoCodeMsg = 'Promo code of R' + this.promoCodeAmount + ' applied.';*/

      // console.log(UsePromoCodeDTO);

      this.loadservice.addloader();
      this.bookService.checkRetailPromo(object.token, UsePromoCodeDTO).subscribe(
        (response: any) => {
        console.log(response);
        // this.promoTargetAmount = response.targetAmount;
        this.promoAmount = response.amount;
        this.bookingObj.promotionCode = this.promoCode;

        /*if (this.bookingObj.price >= this.promoTargetAmount) {
          this.bookingObj.price = this.bookingObj.price - this.promoAmount;
          // this.tempAmount = this.bookingObj;
        }*/
        // this.promoCodeAmount = response.amount;
        this.loadservice.removeloader();
        // Switch between the blue and red buttons for applying promo
        this.switchPromoBtn = true;

        this.promoCodeValid = true;
        this.promoCodeMsg = 'Promo code of R' + this.promoAmount + ' applied.';
        // $('#promoActive').modal('show');
      }, (error) => {
        // this.bookingObj.promotionCode = 'No promo code';

        this.promoCodeValid = false;
          // console.log(error.error.message);
        this.promoCodeMsg = error.error.message;
        if (this.promoCodeMsg === null || this.promoCodeMsg === undefined) {
          this.promoCodeMsg = 'Promo code does not exist.';
        }
        this.loadservice.removeloader();
        // $('#promoActive').modal('show');
      });
    }
  }

  public removePromoCode() {
    this.promoCodeMsg = '';
    this.switchPromoBtn = false;
    this.promoCode = '';
    this.promoCodeValid = false;
    // this.bookingObj.price = this.bookingObj.price + this.promoAmount;
    this.bookingObj.promotionCode = null;
    this.promoCodeAmount = 0;
  }

  public cancelBacketBook() {
    this.loadservice.addloader();
    this.bookService.cancelBucket(this.user.token, this.booking.oid).subscribe(
      (res) => {
        this.loadservice.removeloader();
        this.message = "Booking cancelled.";
        $('#bookingCancelled').modal('show');
      }, (error) => {
        console.log(error);
        this.loadservice.removeloader();
      }
    )
  }

  walletToHome = false;

  public payWithWallet() {

    var currentDate = new Date();
    let date = currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate();
    let monthNo = (currentDate.getMonth() + 1) <= 9 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
    let hour = currentDate.getHours() <= 9 ? '0' + currentDate.getHours() : currentDate.getHours();
    let minutes = currentDate.getMinutes() <= 9 ? '0' + currentDate.getMinutes() : currentDate.getMinutes();
    var second = currentDate.getSeconds() <= 9 ? '0' + currentDate.getSeconds() : currentDate.getSeconds();
    var dropTime = currentDate.getFullYear() + '-' + monthNo + '-' + date + 'T' + hour + ':' + minutes + ':' + second;

    if (this.promoCodeValid) {
      // this.bookingObj.price = this.bookingObj.price - this.promoAmount;
      this.bookingObj.promotionCode = this.promoCode.toUpperCase();
    }

    var drop = {
      'name': 'Booking',
      'notes': 'be careful',
      'cost': (this.bookingObj.price - this.promoAmount),
      'clientOid': this.user.owner.oid,
      'vehicleOid': '1521105441081',
      'driverOid': '575ae6bd30bc5d5017477143',
      'booking': this.bookingObj,
      'from': {
        'coordinates': [
          '-26.002483',
          '28.099503'
        ],
        'address': this.bookingObj.pickUpAddress,
      },
      'to': {
        'coordinates': [
          '-26.002483',
          '28.099503'
        ],
        'address': this.bookingObj.dropOffAddress,
      },
      'when': dropTime,
      'atp': dropTime,
      'eta': dropTime,
      'ata': dropTime,
      'platform': 'web',
      'droplets': [
        {
          'itemOid': '575ae6bd30bc5d5017477143',
          'notes': 'new'
        }
      ]
    };

    // console.log(drop);

    this.loadservice.addloader();
    this.bookService.checkAvailBalance( this.user.token,
        this.retail.owner.userAccountOid,
        this.bookingObj.price).subscribe(
      response => {
        this.isBalancePositive = response.isBalancePositive;
        if (this.isBalancePositive) {
          // this.updateBookingPrices();
          this.bookService.walletDropRetail(drop, this.user.token)
            .subscribe(
              (res) => {
                this.loadservice.removeloader();
                localStorage.setItem('bookingDataObject', JSON.stringify(drop));
                this.router.navigateByUrl('/retail-successful-wallet-payment/' + this.bookingObj.price + '/send_parcel');
              },
              (error) => {
                console.log(error);
                this.loadservice.removeloader();
                this.router.navigateByUrl('/retail-successful-wallet-payment/' + this.bookingObj.price + '/send_parcel');
                /*this.message = "An error occured whilst making a booking.";
                $('#walletPayment').modal('show');*/
              }
            );
        } else {
          this.loadservice.removeloader();
          this.message = 'Your wallet has insufficient funds. Please credit your wallet to proceed with the transaction.';
          $('#walletPayment').modal('show');
        }
      }, error => {
        this.loadservice.removeloader();
        console.log(error);
      }
    )

  }

  public confirmBooking() {
    let myToken = JSON.parse(localStorage.getItem('userInfo')).token;

    var currentDate = new Date();
    let date = currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate();
    let monthNo = (currentDate.getMonth() + 1) <= 9 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
    let hour = currentDate.getHours() <= 9 ? '0' + currentDate.getHours() : currentDate.getHours();
    let minutes = currentDate.getMinutes() <= 9 ? '0' + currentDate.getMinutes() : currentDate.getMinutes();
    var second = currentDate.getSeconds() <= 9 ? '0' + currentDate.getSeconds() : currentDate.getSeconds();
    var dropTime = currentDate.getFullYear() + '-' + monthNo + '-' + date + 'T' + hour + ':' + minutes + ':' + second;

    if (this.promoCodeValid) {
      // this.bookingObj.price = this.bookingObj.price - this.promoAmount;
      this.bookingObj.promotionCode = this.promoCode.toUpperCase();
    }

    var drop = {
      'name': 'Booking',
      'notes': 'be careful',
      'cost': ((this.bookingObj.price - this.promoAmount) * 100),
      'clientOid': this.user.owner.oid,
      'vehicleOid': '1521105441081',
      'driverOid': '575ae6bd30bc5d5017477143',
      'booking': this.bookingObj,
      'from': {
        'coordinates': [
          '-26.002483',
          '28.099503'
        ],
        'address': this.bookingObj.pickUpAddress,
      },
      'to': {
        'coordinates': [
          '-26.002483',
          '28.099503'
        ],
        'address': this.bookingObj.dropOffAddress,
      },
      'when': dropTime,
      'atp': dropTime,
      'eta': dropTime,
      'ata': dropTime,
      'platform': 'web',
      'droplets': [
        {
          'itemOid': '575ae6bd30bc5d5017477143',
          'notes': 'new'
        }
      ]
    };

    this.bucketBookingPrice = this.tempAmount;
    this.loadservice.addloader();
    // this.updateBookingPrices();
    this.bookService.pushRetailExpressBook(drop, myToken).subscribe(
      (res) => {
        this.bucketBooking = res;
        this.loadservice.removeloader();
        this.message = "Booking successfully created.";
        $('#pushBooking').modal('show');
      },
      (error) => {
        console.log(error);
        this.message = error.error.message;
        this.loadservice.removeloader()
        $('#pushBooking').modal('show');
      }
    );
  }

  public payWithCard(paymentType: string) {
    let userObj = JSON.parse(localStorage.getItem('userInfo'));

    var currentDate = new Date();
    let date = currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate();
    let monthNo = (currentDate.getMonth() + 1) <= 9 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
    let hour = currentDate.getHours() <= 9 ? '0' + currentDate.getHours() : currentDate.getHours();
    let minutes = currentDate.getMinutes() <= 9 ? '0' + currentDate.getMinutes() : currentDate.getMinutes();
    var second = currentDate.getSeconds() <= 9 ? '0' + currentDate.getSeconds() : currentDate.getSeconds();
    var dropTime = currentDate.getFullYear() + '-' + monthNo + '-' + date + 'T' + hour + ':' + minutes + ':' + second;

    if (this.promoCodeValid) {
      // this.bookingObj.price = this.bookingObj.price - this.promoAmount;
      this.bookingObj.promotionCode = this.promoCode.toUpperCase();
    }

    var drop = {
      'name': 'Booking',
      'notes': 'be careful',
      'cost': (this.bookingObj.price - this.promoAmount) * 100,
      'clientOid': this.user.owner.oid,
      'vehicleOid': '1521105441081',
      'driverOid': '575ae6bd30bc5d5017477143',
      'booking': this.bookingObj,
      'from': {
        'coordinates': [
          '-26.002483',
          '28.099503'
        ],
        'address': this.bookingObj.pickUpAddress,
      },
      'to': {
        'coordinates': [
          '-26.002483',
          '28.099503'
        ],
        'address': this.bookingObj.dropOffAddress,
      },
      'when': dropTime,
      'atp': dropTime,
      'eta': dropTime,
      'ata': dropTime,
      'platform': 'web',
      'droplets': [
        {
          'itemOid': '575ae6bd30bc5d5017477143',
          'notes': 'new'
        }
      ]
    };
    this.loadservice.addloader();
    this.bookService.createDrop(drop, userObj.token)
      .subscribe(
        (res) => {
          if (paymentType === 'payfast') {
            var paymentData = {
              "name_first": userObj.owner.firstName,
              "name_last": userObj.owner.surname,
              "email_address": userObj.owner.email,
              "cell_number": userObj.owner.mobile,
              "m_payment_id": res.json().oid,
              "amount": this.tempAmount - this.promoAmount,
              "customerReference": userObj.owner.oid,
              "item_name": 'Droppa Booking',
              "item_description": 'Payment For Delivery',
              "email_confirmation": 0,
              "confirmation_address": userObj.owner.email,
              "reference": res.json().oid,
              "paymentType": "SINGLE_BOOKING_PAYMENT"
            };
            this.bookService.requestBucketPaymentPayFast(paymentData, userObj.token).subscribe(
              (response: Response) => {
                this.loadservice.removeloader();
                this.redirectToPayFast(response.json());
              }, (error) => {
                console.log(error);
                this.loadservice.removeloader();
                this.submitButton.nativeElement.disabled = false;
              }
            );
          } else {
            let ozowObj = {
              "CountryCode": "ZA",
              "CurrencyCode": "ZAR",
              "Amount": this.tempAmount - this.promoAmount,
              "TransactionReference": res.json().oid,
              "BankReference": "",
              "Customer": userObj.owner.email,
              "IsTest": true,
              "paymentType": "SINGLE_BOOKING_PAYMENT"
            };
            this.bookService.requestOzowPayment(ozowObj, userObj.token).subscribe(
              (resp) => {
                this.loadservice.removeloader();
                this.ozowRedirect(resp.json());
              }, (error) => {
                console.log(error);
                this.loadservice.removeloader();
              }
            );
          }
        },
        (error) => {
          console.log(error);
          this.loadservice.removeloader();
          this.submitButton.nativeElement.disabled = false;
        }
      );
  }

  public redirectToPayFast(response) {
    localStorage.setItem('paidByRetail', 'true');
    this.merchant_key.nativeElement
    this.merchant_id.nativeElement.value = response.merchant_id;
    this.merchant_key.nativeElement.value = response.merchant_key;
    this.return_url.nativeElement.value = response.return_url;
    this.cancel_url.nativeElement.value = response.cancel_url;
    this.notify_url.nativeElement.value = response.notify_url;
    this.name_first.nativeElement.value = response.name_first;
    this.name_last.nativeElement.value = response.name_last;
    this.email_address.nativeElement.value = response.email_address;
    this.cell_number.nativeElement.value = response.cell_number;
    this.m_payment_id.nativeElement.value = response.m_payment_id;
    this.amount.nativeElement.value = response.amount;
    this.item_name.nativeElement.value = response.item_name;
    this.item_description.nativeElement.value = response.item_description;
    this.custom_int1.nativeElement.value = response.custom_int1;
    this.confirmation_address.nativeElement.value = response.confirmation_address;
    $("#btnMakePayment1").trigger("click");
  }

  public ozowRedirect(ozowBody): void {
    localStorage.setItem('paidByRetail', 'true');
    this.SiteCode.nativeElement.value = ozowBody.SiteCode;
    this.CountryCode.nativeElement.value = ozowBody.CountryCode;
    this.CurrencyCode.nativeElement.value = ozowBody.CurrencyCode;
    this.Amount.nativeElement.value = ozowBody.Amount.toFixed(2);
    this.TransactionReference.nativeElement.value = ozowBody.TransactionReference;
    this.BankReference.nativeElement.value = ozowBody.BankReference;
    this.Customer.nativeElement.value = ozowBody.Customer;
    this.Optional1.nativeElement.value = ozowBody.Optional1;
    this.CancelUrl.nativeElement.value = ozowBody.CancelUrl;
    this.ErrorUrl.nativeElement.value = ozowBody.ErrorUrl;
    this.SuccessUrl.nativeElement.value = ozowBody.SuccessUrl;
    this.NotifyUrl.nativeElement.value = ozowBody.NotifyUrl;
    this.IsTest.nativeElement.value = ozowBody.IsTest;
    this.HashCheck.nativeElement.value = ozowBody.HashCheck;
    $("#ozowPayment").trigger("click");
  }

}
