import { Component, OnInit ,isDevMode} from '@angular/core';
import { AdminService } from '../../services/admin.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoaderService } from 'src/app/services/loader.service';

declare var $: any;
@Component({
  selector: 'app-view-retail-bookings',
  templateUrl: './view-retail-bookings.component.html',
  styleUrls: ['./view-retail-bookings.component.css']
})
export class ViewRetailBookingsComponent implements OnInit {

  book: any;
  bucketBooking: any = {};
  total: number;
  page: number = 1;
  msg: string;
  today: number = Date.now();
  driver: any;
  provinceUpdate: string;
  message: string;
  foundDriver: any;
  assignStatus = '';
  err: string;
  valid: boolean;
  loader: boolean;
  driverMobile: string;
  bookingId:string;
  bucketMessage: any;
  timeAndDateMessage: any;
  priceMessage: any;
  yesterday = new Date(this.today);
  todayDate = new Date();

  bookingDate: any;
  bookingTime: any;
  bookingPrice: any;
  cancelMessage: any;
  hour: number;
  selectedTabID: any;
  vehicleType: any;
  moveMsg: any;
  displayDimensions: any= [];
  retailObj: any;
  superRole: boolean = false;

  elementTrackNo: string = '';
  elementMsg: string = '';
  deleteMsg:string='';
  uberBooking;

  bucketPaymentType = '';
  changePaymentTypeMsg = '';
  showChangePaymentTypeMsg = false;
  bucketPaymentTypeChangeSuccess = false;
  newPaymentType = '';

  newBucketStatus
  bucketStatusError=""

  bucketStatusOptions=[
    {
      name:"BUCKET AWAITING",
      status:"BUCKET_AWAITING"
    },
    {
      name:"AWAITING DRIVER",
      status:"AWAITING_DRIVER"
    },
    {
      name:"IN TRANSIT",
      status:"IN_TRANSACT"
    },
    {
      name:"CREATED WAYBILL",
      status:"CREATED_WAYBILL"
    },
    {
      name:"RESERVED",
      status:"RESERVED"
    }

  ]

  constructor(private adminService: AdminService, private router: Router, private loadservice: LoaderService) {}

  ngOnInit() {

    this.book = JSON.parse(localStorage.getItem('bucketBooking'));
    // console.log(this.book);
    var object = JSON.parse(localStorage.getItem('userInfo'));
    if(this.book.bookings[0]){
      if(this.book.bookings[0].uberResponse){
        this.uberBooking=this.book.bookings[0]
      }
    }
    if (this.book.driverOid != null) {
      this.adminService.getDriver(object.token, this.book.driverOid).subscribe(
        (res) => {
          this.driver = res;
        }
      );
    }
    this.retailObj = this.book.retail;
    this.selectedTabID = localStorage.getItem('activeTab');
    localStorage.setItem('activeID', this.selectedTabID);

    object.roles.forEach(element => {
      if (element.code == 'super_admin') {
        this.superRole = true;
      }
    });
  }
  public trackBooking(){
    let link="";
    if(this.uberBooking){
      link=this.uberBooking.uberResponse.tracking_url
    }else{
      const site=isDevMode() ? "https://droppergroup.co.za/droppa/Track-Driver/":"https://droppa.co.za/droppa/Track-Driver/"
      link=site+this.uberBooking.trackNo
    }
    window.open(link, "_blank");
  }
  public viewRetail() {
    localStorage.setItem('selectedRetail', JSON.stringify(this.retailObj));
    this.router.navigate(['/retail-review']);
  }

  public viewDimensions(bucketDims) {
    this.displayDimensions = bucketDims;
    $('#displayParcels').modal('show');
  }

  public updateProvince() {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.updateBucketProvince(object.token, this.book.oid, this.provinceUpdate).subscribe(
      (response) => {
        this.message = 'Province updated.';
        this.book = response;
        localStorage.setItem('bucketBooking', JSON.stringify(response));
      }, (error) => {
        console.log(error)
        this.message = 'Province not updated.';
      }
    );
  }

  findDriver() {
    this.err = '';
    this.valid = false;
    if (this.driverMobile != null) {
      var object = JSON.parse(localStorage.getItem('userInfo'));
      this.adminService.getDriverByMobile(object.token, this.driverMobile).subscribe(
        (res) => {
          this.valid = true;
          this.foundDriver = res;
          this.err = this.foundDriver.vehicle != null ? '' : 'Driver doesn\'t have a vehicle.';
        }, (error) => {
          this.err = error.error.message;
        }
      );
    } else {
      alert('Please enter mobile numbers.');
    }
  }

  assignNow() {
    if (this.foundDriver != null) {
      var object = JSON.parse(localStorage.getItem('userInfo'));
      this.adminService.assignDriverToBucketBooking(object.token, this.book.oid, this.foundDriver.oid).subscribe(
        (res) => {
          this.assignStatus = 'Driver successfully assigned to booking.';
        }, (error) => {
          this.assignStatus = error.error.message;
        }
      );
    } else {
      alert('Driver details missing..');
    }
  }

  public completeBucketBooking() {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.completeBucketBookings(object.token, this.book.oid).subscribe(
      (response) => {
        this.book = response;
        this.bucketMessage = 'Bucket Booking Complete';
        $('#bucketBookings').modal('show');
        localStorage.setItem('bucketBooking', JSON.stringify(response));
      }, (error) => {
        this.bucketMessage = 'Error occured while trying to complete booking';
        $('#bucketBookings').modal('show');
      }
    );
  }

  public updateBucketBookingDate() {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.book = JSON.parse(localStorage.getItem('bucketBooking'));

    var getTime = new Date();
    var splittedTime = this.bookingTime.split(":");
    getTime.setHours(Number(splittedTime[0]));
    getTime.setMinutes(Number(splittedTime[1]));

    var currentDate = new Date(this.bookingDate);
    let date = currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate();
    let monthNo = (currentDate.getMonth() + 1) <= 9 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
    let hour = splittedTime[0];
    let minutes = splittedTime[1];

    var second = currentDate.getSeconds() <= 9 ? '0' + currentDate.getSeconds() : currentDate.getSeconds();
    var updatedbookingDate = currentDate.getFullYear() + '-' + monthNo + '-' + date + 'T' + hour + ':' + minutes + ':' + second;
    var datePipe = new DatePipe('en-US');
    let updatedTime = datePipe.transform(getTime, 'HH:mm a');

    let TimeSeparatorIndex = updatedTime.indexOf(':');
    this.hour = parseInt(updatedTime.substring(0, TimeSeparatorIndex));

    var bucketBookingDateObj = {
      "date": updatedbookingDate,
      "time": updatedTime,
      "bookingOid": this.book.oid
    };

    if (this.hour >= 7 && this.hour <= 17) {
      this.adminService.updateBucketBookingDateAndTime(object.token, bucketBookingDateObj).subscribe(
        (response) => {
          $('#updateTimeAndDate').modal('show');
          this.book = response;
          localStorage.setItem('bucketBooking', JSON.stringify(response));
          this.timeAndDateMessage = 'Booking Updated!';

        }, (error) => {
          this.timeAndDateMessage = 'Booking NOT Updated!';
        }
      );
    } else {
      this.timeAndDateMessage = 'Working hours are from 07:00 AM to 17:59 PM.';
    }
  }

  public pushExpressBook() {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.book = JSON.parse(localStorage.getItem('bucketBooking'));
    var data = {
      "comments": "",
      "bookingOid": this.book.oid
    };
    this.adminService.pushExpress(object.token, data).subscribe(
      (response) => {
        this.cancelMessage = 'Express booking sucessfully created.';
        $('#recreateBookings').modal('show');
      }, (error) => {
        this.cancelMessage = 'Express booking not created.';
        $('#recreateBookings').modal('show');
      }
    );
  }

  public updateBucketBookingPrice() {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.book = JSON.parse(localStorage.getItem('bucketBooking'));
    var updatePriceObj = {
      "price": this.bookingPrice,
      "bookingOid": this.book.oid
    };
    this.adminService.updateBucketBookingPrice(object.token, updatePriceObj).subscribe(
      (response) => {
        this.book = response;
        localStorage.setItem('bucketBooking', JSON.stringify(response));
        this.priceMessage = 'Price updated.';
      }, (error) => {
        this.priceMessage = 'Price not updated.';
      }
    );
  }

  public cancelBucketBooking() {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.book = JSON.parse(localStorage.getItem('bucketBooking'));
    this.adminService.cancelBucketBooking(object.token, this.book.oid).subscribe(
      (response) => {
        this.book = response;
        this.cancelMessage = 'Bucket booking cancelled!';
        $('#cancelBookings').modal('show');
        localStorage.setItem('bucketBooking', JSON.stringify(this.book));
      }, (error) => {
        this.cancelMessage = 'Error occured while trying to cancel.';
        $('#cancelBookings').modal('show');
      }, () => {
      }
    );
  }

  public recreateBucketBooking() {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.book = JSON.parse(localStorage.getItem('bucketBooking'));
    this.adminService.recreateBucketBooking(object.token, this.book.oid).subscribe(
      (response) => {
        this.book = response;
        this.cancelMessage = 'Bucket re-created.';
        $('#recreateBookings').modal('show');
        localStorage.setItem('bucketBooking', JSON.stringify(this.book));
      }, (error) => {
        this.cancelMessage = 'Error occured while trying to recreate the booking.';
        $('#recreateBookings').modal('show');
      }, () => {
      }
    );
  }

  public changeVehicleType() {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.book = JSON.parse(localStorage.getItem('bucketBooking'));
    this.adminService.retailChangeVehicleType(object.token, this.vehicleType, this.book.oid).subscribe(
      response => {
        this.book = response.json();
        this.bucketMessage = 'Vehicle updated.'
      }, error => {
        this.bucketMessage = 'Error';
      }
    )
  }

  public moveBookingFromBucket(bookingOid) {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.book = JSON.parse(localStorage.getItem('bucketBooking'));
    if (this.book.retail.name != "Dropper Express") {
      this.adminService.moveBookingFromBucket(object.token, this.book.oid, bookingOid).subscribe(
        response => {
          this.book = response.json();
          this.cancelMessage = 'Element successfully moved.';
          $('#bucketMoved').modal('show');
        }, error => {
          console.log(error);
        }
      )
    }
    else {
      alert('You can only move elements from Brima to Droppa');
    }
  }

  public moveBookingToExpress(bookingOid) {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.book = JSON.parse(localStorage.getItem('bucketBooking'));
    this.adminService.moveBookingToExpress(object.token, bookingOid, this.book.oid).subscribe(
      response => {
        this.moveMsg = 'Booking element moved successfully.';
        $('#elementMovedToExpress').modal('show');
      }, error => {
        console.log(error);
        this.moveMsg = 'Element already moved to Droppa Express.';
        $('#elementMovedToExpress').modal('show');
      }, () => {
      }
    )
  }

  public moveBookingToSkynet(bookingOid) {
    this.loadservice.addloader();
    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.book = JSON.parse(localStorage.getItem('bucketBooking'));
    var bookingObj = {
      "bookingID":  bookingOid,
      "bucketOid": this.book.oid,
      "toBucketTrackNo": this.book.trackNo
    };
    this.adminService.moveToSkynet(object.token, bookingObj, this.book.oid).subscribe(
      response => {
        this.loadservice.removeloader();
        this.moveMsg = 'Booking element successfully moved to Skynet.';
        $('#elementMovedToSkynet').modal('show');
      }, error => {
        this.loadservice.removeloader();
        console.log(error);
        this.moveMsg = 'Element already moved to Skynet.';
        $('#elementMovedToSkynet').modal('show');
      }, () => {
      }
    )
  }

  public moveToNewBucket(elementOid): void {
    this.loadservice.addloader();
    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.moveElementToBooking(object.token, elementOid, this.elementTrackNo).subscribe(response => {
      this.loadservice.removeloader();
      this.elementMsg = 'Element successfully moved';
      this.elementTrackNo = '';
      localStorage.setItem('bucketBooking', JSON.stringify(response));
      this.book = response;
    }, error => {
      this.loadservice.removeloader();
      console.log(error);
      this.elementMsg = error.error.message;
    }, () => {
      // console.log('Finally');
    })
  }
  public viewDeleteBookingModal(bookingOid){
    this.bookingId=bookingOid;
    $('#removeBooking').modal('show');
  }
  public deleteBookingFromBucket(bookingOid){
    this.loadservice.addloader();
    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.book = JSON.parse(localStorage.getItem('bucketBooking'));
    var bookingObj = {
      "bookingID":  bookingOid,
      "bucketOid": this.book.oid,
    };

    this.adminService.deleteBooking(object.token, bookingObj, this.book.oid).subscribe(
      response=>{
        this.loadservice.removeloader();
        this.deleteMsg = 'Booking element successfully moved from the bucket.';
        localStorage.setItem('bucketBooking', response._body);
        this.book = JSON.parse(localStorage.getItem('bucketBooking'));;
      },error=>{
        this.loadservice.removeloader();
        console.log(error);
        this.deleteMsg = 'Element already deleted from bucket.';

      },()=>{

      }
    )

  }
  public closeRemoveFromBucketModal(){
    $('#removeBooking').modal('hide');
    this.deleteMsg="";
  }

  updatePaymentType() {
    this.changePaymentTypeMsg = '';
    this.showChangePaymentTypeMsg = false;
    const user = JSON.parse(localStorage.getItem('userInfo'));

    this.adminService.changeBucketPaymentType(user.token, this.bucketPaymentType, this.book.oid).subscribe(
      (response: any) => {
        // console.log(response);
        this.changePaymentTypeMsg = 'Payment type updated to ' + '"' + response.paymentType + '"';
        this.newPaymentType = response.paymentType;
        this.showChangePaymentTypeMsg = true;
        this.bucketPaymentTypeChangeSuccess = true;
      },
      error => {
        console.log(error);
        this.changePaymentTypeMsg = error.error.message;
        this.showChangePaymentTypeMsg = true;
      }
    );
  }
  updateBucketStatus(){
    console.log(this.newBucketStatus)
    console.log(this.book.trackNo)
    const user = JSON.parse(localStorage.getItem('userInfo'));
    this.loadservice.addloader()
    this.bucketStatusError=""
    this.adminService.changeBucketStatus(user.token,this.newBucketStatus,this.book.trackNo).subscribe(
      (response) => {
        $("#changeBucketStatus").modal("hide")
      }, (error) => {
        console.log(error)
        this.loadservice.removeloader()
        this.bucketStatusError = error.message;
      },
      ()=>{
        this.loadservice.removeloader()
      }
    );

  }

}
