import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BookingService } from 'src/app/services/booking.service';
import { LoaderService } from 'src/app/services/loader.service';
import {SharedService} from "../../services/shared.service";
declare var $: any;

@Component({
  selector: 'app-confirm-wallet-order',
  templateUrl: './confirm-wallet-order.component.html',
  styleUrls: ['./confirm-wallet-order.component.css']
})
export class ConfirmWalletOrderComponent implements OnInit {

  addrressData = {
    dropOffAddress: '',
    pickUpAddress: '',
    customerName: '',
    customer : {
      email: '',
      firstName: '',
      surname: ''
    },
    phoneNo: '',
    trackNo: '',
    status: ''
  };

  loader: boolean = false;
  invoiceOption: boolean;
  invoiceControl: boolean = false;
  bookingOid: any;

  companyName: any;
  vatNo: number;
  companyAddress: any;
  dropObj: any;

  service: string;
  waybillDownloadLink: string;

  constructor(private bookService: BookingService, private route: ActivatedRoute, private router: Router,
              private sharedServices: SharedService,private loadservice:LoaderService) {
    this.service = sharedServices.getUrl();
  }

  ngOnInit() {
    // this.addrressData = JSON.parse(localStorage.getItem('bookingDataObject')).booking;
    this.dropObj = JSON.parse(localStorage.getItem('dropDataObject'));
    // console.log("dropp object",this.dropObj);
    this.bookService.getBookingDetails(this.dropObj.oid).subscribe(
      response => {
        this.addrressData = response;
        this.bookingOid=response.trackNo;
        this.waybillDownloadLink=this.bookService.downloadWaybill(this.bookingOid);
        console.log(response.oid);
      }, error => {
        console.log(error);
        console.log('Error block');
      }, () => {
      }
    );
  }


  public attachInvoiceOrNot(invoiceOption) {
    if (invoiceOption) {
      this.invoiceControl = true;
    }
    else {
      this.invoiceControl = false;
    }
  }

  companyNameMsg: string = '';
  companyAddressMsg: string = '';
  vatNoMsg: string = '';

  public modelCompanyName(companyName) {
    if (this.invoiceControl == true) {
      if (companyName == null || companyName == '') {
        this.companyNameMsg = 'Please enter the company name.';
      }
      else {
        this.companyNameMsg = '';
      }
    }
  }

  public modelVATNo(vatNo) {
    if (this.invoiceControl == true) {
      if (vatNo == null || vatNo == '') {
        this.vatNoMsg = 'Please enter the VAT No.';
      }
      else {
        this.vatNoMsg = '';
      }
    }
  }

  public modelCompanyAddress(companyAddress) {
    if (this.invoiceControl == true) {
      if (companyAddress == null || companyAddress == '') {
        this.companyAddressMsg = 'Please enter the company address.';
      }
      else {
        this.companyAddressMsg = '';
      }
    }
  }

  public sendOptionalInvoice() {
    var token = localStorage.getItem('token');

    if (this.invoiceControl) {
      if (this.companyName == null || this.companyName == '') {
        this.companyNameMsg = 'Please enter the company name.';
      }
      else if (this.vatNo == null || this.vatNo == undefined || this.vatNo.toString() == "") {
        this.vatNoMsg = 'Please enter the VAT No.';
      }
      else if (this.companyAddress == null) {
        this.companyAddressMsg = 'Please enter the company address.';
      }
      else {
        var invoiceObj = {
          "companyName": this.companyName,
          "address": this.companyAddress,
          "vatNo": this.vatNo,
          "toNames": this.addrressData.customer.firstName + ' ' + this.addrressData.customer.surname,
          "toEmail": this.addrressData.customer.email,
          "toPhone": this.addrressData.phoneNo,
          "trackNo": this.addrressData.trackNo
        }
        this.loadservice.addloader();
        this.bookService.newInvoiceCustomer(token, invoiceObj).subscribe(
          response => {
            this.loadservice.removeloader();
          }, (error) => {
            console.log(error);
            this.loadservice.removeloader();
          }, () => {
            console.log('final method hit');
            this.router.navigateByUrl('/user');
            // $('#optionalInvoice').modal('show');
          }
        )
      }
    }
    else {
      var invoiceObj = {
        "companyName": this.companyName,
        "address": this.companyAddress,
        "vatNo": this.vatNo,
        "toNames": this.addrressData.customer.firstName + ' ' + this.addrressData.customer.surname,
        "toEmail": this.addrressData.customer.email,
        "toPhone": this.addrressData.phoneNo,
        "trackNo": this.addrressData.trackNo
      }
      this.loadservice.addloader();
      this.bookService.newInvoiceCustomer(token, invoiceObj).subscribe(
        response => {
          this.loadservice.removeloader();
        }, (error) => {
          console.log(error);
          this.loadservice.removeloader();
        }, () => {
          this.router.navigateByUrl('/user');
          // $('#optionalInvoice').modal('show');
        }
      )
    }

  }

  public onHome() {
    this.router.navigateByUrl('/user');
  }

}
