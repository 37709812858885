import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

import { BookingService } from '../../services/booking.service';
import { LoaderService } from 'src/app/services/loader.service';
declare var $: any;

@Component({
  selector: 'app-edit-booking',
  templateUrl: './edit-booking.component.html',
  styleUrls: ['./edit-booking.component.css']
})
export class EditBookingComponent implements OnInit {

  loader: boolean = false;
  price: number;
  pickUp: string;
  dropOff: string;
  pickUpDate: any;
  // pickUpTime: string
  pickUpTime: string = '10:05 AM';
  status: string;
  fullDate: any = [];
  hour: number;
  booking: any = {};
  errorMessage: string;
  datePipe: DatePipe;
  minutes: string;
  today = new Date();
  yesterday = new Date(this.today);

  public min = new Date(this.yesterday.setDate(this.today.getDate() - 1));
  public max = new Date(2030, 3, 21, 20, 30);

  constructor(private bookingServe: BookingService, private route: Router,private loadservice:LoaderService) {
    this.booking = JSON.parse(localStorage.getItem('selectedBooking'));
    //console.log(this.booking);
    this.price = this.booking.price;
    this.pickUp = this.booking.pickUpAddress;
    this.dropOff = this.booking.dropOffAddress;
    this.pickUpDate = new Date();
    /*this.fullDate = this.booking.pickUpDate.split("/");

    let day = this.fullDate[0];
    let month = this.fullDate[1];
    let year = this.fullDate[2];

    let date = year + "-" + month + "-" + day;*/

    this.pickUpDate = this.booking.pickUpDate;

    let index = this.booking.pickUpTime.substring(0, 5).indexOf(':');
    let hr = parseInt(this.booking.pickUpTime.substring(0, 5).substring(0, index));
    let min = this.booking.pickUpTime.substring(0, 5).substring(index + 1);

    this.pickUpTime = this.booking.pickUpTime.substring(0, 5);//this.formatAMPM(hr, min);//this.booking.pickUpTime.substring(0, 5);
    this.status = this.booking.status;
    //console.log(this.booking.pickUpTime);
    //console.log(this.pickUpTime);
    //console.log(this.booking.pickUpTime.substring(0, 5));

  }

  ngOnInit() {
    // this.pickUpTime = this.booking.pickUpTime.substring(0, 5);
  }

  updateBooking() {
    var realTime = new Date(this.pickUpDate);
    var currentDate = new Date();
    var dateFormat = currentDate.toLocaleString('en-us', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');
    var pickUpdateFormat = realTime.toLocaleString('en-us', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');

    var dateSame = pickUpdateFormat == dateFormat;
    var dateDiffer = pickUpdateFormat > dateFormat;

    var datePipe = new DatePipe('en-US');

    var datePipe = new DatePipe('en-US');
    //console.log(datePipe.transform(this.pickUpTime, 'HH:mm a'));
    let valueTime = datePipe.transform(this.pickUpTime, 'HH:mm a');
    //console.log(valueTime);

    var dateValue = this.pickUpDate;
    var datePipe = new DatePipe('en-US');
    let value = datePipe.transform(dateValue, 'yyyy-MM-dd');

    /*let index = this.booking.pickUpTime.substring(0, 5).indexOf(":");
    let hr = parseInt(this.booking.pickUpTime.substring(0, 5).substring(0, index));
    let min = this.booking.pickUpTime.substring(0, 5).substring(index + 1);*/

    /*if(this.pickUpTime == this.booking.pickUpTime) {
      valueTime = this.formatAMPM(hr, min);
      console.log(valueTime)
    } else {
      console.log("here "+this.pickUpTime + " "+ this.booking.pickUpTime);

      valueTime = this.booking.pickUpTime;//datePipe.transform(this.booking.pickUpTime, 'HH:mm a');
    }*/
    let TimeSeparatorIndex = valueTime.indexOf(':');
    //console.log(TimeSeparatorIndex);
    this.hour = parseInt(valueTime.substring(0, TimeSeparatorIndex));
    this.minutes = valueTime.substring(TimeSeparatorIndex + 1);
    let timeValue = this.formatAMPM(this.hour, this.minutes);

    if (dateSame == true || dateDiffer == true) {
      if (this.hour >= 7 && this.hour <= 18) {
        var user = JSON.parse(localStorage.getItem('userInfo'));
        var data = {
          'oid': this.booking.oid,
          'date': value,
          'time': valueTime,
        };
        this.loadservice.addloader();
        // console.log(data);
        this.bookingServe.updateBook(data, user.token)
          .subscribe(
            (respose) => {
              // console.log(respose);
              localStorage.setItem('selectedBooking', JSON.stringify(respose));
              this.pickUpDate = respose.pickUpDate;
              this.pickUpTime = respose.pickUpTime;
              this.loadservice.removeloader();
              this.errorMessage = 'Booking updated.';
              $('#errorDialog').modal('show');
            },
            (err) => {
              console.log(err);
              this.loadservice.removeloader();
              this.errorMessage = 'Error, booking update failed.';
              $('#errorDialog').modal('show');
            }
          );
      } else {
        this.loadservice.removeloader();
        this.errorMessage = 'Working hours are from 07:00 AM to 18:59 PM.';
        $('#errorDialog').modal('show');
      }
    } else {
      this.loadservice.removeloader();
      this.errorMessage = 'Error, invalid pick up date. The date already passed.';
      $('#errorDialog').modal('show');
    }
  }

  public deleteBooking() {
    var user = JSON.parse(localStorage.getItem('userInfo'));
    this.bookingServe.cancelBooking(this.booking.oid, user.token)
      .subscribe(
        (res) => {
          this.loadservice.removeloader();
          this.errorMessage = 'Booking deleted.';
          $('#errorDialog').modal('show');
          this.route.navigateByUrl('/user');
        },
        (err) => {
          console.log(err);
          this.loadservice.removeloader();
          this.errorMessage = 'Error, booking can only be deleted within 24 hours.';
          $('#errorDialog').modal('show');
        }
      )
  }

  public formatAMPM(hours, minutes): string {
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    hours = hours < 10 ? '0' + hours : hours;
    var strTime = hours + ':' + minutes + ' ' + ampm;

    return strTime;
  }

}
