import { Component, OnInit, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Response } from '@angular/http'
import { Router } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { BookingService } from '../../services/booking.service';
import { Parcel } from '../../models/parcel';
import { ParcelDimensions } from '../../models/parcelDimensions';
declare var $: any;
import * as moment from 'moment';
import { LoaderService } from 'src/app/services/loader.service';

declare var google: any;
@Component({
  selector: 'app-retail-book',
  templateUrl: './retail-book.component.html',
  styleUrls: ['./retail-book.component.css']
})

export class RetailBookComponent implements OnInit {

  public userSettings2: any = {
    showRecentSearch: false,
    geoCountryRestriction: ['ZA'],
    inputPlaceholderText: 'Enter pick up address.',
    geoTypes: ['address', 'establishment']
  };

  public userSettings: any = {
    showRecentSearch: false,
    geoCountryRestriction: ['ZA'],
    inputPlaceholderText: 'Enter drop off address.',
    geoTypes: ['address', 'establishment']
  };

  contact: Contact = {
    name: '',
    surname: '',
    mobile: '',
    email: '',
    labours: 0
  };

  dropContact: Contact = {
    name: '',
    surname: '',
    mobile: '',
    email: '',
    labours: 0
  };

  dropContact2: Contact = {
    name: '',
    surname: '',
    mobile: '',
    email: '',
    labours: 0
  };

  comment: string;
  pickUpNotMsg: string;
  pickUpNotFound: boolean;
  fromSuburb: any;
  newPickUpSuburb: any;
  pickUpPCode: any;
  pickUpProvince: any;
  pickUpAddress: any;
  pickUpCoordinate: any[];
  pickUpCoordinateLocation: { coordinates: any; address: any; };
  departureCoordinate: { lat: any; lng: any; };
  dropOffNotMsg: string;
  dropOffNotFound: boolean;
  toSuburb: any;
  newDropOffSuburb: any;
  dropOffPCode: any;
  dropOffProvince: any;
  dropOffAddress: any;
  dropOffCoordinate: any[];
  destinationCoordinate: { lat: any; lng: any; };
  dropOffCoordinateLocation: { coordinates: any; address: any; };
  validateProvince: any;
  province: any;
  disablePickUp: boolean;
  mass: any;
  selectedOption: string = 'SAME_DAY';

  bringMultipleDrop: boolean = true;
  bringFlashPrices: boolean = true;
  bringFleetPrices: boolean = true;
  bringDimensionPages: boolean = true;
  disableAddresses: boolean = true;

  vehicleTypeRate: any;
  msgStatus: string;
  isExpress: boolean = true;
  isBookingExpress: boolean = false;
  distance: any;
  quoteResponse: any;
  flashFlight: any;
  airlineAmt: any;
  pickUpAmt: any;
  dropOffAmt: any;
  flashRoad: any;
  sheshaMode: any;

  flightAvailable: boolean = false;
  flashRoadAvailable: boolean = false;
  sheshaAvailable: boolean = false;
  dashAvailable: boolean = false;
  loader: boolean;

  isBudget: boolean = false;
  isCourier: boolean = false;
  isRoad: boolean = false;
  isFlight: boolean = false;
  isDash: boolean = false;

  isExpress_: boolean = true
  isFleetPicked: boolean = false;
  isCourierPicked: boolean = false;
  isRoadPicked: boolean = false;

  type: string;
  transportMode: string;
  tempAmount: any;

  isExpressBudget: boolean = true;
  isCourierExpress: boolean = true;
  expressCourier: any;
  budgetCourier: any;
  retail: any;
  bookingPrice: any;
  price: any;
  availableQuotes: boolean;
  dir: { origin: { lat: any; lng: any; }; destination: { lat: any; lng: any; }; };
  msg: any;

  miniVan: string = 'assets\\img\\retail\\miniVan2.png';
  oneTon: string = 'assets\\img\\retail\\oneTon2.png';
  oneHalfTon: string = 'assets\\img\\retail\\oneHaltTon2.png';
  fourTon: string = 'assets\\img\\retail\\fourTon2.png';
  express: string = 'assets\\img\\retail\\Group_1055_(1).png';

  vehicleType: any;
  vehicle: string;
  bookingTime: any;
  bookingDate: any;
  fleetBookingDate: any;
  fleetBookingTime: any;
  bucketBooking: any;
  bucketBookingOID: any
  bookingText: any;
  bookData: any;
  err: any;

  currentElement: any;
  selectedVehicleT: any;
  dashVehiceType: any;
  miniVanVehicleType: any;

  load: number = 1;
  dropOffFloors: number = 0;
  floorsPickUp: number = 0;
  canopy: boolean = false;
  promoCodeAmnt: number = 0.0;
  hour: number;
  bookingDates: any;

  timeRange: string;
  msgExpress: any;
  pickUpChecked: any;
  dropOffChecked: boolean = false;
  hourMsg: string;

  provinceMessage: any;
  addressComponent: any = [];
  dateObj: any;

  enteredPickUpAddress: any;
  enteredPickUpSuburb: any;
  enteredPickUpPostalCode: any;
  enteredPickUpProvince: any;

  enteredDropOffAddress: any;
  enteredDropOffSuburb: any;
  enteredDropOffPostalCode: any;
  enteredDropOffProvince: any;

  customerRef: any;

  companyPickUpName: any;
  complexPickUp: any;
  unitPickUp: any;

  companyDropOffName: any;
  complexDropOff: any;
  unitDropOff: any;
  costCentreNumber: any;
  numOfParcels: any;

  numberOfPracels = [];
  pracels: Parcel[] = [];
  numOfBoxes: any;
  customer_reference: any;
  cost_center_num: any;
  parcel_breadth: any;
  parcel_height: any;
  parcel_length: any;
  parcel_mass: any;

  pracelDims: ParcelDimensions[] = [];

  pracel: Parcel;
  massMsg: string;
  breadthMsg: string;
  heightMsg: string;
  lengthMsg: string;
  checkingMsg: string = '';

  overload: boolean = false;

  displayNewDate: any;
  workingDays: any;
  dateMessage: string;

  pickUpExtraDetails: any = [];
  dropOffExtraDetails: any = [];
  workingHours: number;
  validationMsg: string = '';

  disableDropOffAddress: boolean = true;
  fromDropDownSuburb: any;
  toDropDownSuburb: any;

  promptedPickUpPCode: any;
  promptedDropOffPCode: any;

  enteredPickUpAddressMsg: string = '';
  enteredPickUpSuburbMsg: string = '';
  enteredPickUpPostalCodeMsg: string = '';
  enteredPickUpProvinceMsg: string = '';
  enteredPickUpSuccessMsg: string = '';

  enteredDropOffAddressMsg: string = '';
  enteredDropOffSuburbMsg: string = '';
  enteredDropOffPostalCodeMsg: string = '';
  enteredDropOffProvinceMsg: string = '';
  enteredDropOffSuccessMsg: string = '';

  constructor(private router: Router, private sharedService: SharedService, private bookService: BookingService,private loadservice:LoaderService) { }

  @Input() selectedPracel = {}

  ngOnInit() {
    this.retail = JSON.parse(localStorage.getItem('retail'));
    localStorage.setItem('retail', JSON.stringify(this.retail));
    this.vehicleTypeRate = this.retail.typeRates;
  }

  public switchLooks() {
    if (this.retail.canAddParcels) {
      // if user has a tag
      localStorage.setItem('retail', JSON.stringify(this.retail));
      this.router.navigate(['/create-retail-book']);
    } else {
      // if user has no tag
      localStorage.setItem('retail', JSON.stringify(this.retail));
      this.router.navigate(['/create-retail-booking']);
    }
  }

  public checkingParcelDimensionsOverLimit() {
    for (let a = 0; a < this.pracelDims.length; a++) {
      if (this.pracelDims[a].parcel_mass > 70) {
        this.overload = true;
        break;
      }
      else {
        this.overload = false;
      }
    }
  }

  public checkingParcelOverLimit() {
    for (let a = 0; a < this.pracels.length; a++) {
      if (this.pracels[a].parcel_mass > 70) {
        this.overload = true;
        break;
      }
      else {
        this.overload = false;
      }
    }
  }

  public checkDimensionValidation(): Boolean {

    let check = false;

    for (let a = 0; a < this.pracelDims.length; a++) {
      if (this.pracelDims[a].parcel_breadth == null || this.pracelDims[a].parcel_height == null || this.pracelDims[a].parcel_length == null || this.pracelDims[a].parcel_mass == null) {
        this.checkingMsg = 'Please make sure that all the parcel details are filled in before proceeding.';
        $('#validationModal').modal('show');
        check = false;
        break;
      }
      else if (this.pracelDims[a].parcel_breadth == 0 || this.pracelDims[a].parcel_height == 0 || this.pracelDims[a].parcel_length == 0 || this.pracelDims[a].parcel_mass == 0) {
        this.checkingMsg = 'Please make sure that none of the dimensions entered are 0.';
        $('#validationModal').modal('show');
        check = false;
        break;
      }
      else if (this.pracelDims[a].parcel_breadth > 150 || this.pracelDims[a].parcel_height > 150 || this.pracelDims[a].parcel_length > 150 || this.pracelDims[a].parcel_mass > 150) {
        this.checkingMsg = 'Please make sure that none of the dimensions entered are greater than 150.';
        $('#validationModal').modal('show');
        check = false;
        break;
      }
      else if (this.pracelDims[a].parcel_breadth < 0 || this.pracelDims[a].parcel_height < 0 || this.pracelDims[a].parcel_length < 0 || this.pracelDims[a].parcel_mass < 0) {
        this.checkingMsg = 'Please make sure that none of the dimensions entered are a negative number.';

        $('#validationModal').modal('show');
        check = false;
        break;
      }
      else {
        check = true;
      }
    }

    return check;

  }

  public displayPrices() {

    if (!this.retail.canAddParcels) {
      this.checkingParcelDimensionsOverLimit();
    } else {
      this.checkingParcelOverLimit();
    }

    // this.checkingParcelOverLimit();
    this.calculateTotalParcelDimensions();

    if (this.overload) {
      this.checkingMsg = 'Please check that none of your individual parcel exceeds 70KGs.';
      $('#validationModal').modal('show');
    } else if (this.selectedOption == 'SAME_DAY') {
      var d = new Date();
      var hours = d.getHours();
      if (hours >= 7 && hours <= 17) {
        this.bringDimensionPages = true;
        this.bringMultipleDrop = true;
        this.bringFleetPrices = true;
        this.bringFlashPrices = false;
        this.disableAddresses = false;
        this.msgStatus = '';
        this.getSameDayRates();
      } else {
        this.hourMsg = "Service only available from 06:00 AM to 18:00 PM.";
        $('#hourAheadValidation').modal('show');
      }
    }
    else if (this.selectedOption == 'MULITPLE_DROPS') {
      this.bringDimensionPages = true;
      this.bringFlashPrices = true;
      this.bringFleetPrices = true;
      this.bringMultipleDrop = false;
      this.disableAddresses = false;
      this.msgStatus = '';
      this.getCourierRates();
    }
    else if (this.selectedOption == 'FLEET_OPTION') {
      this.bringDimensionPages = true;
      this.bringFlashPrices = true;
      this.bringMultipleDrop = true;
      this.bringFleetPrices = false;
      this.disableAddresses = false;
      this.msgStatus = '';
    }

  }

  public getQuote() {

    if (this.checkDimensionValidation()) {
      this.displayPrices();
    }

  }

  public getMultipleParcels() {
    this.bringDimensionPages = false;
    this.bringMultipleDrop = true;
    this.bringFlashPrices = true;
    this.bringFleetPrices = true;

    this.numberOfPracels = new Array(this.numOfParcels);
    this.pracelDims = [];

    for (let x = 0; x < this.numberOfPracels.length; x++) {
      let pracel = {
        parcel_length: '',
        parcel_breadth: '',
        parcel_height: '',
        parcel_mass: ''
      };
      this.pracelDims.push(pracel);
    }

    this.onSelect(this.pracelDims[0]);
  }

  onSelect(pracel): void {
    this.selectedPracel = pracel;
    this.pracel = pracel;
  }

  public modelBreadthChanged(parcel_breadth) {
    if (this.selectedOption != 'FLEET_OPTION') {
      if (parcel_breadth > 150) {
        this.breadthMsg = "Width for parcel cannot exceed 150";
      }
      else if (parcel_breadth == 0) {
        this.breadthMsg = "Width cannot be 0";
      }
      else if (parcel_breadth < 0) {
        this.breadthMsg = "Width cannot be a negative number";
      }
      else if (parcel_breadth == null) {
        this.breadthMsg = "Width cannot be empty";
      }
      else if (parcel_breadth < 150) {
        this.breadthMsg = " ";
      }
    }
  }

  public modelMassChanged(parcel_mass) {

    if (this.selectedOption != 'FLEET_OPTION') {
      if (parcel_mass > 70) {
        this.massMsg = "Mass cannot exceed 70";
      }
      else if (parcel_mass == 0) {
        this.massMsg = "Mass cannot be 0";
      }
      else if (parcel_mass < 0) {
        this.massMsg = "Mass cannot be a negative number";
      }
      else if (parcel_mass == null) {
        this.massMsg = "Mass cannot be empty";
      }
      else if (parcel_mass < 70) {
        this.massMsg = " ";
      }
    }

  }

  public modelHeightChanged(parcel_height) {

    if (this.selectedOption != 'FLEET_OPTION') {
      if (parcel_height > 150) {
        this.heightMsg = "Height for parcel cannot exceed 150";
      }
      else if (parcel_height == 0) {
        this.heightMsg = "Height cannot be 0";
      }
      else if (parcel_height < 0) {
        this.heightMsg = "Height cannot be a negative number";
      }
      else if (parcel_height == null) {
        this.heightMsg = "Height cannot be empty";
      }
      else if (parcel_height < 150) {
        this.heightMsg = " ";
      }
    }

  }

  public modelLengthChanged(parcel_length) {

    if (this.selectedOption != 'FLEET_OPTION') {
      if (parcel_length > 150) {
        this.lengthMsg = "Length for parcel cannot exceed 150";
      }
      else if (parcel_length == 0) {
        this.lengthMsg = "Length cannot be 0";
      }
      else if (parcel_length < 0) {
        this.lengthMsg = "Length cannot be a negative number";
      }
      else if (parcel_length == null) {
        this.lengthMsg = "Length cannot be empty";
      }
      else if (parcel_length < 150) {
        this.lengthMsg = " ";
      }
    }

  }

  public modelChanged(parcel) {

    if (parcel.parcel_mass > 70) {
      this.massMsg = "Mass cannot exceed 70";
    }
    else if (parcel.parcel_mass == 0) {
      this.massMsg = "Mass cannot be 0";
    }
    else if (parcel.parcel_mass < 0) {
      this.massMsg = "Mass cannot be a negative number";
    }
    else if (parcel.parcel_mass == null) {
      this.massMsg = "Mass cannot be empty";
    }
    else if (parcel.parcel_mass < 70) {
      this.massMsg = " ";
    }

    if (parcel.parcel_breadth > 150) {
      this.breadthMsg = "Width for parcel cannot exceed 150";
    }
    else if (parcel.parcel_breadth == 0) {
      this.breadthMsg = "Width cannot be 0";
    }
    else if (parcel.parcel_breadth < 0) {
      this.breadthMsg = "Width cannot be a negative number";
    }
    else if (parcel.parcel_breadth == null) {
      this.breadthMsg = "Width cannot be empty";
    }
    else if (parcel.parcel_breadth < 150) {
      this.breadthMsg = " ";
    }

    if (parcel.parcel_height > 150) {
      this.heightMsg = "Height for parcel cannot exceed 150";
    }
    else if (parcel.parcel_height == 0) {
      this.heightMsg = "Height cannot be 0";
    }
    else if (parcel.parcel_height < 0) {
      this.heightMsg = "Height cannot be a negative number";
    }
    else if (parcel.parcel_height == null) {
      this.heightMsg = "Height cannot be empty";
    }
    else if (parcel.parcel_height < 150) {
      this.heightMsg = " ";
    }

    if (parcel.parcel_length > 150) {
      this.lengthMsg = "Length for parcel cannot exceed 150";
    }
    else if (parcel.parcel_length == 0) {
      this.lengthMsg = "Length cannot be 0";
    }
    else if (parcel.parcel_length < 0) {
      this.lengthMsg = "Length cannot be a negative number";
    }
    else if (parcel.parcel_length == null) {
      this.lengthMsg = "Length cannot be empty";
    }
    else if (parcel.parcel_length < 150) {
      this.lengthMsg = " ";
    }
  }

  enterPickUpAddress() {
    $('#addressPopUp').modal('show');
  }

  enterDropOffAddress() {
    $('#addressPopUp2').modal('show');
  }

  public enteredAddressInfo() {
    this.pickUpAddress = this.enteredPickUpAddress;
    this.fromSuburb = this.enteredPickUpSuburb;
    this.pickUpPCode = this.enteredPickUpPostalCode;
    this.pickUpProvince = this.enteredPickUpProvince;
    this.province = this.enteredPickUpProvince;
    this.pickUpCoordinate = ['-25.98953', '28.12843'];
    this.pickUpCoordinateLocation = { coordinates: this.pickUpCoordinate, address: this.pickUpAddress };
  }

  public enteredAddressInfo2() {
    this.dropOffAddress = this.enteredDropOffAddress;
    this.toSuburb = this.enteredDropOffSuburb;
    this.dropOffPCode = this.enteredDropOffPostalCode;
    this.dropOffProvince = this.enteredDropOffProvince;
    this.dropOffCoordinate = ['-25.97521', '28.13954'];
    this.dropOffCoordinateLocation = { coordinates: this.dropOffCoordinate, address: this.dropOffAddress };
  }

  public caturedPickpAddress() {
    if (this.enteredPickUpAddress == null || this.enteredPickUpAddress == '') {
      this.enteredPickUpAddressMsg = 'Please enter the address';
    }
    else if (this.enteredPickUpPostalCode == null || this.enteredPickUpPostalCode == '') {
      this.enteredPickUpPostalCodeMsg = 'Please enter the postal code.';
    }
    else if (this.enteredPickUpSuburb == null || this.enteredPickUpSuburb == '') {
      this.enteredPickUpSuburbMsg = 'Please enter the suburb';
    }
    else if (this.enteredPickUpProvince == null || this.enteredPickUpProvince == '') {
      this.enteredPickUpProvinceMsg = 'Please enter the province.';
    }
    else {
      this.disableAddresses = false;
      this.enteredPickUpSuccessMsg = 'Address successfully captured.';
      this.fromDropDownSuburb = this.enteredPickUpSuburb;
      this.pickUpPCode = this.enteredPickUpPostalCode;
      this.pickUpProvince = this.enteredPickUpProvince;
      this.province = this.enteredPickUpProvince;
      this.pickUpCoordinate = ['-25.98953', '28.12843'];
      this.pickUpAddress =
        this.enteredPickUpAddress
          .concat(', ' + this.fromDropDownSuburb)
          .concat(', ' + this.pickUpPCode)
          .concat(', ' + this.province);
      this.pickUpCoordinateLocation = { coordinates: this.pickUpCoordinate, address: this.pickUpAddress };
      // Clearing error messages
      this.enteredPickUpAddressMsg = '';
      this.enteredPickUpSuburbMsg = '';
      this.enteredPickUpPostalCodeMsg = '';
      this.enteredPickUpProvinceMsg = '';
    }
  }

  public closeCaturedPickpAddress() {
    this.enteredPickUpAddressMsg = '';
    this.enteredPickUpSuburbMsg = '';
    this.enteredPickUpPostalCodeMsg = '';
    this.enteredPickUpProvinceMsg = '';
    this.enteredPickUpSuccessMsg = '';
  }

  autoCompleteCallback1(selectedData: any) {
    if (!selectedData) {
      this.pickUpNotMsg = 'Location not found, Please enter nearest location.';
      this.pickUpNotFound = false;
    } else {
      for (let x = 0; x < selectedData.data.address_components.length; x++) {
        for (let y = 0; y < selectedData.data.address_components[x].types.length; y++) {
          for (let a = 0; a < selectedData.data.address_components[x].types.length; a++) {
            if (selectedData.data.address_components[x].types[a] == 'locality') {
              this.fromSuburb = selectedData.data.address_components[x].long_name;
            }
            else if (selectedData.data.address_components[x].types[a] == 'sublocality') {
              this.newPickUpSuburb = selectedData.data.address_components[x].long_name;
            }
            else if (selectedData.data.address_components[x].types[a] == 'postal_code') {
              this.pickUpPCode = selectedData.data.address_components[x].long_name;
              console.log(this.pickUpPCode);
              // this.selectPickUpDropDownSuburb(this.pickUpPCode);
            }
            else if (selectedData.data.address_components[x].types[a] == 'administrative_area_level_1') {
              this.pickUpProvince = selectedData.data.address_components[x].long_name;
            }
          }
        }
      }
      if (this.pickUpPCode == null) {
        this.pickUpPostalCodeService(selectedData);
      }
      this.pickUpAddress = selectedData.data.description;
      this.pickUpCoordinate = [selectedData.data.geometry.location.lat, selectedData.data.geometry.location.lng];
      this.pickUpCoordinateLocation = { 'coordinates': this.pickUpCoordinate, 'address': this.pickUpAddress };
      var geocoder = new google.maps.Geocoder;
      this.departureCoordinate = { "lat": selectedData.data.geometry.location.lat, "lng": selectedData.data.geometry.location.lng };
      this.geocodeLatLng(geocoder, selectedData.data.geometry.location.lat, selectedData.data.geometry.location.lng);
      this.getAdminArea(selectedData.data.address_components);
    }
  }

  // public selectPickUpDropDownSuburb(postalCode) {
  //   let myToken = JSON.parse(localStorage.getItem('userInfo')).token;
  //   if (this.pickUpPCode != null && this.pickUpPCode != undefined) {
  //     this.bookService.postalDetails(myToken, postalCode).subscribe(
  //       response => {
  //         this.pickUpExtraDetails = response;
  //         console.log(this.pickUpExtraDetails);
  //       }, error => {
  //         console.log(error);
  //       }
  //     );
  //   }
  //   else {
  //     this.pickUpExtraDetails = [];
  //     console.log(this.pickUpExtraDetails);
  //   }
  // }

  public selectedPickUpSuburb(fromSuburb) {

    this.fromDropDownSuburb = fromSuburb;

  }

  public getPickUpExtraDetails(postalCode) {
    let myToken = JSON.parse(localStorage.getItem('userInfo')).token;
    this.pickUpPCode = postalCode;
    if (this.pickUpPCode != null || this.pickUpPCode != undefined) {
      this.bookService.postalDetails(myToken, this.pickUpPCode).subscribe(
        response => {
          this.pickUpExtraDetails = response;
          if (this.pickUpExtraDetails.length == 0) {
            this.enteredPickUpPostalCodeMsg = 'Invalid postal code, enter a valid postal code to select a suburb.';
          }
          else {
            this.enteredPickUpPostalCodeMsg = '';
          }
        }, error => {
          console.log(error);
        }
      );
    }
    else {
      this.pickUpExtraDetails = [];
    }
  }

  // public selectedPickUpSuburb(fromSuburb) {
  //   console.log(fromSuburb);
  //   this.fromSuburb = fromSuburb;
  // }

  public pickUpPostalCodeService(selectedData) {
    let latTest = selectedData.data.geometry.location.lat;
    let lngTest = selectedData.data.geometry.location.lng;

    let geocoder = new google.maps.Geocoder;
    const latlng = {
      lat: latTest,
      lng: lngTest,
    };
    geocoder.geocode(
      { location: latlng }, (results) => {
        if (results[0]) {
          let foundAddress = results[0].address_components;
          for (let x = 0; x < foundAddress.length; x++) {
            for (let y = 0; y < foundAddress[x].types.length; y++) {
              for (let a = 0; a < foundAddress[x].types.length; a++) {
                if (foundAddress[x].types[a] == 'postal_code') {
                  this.pickUpPCode = foundAddress[x].long_name;
                  console.log(this.pickUpPCode);
                  this.getPickUpSuburb(this.pickUpPCode);
                }
              }
            }
          }

          if (this.dropOffPCode == null) {
            this.promptPostalCode = "Please enter your area postal code below";
            $('#dropOffPostcalCode').modal('show');
          }

        }
      }
    );
  }

  clickedPickUppCode() {
    this.pickUpPCode = this.promptedPickUpPCode;
    this.getPickUpSuburb(this.pickUpPCode);
    console.log(this.pickUpPCode);
  }

  clickedDropOffpCode() {
    this.dropOffPCode = this.promptedDropOffPCode;
    this.getDropOffSuburb(this.dropOffPCode);
    console.log(this.dropOffPCode);
  }

  public getPickUpSuburb(postalCode) {
    this.bookService.getPickUpSuburb(postalCode).subscribe(
      response => {
        this.fromSuburb = response.suburb;
      }, error => {
        console.log(error);
      }, () => {
      }
    )
  }

  public getDropOffSuburb(postalCode) {
    this.bookService.getDropOffSuburb(postalCode).subscribe(
      response => {
        this.toSuburb = response.suburb;
      }, error => {
        console.log(error);
      }, () => {
      }
    )
  }

  private getAdminArea(address_component: []) {
    this.addressComponent = address_component;
    let len = address_component.length;
    for (let i = 0; i < len; i++) {
      switch (this.addressComponent[i].long_name) {
        case 'Gauteng': {
          this.province = 'GAUTENG';
          this.validateProvince = 'Gauteng';
          break;
        }
        case 'KwaZulu-Natal': {
          this.province = 'KWAZULU-NATAL';
          this.validateProvince = 'KwaZulu-Natal';
          break;
        }
        case 'kzn': {
          this.province = 'kzn';
          this.validateProvince = 'KWA_ZULU_NATAL';
          break;
        }
        case 'Western Cape': {
          this.province = 'WESTERN CAPE';
          this.validateProvince = 'Western Cape';
          break;
        }
        case 'Eastern Cape': {
          this.province = 'EASTERN CAPE';
          this.validateProvince = 'Eastern Cape';
          break;
        }
        case 'Limpopo': {
          this.province = 'LIMPOPO';
          this.validateProvince = 'Limpopo';
          break;
        }
        case 'Mpumalanga': {
          this.province = 'MPUMALANGA';
          this.validateProvince = 'Mpumalanga';
          break;
        }
        case 'Northern Cape': {
          this.province = 'NORTHERN CAPE';
          this.validateProvince = 'Northern Cape';
          break;
        }
        case 'North West': {
          this.province = 'NORTH WEST';
          this.validateProvince = 'North West';
          break;
        }
        case 'Free State': {
          this.province = 'FREE STATE';
          this.validateProvince = 'Free State';
          break;
        }
        default: {

          break;
        }

      }
    }
  }

  autoCompleteCallback2(selectedData: any) {
    if (!selectedData) {
      this.dropOffNotMsg = 'Location not found, Please enter nearest location.';
      this.dropOffNotFound = false;
    } else {
      for (let x = 0; x < selectedData.data.address_components.length; x++) {
        for (let y = 0; y < selectedData.data.address_components[x].types.length; y++) {
          for (let a = 0; a < selectedData.data.address_components[x].types.length; a++) {
            if (selectedData.data.address_components[x].types[a] == 'locality') {
              this.toSuburb = selectedData.data.address_components[x].long_name;
            }
            else if (selectedData.data.address_components[x].types[a] == 'sublocality') {
              this.newDropOffSuburb = selectedData.data.address_components[x].long_name;
            }
            else if (selectedData.data.address_components[x].types[a] == 'postal_code') {
              this.dropOffPCode = selectedData.data.address_components[x].long_name;
              console.log(this.dropOffPCode)
              // this.selectDropOffDropDownSuburb(this.dropOffPCode);
            }
            else if (selectedData.data.address_components[x].types[a] == 'administrative_area_level_1') {
              this.dropOffProvince = selectedData.data.address_components[x].long_name;
            }
          }
        }
      }
      if (this.dropOffPCode == null) {
        this.dropOffPostalCodeService(selectedData);
      }
      this.dropOffAddress = selectedData.data.description;
      this.dropOffCoordinate = [selectedData.data.geometry.location.lat, selectedData.data.geometry.location.lng];
      this.destinationCoordinate = { "lat": selectedData.data.geometry.location.lat, "lng": selectedData.data.geometry.location.lng };
      this.dropOffCoordinateLocation = { 'coordinates': this.dropOffCoordinate, 'address': this.dropOffAddress };
    }
  }

  // public selectDropOffDropDownSuburb(postalCode) {
  //   let myToken = JSON.parse(localStorage.getItem('userInfo')).token;
  //   if (this.dropOffPCode != null && this.dropOffPCode != undefined) {
  //     this.bookService.postalDetails(myToken, postalCode).subscribe(
  //       response => {
  //         this.dropOffExtraDetails = response;
  //         console.log(this.dropOffExtraDetails);
  //       }, error => {
  //         console.log(error);
  //       }
  //     );
  //   }
  //   else {
  //     this.dropOffExtraDetails = [];
  //     console.log(this.dropOffExtraDetails);
  //   }
  // }

  public selectedDropOffSuburb(toSuburb) {
    this.toDropDownSuburb = toSuburb;
  }

  promptPostalCode: string = '';

  public dropOffPostalCodeService(selectedData) {
    let latTest = selectedData.data.geometry.location.lat;
    let lngTest = selectedData.data.geometry.location.lng;

    let geocoder = new google.maps.Geocoder;
    const latlng = {
      lat: latTest,
      lng: lngTest,
    };
    geocoder.geocode(
      { location: latlng }, (results) => {
        if (results[0]) {
          let foundAddress = results[0].address_components;
          for (let x = 0; x < foundAddress.length; x++) {
            for (let y = 0; y < foundAddress[x].types.length; y++) {
              for (let a = 0; a < foundAddress[x].types.length; a++) {
                if (foundAddress[x].types[a] == 'postal_code') {
                  this.dropOffPCode = foundAddress[x].long_name;
                  console.log(this.dropOffPCode)
                  this.getDropOffSuburb(this.dropOffPCode);
                }
              }
            }
          }

          if (this.dropOffPCode == null) {
            this.promptPostalCode = "Please enter your area postal code below";
            $('#dropOffPostcalCode').modal('show');
          }

        }
      }
    );
  }

  public getDropOffExtraDetails(quote) {
    let myToken = JSON.parse(localStorage.getItem('userInfo')).token;
    this.dropOffPCode = quote;
    if (this.dropOffPCode != null || this.dropOffPCode != undefined) {
      this.bookService.postalDetails(myToken, this.dropOffPCode).subscribe(
        response => {
          this.dropOffExtraDetails = response;
          if (this.dropOffExtraDetails.length == 0) {
            this.enteredDropOffPostalCodeMsg = 'Invalid postal code, enter a valid postal code to select a suburb.';
          }
          else {
            this.enteredDropOffPostalCodeMsg = '';
          }
        }, error => {
          console.log(error);
        }
      );
    }
    else {
      this.dropOffExtraDetails = [];
    }
  }

  public capturedDropOffAddress() {

    if (this.enteredDropOffAddress == null || this.enteredDropOffAddress == '') {
      this.enteredDropOffAddressMsg = 'Please enter the address';
    }
    else if (this.enteredDropOffPostalCode == null || this.enteredDropOffPostalCode == '') {
      this.enteredDropOffPostalCodeMsg = 'Please enter the postal code';
    }
    else if (this.enteredDropOffSuburb == null || this.enteredDropOffSuburb == '') {
      this.enteredDropOffSuburbMsg = 'Please enter the suburb';
    }
    else if (this.enteredDropOffProvince == null || this.enteredDropOffProvince == '') {
      this.enteredDropOffProvinceMsg = 'Please enter the province';
    }
    else {
      this.disableDropOffAddress = false;
      this.enteredDropOffSuccessMsg = 'Address successfully captured.'
      this.toDropDownSuburb = this.enteredDropOffSuburb;
      this.dropOffPCode = this.enteredDropOffPostalCode;
      this.dropOffProvince = this.enteredDropOffProvince;
      this.dropOffAddress = this.enteredDropOffAddress
        .concat(', ' + this.toDropDownSuburb)
        .concat(', ' + this.dropOffPCode)
        .concat(', ' + this.dropOffProvince);
      this.dropOffCoordinate = ['-25.97521', '28.13954'];
      this.dropOffCoordinateLocation = { coordinates: this.dropOffCoordinate, address: this.dropOffAddress };

      //Clear error messages
      this.enteredDropOffAddressMsg = '';
      this.enteredDropOffSuburbMsg = '';
      this.enteredDropOffPostalCodeMsg = '';
      this.enteredDropOffProvinceMsg = '';
    }

  }

  public closeCapturedDropOffAddress() {
    this.enteredDropOffAddressMsg = '';
    this.enteredDropOffSuburbMsg = '';
    this.enteredDropOffPostalCodeMsg = '';
    this.enteredDropOffProvinceMsg = '';
  }

  public getBookingDates() {
    this.bookService.getBookingDate(this.pickUpPCode, this.dropOffPCode).subscribe(
      response => {
        console.log(response);
        if (response.pickUpDate != null) {
          this.dateObj = response;
          this.displayNewDate = this.dateObj.pickUpDate;
          this.workingDays = this.dateObj.workingDays;
        } else {
          var tate = new Date();
          var datePipe = new DatePipe('en-US');
          tate.setDate(tate.getDate());
          var dateObject = {
            "workingDays": [],
            "pickUpDate": datePipe.transform(tate, 'yyyy-MM-dd'),
            "dropOffSuburb": this.toSuburb,
            "pickUpSuburb": this.fromSuburb
          }
          this.dateObj = dateObject;
          console.log(this.dateObj);
        }
      }, error => {
        var tate = new Date();
        var datePipe = new DatePipe('en-US');
        tate.setDate(tate.getDate());
        var dateObject = {
          "workingDays": [],
          "pickUpDate": datePipe.transform(tate, 'yyyy-MM-dd'),
          "dropOffSuburb": this.toSuburb,
          "pickUpSuburb": this.fromSuburb
        }
        this.dateObj = dateObject;
        console.log(error);
      }, () => {
      }
    )
  }

  public geocodeLatLng(geocoder, userLat, userLong) {
    var latlng = { lat: userLat, lng: userLong };
    var self = this;
    geocoder.geocode({ 'location': latlng }, function (results, status) {
      if (status === 'OK') {
        var len = results[0].address_components.length;
        var data;
        for (var i = 0; i < len; i++) {
          if (results[0].address_components[i].short_name.length === 2 && results[0].address_components[i].long_name.length >= 7) {
            data = results[0].address_components[i];
            self.validateProvince = data.long_name;
            if (data.long_name === 'South Africa') {
              self.geocodeLatLngProvince(geocoder, results[1].address_components[1].long_name);
            }
            break;
          }
        }
        self.province = data.long_name;
        self.validateProvince = data.long_name;
        self.province = self.validateProvince;
      } else {
        self.province = 'UNKNOWN';
        self.province = self.validateProvince;
      }
    });
  }

  public geocodeLatLngProvince(geocoder, city) {
    var self = this;
    geocoder.geocode({ 'address': city }, function (results, status) {
      if (status === 'OK') {
        var len = results[0].address_components.length;
        var data;
        for (var i = 0; i < len; i++) {
          if (results[0].address_components[i].short_name.length === 2 && results[0].address_components[i].long_name.length >= 7) {
            data = results[0].address_components[i];
            self.validateProvince = data.long_name;
            break;
          }
        }
        self.province = data.long_name;
        self.province = self.validateProvince;
      } else {
        self.province = 'UNKNOWN';
        self.province = self.validateProvince;
      }
    });
  }

  public checkedPickup(pickUp: any) {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    if (pickUp) {
      this.disablePickUp = true;
      this.contact.name = object.owner.firstName;
      this.contact.email = object.owner.email;
      this.contact.mobile = object.owner.mobile;
      this.contact.surname = object.owner.surname;
    } else {
      this.disablePickUp = false;
      this.contact.name = '';
      this.contact.email = '';
      this.contact.mobile = '';
      this.contact.surname = '';
    }
  }

  suburbMsg: string = '';

  public getAllPrices() {

    if (this.pickUpAddress != null && this.dropOffAddress != null) {

      if (this.retail.canAddParcels) {

        if (this.selectedOption == 'SAME_DAY') {
          var d = new Date();
          var hours = d.getHours();
          if (this.pracels.length != 0) {
            if (hours >= 7 && hours <= 17) {
              this.bringDimensionPages = true;
              this.bringMultipleDrop = true;
              this.bringFleetPrices = true;
              this.bringFlashPrices = false;
              this.disableAddresses = false;
              this.disableDropOffAddress = false;
              this.msgStatus = '';
              this.getSameDayRates();
            } else {
              this.hourMsg = "Service only available from 06:00 AM to 18:00 PM.";
              $('#hourAheadValidation').modal('show');
            }
          } else {
            alert('Please add items before getting a quote.');
          }
        }
        else if (this.selectedOption == 'MULITPLE_DROPS') {
          if (this.pracels.length != 0) {
            this.bringDimensionPages = true;
            this.bringFlashPrices = true;
            this.bringFleetPrices = true;
            this.bringMultipleDrop = false;
            this.disableAddresses = false;
            this.disableDropOffAddress = false;
            this.msgStatus = '';
            this.getCourierRates();
            this.getBookingDates();
          } else {
            alert('Please add items before getting a quote.');
          }
        }
        else if (this.selectedOption == 'FLEET_OPTION') {
          if (this.pracels.length != 0) {
            this.bringDimensionPages = true;
            this.bringFlashPrices = true;
            this.bringMultipleDrop = true;
            this.bringFleetPrices = false;
            this.disableAddresses = false;
            this.disableDropOffAddress = false;
            this.msgStatus = '';
          }
          else {
            alert('Please add items before getting a quote.');
          }
        }

      }
      else {

        if (this.selectedOption == 'FLEET_OPTION') {
          if ((this.validateProvince === 'KwaZulu-Natal' || this.validateProvince === 'kzn') ||
            this.validateProvince === 'Western Cape' || this.validateProvince === 'Gauteng') {
            this.bringMultipleDrop = true;
            this.bringFlashPrices = true;
            this.bringFleetPrices = false;
            this.disableAddresses = false;
            this.disableDropOffAddress = false;
            this.tempAmount = 0;
            this.msgStatus = '';
          } else {
            this.provinceMessage = 'Droppa Fleet is currently unavailable in ' + this.pickUpProvince + '. Please use COURIER for your deliveries.';
            $('#provinceValidation').modal('show');
          }
        }
        else if (this.numOfParcels == 0) {
          this.msgStatus = "Parcel cannot be 0.";
        }
        else if (this.numOfParcels < 0) {
          this.msgStatus = "Parcel field cannot be a negative number.";
        }
        else if (this.numOfParcels == null) {
          this.msgStatus = "Parcel field cannot be empty.";
        }
        else if (this.numOfParcels > 8) {
          this.msgStatus = "Parcel over 8.";
        }
        else {
          this.msgStatus = '';
          this.getMultipleParcels();
          this.getBookingDates();
          this.disableAddresses = false;
          this.disableDropOffAddress = false;
        }
      }

    } else {
      this.msgStatus = 'Please enter the pickup and drop off address.';
      alert(this.msgStatus);
    }

  }

  public enterDimensions() {
    $('#enterDimensions').modal('show');
  }

  public addDimensions() {


    this.calculateTotalParcel();
    this.calculateTotalParcelDimensions();
    this.checkingParcelOverLimit();

    if (this.selectedOption != 'FLEET_OPTION') {

      if (this.overload) {
        this.checkingMsg = 'Please check that none of your individual parcels exceeds 70KGs.';
      }
      else {
        if (this.parcel_breadth == 0 || this.parcel_height == 0 || this.parcel_length == 0 || this.parcel_mass == 0) {
          this.checkingMsg = 'Please make sure the entered dimensions is not 0.';
          $('#validationModal').modal('show');
        }
        else if (this.parcel_breadth < 0 || this.parcel_height < 0 || this.parcel_length < 0 || this.parcel_mass < 0) {
          this.checkingMsg = 'Please make sure the entered dimensions is not a negative number.';
          $('#validationModal').modal('show');
        }
        else if (this.parcel_breadth > 150 || this.parcel_height > 150 || this.parcel_length > 150 || this.parcel_mass > 150) {
          this.checkingMsg = 'Please make sure the entered dimensions is not exceed over 150.';
          $('#validationModal').modal('show');
        }
        else {

          let courierObj = {
            parcel_length: this.parcel_length,
            parcel_breadth: this.parcel_breadth,
            parcel_height: this.parcel_height,
            parcel_mass: this.parcel_mass
          };

          this.pracelDims.push(courierObj);

          if (this.retail.canAddParcels) {
            this.pracels.push(
              this.pracel = {
                parcel_length: this.parcel_length,
                parcel_breadth: this.parcel_breadth,
                parcel_height: this.parcel_height,
                parcel_mass: this.parcel_mass,
                customer_reference: this.customer_reference,
                costCenter: this.cost_center_num,
                parcel_number: this.pracels.length + 1,
                noBox: this.numOfBoxes
              }
            );
          }
          this.calculateTotalParcel();
          this.calculateTotalParcelDimensions();
          this.cost_center_num = '';
          this.customer_reference = '';
          this.parcel_length = '';
          this.parcel_height = '';
          this.parcel_mass = '';
          this.parcel_breadth = '';
          this.numOfBoxes = '';
        }
      }

    }
    else {
      let courierObj = {
        parcel_length: this.parcel_length,
        parcel_breadth: this.parcel_breadth,
        parcel_height: this.parcel_height,
        parcel_mass: this.parcel_mass
      };

      this.pracelDims.push(courierObj);

      if (this.retail.canAddParcels) {
        this.pracels.push(
          this.pracel = {
            parcel_length: this.parcel_length,
            parcel_breadth: this.parcel_breadth,
            parcel_height: this.parcel_height,
            parcel_mass: this.parcel_mass,
            customer_reference: this.customer_reference,
            costCenter: this.cost_center_num,
            parcel_number: this.pracels.length + 1,
            noBox: this.numOfBoxes
          }
        );
      }
      this.calculateTotalParcel();
      this.calculateTotalParcelDimensions();
      this.cost_center_num = '';
      this.customer_reference = '';
      this.parcel_length = '';
      this.parcel_height = '';
      this.parcel_mass = '';
      this.parcel_breadth = '';
      this.numOfBoxes = '';
    }

  }

  totalMass: any = 0;

  public calculateTotalParcel() {
    var totalParcelMass = 0;
    // without tag
    this.pracelDims.forEach(element => {
      totalParcelMass += element.parcel_mass;
    });
    this.totalMass = totalParcelMass;
  }

  public calculateTotalParcelDimensions() {
    var totalParcelMass = 0;
    // with tag
    this.pracels.forEach(element => {
      totalParcelMass += element.parcel_mass;
    });
    this.totalMass = totalParcelMass;
  }

  public fleetSelectedOption() {
    this.resetPickedImages();
    this.controlPickedSelection();
    this.isExpress_ = false;
    this.isExpress = false;
    this.isFleetPicked = true;
    this.selectedOption = 'FLEET_OPTION';
    this.disableAddresses = true;
    this.disableDropOffAddress = true;
    this.dropOffExtraDetails = [];
    this.pickUpExtraDetails = [];
    $(".fleetHighlight").addClass("expressChoices_");
    $(".fleetHighlight").removeClass("expressChoices");
  }

  public multipleDrops() {
    this.resetPickedImages();
    this.controlPickedSelection();
    this.isExpress_ = true;
    this.isExpress = true;
    this.isCourierPicked = true;
    this.selectedOption = 'MULITPLE_DROPS';
    this.disableAddresses = true;
    this.disableDropOffAddress = true;
    this.dropOffExtraDetails = [];
    this.pickUpExtraDetails = [];
    $(".multipleDropsHighlight").addClass("expressChoices_");
    $(".multipleDropsHighlight").removeClass("expressChoices");
  }

  public flashSelectedOption() {
    this.resetPickedImages();
    this.controlPickedSelection();
    this.isExpress_ = true;
    this.isRoadPicked = true;
    this.isExpress = true;
    this.selectedOption = 'SAME_DAY';
    this.disableAddresses = true;
    this.disableDropOffAddress = true;
    this.dropOffExtraDetails = [];
    this.pickUpExtraDetails = [];
    $(".flashHighlight").addClass("expressChoices_");
    $(".flashHighlight").removeClass("expressChoices");
  }

  public backToBookingDetails() {
    this.bringFlashPrices = true;
    this.bringFleetPrices = true;
    this.bringMultipleDrop = true;
    this.disableAddresses = true;
    this.bringDimensionPages = true;
    this.disableDropOffAddress = true;
    this.pickUpExtraDetails = [];
    this.dropOffExtraDetails = [];
  }

  public getSameDayRates() {

    var data = {
      "destination": this.destinationCoordinate,
      "departure": this.departureCoordinate
    };
    this.calculateTotalParcel();
    this.calculateTotalParcelDimensions();
    this.bookService.getDistance(data).subscribe(
      (response) => {
        var distanceResponse = response.json();
        this.distance = distanceResponse.rows[0].elements[0].distance.inMeters;
        this.distance = Math.round(this.distance / 1000);

        var quoteBody = {
          "mass": this.totalMass,
          "distance": this.distance,
          "fromSuburb": this.fromSuburb,
          "toSuburb": this.toSuburb,
          "pickUpAddress": this.pickUpAddress,
          "dropOffAddress": this.dropOffAddress,
          "pickUpProvince": this.pickUpProvince,
          "dropOffProvince": this.dropOffProvince,
          "pickUpPCode": this.pickUpPCode,
          "dropOffPCode": this.dropOffPCode,
          "parcelDimensions": this.pracelDims,
          "platform": "web",
        }

        // if (this.pracelDims.length <= 0) {
        //   quoteBody.parcelDimensions = this.pracelDims
        // } else {
        //   // console.log('does not hit');
        // }

        this.bookService.getQuote(quoteBody).subscribe(
          response => {
            this.quoteResponse = response.json();
            this.flashFlight = Math.round(response.json().totalFlight);
            this.airlineAmt = response.json().totalFlight;
            this.pickUpAmt = response.json().flashFlightFromPickUp;
            this.dropOffAmt = response.json().flashFlightToDropff;
            this.flashRoad = Math.round(response.json().flashRoad);
            this.sheshaMode = Math.round(response.json().sheshaAmount);
            if (this.flashFlight == 0) {
              this.flightAvailable = false;
              this.flashRoadAvailable = true;
            }
            else {
              var d = new Date();
              var hours = d.getHours();
              if (hours < 12) {
                this.flightAvailable = true;
                this.flashRoadAvailable = true;
              } else {
                this.flashFlight = 0;
                this.flightAvailable = false;
                this.flashRoadAvailable = true;
              }
            }
            if (this.mass > 15) {
              this.sheshaAvailable = false;
              this.onFlashRoadhSelected();
              this.sheshaMode = 0;
            }
            else {
              this.onDashSelected();
            }
            if (this.sheshaMode == 0) {
              if (this.flightAvailable) {
                this.onFlashFlightSelected();
              } else {
                this.onFlashRoadhSelected();
              }
            } else {
              this.onDashSelected();
              this.dashAvailable = true;
            }
            this.loadservice.removeloader();
          }, error => {
            console.log(error);
            this.loadservice.removeloader();
          }
        );
      }, error => {
        console.log(error);
      }, () => {
      });


  }

  public getCourierRates() {

    // var data = {
    //   "destination": this.destinationCoordinate,
    //   "departure": this.departureCoordinate
    // };

    // if (this.destinationCoordinate == null && this.departureCoordinate == null) {

    //   this.departureCoordinate = { "lat": '-25.8730769', "lng": '28.1847943' };
    //   this.destinationCoordinate = { "lat": '-25.8615762', "lng": '28.185672' };

    //   data = {
    //     "destination": this.destinationCoordinate,
    //     "departure": this.departureCoordinate
    //   }

    // }

    var quoteBody = {
      "mass": this.totalMass,
      "distance": 1,
      "fromSuburb": this.fromDropDownSuburb,
      "toSuburb": this.toDropDownSuburb,
      "pickUpAddress": this.pickUpAddress,
      "dropOffAddress": this.dropOffAddress,
      "pickUpProvince": this.pickUpProvince,
      "dropOffProvince": this.dropOffProvince,
      "pickUpPCode": this.pickUpPCode,
      "dropOffPCode": this.dropOffPCode,
      "parcelDimensions": this.pracelDims,
      "platform": "web",
    }

    // if (this.pracelDims.length <= 0) {
    //   quoteBody.parcelDimensions = this.pracelDims
    // } else {
    //   // console.log('does not hit');
    // }

    this.bookService.getNewSkynetPrices(quoteBody).subscribe(
      response => {
        this.quoteResponse = response.json();

        if (response.json().expressCourier > 0) {
          this.loadservice.removeloader();
          this.isCourierExpress = false;
          this.expressCourier = Math.round(response.json().expressCourier);
          this.tempAmount = this.expressCourier;
          this.onExpressCourierSelected();
        }

        if (response.json().expressBudgetAmt > 0) {
          this.loadservice.removeloader();
          this.isExpressBudget = false;
          this.budgetCourier = Math.round(response.json().expressBudgetAmt);
        }

      }, error => {
        this.loadservice.removeloader();
        console.log(error);
      }, () => { }
    );

    this.calculateTotalParcel();
    this.calculateTotalParcelDimensions();



  }

  public getNormalDroppaRates(vehicleType) {
    var object = JSON.parse(localStorage.getItem('userInfo'));

    var data = {
      "destination": this.destinationCoordinate,
      "departure": this.departureCoordinate
    };

    this.bookService.getDistance(data).subscribe(
      (response) => {
        var distanceResponse = response.json();
        this.distance = distanceResponse.rows[0].elements[0].distance.inMeters;
        this.distance = Math.round(this.distance / 1000);

        this.bookService.getRetailVehicleRate(this.retail.oid, vehicleType)
          .subscribe(
            (res: Response) => {

              var bookingData = {
                "distance": this.distance,
                "retailOid": this.retail.oid,
                "totalPrice": 0,
                "vehicleType": vehicleType
              };
              this.bookService.getRetailbookingPrice(bookingData, object.token)
                .subscribe(
                  (response: Response) => {

                    this.bookingPrice = response.json();
                    this.sharedService.setTotalAmount(this.bookingPrice.totalPrice);
                    this.tempAmount = this.bookingPrice.totalPrice;
                    this.tempAmount = Math.round(this.tempAmount);
                    this.price = this.bookingPrice.totalPrice;
                    this.dir = {
                      origin: { lat: this.pickUpCoordinate[0], lng: this.pickUpCoordinate[1] },
                      destination: { lat: this.dropOffCoordinate[0], lng: this.dropOffCoordinate[1] }
                    };
                    this.loadservice.removeloader();
                    window.scrollTo(0, 0)
                  },
                  (err) => {
                    console.log(err);
                    this.loadservice.removeloader()
                  }
                );
            },
            (err) => {
              console.log(err);
              this.msg = err.json().message;
              $('#results').modal('show');
              this.loadservice.removeloader()
            }
          )
      }), error => {
        console.log(error);
        this.loadservice.removeloader();
      };


  }

  public onSelectChange(vehicleType) {

    if (this.isExpress) {
      this.isExpress = true;
      this.bookingDate = "";
      this.bookingTime = "";
    }

    this.vehicleType = vehicleType.vehicleType;

    this.getNormalDroppaRates(vehicleType.vehicleType);

    for (let myChild of this.vehicleTypeRate) {
      myChild.BackgroundColour = "#CCCCCC";
    }

    if (vehicleType.vehicleType == 'MINI_VAN') {
      this.vehicle = this.miniVan
      localStorage.setItem('vehicleType', this.vehicle);
      vehicleType.BackgroundColour = "black";
    }
    else if (vehicleType.vehicleType == 'ONE_TON_TRUCK') {
      this.vehicle = this.oneTon
      localStorage.setItem('vehicleType', this.vehicle);
      vehicleType.BackgroundColour = "black";
    }
    else if (vehicleType.vehicleType == 'ONE_HALF_TRUCK') {
      this.vehicle = this.oneHalfTon
      localStorage.setItem('vehicleType', this.vehicle);
      vehicleType.BackgroundColour = "black";
    }
    else if (vehicleType.vehicleType == 'FOUR_TON_TRUCK') {
      this.vehicle = this.fourTon
      localStorage.setItem('vehicleType', this.vehicle);
      vehicleType.BackgroundColour = "black";
    }
    else if (vehicleType.vehicleType == 'DROPPA_EXPRESS') {
      this.isExpress = true;
      this.vehicle = this.express
      localStorage.setItem('vehicleType', this.vehicle);
      vehicleType.BackgroundColour = "black";
    }
  }

  public onFlashRoadhSelected() {
    this.controlSelection();
    this.resetImages();
    this.transportMode = 'ROAD';
    this.type = 'FLASH';
    this.vehicleType = 'DROPPA_EXPRESS';
    this.tempAmount = Math.round(this.flashRoad);
    $(".road").addClass("expressChoices_");
    $(".road").removeClass("expressChoices");
    this.isRoad = true;
  }

  public onDashSelected() {
    this.controlSelection();
    this.resetImages();
    this.transportMode = 'ROAD';
    this.type = 'DASH';
    this.vehicleType = 'DASH';
    this.tempAmount = Math.round(this.sheshaMode);
    $(".dash").addClass("expressChoices_");
    $(".dash").removeClass("expressChoices");
    this.isDash = true;
  }

  public onFlashFlightSelected() {
    this.controlSelection();
    this.resetImages();
    this.transportMode = 'AIR';
    this.type = 'FLASH';
    this.vehicleType = 'DROPPA_EXPRESS';
    this.tempAmount = Math.round(this.flashFlight);
    this.airlineAmt = this.airlineAmt;
    this.pickUpAmt = this.pickUpAmt;
    this.dropOffAmt = this.dropOffAmt;
    $(".flight").addClass("expressChoices_");
    $(".flight").removeClass("expressChoices");
    this.isFlight = true;
  }

  public onExpressCourierSelected() {
    this.controlSelection();
    this.resetImages();
    this.transportMode = 'ROAD';
    this.type = 'EXPRESS_COURIER';
    this.vehicleType = 'DROPPA_EXPRESS';
    this.tempAmount = Math.round(this.expressCourier);
    $(".courierExp").addClass("expressChoices_");
    $(".courierExp").removeClass("expressChoices");
    this.isCourier = true;
  }

  public onBudgetCourierSelected() {
    this.controlSelection();
    this.resetImages();
    this.transportMode = 'ROAD';
    this.type = 'BUDGET_COURIER';
    this.vehicleType = 'DROPPA_EXPRESS';
    this.tempAmount = Math.round(this.budgetCourier);
    $(".budget").addClass("expressChoices_");
    $(".budget").removeClass("expressChoices");
    this.isBudget = true;
  }



  private controlSelection() {
    $(".road").addClass("expressChoices").removeClass("expressChoices_");
    $(".flight").addClass("expressChoices").removeClass("expressChoices_");
    $(".courierExp").addClass("expressChoices").removeClass("expressChoices_");
    $(".budget").addClass("expressChoices").removeClass("expressChoices_");
    $(".dash").addClass("expressChoices").removeClass("expressChoices_");
  }

  private resetImages() {
    this.isBudget = false;
    this.isCourier = false;
    this.isRoad = false;
    this.isFlight = false;
    this.isDash = false;
  }

  public resetPickedImages() {
    this.isRoadPicked = false;
    this.isCourierPicked = false;
    this.isFleetPicked = false;
  }

  public controlPickedSelection() {
    $(".flashHighlight").addClass("expressChoices").removeClass("expressChoices_");
    $(".multipleDropsHighlight").addClass("expressChoices").removeClass("expressChoices_");
    $(".fleetHighlight").addClass("expressChoices").removeClass("expressChoices_");
  }



  decrementLabour(labour) {
    if (labour > 0) {
      this.contact.labours -= 1;
      this.onLabour(this.contact.labours);
    } else {
      this.contact.labours = 0;
      this.onLabour(this.contact.labours);
    }
  }

  incrementLabour(labour) {
    if (labour >= 0) {
      this.contact.labours += 1;
      this.onLabour(this.contact.labours);
    } else {
      this.contact.labours = 0;
      this.onLabour(this.contact.labours);
    }
  }

  public onLabour(labour: number) {
    if (labour != null) {
      if (labour >= 0) {
        this.tempAmount = (((this.price + this.contact.labours * 100)));
      }
    } else {
      this.tempAmount = ((this.price + (this.contact.labours * 100)) * this.load);
    }
  }

  public getBookingDate(pickUpDate: string): string {
    var currentDate = new Date(pickUpDate);
    let date = currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate();
    let monthNo = (currentDate.getMonth() + 1) <= 9 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
    let hour = currentDate.getHours() <= 9 ? '0' + currentDate.getHours() : currentDate.getHours();
    let minutes = currentDate.getMinutes() <= 9 ? '0' + currentDate.getMinutes() : currentDate.getMinutes();
    var second = currentDate.getSeconds() <= 9 ? '0' + currentDate.getSeconds() : currentDate.getSeconds();
    return currentDate.getFullYear() + '-' + monthNo + '-' + date + 'T' + hour + ':' + minutes + ':' + second;
  }



  public toConfirmSameDayDelivery() {
    if (this.distance > 15 && this.type == "DASH") {
      alert('DASH service is only available within a 15KM radius.');
    }
    else {

      var object = JSON.parse(localStorage.getItem('userInfo'));
      var datePipe = new DatePipe("en-US");
      var timestamp = new Date().valueOf();
      var currentDate = new Date();
      let value = null;
      var timestamp = new Date().valueOf();
      var datePipe = new DatePipe('en-US');
      let valueTime = null;

      value = datePipe.transform(currentDate, 'yyyy-MM-dd');
      valueTime = datePipe.transform(currentDate, 'HH:mm a');

      var bookingData = {
        'dropOff': {
          'firstName': this.dropContact.name,
          'lastName': this.dropContact.surname != null ? this.dropContact.surname : object.owner.surname,
          'phone': this.dropContact.mobile,
          'email': object.owner.email,
          'companyName': this.companyDropOffName != null ? this.companyDropOffName : object.owner.firstName,
          'complex': this.complexDropOff != null ? this.complexDropOff : null,
          'unitNo': this.unitDropOff != null ? this.unitDropOff : null
        },
        'pickUp': {
          'firstName': this.contact.name,
          'lastName': this.contact.surname != null ? this.contact.surname : object.owner.surname,
          'phone': this.contact.mobile,
          'email': object.owner.email,
          'companyName': this.companyPickUpName != null ? this.companyPickUpName : object.owner.firstName,
          'complex': this.complexPickUp != null ? this.complexPickUp : null,
          'unitNo': this.unitPickUp != null ? this.unitPickUp : null
        },
        "customer": object.owner,
        "price": this.tempAmount,
        "vehicleType": this.vehicleType,
        "pickUpAddress": this.pickUpAddress,
        "pickUpTime": valueTime,
        "phoneNo": object.owner.mobile,
        "pickUpDate": value,
        "dropOffAddress": this.dropOffAddress,
        "comment": this.comment,
        "labour": this.contact.labours,
        "timestamp": timestamp,
        "status": "BUCKET",
        "dropFloors": this.dropOffFloors,
        "pickUpFloors": this.floorsPickUp,
        "pickUpCoordinate": this.pickUpCoordinateLocation,
        "dropOffCoordinate": this.dropOffCoordinateLocation,
        "load": this.load == 0 ? 1 : this.load,
        "canopy": this.canopy,
        "dropOid": null,
        "distance": this.distance,
        "province": this.validateProvince,
        'transportMode': this.transportMode,
        'type': this.type,
        "itemMass": this.mass != null ? this.mass : null,
        'isItemPicked': false,
        'mainCityOid': null,
        'fromSuburb': this.fromSuburb,
        'toSuburb': this.toSuburb,
        'pickUpPCode': this.pickUpPCode,
        'dropOffPCode': this.dropOffPCode,
        'pickUpAmt': this.pickUpAmt,
        'airlineAmt': this.airlineAmt,
        'dropOffAmt': this.dropOffAmt,
        'destinationProvince': this.dropOffProvince,
        'isPayed': false,
        'paymentType': "ONLINE_PAY",
        'isExpress': true,
        "customerReference": this.customerRef,
        "costCenter": this.costCentreNumber,
        "parcelDimensions": this.pracelDims,
        "parcels": null
      };
      if (this.pracels.length > 0) {
        bookingData.parcels = this.pracels
      }

      localStorage.setItem('bookingObj', JSON.stringify(bookingData));
      this.router.navigateByUrl('/confirm-single-delivery');

    }
  }

  public onHome() {
    this.router.navigateByUrl('/retail-book-review');
  }

  public continueBooking() {
    if (this.selectedOption == 'SAME_DAY') {
      this.toConfirmSameDayDelivery();
    } else {
      this.createMultipleDrop();
    }
  }

  public checkingDeliveryDates(theDateFound: Date): boolean {

    var datePipe = new DatePipe('en-US');
    let valid = false;
    const pickUpDate = moment(theDateFound).toDate();
    let day = moment(pickUpDate).format('dddd');
    if (pickUpDate.getDay() == 6 || pickUpDate.getDay() == 0) {

      const foundDisplayDate = new Date(this.displayNewDate);

      if (foundDisplayDate > pickUpDate) {
        this.bookingDate = datePipe.transform(foundDisplayDate, 'yyy-MM-dd');
        valid = true;
      }

      this.dateMessage = "Please note that Courier and Budget is not available on weekend.";
      valid = false;
    }
    else if (!this.workingDays.includes(day)) {
      var message = "";
      for (let element of this.workingDays) {
        message = message + "" + element + ", ";
      }
      this.dateMessage = "There is no pick up on " + day + " at " + this.fromSuburb + ", available " + message;
      valid = false;
    }
    else {
      this.bookingDate = datePipe.transform(pickUpDate, 'yyyy-MM-dd');
      valid = true;
    }

    return valid;
  }

  public createMultipleDrop() {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    var currentDate = new Date();
    let date = currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate();
    let monthNo = (currentDate.getMonth() + 1) <= 9 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
    let hour = currentDate.getHours() <= 9 ? '0' + currentDate.getHours() : currentDate.getHours();
    let minutes = currentDate.getMinutes() <= 9 ? '0' + currentDate.getMinutes() : currentDate.getMinutes();
    var second = currentDate.getSeconds() <= 9 ? '0' + currentDate.getSeconds() : currentDate.getSeconds();
    var dropTime = currentDate.getFullYear() + '-' + monthNo + '-' + date + 'T' + hour + ':' + minutes + ':' + second;

    var datePipe = new DatePipe("en-US");
    let value = null;
    var timestamp = new Date().valueOf();
    var datePipe = new DatePipe('en-US');
    let valueTime = null;

    if (this.bookingDate != null) {
      value = datePipe.transform(this.bookingDate, 'yyyy-MM-dd');
      var dateHalf2 = new Date(this.bookingDate.getFullYear(), Number(monthNo), Number(date), 14, 30);
      valueTime = datePipe.transform(dateHalf2, 'HH:mm a');
    }
    else {
      value = datePipe.transform(this.displayNewDate, 'yyyy-MM-dd');
      var dateHalf2 = new Date(currentDate.getFullYear(), Number(monthNo), Number(date), 14, 30);
      valueTime = datePipe.transform(dateHalf2, 'HH:mm a');
    }

    var bookingData = {
      'dropOff': {
        'firstName': this.dropContact.name,
        'lastName': this.dropContact.surname != null ? this.dropContact.surname : object.owner.surname,
        'phone': this.dropContact.mobile,
        'email': object.owner.email,
        'companyName': this.companyDropOffName != null ? this.companyDropOffName : object.owner.firstName,
        'complex': this.complexDropOff != null ? this.complexDropOff : null,
        'unitNo': this.unitDropOff != null ? this.unitDropOff : null
      },
      'pickUp': {
        'firstName': this.contact.name,
        'lastName': this.contact.surname != null ? this.contact.surname : object.owner.surname,
        'phone': this.contact.mobile,
        'email': object.owner.email,
        'companyName': this.companyPickUpName != null ? this.companyPickUpName : object.owner.firstName,
        'complex': this.complexPickUp != null ? this.complexPickUp : null,
        'unitNo': this.unitPickUp != null ? this.unitPickUp : null
      },
      "customer": object.owner,
      "price": this.tempAmount,
      "vehicleType": this.vehicleType,
      "pickUpAddress": this.pickUpAddress,
      "pickUpTime": valueTime,
      "phoneNo": object.owner.mobile,
      "pickUpDate": value,
      "dropOffAddress": this.dropOffAddress,
      "comment": this.comment,
      "labour": this.contact.labours,
      "timestamp": timestamp,
      "status": "BUCKET",
      "dropFloors": this.dropOffFloors,
      "pickUpFloors": this.floorsPickUp,
      "pickUpCoordinate": this.pickUpCoordinateLocation,
      "dropOffCoordinate": this.dropOffCoordinateLocation,
      "load": 1,
      "canopy": this.canopy,
      "dropOid": null,
      "distance": this.distance,
      "province": this.validateProvince,
      'transportMode': this.transportMode,
      'type': this.type,
      "itemMass": this.mass != null ? this.mass : null,
      'isItemPicked': false,
      'mainCityOid': null,
      'fromSuburb': this.fromDropDownSuburb,
      'toSuburb': this.toDropDownSuburb,
      'pickUpPCode': this.pickUpPCode,
      'dropOffPCode': this.dropOffPCode,
      'pickUpAmt': this.pickUpAmt,
      'airlineAmt': this.airlineAmt,
      'dropOffAmt': this.dropOffAmt,
      'destinationProvince': this.dropOffProvince,
      'isPayed': false,
      'paymentType': "ONLINE_PAY",
      'isExpress': true,
      "customerReference": this.customerRef,
      "costCenter": this.costCentreNumber,
      "parcelDimensions": this.pracelDims,
      "parcels": null
    };

    console.log(bookingData);
    if (this.pracels.length > 0) {
      bookingData.parcels = this.pracels
    }

    if (bookingData.fromSuburb == undefined && bookingData.toSuburb == undefined) {
      bookingData.fromSuburb = this.fromSuburb;
      bookingData.toSuburb = this.toSuburb;
      console.log(this.fromSuburb + ' ' + this.toSuburb);
    }

    var drop = {
      "name": "Drop",
      "notes": "be careful",
      "cost": (this.tempAmount * 100) - this.promoCodeAmnt,
      "clientOid": object.owner.oid,
      "vehicleOid": '1521105441081',
      "driverOid": "575ae6bd30bc5d5017477143",
      "from": {
        "coordinates": [
          "-26.002483",
          "28.099503"
        ],
        'address': this.pickUpAddress,
      },
      'to': {
        'coordinates': [
          '-26.002483',
          '28.099503'
        ],
        'address': this.dropOffAddress,
      },
      'when': dropTime,
      'atp': dropTime,
      'eta': dropTime,
      'ata': dropTime,
      'droplets': [
        {
          'itemOid': '575ae6bd30bc5d5017477143',
          'notes': 'new'
        }
      ],
      'booking': bookingData,
    };

    let myToken = JSON.parse(localStorage.getItem('userInfo')).token;

    if (this.checkingDeliveryDates(value)) {
      this.loadservice.removeloader();
      this.pushDrop(drop, myToken, bookingData);
    } else {
      alert(this.dateMessage);
    }

  }

  private pushDrop(drop: any, myToken: string, bookingData: any) {
    this.bookService.plainDrop(drop, myToken)
      .subscribe(
        (res: Response) => {
          bookingData.dropOid = res.json().oid;
          var bucket = {
            "bookings": bookingData,
            "retailOid": this.retail.oid,
            "price": bookingData.price,
            "status": "BUCKET_AWAITING",
            "vehicleType": bookingData.vehicleType,
            "dropOid": res.json().oid,
            "date": this.getBookingDate(bookingData.pickUpDate),
            "time": bookingData.pickUpTime,
            "province": this.validateProvince != null ? this.validateProvince : this.province,
            "type": this.type,
            "isExpress": true
          }
          localStorage.setItem('BookingData', JSON.stringify(bookingData));
          this.bookService.bucketsBook(bucket, myToken)
            .subscribe(
              (response: Response) => {
                this.bucketBooking = response;
                localStorage.setItem('bucketBooking', JSON.stringify(this.bucketBooking));
                this.loadservice.removeloader();
                this.onHome();
              },
              (err) => {
                console.log(err);
                this.loadservice.removeloader();
                this.bookingText = 'Error occured while making a booking, please try again.';
                $('#results2').modal('show');
              }
            );
        },
        (error) => {
          console.log(error);
          this.loadservice.removeloader();
          this.bookingText = 'Unable to make a booking.';
          $('#results3').modal('show');
        }
      );
  }

  public DateAndTimePopUp() {
    $('#dateAndTime').modal('show');
  }

  public createFleetDrop() {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    var currentDate = new Date();
    let date = currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate();
    let monthNo = (currentDate.getMonth() + 1) <= 9 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
    let hour = currentDate.getHours() <= 9 ? '0' + currentDate.getHours() : currentDate.getHours();
    let minutes = currentDate.getMinutes() <= 9 ? '0' + currentDate.getMinutes() : currentDate.getMinutes();
    var second = currentDate.getSeconds() <= 9 ? '0' + currentDate.getSeconds() : currentDate.getSeconds();
    var dropTime = currentDate.getFullYear() + '-' + monthNo + '-' + date + 'T' + hour + ':' + minutes + ':' + second;

    var dateValue = this.fleetBookingDate;
    var datePipe = new DatePipe("en-US");
    let value = datePipe.transform(dateValue, 'yyyy-MM-dd');
    var timestamp = new Date().valueOf();
    var datePipe = new DatePipe('en-US');
    var timeDate = new Date();
    var hours = this.fleetBookingTime.split(':');
    timeDate.setHours(hours[0], hours[1]);
    let valueTime = datePipe.transform(timeDate, 'HH:mm a');

    var bookingData = {
      'dropOff': {
        'firstName': this.dropContact.name,
        'lastName': this.dropContact.surname != null ? this.dropContact.surname : object.owner.surname,
        'phone': this.dropContact.mobile,
        'email': object.owner.email,
        'companyName': this.companyDropOffName != null ? this.companyDropOffName : object.owner.firstName,
        'complex': this.complexDropOff != null ? this.complexDropOff : null,
        'unitNo': this.unitDropOff != null ? this.unitDropOff : null
      },
      'pickUp': {
        'firstName': this.contact.name,
        'lastName': this.contact.surname != null ? this.contact.surname : object.owner.surname,
        'phone': this.contact.mobile,
        'email': object.owner.email,
        'companyName': this.companyPickUpName != null ? this.companyPickUpName : object.owner.firstName,
        'complex': this.complexPickUp != null ? this.complexPickUp : null,
        'unitNo': this.unitPickUp != null ? this.unitPickUp : null
      },
      "customer": object.owner,
      "price": this.tempAmount,
      "vehicleType": this.vehicleType,
      "pickUpAddress": this.pickUpAddress,
      "pickUpTime": valueTime,
      "phoneNo": object.owner.mobile,
      "pickUpDate": value,
      "dropOffAddress": this.dropOffAddress,
      "comment": this.comment,
      "labour": this.contact.labours,
      "timestamp": timestamp,
      "status": "BUCKET",
      "dropFloors": this.dropOffFloors,
      "pickUpFloors": this.floorsPickUp,
      "pickUpCoordinate": this.pickUpCoordinateLocation,
      "dropOffCoordinate": this.dropOffCoordinateLocation,
      "load": this.load == 0 ? 1 : this.load,
      "canopy": this.canopy,
      "dropOid": null,
      "distance": this.distance,
      "province": this.validateProvince,
      'transportMode': 'ROAD',
      'type': 'DEDICATED',
      "itemMass": this.mass != null ? this.mass : null,
      'isItemPicked': false,
      'mainCityOid': null,
      'fromSuburb': this.fromSuburb,
      'toSuburb': this.toSuburb,
      'pickUpPCode': this.pickUpPCode,
      'dropOffPCode': this.dropOffPCode,
      'pickUpAmt': this.pickUpAmt,
      'airlineAmt': this.airlineAmt,
      'dropOffAmt': this.dropOffAmt,
      'destinationProvince': this.dropOffProvince,
      'isPayed': false,
      'paymentType': "ONLINE_PAY",
      "customerReference": this.customerRef,
      "costCenter": this.costCentreNumber,
      "parcelDimensions": this.pracelDims,
      "parcels": null
    };

    if (this.pracels.length > 0) {
      bookingData.parcels = this.pracels
    }

    var obect = {
      'pickUpAddress': this.pickUpCoordinateLocation,
      'dropOffAddress': this.dropOffCoordinateLocation,
      'price': this.tempAmount + this.promoCodeAmnt,
      'vehicleType': this.vehicleType
    };

    localStorage.setItem('addressData', JSON.stringify(obect));

    var drop = {
      "name": "Drop",
      "notes": "be careful",
      "cost": (this.tempAmount * 100) - this.promoCodeAmnt,
      "clientOid": object.owner.oid,
      "vehicleOid": '1521105441081',
      "driverOid": "575ae6bd30bc5d5017477143",
      "from": {
        "coordinates": [
          "-26.002483",
          "28.099503"
        ],
        'address': this.pickUpAddress,
      },
      'to': {
        'coordinates': [
          '-26.002483',
          '28.099503'
        ],
        'address': this.dropOffAddress,
      },
      'when': dropTime,
      'atp': dropTime,
      'eta': dropTime,
      'ata': dropTime,
      'droplets': [
        {
          'itemOid': '575ae6bd30bc5d5017477143',
          'notes': 'new'
        }
      ],
      'booking': bookingData,
    };

    var obect1 = {
      'pickUpAddress': this.pickUpCoordinateLocation,
      'dropOffAddress': this.dropOffCoordinateLocation,
      'price': this.tempAmount - this.promoCodeAmnt,
      'vehicleType': this.vehicleType
    };

    localStorage.setItem('addressData', JSON.stringify(obect1));
    var currentDate = new Date();
    var timeArray = valueTime.split(' ');
    var bookingTimeArray = timeArray[0].split(':');
    let TimeSeparatorIndex = valueTime.indexOf(":");
    this.hour = parseInt(valueTime.substring(0, TimeSeparatorIndex));
    this.bookingDates = new Date(datePipe.transform(dateValue, 'yyyy-MM-dd'));
    this.bookingDates.setHours(this.hour);
    this.bookingDates.setMinutes(parseInt(bookingTimeArray[1]));
    let myToken = JSON.parse(localStorage.getItem('userInfo')).token;

    this.loadservice.addloader();

    let todaysDate = datePipe.transform(currentDate, 'yyyy-MM-dd');
    this.workingHours = parseInt(valueTime.substring(0, TimeSeparatorIndex));

    if (value >= todaysDate) {
      this.validationMsg = '';
      if (this.workingHours > 7 && this.workingHours < 18) {
        this.hourMsg = '';
        if (this.tempAmount != 0) {
          this.bookService.plainDrop(drop, myToken)
            .subscribe(
              (res: Response) => {
                bookingData.dropOid = res.json().oid;
                var bucket = {
                  "bookings": bookingData,
                  "retailOid": this.retail.oid,
                  "price": bookingData.price,
                  "status": "BUCKET_AWAITING",
                  "vehicleType": bookingData.vehicleType,
                  "dropOid": res.json().oid,
                  "date": this.getBookingDate(value),
                  "time": bookingData.pickUpTime,
                  "province": this.validateProvince
                }

                localStorage.setItem('BookingData', JSON.stringify(bookingData));
                this.bookService.bucketsBook(bucket, myToken)
                  .subscribe(
                    (response: Response) => {
                      this.bucketBooking = response;
                      $('#dateAndTime').modal('hide');
                      localStorage.setItem('bucketBooking', JSON.stringify(this.bucketBooking));
                      this.loadservice.removeloader();
                      this.onHome();
                    },
                    (err) => {
                      console.log(err);
                      this.loadservice.removeloader();
                      this.bookingText = 'Error occured while making a booking, please try again.';
                      $('#results2').modal('show');
                    }
                  );
              },
              (error) => {
                console.log(error);
                this.loadservice.removeloader();
                this.bookingText = 'Unable to make a booking.';
                $('#results3').modal('show');
              }
            );
        } else {
          this.loadservice.removeloader();
          alert('Please select a vehicle type to proceed with the booking.');
        }
      } else {
        this.loadservice.removeloader();
        this.hourMsg = 'Working hours are from 07:00 AM to 18:00 PM.';
      }
    } else {
      this.loadservice.removeloader();
      this.validationMsg = 'Error, invalid pick up date. The date already passed.';
    }

  }

}

interface Contact {
  name: string;
  surname: string;
  mobile: string;
  email: string;
  labours: number;
}
