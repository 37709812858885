import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '../../services/admin.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-view-spreadsheet',
  templateUrl: './view-spreadsheet.component.html',
  styleUrls: ['./view-spreadsheet.component.css','../admin-portal/admin-portal.component.css']
})
export class ViewSpreadsheetComponent implements OnInit {

  constructor(private adminService: AdminService, private router: Router) { }

  adminUser: any;
  spreadsheet: any;
  paymentDetails: any=[];

  ngOnInit() {
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    this.spreadsheet = JSON.parse(localStorage.getItem('selectedsheet'));
    this.paymentDetails=this.spreadsheet.paymentDetails;
  }

  downloadSpreadSheetFile() {
    const replacer = (key, value) => (value === null ? '' : value);
    const header = Object.keys(this.paymentDetails[0]);


    const csv = this.paymentDetails.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');

    const a = document.createElement('a');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    var currentDate = new Date();
    var datePipe = new DatePipe("en-US");
    let value = datePipe.transform(this.spreadsheet.processDate, 'yyyy-MM-dd');
    a.download = value + '-' + 'spreadsheet.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
  public showTabByRole(role):boolean{
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let result=false;
    userInfo.roles.map(r=>{
      if(role===r.code){
        result=true;
      }
    })
    return result;
  }

}
