import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BookingService } from 'src/app/services/booking.service';

@Component({
  selector: 'app-user-wallet-transactions',
  templateUrl: './user-wallet-transactions.component.html',
  styleUrls: ['./user-wallet-transactions.component.css']
})
export class UserWalletTransactionsComponent implements OnInit {

  walletOID: any;
  user: any;
  history: any = [];

  constructor(private route: Router, private bookingService: BookingService) { }

  ngOnInit() {
    this.walletOID = localStorage.getItem('walletID');
    this.user = JSON.parse(localStorage.getItem('userInfo'));
    // console.log(this.walletOID);
    // console.log(this.user);
    this.retrieveTransaction();
  }

  public retrieveTransaction() {
    this.bookingService.getTransactions(this.user.token, this.walletOID).subscribe(
      response => {
        // console.log(response);
        this.history = response.reverse();
      }, error => {
        console.log(error);
      }
    )
  }

}
