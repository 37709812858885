import { Component, OnInit, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';
import { UserLoggInService } from 'src/app/services/loggedInUser.service';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-elite-sign-up',
  templateUrl: './elite-sign-up.component.html',
  styleUrls: ['./elite-sign-up.component.css']
})
export class EliteSignUpComponent implements OnInit {

  eliteData: any = {};
  username: string;
  pwd: string;
  loader: boolean;
  errorMsg = '';

  isDevMode = isDevMode();

  constructor(private router: Router, private loginService: LoginService,
              private userLogged: UserLoggInService, private loadservice: LoaderService) { }

  ngOnInit() {
    this.eliteData = JSON.parse(localStorage.getItem('eliteObj'));
    //console.log(this.eliteData);
  }

  public login() {
    if (this.username.toLowerCase().trim() != null && this.pwd != null) {
      this.loadservice.addloader();
      this.loginService.login(this.pwd, this.username.toLowerCase().trim()).subscribe(
        (response: any) => {
          // console.log(response);
          localStorage.removeItem('userData');
          localStorage.setItem('userData', JSON.stringify(response));
          localStorage.setItem('token', response.token);
          localStorage.setItem('userInfo', JSON.stringify(response));

          var loginsData = { "username": this.username.toLowerCase().trim(), "pwd": this.pwd };
          localStorage.setItem('userInfoBooking', JSON.stringify(response));
          localStorage.setItem('userInfoBookingLogin', JSON.stringify(loginsData));

          this.userLogged.setUser(response);
          this.loadservice.removeloader();
          localStorage.setItem('eliteDetails', JSON.stringify(this.eliteData));
          this.router.navigate(['/Elite-Confirm-Details']);

          window.scrollTo(0, 0);
        },
        (err) => {
          console.log(err)
          this.loadservice.removeloader();
          this.errorMsg = "Wrong password or Username";
        }
      );
    } else {
      console.log('err')
      this.loadservice.removeloader();
      this.errorMsg = "Enter password and username";
    }
  }

}
