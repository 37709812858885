import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';

declare var $: any;

@Component({
  selector: 'app-driver-ratings',
  templateUrl: './driver-ratings.component.html',
  styleUrls: ['./driver-ratings.component.css', '../admin-portal/admin-portal.component.css']
})
export class DriverRatingsComponent implements OnInit {
  adminUser: any;
  ratings: any;
  errorMessage: any;

  totalNumberOfRatings = 0;
  pageNumber = 0;
  itemsPerPage = 5;
  pageSizeOptions: number[] = [5, 10];

  constructor(private adminService: AdminService, private router: Router, private loader:LoaderService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    this.getDriverRating();
  }


  public getDriverRating() {

    const filter = {
      pageNumber: this.pageNumber,
      pageTotalItems: this.itemsPerPage
    };

    this.loader.addloader();

    this.adminService.getDriverRatingsFiltered(this.adminUser.token, filter).subscribe(
      (response) => {
        this.ratings = response.content;
        this.totalNumberOfRatings = response.totalElement;
        this.loader.removeloader();

      }, (error) => {
        this.errorMessage = 'Ratings could not be retrieved at this time.';
        this.loader.removeloader();
        $('#errorDialog').modal('show');
      }
    );
  }


  getNewData(pageEvent?: any) {
    this.pageNumber = pageEvent.pageIndex;
    this.itemsPerPage = pageEvent.pageSize;
    this.getDriverRating();
  }


}
