import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;



@Component({
  selector: 'app-sign-up-choice',
  templateUrl: './sign-up-choice.component.html',
  styleUrls: ['./sign-up-choice.component.css']
})
export class SignUpChoiceComponent implements OnInit {
  msg: string;
  msgStatus = '';
  valuee: any;
  checked: boolean = false;
  result: string = "success";

  yes_value: any;
  yes_value1: any;
  yes_value2: any;
  yes_value3: any;
  yes_value4: any;
  yes_value5: any;
  no_value: any;

  constructor(private router: Router) { }

  ngOnInit() {

    // this.onItemChange(event)

  }

  toCustomer() {
    this.router.navigate(['/customer-signup']);
  }


  toPartner() {
    $('#partnerRegistration').modal('show');
  }

  goToPartnerRagistrationn() {


    var ele = document.getElementsByTagName('input');
    var result = "success"
    var count = 0

    for (var i = 0; i < ele.length; i++) {
      if (ele[i].type = "radio") {

        if (ele[i].checked) {
          count = count + 1;
          if ( ele[i].value == 'no1') {
            result = "fail"
          }

        }
      }
    }

    // console.log(count)

    if (count >= 6) {
      if (result == 'success') {
        this.router.navigate(['/Become-Droppa']);
      }
      else if (result == 'fail') {
        $('#partnerResModal').modal('show');
        this.msgStatus = "All answers must be YES to proceed with the registration";

      }
    }
    else {
      $('#partnerResModal2').modal('show');
      this.msgStatus = "Please answer all questions to proceed with the registration";
    }
  }



  toRetail() {
    $('#retailRegistration').modal('show');
  }

  goToRetailRagistration() {
    var ele = document.getElementsByTagName('input');
    var result = "success"
    var count = 0

    for (var i = 0; i < ele.length; i++) {
      if (ele[i].type = "radio") {

        if (ele[i].checked) {
          count = count + 1;
          if ( ele[i].value == 'no') {
            result = "fail"
          }

        }
      }
    }


    if (count >= 4) {
      if (result == 'success') {
        this.router.navigate(['/Become-Retail']);
      }
      else if (result == 'fail') {
        $('#retailResModal').modal('show');
        this.msgStatus = "All answers must be YES to proceed with the registration";

      }
    }
    else {
      $('#retailResModal2').modal('show');
      this.msgStatus = "Please answer all questions to proceed with the registration";
    }

  }


  onGoHome(){
    this.router.navigateByUrl('/');
  }





}
