import { DatePipe, formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';
import { AdminService } from '../../services/admin.service';
import { BookingService } from '../../services/booking.service';

declare var $: any;

@Component({
  selector: 'app-all-bucket-bookings',
  templateUrl: './all-bucket-bookings.component.html',
  styleUrls: ['./all-bucket-bookings.component.css', '../admin-portal/admin-portal.component.css']
})
export class AllBucketBookingsComponent implements OnInit {

  loader: boolean;
  adminUser: any;
  buckets: any = [];
  bookings: any = [];
  allBookings: any;
  bookingData: any;

  searchQueryStatus: string;
  searchUsers: any;
  bookingArray: any = [];
  bookingFoundData: any;

  totalbucketPrice: any;
  totalbucketCount: any;
  totalweeklybucket: any;
  montlyBucketCount: any;
  dailyBucketCount: any;

  searchQueryBucket: string;
  status = 'status';
  msg: any;

  startDate: any;
  endDate: any;
  datePipe = new DatePipe('en-US');

  totalNumberOfBookings = 25;
  pageNumber = 0;
  itemsPerPage = 25;
  pageSizeOptions: number[] = [10, 25];

  constructor(private adminService: AdminService, private router: Router, private adminBucketBookings: BookingService,private loadservice:LoaderService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));

    this.searchQueryStatus = localStorage.getItem('searchQueryStatus') !== null ? (localStorage.getItem('searchQueryStatus')) : '';
    this.searchUsers = localStorage.getItem('trackNumberToSearch') !== null ? (localStorage.getItem('trackNumberToSearch')) : '';
    this.pageNumber = localStorage.getItem('pageNumber') !== null ? Number(localStorage.getItem('pageNumber')) : 0;
    this.itemsPerPage = localStorage.getItem('itemsPerPage') !== null ? Number(localStorage.getItem('itemsPerPage')) : 25;

    // this.getTotalBookings(this.adminUser.token);
    this.getBucketBooking(1);
  }

  resetPagination(){
    this.pageNumber = 0;
    this.itemsPerPage = 25;
  }

  public getBucketBooking(retrieveType: number) {
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    this.loadservice.addloader();

    if(retrieveType===0)
      this.resetPagination();

    const filter = {
      status: this.searchQueryStatus,
      trackNo: this.searchUsers,
      pageNumber: this.pageNumber,
      pageTotalItems: this.itemsPerPage
    };

    //console.log(filter);


    this.adminService.getAllPaginationBucketBooking(this.adminUser.token, filter)
      .subscribe(
        (res) => {
          this.buckets = res.content;
          this.totalNumberOfBookings = res.totalElement;

          // console.log(this.buckets);
          this.loadservice.removeloader();
        },
        (err) => {
          this.loadservice.removeloader();
          if (err.statusText === 'Unknown Error' || err.statusText === 'Internal Server Error') {
            this.router.navigate(['/logon']);
          }
        }
      );
  }

  public onBucketBooking(book: any) {
    localStorage.setItem('bucketBooking', JSON.stringify(book));
    localStorage.setItem('retailFromAllBucketTable', String(true));
    localStorage.setItem('searchQueryStatus',this.searchQueryStatus);
    localStorage.setItem('trackNumberToSearch',this.searchUsers);
    localStorage.setItem('pageNumber', String(this.pageNumber));
    localStorage.setItem('itemsPerPage', String(this.itemsPerPage));
    this.router.navigate(['/view-retail-bookings']);
  }

  private getTotalBookings(token: any) {
    this.adminService.getTotalAllBookings(token)
      .subscribe(
        (res) => {
          this.totalbucketPrice = res.bookingMatrixDTO.totalBucketBookings;
          this.totalbucketCount = res.bookingMatrixDTO.bucketCount;
          this.totalweeklybucket = res.bookingMatrixDTO.weeklyBucketBookings;
          this.montlyBucketCount = res.bookingMatrixDTO.monthlyBucketBooking;
          this.dailyBucketCount = res.bookingMatrixDTO.dailyBucketBooking;
        },
        (err) => {
          this.loadservice.removeloader();
          if (err.statusText === 'Unknown Error' || err.statusText === 'Internal Server Error') {
            this.router.navigate(['/logon']);
          }
        }
      );
  }

  // public searchBooking() {
  //   this.loadservice.addloader();
  //   this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
  //   if (this.searchUsers != null) {
  //     this.adminBucketBookings.getBucketByTrackNum(this.searchUsers.toUpperCase()).subscribe((response: any) => {
  //       this.bookingFoundData = response;
  //       /*if (this.bookingFoundData) {
  //         for (const responseKey in this.bookingFoundData) {
  //           this.bookingFoundData.indexOf(responseKey).pop();
  //         }
  //       }*/
  //       this.loadservice.removeloader();
  //     }, (error) => {
  //       console.log(error);
  //       $('#searchBooking').modal('show');
  //       this.msg = 'The booking does not exist. Please make sure you entered the right track number.';
  //       this.loadservice.removeloader();
  //     });
  //   } else {
  //     $('#searchBooking').modal('show');
  //     this.msg = 'Please enter a track number to search for a booking.';
  //     this.loadservice.removeloader();
  //   }
  // }

  // searchBookings(_trackNumberSearch: any) {
  //   if (_trackNumberSearch && _trackNumberSearch.trim() != '')
  //     this.bookings = _trackNumberSearch.toUpperCase();
  // }

  // filterMethodTest() {
  //
  //   const format = 'yyyy-MM-dd';
  //   const locale = 'en-US';
  //   const startDate = formatDate(this.startDate, format, locale);
  //   const endDate = formatDate(this.endDate, format, locale);
  //   const selectedMembers = this.buckets.filter((m: any) => {
  //     return m.date >= startDate && m.date <= endDate;
  //   });
  //
  //   this.getSearchedBucketBookings(selectedMembers);
  // }

  // public getSearchedBucketBookings(filtered) {
  //
  //   let bucketBookings = [];
  //
  //   filtered.forEach(element => {
  //     bucketBookings += element;
  //   });
  //
  // }

  /*
  13/08/2022
  Terrence
  */

  isBucketError:boolean=false;
  bucketErrormsg:any="";
  bucketmsg:any="";


  public CreateEmptyBucket() {
    this.loadservice.addloader();
    this.bucketErrormsg="";
    this.bucketmsg="";
    var object = JSON.parse(localStorage.getItem('userInfo'));

      this.adminService.createEmptyBucket(object.token).subscribe((res)=>{
        console.log("res:: ",res);
       this.bucketmsg="Empty Bucket Created";
       this.isBucketError=false;
       this.loadservice.removeloader();
       $("#bucketdetails").modal("show");
      },(err)=>{
        console.log("err::: ",err);
        this.isBucketError=true;
        this.loadservice.removeloader();
        this.bucketErrormsg="Error occured while creating empty bucket booking. ";
        $("#bucketdetails").modal("show");
      })

  }

  public refreshtable(){
    if(!this.isBucketError){
      // this.getTotalBookings(this.adminUser.token);
      this.getBucketBooking(1);
    }
    $("#bucketdetails").modal("hide");
  }


  public showTabByRole(role):boolean{
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let result=false;
    userInfo.roles.map(r=>{
      if(role===r.code){
        result=true;
      }
    })
    return result;
  }

  getNewData(pageEvent?: any) {
    this.pageNumber = pageEvent.pageIndex;
    this.itemsPerPage = pageEvent.pageSize;
    console.log(this.pageNumber);
    console.log(this.itemsPerPage);
    this.getBucketBooking(1);
  }

  ngOnDestroy() {
    if(this.router.getCurrentNavigation().finalUrl.toString() !== '/view-retail-bookings') {
      localStorage.removeItem('pageNumber');
      localStorage.removeItem('itemsPerPage');
      localStorage.removeItem('searchQueryStatus');
      localStorage.removeItem('trackNumberToSearch');
    }
  }
}
