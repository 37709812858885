import {Component, isDevMode, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '../../services/admin.service';
import { BookingService } from 'src/app/services/booking.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-all-retails',
  templateUrl: './all-retails.component.html',
  styleUrls: ['./all-retails.component.css', '../admin-portal/admin-portal.component.css']
})
export class AllRetailsComponent implements OnInit {

  loader: boolean;
  adminUser: any;
  retails: any = [];
  retailsToFilter = [];

  public findMobileNumberSearch: any;
  public searchQueryRetail = '1';
  public statusMessage: string;

  constructor(private adminService: AdminService, private router: Router,private loadservice:LoaderService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    this.getRetails();
  }

  public onRetail(retail: any) {
    localStorage.setItem('selectedRetail', JSON.stringify(retail));
    this.router.navigate(['/retail-review']);
  }

  public getRetails() {
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    this.loadservice.addloader();

    this.adminService.getAllRetails(this.adminUser.token)
      .subscribe(
        (res: any) => {
          this.retails = res;

          this.retailsToFilter = this.retails;
          // console.log(this.retailsToFilter);
          // localStorage.setItem('allRetails', JSON.stringify(this.retails));
          this.loadservice.removeloader();
          // console.log(this.retails);
        },
        (error) => {
          this.loadservice.removeloader();
          if (error.statusText === 'Unknown Error' || error.statusText === 'Internal Server Error') {
            this.router.navigate(['/logon']);
          }
        }
      );
  }

  public showTabByRole(role):boolean{
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let result=false;
    userInfo.roles.map(r=>{
      if(role===r.code){
        result=true;
      }
    })
    return result;
  }

  public filterTable() {
    this.retailsToFilter = this.retails;

    switch (this.searchQueryRetail) {
      case '1':
        this.retailsToFilter = this.retailsToFilter.filter(
          (retail: any) => {
            if (retail.owner) {
              return retail.owner.mobile.toLowerCase().includes(this.findMobileNumberSearch.toLowerCase());
            }
          });
        break;
      case '2':
        this.retailsToFilter = this.retailsToFilter.filter((retail: any) => {
          if (retail.owner) {
            return retail.owner.email.toLowerCase().includes(this.findMobileNumberSearch.toLowerCase());
          }
        });
        break;
      case '3':
        this.retailsToFilter = this.retailsToFilter.filter((retail) => {
          return  (
            retail.name.toLowerCase().includes(this.findMobileNumberSearch.toLowerCase())
          );
        });
        break;
      case '4':
        this.retailsToFilter = this.retailsToFilter.filter((retail) => {
          return  (
            retail.registrationNumber.toLowerCase().includes(this.findMobileNumberSearch.toLowerCase())
          );
        });
        break;
      case '5':
        this.retailsToFilter = this.retailsToFilter.filter((retail) => {
          return  (
            retail.status.toLowerCase().includes(this.findMobileNumberSearch.toLowerCase())
          );
        });
        break;
    }
  }

}
