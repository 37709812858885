import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-developers-portal-navigate',
  templateUrl: './developers-portal-navigate.component.html',
  styleUrls: ['./developers-portal-navigate.component.css']
})
export class DevelopersPortalNavigateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  onDeactivate() {
    document.body.scrollTop = 0;
    window.scrollTo(0, 0);
  }

}
