import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-retail-select-payment-tag',
  templateUrl: './retail-select-payment-tag.component.html',
  styleUrls: ['./retail-select-payment-tag.component.css']
})
export class RetailSelectPaymentTagComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
