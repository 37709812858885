import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SharedService } from './shared.service';
declare var google: any;

@Injectable()
export class BookingService {

  constructor(private http: Http, private httpClient: HttpClient, private sharedService: SharedService) { }
  public getBookingContactPerson(token,bookingoid): Observable<any> {
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.get(this.sharedService.getUrl() + 'parties/booking/contact/persons/' + bookingoid, options);
  }
  public getContactPerson(token,contactoid): Observable<any> {
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.get(this.sharedService.getUrl() + 'parties/contact/person/' + contactoid, options);
  }
  // @ts-ignore
  public getUberDeliveryByBookingOid(token, bookingOid): Observable<any> {
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);

    let options = new RequestOptions({ headers: headers });
    return this.http.get(this.sharedService.getUrl() + 'parties/uber/delivery/' + bookingOid, options);

  }
  public getUberdelivery(token,deliverybody,bookingOid): Observable<any> {
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    console.log(deliverybody);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + 'parties/uber/delivery/booking/'+bookingOid, deliverybody, options);
  }
  public getUberDeliveryDetails(token,bookingOid): Observable<any> {
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.get(this.sharedService.getUrl() + 'parties/uber/delivery/booking/details/'+bookingOid, options);
  }
  public cancelUberDelivery(token,bookingOid): Observable<any> {
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + 'parties/uber/cancel/delivery/'+bookingOid,'', options);
  }
  public getuberquote(token,quoterequestbody): Observable<any> {
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + 'parties/uber/quote', quoterequestbody, options);
  }
  public downloadWaybills(bookingIds): Observable<any> {
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + 'plugins/waybill/download/multiple/pdf',bookingIds,options);
  }
  public downloadWaybill(bookingOid){
    return this.sharedService.getUrl() + 'plugins/waybill/download/pdf/'+bookingOid
  }

  public getVehicleRate(vehicleType): Observable<any> {
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return this.http.get(this.sharedService.getUrl() + 'vehicles/typeRate/' + vehicleType, options);
  }

  public getRetailVehicleRate(retailOid, vehicleType): Observable<any> {
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return this.http.get(this.sharedService.getUrl() + 'parties/retails/typeRate/' + retailOid + '/' + vehicleType, options);
  }

  public getDistance1(pickUp: string, dropOff: string): any {
    var matrix = new google.maps.DistanceMatrixService();
    return matrix.getDistanceMatrix({ 'origins': [pickUp], 'destinations': [dropOff], travelMode: google.maps.TravelMode.DRIVING },
      (results: any) => {
        console.log(results);

      }, (err) => {
        console.log(err);

      });
  }
  public getClosedDriver(token, bucketOid): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'bookings/buckets/' + bucketOid+'/close/driver',
        {
            headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
                .set('Authorization', userToken)
        });
}
  public getDistance(data: any): Observable<any> {
    //var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    //headers.append('Authorization', userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + 'parties/coordinate/distance', data, options);
  }

  public getRetailBookPrice(retailOid, vehicleType): Observable<any> {
    // console.log(retailOid + " " + vehicleType);
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return this.http.get(this.sharedService.getUrl() + 'parties/retails/typeRate/' + retailOid + '/' + vehicleType, options);
  }

  public payBooking(token: string) {
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + 'payments/requests', '', options);
  }

  public getRetailbookingPrice(bookData, token: string) {
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + 'bookings/pricings', bookData, options);
  }

  // Original drop called on any page (anywhere else except bucket)
  public validateAddress(obj: any): Observable<any> {
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + 'drops/validate/address', obj, options);
  }
  public createDrop(drop: any, token): Observable<any> {
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + 'drops/drop/booking', drop, options);
  }
  // Drop called for wallet
  public walletDrop(drop: any, token): Observable<any> {
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + 'drops/wallet/drop', drop, options);
  }
  public walletDropRetail(drop: any, token): Observable<any> {
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + 'drops/wallet/drop/retail', drop, options);
  }

  // This is used for retail bookings (Only for bucket multiple bookings)
  public plainDrop(drop: any, token): Observable<any> {
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + 'drops/plain/drop', drop, options);
  }

  public publishBook(bookingOid: string, token): Observable<any> {
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + 'bookings/publish/' + bookingOid, '', options);
  }

  public cash(drop: any, token): Observable<any> {
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + 'drops/bookings/cash', drop, options);
  }

  public cashPay(dropOID, token): Observable<any> {
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + 'drops/bookings/cash/' + dropOID, '', options);
  }

  public voucherPayment(drop, token): Observable<any> {
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + 'drops/bookings/vouchers', drop, options);
  }

  public reCreateDrop(dropOid, token): Observable<any> {
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + 'drops/recreate/' + dropOid, '', options);
  }

  public requestPayment(paymentDetails: any, token): Observable<any> {
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + 'payments/requests', paymentDetails, options);
  }

  public requestPayfastPayment(paymentDetails: any, token): Observable<any> {
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + 'v2/payments/request', paymentDetails, options);
  }

  public requestOzowPayment(paymentDetails, token): Observable<any> {
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + 'v2/payments/request/ozow', paymentDetails, options);
  }

  public getRequestPayment(token, reference): Observable<any> {
    var userToken = 'Bearer ' + token;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        "Authorization": userToken
      })
    };
    return this.httpClient.get(this.sharedService.getUrl() + 'v2/payments/requests/' + reference, httpOptions);
  }

  public requestBucketPayment(paymentDetails: any, token): Observable<any> {
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + 'payments/buckets/requests', paymentDetails, options);
  }

  public requestBucketPaymentPayFast(paymentDetails: any, token): Observable<any> {
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + 'v2/payments/request', paymentDetails, options);
  }

  public book(bookData: any, token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'drops/book', bookData,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public retailBook(bookData: any, token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'drops/retail/book', bookData,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public bucketsBook(bookData: any, token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/buckets', bookData,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public cancelBucket(token, bucketOid): Observable<any> {
    var userToken = "Bearer " + token;
    return this.httpClient.post(this.sharedService.getUrl() + "bookings/buckets/cancel/" + bucketOid, '',
      {
        headers: new HttpHeaders().set("Accept", 'application/json').set('Content-Type', 'application/json')
          .set("Authorization", userToken)
      });
  }

  public pushBucketsBook(bookData: any, token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/auth/buckets', bookData,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public pushRetailExpressBook(bookData: any, token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'drops/express', bookData,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public addToBucketsBook(bookData: any, token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/buckets/add/bookings', bookData,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public removeFromBucketsBook(bucketOid: string, bookingOid: string, token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/buckets/remove/' + bucketOid + '/' + bookingOid, '',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public updateBook(bookData: any, token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'drops/book/update', bookData,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

 /* public updateBucketPriceCardPayment(bookData: any, token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/bucket/updates/prices', bookData,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }*/

  public updateBookingsPrice(oid: any, token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/updates/prices/bucket/'+oid,'',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public updateBucketPriceCardPayment(oid: any, token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/update/buckets/price/' + oid,'',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public cancelBooking(oid: string, token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'drops/book/cancel/' + oid, '',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  // Used to retrieve a retail single booking
  public getRetailSingleBooking(token, bookingOid): Observable<any> {
    var userToken = "Bearer " + token;
    return this.httpClient.get(this.sharedService.getUrl() + "bookings/filters/by/drops/" + bookingOid,
      {
        headers: new HttpHeaders().set("Accept", 'application/json').set('Content-Type', 'application/json')
          .set("Authorization", userToken)
      });
  }

  public getBookingByDrop(dropOid): Observable<any> {
    return this.httpClient.get(this.sharedService.getUrl() + "drops/booking/by/drops/" + dropOid,
      {
        headers: new HttpHeaders().set("Accept", 'application/json').set('Content-Type', 'application/json')
      });
  }

  public getBucketBook(token, oid): Observable<any> {
    var userToken = "Bearer " + token;
    return this.httpClient.get(this.sharedService.getUrl() + "bookings/bucket/" + oid,
      {
        headers: new HttpHeaders().set("Accept", 'application/json').set('Content-Type', 'application/json')
          .set("Authorization", userToken)
      });
  }

  /**
   * @description   - Retreive a single booking with a Track number search
   * @param         trackNo
   */
  public getBookingByTrackNum(trackNo) {
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return this.http.get(this.sharedService.getUrl() + "bookings/track/" + trackNo, options);
  }

  public retrieveWayBillStatus(waybillNum): Observable<any> {
    return this.httpClient.get(this.sharedService.getUrl() + 'bookings/track/skynet/' + waybillNum,
    {
      headers: new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
    });
  }

  /**
   * @description - Retreive a single booking with a waybill number search
   * @param       waybillNumber
   */
  public getBookingByWaybillNumber(waybillNumber: any): Observable<any> {
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });

    return this.http.get(`${this.sharedService.getUrl()}bookings/waybill/${waybillNumber}`, options);
  }

  public getBucketByTrackNum(trackNo): Observable<any> {
    return this.httpClient.get(this.sharedService.getUrl() + "bookings/buckets/bookings/track/" + trackNo,
      {
        headers: new HttpHeaders().set("Accept", 'application/json').set('Content-Type', 'application/json')
      });
  }

  public retrievePayments(token, payRef): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'payments/requests/responses/' + payRef,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public syncPayment(token, payRef): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'payments/results/sync/' + payRef, '',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
      });
  }

  public getPaymentResults(token, payRef): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'payments/results/' + payRef,
      {
        headers: new HttpHeaders()
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  // Retrieve booking details using the dropOid - dropOid coming from the success body of drop service
  public getBookingDetails(dropOid): Observable<any> {
    return this.httpClient.get(this.sharedService.getUrl() + 'bookings/filters/by/drops/' + dropOid,
      {
        headers: new HttpHeaders()
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json')
      });
  }
  public tipDriver(tipDetails): Observable<any> {
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/driver/tip' ,tipDetails,
      {
        headers: new HttpHeaders()
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json')
      });
  }

  public getPaymentResultsByRef(token, payRef, gateRef): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'payments/results/' + payRef + '/' + gateRef,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
      });
  }

  public retrieveResponsePayment(token, payRef): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'payments/responses/' + payRef,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
      });
  }

  public getPayment(token, payRef): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'payments/' + payRef,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
      });
  }

  public finalizePayment(token, finalizeData): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'payments', finalizeData,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
      });
  }

  login(password, email): Observable<any> {
    var pass = password.split('');
    var login = {
      'username': email,
      'pwd': password
    };
    return this.httpClient.post(this.sharedService.getUrl() + 'sessions/login', login,
      { headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json') });
  }

  public vehicleTypeRates(): Observable<any> {
    return this.httpClient.get(this.sharedService.getUrl() + 'vehicles/vehicleTypeRate/all',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
      });
  }

  public downloadCustomerInvoices(token, dropOid): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/create/drop/invoice/' + dropOid, '',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
      });
  }

  public createRetailExpressBooking(token, dropOid): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/express/push/' + dropOid, '',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getQuote(quoteBody): Observable<any> {
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + 'parties/flash/rates', quoteBody, options);
  }

  public getNewSkynetPrices(quoteBody): Observable<any> {
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + 'parties/skynet/rates', quoteBody, options);
  }
  public getNewSkynetPrices2(quoteBody): Observable<any> {
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + 'parties/rates/skynet', quoteBody, options);
  }

  // public getSkynetQuote(quoteBody): Observable<any> {
  //   var headers = new Headers();
  //   headers.append('Accept', 'application/json');
  //   headers.append('Content-Type', 'application/json');
  //   let options = new RequestOptions({ headers: headers });
  //   return this.http.post(this.sharedService.getUrl() + 'parties/flash/skynet/rates', quoteBody, options);
  // }

  public getPOI(): Observable<any> {
    return this.httpClient.get(this.sharedService.getUrl() + 'parties/main/centers',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
      })
  }

  public checkAvailBalance(token, userID, amount): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'payments/check/balance/' + userID + '/' + amount,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
      })
  }

  public getWalletInfo(token, userID): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'payments/wallets/balance/' + userID,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
      })
  }

  public payWithWallet(token, body): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'payments/execute/wallet', body,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
      });
  }

  public authWallet(token, walletBody): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/auth/wallet/buckets', walletBody,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public getTransactions(token, walletID): Observable<any> {
    var userToken = 'Bearer ' + token;
    //parties/wallets/transactions/
    return this.httpClient.get(this.sharedService.getUrl() + 'parties/wallets/transactions/' + walletID,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
      })
  }

  public getTransactionsPaginate(token, filter): Observable<any> {
    var userToken = 'Bearer ' + token;
    //parties/wallets/transactions/
    return this.httpClient.post(this.sharedService.getUrl() + 'parties/wallets/transactions/', filter,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
      })
  }

  public getBookingDate(pickUpPCode, dropOffPCode): Observable<any> {
    return this.httpClient.get(this.sharedService.getUrl() + 'parties/validate/booking/date/' + pickUpPCode + '/' + dropOffPCode,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
      })
  }

  public getPickUpSuburb(postalCode): Observable<any> {
    return this.httpClient.get(this.sharedService.getUrl() + 'parties/suburb/' + postalCode,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
      })
  }

  public getDropOffSuburb(postalCode): Observable<any> {
    return this.httpClient.get(this.sharedService.getUrl() + 'parties/suburb/' + postalCode,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
      })
  }

  // Service in use for sending tax invoice to customers
  public newInvoiceCustomer(token, invoiceObj): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/tax/invoice', invoiceObj,
      {
        headers: new HttpHeaders()
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      }
    )
  }

  // Not in use anymore
  public invoiceCustomer(token, trackNo): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/tax/invoice/' + trackNo, '',
      {
        headers: new HttpHeaders()
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      }
    )
  }

  public invoiceBucketCustomer(token, trackNo): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/bucket/tax/invoice/' + trackNo, '',
      {
        headers: new HttpHeaders()
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      }
    )
  }

  // New invoice service being used on retail
  public invoiceRetailBucketCustomer(token, trackNo): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/bucket/tax/invoices/' + trackNo, '',
      {
        headers: new HttpHeaders()
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      }
    )
  }

  public downloadTaxInvoiceForRetail(token, trackNo): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/bucket/taxinvoice/' + trackNo, '',
      {
        headers: new HttpHeaders()
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      }
    );
  }

  // Used on retail while logged in
  public postalDetails(token, postalCode): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'parties/postal/code/details/' + postalCode,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
      })
  }

  //Used on the home page, does not require a token
  public postalForHomeDetails(postalCode): Observable<any> {
    return this.httpClient.get(this.sharedService.getUrl() + 'parties/postal/code/details/' + postalCode,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
      })
  }

  public storeAddresses(token, addressInfo): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'parties/previous/address', addressInfo,
      {
        headers: new HttpHeaders()
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      }
    )
  }

  public retrieveAddresses(token, personOid): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'parties/previous/address/' + personOid,
      {
        headers: new HttpHeaders()
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      })
  }


  // Elite Service
  // admin
  // public getEliteVehicles(token): Observable<any> {
  //   var userToken = 'Bearer ' + token;
  //   return this.httpClient.get(this.sharedService.getUrl() + 'rentals', { headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken) });
  // }

  public eliteInvoice(token, invoiceObj): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'rentals/tax/invoice', invoiceObj,
      {
        headers: new HttpHeaders()
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      }
    )
  }

  public saveEliteVehicles(token, eliteObj): Observable<any>  {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'rentals', eliteObj, {headers: new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('Authorization', userToken)})
  }

  public getRentalByOid(token, oid) {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'rentals/filters/' + oid, {headers: new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('Authorization', userToken)})
  }

  public getEliteBranches(): Observable<any> {
    return this.httpClient.get(this.sharedService.getUrl() + 'rentals/branch', {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')});
  }

  public saveEliteBranches(branch): Observable<any> {
    return this.httpClient.post(this.sharedService.getUrl() + 'rentals/branch', branch, { headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')});
  }
  // admin
  public getEliteBranchesByProvince(province): Observable<any> {
    return this.httpClient.get(this.sharedService.getUrl() + 'rentals/branch/' + province, {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')});
  }

  public getEliteVehicleByCategory(token, branchOid): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'rentals/by/category/' + branchOid, {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)});
  }

  public getEliteBookingByCustomer(token, customerOid): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'rentals/by/customer/' + customerOid, {headers: new HttpHeaders()
    .set('Accept', 'application/json')
    .set('Content-Type', 'application/json')
    .set('Authorization', userToken)});
  }

  public getEliteVehicleByUnit(unit): Observable<any> {
    return this.httpClient.get(this.sharedService.getUrl() + 'rentals/by/units/' + unit, {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')});
  }

  public getEliteQuotes(): Observable<any> {
    return this.httpClient.get(this.sharedService.getUrl() + 'rentals/quotes', {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')});
  }

  public getEliteQuotes2(quoteBody): Observable<any> {
    return this.httpClient.post(this.sharedService.getUrl() + 'rentals/quotes', quoteBody, {headers: new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
    });
  }

  // admin
  public getEliteRates(): Observable<any> {
    return this.httpClient.get(this.sharedService.getUrl() + 'rentals/rates', {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')});
  }

  public saveEliteRates(token, body): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'rentals/rates', body, {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)});
  }

  public getEliteRatesByCategoryName(token, categoryName): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'rentals/rates/' + categoryName, {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)});
  }

  public trackElite(token, trackNo): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'rentals/tracking/' + trackNo, {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)});
  }

  // Elite Service

  public listAvailPaymentOptions(token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'admin/payment/options',
      {headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)});
  }

  public addPromoCode(token, usePromoCodeDTO): Observable<any> {
    var userToken = "Bearer " + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/use/promotion', usePromoCodeDTO, {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    })
  }

  public checkRetailPromo(token, promoCodeDTO) {
    var userToken = "Bearer " + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/use/promotion/retail', promoCodeDTO, {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    });
  }

  public updateBucketPromoPriceCardPayment(promoCodeDTO, token) {
    var userToken = "Bearer " + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/buckets/update/promo/price', promoCodeDTO, {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    });
  }

  public getListOfPudoLockers(token) {
    const userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'pudo/all/terminals', {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    });
  }

  public getListOfAvaialablePudoLockerBoxes(token, terminalId) {
    const userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'pudo/terminal/' + terminalId, {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    });
  }

  public createPudoBooking(token, pudoBody, retailId) {
    const userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'pudo/create/booking/' + retailId, pudoBody, {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    });
  }
  public getPudoBooking(pudoId) {
    return this.httpClient.get(this.sharedService.getUrl() + 'pudo/booking/' + pudoId, {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
    });
  }
  public invoicePayPudoBooking(token, transactionId) {
    const userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'pudo/invoice/pay/' + transactionId, '', {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    });
  }

  public walletPayPudoBooking(token, transactionId) {
    const userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'pudo/wallet/pay/' + transactionId, '', {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    });
  }

  public getPudoBookings(token, retailOwnerId) {
    const userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'pudo/all/retail/bookings/' + retailOwnerId, {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    });
  }

  public getAllPudoBookings(token, filter) {
    const userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'pudo/all/bookings/filter', filter, {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    });
  }
  public getAllIndividualPudoBookings(token, filter) {
    const userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'pudo/get/individual/bookings/filter', filter, {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    });
  }
  // public getAllInsuredBookings(token) {
  //   const userToken = 'Bearer ' + token;
  //   return this.httpClient.get(this.sharedService.getUrl() + 'admin/insurance/bookings', {
  //     headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
  //   });
  // }

  public getAllInsuredBookings(token, filter) {
    const userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'admin/insurance/bookings/filter', filter, {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    });
  }


  public getPudoBookingWaybill(token, transactionId) {
    const userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'pudo/update/collection/info/' + transactionId, {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    });
  }

  public cancelPudoBooking(token, transactionId) {
    const userToken = 'Bearer ' + token;
    return this.httpClient.put(this.sharedService.getUrl() + 'pudo/cancel/' + transactionId, {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    });
  }

  public getPudoBookingEvents(token, waybill) {
    const userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'pudo/single/booking/events/' + waybill, {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    });
  }

  public getPudoIndividualBookingEvents(token, waybill) {
    const userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'pudo/single/booking/events/individual/' + waybill, {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    });
  }

  public completePudoBooking(token, transactionId) {
    const userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'pudo/complete/booking/' + transactionId, '', {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
    });
  }

  public readyForShipping(token, bookingOid): Observable<any> {
    const userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'plugins/wallet/confirm/' + bookingOid, '',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
      });
  }

  getUberPOD(token, oid): Observable<any> {
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.get(this.sharedService.getUrl() + 'parties/uber/pod/'+oid, options);
  }
}
