import {Component, isDevMode, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ContractOwnerService} from '../../services/contract-owner.service';
import {UserInterface} from '../../interfaces/user.interface';
import {LoaderService} from '../../services/loader.service';
import {NgForm} from '@angular/forms';

declare var $: any;

@Component({
  selector: 'app-detailed-contract-owner',
  templateUrl: './detailed-contract-owner.component.html',
  styleUrls: ['./detailed-contract-owner.component.css']
})
export class DetailedContractOwnerComponent implements OnInit {
  selectedRouteTobeAssigned: any;
  vehicleSelectedToBeAssigned: any;
  allBranches: any;
  private selectedDriverId: any;
  deductionType: any;
  deductionValue: any;
  deductionDescription: any;
  deductionMonth: any;
  deductionYear: any;
  errorMsgLicense: string;
  licenseIssueDateError: string;
  driverProvince: string;
  basePrice: number;


  constructor(private activeRoute: ActivatedRoute, private ownerService: ContractOwnerService, private loaderService: LoaderService) {
  }

  driverDeductions: any;

  itemsPage = 20;
  ownerId;
  userInfo: UserInterface;
  title = 'Parcels';
  owner;
  bankingDetails;
  vehicles = [];
  drivers = [];

  selectedWaybill;
  errorTitle;
  errorMsg;
  driverToAssign;
  vehiclToAssignTo;
  vehicleRate;
  vehicleSelected: any;
  selectedDriverVehicle;
  collectionsCheckBox = false;
  deliveriesCheckBox = false;
  typeCheckBox = false;
  rateStructures = [];
  routes = [];
  rateType: any;
  selectedRateType: any;
  vehicleBilling: any;
  selectedRoute;
  routeRate: any;
  selectedViewRate: any;
  tiersArray = [];
  errorAddParcel = '';
  tierError = '';
  step = 1;
  isDevMode = isDevMode();
  isPam = false;
  driverToBeAssignedToVehicle: any;
  newRatesArray: any[] = [];
  accoutTypes = [
    {
      name: 'Saving Acc',
      value: 'SAVING',
    },
    {
      name: 'Chequing Acc',
      value: 'Chequing',
    },
    {
      name: 'Business Acc',
      value: 'Business',
    }
  ];
  vehicleTypes = [
    {
      name: 'mini van',
      value: 'MINI_VAN'
    },
    {
      name: '1 Ton',
      value: 'ONE_TON_TRUCK'
    },
    {
      name: '1.5 Ton',
      value: 'ONE_HALF_TRUCK'
    }, {
      name: '4 Ton',
      value: 'FOUR_TON_TRUCK'
    },
    {
      name: '8 Ton',
      value: 'EIGHT_TON_TRUCK'
    }
    ,
    {
      name: 'Scooters',
      value: 'MOTO-BIKES'
    }

  ];

  pageSizeOptions: number[] = [10, 25, 50];


  //owner parcels
  parcels = [];
  parcelsPage = 0;
  parcelsSize = 10;
  totalNumberOfParcels = 0;
  parcelsStartDate: any;
  parcelsEndDate: any;


  assignVehicleToDriver: any;
  selectedVehicleForDriver: any;
  private boolean;
  isThisAFixedRate: boolean;
  rateNumberOfTiers: number;
  rateNameOfTIERS: any;
  createTierStructure: boolean;
  selectedRateTypeForRoute: any;
  rateRouteName: any;
  rateRouteProvince: any;
  rateRouteBranch: any;
  fixedAmount = 0;

  parcelEdit: any;

  licenseNumber = '';
  licenseIssueDate = '';
  licenseCode = '';
  licenseRestrictions = '';
  licenseFrontView = '';
  licenseBackView = '';

  deleteParcelId = '';
  currentYear = new Date().getFullYear();
  licenseNumberError: any;
  driverProvinceError: any;

  ngOnInit() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.button();
    this.activeRoute.params.subscribe(params => {
      this.ownerId = params.ownerId;
    });
    if (this.ownerId) {
      this.getOwner();
    }
    this.getRateStructures();
    this.getRoute();
    this.getBranches();

    this.createDummyObject();

    // console.log(this.userInfo)
  }

  public button() {
    const header = document.getElementById('myTab');
    const btns = header.getElementsByClassName('tabs');
    for (let i = 0; i < btns.length; i++) {
      btns[i].addEventListener('click', function () {
        const current = document.getElementsByClassName('activee');
        current[0].className = current[0].className.replace(' activee', '');
        this.className += ' activee';
      });
    }
  }

  public changeTitle(title) {
    this.title = title;
  }

  public getOwner() {
    const token = this.userInfo.token;
    this.loaderService.addloader();
    this.ownerService.getContractOwner(token, this.ownerId).subscribe(
      (res) => {
        this.owner = res;
        this.isPam = !!this.owner.person;
        // console.log(res)
        this.getOwnerVehicle();
        this.getOwnerParcelsFiltered(0);
        this.getOwnerDriver();
        this.getOwnerBankingDetails();
      },
      (error) => {
        this.errorModal(error.error.message, 'Error');
        this.loaderService.removeloader();
      },
      () => {
        this.loaderService.removeloader();
      }
    );
  }

  public getOwnerVehicle() {
    const token = this.userInfo.token;
    this.loaderService.addloader();
    this.ownerService.getOwnerVehicle(token, this.ownerId).subscribe(
      (res) => {
        this.vehicles = res;
        console.log(res);
      },
      (error) => {
        this.errorModal(error.error.message, 'Error');
        this.loaderService.removeloader();
      },
      () => {
        this.loaderService.removeloader();
      }
    );
  }

  public getOwnerBankingDetails() {
    const token = this.userInfo.token;
    this.loaderService.addloader();
    this.ownerService.getOwnerBankingDetails(token, this.ownerId).subscribe(
      (res) => {
        // console.log(res)
        this.bankingDetails = res;
      },
      (error) => {
        // console.log(error)
        this.errorModal(error.error.message, 'Error');
        this.loaderService.removeloader();
      },
      () => {
        this.loaderService.removeloader();
      }
    );
  }

  public addCompanyFormForm(form: NgForm) {
    console.log(form);
  }

  public selectWaybills(selectedWaybill) {
    this.selectedWaybill = selectedWaybill;
    $('#waybillModal').modal('show');
  }

  public getOwnerParcels() {
    const token = this.userInfo.token;
    this.loaderService.addloader();
    this.ownerService.getOwnerParcels(token, this.ownerId).subscribe(
      (res) => {
        this.parcels = res;
        console.log(this.parcels);
        // console.log(res)
      },
      (error) => {
        // console.log(error)
        this.errorModal(error.error.message, 'Error');
        this.loaderService.removeloader();
      },
      () => {
        this.loaderService.removeloader();
      }
    );
  }

  public getOwnerDriver() {
    const token = this.userInfo.token;
    this.loaderService.addloader();
    this.ownerService.getOwnerDriver(token, this.ownerId).subscribe(
      (res) => {
        this.drivers = res;
        // console.log(res)
      },
      (error) => {
        // console.log(error)
        this.errorModal(error.error.message, 'Error');
        this.loaderService.removeloader();
      },
      () => {
        this.loaderService.removeloader();
      }
    );
  }

  public editParcelForm(form: NgForm) {


    const token = this.userInfo.token;

    this.loaderService.addloader();
    this.ownerService.editParcel(token, this.parcelEdit, this.parcelEdit.oid).subscribe(
      (res) => {
        console.log(res);
        $('#editParcelModal').modal('hide');
        const index = this.parcels.findIndex(parcel => parcel.oid === res.oid);

        if (index !== -1) {
          this.parcels[index] = res;
        }
        form.resetForm();

      },
      (error) => {

        this.errorModal(error.error.message, 'Error');
        this.loaderService.removeloader();
      },
      () => {
        this.loaderService.removeloader();
      }
    );

  }

  public addParcelForm(form: NgForm) {
    this.errorAddParcel = '';
    // console.log(form.controls)
    // console.log(form)
    this.removeError();

    const parcelOdj = form.value;


    if (form.status === 'INVALID') {
      this.errorAddParcel = 'there are same fields missing please enter all the required data.';
      if (form.controls.returns.status === 'INVALID') {
        this.inputError('returns');
      }
      if (form.controls.branch.status === 'INVALID') {
        this.inputError('branch');
      }
      if (form.controls.route.status === 'INVALID') {
        this.inputError('route');
      }

      if (form.controls.waybillDate.status === 'INVALID') {
        this.inputError('waybillDate');
      }

      if (form.controls.vehicleRegistration.status === 'INVALID') {
        this.inputError('vehicleRegistration');
      }

      if (form.controls.collectionsManifestNumber.status === 'INVALID') {
        this.inputError('collectionsManifestNumber');
      }
      if (form.controls.deliveryManifestNumber.status === 'INVALID') {
        this.inputError('deliveryManifestNumber');
      }
      return;
    }
    /*if(!this.collectionsCheckBox&&!this.deliveriesCheckBox){
      this.errorModal("parcel must contain at least one collection or delivery","Error")
      return
    }*/
    /*if(this.collectionsCheckBox){
      parcelOdj.collectionsWaybillNo=parcelOdj.collectionsWaybillNo.split(',')
      var commaError=false
      parcelOdj.collectionsWaybillNo.forEach(p=>{
        if(p==""){
          commaError=true
        }
      })
      if(commaError){
        this.errorAddParcel="error getting waybills,please make sure there are no leading ',' or extra ','"
        this.inputError('collectionWaybillNo')
        return
      }
      if(parcelOdj.collectionsWaybillNo.length!=parcelOdj.collections){
        this.errorAddParcel="Waybills collected does not match number of waybills entered"
        this.inputError('collections')
        return
      }
    }*/
    /*if(this.deliveriesCheckBox){
      parcelOdj.waybillNo=parcelOdj.waybillNo.split(',')
      var commaError=false
      parcelOdj.waybillNo.forEach(p=>{
        if(p==""){
          commaError=true
        }
      })
      if(commaError){
        this.errorAddParcel="error getting waybills,please make sure there are no leading ',' or extra ','"
        this.inputError('waybillNo')
        return
      }
      if(parcelOdj.waybillNo.length!=parcelOdj.delivered){
        this.errorAddParcel="Waybills collected does not match number of waybills entered"
        this.inputError('delivered')
        return
      }
    }*/


    parcelOdj.waybillDate = parcelOdj.waybillDate + 'T' + '00' + ':' + '00' + ':' + '00';
    // console.log(parcelOdj)
    parcelOdj.type = this.typeCheckBox ? 'PERMANENT' : 'ADHOCK';
    const token = this.userInfo.token;
    if (this.typeCheckBox) {
      const rr = this.routes.filter(r => r.oid == parcelOdj.route)[0];
      if (!rr.rate) {
        this.errorModal('selected route does not have a rate.', 'Error');
        return;
      }
      parcelOdj.route = rr.name;
      parcelOdj.routeRateId = rr.rate.oid;
    }

    // parcelOdj.route=this.routes.filter(r=>r.oid==parcelOdj.route)[0]
    // console.log(parcelOdj)
    this.loaderService.addloader();
    console.log(parcelOdj);
    this.ownerService.addParcel(token, this.ownerId, parcelOdj).subscribe(
      (res) => {
        // console.log(res)
        $('#addParcelModal').modal('hide');
        form.resetForm();

        this.parcels.push(res);
      },
      (error) => {

        this.errorModal(error.error.message, 'Error');
        this.loaderService.removeloader();
      },
      () => {
        this.loaderService.removeloader();
      }
    );
  }

  public addDriver(form: NgForm) {
    const driverOdj = form.value;
    /*
        if (this.isPam) {
          driverOdj.licenceIssueDate = driverOdj.licenceIssueDate + 'T' + '00' + ':' + '00' + ':' + '00';
        }*/
    console.log(driverOdj);

    const token = this.userInfo.token;
    this.loaderService.addloader();
    this.ownerService.addDriver(token, this.ownerId, driverOdj).subscribe(
      (res) => {
        // console.log(res)
        $('#addDriverModal').modal('hide');
        form.resetForm();
        this.drivers.push(res);
      },
      (error) => {
        // console.log(error)
        this.errorModal(error.error.message, 'Error');
        this.loaderService.removeloader();
      },
      () => {
        this.loaderService.removeloader();
      }
    );
  }

  public addVehicleFormForm(form: NgForm) {
    const vehicleObj = form.value;
    vehicleObj.contractOwnerId = this.ownerId;
    const token = this.userInfo.token;
    this.loaderService.addloader();
    this.ownerService.addVehicle(token, vehicleObj).subscribe(
      (res) => {
        // console.log(res)
        $('#addVehicleModal').modal('hide');
        form.resetForm();
        this.vehicles.push(res);
      },
      (error) => {
        // console.log(error)
        this.errorModal(error.error.message, 'Error');
        this.loaderService.removeloader();
      },
      () => {
        this.loaderService.removeloader();
      }
    );
  }

  public addBankingDetailForm(form: NgForm) {
    const bankingObj = form.value;
    bankingObj.contractOwnerId = this.ownerId;
    // console.log(form)

    const token = this.userInfo.token;
    this.loaderService.addloader();
    this.ownerService.addBankingDetails(token, bankingObj).subscribe(
      (res) => {
        this.bankingDetails = res;
        // console.log(res)
        $('#bankingDetailsModal').modal('hide');
        form.resetForm();
      },
      (error) => {
        this.loaderService.removeloader();
        // console.log(error)
        this.errorModal(error.error.message, 'Error');

      },
      () => {
        this.loaderService.removeloader();
      }
    );
  }

  public updateBankingDetailForm(form: NgForm) {
    this.addBankingDetailForm(form);
    $('#updateBankingDetailsModal').modal('hide');
  }

  public openAddParcelModal() {
    if (this.vehicles.length == 0) {
      this.errorModal('Please add vehicle before trying to a parcel.', 'Error Adding Parcel');
      return;
    }
    $('#addParcelModal').modal('show');
  }

  public openEditParcelModal(parcel: any) {
    console.log(parcel.oid);
    console.log(parcel);

    this.parcelEdit.oid = parcel.oid;
    this.parcelEdit.vehicleRegistration = parcel.vehicleRegistration;
    this.parcelEdit.collectionsManifestNumber = parcel.collectionsManifestNumber;
    this.parcelEdit.collections = parcel.collections;
    this.parcelEdit.deliveryManifestNumber = parcel.deliveryManifestNumber;
    this.parcelEdit.delivered = parcel.delivered;
    this.parcelEdit.route = parcel.route;
    this.parcelEdit.amountDue = parcel.amountDue;
    this.parcelEdit.waybillDate = parcel.waybillDate;


    this.typeCheckBox = false;
    // this.parcelEdit = x;


    $('#editParcelModal').modal('show');
  }

  public deleteParcelConfirm() {

    console.log('delete initiate');
    const token = this.userInfo.token;

    console.log('delete oid ' + this.deleteParcelId);

    this.ownerService.deleteParcelById(token, this.deleteParcelId).subscribe(
      (res) => {
        console.log(res);
        $('#deleteParcelModal').modal('hide');
        this.deleteParcelId = '';

        const index = this.parcels.findIndex(parcel => parcel.oid === res.oid);

        if (index !== -1) {
          this.parcels.splice(index, 1);
        }
      },
      (error) => {
        this.loaderService.removeloader();
        // console.log(error)
        this.errorModal(error.error.message, 'Error');

      },
      () => {
        this.loaderService.removeloader();
      }
    );
  }

  public openDeleteParcelModal(parcel) {
    this.deleteParcelId = parcel.oid;

    console.log(parcel);

    $('#deleteParcelModal').modal('show');
  }

  public openAddParcelModalForDriver(driver: any) {
    this.driverToBeAssignedToVehicle = driver;
    if (this.vehicles.length === 0) {
      $('#addVehicleModal').modal('show');
    } else if (driver.vehicleRegistration == null) {
      $('#selectVehicleAndDriver').modal('show');
    } else {

      if (this.findVehicleByRegistration(driver.vehicleRegistration)) {
        $('#addParcelModal').modal('show');
      } else {
        this.selectVehicleRateToAssign(this.findVehicleByReg(driver.vehicleRegistration));
      }


    }

  }

  public selectVehicleToAssign(vehicle) {
    if (this.drivers.length === 0) {
      this.errorModal('Please add driver before trying to assign driver to vehicle.', 'Error assigning driver');
      return;
    }
    let valid = false;
    this.drivers.forEach((d) => {
      if (!d.vehicleRegistration) {
        valid = true;
      }
    });
    if (!valid) {
      this.errorModal('all drivers have been assigned to a vehicle please add a new driver to continue.', 'Error assigning driver');
      return;
    }
    this.vehiclToAssignTo = vehicle;
    $('#assignDriver').modal('show');
  }

  public selectVehicleRateToAssign(vehicle) {
    this.vehiclToAssignTo = vehicle;
    $('#createRateModal').modal('show');
  }

  public assignDriver() {
    const token = this.userInfo.token;
    this.loaderService.addloader();
    this.ownerService.assignDriver(token, this.driverToAssign, this.vehiclToAssignTo.oid).subscribe(
      (res) => {
        this.getOwnerVehicle();
        this.getOwnerDriver();
      },
      (error) => {
        // console.log(error)
        this.errorModal(error.error.message, 'Error');
        this.loaderService.removeloader();
      },
      () => {
        this.loaderService.removeloader();
      }
    );
  }

  public createRate(form: NgForm) {

    const vehicleRate = form.value;
    vehicleRate.vehicleId = this.vehiclToAssignTo.oid;
    // console.log(vehicleRate)
    const token = this.userInfo.token;
    this.loaderService.addloader();
    this.ownerService.createVehicleRate(token, vehicleRate).subscribe(
      (res) => {
        form.resetForm();
        $('#createRateModal').modal('hide');
        this.getOwnerVehicle();
      },
      (error) => {
        // console.log(error)
        this.errorModal(error.error.message, 'Error');
        this.loaderService.removeloader();
      },
      () => {
        this.loaderService.removeloader();
      }
    );
  }

  public getVehicleRate(vehicle) {
    this.vehicleSelected = vehicle;
    const token = this.userInfo.token;
    this.loaderService.addloader();
    this.ownerService.getVehicleRate(token, vehicle.vehicleRateId).subscribe(
      (res) => {
        // console.log(res)
        this.vehicleRate = res;
        $('#updateCreateRateModal').modal('show');
      },
      (error) => {
        // console.log(error)
        this.errorModal(error.message, 'Error creating rate');
        this.loaderService.removeloader();
      },
      () => {
        this.loaderService.removeloader();
      }
    );
  }

  public removeDriver(vehicle) {
    // console.log(vehicle)
    this.selectedDriverVehicle = vehicle;
    $('#removeDriverModal').modal('show');
  }

  public updateRate(form: NgForm) {
    const vehicleRate = form.value;
    vehicleRate.vehicleId = this.vehicleSelected.oid;
    // console.log(vehicleRate)
    const token = this.userInfo.token;
    this.loaderService.addloader();
    this.ownerService.updateVehicleRate(token, vehicleRate).subscribe(
      (res) => {
        $('#updateCreateRateModal').modal('hide');
        this.getOwnerVehicle();
      },
      (error) => {
        // console.log(error)
        this.errorModal(error.error.message, 'Error');
        this.loaderService.removeloader();
      },
      () => {
        this.loaderService.removeloader();
      }
    );
  }

  public errorModal(msg, title) {
    if (!msg) {
      this.errorMsg = 'Some error occurred please try reloading the page.';
    } else {
      this.errorMsg = msg;
    }
    this.errorTitle = title;
    $('#errorModal').modal('show');
  }

  public inputError(inputId) {
    const inputElement = document.getElementById(inputId);
    if (inputElement) {
      inputElement.classList.add('error');
    }
  }

  public removeError() {
    const elements = document.querySelectorAll('.error');
    if (elements.length > 0) {
      elements.forEach(element => {
        element.classList.remove('error');
      });
    }
  }

  public getRateStructures() {
    this.errorMsg = '';
    this.loaderService.addloader();
    this.ownerService.getRateStructures(this.userInfo.token)
      .subscribe(res => {
        this.rateStructures = res;
      }, (error) => {
        this.loaderService.removeloader();
        this.errorMsg = error.message;
        console.log(error);
      }, () => {
        this.loaderService.removeloader();
      });
  }

  public selectRateType(rateType) {

    const r = this.rateStructures.filter((r: any) => r.name === this.rateType);
    this.selectedRateType = r[0];
    if (this.selectedRateType.numTiers > 0) {
      this.tierArray(this.selectedRateType.numTiers);
    }
  }

  public addRoute(form: NgForm) {
    console.log(form);
    const obj = form.value;
    obj.ownerId = this.ownerId;
    this.errorMsg = '';
    console.log(obj);
    this.loaderService.addloader();
    this.ownerService.createRoute(this.userInfo.token, obj)
      .subscribe(res => {
        form.resetForm();
        this.getRoute();
      }, (error) => {
        this.loaderService.removeloader();
        this.errorMsg = error.message;
        console.log(error);
      }, () => {
        this.loaderService.removeloader();
      });
  }

  public getRoute() {
    this.loaderService.addloader();
    this.ownerService.getRoute(this.userInfo.token, this.ownerId)
      .subscribe(res => {
        this.routes = res;
        console.log(res);
      }, (error) => {
        this.loaderService.removeloader();
        this.errorMsg = error.message;
        console.log(error);
      }, () => {
        this.loaderService.removeloader();
      });
  }

  public tierArray(size) {
    console.log(size);
    this.tiersArray = [];
    for (let i = 0; i < size; i++) {
      this.tiersArray.push({
        index: i,
        minWaybills: 0,
        maxWaybills: 0,
        amountPerWb: 0
      });
    }
  }

  public createRouteRate(route) {
    const obj = {
      routeId: route.oid,
      fixedAmount: 0.0,
      tiers: []
    };

    this.selectedRoute = route;

    if (this.selectedRoute.rateTypeObj.numTiers > 0) {
      const selectedRouteTiers = [];
      for (let i = 0; i < this.selectedRoute.rateTypeObj.numTiers; i++) {
        selectedRouteTiers.push({
          minWaybills: 0,
          maxWaybills: 0,
          amount: 0
        });
      }
      obj.tiers = selectedRouteTiers;
    }
    this.routeRate = obj;
    console.log(this.routeRate);
    console.log(this.selectedRoute);
    $('#addRouteRateModal').modal('show');
  }

  public submitRouteRate() {
    // console.log(this.routeRate)
    this.tierError = '';
    if (this.routeRate.tiers) {
      for (let i = 0; i < this.routeRate.tiers.length; i++) {

        if (i == 0 && this.routeRate.tiers[i].minWaybills != 0) {
          this.tierError = 'tier 1 must have minimum of 0';
          return;
        } else if (i > 0) {
          const prevTier = this.routeRate.tiers[i - 1];

          if ((prevTier.maxWaybills + 1) != this.routeRate.tiers[i].minWaybills) {
            this.tierError = 'tier ' + (i + 1) + ' minimum must be 1 more than tier ' + (i) + ' maximum';
            return;
          }
        }
        if (i == (this.routeRate.tiers.length - 1)) {
          if (this.routeRate.tiers[i].maxWaybills != -1) {
            this.tierError = 'tier ' + i + ' must have maximum as -1';
            return;
          }
        }
      }
    }
    this.loaderService.addloader();
    this.ownerService.createRouteRate(this.userInfo.token, this.routeRate)
      .subscribe(res => {
        $('#addRouteRateModal').modal('hide');
        this.routeRate = null;
        this.selectedRoute = null;
        console.log(res);
        this.getRoute();
      }, (error) => {
        this.loaderService.removeloader();
        this.errorMsg = error.message;
        console.log('Error', error.error.message);
        this.errorModal(error.error.message, 'Error');
        $('#addRouteRateModal').modal('hide');
        this.routeRate = null;
        this.selectedRoute = null;
      }, () => {
        this.loaderService.removeloader();
      });
  }

  public ratesToView(rate) {
    this.selectedViewRate = rate;
    $('#viewRouteRateModal').modal('show');
  }

  public changeRouteName(route) {
    console.log(route);
    console.log(route.target);
    console.log(route.target.value);
    console.log(route.target.value.name);
  }

  public selectVehicleViewBilling(vehicle) {
    console.log(vehicle);
    this.loaderService.addloader();
    this.ownerService.vehicleBilling(this.userInfo.token, vehicle.oid)
      .subscribe(res => {
        console.log(res);
        this.vehicleBilling = res;
        $('#viewVehicleBillingModal').modal('show');
      }, (error) => {
        this.loaderService.removeloader();
        console.log('Error', error);
        this.errorModal(error.error.message, 'Error');
      }, () => {
        this.loaderService.removeloader();
      });
  }

  assignDriverToAVehicle() {

    const token = this.userInfo.token;
    console.log(this.driverToBeAssignedToVehicle.oid);
    console.log(this.selectedVehicleForDriver);
    this.ownerService.assignDriver(token, this.driverToBeAssignedToVehicle.oid, this.selectedVehicleForDriver).subscribe(
      (res) => {
        this.getOwnerVehicle();
        this.getOwnerDriver();
      },
      (error) => {
        $('#selectVehicleAndDriver').modal('hide');
        this.errorModal(error.error.message, 'Error');
        this.loaderService.removeloader();
      },
      () => {
        this.loaderService.removeloader();
      }
    );

  }

  findVehicleByRegistration(registration: any): boolean {
    // Use the find() method to search for a vehicle with the given registration
    const foundVehicle = this.vehicles.find(value => value.registrationNumber === registration);

    // If a vehicle is found and it has a non-null rate, return true
    if (foundVehicle && foundVehicle.rate !== null) {
      return true;
    }

    // Otherwise, return false
    return false;
  }

  findVehicleByReg(registration: any): any {
    return this.vehicles.find(value => value.registrationNumber === registration);
  }

  viewCreateRoute() {
    $('#addParcelModal').modal('hide');
    $('#addRouteModal').modal('show');
  }

  createVehicleFroDriver() {
    $('#selectVehicleAndDriver').modal('hide');
    $('#addVehicleModal').modal('show');
  }

  createArraysForRates() {
    this.newRatesArray = [];
    for (let i = 0; i < this.rateNumberOfTiers; i++) {
      const item = {};
      this.newRatesArray.push(item);
    }
  }

  createNewRouteRate() {
    if (!this.createTierStructure && (this.selectedRateTypeForRoute !== '' || this.selectedRateTypeForRoute !== null)) {
      if (this.rateRouteName !== '' || this.rateRouteName !== null) {
        if (this.rateRouteProvince !== '' || this.rateRouteProvince !== null) {
          if (this.rateRouteBranch !== '' || this.rateRouteBranch !== null) {

            const obj = {
              province: this.rateRouteProvince,
              branch: this.rateRouteBranch,
              name: this.rateRouteName,
              rateType: this.selectedRateTypeForRoute,
              ownerId: this.ownerId
            };

            this.ownerService.createRoute(this.userInfo.token, obj)
              .subscribe(res => {
                this.getRoute();
                $('#createRouteRateModal').modal('hide');
              }, (error) => {
                this.loaderService.removeloader();
                this.errorMsg = error.message;
                console.log(error);
              }, () => {
                this.loaderService.removeloader();
              });
          }
        }
      }
    } else if (this.createTierStructure && (this.selectedRateTypeForRoute !== '' || this.selectedRateTypeForRoute !== null)) {
      if (this.rateNumberOfTiers > 0) {
        this.tierError = '';

        if (this.newRatesArray) {
          for (let i = 0; i < this.newRatesArray.length; i++) {

            if (i === 0 && this.newRatesArray[i].minWaybills !== 0) {
              this.tierError = 'tier 1 must have minimum of 0';
              return;
            } else if (i > 0) {
              const prevTier = this.newRatesArray[i - 1];

              if ((prevTier.maxWaybills + 1) !== this.newRatesArray[i].minWaybills) {
                this.tierError = 'tier ' + (i + 1) + ' minimum must be 1 more than tier ' + (i) + ' maximum';
                return;
              }
            }
            if (i === (this.newRatesArray.length - 1)) {
              if (this.newRatesArray[i].maxWaybills !== -1) {
                this.tierError = 'tier ' + (i + 1) + ' must have maximum as -1';
                return;
              }
            }
          }
        }

        if (this.rateRouteName !== '' || this.rateRouteName !== null) {
          if (this.rateRouteProvince !== '' || this.rateRouteProvince !== null) {
            if (this.rateRouteBranch !== '' || this.rateRouteBranch !== null) {

              const rateStructure = {
                numTiers: this.rateNumberOfTiers,
                name: this.rateRouteName
              };

              const token = this.userInfo.token;
              this.ownerService.createRateStructure(token, rateStructure).subscribe(
                (response) => {

                  const obj = {
                    province: this.rateRouteProvince,
                    branch: this.rateRouteBranch,
                    name: this.rateRouteName,
                    rateType: response.name,
                    ownerId: this.ownerId
                  };

                  this.ownerService.createRoute(this.userInfo.token, obj)
                    .subscribe(route => {
                      const newRouteRate = {

                        routeId: route.oid,
                        fixedAmount: this.fixedAmount,
                        tiers: this.newRatesArray

                      };
                      this.ownerService.createRouteRate(this.userInfo.token, newRouteRate)
                        .subscribe(p => {
                          this.routeRate = null;
                          this.selectedRoute = null;
                          this.getRoute();
                          $('#createRouteRateModal').modal('hide');
                        }, (error) => {
                          this.loaderService.removeloader();
                          this.errorModal(error.error.message, 'Error');
                          this.routeRate = null;
                          this.selectedRoute = null;
                        }, () => {
                          this.loaderService.removeloader();
                        });


                    }, (error) => {
                      this.loaderService.removeloader();
                      this.errorMsg = error.message;
                      console.log(error);
                    }, () => {
                      this.loaderService.removeloader();
                    });

                }, (error) => {

                }, () => this.loaderService.removeloader()
              );
            }
          }
        }


      }
    }
  }

  assignVehicleToRoute() {
    this.loaderService.addloader();
    const token = this.userInfo.token;
    console.log('' + this.selectedRouteTobeAssigned.oid);
    console.log('-' + this.vehicleSelectedToBeAssigned);
    this.ownerService.assignVehicleToRoute(token, this.vehicleSelectedToBeAssigned, this.selectedRouteTobeAssigned.oid).subscribe(
      (response) => {
        this.getRoute();
      }, (error) => {
        this.loaderService.removeloader();
        this.errorMsg = error.message;
      }, () => {
        this.loaderService.removeloader();
      }
    );
  }

  selectRoute(route: any) {
    this.selectedRouteTobeAssigned = route;
    $('#assignVehicleToRoute').modal('show');
  }

  getBranches() {

    this.loaderService.addloader();
    this.ownerService.getAllBranches(this.userInfo.token).subscribe(
      (response) => {
        this.allBranches = response;
        console.log(response);
      }, (error) => {
        console.log(error);
        this.errorMsg = error.message;
        this.loaderService.removeloader();
      }, () => this.loaderService.removeloader()
    );
  }

  makeOwnerPermanent() {
    this.loaderService.addloader();
    const obj = {
      name: this.owner.name,
      surname: this.owner.surname,
      email: this.owner.email,
      mobile: this.owner.mobile,
      isDriver: false,
      saveAccount: true,
      companyName: this.owner.companyName,
      registrationNumber: this.owner.registrationNumber
    };

    this.ownerService.makeOwnerPermanent(this.userInfo.token, obj).subscribe(
      (response) => {
        this.activeRoute.params.subscribe(params => {
          this.ownerId = params.ownerId;
        });
        if (this.ownerId) {
          this.getOwner();
        }
        this.getRateStructures();
        this.getRoute();
        this.getBranches();
      }, (error) => {
        this.errorMsg = error.message;
        this.loaderService.removeloader();
        $('#errorModal').modal('show');
      }, () => this.loaderService.removeloader()
    );
  }


  getDate() {
    const date = new Date(this.parcelEdit.waybillDate);

    // Utility function to pad single-digit numbers with a leading zero
    function pad(number) {
      return number < 10 ? '0' + number : number;
    }

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1); // Month is 0-indexed, so add 1
    const day = pad(date.getDate()); // Date of the month

    return `${year}-${month}-${day}`;
  }

  createDummyObject() {

    this.parcelEdit = {
      collectionsManifestNumber: '',
      collections: '',
      deliveryManifestNumber: '',
      delivered: ''
    };
  }

  openAddDeductionModal(driverId: any) {
    $('#openDeductionModal').modal('show');
    this.selectedDriverId = driverId;
  }


  viewDeductionModal(driverId: any) {
    console.log(driverId);
    $('#viewDeductionModal').modal('show');
    this.selectedDriverId = driverId.oid;
    this.viewDriverDeductions(this.selectedDriverId);

  }

  addDeductionToDriver() {

    if (this.isNullOrEmpty(this.deductionType)) {
      this.toggleInvalidClass('deductionType', true);
      return;
    }

    if (this.isNullOrEmpty(this.deductionValue)) {
      this.toggleInvalidClass('deductionValue', true);
      return;
    }


    if (this.isNullOrEmpty(this.deductionMonth)) {
      this.toggleInvalidClass('deductionMonth', true);
      return;
    }


    if (this.isNullOrEmpty(this.deductionYear)) {
      this.toggleInvalidClass('deductionYear', true);
      return;
    }

    const obj = {
      type: this.deductionType,
      otherDeduction: this.deductionType == "OTHER" ? this.deductionDescription : null,
      value: this.deductionValue,
      month: this.deductionMonth,
      year: this.deductionYear,
      driverId: this.selectedDriverId
    };


    this.loaderService.addloader();
    this.ownerService.addDeductionToDriver(obj, this.userInfo.token).subscribe(
      (response) => {
        this.loaderService.removeloader();
        this.resetDeductionForm();
        $('#openDeductionModal').modal('hide');
      },
      (error) => {
        this.loaderService.removeloader();
        console.log(error.message);
      }
    );
  }

  removeDeduction(m: any) {
    this.loaderService.addloader();
    this.ownerService.removeDeductions(m.driverId, m.oid, this.userInfo.token).subscribe(
      (response) => {
        this.loaderService.removeloader();
        this.viewDriverDeductions(m.driverId);
      }, (error) => {
        this.loaderService.removeloader();
      }
    );
  }

  viewDriverDeductions(driverId: any) {
    this.loaderService.addloader();
    this.ownerService.viewDrierDeductions(driverId, this.userInfo.token).subscribe(
      (response) => {
        this.loaderService.removeloader();
        console.log(response);
        this.driverDeductions = response;
      },
      (error) => {
        this.loaderService.removeloader();
      }
    );
  }

  private isNullOrEmpty(value: any): boolean {
    return value === null || value === undefined || value === '';
  }

  toggleInvalidClass(id: string, toggle: boolean) {
    const element = document.getElementById(id);
    console.log(element);
    if (element) {
      if (toggle) {
        element.classList.add('is-invalid');
      } else {
        element.classList.remove('is-invalid');
      }
    }
  }

  resetDeductionForm() {
    this.deductionType = null;
    this.deductionValue = null;
    this.deductionMonth = null;
    this.deductionYear = null;
    this.selectedDriverId = null;
  }

  createLicenseModal(driver: any): void {
    this.selectedDriverId = driver.oid;
    $('#viewLicenseModal').modal('show');
  }

  createLicense() {

    this.licenseNumberError = null;
    this.licenseIssueDateError = null;
    this.errorMsgLicense = null;
    this.driverProvinceError = null;

    if (!this.licenseNumber || this.licenseNumber.trim() === '') {
      this.licenseNumberError = 'License Number cannot be empty or spaces only';
    }

    // License Issue Date Validation
    if (!this.licenseIssueDate) {
      this.licenseIssueDateError = 'Issue Date is required';
    } else {
      const selectedDate = new Date(this.licenseIssueDate);
      const today = new Date();


      today.setHours(0, 0, 0, 0);
      selectedDate.setHours(0, 0, 0, 0);

      if (selectedDate > today) {
        this.licenseIssueDateError = 'Issue Date cannot be in the future';
      }
    }

    if (!this.licenseCode || this.licenseCode.trim() === '') {
      this.errorMsgLicense = 'Please enter license code';
    }

    if (!this.driverProvince || this.driverProvince.trim() === '') {
      this.driverProvinceError = 'Please select a province';
    }

    if (this.licenseNumberError || this.licenseIssueDateError || this.errorMsgLicense || this.driverProvinceError) {
      return;
    }


    const license = {
      number: this.licenseNumber,
      issueDate: this.licenseIssueDate,
      code: this.licenseCode,
      restrictions: this.licenseRestrictions,
      licenceFrontImage: this.licenseFrontView,
      licenceBackImage: this.licenseBackView,
      province: this.driverProvince
    };

    console.log(license);

    this.loaderService.addloader();
    this.ownerService.createLicenseForDriverOwner(this.selectedDriverId, license, this.userInfo.token).subscribe(
      (response) => {
        this.loaderService.removeloader();
        this.getOwnerDriver();
        this.selectedDriverId = null;
        $('#viewLicenseModal').modal('hide');
      },
      (error) => {
        this.loaderService.removeloader();
      }
    );
  }

  viewDriverBasePrice(driver: any) {
    this.selectedDriverId = driver.oid;
    $('#basePriceModal').modal('show');
  }

  submitBasePrice() {
    this.loaderService.addloader();
    const basePrice = {
      base_price: this.basePrice,
      oid: this.selectedDriverId
    };

    this.ownerService.updateDriverBasePrice(this.selectedDriverId, basePrice, this.userInfo.token).subscribe(
      (response) => {
        this.loaderService.removeloader();
        this.getOwnerDriver();
        this.selectedDriverId = null;
        this.closeModel('basePriceModal');
      },
      (error) => {
        this.loaderService.removeloader();
      }
    );
  }

  closeModel(name: string) {
    this.selectedDriverId = null;
    $(`#${name}`).modal('hide');
  }


  public getOwnerParcelsFiltered(retrieveType: number) {
    const token = this.userInfo.token;
    this.loaderService.addloader();

    if (retrieveType === 1) {
      this.parcelsPage = 0;
      this.parcelsSize = 10;
    }

    const filter = {
      "pageNumber": this.parcelsPage,
      "pageTotalItems": this.parcelsSize,
      "filters": {}
    }

    this.ownerService.getOwnerParcelsFiltered(token, this.ownerId, filter).subscribe(
      (res) => {
        this.parcels = res.content;
        this.totalNumberOfParcels = res.totalElement;
        console.log(this.parcels);
        // console.log(res)
      },
      (error) => {
        // console.log(error)
        this.errorModal(error.error.message, 'Error');
        this.loaderService.removeloader();
      },
      () => {
        this.loaderService.removeloader();
      }
    );
  }

  getNewOwnerParcels(pageEvent: any) {
    this.parcelsPage = pageEvent.pageIndex;
    this.parcelsSize = pageEvent.pageSize;
    this.getOwnerParcelsFiltered(0);
  }
}


