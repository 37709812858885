import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';
import { AdminService } from '../../services/admin.service';


@Component({
  selector: 'app-old-spreadsheet',
  templateUrl: './old-spreadsheet.component.html',
  styleUrls: ['../admin-portal/admin-portal.component.css', './old-spreadsheet.component.css']
})
export class OldSpreadsheetComponent implements OnInit {

  constructor(private adminService: AdminService, private router: Router,private loadservice:LoaderService) { }

  adminUser: any;
  spreadsheets:any=[];
  loader:boolean=true;
  numOfSpreadsheet:any;
  isEmpty:boolean=false;

  totalNumberOfSpreadsheets = 0;
  pageNumber = 0;
  itemsPerPage = 10;
  pageSizeOptions: number[] = [5, 10];

  ngOnInit() {
    window.scrollTo(0, 0);
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    this.getSpreadSheets();
  }


  // public getSpreadSheets(){
  //   this.loadservice.addloader()
  //   this.adminService.getSpreadSheets(this.adminUser.token).subscribe((res)=>{
  //       this.loadservice.removeloader();
  //       this.spreadsheets=res;
  //       this.numOfSpreadsheet=res.length;
  //       if(res.length===0){
  //         this.isEmpty=true;
  //       }
  //   },(error)=>{
  //     console.log("ERROR:::"+error)
  //     this.loadservice.removeloader();
  //     this.isEmpty=true;
  //   })
  // }

  public getSpreadSheets(){


    const filter = {
      pageNumber: this.pageNumber,
      pageTotalItems: this.itemsPerPage
    };

    this.loadservice.addloader()
    this.adminService.getSpreadSheetsFiltered(this.adminUser.token, filter).subscribe((res)=>{

        this.loadservice.removeloader();
        this.spreadsheets=res.content;
        this.totalNumberOfSpreadsheets = res.totalElement;

    },(error)=>{
      console.log("ERROR:::"+error)
      this.loadservice.removeloader();
      this.isEmpty=true;
    })
  }

  public selectedspreadSheet(spreadsheet:any){
      localStorage.setItem("selectedsheet",JSON.stringify(spreadsheet))
      this.router.navigate(['/view-spreadsheet']);
  }


  getNewData(pageEvent?: any) {
    this.pageNumber = pageEvent.pageIndex;
    this.itemsPerPage = pageEvent.pageSize;
    this.getSpreadSheets();
  }

  // public showTabByRole(role):boolean{
  //   const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  //   let result=false;
  //   userInfo.roles.map(r=>{
  //     if(role===r.code){
  //       result=true;
  //     }
  //   })
  //   return result;
  // }
}
