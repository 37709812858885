import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-update-custom-messages',
  templateUrl: './update-custom-messages.component.html',
  styleUrls: ['./update-custom-messages.component.css']
})
export class UpdateCustomMessagesComponent implements OnInit {

  constructor(private adminService: AdminService, private router: Router) { }

  messages: any;
  condition: any;
  adminUser: any;
  oid: any;
  text: any;
  message: any;
  loader: boolean;

  ngOnInit() {
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    // console.log(this.adminUser);
    this.messages = JSON.parse(localStorage.getItem('customMessages'));
    // console.log(this.messages);
  }

  public updateMessage() {
    const updatedMessage = {
      oid: this.messages.oid,
      message: this.message,
      type: this.messages.type
    };
    // console.log(updatedMessage);
    // console.log(this.adminUser.token);

    this.adminService.updateMessage(this.adminUser.token, updatedMessage).subscribe(
      (response) => {
        // console.log(response);
        this.text = 'Message successfully updated!';
      }, (error) => {
        // console.log(error);
        this.text = 'Message not updated. Please try again!';
      }
    );

  }

}
