import {DatePipe} from '@angular/common';
import {Input, isDevMode} from '@angular/core';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Router} from '@angular/router';
import {Parcel} from 'src/app/models/parcel';
import {ParcelDimensions} from 'src/app/models/parcelDimensions';
import {BookingService} from 'src/app/services/booking.service';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer} from '@angular/platform-browser';
import loader from '@angular-devkit/build-angular/src/angular-cli-files/plugins/single-test-transform';
import {LoaderService} from 'src/app/services/loader.service';

declare var google: any;
declare var $: any;

@Component({
  selector: 'app-courier-service',
  templateUrl: './courier-service.component.html',
  styleUrls: ['./courier-service.component.css', '../../css/style.css', '../../css/v-bg-stylish.css', '../../css/custom.css', '../../css/v-shortcodes.css']
})
export class CourierServiceComponent implements OnInit {
  constructor(private router: Router, private bookService: BookingService, private http: HttpClient, private _sanitizer: DomSanitizer, private loadservice: LoaderService) {
  }

  @ViewChild('contactFormss', {static: true}) contactFormss: NgForm;

  public userSettings2: any = {
    showRecentSearch: true,
    geoCountryRestriction: ['ZA'],
    inputPlaceholderText: 'Enter pick-up address.',
    geoTypes: ['address', 'establishment']
  };

  public userSettings: any = {
    showRecentSearch: true,
    geoCountryRestriction: ['ZA'],
    inputPlaceholderText: 'Enter drop-off address.',
    geoTypes: ['address', 'establishment']
  };

  email: string;
  msgBody: string;
  fullName: string;
  msgStatus = '';

  pickUpNotMsg: string;
  pickUpAddress: string;
  dropOffAddress: string;
  dropOffNotMsg = '';
  dropOffNotFound: boolean;
  pickUpNotFound: boolean;
  pickUpLat: any;
  pickUpLong: any;

  pickUpPlaceHolder: string;
  dropOffPlaceHolder: string;

  pickUpCoordinate = [];
  dropOffCoordinate = [];

  vehicleTypeRate: any = [];

  pickUpCoordinateLocation: any = {};
  dropOffCoordinateLocation: any = {};

  dropOffLat: number;
  dropOffLong: number;

  lat: number = parseFloat('-25.98953');
  lng: number = parseFloat('28.12843');

  vehicleType: any;

  userLat: number;
  userLong: number;

  location: any = {};
  dir = undefined;
  priceBox = false;
  dedicated = true;

  distance: number;
  distanceTest: number;
  loader = false;

  totalExpressPrice: number;
  distanceOfTrip: any;
  tempAmount: number;

  vehicleTypeData: any;
  vehicleOid: string;
  errorMessage: string;
  description = '';
  province = 'UNKNOWN';
  validateProvince: any;
  validatePickuProvince: string;
  context: any = this;

  address: string;
  city: any;
  Surbub: any;
  postalCode: any;
  address11: any;
  destination: any;
  matrix: any;
  vehicle: string;
  addressComponent: any = [];

  departureCoordinate: any = {};
  destinationCoordinate: any = {};

  mass: any;
  isExpress: boolean;

  miniVan = 'assets\\img\\home\\Group_1014.png';
  oneHalfTon = 'assets\\img\\home\\Group_1016.png';
  oneTon = 'assets\\img\\home\\Group_1015.png';
  fourTon = 'assets\\img\\home\\Group_1017.png';
  eightTon = 'assets\\img\\home\\Group_1018.png';
  express = 'assets\\img\\retail\\expressVehicle.png';
  vehicleVan = 'assets\\img\\home\\Group_1046.png';
  image = 'assets\\img\\retail\\Group851.png';

  yes_value: any;
  yes_value1: any;
  yes_value2: any;
  yes_value3: any;
  yes_value4: any;
  yes_value5: any;

  no_value: any;
  no_value1: any;
  no_value2: any;
  no_value3: any;
  no_value4: any;
  no_value5: any;

  @ViewChild('focus', {static: false}) input: ElementRef;
  @ViewChild('activeTab', {static: false}) inputActive: ElementRef;

  activeColor = '';
  activeTab = true;

  flashFlight = 0;
  flashRoad = 0;
  expressCourier = 0;
  budgetCourier = 0;
  sheshaMode = 0;
  tenderDocument = 0;

  flashFlightMode: any;

  transportMode: any;
  isItemPicked = false;
  mainCityOid: any;
  isPayed = false;
  fromSuburb: any;
  toSuburb: any;
  pickUpPCode: any;
  dropOffPCode: any;
  pickUpProvince: any;
  dropOffProvince: string;
  type: any;
  pickUpAmt = 0.0;
  airlineAmt = 0.0;
  dropOffAmt = 0.0;
  destinationProvince: any;
  flashRoadMode: any;
  expressCourierMode: any;
  budgetCourierMode: any;

  isBudget = false;
  isCourier = false;
  isRoad = false;
  isFlight = false;
  isDash = false;
  isTender = false;
  tenderDocumentSelected = false;
  miniVanVehicleType: any;

  isExpress_ = true;

  flightAvailable = false;
  flashRoadAvailable = false;
  sheshaAvailable = true;

  expressChoicesActive: string;
  expressChoicesInActive: string;

  isExpressBudget = false;
  isCourierExpress = false;

  dashVehiceType: any;
  selectedVehicleT: any;

  currentElement: any;
  newPickUpSuburb: any;
  newDropOffSuburb: any;
  selectedOption = 'COURIER';
  bookingDate: any;
  promptPostalCode: any;
  dateObj: any;

  promptedPickUpPCode: any;
  promptedDropOffPCode: any;

  numOfParcels: number;

  enteredPickUpAddress: any;
  enteredPickUpSuburb: any;
  enteredPickUpPostalCode: any;
  enteredPickUpProvince: any;

  enteredDropOffAddress: any;
  enteredDropOffSuburb: any;
  enteredDropOffPostalCode: any;
  enteredDropOffProvince: any;

  disablePickUpAddresses = true;
  disableDropOffAddress = true;

  displayPickUpAddress: any;
  displayDropOffAddress: any;

  width: number;
  height: number;
  length: number;
  pracel: Parcel;
  pracels: ParcelDimensions[] = [];
  pracelList = [];

  numberOfPracels = [];
  showParcels = true;
  totalMass: any = 0;
  totalLength: any = 0;
  totalBreadth: any = 0;
  totalHeight: any = 0;

  massValidate: any;
  lengthValidate: any;
  breadthValidate: any;
  heightValidate: any;

  breadthMsg = '';
  lengthMsg = '';
  heightMsg = '';
  massMsg = '';

  parcelValidation = false;
  public title = '';
  userObject: any;
  checkingMsg = '';
  overload = false;
  capturePickUpAddressLabel: any;
  captureDropOffAddressLabel: any;
  checkingPickUpMsg = '';
  checkingDropOffMsg = '';

  savedAddresses: any = [];
  pickedSavedAddresses: any;

  enteredPickUpAddressMsg = '';
  enteredPickUpSuburbMsg = '';
  enteredPickUpPostalCodeMsg = '';
  enteredPickUpProvinceMsg = '';
  enteredPickUpSuccessMsg = '';
  pickUpExtraDetails: any = [];

  enteredDropOffAddressMsg = '';
  enteredDropOffSuburbMsg = '';
  enteredDropOffPostalCodeMsg = '';
  enteredDropOffProvinceMsg = '';
  enteredDropOffSuccessMsg = '';
  dropOffExtraDetails: any = [];

  usedSavedAddress = false;
  metricTotal = 0;
  terms: any;
  termsControl = false;
  noInsurance: any;
  noInsuranceControl = false;
  termsMsg = '';

  sameMassParcels = false;
  redirectToFleetMsg = '';

  single_parcel_mass: any;
  single_parcel_breadth: any;
  single_parcel_height: any;
  single_parcel_length: any;
  droppaMainCities: any = [];
  distanceRadius = 0;
  maincityDistanceDash = 0;
  homeImg;
  envelopSelected = false;
  textBookSelected = false;
  shoeBoxSelected = false;
  movingBoxSelected = false;

  isBike = false;
  noServiceSelected = false;
  isBeforeEight = false;

  @Input() selectedPracel = {};

  selectedPickUpSuburb: any;

  selectedDropOffSuburb: any;

  surbCheck={fromSuburbList:[],toSuburbList:[],pickUpPCodeList:[],dropOffPCodeList:[]}
  fromSuburbList:any[]=[];
  toSuburbList:any[]=[];
  pickUpPCodeList:any[]=[];
  dropOffPCodeList:any[]=[];

  usedGoogleAddressPickUp = false;
  usedGoogleAddressDropOff = false;

  ngOnInit() {
    // 'paymentFromTable' variable below is used to mark that this is a new booking
    localStorage.setItem('paymentFromTable', 'false');

    if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition((pos) => this.setPosition(pos));
    }
    // this.loadHomePageImage();
    this.userLoggedIn();
    this.getVehicleTypeRates();
    this.matrix = new google.maps.DistanceMatrixService();
    // this.getMainCity();
    window.scrollTo(0, 0);
    this.resetImages();
  }


  async loadHomePageImage() {
    await this.http.get('/assets/Homepage_banner.png', {responseType: 'blob'})
      .subscribe(res => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data = reader.result;
          this.homeImg = this._sanitizer.bypassSecurityTrustResourceUrl('' + base64data);
          // this.isLand = false;
        };
        reader.readAsDataURL(res);
      });
  }
  resetDropOff(){
    this.toSuburbList=[];
    this.dropOffPCodeList=[];
  }
  resetPickUp(){
    this.fromSuburbList=[];
    this.pickUpPCodeList=[];
  }
  public setPosition(position) {
    this.location = position.coords;
    this.userLat = parseFloat(this.location.latitude);
    this.userLong = parseFloat(this.location.longitude);
  }

  autoCompleteCallback1(selectedData: any) {
    // console.log(selectedData);
    this.resetPickUp()
    if(!selectedData)return
    let suburb1 = selectedData.data.address_components
      .filter(f => JSON.stringify(f.types) === JSON.stringify(['locality', 'political']))[0];

    if(suburb1){
      suburb1= suburb1.short_name;
    }

    let suburb2 = selectedData.data.address_components
      .filter(f => JSON.stringify(f.types) === JSON.stringify(['sublocality_level_1', 'political']))[0];

    if(!suburb2){
      suburb2 = selectedData.data.address_components
        .filter(f => JSON.stringify(f.types) === JSON.stringify(['sublocality_level_1','sublocality', 'political']))[0];
    }
    if(suburb2){
      suburb2= suburb2.short_name;
    }
    let suburbToCheck;
    //   .short_name;

    if(suburb1&&suburb2){
      suburbToCheck=suburb2;
      this.fromSuburbList.push(suburb1.toUpperCase())
      this.fromSuburbList.push(suburb2.toUpperCase())
    }else if(suburb1&&!suburb2){
      suburbToCheck=suburb1;
      this.fromSuburbList.push(suburb1.toUpperCase())
    }else if(!suburb1&&suburb2){
      suburbToCheck=suburb2;
      this.fromSuburbList.push(suburb2.toUpperCase())
    }

    this.pickUpPCode = null;
    this.promptedPickUpPCode = null;
    // console.log(selectedData);
    if (!selectedData) {
      this.pickUpNotMsg = 'Location not found, Please enter nearest location.';
      this.pickUpNotFound = false;
    } else {
      for (let x = 0; x < selectedData.data.address_components.length; x++) {
        for (let y = 0; y < selectedData.data.address_components[x].types.length; y++) {
          for (let a = 0; a < selectedData.data.address_components[x].types.length; a++) {
            if (selectedData.data.address_components[x].types[a] == 'postal_code') {
              this.pickUpPCode = selectedData.data.address_components[x].long_name;
              if (this.pickUpPCode === '2999') {
                this.pickUpPCode = '0299';
              }
              if (this.pickUpPCode === '3640') {
                this.pickUpPCode = '3610';
              }
              this.getPickUpSuburb(this.pickUpPCode);
            } else if (selectedData.data.address_components[x].types[a] == 'administrative_area_level_1') {
              this.pickUpProvince = selectedData.data.address_components[x].long_name;
            }
          }
        }
      }
      if (this.pickUpPCode == null) {
        this.pickUpPostalCodeService(selectedData);
      }else {
        this.pickUpPCodeList.push(this.pickUpPCode)
      }

      this.pickUpAddress = selectedData.data.description;
      this.pickUpLat = selectedData.data.geometry.location.lat;
      this.pickUpLong = selectedData.data.geometry.location.lng;
      this.pickUpCoordinate = [selectedData.data.geometry.location.lat, selectedData.data.geometry.location.lng];
      this.pickUpCoordinateLocation = {coordinates: this.pickUpCoordinate, address: this.pickUpAddress};
      this.departureCoordinate = {
        lat: selectedData.data.geometry.location.lat,
        lng: selectedData.data.geometry.location.lng
      };
      this.getAdminArea(selectedData.data.address_components);

    }

    // console.log("test.....pick up");
    // console.log('Distance is ' + this.pickUpProvince, ".....", this.dropOffProvince);

    this.usedGoogleAddressPickUp = true;
  }

  autoCompleteCallback2(selectedData: any) {
    this.resetDropOff()
    if(!selectedData)return
    let suburb1 = selectedData.data.address_components
      .filter(f => JSON.stringify(f.types) === JSON.stringify(['locality', 'political']))[0];

    if(suburb1){
      suburb1= suburb1.short_name;
    }

    let suburb2 = selectedData.data.address_components
      .filter(f => JSON.stringify(f.types) === JSON.stringify(['sublocality_level_1', 'political']))[0];

    if(!suburb2){
      suburb2 = selectedData.data.address_components
        .filter(f => JSON.stringify(f.types) === JSON.stringify(['sublocality_level_1','sublocality', 'political']))[0];
    }
    if(suburb2){
      suburb2= suburb2.short_name;
    }
    let suburbToCheck;
    //   .short_name;

    if(suburb1&&suburb2){
      suburbToCheck=suburb2;
      this.toSuburbList.push(suburb1.toUpperCase())
      this.toSuburbList.push(suburb2.toUpperCase())
    }else if(suburb1&&!suburb2){
      suburbToCheck=suburb1;
      this.toSuburbList.push(suburb1.toUpperCase())
    }else if(!suburb1&&suburb2){
      suburbToCheck=suburb2;
      this.toSuburbList.push(suburb2.toUpperCase())
    }


    this.dropOffPCode = null;
    this.promptedDropOffPCode = null;
    if (!selectedData) {
      this.dropOffNotMsg = 'Location not found, Please enter nearest location.';
      this.dropOffNotFound = false;
    } else {
      for (let x = 0; x < selectedData.data.address_components.length; x++) {
        for (let y = 0; y < selectedData.data.address_components[x].types.length; y++) {
          for (let a = 0; a < selectedData.data.address_components[x].types.length; a++) {
            if (selectedData.data.address_components[x].types[a] == 'postal_code') {
              this.dropOffPCode = selectedData.data.address_components[x].long_name;
              if (this.dropOffPCode === '2999') {
                this.dropOffPCode = '0299';
              }
              if (this.pickUpPCode === '3640') {
                this.pickUpPCode = '3610';
              }
              this.getDropOffSuburb(this.dropOffPCode);
            } else if (selectedData.data.address_components[x].types[a] == 'administrative_area_level_1') {
              this.dropOffProvince = selectedData.data.address_components[x].long_name;
            }
          }
        }
      }
      if (this.dropOffPCode == null) {
        this.dropOffPostalCodeService(selectedData);
      }else {
        this.dropOffPCodeList.push(this.dropOffPCode)
      }
      this.dropOffAddress = selectedData.data.description;
      this.dropOffLat = selectedData.data.geometry.location.lat;
      this.dropOffLong = selectedData.data.geometry.location.lng;
      this.dropOffCoordinate = [selectedData.data.geometry.location.lat, selectedData.data.geometry.location.lng];
      this.dropOffCoordinateLocation = {coordinates: this.dropOffCoordinate, address: this.dropOffAddress};
      this.destinationCoordinate = {
        lat: selectedData.data.geometry.location.lat,
        lng: selectedData.data.geometry.location.lng
      };
    }

    this.usedGoogleAddressDropOff = true;
  }

  public getPickUpSuburb(postcalCode) {
    this.bookService.getPickUpSuburb(postcalCode).subscribe(
      response => {
        this.fromSuburb = response.suburb;
        // console.log(this.fromSuburb);
      }, error => {
        console.log(error);
      }, () => {
      }
    );
  }

  public getDropOffSuburb(postcalCode) {
    this.bookService.getDropOffSuburb(postcalCode).subscribe(
      response => {
        this.toSuburb = response.suburb;
      }, error => {
        console.log(error);
      }, () => {
      }
    );
  }

  public clearDetails(pracel) {
    pracel.parcel_length = 0;
    pracel.parcel_breadth = 0;
    pracel.parcel_height = 0;
    pracel.parcel_mass = 0;
  }

  public envelopeSize(pracel) {
    pracel.parcel_length = 22;
    pracel.parcel_breadth = 11;
    pracel.parcel_height = 0.1;
    pracel.parcel_mass = 0.1;

    this.envelopSelected = true;
    this.textBookSelected = false;
    this.shoeBoxSelected = false;
    this.movingBoxSelected = false;
  }

  public textBookSize(pracel) {
    pracel.parcel_length = 32;
    pracel.parcel_breadth = 24;
    pracel.parcel_height = 1;
    pracel.parcel_mass = 0.2;

    this.envelopSelected = false;
    this.textBookSelected = true;
    this.shoeBoxSelected = false;
    this.movingBoxSelected = false;
  }

  public shoeBoxSize(pracel) {
    pracel.parcel_length = 35;
    pracel.parcel_breadth = 20;
    pracel.parcel_height = 15;
    pracel.parcel_mass = 9;

    this.envelopSelected = false;
    this.textBookSelected = false;
    this.shoeBoxSelected = true;
    this.movingBoxSelected = false;
  }

  public movingBoxSize(pracel) {
    pracel.parcel_length = 32;
    pracel.parcel_breadth = 24;
    pracel.parcel_height = 25;
    pracel.parcel_mass = 12;

    this.envelopSelected = false;
    this.textBookSelected = false;
    this.shoeBoxSelected = false;
    this.movingBoxSelected = true;
  }

  public singleParcelEnvelopeSize() {
    this.single_parcel_length = 22;
    this.single_parcel_breadth = 11;
    this.single_parcel_height = 0.1;
    this.single_parcel_mass = 0.1;

    this.envelopSelected = true;
    this.textBookSelected = false;
    this.shoeBoxSelected = false;
    this.movingBoxSelected = false;
  }

  public singleParcelTextBookSize() {
    this.single_parcel_length = 32;
    this.single_parcel_breadth = 24;
    this.single_parcel_height = 1;
    this.single_parcel_mass = 0.2;

    this.envelopSelected = false;
    this.textBookSelected = true;
    this.shoeBoxSelected = false;
    this.movingBoxSelected = false;
  }

  public singleParcelShoeBoxSize() {
    this.single_parcel_length = 35;
    this.single_parcel_breadth = 20;
    this.single_parcel_height = 15;
    this.single_parcel_mass = 9;

    this.envelopSelected = false;
    this.textBookSelected = false;
    this.shoeBoxSelected = true;
    this.movingBoxSelected = false;
  }

  public singleParcelMovingBoxSize() {
    this.single_parcel_length = 32;
    this.single_parcel_breadth = 24;
    this.single_parcel_height = 25;
    this.single_parcel_mass = 12;

    this.envelopSelected = false;
    this.textBookSelected = false;
    this.shoeBoxSelected = false;
    this.movingBoxSelected = true;
  }

  private resetParcelImages() {
    /*this.single_parcel_length = 0;
    this.single_parcel_breadth = 0;
    this.single_parcel_height = 0;
    this.single_parcel_mass = 0;

    this.pracel.parcel_mass = 0;
    this.pracel.parcel_length = 0;
    this.pracel.parcel_breadth = 0;
    this.pracel.parcel_height = 0;*/

    // this.sameMassParcels = true;

    this.envelopSelected = false;
    this.textBookSelected = false;
    this.shoeBoxSelected = false;
    this.movingBoxSelected = false;
  }

  public pickUpPostalCodeService(selectedData) {
    const latTest = selectedData.data.geometry.location.lat;
    const lngTest = selectedData.data.geometry.location.lng;

    const geocoder = new google.maps.Geocoder;
    const latlng = {
      lat: latTest,
      lng: lngTest,
    };
    geocoder.geocode(
      {location: latlng}, (results) => {
        if (results[0]) {
          const foundAddress = results[0].address_components;

          for (let x = 0; x < foundAddress.length; x++) {
            for (let y = 0; y < foundAddress[x].types.length; y++) {
              for (let a = 0; a < foundAddress[x].types.length; a++) {
                if (foundAddress[x].types[a] == 'postal_code') {
                  this.pickUpPCode = foundAddress[x].long_name;
                  this.pickUpPCodeList.push(this.pickUpPCode)
                  this.getPickUpSuburb(this.pickUpPCode);
                }
              }
            }
          }

          if (this.pickUpPCode == null) {
            this.promptPostalCode = 'Please enter your area postal code below';
            $('#pickUpPostcalCode').modal('show');
          }

        }
      }
    );
  }

  public dropOffPostalCodeService(selectedData) {
    const latTest = selectedData.data.geometry.location.lat;
    const lngTest = selectedData.data.geometry.location.lng;

    const geocoder = new google.maps.Geocoder;
    const latlng = {
      lat: latTest,
      lng: lngTest,
    };
    geocoder.geocode(
      {location: latlng}, (results) => {
        if (results[0]) {
          const foundAddress = results[0].address_components;

          for (let x = 0; x < foundAddress.length; x++) {
            for (let y = 0; y < foundAddress[x].types.length; y++) {
              for (let a = 0; a < foundAddress[x].types.length; a++) {
                if (foundAddress[x].types[a] == 'postal_code') {
                  this.dropOffPCode = foundAddress[x].long_name;
                  this.dropOffPCodeList.push(this.dropOffPCode)
                  this.getDropOffSuburb(this.dropOffPCode);

                }
              }
            }
          }

          if (this.dropOffPCode == null) {
            this.promptPostalCode = 'Please enter your area postal code below';
            $('#dropOffPostcalCode').modal('show');
          }

        }
      }
    );
  }

  public geocodeLatLng(geocoder, userLat, userLong) {
    const latlng = {lat: userLat, lng: userLong};
    const self = this;
    geocoder.geocode({location: latlng}, function (results, status) {
      if (status === 'OK') {
        const len = results[0].address_components.length;
        let data;
        for (let i = 0; i < len; i++) {
          if (results[0].address_components[i].short_name.length === 2 && results[0].address_components[i].long_name.length >= 7) {
            data = results[0].address_components[i];
            self.validateProvince = data.long_name;
            if (data.long_name === 'South Africa') {
              self.geocodeLatLngProvince(geocoder, results[1].address_components[1].long_name);
            }
            break;
          }
        }
        self.province = data.long_name;
        self.province = self.validateProvince;
      } else {
        self.province = 'UNKNOWN';
        self.province = self.validateProvince;
      }
    });
  }

  public geocodeLatLngProvince(geocoder, city) {
    const self = this;
    geocoder.geocode({address: city}, function (results, status) {
      if (status === 'OK') {
        const len = results[0].address_components.length;
        let data;
        for (let i = 0; i < len; i++) {
          if (results[0].address_components[i].short_name.length === 2 && results[0].address_components[i].long_name.length >= 7) {
            data = results[0].address_components[i];
            self.validateProvince = data.long_name;
            break;
          }
        }
        self.province = data.long_name;
        self.province = self.validateProvince;
      } else {
        self.province = 'UNKNOWN';
        self.province = self.validateProvince;
      }

    });
  }

  private getAdminArea(address_component: []) {

    this.addressComponent = address_component;
    const len = address_component.length;

    for (let i = 0; i < len; i++) {
      switch (this.addressComponent[i].long_name) {
        case 'Gauteng': {
          this.province = 'GAUTENG';
          this.validateProvince = 'Gauteng';
          break;
        }
        case 'KwaZulu-Natal': {
          this.province = 'KWAZULU-NATAL';
          this.validateProvince = 'KwaZulu-Natal';
          break;
        }
        case 'kzn': {
          this.province = 'kzn';
          this.validateProvince = 'KWA_ZULU_NATAL';
          break;
        }
        case 'Western Cape': {
          this.province = 'WESTERN CAPE';
          this.validateProvince = 'Western Cape';
          break;
        }
        case 'Eastern Cape': {
          this.province = 'EASTERN CAPE';
          this.validateProvince = 'Eastern Cape';
          break;
        }
        case 'Limpopo': {
          this.province = 'LIMPOPO';
          this.validateProvince = 'Limpopo';
          break;
        }
        case 'Mpumalanga': {
          this.province = 'MPUMALANGA';
          this.validateProvince = 'Mpumalanga';
          break;
        }
        case 'Northern Cape': {
          this.province = 'NORTHERN CAPE';
          this.validateProvince = 'Northern Cape';
          break;
        }
        case 'North West': {
          this.province = 'NORTH WEST';
          this.validateProvince = 'North West';
          break;
        }
        case 'Free State': {
          this.province = 'FREE STATE';
          this.validateProvince = 'Free State';
          break;
        }
        default: {

          break;
        }

      }
    }
  }

  public enterManualAddress() {
    $('#addressPopUp').modal('show');
  }

  public enterManualAddress2() {
    $('#addressPopUp2').modal('show');
  }

  onSelect(pracel): void {
    this.selectedPracel = pracel;
    this.pracel = pracel;
  }

  public modelChanged(parcel) {

    if (parcel.parcel_mass > 70) {
      // this.massMsg = 'Mass cannot exceed 70';
      this.massMsg = ' ';
    } else if (parcel.parcel_mass == 0) {
      this.massMsg = 'Mass cannot be 0';
    } else if (parcel.parcel_mass < 0) {
      this.massMsg = 'Mass cannot be a negative number';
    } else if (parcel.parcel_mass == null) {
      this.massMsg = 'Mass cannot be empty';
    } else if (parcel.parcel_mass < 70) {
      this.massMsg = ' ';
    }

    if (parcel.parcel_breadth > 150) {
      // this.breadthMsg = 'Width for parcel cannot exceed 150';
      this.breadthMsg = ' ';
    } else if (parcel.parcel_breadth == 0) {
      this.breadthMsg = 'Width cannot be 0';
    } else if (parcel.parcel_breadth < 0) {
      this.breadthMsg = 'Width cannot be a negative number';
    } else if (parcel.parcel_breadth == null) {
      this.breadthMsg = 'Width cannot be empty';
    } else if (parcel.parcel_breadth < 150) {
      this.breadthMsg = ' ';
    }

    if (parcel.parcel_height > 150) {
      this.heightMsg = 'Height for parcel cannot exceed 150';
      this.heightMsg = ' ';
    } else if (parcel.parcel_height == 0) {
      this.heightMsg = 'Height cannot be 0';
    } else if (parcel.parcel_height < 0) {
      this.heightMsg = 'Height cannot be a negative number';
    } else if (parcel.parcel_height == null) {
      this.heightMsg = 'Height cannot be empty';
    } else if (parcel.parcel_height < 150) {
      this.heightMsg = ' ';
    }

    if (parcel.parcel_length > 150) {
      this.lengthMsg = 'Length for parcel cannot exceed 150';
      this.lengthMsg = ' ';
    } else if (parcel.parcel_length == 0) {
      this.lengthMsg = 'Length cannot be 0';
    } else if (parcel.parcel_length < 0) {
      this.lengthMsg = 'Length cannot be a negative number';
    } else if (parcel.parcel_length == null) {
      this.lengthMsg = 'Length cannot be empty';
    } else if (parcel.parcel_length < 150) {
      this.lengthMsg = ' ';
    }
  }

  public single_mass_change(single_parcel_mass) {
    this.totalMass = single_parcel_mass * this.numOfParcels;
  }

  public postMultipleParcels() {
    this.priceBox = true;
  }

  public getMultipleParcels() {
    this.priceBox = true;

    // If user did not tick the checkbox
    if (!this.sameMassParcels) {
      this.numberOfPracels = new Array(this.numOfParcels);
      this.pracels = [];

      for (let x = 0; x < this.numberOfPracels.length; x++) {
        const pracel = {parcel_length: '', parcel_breadth: '', parcel_height: '', parcel_mass: ''};
        this.pracels.push(pracel);
      }

      this.onSelect(this.pracels[0]);
      window.scroll(0, 0);
    }
  }

  public calculateMetricFactor() {
    let metricTotal = 0;
    let volumeMetric = 0;
    // console.log(this.pracels);
    this.pracels.forEach(element => {
      metricTotal = (element.parcel_breadth * element.parcel_height * element.parcel_length) / 5000;
      volumeMetric = volumeMetric + metricTotal;
    });
    // console.log(volumeMetric);
    return volumeMetric;
  }

  public calculateMetricVolume() {
    this.metricTotal = this.metricTotal + Math.floor(this.calculateMetricFactor());
  }
  surbCheckFunction(){
    this.surbCheck.fromSuburbList= this.usedGoogleAddressPickUp ? this.fromSuburbList : [this.enteredPickUpSuburb];
    this.surbCheck.toSuburbList= this.usedGoogleAddressDropOff ? this.toSuburbList : [this.enteredDropOffSuburb];
    this.surbCheck.pickUpPCodeList= this.usedGoogleAddressPickUp ? this.pickUpPCodeList : [this.enteredPickUpPostalCode.trim()];
    this.surbCheck.dropOffPCodeList= this.usedGoogleAddressDropOff ? this.dropOffPCodeList : [this.enteredDropOffPostalCode.trim()];
    // console.log(this.surbCheck)
    localStorage.setItem("surbCheck",JSON.stringify(this.surbCheck))
  }
  public showParcelScreen() {

    this.surbCheckFunction()
    if (this.pickUpAddress != null && this.dropOffAddress != null) {

      if (this.sameMassParcels) {
        if (this.numOfParcels === 0) {
          this.msgStatus = 'Number of parcels cannot be 0.';
        } else if (this.numOfParcels < 0) {
          this.msgStatus = 'Parcels cannot be a negative number.';
        } else if (this.numOfParcels == null) {
          this.msgStatus = 'Number of parcels cannot be empty.';
        } /*else if (this.numOfParcels > 150) {
          this.msgStatus = 'You can only transport up to 150 parcels.';
        }*/ else {
          this.msgStatus = '';
          this.showParcels = false;
          this.getMultipleParcels();
        }
      } else {
        if (this.numOfParcels === 0) {
          this.msgStatus = 'Number of parcels cannot be 0.';
        } /*else if (this.numOfParcels > 8) {
          this.redirectToFleetMsg = 'You can only transport up to 8 parcels if dimensions are different.';
          $('#navigateToFleet').modal('show');
        }*/ else if (this.numOfParcels < 0) {
          this.msgStatus = 'Parcels cannot be a negative number.';
        } else if (this.numOfParcels == null) {
          this.msgStatus = 'Number of parcels cannot be empty.';
        } else {
          this.redirectToFleetMsg = '';
          this.msgStatus = '';
          this.showParcels = false;
          this.getMultipleParcels();
        }
      }

    } else {
      this.errorMessage = 'Please enter all addresses.';
      $('#errorDialog').modal('show');
    }
  }

  public backToHome() {
    this.pracels = [];
    this.numOfParcels = 0;
    this.numberOfPracels = [];
    this.priceBox = false;
    this.sameMassParcels = false;
    this.single_parcel_length = '';
    this.single_parcel_breadth = '';
    this.single_parcel_height = '';
    this.single_parcel_mass = '';
    this.termsControl = false;
    this.terms = false;
    this.noInsurance = false;
    this.noInsuranceControl = false;
  }

  public getQuote() {

    if (!this.sameMassParcels) {
      if (!(this.termsControl && (isDevMode() ? this.noInsurance : true))) {

        $('#termsAndConditionsModal').modal('show');
        this.termsMsg = 'Please read the text beneath the boxes & click on the checkbox to proceed.';
      } else if (this.checkDimensionValidation()) {
        this.getCourierQuote();
      }
    } else {
      if (this.single_parcel_mass == null) {
        alert('mass is missing');
      } else if (this.single_parcel_breadth == null) {
        alert('breadth is missing');
      } else if (this.single_parcel_height == null) {
        alert('height is missing');
      } else if (this.single_parcel_length == null) {
        alert('length is missing');
      } else if (!(this.termsControl && !this.noInsurance)) {
        $('#termsAndConditionsModal').modal('show');
        this.termsMsg = 'Please read the text beneath the boxes & click on the checkbox to proceed.';
      } else {
        this.numberOfPracels = new Array(this.numOfParcels);
        this.pracels = [];
        for (let x = 0; x < this.numOfParcels; x++) {
          const pracel = {
            parcel_length: this.single_parcel_length,
            parcel_breadth: this.single_parcel_breadth,
            parcel_height: this.single_parcel_height,
            parcel_mass: this.single_parcel_mass
          };

          this.pracels.push(pracel);


        }
        this.getCourierQuote();
      }
    }
  }

  public checkDimensionValidation(): Boolean {

    let check = false;

    for (let a = 0; a < this.pracels.length; a++) {
      if (this.pracels[a].parcel_breadth == null || this.pracels[a].parcel_height == null || this.pracels[a].parcel_length == null || this.pracels[a].parcel_mass == null) {
        this.checkingMsg = 'Please make sure that none of the parcels details are empty.';
        $('#validationModal').modal('show');
        check = false;
        break;
      } else if (this.pracels[a].parcel_breadth == 0 || this.pracels[a].parcel_height == 0 || this.pracels[a].parcel_length == 0 || this.pracels[a].parcel_mass == 0) {
        this.checkingMsg = 'Please make sure that the entered numbers are not 0.';
        $('#validationModal').modal('show');
        check = false;
        break;
      } /*else if (this.pracels[a].parcel_breadth > 150 || this.pracels[a].parcel_height > 150 || this.pracels[a].parcel_length > 150 || this.pracels[a].parcel_mass > 150) {
        this.checkingMsg = 'Parcel details entered cannot exceed 150cm.';
        $('#validationModal').modal('show');
        check = false;
        break;
      }*/ else if (this.pracels[a].parcel_breadth < 0 || this.pracels[a].parcel_height < 0 || this.pracels[a].parcel_length < 0 || this.pracels[a].parcel_mass < 0) {
        this.checkingMsg = 'Parcel details entered cannot be a negative number.';
        $('#validationModal').modal('show');
        check = false;
        break;
      } else {
        check = true;
      }
    }

    return check;

  }

  public deleteParcels() {
    this.pracels = [];
  }

  public checkingParcelOverLimit() {
    for (let a = 0; a < this.pracels.length; a++) {
      if (this.pracels[a].parcel_mass > 70) {
        this.overload = true;
        break;
      } else {
        this.overload = false;
      }
    }
  }

  public calculateParcelDimensions() {
    let totalParcelMass = 0;

    this.pracels.forEach(element => {
      totalParcelMass += element.parcel_mass;
    });
    this.totalMass = totalParcelMass;
  }

  async getMainCity() {

    await this.bookService.getPOI().subscribe((res) => {
      if (res) {
        this.droppaMainCities = res;
        for (let i = 0; i < this.droppaMainCities.length; i++) {
          this.distanceRadius = this.coordinateDistance(this.pickUpLat, this.pickUpLong, Number.parseFloat(this.droppaMainCities[i].location.coordinates[0]), Number.parseFloat(this.droppaMainCities[i].location.coordinates[1]), 'K');
          const dist = Math.round(this.distanceRadius);
          if (dist <= 70) {
            // console.log(dist);
            this.maincityDistanceDash = dist;
            break;
          }
        }
      }
    });
    // return distanceFound
  }

  private coordinateDistance(lat1, lon1, lat2, lon2, unit): number {
    const radlat1 = Math.PI * lat1 / 180;
    const radlat2 = Math.PI * lat2 / 180;
    const theta = lon1 - lon2;
    const radtheta = Math.PI * theta / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit == 'K') {
      dist = dist * 1.609344;
    }
    if (unit == 'N') {
      dist = dist * 0.8684;
    }
    return dist;
  }

  async getExpressPricings() {

    for (const item of this.pracels) {
      if (item.parcel_breadth > 150 || item.parcel_height > 150 ||
        item.parcel_length > 150 || item.parcel_mass > 70) {
        this.flashRoad = 0;
        this.sheshaMode = 0;
        this.flashFlight = 0;
        return;
      }
    }

      if ((this.validateProvince === 'KwaZulu-Natal' || this.validateProvince === 'kzn') ||
      this.validateProvince === 'Western Cape' || this.validateProvince === 'Gauteng' || this.validateProvince === 'Eastern Cape') {
      const quoteBody = {
        mass: this.totalMass,
        distance: this.distance,
        fromSuburb: this.fromSuburb,
        toSuburb: this.toSuburb,
        pickUpAddress: this.pickUpAddress,
        dropOffAddress: this.dropOffAddress,
        pickUpProvince: this.pickUpProvince,
        dropOffProvince: this.dropOffProvince,
        pickUpPCode: this.pickUpPCode,
        dropOffPCode: this.dropOffPCode,
        parcelDimensions: this.pracels,
        platform: 'web',
        pickUpCoordinate: this.pickUpCoordinate
      };
      // console.log(quoteBody)
      await this.getMainCity();

      localStorage.setItem('quoteBody', JSON.stringify(quoteBody));
      this.bookService.getQuote(quoteBody).subscribe(
        response => {
          this.loadservice.removeloader();
          this.flashFlight = Math.round(response.json().totalFlight);
          this.airlineAmt = response.json().totalFlight;
          this.pickUpAmt = response.json().flashFlightFromPickUp;
          this.dropOffAmt = response.json().flashFlightToDropff;
          this.flashRoad = Math.round(response.json().flashRoad);
          this.sheshaMode = Math.round(response.json().sheshaAmount);
          this.isBike = response.json().isBike;
          this.mainCityOid = response.json().mainCityOid;
          localStorage.setItem('isBike', String(this.isBike));
          localStorage.setItem('mainCityOid', String(this.mainCityOid));


          /*if (this.validateProvince === 'Western Cape' || (this.validateProvince === 'KwaZulu-Natal' || this.validateProvince === 'kzn' || this.validateProvince === 'Eastern Cape')) {
            if (quoteBody.pickUpProvince == this.validateProvince && quoteBody.dropOffProvince == this.validateProvince) {
              // console.log("quote body....", quoteBody);

              // console.log("dash distnce is....", this.maincityDistanceDash);
              // console.log("dash distnce is....", this.maincityDistanceDash);
              if (this.maincityDistanceDash > 50) {
                this.sheshaMode = 0;
                // console.log("dash distnce is....", this.maincityDistanceDash);
              }
            }
          }*/

          /*if (this.flashFlight == 0) {
            this.flightAvailable = false;
            this.flashRoadAvailable = true;
          }*/
          // else {
          //   var d = new Date();
          //   var hours = d.getHours();
          //   if (hours < 12) {
          //     this.flightAvailable = true;
          //     this.flashRoadAvailable = true;
          //   } else {
          //     this.flashFlight = 0;
          //     this.flightAvailable = false;
          //     this.flashRoadAvailable = true;
          //   }
          // }

          // if (this.distance > 30 && this.distance <= 70) {
          //   this.sheshaMode = 150;
          // }

          /*if (this.distance > 70) {
            this.onFlashRoadhSelected();
            this.sheshaMode = 0;
          }
          else {
            this.onDashSelected();
          }

          if (this.totalMass > 7) {
            this.sheshaAvailable;
            this.onFlashRoadhSelected();
            this.sheshaMode = 0;
          }
          else {
            this.onDashSelected();
            this.sheshaAvailable = false;
          }

          if (this.flightAvailable) {
            this.onFlashFlightSelected();
          } else {
            this.onFlashRoadhSelected();
          }*/

          this.loadservice.removeloader();
        }, error => {
          this.loadservice.removeloader();
          console.log(error);
        }
      );
    } else {
      // this.onExpressCourierSelected();
    }
  }

  public getSkynetPrincings() {
    const quoteBody = {
      mass: this.totalMass,
      distance: 1,
      fromSuburb: this.fromSuburb,
      toSuburb: this.toSuburb,
      pickUpAddress: this.pickUpAddress,
      dropOffAddress: this.dropOffAddress,
      pickUpProvince: this.pickUpProvince,
      dropOffProvince: this.dropOffProvince,
      pickUpPCode: this.pickUpPCode,
      dropOffPCode: this.dropOffPCode,
      parcelDimensions: this.pracels,
      platform: 'web',
    };
    console.log(quoteBody);
    this.bookService.getNewSkynetPrices(quoteBody).subscribe(
      response => {
        this.loadservice.removeloader();
        // console.log("===================",response,"=======================")
        if (response.json().expressCourier > 0) {
          this.isCourierExpress = false;
          this.expressCourier = Math.round(response.json().expressCourier);
        }
        if (response.json().expressBudgetAmt > 0) {
          this.isExpressBudget = false;
          this.budgetCourier = Math.round(response.json().expressBudgetAmt);
        }
        if (response.json().tenderDocAmount > 0) {
          this.tenderDocument = Math.round(response.json().tenderDocAmount);
        }
      }, error => {
        this.loadservice.removeloader();
        console.log(error);

      }, () => {
        this.onExpressCourierSelected();
        this.loadservice.removeloader();
      }
    );

  }

  public onDashSelected() {
    // this.controlSelection();
    this.noServiceSelected = false;
    this.resetImages();
    this.transportMode = 'ROAD';
    this.type = 'DASH';
    this.tempAmount = this.sheshaMode;
    /*$(".dash").addClass("expressChoices_");
    $(".dash").removeClass("expressChoices");*/
    this.isDash = true;
    this.tenderDocumentSelected = false;
  }

  public onFlashRoadhSelected() {
    // this.controlSelection();
    this.noServiceSelected = false;
    this.resetImages();
    this.transportMode = 'ROAD';
    this.type = 'FLASH';
    this.tempAmount = this.flashRoad;
    /*$(".road").addClass("expressChoices_");
    $(".road").removeClass("expressChoices");*/
    this.isRoad = true;
    this.tenderDocumentSelected = false;
  }

  public onFlashFlightSelected() {
    // this.controlSelection();
    this.noServiceSelected = false;
    this.resetImages();
    this.transportMode = 'AIR';
    this.type = 'FLASH';
    this.tempAmount = this.flashFlight;
    this.airlineAmt = this.airlineAmt;
    this.pickUpAmt = this.pickUpAmt;
    this.dropOffAmt = this.dropOffAmt;
    /*$(".flight").addClass("expressChoices_");
    $(".flight").removeClass("expressChoices");*/
    this.isFlight = true;
    this.tenderDocumentSelected = false;
  }

  public onExpressCourierSelected() {
    this.noServiceSelected = false;

    /*const quoteBody = {
      mass: this.totalMass,
      distance: 1,
      fromSuburb: this.fromSuburb,
      toSuburb: this.toSuburb,
      pickUpAddress: this.pickUpAddress,
      dropOffAddress: this.dropOffAddress,
      pickUpProvince: this.pickUpProvince,
      dropOffProvince: this.dropOffProvince,
      pickUpPCode: this.pickUpPCode,
      dropOffPCode: this.dropOffPCode,
      parcelDimensions: this.pracels,
      platform: 'web',
    };
    this.bookService.getNewSkynetPrices(quoteBody).subscribe(
      response => {
        this.loadservice.removeloader();
        if (response.json().expressCourier > 0) {
          this.isCourierExpress = false;
          this.expressCourier = Math.round(response.json().expressCourier);
          this.tempAmount = this.expressCourier;
          this.loadservice.removeloader();
        }
      }, error => {
        this.loadservice.removeloader();
      }, () => {
        this.loadservice.removeloader();
      }
    );*/

    // this.controlSelection();
    this.resetImages();
    this.transportMode = 'ROAD';
    this.type = 'EXPRESS_COURIER';
    /*$(".courierExp").addClass("expressChoices_");
    $(".courierExp").removeClass("expressChoices");*/
    this.tempAmount = this.expressCourier;
    this.isCourier = true;
    this.tenderDocumentSelected = false;
  }

  public onBudgetCourierSelected() {
    // this.controlSelection();
    this.noServiceSelected = false;
    this.resetImages();
    this.transportMode = 'ROAD';
    this.type = 'BUDGET_COURIER';
    this.tempAmount = this.budgetCourier;
    /* $(".budget").addClass("expressChoices_");
     $(".budget").removeClass("expressChoices");*/
    this.isBudget = true;
    this.tenderDocumentSelected = false;
  }

  public onTenderDocumentSelected() {
    // this.controlSelection();
    this.noServiceSelected = false;
    this.resetImages();
    this.transportMode = 'ROAD';
    this.type = 'EXPRESS_COURIER';
    this.tempAmount = this.tenderDocument;
    /*$(".tender").addClass("expressChoices_");
    $(".tender").removeClass("expressChoices");*/
    this.isTender = true;
    this.tenderDocumentSelected = true;
  }

  /*private controlSelection() {
    $(".road").addClass("expressChoices").removeClass("expressChoices_");
    $(".flight").addClass("expressChoices").removeClass("expressChoices_");
    $(".courierExp").addClass("expressChoices").removeClass("expressChoices_");
    $(".budget").addClass("expressChoices").removeClass("expressChoices_");
    $(".dash").addClass("expressChoices").removeClass("expressChoices_");
    $(".tender").addClass("expressChoices").removeClass("expressChoices_");
  }*/

  private resetImages() {
    this.isBudget = false;
    this.isCourier = false;
    this.isRoad = false;
    this.isFlight = false;
    this.isDash = false;
    this.isTender = false;
  }

  public getVehicleTypeRates() {
    this.bookService.vehicleTypeRates().subscribe(
      (res) => {
        this.vehicleTypeRate = res;
        this.getExpressVehicleTypeRates(this.vehicleTypeRate);
        this.vehicle = this.vehicleVan;
      }
    );
  }

  public getExpressVehicleTypeRates(vehicleTypes: []) {
    vehicleTypes.forEach(element => {
      this.currentElement = element;
      if (this.currentElement.vehicleType === 'DROPPA_EXPRESS') {
        this.selectedVehicleT = element;
      }
      if (this.currentElement.vehicleType === 'DASH') {
        this.dashVehiceType = element;
      }
    });
  }

  private loginExpress(object: any) {
    if (object.pwd != null) {
      this.bookService.login(object.pwd, object.username).subscribe(
        (response: any) => {
          localStorage.setItem('userData', JSON.stringify(response));
          localStorage.setItem('token', response.token);
          localStorage.setItem('userInfo', JSON.stringify(response));
          localStorage.removeItem('userInfoBookingLogin');
          localStorage.removeItem('userInfoBooking');
          this.router.navigate(['/droppa-express']);
          window.scrollTo(0, 0);
        }
      );
    } else {
      const userdata = JSON.parse(localStorage.getItem('userInfoBooking'));
      localStorage.setItem('userData', JSON.stringify(userdata));
      localStorage.setItem('token', userdata.token);
      localStorage.setItem('userInfo', JSON.stringify(userdata));
      localStorage.removeItem('userInfoBookingLogin');
      localStorage.removeItem('userInfoBooking');
      this.router.navigate(['/droppa-express']);
      window.scrollTo(0, 0);
    }

  }

  public getSavedAddress(token, personId) {
    this.bookService.retrieveAddresses(token, personId).subscribe(
      response => {
        this.savedAddresses = response;
      }, error => {
        console.log(error);
      }, () => {

      }
    );
  }

  public selectPickUpSavedAddress() {
    $('#showPickUpSavedAddressModal').modal('show');
  }

  public selectDropOffSavedAddress() {
    $('#showDropOffSavedAddressModal').modal('show');
  }

  public selectPickUpAddress(selectedAddress) {
    this.disablePickUpAddresses = false;
    // Address used on the logic side
    this.pickUpAddress = selectedAddress.address;
    this.province = selectedAddress.province;
    this.fromSuburb = selectedAddress.suburb;
    this.pickUpPCode = selectedAddress.postalCode;
    // Address used for display
    this.displayPickUpAddress = selectedAddress.address;
    if ((selectedAddress.latitude == 0 || selectedAddress.latitude == null) && (selectedAddress.longitude == 0 || selectedAddress.longitude == null)) {
      this.pickUpCoordinate = ['-25.98953', '28.12843'];
    }
    this.pickUpCoordinateLocation = {coordinates: this.pickUpCoordinate, address: this.pickUpAddress};
    this.usedSavedAddress = true;
  }

  public selectDropOffAddress(selectedAddress) {
    this.disableDropOffAddress = false;
    // Address used on the logic side
    this.dropOffAddress = selectedAddress.address;
    this.province = selectedAddress.province;
    this.toSuburb = selectedAddress.suburb;
    this.dropOffPCode = selectedAddress.postalCode;
    // Address used for display
    this.displayDropOffAddress = selectedAddress.address;
    if ((selectedAddress.latitude == 0 || selectedAddress.latitude == null) && (selectedAddress.longitude == 0 || selectedAddress.longitude == null)) {
      this.dropOffCoordinate = ['-25.97521', '28.13954'];
    }
    this.dropOffCoordinateLocation = {coordinates: this.dropOffCoordinate, address: this.dropOffAddress};
    this.usedSavedAddress = true;
  }

  public confirmBookingCourier() {
    if (this.isDash || this.isRoad || this.isBudget || this.isCourier || this.isTender || this.isFlight) {
      let selectedVT;

      if (this.type == 'DASH') {
        // selectedVT =this.isBike?"MOTO_BIKE":this.dashVehiceType;
        selectedVT = this.dashVehiceType;
      } else {
        selectedVT = this.selectedVehicleT;
      }

      const obect = {
        pickUpAddress: this.pickUpCoordinateLocation,
        dropOffAddress: this.dropOffCoordinateLocation,
        price: this.tempAmount,
        vehicleType: selectedVT,
        province: this.province.toUpperCase(),
        mass: this.totalMass,
        dropOffProvince: this.dropOffProvince,
        transportMode: this.transportMode,
        type: this.type,
        distance: this.distance != null ? this.distance : 1,
        fromSuburb: this.fromSuburb,
        toSuburb: this.toSuburb,
        pickUpProvince: this.pickUpProvince,
        pickUpPCode: this.pickUpPCode,
        dropOffPCode: this.dropOffPCode,
        pickUpAmt: this.pickUpAmt,
        airlineAmt: this.airlineAmt,
        dropOffAmt: this.dropOffAmt,
        parcelDetails: this.pracels,
        tenderSelected: this.tenderDocumentSelected,

      };

      // console.log(obect);

      const d = new Date();
      const hours = d.getHours();

      if (this.type == 'EXPRESS_COURIER' || this.type == 'BUDGET_COURIER') {
        if ((localStorage.getItem('userInfoBookingLogin') != null && localStorage.getItem('userInfoBooking') != null)) {
          localStorage.setItem('addressData', JSON.stringify(obect));
          localStorage.setItem('newDateObj', JSON.stringify(this.dateObj));
          this.loginExpress(JSON.parse(localStorage.getItem('userInfoBookingLogin')));
        } else {
          localStorage.setItem('newDateObj', JSON.stringify(this.dateObj));
          localStorage.setItem('addressData', JSON.stringify(obect));
          this.router.navigate(['/Booking-SignUp']);
        }
      } else {
        // if (hours >= 7 && hours <= 17) {
        if ((localStorage.getItem('userInfoBookingLogin') != null && localStorage.getItem('userInfoBooking') != null)) {
          localStorage.setItem('addressData', JSON.stringify(obect));
          this.loginExpress(JSON.parse(localStorage.getItem('userInfoBookingLogin')));
        } else {
          localStorage.setItem('addressData', JSON.stringify(obect));
          this.router.navigate(['/Booking-SignUp']);
        }
        // } else {
        //   alert("Service only available from 06:00 AM to 18:00 PM. Please select Express or Budget Courier services.");
        //   this.router.navigateByUrl('/Courier-Service');
        // }
      }
    } else {
      this.noServiceSelected = true;
    }

  }

  goToEntry() {
    // this.metricTotal = 0;
    this.resetParcelImages();

    /*this.enteredPickUpAddress = null;
    this.enteredPickUpSuburb = null;
    this.enteredPickUpPostalCode = null;
    this.enteredPickUpProvince = null;
    this.enteredPickUpProvince = null;

    this.enteredDropOffAddress = null;
    this.enteredDropOffSuburb = null;
    this.enteredDropOffPostalCode = null;
    this.enteredDropOffProvince = null;
    this.enteredDropOffProvince = null;*/

    this.priceBox = true;
    this.showParcels = false;
    /*this.expressCourier = 0;
    this.sheshaMode = 0;
    this.flashFlight = 0;
    this.flashRoad = 0;
    this.budgetCourier = 0;*/

    /*this.msgStatus = '';
    this.disableDropOffAddress = true;
    this.disablePickUpAddresses = true;*/

    // this.isCourierExpress = true;

    // this.termsControl = true;
    /*this.sameMassParcels = false;
    this.single_parcel_length = "";
    this.single_parcel_breadth = "";
    this.single_parcel_height = "";
    this.single_parcel_mass = "";*/

    /*this.pracels = [];
    this.numOfParcels = 0;
    this.numberOfPracels = [];*/
    // this.terms = true;
  }

  public enteredAddressInfo() {

    if (this.enteredPickUpAddress == null || this.enteredPickUpAddress == '') {
      this.enteredPickUpAddressMsg = 'Please enter the address';
    } else if (this.enteredPickUpPostalCode == null || this.enteredPickUpPostalCode == '') {
      this.enteredPickUpPostalCodeMsg = 'Please enter the postal code.';
    } else if (this.enteredPickUpSuburb == null || this.enteredPickUpSuburb == '') {
      this.enteredPickUpSuburbMsg = 'Please enter the suburb';
    } else if (this.enteredPickUpProvince == null || this.enteredPickUpProvince == '') {
      this.enteredPickUpProvinceMsg = 'Please enter the province.';
    } else {
      this.fromSuburb = this.enteredPickUpSuburb;
      this.pickUpPCode = this.enteredPickUpPostalCode.trim();
      this.pickUpProvince = this.enteredPickUpProvince;
      this.province = this.enteredPickUpProvince;
      this.pickUpCoordinate = ['-25.98953', '28.12843'];
      this.enteredPickUpSuccessMsg = 'Address successfully captured.';
      this.disablePickUpAddresses = false;
      this.displayPickUpAddress = this.enteredPickUpAddress.concat(', ' + this.fromSuburb).concat(', ' + this.pickUpPCode).
      concat(', ' + this.province.charAt(0).toUpperCase() + this.province.slice(1));
      this.pickUpAddress = this.enteredPickUpAddress.concat(', ' + this.fromSuburb).concat(', ' + this.pickUpPCode).
      concat(', ' + this.province.charAt(0).toUpperCase() + this.province.slice(1));
      this.pickUpCoordinateLocation = {coordinates: this.pickUpCoordinate, address: this.pickUpAddress};
      this.usedSavedAddress = true;
      this.usedGoogleAddressPickUp = false;
    }

  }

  public getPickUpExtraDetails(postalCode) {
    this.pickUpPCode = postalCode;
    if (this.pickUpPCode != null || this.pickUpPCode != undefined) {
      this.bookService.postalForHomeDetails(this.pickUpPCode).subscribe(
        response => {
          this.pickUpExtraDetails = response;
          if (this.pickUpExtraDetails.length == 0) {
            this.enteredPickUpPostalCodeMsg = 'Invalid postal code, enter a valid postal code to select a suburb.';
          } else {
            this.enteredPickUpPostalCodeMsg = '';
          }
        }, error => {
          console.log(error);
        }
      );
    } else {
      this.pickUpExtraDetails = [];
    }
  }

  public validatePostalCode(promptedPickUpPCode) {
    this.pickUpPCode = promptedPickUpPCode;
    if (this.pickUpPCode != null || this.pickUpPCode != undefined) {
      this.bookService.postalForHomeDetails(this.pickUpPCode).subscribe(
        response => {
          this.pickUpExtraDetails = response;
          if (this.pickUpExtraDetails.length == 0) {
            this.enteredPickUpPostalCodeMsg = 'Invalid postal code, enter a valid postal code to select a suburb.';
          } else {
            this.enteredPickUpPostalCodeMsg = '';
          }
        }, error => {
          console.log(error);
        }
      );
    } else {
      this.pickUpExtraDetails = [];
    }
  }

  clickedPickUppCode() {
    this.fromSuburb = this.selectedPickUpSuburb;
    this.pickUpPCode = this.promptedPickUpPCode;
  }

  public enteredAddressInfo2() {

    if (this.enteredDropOffAddress == null || this.enteredDropOffAddress == '') {
      this.enteredDropOffAddressMsg = 'Please enter the address';
    } else if (this.enteredDropOffPostalCode == null || this.enteredDropOffPostalCode == '') {
      this.enteredDropOffPostalCodeMsg = 'Please enter the postal code';
    } else if (this.enteredDropOffSuburb == null || this.enteredDropOffSuburb == '') {
      this.enteredDropOffSuburbMsg = 'Please enter the suburb';
    } else if (this.enteredDropOffProvince == null || this.enteredDropOffProvince == '') {
      this.enteredDropOffProvinceMsg = 'Please enter the province';
    } else {
      this.toSuburb = this.enteredDropOffSuburb;
      this.dropOffPCode = this.enteredDropOffPostalCode.trim();
      this.dropOffProvince = this.enteredDropOffProvince;
      this.dropOffCoordinate = ['-25.97521', '28.13954'];
      this.enteredDropOffSuccessMsg = 'Address successfully captured.';
      this.disableDropOffAddress = false;
      this.displayDropOffAddress = this.enteredDropOffAddress.concat(', ' + this.toSuburb).concat(', ' + this.dropOffPCode).
      concat(', ' + this.dropOffProvince.charAt(0).toUpperCase() + this.dropOffProvince.slice(1));
      this.dropOffAddress = this.enteredDropOffAddress.concat(', ' + this.toSuburb).concat(', ' + this.dropOffPCode).
      concat(', ' + this.enteredDropOffProvince.charAt(0).toUpperCase() + this.enteredDropOffProvince.slice(1));
      this.dropOffCoordinateLocation = {coordinates: this.dropOffCoordinate, address: this.dropOffAddress};
      this.usedSavedAddress = true;
      this.usedGoogleAddressDropOff = false;
    }

  }

  public getDropOffExtraDetails(quote) {
    this.dropOffPCode = quote;
    if (this.dropOffPCode != null || this.dropOffPCode != undefined) {
      this.bookService.postalForHomeDetails(this.dropOffPCode).subscribe(
        response => {
          this.dropOffExtraDetails = response;
          if (this.dropOffExtraDetails.length == 0) {
            this.enteredDropOffPostalCodeMsg = 'Invalid postal code, enter a valid postal code to select a suburb.';
          } else {
            this.enteredDropOffPostalCodeMsg = '';
          }
        }, error => {
          console.log(error);
        }
      );
    } else {
      this.dropOffExtraDetails = [];
    }
  }

  public validateDropOffPostalCode(quote) {
    this.dropOffPCode = quote;
    if (this.dropOffPCode != null || this.dropOffPCode != undefined) {
      this.bookService.postalForHomeDetails(this.dropOffPCode).subscribe(
        response => {
          this.dropOffExtraDetails = response;
          if (this.dropOffExtraDetails.length == 0) {
            this.enteredDropOffPostalCodeMsg = 'Invalid postal code, enter a valid postal code to select a suburb.';
          } else {
            this.enteredDropOffPostalCodeMsg = '';
          }
        }, error => {
          console.log(error);
        }
      );
    } else {
      this.dropOffExtraDetails = [];
    }
  }

  public clickedDropOffpCode() {
    this.toSuburb = this.selectedDropOffSuburb;
    this.dropOffPCode = this.promptedDropOffPCode;
  }

  getBookingDates() {
    this.bookService.getBookingDate(this.pickUpPCode, this.dropOffPCode).subscribe(
      response => {
        this.loadservice.removeloader();
        if (response.pickUpDate != null) {
          this.dateObj = response;
        } else {
          const tate = new Date();
          const datePipe = new DatePipe('en-US');
          tate.setDate(tate.getDate());
          const dateObject = {
            workingDays: [],
            pickUpDate: datePipe.transform(tate, 'yyyy-MM-dd'),
            dropOffSuburb: this.toSuburb,
            pickUpSuburb: this.fromSuburb
          };
          this.dateObj = dateObject;
        }
      }, error => {
        this.loadservice.removeloader();
        const tate = new Date();
        const datePipe = new DatePipe('en-US');
        tate.setDate(tate.getDate());
        const dateObject = {
          workingDays: [],
          pickUpDate: datePipe.transform(tate, 'yyyy-MM-dd'),
          dropOffSuburb: this.toSuburb,
          pickUpSuburb: this.fromSuburb
        };
        this.dateObj = dateObject;
        console.log(error);
      }, () => {
        this.loadservice.removeloader();
      }
    );
  }

  public getCourierQuote() {
    localStorage.removeItem("validAddress");
    this.loadservice.addloader();
    const self = this;
    this.priceBox = false;
    this.showParcels = true;

    const data = {
      destination: this.destinationCoordinate,
      departure: this.departureCoordinate
    };

    this.checkingParcelOverLimit();
    this.calculateParcelDimensions();
    this.calculateMetricVolume();

    // if (this.overload)
    if (false) {
      this.checkingMsg = 'Please check that none of your individual parcel exceeds 70KGs.';
      $('#validationModal').modal('show');
      this.priceBox = true;
      this.showParcels = false;
      this.loadservice.removeloader();
    } else {
      this.getBookingDates();
      this.postMultipleParcels();
      if (this.usedSavedAddress == true) {

        this.priceBox = true;
        self.priceBox = true;
        // this.onExpressCourierSelected();
        this.validateAddress();
        // localStorage.setItem('validAddress', JSON.stringify(this.validAddress));
        this.getSkynetPrincings();
        this.loadservice.removeloader();
        this.flashRoad = 0;
        this.sheshaMode = 0;

      } else {
        this.bookService.getDistance(data).subscribe(
          (response) => {
            const distanceResponse = response.json();
            this.distance = distanceResponse.rows[0].elements[0].distance.inMeters;
            this.distance = Math.round(this.distance / 1000);
            this.bookService.getVehicleRate(this.vehicleType)
              .subscribe(
                (res: Response) => {
                  this.priceBox = true;
                  self.priceBox = true;

                  this.validateAddress();
                  this.getExpressPricings();
                  this.getSkynetPrincings();

                  window.scrollTo(0, 0);
                },
                (err) => {
                  this.errorMessage = 'Error occured, please contact Droppa.';
                  $('#errorDialog').modal('show');
                  this.loadservice.removeloader();
                }
              );
          });
      }
    }

  }

  validAddress:any={fromSuburb:null,pickUpPCode:null,toSuburb:null,dropOffPCode:null};

  validateAddress() {
    if (!(this.usedGoogleAddressPickUp || this.usedGoogleAddressDropOff)) {
      this.validAddress.fromSuburb = this.enteredPickUpSuburb;
      this.validAddress.pickUpPCode = this.enteredPickUpPostalCode.trim();
      this.validAddress.toSuburb = this.enteredDropOffSuburb;
      this.validAddress.dropOffPCode = this.enteredDropOffPostalCode.trim();
      localStorage.setItem('validAddress', JSON.stringify(this.validAddress));
      return;
    }
    this.loadservice.addloader();
    let objBody=JSON.parse(localStorage.getItem("surbCheck"));
    this.bookService.validateAddress(objBody).subscribe(
      response => {
        // let validAddress={};
        let valid=false
        this.loadservice.removeloader();
        const r=response.json();
        // console.log("validated suburb-->",response.json())
        if(r.fromSuburbList&&r.fromSuburbList.length>0){

          this.validAddress.fromSuburb=r.fromSuburbList[r.fromSuburbList.length-1]
          this.validAddress.pickUpPCode=r.pickUpPCodeList[0];
          valid=true
        }
        if(r.toSuburbList&&r.toSuburbList.length>0){

          this.validAddress.toSuburb=r.toSuburbList[r.toSuburbList.length-1];
          this.validAddress.dropOffPCode=r.dropOffPCodeList[0];
          valid=true
        }
        if(valid){
          localStorage.setItem("validAddress",JSON.stringify(this.validAddress))
        }
      }, error => {
        this.loadservice.removeloader();
        console.log(error);

      }, () => {
        this.loadservice.removeloader();
      }
    );
  }

  toPartner() {
    $('#partnerRegistration').modal('show');
  }

  goToPartnerRegistration() {
    const ele = document.getElementsByTagName('input');
    let result = 'success';
    let count = 0;
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].type = 'radio') {
        if (ele[i].checked) {
          count = count + 1;
          if (ele[i].value == 'yes2') {
            result = 'fail';
          }
        }
      }
    }
    if (count >= 6) {
      if (result == 'success') {
        this.router.navigate(['/Become-Droppa']);
      } else if (result == 'fail') {
        $('#criminalResModal').modal('show');
        this.msgStatus = 'You cannot proceed with registration due to criminal record.';
      }
    } else {
      $('#partnerResModal2').modal('show');
      this.msgStatus = 'Please answer all questions to proceed with the registration';
    }
  }

  toRetail() {
    $('#retailRegistration').modal('show');
  }

  goToRetailRagistration() {
    const ele = document.getElementsByTagName('input');
    let result = 'success';
    let count = 0;
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].type = 'radio') {
        if (ele[i].checked) {
          count = count + 1;
          if (ele[i].value == 'no') {
            result = 'fail';
          }
        }
      }
    }
    if (count == 3) {
      // if (result == 'success') {
      //   this.router.navigate(['/Become-Retail']);
      // }
      // else if (result == 'fail') {
      //   $('#retailResModal').modal('show');
      //   this.msgStatus = "All answers must be YES to proceed with the registration";
      // }
      // this.router.navigate(['/Become-Retail']);
    } else {
      $('#retailResModal2').modal('show');
      this.msgStatus = 'Please answer all the questions to proceed with the registration.';
    }
  }

  public userLoggedIn() {
    this.userObject = JSON.parse(localStorage.getItem('userInfoBooking'));
    if (this.userObject == null) {
      this.title = 'Sign In';
    } else {
      this.title = this.userObject.owner.firstName;
      this.getSavedAddress(this.userObject.token, this.userObject.oid);
    }
  }

  public checkedTickBox(terms) {
    this.termsControl = terms;
  }

  public checkedInsurance(noInsurance: boolean) {
    this.noInsuranceControl = noInsurance;
  }

  protected readonly isDevMode = isDevMode;
}

export interface surbInterface {
  fromSuburb:any[],
  toSuburb:any[],
  pickUpPCode:any[],
  dropOffPCode:any[],
}
