import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  prodApiKey: any;
  prodServiceID: any;
  testApiKey: any;
  testServiceID: any;
  serviceId: string;
  user: any;

  constructor(private userService: UserService, private router: Router) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('userInfo'));
    this.serviceId = this.user.owner.oid;
    this.getApiKey(this.serviceId)
    // console.log(this.user);
  }

  public getApiKey(servicEID) {
   
    this.userService.getAPIkEY(this.user.token, this.serviceId)
      .subscribe(
        (response: any) => {
            // console.log (response)
            // this.prodApiKey = response.apiKey;
            // this.prodServiceID = response.serviceId;
            // this.testApiKey =  response.testCredential != null ? response.testCredential.apiKey : 'Not Available';
            // this.testServiceID = response.testCredential != null ? response.testCredential.serviceId : 'Not Available';
          if (response.status == 'ACTIVE') {
            // console.log (response)
            this.prodApiKey = response.apiKey;
            this.prodServiceID = response.serviceId;
            this.testApiKey =  response.testCredential != null ? response.testCredential.apiKey : 'Not Available';
            this.testServiceID = response.testCredential != null ? response.testCredential.serviceId : 'Not Available';
          } else {
            this.router.navigateByUrl('/accont-not-active');
            // alert("Not active");
          }
        },
        (err) => {
        }
      );


  }


}
