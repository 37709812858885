import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';
declare var $: any;

@Component({
  selector: 'app-wallets',
  templateUrl: './wallets.component.html',
  styleUrls: ['./wallets.component.css', '../admin-portal/admin-portal.component.css']
})

export class WalletsComponent implements OnInit {

  adminUser: any;
  transactionDetails: any = [];
  filteredTransactionDetails: any = [];
  overallTotal = 0;
  financeAllowed = false;
  loader: boolean;

  public searchWalletUserField: any;
  public wallerUserFound: any;
  public searchedUserOnWallet: string;
  public messageStatus: string;

  constructor(private adminService: AdminService, private router: Router,private loadservice:LoaderService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    // this.transactions();

    this.adminUser.roles.forEach((role) => {
      if (role.toLowerCase() === 'finance_user') {
        this.financeAllowed = true;
      }
    });
  }

  /*public searchWalletUsers(returnedUserWallet: any) {
    this.adminService.getAllWalletTransactions(this.adminUser.token).subscribe((response: any) => {
      response.findIndex((arrayContents: any) => {
        if (arrayContents.accountDTO !== null) {
          if ((
            this.searchWalletUserField.toLowerCase() === arrayContents.accountDTO.owner.firstName.toLowerCase())
            ||
            this.searchWalletUserField.toLowerCase() === arrayContents.accountDTO.owner.surname.toLowerCase()) {
            this.wallerUserFound = Array.of(arrayContents);
          } else {
            this.wallerUserFound = '';
          }
        }
      });
    });
  }*/

  public transactions() {

    this.loadservice.addloader();
    this.adminService.getAllWalletTransactions(this.adminUser.token).subscribe(
      response => {
        // console.log(response);
        this.loadservice.removeloader();
        this.transactionDetails = response.reverse();
        // The transactionDetails object is used hold all data, while filteredTransactionDetails allows filtering
        this.filteredTransactionDetails = this.transactionDetails;

        this.transactionDetails.forEach((transaction) => {
          this.overallTotal += transaction.amount;
        });

        // console.log(this.filteredTransactionDetails[0]);
      }, error => {
        this.loadservice.removeloader();
        //console.log(error);
      }
    )
  }

  public viewTransactions(transaction: any) {
    localStorage.setItem('walletDetails', JSON.stringify(transaction));
    this.router.navigate(['/user-wallet-details']);
  }

  public showTabByRole(role):boolean{
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let result=false;
    userInfo.roles.map(r=>{
      if(role===r.code){
        result=true;
      }
    })
    return result;
  }

  public filterTable() {
    this.filteredTransactionDetails = this.transactionDetails;
    if (this.searchWalletUserField !== null && this.searchWalletUserField.trim() !== '') {
      this.filteredTransactionDetails = this.filteredTransactionDetails.filter((search) => {
        if (search.accountDTO !== null) {
          return search.accountDTO.owner.firstName.toLowerCase().includes(this.searchWalletUserField.toLowerCase());
        }
      });
    } else {
      this.filteredTransactionDetails = this.transactionDetails;
    }
  }
}
