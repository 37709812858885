import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../services/admin.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { LoaderService } from 'src/app/services/loader.service';
declare var $: any;

@Component({
  selector: 'app-bundle-bookings',
  templateUrl: './bundle-bookings.component.html',
  styleUrls: ['./bundle-bookings.component.css', '../admin-portal/admin-portal.component.css']
})
export class BundleBookingsComponent implements OnInit {

  bookings: any = [];
  booking: any = [];
  companies: any = [];
  admin: any = {};
  loader: boolean = false;
  messege: string;
  feedback: string;
  invoiceDate: any;
  constructor(private adminService: AdminService,
              private router: Router,private loadservice:LoaderService) { }

  ngOnInit() {
   // this.getBundleBooking();
    this.messege = localStorage.getItem('message');
    localStorage.removeItem('message');
    this.getBundleBooking2();
    window.scrollTo(0, 0);
  }

  public getBundleBooking2() {
    this.loadservice.addloader()
    this.admin = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.getBundleBookingByCompanies(this.admin.token).subscribe(
      (res) => {
        this.companies = res;
      }, (error) => {

      },
      ()=>{
        this.loadservice.removeloader()
      }
    );
  }

  public getBundleBooking() {
    this.admin = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.getBundleBookingPayment(this.admin.token).subscribe(
      (res) => {
        this.bookings = res;
        // console.log(this.bookings);
      }, (error) => {
        // console.log(error);
      }
    );
  }

  public makePayment(book) {
    localStorage.setItem('selectedBundleBooking', JSON.stringify(book));
    this.router.navigate(['/bookings-payment']);
  }

  public viewCompany(company) {
    localStorage.setItem('selectedBookingCompany', JSON.stringify(company));
    this.router.navigate(['/bookings-payment']);
  }

  public downloadBookingsForSpreadsheet() {
    this.admin = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.downloadBookingSpreadsheet(this.admin.token).subscribe(
      (response) => {
        var a = document.createElement('a');
        a.href = URL.createObjectURL(response);
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        let todayDate = yyyy + '-' + mm + '-' + dd;
        // document.write(today);
        a.download = 'payment-spreadsheet ' + todayDate + '.xlsx';
        // start download
        a.click();
        this.feedback = 'Download successful.';
        $('#confirm').modal('show');
      }, (error) => {
        console.log(error);
        this.feedback = 'Error downloading spreadsheet.';
        $('#confirm').modal('show');
      }, () => {
        // console.log('Final');
      }
    );
  }

  public runBackgroundPaymentSync() {
    if (this.invoiceDate != null) {
      this.loadservice.removeloader();
      var currentDate = new Date(this.invoiceDate);
      let date = currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate();
      let monthNo = (currentDate.getMonth() + 1) <= 9 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
      let hour = currentDate.getHours() <= 9 ? '0' + currentDate.getHours() : currentDate.getHours();
      let minutes = currentDate.getMinutes() <= 9 ? '0' + currentDate.getMinutes() : currentDate.getMinutes();
      var second = currentDate.getSeconds() <= 9 ? '0' + currentDate.getSeconds() : currentDate.getSeconds();
      var endDate = currentDate.getFullYear() + '-' + monthNo + '-' + date + 'T' + 19 + ':' + minutes + ':' + second;

      var data = {
        "date": endDate
      }
      this.adminService.runPaymentSync(this.admin.token, data).subscribe(
        (res) => {
          console.log(res);
          this.loadservice.removeloader();
          alert("Thank you, Payment proccessed. Monitor console for any error.");
        }, (err) => {
          console.log(err);
          this.loadservice.removeloader();
          // alert("Error occured while processing payment.");
        }
      );
    } else {
      alert("Please select the date.");
    }
  }

  public paymentStatus(book) {
    this.loadservice.addloader();
    this.adminService.updatePayedBucketBookings(this.admin.token, book.booking.oid).subscribe(
      (res) => {
        this.ngOnInit();
        this.loadservice.removeloader();
      }, (error) => {
        this.loadservice.removeloader();
      }
    );
  }

  public confirmDate() {
    $('#processDate').modal('show');
  }

  public standardBankSpreedsheet() {
    if (this.invoiceDate != null) {
      this.loadservice.removeloader();
      var currentDate = new Date(this.invoiceDate);
      let date = currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate();
      let monthNo = (currentDate.getMonth() + 1) <= 9 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
      let hour = currentDate.getHours() <= 9 ? '0' + currentDate.getHours() : currentDate.getHours();
      let minutes = currentDate.getMinutes() <= 9 ? '0' + currentDate.getMinutes() : currentDate.getMinutes();
      var second = currentDate.getSeconds() <= 9 ? '0' + currentDate.getSeconds() : currentDate.getSeconds();
      var endDate = currentDate.getFullYear() + '-' + monthNo + '-' + date + 'T' + 19 + ':' + minutes + ':' + second;

      var data = {
        "date": endDate
      }

      this.adminService.standardBankService(this.admin.token, data).subscribe(
        (res) => {
          console.log(res);
          this.loadservice.removeloader();
          alert("Thank you, Payment proccessed. Monitor console for any error.");
        }, (err) => {
          console.log(err);
          this.loadservice.removeloader();
        }
      );
    } else {
      alert("Please select the date.");
    }
  }
  public showTabByRole(role):boolean{
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let result=false;
    userInfo.roles.map(r=>{
      if(role===r.code){
        result=true;
      }
    })
    return result;
  }

}
