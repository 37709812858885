import {Component, OnInit, isDevMode} from '@angular/core';
import {ContractOwnerService} from "../../services/contract-owner.service";
import {UserInterface} from "../../interfaces/user.interface";
import {LoaderService} from "../../services/loader.service";
import {Router} from "@angular/router";
import {SharedService} from "../../services/shared.service";
import {NgForm} from "@angular/forms";
import {PageEvent} from "angular2-datatable";
import {Subject} from "rxjs/Subject";
import {debounceTime} from "rxjs/operators";

declare var $: any

@Component({
  selector: 'app-droppa-contract-owners',
  templateUrl: './droppa-contract-owners.component.html',
  styleUrls: ['./droppa-contract-owners.component.css', '../admin-portal/admin-portal.component.css']
})
export class DroppaContractOwnersComponent implements OnInit {
  routesList: any;
  selectedRoute: any;
  allDriverOwnerVehicle: any;
  parcelDate: any;
  vehicleRegistration: any;
  deliveries: any;
  collections: any;
  deliveryManifestNumber: any;
  collectionsManifestNumber: any;

  // rate structures
  rateStructures = [];
  rateStructuresPage = 0;
  rateStructuresSize= 10;
  rateStructuresName= "";
  rateStructuresFixedAmnt= null;
  totalNumberOfRateStructures = 0;

  // contract owners
  contractOwners = [];
  contractOwnersPage = 0;
  contractOwnersSize= 10;
  contractOwnersName= "";
  totalNumberOfContractOwners = 0;
  contractOwnersCompanyName= "";
  contractOwnersEmail= "";
  contractOwnersSearch: "";

  exportAnythingError:boolean=false;
  errorText : string = 'Please select a valid date range.';

  pageSizeOptions: number[] = [10, 25, 50];


  constructor(private ownerService: ContractOwnerService, private loader: LoaderService, private route: Router, private sharedService: SharedService) {
  }

  errorMsg = ""
  user: UserInterface
  itemsPage = 25;
  totalItems = 25;

  remittence = []
  remittenceFromDate: any
  remittenceToDate: any
  remittenceTable = false

  contractOwnerTab = true
  rateStructureTab = false
  branchesTab = false;
  selectedStructure
  fuelSurcharge
  isDevMode = isDevMode()
  isSaveAccount = false
  isDriver = false
  addingDriverHasError = false;
  addingDriverErrorMessage = "";
  isFiance: boolean;
  searchValue: any;
  searchType: any;
  showRoutes: boolean = false;
  allBranches: any;
  branchCode: any;
  branchProvince: any;

  searchUpdateContractOwners = new Subject<string>();


  ngOnInit() {
    this.searchUpdateContractOwners.pipe(debounceTime(300)).subscribe(value => {
      this.getContractOwners(1);
    });
    this.user = JSON.parse(localStorage.getItem("userInfo"));
    this.isFiance = this.checkIfUserIsFinance(this.user);
    this.getContractOwners(1);
    this.getRateStructures(1)
    this.getFuelCharge();
    this.getBranches();

  }

  showExportAnythingError() {
    this.exportAnythingError = true;
    setTimeout(() => {
      this.exportAnythingError = false;
    }, 2500);
  }



  onSearchChangeContractOwners(searchValue: string) {
    this.searchUpdateContractOwners.next(searchValue);
  }

  public getContractOwners(retrieveType:number) {
    const token = this.user.token

    console.log(this.contractOwnersSearch);
    if (retrieveType === 1) {
      this.contractOwnersPage = 0;
      this.contractOwnersSize = 10;
    }
    else
      this.loader.addloader();

    const filter = {
      "pageNumber": this.contractOwnersPage,
      "pageTotalItems": this.contractOwnersSize,
      "filters": {
        "search": this.contractOwnersSearch
      }
    }
    this.ownerService.getContractOwners(filter,token).subscribe(
      (res) => {
        this.contractOwners = res.content
        this.totalNumberOfContractOwners = res.totalElement
      },
      (error) => {
        this.loader.removeloader()
        console.log(error)
      },
      () => {
        this.loader.removeloader()
      }
    )
  }

  public submitContractForm(form) {
    const contractOwnerObj = form.value
    contractOwnerObj.licenceIssueDate = contractOwnerObj.licenceIssueDate + 'T' + '00' + ':' + '00' + ':' + '00';
    //console.log(contractOwnerObj)
    const token = this.user.token
    this.ownerService.addContractOwner(token, contractOwnerObj).subscribe(
      (res) => {
        this.contractOwners.push(res)
        form.resetForm();
        this.addingDriverHasError = false;
        this.addingDriverErrorMessage = "";
        console.log(res)
      },
      (error) => {

        this.addingDriverHasError = true;
        this.addingDriverErrorMessage = error.error.message;
        this.loader.removeloader();
        console.log("aybo");
        console.log(error);
        console.log("aybo");
      },
      () => {
        this.loader.removeloader();
      }
    )
  }

  public selectOwner(owner) {
    this.route.navigate([`detailed-contract-owner/${owner.oid}`])
  }

  public genareteRemittance() {
    this.errorMsg = ""
    if (!this.remittenceFromDate) {
      this.showExportAnythingError();
      return
    }
    if (!this.remittenceToDate) {
      this.showExportAnythingError();
      return;
    }
    var fromDate = new Date(this.remittenceFromDate);
    let fromdate = fromDate.getDate() <= 9 ? '0' + fromDate.getDate() : fromDate.getDate();
    let frommonthNo = (fromDate.getMonth() + 1) <= 9 ? '0' + (fromDate.getMonth() + 1) : (fromDate.getMonth() + 1);

    var toDate = new Date(this.remittenceToDate);
    let todate = toDate.getDate() <= 9 ? '0' + toDate.getDate() : toDate.getDate();
    let tomonthNo = (toDate.getMonth() + 1) <= 9 ? '0' + (toDate.getMonth() + 1) : (toDate.getMonth() + 1);

    var fDate = fromDate.getFullYear() + '-' + frommonthNo + '-' + fromdate + 'T' + '00' + ':' + '00' + ':' + '00';
    var tDate = toDate.getFullYear() + '-' + tomonthNo + '-' + todate + 'T' + '00' + ':' + '00' + ':' + '00';
    const filter = {
      startDate: fDate,
      endDate: tDate
    }
    this.loader.addloader();
    this.ownerService.getRemittence(this.user.token, filter)
      .subscribe(res => {
        this.remittenceTable = true;
        this.loader.removeloader()
        this.remittence = res
        console.log(res)
      }, (error) => {
        this.loader.removeloader()
        this.errorMsg = error.message
        console.log(error)
      }, () => {
        this.loader.removeloader()
      })

  }

  public sendRemittence() {
    this.errorMsg = ""
    if (!this.remittenceFromDate) {
      this.showExportAnythingError();
      return
    }
    if (!this.remittenceToDate) {
      this.showExportAnythingError();
      return;
    }
    var fromDate = new Date(this.remittenceFromDate);
    let fromdate = fromDate.getDate() <= 9 ? '0' + fromDate.getDate() : fromDate.getDate();
    let frommonthNo = (fromDate.getMonth() + 1) <= 9 ? '0' + (fromDate.getMonth() + 1) : (fromDate.getMonth() + 1);

    var toDate = new Date(this.remittenceToDate);
    let todate = toDate.getDate() <= 9 ? '0' + toDate.getDate() : toDate.getDate();
    let tomonthNo = (toDate.getMonth() + 1) <= 9 ? '0' + (toDate.getMonth() + 1) : (toDate.getMonth() + 1);

    var fDate = fromDate.getFullYear() + '-' + frommonthNo + '-' + fromdate + 'T' + '00' + ':' + '00' + ':' + '00';
    var tDate = toDate.getFullYear() + '-' + tomonthNo + '-' + todate + 'T' + '00' + ':' + '00' + ':' + '00';
    const filter = {
      startDate: fDate,
      endDate: tDate
    }
    this.loader.addloader();
    this.ownerService.sendRemittence(this.user.token, filter)
      .subscribe(res => {
        this.remittenceTable = false;
        this.loader.removeloader()
        this.remittence = []
        console.log(res)
      }, (error) => {
        this.loader.removeloader()
        this.errorMsg = error.message
        console.log(error)
      }, () => {
        this.loader.removeloader()
      })

  }

  public downloadSkynetBilling() {
    this.errorMsg = ""
    if (!this.remittenceFromDate) {
      this.showExportAnythingError();
      return
    }
    if (!this.remittenceToDate) {
      this.showExportAnythingError();
      return;
    }
    var fromDate = new Date(this.remittenceFromDate);
    let fromdate = fromDate.getDate() <= 9 ? '0' + fromDate.getDate() : fromDate.getDate();
    let frommonthNo = (fromDate.getMonth() + 1) <= 9 ? '0' + (fromDate.getMonth() + 1) : (fromDate.getMonth() + 1);

    var toDate = new Date(this.remittenceToDate);
    let todate = toDate.getDate() <= 9 ? '0' + toDate.getDate() : toDate.getDate();
    let tomonthNo = (toDate.getMonth() + 1) <= 9 ? '0' + (toDate.getMonth() + 1) : (toDate.getMonth() + 1);

    var fDate = fromDate.getFullYear() + '-' + frommonthNo + '-' + fromdate + 'T' + '00' + ':' + '00' + ':' + '00';
    var tDate = toDate.getFullYear() + '-' + tomonthNo + '-' + todate + 'T' + '00' + ':' + '00' + ':' + '00';
    const filter = {
      startDate: fDate,
      endDate: tDate
    };
    console.log(filter);

    this.loader.addloader();
    this.ownerService.downloadSkyNetRouteBilling(this.user.token, filter)
      .subscribe(res => {
        this.loader.removeloader();
        console.log(res);
        this.downloadExcel(res.downloadPdfURL, 'skynetBilling');
      }, (error) => {
        this.loader.removeloader();
        this.errorMsg = error.message;
        console.log(error);
      }, () => {
        this.loader.removeloader();
      });
  }

  public downloadSkynetBillingSummary() {
    this.errorMsg = ""
    if (!this.remittenceFromDate) {
      this.showExportAnythingError();
      return
    }
    if (!this.remittenceToDate) {
      this.showExportAnythingError();
      return;
    }
    var fromDate = new Date(this.remittenceFromDate);
    let fromdate = fromDate.getDate() <= 9 ? '0' + fromDate.getDate() : fromDate.getDate();
    let frommonthNo = (fromDate.getMonth() + 1) <= 9 ? '0' + (fromDate.getMonth() + 1) : (fromDate.getMonth() + 1);

    var toDate = new Date(this.remittenceToDate);
    let todate = toDate.getDate() <= 9 ? '0' + toDate.getDate() : toDate.getDate();
    let tomonthNo = (toDate.getMonth() + 1) <= 9 ? '0' + (toDate.getMonth() + 1) : (toDate.getMonth() + 1);

    var fDate = fromDate.getFullYear() + '-' + frommonthNo + '-' + fromdate + 'T' + '00' + ':' + '00' + ':' + '00';
    var tDate = toDate.getFullYear() + '-' + tomonthNo + '-' + todate + 'T' + '00' + ':' + '00' + ':' + '00';
    const filter = {
      startDate: fDate,
      endDate: tDate
    };
    console.log(filter);

    this.loader.addloader();
    this.ownerService.downloadBillingSummary(this.user.token, filter)
      .subscribe(res => {
        this.loader.removeloader();
        console.log(res);
        const now = new Date();
        const formattedDate = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}T${now.getHours().toString().padStart(2, '0')}_${now.getMinutes().toString().padStart(2, '0')}`;
        const dynamicFileName = `BILLING-SUMMARY-${formattedDate}`;
        this.downloadExcel(res.downloadPdfURL, dynamicFileName);
      }, (error) => {
        this.loader.removeloader();
        this.errorMsg = error.message;
        console.log(error);
      }, () => {
        this.loader.removeloader();
      });
  }

  downloadExcel(base64String: string, fileName: string) {
    // Decode base64 string to binary data
    const binaryString = window.atob(base64String);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);

    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }

    // Create a Blob with the Excel MIME type
    const blob = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Create a link element
    const link = document.createElement('a');
    const url = window.URL.createObjectURL(blob);
    link.href = url;
    link.download = fileName;

    // Append the link to the body
    document.body.appendChild(link);

    // Trigger the download by simulating a click
    link.click();

    // Clean up and remove the link
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  }

  public downloadLink(fileId) {
    const url = this.sharedService.getUrl() + "owner/skynet/billing/download/" + fileId
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    URL.revokeObjectURL(url);
  }

  public contractOwnerSwitch() {
    this.contractOwnerTab = true;
    this.rateStructureTab = false;
    this.branchesTab = false;
  }

  public rateStructureSwitch() {
    this.contractOwnerTab = false;
    this.rateStructureTab = true;
    this.branchesTab = false;
  }

  branchesSwitch() {
    this.branchesTab = true;
    this.contractOwnerTab = false;
    this.rateStructureTab = false;
    this.getBranches();
  }


  public submitRateStructureForm(form) {
    this.errorMsg = ""
    if (form.status === 'INVALID') {
      this.errorMsg = "Please enter all the fields."
      return;
    }
    const rateStructure = form.value
    this.loader.addloader();
    this.ownerService.createRateStructure(this.user.token, rateStructure)
      .subscribe(res => {
        this.rateStructures.push(res)
        form.resetForm()
        $("#addRateStructureModal").modal('hide')
      }, (error) => {
        this.loader.removeloader()
        this.errorMsg = error.message
        console.log(error)
      }, () => {
        this.loader.removeloader()
      })
  }

  public deleteStructureModal(structure) {
    $("#deleteRateStructureModal").modal("show")
    this.selectedStructure = structure
  }

  public deleteStructure() {
    this.loader.addloader();
    this.ownerService.removeRateStructures(this.user.token, this.selectedStructure.oid)
      .subscribe(res => {
        this.getRateStructures(1)
        $("#deleteRateStructureModal").modal('hide');
      }, (error) => {
        this.loader.removeloader()
        this.errorMsg = error.message
        console.log(error)
      }, () => {
        this.loader.removeloader();
      })
  }

  public getFuelCharge() {
    this.loader.addloader();
    this.ownerService.getFuelCharge(this.user.token)
      .subscribe(res => {
        this.fuelSurcharge = res
      }, (error) => {
        this.loader.removeloader()
        this.errorMsg = error.message
        console.log(error)
      }, () => {
        this.loader.removeloader()
      })
  }

  public addfuelSurchargeForm(form: NgForm) {
    this.loader.addloader();
    this.ownerService.createFuelCharge(this.user.token, form.value)
      .subscribe(res => {
        this.fuelSurcharge = res
        form.resetForm()
        $("#fuelSurChargeModal").modal("hide")
      }, (error) => {
        this.loader.removeloader()
        this.errorMsg = error.message
        console.log(error)
      }, () => {
        this.loader.removeloader()
      })
  }

  searchForRoute() {
    this.loader.addloader();
    const obj = {
      page: 0,
      offset: 10,
      searchType: this.searchType,
      searchValue: this.searchValue
    };
    this.ownerService.getRouteByFilter(this.user.token, obj).subscribe(
      response => {
        this.routesList = response.content;
        console.log(this.routesList);
        this.showRoutes = true;
      }, (error) => {
        this.showRoutes = true;
        this.loader.removeloader();
        this.errorMsg = error.message;
      }, () => {
        this.loader.removeloader();
      }
    );

  }

  showDrivers() {
    this.showRoutes = false;
  }

  findRouteByRegistrationNumber(registrationNumber: string): any {
    for (const v of this.allDriverOwnerVehicle) {
      if (registrationNumber.toUpperCase() === v.registrationNumber.toUpperCase()) {
        return v;
      }
    }
    return null;
  }

  addParcelToRoute(route: any) {
    this.selectedRoute = route;
    this.loader.addloader();

    this.ownerService.getOwnerVehicle(this.user.token, route.ownerId).subscribe(
      (response) => {
        this.allDriverOwnerVehicle = response;
        if (route.ownerVehicle) {
          if (route.ownerVehicle.registrationNumber) {
            this.vehicleRegistration = this.findRouteByRegistrationNumber(route.ownerVehicle.registrationNumber);
          }
        }
        $('#addParcelToRoute').modal('show');
      },
      (error) => {
        this.errorMsg = error.message;
        this.loader.removeloader();
      },
      () => this.loader.removeloader()
    );
  }


  addParcel() {
    const obj = {
      waybillDate: this.parcelDate,
      branch: this.selectedRoute.branch,
      route: this.selectedRoute.name,
      vehicleRegistration: this.vehicleRegistration.registrationNumber,
      delivered: this.deliveries,
      collections: this.collections,
      deliveryManifestNumber: this.deliveryManifestNumber,
      collectionsManifestNumber: this.collectionsManifestNumber,
      warehouseCollections: 0,
      returns: 0,
      type: 'PERMANENT'
    };
    obj.waybillDate = this.parcelDate + 'T' + '00' + ':' + '00' + ':' + '00';
    console.log(obj);
    this.errorMsg = '';
    this.ownerService.addParcel(this.user.token, this.selectedRoute.ownerId, obj).subscribe(
      (response) => {
        $('#addParcelToRoute').modal('hide');
      }, (error) => {
        this.loader.removeloader();
        this.errorMsg = error.message;
      }, () => {
        this.loader.removeloader();

      }
    );
  }


  addBranch() {
    if (this.branchProvince !== null && this.branchCode !== null) {

      const createBranch = {
        province: this.branchProvince,
        code: this.branchCode
      };

      this.loader.addloader();

      this.ownerService.addBranch(this.user.token, createBranch).subscribe(
        (response) => {
          this.getBranches();
        }, (error) => {
          this.errorMsg = error.message;
      },
      () => this.loader.removeloader()
    );
    }
  }

  getBranches() {

    this.loader.addloader();
    this.ownerService.getAllBranches(this.user.token).subscribe(
      (response) => {
              this.allBranches = response;
              console.log(response);
      }, (error) => {
        console.log(error);
        this.errorMsg = error.message;
        this.loader.removeloader();
      }, () => this.loader.removeloader()
  );
  }

  private checkIfUserIsFinance(user: any): boolean {
    for (const role of user.roles) {
      if (role.code === 'finance_user') {
        return true;
      }
    }
    return false;
  }


  getNewContractOwners(pageEvent?: any) {
    this.contractOwnersPage = pageEvent.pageIndex;
    this.contractOwnersSize = pageEvent.pageSize;
    this.getContractOwners(0);
  }

  getNewRateStructureData(pageEvent?: any) {
      this.rateStructuresPage = pageEvent.pageIndex;
      this.rateStructuresSize = pageEvent.pageSize;
      this.getRateStructures(0);
  }



  getRateStructures(retrieveType: number) {
    this.errorMsg = "";
    this.loader.addloader();

    if (retrieveType === 1) {
      this.rateStructuresPage = 0;
      this.rateStructuresSize = 10;
    }

    const filter = {
      "pageNumber": this.rateStructuresPage,
      "pageTotalItems": this.rateStructuresSize,
      "filters": {
        "name": this.rateStructuresName,
        "hasFixedAmount": this.rateStructuresFixedAmnt
      }
    }

    console.log(filter)

    this.ownerService.getRateStructuresFiltered(filter,this.user.token)
      .subscribe(res => {
        console.log(res)
        this.totalNumberOfRateStructures = res.totalElement;
        console.log(this.totalNumberOfRateStructures+" total num rate structures........................................................................")
        this.rateStructures = res.content
      }, (error) => {
        this.loader.removeloader()
        this.errorMsg = error.message
        console.log(error)
      }, () => {
        this.loader.removeloader()
      })
  }
}

