import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { Subscription } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';

declare var $: any;
export let browserRefresh = false;

@Component({
  selector: 'app-view-outstanding-retail-booking-invoices',
  templateUrl: './view-outstanding-retail-booking-invoices.component.html',
  styleUrls: ['./view-outstanding-retail-booking-invoices.component.css',  '../admin-portal/admin-portal.component.css']
})
export class ViewOutstandingRetailBookingInvoicesComponent implements OnInit {

  retailObj: any = {};
  bookings: any = [];
  bucket: any = [];
  rentals: any = [];
  dates: any = {};
  admin: any = {};
  assignStatus: string = '';
  loader: boolean = false;
  subscription: Subscription;
  showBooking: boolean = false;
  showRentals: boolean = false;
  showBucket: boolean = false;
  setDates: any;

  constructor(private adminService: AdminService, private router: Router) {
    this.subscription = router.events.subscribe((event) => {
      console.log(event);

      if (event instanceof NavigationStart) {
        console.log(event);

        browserRefresh = !router.navigated;
      }
    });
   }


  ngOnInit() {
    this.subscription = this.adminService.currentRetailCompany.subscribe(booking => this.retailObj = booking);
    this.dates = JSON.parse(localStorage.getItem('selectedRangeDates'));
    this.admin = JSON.parse(localStorage.getItem('userInfo'));

    this.checkValues();
  }

  public checkValues(){

    if (this.retailObj.bookings !== null){
      if(this.retailObj.bookings.length > 0){
        this.bookings = this.retailObj.bookings;
        this.showBooking = true;
      }
    }

    if (this.retailObj.rentals !== null){
      if(this.retailObj.rentals.length > 0){
        this.rentals = this.retailObj.rentals;
        this.showRentals = true;
      }
    }

    if (this.retailObj.bucketBookings !== null){
      if(this.retailObj.bucketBookings.length > 0){
        this.bucket = this.retailObj.bucketBookings;
        this.showBucket = true;
      }
    }

  }

  public sendInvoiceByDate() {
    var retailDate = {
      'retailOid': this.retailObj.retailDTO.oid,
      'startDate': this.dates.startDate,
      'endDate': this.dates.endDate
    };

    this.adminService.sendRetailInvoiceByDate(this.admin.token, retailDate).subscribe(
      (response: Response) => {
        this.assignStatus = 'Invoice successfully sent.';
        $('#invoiceDateSent').modal('show');
      }, (error) => {
        console.log(error);
        this.assignStatus = 'Error';
        $('#invoiceDateSent').modal('show');
      }, () => {

      }
    );
  }

  ngOnDestroy() {
    console.log("left view outsanding view 2");
    localStorage.setItem('viewInovices', "false");
    this.subscription.unsubscribe();
  }

  @HostListener("window:unload", ["$event"]) unloadHandler(event: Event) {
    console.log("Processing beforeunload...");
    localStorage.setItem('selectedBookingRetail', JSON.stringify(this.retailObj));
    // this.subscription = this.adminService.currentRetailCompany.subscribe(booking => this.retailObj = booking);
    const testLocalSaveReload = JSON.parse(localStorage.getItem('selectedBookingRetail'));
    this.adminService.changeRetailer(testLocalSaveReload);
    // this.processData();
  }
  public showTabByRole(role):boolean{
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let result=false;
    userInfo.roles.map(r=>{
      if(role===r.code){
        result=true;
      }
    })
    return result;
  }

}
