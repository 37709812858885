import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { Router } from '@angular/router';

import { UserService } from '../../services/user.service';
import { AdminService } from '../../services/admin.service';
import { LoaderService } from 'src/app/services/loader.service';
@Component({
  selector: 'app-driver-owner-financials',
  templateUrl: './driver-owner-financials.component.html',
  styleUrls: ['./driver-owner-financials.component.css']
})
export class DriverOwnerFinancialsComponent implements OnInit {

  constructor(private userService: UserService, private nameService: SharedService, private route: Router, private adminService: AdminService,private loadservice:LoaderService) { }
  itemsPage: number = 1;
  totalItems: number;
  user: any;
  vehicle: any;
  company:any;
  filter:any;
  transactionsummary:any;
  startDate
  viewheading="Vehicle Freight Transaction Summary"
  endDate
  rates
  selecterate
  loader=false
  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('userInfo'));
    this.vehicle = JSON.parse(localStorage.getItem('vehicle'));
    this.company = JSON.parse(localStorage.getItem('company'));
    this.filter = JSON.parse(localStorage.getItem('filter'));
    this.transactionsummary = JSON.parse(localStorage.getItem('transactionsummary'));
    this.button();
    this.getRates();

    console.log(this.transactionsummary);

  }

  button() {
    var header = document.getElementById("myTab");
    var btns = header.getElementsByClassName("tabs");
    for (var i = 0; i < btns.length; i++) {
      btns[i].addEventListener("click", function () {
        var current = document.getElementsByClassName("activee");
        current[0].className = current[0].className.replace(" activee", "");
        this.className += " activee";
      });
    }
  }
  selectRate(rate){
    this.selecterate=rate.tierRates
  }
  public getRates(){
    this.loadservice.addloader()
    this.userService.getRatesVehicle(this.user.token, this.vehicle.oid)
    .subscribe(
      (res: Response) => {
        this.rates=res.json()
        this.loadservice.removeloader()
      },
      (err) => {
        console.log(err)
        this.loadservice.removeloader()
      }
    );
  }
  public changeTitle(title){
    this.viewheading=title
  }
  public openUpdateModal(){
    // $('#updateDetails').modal('hide');

  }

}
