import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LoaderService } from 'src/app/services/loader.service';

declare var $: any;

@Component({
  selector: 'app-outstanding-retail-invoices',
  templateUrl: './outstanding-retail-invoices.component.html',
  styleUrls: ['./outstanding-retail-invoices.component.css',  '../admin-portal/admin-portal.component.css']
})
export class OutstandingRetailInvoicesComponent implements OnInit {

  admin: any;
  invoiceRetailStartDate: any;
  invoiceRetailEndDate: any;
  loader: boolean;
  outstandingBookings: any = [];
  outstandingBucket: any = [];
  outstandingRetailerInfo: any = [];
  dumpsDate: any = {};
  private foundBookings = new BehaviorSubject([]);
  currentDate = this.foundBookings.asObservable();
  subscription: Subscription;
  setDates:any;

  retailObj: any = {};
  assignStatus: string = '';

  constructor(private adminService: AdminService, private router: Router,private loadservice:LoaderService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.subscription = this.adminService.currentRetailCompany.subscribe(booking => this.retailObj = booking);
    this.admin = JSON.parse(localStorage.getItem('userInfo'));
    this.setDates = JSON.parse(localStorage.getItem('selectedRangeDates'));

    const storedData = sessionStorage.getItem('invoiceData');
    const isSorted = sessionStorage.getItem('isSorted');
    const isDate = sessionStorage.getItem('dumpsDate');

    if (storedData && isSorted === 'true') {
      console.log('isDate', isDate);
      this.outstandingRetailerInfo = JSON.parse(storedData);
      const dumpsDate = JSON.parse(isDate);
      this.invoiceRetailStartDate = dumpsDate.startDate;
      this.invoiceRetailEndDate = dumpsDate.endDate;
    } else {
      this.invoiceOutstandingRetails();
    }

  }

  public invoiceOutstandingRetails() {
    var currentDate = new Date(this.invoiceRetailStartDate);
    let date = currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate();
    let monthNo = (currentDate.getMonth() + 1) <= 9 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
    var dropTime = currentDate.getFullYear() + '-' + monthNo + '-' + date + 'T' + '00' + ':' + '00' + ':' + '00';
    this.invoiceRetailStartDate = dropTime;

    var currentDate2 = new Date(this.invoiceRetailEndDate);
    let date2 = currentDate2.getDate() <= 9 ? '0' + currentDate2.getDate() : currentDate2.getDate();
    let monthNo2 = (currentDate2.getMonth() + 1) <= 9 ? '0' + (currentDate2.getMonth() + 1) : (currentDate2.getMonth() + 1);
    var dropTime2 = currentDate2.getFullYear() + '-' + monthNo2 + '-' + date2 + 'T' + '23' + ':' + '59' + ':' + '59';
    this.invoiceRetailEndDate = dropTime2;

    this.dumpsDate = {
      'startDate': this.invoiceRetailStartDate,
      'endDate': this.invoiceRetailEndDate
    };

    this.loadservice.addloader();
    this.adminService.outstandingRetailInvoices(this.admin.token, this.dumpsDate).subscribe(
      response => {

        this.outstandingRetailerInfo = response.json();
        console.log(response.json());

        sessionStorage.setItem('invoiceData', JSON.stringify(this.outstandingRetailerInfo));
        sessionStorage.setItem('isSorted', 'true');
        sessionStorage.setItem('dumpsDate', JSON.stringify(this.dumpsDate));

        this.outstandingRetailerInfo.forEach(element => {
          if (element.bookings != null && element.bookings.length > 0) {
            this.outstandingBookings = element.bookings;
          }
          else {
            //console.log('has no bookings');
          }

          if (element.bucketBookings != null && element.bucketBookings.length > 0) {
            this.outstandingBucket = element.bucketBookings;
          }
          else {
            //console.log('has no bucket bookings');
          }

        });
        this.loadservice.removeloader();
      }, error => {
        this.loadservice.removeloader();
        console.log(error);
      }, () => {

      }
    );
  }



  public viewBookings(company) {
    console.log(company);
    console.log(this.dumpsDate);
    // this..next(this.outstandingBookings);
    this.subscription = this.adminService.currentRetailCompany.subscribe(company => company = company)
    this.adminService.changeRetailer(company);
    localStorage.setItem('selectedRangeDates', JSON.stringify(this.dumpsDate));
    localStorage.setItem('selectedBookingRetail', JSON.stringify(company));
    localStorage.setItem('viewInovices', "true");
    this.router.navigate(['/view-outstanding-retail-invoices']);
  }

  public sendInvoiceByDate(company:any) {
    var currentDate = new Date(this.invoiceRetailStartDate);
    let date = currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate();
    let monthNo = (currentDate.getMonth() + 1) <= 9 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
    var dropTime = currentDate.getFullYear() + '-' + monthNo + '-' + date + 'T' + '00' + ':' + '00' + ':' + '00';
    this.invoiceRetailStartDate = dropTime;

    var currentDate2 = new Date(this.invoiceRetailEndDate);
    let date2 = currentDate2.getDate() <= 9 ? '0' + currentDate2.getDate() : currentDate2.getDate();
    let monthNo2 = (currentDate2.getMonth() + 1) <= 9 ? '0' + (currentDate2.getMonth() + 1) : (currentDate2.getMonth() + 1);
    var dropTime2 = currentDate2.getFullYear() + '-' + monthNo2 + '-' + date2 + 'T' + '23' + ':' + '59' + ':' + '59';
    this.invoiceRetailEndDate = dropTime2;


    var retailDate = {
      'retailOid': company.retailDTO.oid,
      'startDate': this.invoiceRetailStartDate,
      'endDate': this.invoiceRetailEndDate
    };

    console.log(retailDate);

    this.adminService.sendRetailInvoiceByDate(this.admin.token, retailDate).subscribe(
      (response: Response) => {
        this.assignStatus = 'Invoice successfully sent.';
        $('#invoiceDateSent').modal('show');
      }, (error) => {
        console.log(error);
        this.assignStatus = 'Error';
        $('#invoiceDateSent').modal('show');
      }, () => {

      }
    );
  }

  ngOnDestroy() {
    const wasViewed = localStorage.getItem('viewInovices')
    console.log(wasViewed);

    if(wasViewed == "false"){
      localStorage.removeItem("selectedRangeDates")
    }

    console.log("left view outsanding view");
  }
  public showTabByRole(role):boolean{
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let result=false;
    userInfo.roles.map(r=>{
      if(role===r.code){
        result=true;
      }
    })
    return result;
  }


}
