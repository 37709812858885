import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-view-rental-details',
  templateUrl: './view-rental-details.component.html',
  styleUrls: ['./view-rental-details.component.css']
})
export class ViewRentalDetailsComponent implements OnInit {

  dateStart: any;
  dateEnd: any;
  userObj: any;
  rentalObj: any;
  branchName: any;
  total: any;
  VAT: any;
  subTotal: any;
  numDays: any;
  loader: boolean = false;
  userName: any;
  endDate: any;

  constructor() { }

  ngOnInit() {
    this.rentalObj = JSON.parse(localStorage.getItem('selectedRental'));
    this.userObj = JSON.parse(localStorage.getItem('userInfo'));
    this.dateStart = moment(this.rentalObj.in).format('dddd, MMMM Do YYYY');
    this.endDate = moment(this.rentalObj.out).format('dddd, MMMM Do YYYY');
    var pickUpDate = new Date(this.rentalObj.in);
    var dropOffDate = new Date(this.rentalObj.out);
    this.numDays = moment(dropOffDate).diff(moment(pickUpDate), 'days');
    if (this.numDays == 0) {
      this.numDays = this.numDays + 1;
    }
    this.userName = this.rentalObj.customer.firstName + " " + this.rentalObj.customer.surname;
  }

}
