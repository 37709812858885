import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { Http, Response } from '@angular/http';
import { from } from 'rxjs';
import { NgForm } from '@angular/forms';
import { LoaderService } from 'src/app/services/loader.service';


@Component({
  selector: 'app-api-developers-page',
  templateUrl: './api-developers-page.component.html',
  styleUrls: ['./api-developers-page.component.css', '../admin-portal/admin-portal.component.css']
})
export class APIDevelopersPageComponent implements OnInit {

  apiUsers: any = [];
  adminUser: any;
  loader: boolean;

  searchQuery;
  findUser: any;
  userData: any;


  constructor(private adminService: AdminService, private service: UserService, private route: Router,private loadservice:LoaderService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    this.getUsers(this.adminUser.token);
  }

  onUserSelected(user) {
    localStorage.setItem('selectedUser', JSON.stringify(user));
    this.route.navigate(['/view-ApiUser']);
  }


  private getUsers(token) {
    this.loadservice.addloader();
    this.adminService.getAPIUsers(token)
      .subscribe(
        (res) => {
          this.apiUsers = res; // .reverse();
          this.loadservice.removeloader();
         console.log(this.apiUsers)
        },
        (err) => {
          console.log({err})
          this.loadservice.removeloader();
          // if (err.statusText == 'Unknown Error' || err.statusText == 'Internal Server Error') {
          //   this.route.navigate(['/logon']);
          // }
        }
      );
  }

  // public searchUser() {
  //   this.loadservice.r();
  //   this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
  //   if (this.findUser != null && this.searchQuery != 'undefined') {
  //     if (this.searchQuery == 'mobile') {
  //       this.adminService.getUserByNumber(this.findUser, this.adminUser.token)
  //         .subscribe(
  //           (res) => {
  //             this.userData = res;

  //             this.loadservice.r();
  //           }, (error) => {
  //             $('#searchUser').modal('show');
  //             this.msg = 'Mobile number Does not exist!';
  //             this.loadservice.r();
  //           });
  //     } else {
  //       $('#searchUser').modal('show');
  //       this.msg = 'Please select  number on the select input!';
  //       this.loadservice.r();
  //     }
  //   } else {
  //     $('#searchUser').modal('show');
  //     this.msg = 'Please enter user mobile number and select  number on the select input!';
  //     this.loadservice.r();
  //   }
  // }
  public showTabByRole(role):boolean{
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let result=false;
    userInfo.roles.map(r=>{
      if(role===r.code){
        result=true;
      }
    })
    return result;
  }






}
