import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderByDate'
})
export class OrderByDatePipe implements PipeTransform {

  Item : any;

  transform(value: any, args?: any): any {
    return null;
  }
}
