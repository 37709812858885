import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {Advert} from "../../models/advert";

@Component({
  selector: 'app-marketing',
  templateUrl: './marketing.component.html',
  styleUrls: ['./marketing.component.css']
})
export class MarketingComponent implements OnInit {

  adverts: Advert[];
  deletedAdverts: Advert[];
  showDeleted = false;

  constructor(private route: Router) { }

  ngOnInit() {
    // get list of adverts
    if (localStorage.getItem('adverts') !== 'adverts') {
      this.adverts = JSON.parse(localStorage.getItem('adverts'));
      // this.adverts.reverse();
    }

    // get list of deleted adverts
    if (localStorage.getItem('deletedAdverts') !== 'deletedAdverts') {
      this.deletedAdverts = JSON.parse(localStorage.getItem('deletedAdverts'));
      // this.adverts.reverse();
    }
  }

  createAdvert() {
    if (localStorage.getItem('adverts') !== 'adverts') {
      localStorage.setItem('adverts', JSON.stringify(this.adverts));
    }
  }

  viewAdvertDetails(advert, i) {
    // collects details of the advert to be viewed
    localStorage.setItem('advertToView', JSON.stringify(this.adverts[i]));
  }

  editAdvert(adverts, i) {
  //  collects data about the advert to be edited
    localStorage.setItem('advertToEdit', JSON.stringify(this.adverts[i]));
    localStorage.setItem('advertNumber', JSON.stringify(i));
  }

  deleteAdvert(advIndex) {
    // place deleted advert in the 'recycle bin'
    const advert = this.adverts[advIndex];
    advert.dateDeleted = new Date();

    if (this.deletedAdverts) {
      this.deletedAdverts.push(advert);
    } else {
      this.deletedAdverts = [advert];
    }

    this.adverts.splice(advIndex, 1);

    localStorage.setItem('adverts', JSON.stringify(this.adverts));
    localStorage.setItem('deletedAdverts', JSON.stringify(this.deletedAdverts));
  }

  viewDeletedAdverts() {
    this.showDeleted = !this.showDeleted;
  }

  restoreAdvert(advIndex) {
    this.adverts.push(this.deletedAdverts[advIndex]);
    this.deletedAdverts.splice(advIndex, 1);

    localStorage.setItem('adverts', JSON.stringify(this.adverts));
    localStorage.setItem('deletedAdverts', JSON.stringify(this.deletedAdverts));
  }
}
