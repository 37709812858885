import { Component, OnInit } from '@angular/core';
import { HaversineService, GeoCoord } from 'ng2-haversine';
declare var $: any;
import * as firebase from 'firebase/app';
import { log } from 'util';
import { BookingService } from 'src/app/services/booking.service';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/services/shared.service';
import { LoaderService } from 'src/app/services/loader.service';
declare var google: any;

@Component({
  selector: 'app-skynethome',
  templateUrl: './skynethome.component.html',
  styleUrls: ['./skynethome.component.css']
})
export class SkynethomeComponent implements OnInit {

  public userSettings2: any = {
    showRecentSearch: false,
    geoCountryRestriction: ['ZA'],
    inputPlaceholderText: 'Enter pick-up street address.',
    geoTypes: ['address', 'establishment']
  };

  public userSettings: any = {
    showRecentSearch: false,
    geoCountryRestriction: ['ZA'],
    inputPlaceholderText: 'Enter drop-Off street address.',
    geoTypes: ['address', 'establishment']
  };

  addressComponent: any;
  province: string;
  validateProvince: string;

  pickUpNotMsg: string;
  pickUpNotFound: boolean;
  pickUpAddress: any;
  pickUpLat: any;
  pickUpLong: any;
  pickUpCoordinate: any[];
  pickUpCoordinateLocation: any = {};
  departureCoordinate: any = {};

  vehicleType: any;
  description: any;
  vehicleTypeRate: any;
  vehicle: any;
  miniVan: any;
  isExpress: boolean;
  oneTon: any;
  oneHalfTon: any;
  fourTon: any;
  eightTon: any;
  // express: any;

  dropOffLat: number;
  dropOffLong: number;
  dropOffNotMsg = '';
  dropOffNotFound: boolean;
  dropOffAddress: string;
  dropOffCoordinate = [];
  dropOffCoordinateLocation: any = {};
  destinationCoordinate: any = {};

  pickUpPlaceHolder: string;
  dropOffPlaceHolder: string;

  priceBox: boolean = false;

  dir = undefined;

  tempAmount: number;

  location: any = {};
  userLat: number;
  userLong: number;
  matrix: any;

  express: string = 'assets\\img\\retail\\SkynetExpress.png';

  errorMessage: string;
  loader = false;
  msgStatus: string;

  mass: any;
  massRate: number;

  distance: number;
  distanceTest: number;
  vehicleTypeData: any;
  vehicleOid: string;
  totalExpressPrice: number;

  constructor(private _haversineService: HaversineService, private bookService: BookingService, private router: Router, private sharedService: SharedService,private loadservice:LoaderService) { }

  ngOnInit() {
    if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition((pos) => this.setPosition(pos));
    }
    this.getVehicleTypeRates();
    this.matrix = new google.maps.DistanceMatrixService()
  }

  autoCompleteCallback1(selectedData: any) {

    if (!selectedData) {
      this.pickUpNotMsg = 'Location not found, Please enter nearest location.';
      this.pickUpNotFound = false;
    } else {
      this.pickUpAddress = selectedData.data.description;
      this.pickUpLat = selectedData.data.geometry.location.lat;
      this.pickUpLong = selectedData.data.geometry.location.lng;
      this.pickUpCoordinate = [selectedData.data.geometry.location.lat, selectedData.data.geometry.location.lng];
      this.pickUpCoordinateLocation = { coordinates: this.pickUpCoordinate, address: this.pickUpAddress };
      let geocoder = new google.maps.Geocoder;
      this.departureCoordinate = { "lat": selectedData.data.geometry.location.lat, "lng": selectedData.data.geometry.location.lng };
      this.getAdminArea(selectedData.data.address_components);
    }
  }

  autoCompleteCallback2(selectedData: any) {
    if (!selectedData) {
      this.dropOffNotMsg = 'Location not found, Please enter nearest location.';
      this.dropOffNotFound = false;
    } else {
      this.dropOffAddress = selectedData.data.description;
      this.dropOffLat = selectedData.data.geometry.location.lat;
      this.dropOffLong = selectedData.data.geometry.location.lng;
      this.dropOffCoordinate = [selectedData.data.geometry.location.lat, selectedData.data.geometry.location.lng];
      this.dropOffCoordinateLocation = { coordinates: this.dropOffCoordinate, address: this.dropOffAddress };
      this.destinationCoordinate = { "lat": selectedData.data.geometry.location.lat, "lng": selectedData.data.geometry.location.lng };
    }
  }

  private getAdminArea(address_component: []) {

    this.addressComponent = address_component;
    let len = address_component.length;

    for (let i = 0; i < len; i++) {
      switch (this.addressComponent[i].long_name) {
        case 'Gauteng': {
          this.province = 'Gauteng';
          this.validateProvince = 'Gauteng';
          break;
        }
        case 'KwaZulu-Natal': {
          this.province = 'KwaZulu-Natal';
          this.validateProvince = 'KwaZulu-Natal';
          break;
        }
        case 'kzn': {
          this.province = 'kzn';
          this.validateProvince = 'KWA_ZULU_NATAL';
          break;
        }
        case 'Western Cape': {
          this.province = 'Western Cape';
          this.validateProvince = 'Western Cape';
          break;
        }
        case 'Eastern Cape': {
          this.province = 'Eastern Cape';
          this.validateProvince = 'Eastern Cape';
          break;
        }
        case 'Limpopo': {
          this.province = 'Limpopo';
          this.validateProvince = 'Limpopo';
          break;
        }
        case 'Mpumalanga': {
          this.province = 'Mpumalanga';
          this.validateProvince = 'Mpumalanga';
          break;
        }
        case 'Northern Cape': {
          this.province = 'Northern Cape';
          this.validateProvince = 'Northern Cape';
          break;
        }
        case 'North West': {
          this.province = 'North West';
          this.validateProvince = 'North West';
          break;
        }
        case 'Free State': {
          this.province = 'Free State';
          this.validateProvince = 'Free State';
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  onSelectChange(vehicleType) {
    this.vehicleType = vehicleType.vehicleType;
    this.description = vehicleType.description;
    for (let myChild of this.vehicleTypeRate) {
      myChild.BackgroundColour = "#CCCCCC";
    }
    if (vehicleType.vehicleType == 'DROPPA_EXPRESS') {
      this.vehicle = this.express;
      localStorage.setItem('vehicleType', this.vehicle);
      vehicleType.BackgroundColour = "black";
    }
  }

  getDistance(): number {
    const pickCordinate: GeoCoord = {
      latitude: this.pickUpLat,
      longitude: this.pickUpLong
    };
    const dropCordinate: GeoCoord = {
      latitude: this.dropOffLat,
      longitude: this.dropOffLong
    };
    const kilometers = this._haversineService.getDistanceInKilometers(pickCordinate, dropCordinate);
    return kilometers;
  }

  GetElementInsideContainer(containerID, childID) {
    var elm = {};
    var elms = document.getElementById(containerID).getElementsByTagName("input");
    for (var i = 0; i < elms.length; i++) {
      if (elms[i].id === childID) {
        elm = elms[i];
        this.pickUpPlaceHolder = elms[i].value;
        this.userSettings2 = {
          showRecentSearch: false,
          geoCountryRestriction: ['ZA'],
          inputPlaceholderText: this.pickUpPlaceHolder,
          geoTypes: ['address', 'establishment']
        };
        break;
      }
    }
    return elm;
  }

  GetElementInsideContainer2(containerID, childID) {
    var elm = {};
    var elms = document.getElementById(containerID).getElementsByTagName("input");
    for (var i = 0; i < elms.length; i++) {
      if (elms[i].id === childID) {
        elm = elms[i];
        this.dropOffPlaceHolder = elms[i].value;
        elms[i].placeholder = this.dropOffPlaceHolder;
        this.userSettings = {
          showRecentSearch: false,
          geoCountryRestriction: ['ZA'],
          inputPlaceholderText: this.dropOffPlaceHolder,
          geoTypes: ['address', 'establishment']
        };
        break;
      }
    }
    return elm;
  }

  getQuotes() {
    this.priceBox = true;
    this.dir = {
      origin: { lat: this.pickUpCoordinate[0], lng: this.pickUpCoordinate[1] },
      destination: { lat: this.dropOffCoordinate[0], lng: this.dropOffCoordinate[1] }
    };
  }

  goToEntry() {
    this.priceBox = undefined;
  }

  public changeValues() {
    this.priceBox = true;
  }

  confirmBooking() {

    let obect = {
      pickUpAddress: this.pickUpCoordinateLocation,
      dropOffAddress: this.dropOffCoordinateLocation,
      price: this.tempAmount,
      vehicleType: this.vehicleType,
      province: this.province.toUpperCase()
    };

    if ((localStorage.getItem('userInfoBookingLogin') != null && localStorage.getItem('userInfoBooking') != null)) {
      localStorage.setItem('addressData', JSON.stringify(obect));
      this.loginExpress(JSON.parse(localStorage.getItem('userInfoBookingLogin')));
    }
    else {
      localStorage.setItem('addressData', JSON.stringify(obect));
      this.router.navigate(['/Booking-SignUp']);
    }
  }

  private loginExpress(object: any) {
    this.bookService.login(object.pwd, object.username).subscribe(
      (response: any) => {
        localStorage.setItem('userData', JSON.stringify(response));
        localStorage.setItem('token', response.token);
        localStorage.setItem('userInfo', JSON.stringify(response));
        localStorage.removeItem('userInfoBookingLogin');
        localStorage.removeItem('userInfoBooking');
        this.router.navigate(['/droppa-express']);
        window.scrollTo(0, 0);
      }
    );
  }

  public getVehicleTypeRates() {
    this.bookService.vehicleTypeRates().subscribe(
      (res) => {
        this.vehicleTypeRate = res;
        //console.log(this.vehicleTypeRate)
        this.onSelectChange(this.vehicleTypeRate[5]);
      }
    );
  }

  setPosition(position) {
    this.location = position.coords;
    this.userLat = parseFloat(this.location.latitude);
    this.userLong = parseFloat(this.location.longitude);
  }

  public geocodeLatLng(geocoder, userLat, userLong) {
    let latlng = { lat: userLat, lng: userLong };
    let self = this;
    geocoder.geocode({ location: latlng }, function (results, status) {
      if (status === 'OK') {
        let len = results[0].address_components.length;
        let data;
        for (let i = 0; i < len; i++) {
          if (results[0].address_components[i].short_name.length === 2 && results[0].address_components[i].long_name.length >= 7) {
            data = results[0].address_components[i];
            self.validateProvince = data.long_name;
            if (data.long_name === 'South Africa') {
              self.geocodeLatLngProvince(geocoder, results[1].address_components[1].long_name);
            }
            break;
          }
        }
        self.province = data.long_name;
        self.province = self.validateProvince;
      } else {
        self.province = 'UNKNOWN';
        self.province = self.validateProvince;
      }
    });
  }

  public geocodeLatLngProvince(geocoder, city) {
    let self = this;
    geocoder.geocode({ address: city }, function (results, status) {
      if (status === 'OK') {
        let len = results[0].address_components.length;
        let data;
        for (let i = 0; i < len; i++) {
          if (results[0].address_components[i].short_name.length === 2 && results[0].address_components[i].long_name.length >= 7) {
            data = results[0].address_components[i];
            self.validateProvince = data.long_name;
            break;
          }
        }
        self.province = data.long_name;
        self.province = self.validateProvince;

      } else {
        self.province = 'UNKNOWN';
        self.province = self.validateProvince;
      }

    });
  }

  public getQuote() {
    this.GetElementInsideContainer("pickup", "search_places")
    this.GetElementInsideContainer2("dropOff", "search_places")
    this.loadservice.addloader();
    let self = this;
    this.priceBox = false;

    if (this.pickUpAddress != null && this.dropOffAddress != null) {
      if ((this.validateProvince === 'KwaZulu-Natal' ||
           this.validateProvince === 'kzn') ||
           this.validateProvince === 'Western Cape' ||
           this.validateProvince === 'Gauteng' ||
           this.validateProvince === 'Eastern Cape' ||
           this.validateProvince === 'Limpopo' ||
           this.validateProvince === 'Mpumalanga' ||
           this.validateProvince === 'Northern Cape' ||
           this.validateProvince === 'North West' ||
           this.validateProvince === 'Free State') {
          // console.log(this.validateProvince);
          var data = {
            "destination": this.destinationCoordinate,
            "departure": this.departureCoordinate
          };
          if (this.vehicleType == 'DROPPA_EXPRESS' && this.mass == 0) {
            this.msgStatus = "The mass cannot be 0.";
          }
          else if (this.vehicleType == 'DROPPA_EXPRESS' && this.mass == null) {
            this.msgStatus = "The mass field cannot be empty.";
          }
          else if (this.vehicleType == 'DROPPA_EXPRESS' && this.mass > 15) {
            this.msgStatus = "The entered mass cannot exceed 15KG";
          }
          else {
            this.bookService.getDistance(data).subscribe(
              (response) => {
                var distanceResponse = response.json();
                this.distance = distanceResponse.rows[0].elements[0].distance.inMeters;
                this.distance = Math.round(this.distance / 1000);
                localStorage.setItem('distance', this.distance + '');
                this.bookService.getVehicleRate(this.vehicleType)
                  .subscribe(
                    (res: Response) => {
                      this.vehicleTypeData = res.json();
                      this.vehicleOid = this.vehicleTypeData.oid;
                      this.totalExpressPrice = this.vehicleTypeData.basePrice + (this.vehicleTypeData.kilometer * self.distance);
                      this.tempAmount = this.totalExpressPrice;
                      if (this.distance > 200) {
                        this.tempAmount = this.tempAmount * 0.15 + this.tempAmount;
                        this.totalExpressPrice = this.totalExpressPrice * 1.3;
                      }
                      if (this.mass <= 5) {
                        this.massRate = 0.4
                      }
                      else if (this.mass > 5 && this.mass <= 10) {
                        this.massRate = 0.55
                      }
                      else if (this.mass > 10 && this.mass <= 15) {
                        this.massRate = 0.63
                      }
                      else if (this.mass > 15) {
                        this.massRate = 0.7
                      }
                      localStorage.setItem('mass', this.mass);
                      if (this.distance <= 30 && this.vehicleType == 'DROPPA_EXPRESS') {
                        this.tempAmount = (75.00 + (this.distance * this.massRate));
                        this.tempAmount = Math.round(this.tempAmount);
                      }
                      this.sharedService.setTotalAmount(this.totalExpressPrice);
                      this.priceBox = true;
                      self.priceBox = true;

                      this.dir = {
                        origin: { lat: this.pickUpCoordinate[0], lng: this.pickUpCoordinate[1] },
                        destination: { lat: this.dropOffCoordinate[0], lng: this.dropOffCoordinate[1] }
                      };

                      this.changeValues();

                      this.loadservice.removeloader();
                      window.scrollTo(0, 0);
                    },
                    (err) => {
                      this.errorMessage = 'Error occured, please contact Droppa.';
                      $('#errorDialog').modal('show');
                      this.loadservice.removeloader();
                    }
                  );
              });
          }
      } else {
        this.loadservice.removeloader();
          $('#provinceValidation').modal('show');
          this.errorMessage = 'Droppa is currently unavailable in this area';
      }
    } else {
      this.loadservice.removeloader();
      this.errorMessage = 'Please enter all addresses.';
      $('#errorDialog').modal('show');
    }

  }

}
