import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable, from } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';
import {error} from "protractor";

@Component({
  selector: 'app-view-promo-code',
  templateUrl: './view-promo-code.component.html',
  styleUrls: ['./view-promo-code.component.css', '../admin-portal/admin-portal.component.css']
})
export class ViewPromoCodeComponent implements OnInit {

  @ViewChild('mainForm', { static: true }) mainForm: NgForm;
  @ViewChild('promoForm', { static: true}) promoForm: NgForm;
  promoCodes: any = [];
  fleetCodes = [];
  flashCodes = [];
  courierCodes = [];
  referralCodes = [];
  userCodes = [];
  fleetTable = true;
  flashTable = false;
  courierTable = false;
  referralTable = false;
  userTable = false;
  firebaseFeedback = '';
  userObj: any;
  startDate: any;
  endDate: any;
  promoType = '';
  promoAmount = '';
  promoCode = '';
  promoUsageLimit = '';
  targetAmount = null;
  hasTarget = false;
  addOwner = false;
  valid = true;
  ownerNumber
  creditAmount
  hasValidPhone=true
  hasValidAmount=true
  errorFeedback=""

  constructor(public db: AngularFireDatabase, public services: AdminService) {}

  ngOnInit() {
    window.scrollTo(0, 0);
    this.userObj = JSON.parse(localStorage.getItem('userInfo'));
    this.getPromoCode();
    // this.getReferralCodes();
  }

  public getPromoCode() {
    this.services.getPromoCodes(this.userObj.token).subscribe(
      response => {
        this.promoCodes = [];
        this.promoCodes = response.reverse();
        // console.log(this.promoCodes);
        this.fleetCodes = this.promoCodes.filter(code => code.promoType.toUpperCase() === 'FLEET' && code.ownerName === null);
        this.flashCodes = this.promoCodes.filter(code => code.promoType.toUpperCase() === 'FLASH' && code.ownerName === null);
        this.courierCodes = this.promoCodes.filter(code => code.promoType.toUpperCase() === 'COURIER' && code.ownerName === null);
        this.userCodes = this.promoCodes.filter(code => code.ownerName !== null);
        this.getReferralCodes();
      }, error => {
        console.log(error);
      }, () => {});
  }

  public addPromoCode() {
    this.valid=true;
    var currentDate = new Date(this.startDate);
    let date = currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate();
    let monthNo = (currentDate.getMonth() + 1) <= 9 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
    let hour = currentDate.getHours() <= 9 ? '0' + currentDate.getHours() : currentDate.getHours();
    let minutes = currentDate.getMinutes() <= 9 ? '0' + currentDate.getMinutes() : currentDate.getMinutes();
    var second = currentDate.getSeconds() <= 9 ? '0' + currentDate.getSeconds() : currentDate.getSeconds();
    var dropTime = currentDate.getFullYear() + '-' + monthNo + '-' + date + 'T' + hour + ':' + minutes + ':' + second + '.312Z';

    var currentDate2 = new Date(this.endDate);
    let date2 = currentDate2.getDate() <= 9 ? '0' + currentDate2.getDate() : currentDate2.getDate();
    let monthNo2 = (currentDate2.getMonth() + 1) <= 9 ? '0' + (currentDate2.getMonth() + 1) : (currentDate2.getMonth() + 1);
    let hour2 = currentDate2.getHours() <= 9 ? '0' + currentDate2.getHours() : currentDate2.getHours();
    let minutes2 = currentDate2.getMinutes() <= 9 ? '0' + currentDate2.getMinutes() : currentDate2.getMinutes();
    var second2 = currentDate2.getSeconds() <= 9 ? '0' + currentDate2.getSeconds() : currentDate2.getSeconds();
    var dropTime2 = currentDate2.getFullYear() + '-' + monthNo2 + '-' + date2 + 'T' + hour2 + ':' + minutes2 + ':' + second2 + '.312Z';

    if (this.promoType !== 'REFERRAL') {
      let promoCodeDTO ;

      if(this.addOwner){
        promoCodeDTO = {
          code: this.promoCode.toUpperCase(),
          amount: this.promoAmount,
          startDate: dropTime,
          endDate: dropTime2,
          promoType: this.promoType,
          ownerNumber: this.ownerNumber,
          creditAmount: this.creditAmount
        };
      }else{
        promoCodeDTO = {
          code: this.promoCode.toUpperCase(),
          amount: this.promoAmount,
          startDate: dropTime,
          endDate: dropTime2,
          promoType: this.promoType,
          targetAmount: this.hasTarget ? this.targetAmount : null
        };
      }
      this.hasValidPhone=true
      this.hasValidAmount=true
      if(this.addOwner){
        if(!this.creditAmount||this.creditAmount===''){
          this.hasValidAmount=false
          return
        }
      }
      if (this.hasTarget && this.targetAmount === '') {
        this.valid = false;
        return;
      }

      this.services.addPromoCode(this.userObj.token, promoCodeDTO).subscribe(response => {
        this.firebaseFeedback = 'Promo code registered successfully';
        this.promoForm.reset();
        this.getPromoCode();
      }, error => {
        console.log(error);

        this.errorFeedback=error.error.message
      }, () => {
        console.log('Finally');
      });
    } else {
      const referralCodeDTO = {
        code: this.promoCode.toUpperCase(),
        amount: this.promoAmount,
        startDate: dropTime,
        endDate: dropTime2,
        // promoType: this.promoType,
        // targetAmount: this.hasTarget ? this.targetAmount : null,
        timesUsable: this.promoUsageLimit
      };

      if (this.hasTarget && this.targetAmount === '') {
        this.valid = false;
        return;
      }
      this.errorFeedback=""
      this.services.addReferralCode(this.userObj.token, referralCodeDTO).subscribe(
        response => {
          this.firebaseFeedback = 'Referral code registered successfully';
          this.promoForm.reset();
          this.getPromoCode();
        }, error => {


          console.log(error);
          this.errorFeedback=error.error.message
        }, () => {
          console.log('Finally');
        });
    }
  }

  private getReferralCodes() {
    this.services.getReferralCodes(this.userObj.token).subscribe(
      response => {
        this.referralCodes = response.reverse();
      },
      error => {
        console.log(error);
      });
  }

  public updatePromoCode(promo, status) {
    // console.log(promo);
    if (promo.promoType) {
      this.services.updatePromoCode(this.userObj.token, promo.code, status, promo.promoType).subscribe(
        response => {
          this.getPromoCode();
        }, error => {
          console.log(error);
        }, () => {
        });
    } else {
      this.services.updateReferralCode(this.userObj.token, promo.code, status).subscribe(
        response => {
          this.getPromoCode();
        }, error => {
          console.log(error);
        }, () => {
        });
    }
  }
  public showTabByRole(role):boolean{
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let result=false;
    userInfo.roles.map(r=>{
      if(role===r.code){
        result=true;
      }
    })
    return result;
  }

  public selectTable(selectedTable) {
    this.fleetTable = false;
    this.flashTable = false;
    this.courierTable = false;
    this.referralTable = false;
    this.userTable = false;

    switch (selectedTable) {
      case 'Fleet' : this.fleetTable = true; break;
      case 'Flash' : this.flashTable = true; break;
      case 'Courier' : this.courierTable = true; break;
      case 'Referral' : this.referralTable = true; break;
      case 'User' : this.userTable = true; break;
    }
  }

}
