import {DatePipe} from "@angular/common";
import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {Router} from "@angular/router";
import {Parcel} from "src/app/models/parcel";
import {ParcelDimensions} from "src/app/models/parcelDimensions";
import {BookingService} from "src/app/services/booking.service";
import {SharedService} from "src/app/services/shared.service";
import {Response} from "@angular/http";
import * as moment from "moment";
import {LoaderService} from "src/app/services/loader.service";
import {UserService} from "../../services/user.service";

declare var google, $: any;

@Component({
  selector: 'app-create-retail-booking-with-tag',
  templateUrl: './create-retail-booking-with-tag.component.html',
  styleUrls: ['./create-retail-booking-with-tag.component.css'],
})
export class CreateRetailBookingWithTagComponent implements OnInit {


  public userSettings2: any = {
    showRecentSearch: true,
    geoCountryRestriction: ["ZA"],
    inputPlaceholderText: "Enter pick up address.",
    geoTypes: ["address", "establishment"],
  };

  public userSettings: any = {
    showRecentSearch: true,
    geoCountryRestriction: ["ZA"],
    inputPlaceholderText: "Enter drop off address.",
    geoTypes: ["address", "establishment"],
  };

  public userSettingsRental: any = {
    showRecentSearch: false,
    geoCountryRestriction: ["ZA"],
    inputPlaceholderText: "Enter your address.",
    geoTypes: ["address", "establishment"],
  };

  contact: Contact = {
    name: "",
    surname: "",
    mobile: "",
    email: "",
    labours: 0,
  };

  dropContact: Contact = {
    name: "",
    surname: "",
    mobile: "",
    email: "",
    labours: 0,
  };

  retail: any;

  disablePickUp: boolean;
  disableAddresses: boolean = true;
  disableDropOffAddress: boolean = true;
  pickUpNotMsg: string;
  pickUpNotFound: boolean;
  fromSuburb: any;
  newPickUpSuburb: any;
  pickUpPCode: any;
  pickUpProvince: any;
  pickUpAddress: any;
  pickUpCoordinate: any[];
  pickUpCoordinateLocation: { coordinates: any; address: any };
  departureCoordinate: { lat: any; lng: any };
  companyPickUpName: any;
  complexPickUp: any;
  unitPickUp: any;
  pudoError = false;
  validateProvince: any;
  province: any;
  addressComponent: any = [];

  disableDropOff: boolean;
  dropOffNotMsg: string;
  dropOffNotFound: boolean;
  toSuburb: any;
  newDropOffSuburb: any;
  dropOffPCode: any;
  dropOffProvince: any;
  dropOffAddress: any;
  dropOffCoordinate: any[];
  destinationCoordinate: { lat: any; lng: any };
  dropOffCoordinateLocation: { coordinates: any; address: any };
  companyDropOffName: any;
  complexDropOff: any;
  unitDropOff: any;
  comment: string;

  isExpress_: boolean = true;
  isFleetPicked: boolean = false;
  isCourierPicked: boolean = false;
  isRoadPicked: boolean = false;
  isExpress: boolean = true;
  // selectedOption: string = "SAME_DAY";
  selectedOption: string = null;

  //side buttons
  isfleetBtn: boolean = true;
  isCourierBtn: boolean = false;
  isRetalBtn: boolean = false;
  isPudoBtn = false;

  isMiniVanPicked: boolean = true;
  isOneTonPicked: boolean = true;
  isOneHalfTonPicked: boolean = true;
  isFourTonPicked: boolean = true;
  isEightTonPicked: boolean = true;

  pracelDims: ParcelDimensions[] = [];
  pracels: Parcel[] = [];
  pracel: Parcel;
  numOfBoxes: any;
  customer_reference: any;
  oppa;
  cost_center_num: any;
  parcel_breadth: any;
  parcel_height: any;
  parcel_length: any;
  parcel_mass: any;
  totalMass: any;

  hourMsg: string;
  provinceMessage: any;

  quoteResponse: any;
  flashFlight: any;
  airlineAmt: any;
  pickUpAmt: any;
  dropOffAmt: any;
  flashRoad: any;
  sheshaMode: any;
  expressCourier: any;
  budgetCourier: any;
  tenderDocument: number = 0;

  flightAvailable: boolean = false;
  flashRoadAvailable: boolean = false;
  sheshaAvailable: boolean = false;
  dashAvailable: boolean = false;

  isBudget: boolean = false;
  isCourier: boolean = false;
  isRoad: boolean = false;
  isFlight: boolean = false;
  isDash: boolean = false;
  isTender: boolean = false;

  vehicleType: any;
  miniVanFleet: string = "";
  oneTonFleet: string = "";
  oneHalfTonFleet: string = "";
  fourTonFleet: string = "";
  eightTonFleet: string = "";
  loader: boolean;
  rentalVehicleType: any;

  bringMultipleDrop: boolean = true;
  bringFlashPrices: boolean = true;
  bringFleetPrices: boolean = true;

  distance: any;
  mass: any;

  isExpressBudget: boolean = true;
  isCourierExpress: boolean = true;

  load: number = 1;
  dropOffFloors: number = 0;
  floorsPickUp: number = 0;
  canopy: boolean = false;
  promoCodeAmnt: number = 0.0;
  hour: number;
  bookingDates: any;

  timeRange: string;
  msgExpress: any;
  pickUpChecked: any;
  dropOffChecked: boolean = false;
  customerRef: any;
  costCentreNumber: any;

  vehicle: string;
  bookingTime: any;
  bookingDate: any;
  bucketBooking: any;
  bookingText: string;

  miniVan: string = "assets\\img\\home\\Group_1014.png";
  oneTon: string = "assets\\img\\home\\Group_1015.png";
  oneHalfTon: string = "assets\\img\\home\\Group_1016.png";
  fourTon: string = "assets\\img\\home\\Group_1017.png";
  express: string = "assets\\img\\home\\Group_1046.png";

  // vehicletp:string="./assets/img/home/Group_1015.png"

  choosentruck: string = "./assets/img/home/Group_1014.png";

  missingTextMsg: string = "";
  massMsg: string = "";
  breadthMsg: string = "";
  heightMsg: string = "";
  lengthMsg: string = "";
  checkingMsg: string = "";

  pickUpExtraDetails: any = [];
  dropOffExtraDetails: any = [];
  promptPostalCode: string = "";
  promptedPickUpPCode: any;
  promptedDropOffPCode: any;

  user: any = {};
  savedAddresses: any = [];

  // Pick up
  enteredPickUpAddress: any;
  enteredPickUpSuburb: any;
  enteredPickUpPostalCode: any;
  enteredPickUpProvince: any;

  enteredPickUpAddressMsg: string = "";
  enteredPickUpSuburbMsg: string = "";
  enteredPickUpPostalCodeMsg: string = "";
  enteredPickUpProvinceMsg: string = "";
  enteredPickUpSuccessMsg: string = "";

  // Drop off
  enteredDropOffAddress: any;
  enteredDropOffSuburb: any;
  enteredDropOffPostalCode: any;
  enteredDropOffProvince: any;

  enteredDropOffAddressMsg: string = "";
  enteredDropOffSuburbMsg: string = "";
  enteredDropOffPostalCodeMsg: string = "";
  enteredDropOffProvinceMsg: string = "";
  enteredDropOffSuccessMsg: string = "";
  fromDropDownSuburb: any;
  toDropDownSuburb: any;

  bookingPrice: any;
  price: number = 0;

  dateObj: any;
  displayNewDate: any;
  workingDays: any;
  dateMessage: string;
  type: string;
  transportMode: string;
  tempAmount: number = 0;
  fleetBookingDate: any;
  fleetBookingTime: any;
  validationMsg: string = "";
  workingHours: number;
  additionalHour: any;

  // Checks if this vehicle is configured on the retail then binds it on the html
  isMiniVanConfig: boolean = false;
  isOneTonConfig: boolean = false;
  isOneHalfTonConfig: boolean = false;
  isFourTonConfig: boolean = false;
  isEightTonConfig: boolean = false;

  // Checks if this vehicle is configured on the retail then binds it on the html
  isRentalMiniVan: boolean = false;
  isRentalOneTon: boolean = false;
  isRentalOneHalfTon: boolean = false;
  isRentalFourTon: boolean = false;
  isRentalEightTon: boolean = false;

  // Controls what is viewed with the tablist buttons
  bookingFleetBtn: boolean = false;
  courierServiceBtn: boolean = false;
  pudoServiceBtn = false;
  truckRentalBtn: boolean = false;

  // Displays either fridge or none fridge prices
  fridgePrices: any = [];
  bringFridePrices: boolean = false;
  normalTrucks: any = [];
  code10Vehicles = [];
  code14Vehicles = [];
  bringNormalPrices: boolean = false;
  bringDroppaRental: boolean = false;
  showCode10 = false;
  showCode14 = false;

  // Elite Details
  @ViewChild("m_id", { static: false }) merchant_id: ElementRef;
  @ViewChild("m_key", { static: false }) merchant_key: ElementRef;
  @ViewChild("return_url", { static: false }) return_url: ElementRef;
  @ViewChild("cancel_url", { static: false }) cancel_url: ElementRef;
  @ViewChild("notify_url", { static: false }) notify_url: ElementRef;
  @ViewChild("name_first", { static: false }) name_first: ElementRef;
  @ViewChild("name_last", { static: false }) name_last: ElementRef;
  @ViewChild("email_address", { static: false }) email_address: ElementRef;
  @ViewChild("cell_number", { static: false }) cell_number: ElementRef;
  @ViewChild("m_payment_id", { static: false }) m_payment_id: ElementRef;
  @ViewChild("amount", { static: false }) amount: ElementRef;
  @ViewChild("item_name", { static: false }) item_name: ElementRef;
  @ViewChild("item_description", { static: false }) item_description: ElementRef;
  @ViewChild("custom_int1", { static: false }) custom_int1: ElementRef;
  @ViewChild("confirmation_address", { static: false }) confirmation_address: ElementRef;
  // Elite Details

  eliteAddress: string = "";
  eliteSuburb: string = "";
  elitePostalCode: string = "";
  eliteProvince: string = "";
  eliteComplexName: string = "";
  eliteUnitNo: string = "";
  eliteType: any;
  today = new Date();
  eliteCompanyName: string = "";
  eliteUserName: string = "";
  eliteMobile: string = "";
  selectedBranch: any;
  branchOid: any;
  branches: any = [];
  public min = new Date(this.today.setDate(this.today.getDate() - 1));
  public max = new Date(2025, 3, 21, 20, 30);

  // Tender Dates
  public minTender = new Date(this.today.setDate(this.today.getDate() + 1));
  public maxTender = new Date(2025, 3, 21, 20, 30);
  terms: boolean;
  termsControl: boolean = false;
  numDays: number;
  labour: number = 0;
  subtotal: number = 0;
  VAT: number = 0;
  totalAmt: number = 0;
  numberDecimalMsg: string = "";
  warningMsg: string = "";
  paymentMsg: string = "";
  eliteStartDate: any;
  eliteEndDate: any;
  kilometersToTravel = 0;
  url: any;
  kilometersAbove: boolean = false;
  vatAmt: number = 0;
  baseAmt: any;
  sleepOutFee: any;
  selectedTab: string = "";

  sameMassParcels: boolean = false;
  numOfParcels: string = "";

  // Holding place variables for rental droppa vehicles
  rentalMiniVanTotal: any;
  rentalOneTonTotal: any;
  rentalOneHalfTonTotal: any;
  rentalFourTonTotal: any;
  rentalEightTonTotal: any;

  rentalMiniVanSubTotal: any;
  rentalOneTonSubTotal: any;
  rentalOneHalfTonSubTotal: any;
  rentalFourTonSubTotal: any;
  rentalEightTonSubTotal: any;

  rentalMiniVanTypeRateOid: any;
  rentalOneTonTypeRateOid: any;
  rentalOneHalfTonTypeRateOid: any;
  rentalFourTonTypeRateOid: any;
  rentalEightTonTypeRateOid: any;
  typeRateOid: any;

  rentalAddress: string = "";
  rentalCoordinate: any = [];
  rentalLat: string = "";
  rentalLong: string = "";

  tenderDocumentSelected: boolean = false;
  tenderDate: any;
  tenderTime: any;

  retailBranch: any;


  pickupaddressError:boolean=false;
  atPickupError:boolean=false;
  pickupNameError:boolean=false;
  pickupPhoneNumberError:boolean=false;

  dropoffaddressError:boolean=false;
  atDroppoffError:boolean=false;
  droppoffNameError:boolean=false;
  droppofPhoneNumberError:boolean=false;
  commentError:boolean=false;

  truckError:boolean=false;

  termsFleetError=false;

  termsCourierError=false;
  serviceTypeError=false;

  canAddParcels = false;

  // Pudo

  public pudoNg4GeoPickup: any = {
    showRecentSearch: true,
    geoCountryRestriction: ['ZA'],
    inputPlaceholderText: 'Enter sending area address to zoom in.',
    geoTypes: ['address', 'establishment'],
  };

  public pudoNg4GeoDropOff: any = {
    showRecentSearch: true,
    geoCountryRestriction: ['ZA'],
    inputPlaceholderText: 'Enter receiving area address to zoom in.',
    geoTypes: ['address', 'establishment'],
  };

  pudoLockers = [];
  sendLockerMapCenterLng = 25.994687;
  sendLockerMapCenterLat = -29.132212;
  receiveLockerMapCenterLng = 25.994687;
  receiveLockerMapCenterLat = -29.132212;
  sendLockerZoomLevel = 5;
  receiveLockerZoomLevel = 5;

  showPickUpLockerOption = false;
  showPickUpDoorOption = false;
  showDropOffLockerOption = false;
  showDropOffDoorOption = false;
  reservationType = '';

  showJourney = false;
  selectedPickUpLocker = -1;
  selectedDropOffLocker = -1;
  selectedPudoBox = -1;

  pickUpLockerTab = false;
  pickUpDoorTab = false;
  pickUpBoxSizeTab = false;
  pickUpContactTab = false;
  dropOffLockerTab = false;
  dropOffDoorTab = false;
  dropOffBoxSizeTab = false;
  dropOffContactTab = false;
  summaryTab  = false;
  paymentTab = false;

  pickUpLocker: any;
  invalidPickUpLocker = false;

  pickUpDoor: any;
  pudoPickUpDoorCompanyName = '';
  pudoPickUpDoorAdditional = '';
  invalidPickUpDoor = false;

  boxSize: any;
  invalidBoxSize = false;

  pickUpContact = {
    name: '',
    phone: '',
    email: ''
  };
  fillPickUpContact = false;
  needPickUpCompanyName = false;
  invalidPickUpContact = false;

  dropOffLocker: any;
  invalidDropOffLocker = false;

  dropOffDoor: any;
  pudoDropOffDoorCompanyName = '';
  pudoDropOffDoorAdditional = '';
  invalidDropOffDoor = false;

  dropOffContact = {
    name: '',
    phone: '',
    email: ''
  };
  fillDropOffContact = false;
  needDropOffCompanyName = false;
  invalidDropOffContact = false;

  summaryHasBeenViewed = false;
  invalidSummary = false;

  pudoTerms = false;
  pudoMessage = '';
  pudoSelectedMarker = './assets/img/img/home/selectedMarker_.png';

  pudoBooking: any;
  isPudoCreated = false;
  walletBalance = 0;
  pudoWalletInsufficient = false;

  amountChosen = 0;
  pudoWalletMsg = '';
  invalidWalletAmt = false;

  // Pudo

  selectedRetailService = '';

  surbCheck={fromSuburbList:[],toSuburbList:[],pickUpPCodeList:[],dropOffPCodeList:[]}
  fromSuburbList:any[]=[];
  toSuburbList:any[]=[];
  pickUpPCodeList:any[]=[];
  dropOffPCodeList:any[]=[];

  usedGoogleAddressPickUp = false;
  usedGoogleAddressDropOff = false;

  constructor(
    private nameService: SharedService,
    private router: Router,
    private sharedService: SharedService,
    private bookService: BookingService,
    private loadservice:LoaderService,
    private userService: UserService
  ) {
    this.url = this.nameService.getPayFastUrl();
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem("userInfo"));
    this.retail = JSON.parse(localStorage.getItem("retail"));
    localStorage.setItem("retail", JSON.stringify(this.retail));
    this.canAddParcels = this.retail.canAddParcels;
    this.getSavedAddress(this.user.token, this.user.oid);
    this.checkConfigedVehicles(this.retail.typeRates);
    /*if (this.isMiniVanConfig || this.isOneTonConfig || this.isOneHalfTonConfig || this.isFourTonConfig || this.isEightTonConfig) {
      this.bookFleetButton();
    } else {
      this.courierServiceButton();
    }*/
    this.eliteUserName = this.user.owner.firstName + " " + this.user.owner.surname;
    this.selectedRetailService = localStorage.getItem('selectedRetailService');
    // console.log(this.selectedRetailService);
    switch (this.selectedRetailService) {
      case 'courier': this.courierServiceButton(); break;
      case 'pudo': this.pudoLockerButton(); break;
      case 'fleet': this.bookFleetButton(); break;
      case 'rental': this.truckRentalButton(); break;
    }
  }

  noSelectedDate: string = "";

  // RENTAL
  public autoCompleteCallbackRental(selectedData: any) {
    if (!selectedData) {
      this.pickUpNotMsg = "Location not found, Please enter nearest location.";
      this.pickUpNotFound = false;
    } else {
      this.rentalAddress = selectedData.data.description;
      this.rentalLat = selectedData.data.geometry.location.lat;
      this.rentalLong = selectedData.data.geometry.location.lng;
    }
  }

  public truckType(eliteType) {
    if (this.eliteStartDate != undefined && this.eliteEndDate != undefined) {
      var diffBetweenDays = moment(this.eliteEndDate).diff(
        moment(this.eliteStartDate),
        "days"
      );
      var diffBetweenDays = diffBetweenDays + 1;
      if (false) {
        this.numberDecimalMsg = "Maximum number of rental days are 21.";
      } else if (diffBetweenDays < 0) {
        this.numberDecimalMsg =
          "The return date cannot be before the start date. Please select accordingly.";
      } else {
        // incrementing the days by 1 since the backend increments the day by 1
        this.numDays = diffBetweenDays;
        if (diffBetweenDays == 0) {
          this.numDays = diffBetweenDays + 1;
        }
        if (diffBetweenDays == 22) {
          this.numDays = diffBetweenDays - 1;
        }
        var currentDate = new Date(this.eliteStartDate);
        let date =
          currentDate.getDate() <= 9
            ? "0" + currentDate.getDate()
            : currentDate.getDate();
        let monthNo =
          currentDate.getMonth() + 1 <= 9
            ? "0" + (currentDate.getMonth() + 1)
            : currentDate.getMonth() + 1;
        let hour =
          currentDate.getHours() <= 9
            ? "0" + currentDate.getHours()
            : currentDate.getHours();
        let minutes =
          currentDate.getMinutes() <= 9
            ? "0" + currentDate.getMinutes()
            : currentDate.getMinutes();
        var second =
          currentDate.getSeconds() <= 9
            ? "0" + currentDate.getSeconds()
            : currentDate.getSeconds();
        var dropTime =
          currentDate.getFullYear() +
          "-" +
          monthNo +
          "-" +
          date +
          "T" +
          hour +
          ":" +
          minutes +
          ":" +
          second +
          ".957Z";
        this.eliteStartDate = dropTime;
        var currentDate2 = new Date(this.eliteEndDate);
        let dateHalf2 =
          currentDate2.getDate() <= 9
            ? "0" + currentDate2.getDate()
            : currentDate2.getDate();
        let monthNo2 =
          currentDate2.getMonth() + 1 <= 9
            ? "0" + (currentDate2.getMonth() + 1)
            : currentDate2.getMonth() + 1;
        let hour2 =
          currentDate2.getHours() <= 9
            ? "0" + currentDate2.getHours()
            : currentDate2.getHours();
        let minutes2 =
          currentDate2.getMinutes() <= 9
            ? "0" + currentDate2.getMinutes()
            : currentDate2.getMinutes();
        var second2 =
          currentDate2.getSeconds() <= 9
            ? "0" + currentDate2.getSeconds()
            : currentDate2.getSeconds();
        var dropTime2 =
          currentDate2.getFullYear() +
          "-" +
          monthNo2 +
          "-" +
          dateHalf2 +
          "T" +
          hour2 +
          ":" +
          minutes2 +
          ":" +
          second2 +
          ".957Z";
        this.eliteEndDate = dropTime2;
        let quoteBody = {
          // isDistanceAbove200: this.kilometersAbove,
          startDate: this.eliteStartDate,
          returnDate: this.eliteEndDate,
          Code: this.eliteType,
          distance: this.kilometersToTravel
        };
        this.numberDecimalMsg = "";
        this.eliteType = eliteType;
        this.getNewElitePrices(this.eliteType, quoteBody);
      }
    } else {
      this.noSelectedDate =
        "Please select the dates before selecting the truck type";
    }
  }

  public selectBranchOid(selectedBranch) {
    this.branchOid = selectedBranch;
  }

  public selectRetailBranch(selectedBranch) {
    this.retailBranch = selectedBranch;
  }

  public getBranches() {
    this.bookService.getEliteBranches().subscribe(
      (response) => {
        this.branches = response;
      },
      (error) => {
        console.log(error);
      },
      () => {}
    );
  }

  public getNewElitePrices(type, quoteBody) {
    this.loadservice.addloader()
    this.bookService.getEliteQuotes2(quoteBody).subscribe(
      (res) => {
        // console.log(res);
        this.loadservice.removeloader()
        if (type == "fridges") {
          this.fridgePrices = res.fridges;
          this.bringFridePrices = true;
          this.bringNormalPrices = false;
          this.bringDroppaRental = false;
          // Set the price back default when a new rental type is selected
          this.subtotal = 0;
          this.vatAmt = 0;
          this.totalAmt = 0;
        } else if (type == "normalTruck") {
          this.normalTrucks = res.normalTruck;
          this.bringNormalPrices = true;
          this.bringFridePrices = false;
          this.bringDroppaRental = false;
          // Set the price back default when a new rental type is selected
          this.subtotal = 0;
          this.vatAmt = 0;
          this.totalAmt = 0;
        } else if (type == "droppaTruck") {
          this.bringNormalPrices = false;
          this.bringFridePrices = false;
          this.bringDroppaRental = true;
          // Set the price back default when a new rental type is selected
          this.subtotal = 0;
          this.vatAmt = 0;
          this.totalAmt = 0;
          // call method to bring the list for droppa rental trucks
          this.droppaRentalTrucks(this.retail.typeRates);
          // Checks if the rental type is 'Droppa Trucks' if it is, automatically sets the branchOid for Droppa Branch
          this.branches.forEach((element) => {
            if (element.name == "Droppa Rental") {
              this.branchOid = element.oid;
            }
          });
        } else if (this.eliteType === 'CODE_10') {
          this.code10Vehicles = res.fridges;
          this.showCode10 = true;
          this.showCode14 = false;
          // Set the price back default when a new rental type is selected
          this.subtotal = 0;
          this.vatAmt = 0;
          this.totalAmt = 0;
        } else if (this.eliteType === 'CODE_14') {
          this.code14Vehicles = res.fridges;
          this.showCode10 = false;
          this.showCode14 = true;
          // Set the price back default when a new rental type is selected
          this.subtotal = 0;
          this.vatAmt = 0;
          this.totalAmt = 0;
        }
      },
      (error) => {
        this.loadservice.removeloader()
        console.log(error);
      },
      () => {}
    );
  }

  public droppaRentalTrucks(vehicle) {
    vehicle.forEach((element) => {
      // If the vehicles are Droppa Rental Trucks
      if (element.vehicleType == "MINI_VAN" && element.type == "RENTAL") {
        this.rentalMiniVanTypeRateOid = element.oid;
        this.isRentalMiniVan = true;
        this.rentalMiniVanSubTotal = element.standardRate;
        this.tempAmount = this.rentalMiniVanSubTotal * this.numDays;
        this.rentalMiniVanTotal = this.tempAmount + this.vatAmt;
      } else if (
        element.vehicleType == "ONE_TON_TRUCK" &&
        element.type == "RENTAL"
      ) {
        this.rentalOneTonTypeRateOid = element.oid;
        this.isRentalOneTon = true;
        this.rentalOneTonSubTotal = element.standardRate;
        this.tempAmount = this.rentalOneTonSubTotal * this.numDays;
        this.rentalOneTonTotal = this.tempAmount + this.vatAmt;
      } else if (
        element.vehicleType == "ONE_HALF_TRUCK" &&
        element.type == "RENTAL"
      ) {
        this.rentalOneHalfTonTypeRateOid = element.oid;
        this.isRentalOneHalfTon = true;
        this.rentalOneHalfTonSubTotal = element.standardRate;
        this.tempAmount = this.rentalOneHalfTonSubTotal * this.numDays;
        this.rentalOneHalfTonTotal = this.tempAmount + this.vatAmt;
      } else if (
        element.vehicleType == "FOUR_TON_TRUCK" &&
        element.type == "RENTAL"
      ) {
        this.rentalFourTonTypeRateOid = element.oid;
        this.isRentalFourTon = true;
        this.rentalFourTonSubTotal = element.standardRate;
        this.tempAmount = this.rentalFourTonSubTotal * this.numDays;
        this.rentalFourTonTotal = this.tempAmount + this.vatAmt;
      } else if (
        element.vehicleType == "EIGHT_TON_TRUCK" &&
        element.type == "RENTAL"
      ) {
        this.rentalEightTonTypeRateOid = element.oid;
        this.isRentalEightTon = true;
        this.rentalEightTonSubTotal = element.standardRate;
        this.tempAmount = this.rentalEightTonSubTotal * this.numDays;
        this.rentalEightTonTotal = this.tempAmount;
      }
    });
  }

  // Droppa Rental Trucks
  public droppaRentalMiniVan() {
    this.baseAmt = this.rentalMiniVanTotal;
    this.subtotal = this.rentalMiniVanTotal;
    this.vatAmt = this.subtotal * 0.15;
    this.totalAmt = this.subtotal + this.vatAmt;
    this.typeRateOid = this.rentalMiniVanTypeRateOid;
    this.type = "MINI_VAN";
  }

  public droppaRentalOneTon() {
    this.baseAmt = this.rentalOneTonTotal;
    this.subtotal = this.rentalOneTonTotal;
    this.vatAmt = this.subtotal * 0.15;
    this.totalAmt = this.subtotal + this.vatAmt;
    this.typeRateOid = this.rentalOneTonTypeRateOid;
    this.type = "ONE_TON";
  }

  public droppaRentalOneHalfTon() {
    this.baseAmt = this.rentalOneHalfTonTotal;
    this.subtotal = this.rentalOneHalfTonTotal;
    this.vatAmt = this.subtotal * 0.15;
    this.totalAmt = this.subtotal + this.vatAmt;
    this.typeRateOid = this.rentalOneHalfTonTypeRateOid;
    this.type = "ONE_HALF_TON";
  }

  public droppaRentalFourTon() {
    this.baseAmt = this.rentalFourTonTotal;
    this.subtotal = this.rentalFourTonTotal;
    this.vatAmt = this.subtotal * 0.15;
    this.totalAmt = this.subtotal + this.vatAmt;
    this.typeRateOid = this.rentalFourTonTypeRateOid;
    this.type = "FOUR_TON";
  }

  public droppaRentalEightTon() {
    this.baseAmt = this.rentalEightTonTotal;
    this.subtotal = this.rentalEightTonTotal;
    this.vatAmt = this.subtotal * 0.15;
    this.totalAmt = this.subtotal + this.vatAmt;
    this.typeRateOid = this.rentalEightTonTypeRateOid;
    this.type = "EIGHT_TON";
  }
  // Droppa Rental Trucks

  // Fridged Trucks
  public selectFirstFridgeTruck(truck) {
    this.labour = 0;
    this.type = truck.type;
    this.baseAmt = Math.floor(truck.totalAmt * 0.85);
    this.vatAmt = Math.floor(truck.totalAmt * 0.15);
    this.tempAmount = Math.floor(truck.totalAmt);
    this.sleepOutFee = truck.sleepOutFee;
    this.subtotal = this.baseAmt;
    this.totalAmt = this.tempAmount;
  }

  public selectSecondFridgeTruck() {
    this.labour = 0;
    this.type = this.fridgePrices[3].type;
    this.baseAmt = Math.floor(this.fridgePrices[3].amount);
    this.vatAmt = Math.floor(this.fridgePrices[3].vatAmt);
    this.tempAmount = Math.floor(this.fridgePrices[3].totalAmt);
    this.sleepOutFee = this.fridgePrices[3].sleepOutFee;
    this.subtotal = this.baseAmt;
    this.totalAmt = this.tempAmount;
  }

  public selectThirdFridgeTruck() {
    this.labour = 0;
    this.type = this.fridgePrices[2].type;
    this.baseAmt = Math.floor(this.fridgePrices[2].amount);
    this.vatAmt = Math.floor(this.fridgePrices[2].vatAmt);
    this.tempAmount = Math.floor(this.fridgePrices[2].totalAmt);
    this.sleepOutFee = this.fridgePrices[2].sleepOutFee;
    this.subtotal = this.baseAmt;
    this.totalAmt = this.tempAmount;
  }

  public selectFourthFridgeTruck() {
    this.labour = 0;
    this.type = this.fridgePrices[1].type;
    this.baseAmt = Math.floor(this.fridgePrices[1].amount);
    this.vatAmt = Math.floor(this.fridgePrices[1].vatAmt);
    this.tempAmount = Math.floor(this.fridgePrices[1].totalAmt);
    this.sleepOutFee = this.fridgePrices[1].sleepOutFee;
    this.subtotal = this.baseAmt;
    this.totalAmt = this.tempAmount;
  }
  // Fridged Trucks

  // None Fridged Trucks
  public selectFirstNormalTruck() {
    this.labour = 0;
    this.baseAmt = Math.floor(this.normalTrucks[0].amount);
    this.vatAmt = Math.floor(this.normalTrucks[0].vatAmt);
    this.tempAmount = Math.floor(this.normalTrucks[0].totalAmt);
    this.type = this.normalTrucks[0].type;
    this.sleepOutFee = this.normalTrucks[0].sleepOutFee;
    this.subtotal = this.baseAmt;
    this.totalAmt = this.tempAmount;
  }

  public selectSecondNormalTruck() {
    this.labour = 0;
    this.type = this.normalTrucks[4].type;
    this.baseAmt = Math.floor(this.normalTrucks[4].amount);
    this.vatAmt = Math.floor(this.normalTrucks[4].vatAmt);
    this.tempAmount = Math.floor(this.normalTrucks[4].totalAmt);
    this.sleepOutFee = this.normalTrucks[4].sleepOutFee;
    this.subtotal = this.baseAmt;
    this.totalAmt = this.tempAmount;
  }

  public selectThirdNormalTruck() {
    this.labour = 0;
    this.type = this.normalTrucks[2].type;
    this.baseAmt = Math.floor(this.normalTrucks[2].amount);
    this.vatAmt = Math.floor(this.normalTrucks[2].vatAmt);
    this.tempAmount = Math.floor(this.normalTrucks[2].totalAmt);
    this.sleepOutFee = this.normalTrucks[2].sleepOutFee;
    this.subtotal = this.baseAmt;
    this.totalAmt = this.tempAmount;
  }

  public selectFourthNormalTruck() {
    this.labour = 0;
    this.type = this.normalTrucks[3].type;
    this.baseAmt = Math.floor(this.normalTrucks[3].amount);
    this.vatAmt = Math.floor(this.normalTrucks[3].vatAmt);
    this.tempAmount = Math.floor(this.normalTrucks[3].totalAmt);
    this.sleepOutFee = this.normalTrucks[3].sleepOutFee;
    this.subtotal = this.baseAmt;
    this.totalAmt = this.tempAmount;
  }

  public selectFifthNormalTruck() {
    this.labour = 0;
    this.type = this.normalTrucks[1].type;
    this.baseAmt = Math.floor(this.normalTrucks[1].amount);
    this.vatAmt = Math.floor(this.normalTrucks[1].vatAmt);
    this.tempAmount = Math.floor(this.normalTrucks[1].totalAmt);
    this.sleepOutFee = this.normalTrucks[1].sleepOutFee;
    this.subtotal = this.baseAmt;
    this.totalAmt = this.tempAmount;
  }
  // None Fridged Trucks

  public calcBasePrice(): number {
    let price = 0.0;
    price = this.baseAmt + this.labour * 595 * this.numDays;
    return price;
  }

  public calcVAT(): number {
    let vat = 0.0;
    vat = this.calcBasePrice() * 0.15;
    return vat;
  }

  public eliteLabours(labours) {
    if (labours > 0) {
      this.renatalLabours(labours);
    } else {
      this.renatalLabours(labours);
    }
  }

  public renatalLabours(labour: number) {
    this.totalAmt = this.tempAmount + (labour * 595);
    this.subtotal = this.totalAmt * 0.85;
    this.vatAmt = this.totalAmt * 0.15;
    /*this.subtotal = this.calcBasePrice();
    this.vatAmt = this.calcVAT();
    this.totalAmt = this.subtotal + this.vatAmt;*/
  }
  public payRental() {
    var currentDate = new Date(this.eliteStartDate);
    let date =
      currentDate.getDate() <= 9
        ? "0" + currentDate.getDate()
        : currentDate.getDate();
    let monthNo =
      currentDate.getMonth() + 1 <= 9
        ? "0" + (currentDate.getMonth() + 1)
        : currentDate.getMonth() + 1;
    let hour =
      currentDate.getHours() <= 9
        ? "0" + currentDate.getHours()
        : currentDate.getHours();
    let minutes =
      currentDate.getMinutes() <= 9
        ? "0" + currentDate.getMinutes()
        : currentDate.getMinutes();
    var second =
      currentDate.getSeconds() <= 9
        ? "0" + currentDate.getSeconds()
        : currentDate.getSeconds();
    var dropTime =
      currentDate.getFullYear() +
      "-" +
      monthNo +
      "-" +
      date +
      "T" +
      hour +
      ":" +
      minutes +
      ":" +
      second +
      ".957Z";
    this.eliteStartDate = dropTime;

    var currentDate2 = new Date(this.eliteEndDate);
    let dateHalf2 =
      currentDate2.getDate() <= 9
        ? "0" + currentDate2.getDate()
        : currentDate2.getDate();
    let monthNo2 =
      currentDate2.getMonth() + 1 <= 9
        ? "0" + (currentDate2.getMonth() + 1)
        : currentDate2.getMonth() + 1;
    let hour2 =
      currentDate2.getHours() <= 9
        ? "0" + currentDate2.getHours()
        : currentDate2.getHours();
    let minutes2 =
      currentDate2.getMinutes() <= 9
        ? "0" + currentDate2.getMinutes()
        : currentDate2.getMinutes();
    var second2 =
      currentDate2.getSeconds() <= 9
        ? "0" + currentDate2.getSeconds()
        : currentDate2.getSeconds();
    var dropTime2 =
      currentDate2.getFullYear() +
      "-" +
      monthNo2 +
      "-" +
      dateHalf2 +
      "T" +
      hour2 +
      ":" +
      minutes2 +
      ":" +
      second2 +
      ".957Z";
    this.eliteEndDate = dropTime2;

    if (this.rentalAddress == null || this.rentalAddress == "") {
      this.warningMsg = "Please enter the address to proceed.";
      $("#warning").modal("show");
    } else if (this.eliteStartDate == null) {
      this.warningMsg = "Please select a start date to proceed.";
      $("#warning").modal("show");
    } else if (this.eliteEndDate == null) {
      this.warningMsg = "Please select a return date to proceed.";
      $("#warning").modal("show");
    } else if (this.eliteType == null) {
      this.warningMsg = "Please select a truck type to proceed.";
      $("#warning").modal("show");
    } else if (this.selectedBranch == null && this.eliteType != "droppaTruck") {
      this.warningMsg = "Please select a branch for your truck hire.";
      $("#warning").modal("show");
    } else if (!this.termsControl) {
      this.warningMsg =
        "Please click the checkbox below the terms & conditions header to proceed with the elite rental";
      $("#warning").modal("show");
    } else if (this.comment == null) {
      this.warningMsg =
        "Please enter instructions before proceeding with payment";
      $("#warning").modal("show");
    } else if (this.totalAmt == 0) {
      this.warningMsg = "Cannot proceed with booking whilst amount is R0";
      $("#warning").modal("show");
    } else {
      this.loadservice.addloader()
      let rentalObj = {
        from: {
          coordinates: [this.rentalLat, this.rentalLong],
          address: this.rentalAddress,
        },
        branchOid: this.branchOid,
        in: this.eliteStartDate,
        out: this.eliteEndDate,
        rateUnit: "DAILY",
        category: this.type,
        assistance: this.labour,
        includedDistance: 0,
        comments: this.comment,
        customerOid: this.user.owner.oid,
        total: this.totalAmt,
        platform: "web",
        typeRateOid: null,
        paymentType: "ONLINE",
      };

      if (this.typeRateOid != null) {
        rentalObj.typeRateOid = this.typeRateOid;
      }

      this.bookService.saveEliteVehicles(this.user.token, rentalObj).subscribe(
        (response) => {
          this.loadservice.removeloader()
          var paymentData = {
            name_first: this.user.owner.firstName,
            name_last: this.user.owner.surname,
            email_address: this.user.owner.email,
            cell_number: this.user.owner.mobile,
            m_payment_id: response.oid,
            amount: this.totalAmt,
            customerReference: this.user.owner.oid,
            item_name: "Elite Rental",
            item_description: "Payment For Rental Truck",
            email_confirmation: 1,
            confirmation_address: this.user.owner.email,
            reference: response.oid,
            paymentType: "RETAIL_RENTAL_PAYMENT",
          };

          this.bookService
            .requestPayfastPayment(paymentData, this.user.token).subscribe(
              (m) => {
                this.redirectToPayFast(m.json());
                this.loadservice.removeloader()
              },
              (e) => {
                console.log(e);
                this.loadservice.removeloader()
              },
              () => {
                this.loadservice.removeloader()
              }
            );
        },
        (e) => {
          console.log(e);
          this.loadservice.removeloader()
        },
        () => {
          this.loadservice.removeloader();
        }
      );
    }
  }

  public redirectToPayFast(response) {
    this.merchant_id.nativeElement.value = response.merchant_id;
    this.merchant_key.nativeElement.value = response.merchant_key;
    this.return_url.nativeElement.value = response.return_url;
    this.cancel_url.nativeElement.value = response.cancel_url;
    this.notify_url.nativeElement.value = response.notify_url;
    this.name_first.nativeElement.value = response.name_first;
    this.name_last.nativeElement.value = response.name_last;
    this.email_address.nativeElement.value = response.email_address;
    this.cell_number.nativeElement.value = response.cell_number;
    this.m_payment_id.nativeElement.value = response.m_payment_id;
    this.amount.nativeElement.value = response.amount;
    this.item_name.nativeElement.value = response.item_name;
    this.item_description.nativeElement.value = response.item_description;
    this.custom_int1.nativeElement.value = response.custom_int1;
    this.confirmation_address.nativeElement.value =
      response.confirmation_address;
    $("#btnMakePayment1").trigger("click");
  }

  // controls which tab is selected
  public bookFleetButton() {
    this.selectedTab = "";
    this.truckRentalBtn = false;
    this.bookingFleetBtn = true;
    this.courierServiceBtn = false;
    this.pudoServiceBtn = false;
    this.isfleetBtn = true;
    this.isCourierBtn = false;
    this.isRetalBtn = false;
    this.isPudoBtn = false;
    /*this.controlSelectedTab();
    $(".bookFleet").addClass("btnActiveColor");
    $(".bookFleet").removeClass("btnMainColor");*/
    this.backToBookingDetails()
    this.tempAmount===0
    window.scrollTo(0, 0);
  }

  public courierServiceButton() {
    this.selectedTab = "COURIER_SELECTED";
    this.truckRentalBtn = false;
    this.bookingFleetBtn = false;
    this.courierServiceBtn = true;
    this.pudoServiceBtn = false;
    this.isfleetBtn = false;
    this.isCourierBtn = true;
    this.isRetalBtn = false;
    this.isPudoBtn = false;
    /*this.controlSelectedTab();
    $(".courierService").addClass("btnActiveColor");
    $(".courierService").removeClass("btnMainColor");*/
    this.backToBookingDetails()
    this.tempAmount===0
    window.scrollTo(0, 0);
  }

  public pudoLockerButton() {
    this.selectedTab = '';
    this.truckRentalBtn = false;
    this.bookingFleetBtn = false;
    this.courierServiceBtn = false;
    this.pudoServiceBtn = true;
    this.goToL2L();
    this.isfleetBtn = false;
    this.isCourierBtn = false;
    this.isRetalBtn = false;
    this.isPudoBtn = true;
    /*this.controlSelectedTab();
    $(".courierService").addClass("btnActiveColor");
    $(".courierService").removeClass("btnMainColor");*/
    this.backToBookingDetails()
    this.tempAmount===0
    window.scrollTo(0, 0);
  }

  public truckRentalButton() {
    this.selectedTab = "";
    this.truckRentalBtn = true;
    this.bookingFleetBtn = false;
    this.courierServiceBtn = false;
    this.pudoServiceBtn = false;
    this.isfleetBtn = false;
    this.isCourierBtn = false;
    this.isRetalBtn = true;
    this.isPudoBtn = false;
    /*this.controlSelectedTab();
    $(".truckRental").addClass("btnActiveColor");
    $(".truckRental").removeClass("btnMainColor");*/
    this.getBranches();
    this.backToBookingDetails()
    this.tempAmount===0
    window.scrollTo(0, 0);
  }

  public controlSelectedTab() {
    $(".truckRental").addClass("btnMainColor").removeClass("btnActiveColor");
    $(".courierService").addClass("btnMainColor").removeClass("btnActiveColor");
    $(".bookFleet").addClass("btnMainColor").removeClass("btnActiveColor");
  }
  // controls which tab is selected
  // RENTAL

  // This method checks the available vehicles configured to this retail and displays them on the HTML side
  public checkConfigedVehicles(vehicles) {
    this.resetVehicleSelected();

    if (vehicles.length > 0) {
      this.setDefaultVehicle(vehicles[0]);
    }

    vehicles.forEach((element) => {
      if (
        element.vehicleType == "MINI_VAN" &&
        (element.type == null || element.type == "FLEET")
      ) {
        this.isMiniVanConfig = true;
        this.miniVanFleet = element.vehicleType;
      } else if (
        element.vehicleType == "ONE_TON_TRUCK" &&
        (element.type == null || element.type == "FLEET")
      ) {
        this.isOneTonConfig = true;
        this.oneTonFleet = element.vehicleType;
      } else if (
        element.vehicleType == "ONE_HALF_TRUCK" &&
        (element.type == null || element.type == "FLEET")
      ) {
        this.isOneHalfTonConfig = true;
        this.oneHalfTonFleet = element.vehicleType;
      } else if (
        element.vehicleType == "FOUR_TON_TRUCK" &&
        (element.type == null || element.type == "FLEET")
      ) {
        this.isFourTonConfig = true;
        this.fourTonFleet = element.vehicleType;
      } else if (
        element.vehicleType == "EIGHT_TON_TRUCK" &&
        (element.type == null || element.type == "FLEET")
      ) {
        this.isEightTonConfig = true;
        this.eightTonFleet = element.vehicleType;
      }
    });
  }

  public setDefaultVehicle(vehicle) {
    if (vehicle.vehicleType === "MINI_VAN") {
      this.choosentruck = "./assets/img/home/Group_1014.png";
    } else if (vehicle.vehicleType === "ONE_TON_TRUCK") {
      this.choosentruck = "./assets/img/home/Group_1015.png";
    } else if (vehicle.vehicleType === "ONE_HALF_TRUCK") {
      this.choosentruck = "./assets/img/home/Group_1016.png";
    } else if (vehicle.vehicleType === "FOUR_TON_TRUCK") {
      this.choosentruck = "./assets/img/home/Group_1017.png";
    } else if (vehicle.vehicleType === "EIGHT_TON_TRUCK") {
      this.choosentruck = "./assets/img/home/Group_1018.png";
    }
  }

  // Fleet Selection
  public resetVehicleSelected() {
    this.isMiniVanPicked = false;
    this.isOneTonPicked = false;
    this.isOneHalfTonPicked = false;
    this.isFourTonPicked = false;
    this.isEightTonPicked = false;
  }

  private validateInputs() {
    return (this.pickUpAddress && this.contact.name !== null && this.contact.mobile !== null &&
      this.dropOffAddress && this.dropContact.name && this.dropContact.mobile);
  }

  public minivanSelected() {
    this.choosentruck = "./assets/img/home/Group_1014.png";
    this.resetVehicleSelected();
    this.isMiniVanPicked = true;
    // this.vehicleType = this.miniVanFleet;
    if (this.validateInputs()) {
      this.getNormalDroppaRates(this.miniVanFleet);
    }
  }

  public oneTonSelected() {
    this.choosentruck = "./assets/img/home/Group_1015.png";
    this.resetVehicleSelected();
    this.isOneTonPicked = true;
    // this.vehicleType = this.oneTonFleet;
    if (this.validateInputs()) {
      this.getNormalDroppaRates(this.oneTonFleet);
    }
  }

  public oneHalfTonSelected() {
    this.choosentruck = "./assets/img/home/Group_1016.png";
    this.resetVehicleSelected();
    this.isOneHalfTonPicked = true;
    // this.vehicleType = this.oneHalfTonFleet;
    if (this.validateInputs()) {
      this.getNormalDroppaRates(this.oneHalfTonFleet);
    }
  }

  public fourTonSelected() {
    this.choosentruck = "./assets/img/home/Group_1017.png";
    this.resetVehicleSelected();
    this.isFourTonPicked = true;
    // this.vehicleType = this.fourTonFleet;
    if (this.validateInputs()) {
      this.getNormalDroppaRates(this.fourTonFleet);
    }
  }

  public eightTonSelected() {
    this.choosentruck = "./assets/img/home/Group_1018.png";
    this.resetVehicleSelected();
    this.isEightTonPicked = true;
    // this.vehicleType = this.eightTonFleet;
    if (this.validateInputs()) {
      this.getNormalDroppaRates(this.eightTonFleet);
    }
  }
  // Fleet Selection

  // Saved Addresses
  public selectPickUpSavedAddress() {
    $("#showPickUpSavedAddressModal").modal("show");
  }

  public selectDropOffSavedAddress() {
    $("#showDropOffSavedAddressModal").modal("show");
  }

  public getSavedAddress(token, personId) {
    this.bookService.retrieveAddresses(token, personId).subscribe(
      (response) => {
        this.savedAddresses = response;
      },
      (error) => {
        console.log(error);
      },
      () => {}
    );
  }

  public checkedPickup(pickUp: any) {
    var object = JSON.parse(localStorage.getItem("userInfo"));
    if (pickUp == "YES") {
      this.disablePickUp = true;
      this.contact.name = object.owner.firstName;
      this.contact.email = object.owner.email;
      this.contact.mobile = object.owner.mobile;
      this.contact.surname = object.owner.surname;
    } else {
      this.disablePickUp = false;
      /*this.contact.name = "";
      this.contact.email = "";
      this.contact.mobile = "";
      this.contact.surname = "";*/
    }
  }

  public selectPickUpAddress(selectedAddress) {
    this.disableAddresses = false;
    // Address used on the logic side

    this.pickUpAddress = selectedAddress.address;
    this.province = selectedAddress.province;
    this.pickUpProvince=selectedAddress.province
    this.fromSuburb = selectedAddress.suburb;
    this.pickUpPCode = selectedAddress.postalCode;
    this.getPickUpExtraDetails(selectedAddress.postalCode)
    // Address used for display
    if (
      (selectedAddress.latitude == 0 || selectedAddress.latitude == null) &&
      (selectedAddress.longitude == 0 || selectedAddress.longitude == null)
    ) {
      this.pickUpCoordinate = ["-25.98953", "28.12843"];
    }else{
      this.pickUpCoordinate = [selectedAddress.longitude, selectedAddress.latitude];
    }
    this.pickUpCoordinateLocation = {
      coordinates: this.pickUpCoordinate,
      address: this.pickUpAddress,
    };


  }

  public selectDropOffAddress(selectedAddress) {
    this.disableDropOffAddress = false;
    // Address used on the logic side
    this.dropOffAddress = selectedAddress.address;
    this.province = selectedAddress.province;
    this.dropOffProvince = selectedAddress.province;
    this.toSuburb = selectedAddress.suburb;
    this.dropOffPCode = selectedAddress.postalCode;
    this.getDropOffExtraDetails(selectedAddress.postalCode)
    // Address used for display
    this.dropOffAddress = selectedAddress.address;
    if (
      (selectedAddress.latitude == 0 || selectedAddress.latitude == null) &&
      (selectedAddress.longitude == 0 || selectedAddress.longitude == null)
    ) {
      this.dropOffCoordinate = ["-25.98953", "28.12843"];
    }else{
      this.dropOffCoordinate = [selectedAddress.longitude , selectedAddress.latitude];
    }
    this.dropOffCoordinateLocation = {
      coordinates: this.dropOffCoordinate,
      address: this.dropOffAddress,
    };
  }
  // Saved Addresses

  // Pick up Addresses
  autoCompleteCallback1(selectedData: any) {

    // New code for getting a suburb based on a postal code
    this.resetPickUp()
    if(!selectedData)return
    let suburb1 = selectedData.data.address_components
      .filter(f => JSON.stringify(f.types) === JSON.stringify(['locality', 'political']))[0];

    if(suburb1){
      suburb1= suburb1.short_name;
    }

    let suburb2 = selectedData.data.address_components
      .filter(f => JSON.stringify(f.types) === JSON.stringify(['sublocality_level_1', 'political']))[0];

    if(!suburb2){
      suburb2 = selectedData.data.address_components
        .filter(f => JSON.stringify(f.types) === JSON.stringify(['sublocality_level_1','sublocality', 'political']))[0];
    }
    if(suburb2){
      suburb2= suburb2.short_name;
    }
    let suburbToCheck;
    //   .short_name;

    if(suburb1&&suburb2){
      suburbToCheck=suburb2;
      this.fromSuburbList.push(suburb1.toUpperCase())
      this.fromSuburbList.push(suburb2.toUpperCase())
    }else if(suburb1&&!suburb2){
      suburbToCheck=suburb1;
      this.fromSuburbList.push(suburb1.toUpperCase())
    }else if(!suburb1&&suburb2){
      suburbToCheck=suburb2;
      this.fromSuburbList.push(suburb2.toUpperCase())
    }

    this.pickUpPCode = null;
    this.promptedPickUpPCode = null;
    // New code for getting a suburb based on a postal code

    // Old code for getting postal code and suburb list
    if (!selectedData) {
      this.pickUpNotMsg = "Location not found, Please enter nearest location.";
      this.pickUpNotFound = false;
    } else {
      /*if (selectedData.response == false) {
        this.pickUpPCode = null;
      } else {
        for (let x = 0; x < selectedData.data.address_components.length; x++) {
          for (
            let y = 0;
            y < selectedData.data.address_components[x].types.length;
            y++
          ) {
            for (
              let a = 0;
              a < selectedData.data.address_components[x].types.length;
              a++
            ) {
              if (
                selectedData.data.address_components[x].types[a] == "locality"
              ) {
              } else if (
                selectedData.data.address_components[x].types[a] ==
                "sublocality"
              ) {
                this.newPickUpSuburb =
                  selectedData.data.address_components[x].long_name;
              } else if (
                selectedData.data.address_components[x].types[a] ==
                "postal_code"
              ) {
                this.pickUpPCode =
                  selectedData.data.address_components[x].long_name;
                if (this.pickUpPCode != null) {
                  this.selectPickUpDropDownSuburb(this.pickUpPCode);
                }
              } else if (
                selectedData.data.address_components[x].types[a] ==
                "administrative_area_level_1"
              ) {
                this.pickUpProvince =
                  selectedData.data.address_components[x].long_name;
              }
            }
          }
        }
        if (this.pickUpPCode == null) {
          this.pickUpPostalCodeService(selectedData);
        }
      }*/

      for (let x = 0; x < selectedData.data.address_components.length; x++) {
        for (
          let y = 0;
          y < selectedData.data.address_components[x].types.length;
          y++
        ) {
          for (
            let a = 0;
            a < selectedData.data.address_components[x].types.length;
            a++
          ) {
            if (
              selectedData.data.address_components[x].types[a] == "locality"
            ) {
            } else if (
              selectedData.data.address_components[x].types[a] ==
              "sublocality"
            ) {
              this.newPickUpSuburb =
                selectedData.data.address_components[x].long_name;
            } else if (
              selectedData.data.address_components[x].types[a] ==
              "postal_code"
            ) {
              this.pickUpPCode =
                selectedData.data.address_components[x].long_name;
              if (this.pickUpPCode === '2999') {
                this.pickUpPCode = '0299';
              }
              if (this.pickUpPCode === '3640') {
                this.pickUpPCode = '3610';
              }
              if (this.pickUpPCode != null) {
                this.selectPickUpDropDownSuburb(this.pickUpPCode);
              }
            } else if (
              selectedData.data.address_components[x].types[a] ==
              "administrative_area_level_1"
            ) {
              this.pickUpProvince =
                selectedData.data.address_components[x].long_name;
            }
          }
        }
      }
      if (this.pickUpPCode == null) {
        this.pickUpPostalCodeService(selectedData);
      } else {
        // New code for getting a suburb based on a postal code
        this.pickUpPCodeList.push(this.pickUpPCode);
        // New code for getting a suburb based on a postal code
      }

      this.pickUpAddress = selectedData.data.description;
      this.pickUpCoordinate = [
        selectedData.data.geometry.location.lat,
        selectedData.data.geometry.location.lng,
      ];
      this.pickUpCoordinateLocation = {
        coordinates: this.pickUpCoordinate,
        address: this.pickUpAddress,
      };
      var geocoder = new google.maps.Geocoder();
      this.departureCoordinate = {
        lat: selectedData.data.geometry.location.lat,
        lng: selectedData.data.geometry.location.lng,
      };
      this.geocodeLatLng(
        geocoder,
        selectedData.data.geometry.location.lat,
        selectedData.data.geometry.location.lng
      );
      // Probably used to determine how to start the tracking number [eg: GAU, KNZ, UNK]
      this.getAdminArea(selectedData.data.address_components);
      // Old code for getting postal code and suburb list

      // New code for getting a suburb based on a postal code
      this.surbCheck.fromSuburbList = this.fromSuburbList ;
      this.surbCheck.toSuburbList = this.surbCheck.toSuburbList.length < 1 ? [' '] : this.surbCheck.toSuburbList;
      this.surbCheck.pickUpPCodeList = this.pickUpPCodeList;
      this.surbCheck.dropOffPCodeList = this.surbCheck.dropOffPCodeList.length < 1 ? this.pickUpPCodeList : this.surbCheck.dropOffPCodeList;

      this.usedGoogleAddressPickUp = true;
      this.validateAddress(true);
      // New code for getting a suburb based on a postal code
    }
  }

  resetPickUp(){
    this.fromSuburbList=[];
    this.pickUpPCodeList=[];
  }

  public pickUpPostalCodeService(selectedData) {
    let latTest = selectedData.data.geometry.location.lat;
    let lngTest = selectedData.data.geometry.location.lng;

    let geocoder = new google.maps.Geocoder();
    const latlng = {
      lat: latTest,
      lng: lngTest,
    };
    geocoder.geocode({ location: latlng }, (results) => {
      if (results[0]) {
        let foundAddress = results[0].address_components;
        for (let x = 0; x < foundAddress.length; x++) {
          for (let y = 0; y < foundAddress[x].types.length; y++) {
            for (let a = 0; a < foundAddress[x].types.length; a++) {
              if (foundAddress[x].types[a] == "postal_code") {
                this.pickUpPCode = foundAddress[x].long_name;
                this.pickUpPCodeList.push(this.pickUpPCode)
                this.selectPickUpDropDownSuburb(this.pickUpPCode);
              }
            }
          }
        }

        if (this.pickUpPCode == null) {
          this.promptPostalCode = "Please enter your area postal code below";
          $("#pickUpPostcalCode").modal("show");
        }
      }
    });
  }

  public getPickUpSuburb(code) {
    this.bookService.getPickUpSuburb(code).subscribe(
      (response) => {
        this.fromSuburb = response.suburb;
      },
      (error) => {
        console.log(error);
      },
      () => {}
    );
  }

  clickedPickUppCode() {
    this.pickUpPCode = this.promptedPickUpPCode;
    this.getPickUpSuburb(this.pickUpPCode);
    this.selectPickUpDropDownSuburb(this.pickUpPCode);
  }
  // Pick up Addresses

  // Drop off Addresses
  autoCompleteCallback2(selectedData: any) {

    // New code for getting a suburb based on a postal code
    this.resetDropOff()
    if(!selectedData)return
    let suburb1 = selectedData.data.address_components
      .filter(f => JSON.stringify(f.types) === JSON.stringify(['locality', 'political']))[0];

    if(suburb1){
      suburb1= suburb1.short_name;
    }

    let suburb2 = selectedData.data.address_components
      .filter(f => JSON.stringify(f.types) === JSON.stringify(['sublocality_level_1', 'political']))[0];

    if(!suburb2){
      suburb2 = selectedData.data.address_components
        .filter(f => JSON.stringify(f.types) === JSON.stringify(['sublocality_level_1','sublocality', 'political']))[0];
    }
    if(suburb2){
      suburb2= suburb2.short_name;
    }
    let suburbToCheck;
    //   .short_name;

    if(suburb1&&suburb2){
      suburbToCheck=suburb2;
      this.toSuburbList.push(suburb1.toUpperCase())
      this.toSuburbList.push(suburb2.toUpperCase())
    }else if(suburb1&&!suburb2){
      suburbToCheck=suburb1;
      this.toSuburbList.push(suburb1.toUpperCase())
    }else if(!suburb1&&suburb2){
      suburbToCheck=suburb2;
      this.toSuburbList.push(suburb2.toUpperCase())
    }


    this.dropOffPCode = null;
    this.promptedDropOffPCode = null;
    // New code for getting a suburb based on a postal code

    // Old code for getting postal code and suburb list
    if (!selectedData) {
      this.dropOffNotMsg = "Location not found, Please enter nearest location.";
      this.dropOffNotFound = false;
    } else {
      /*if (selectedData.response == false) {
        this.dropOffPCode = null;
      }*/
      for (let x = 0; x < selectedData.data.address_components.length; x++) {
        for (
          let y = 0;
          y < selectedData.data.address_components[x].types.length;
          y++
        ) {
          for (
            let a = 0;
            a < selectedData.data.address_components[x].types.length;
            a++
          ) {
            if (
              selectedData.data.address_components[x].types[a] == "locality"
            ) {
            } else if (
              selectedData.data.address_components[x].types[a] == "sublocality"
            ) {
              this.newDropOffSuburb =
                selectedData.data.address_components[x].long_name;
            } else if (
              selectedData.data.address_components[x].types[a] == "postal_code"
            ) {
              this.dropOffPCode =
                selectedData.data.address_components[x].long_name;
              if (this.dropOffPCode === '2999') {
                this.dropOffPCode = '0299';
              }
              if (this.pickUpPCode === '3640') {
                this.pickUpPCode = '3610';
              }
              this.selectDropOffDropDownSuburb(this.dropOffPCode);
            } else if (
              selectedData.data.address_components[x].types[a] ==
              "administrative_area_level_1"
            ) {
              this.dropOffProvince =
                selectedData.data.address_components[x].long_name;
            }
          }
        }
      }
      if (this.dropOffPCode == null) {
        this.dropOffPostalCodeService(selectedData);
      } else {
        // New code for getting a suburb based on a postal code
        this.dropOffPCodeList.push(this.dropOffPCode);
        // New code for getting a suburb based on a postal code
      }

      this.dropOffAddress = selectedData.data.description;
      this.dropOffCoordinate = [
        selectedData.data.geometry.location.lat,
        selectedData.data.geometry.location.lng,
      ];
      this.destinationCoordinate = {
        lat: selectedData.data.geometry.location.lat,
        lng: selectedData.data.geometry.location.lng,
      };
      this.dropOffCoordinateLocation = {
        coordinates: this.dropOffCoordinate,
        address: this.dropOffAddress,
      };
      // Old code for getting postal code and suburb list

      // New code for getting a suburb based on a postal code
      this.surbCheck.fromSuburbList = this.surbCheck.fromSuburbList.length < 1 ? [' '] : this.surbCheck.fromSuburbList;
      this.surbCheck.toSuburbList = this.toSuburbList ;
      this.surbCheck.pickUpPCodeList = this.surbCheck.pickUpPCodeList.length < 1 ? this.dropOffPCodeList : this.surbCheck.pickUpPCodeList;
      this.surbCheck.dropOffPCodeList = this.dropOffPCodeList;

      this.usedGoogleAddressDropOff = true;
      this.validateAddress(false);
      // New code for getting a suburb based on a postal code
    }
  }

  resetDropOff(){
    this.toSuburbList=[];
    this.dropOffPCodeList=[];
  }

  public dropOffPostalCodeService(selectedData) {
    let latTest = selectedData.data.geometry.location.lat;
    let lngTest = selectedData.data.geometry.location.lng;

    let geocoder = new google.maps.Geocoder();
    const latlng = {
      lat: latTest,
      lng: lngTest,
    };
    geocoder.geocode({ location: latlng }, (results) => {
      if (results[0]) {
        let foundAddress = results[0].address_components;
        for (let x = 0; x < foundAddress.length; x++) {
          for (let y = 0; y < foundAddress[x].types.length; y++) {
            for (let a = 0; a < foundAddress[x].types.length; a++) {
              if (foundAddress[x].types[a] == "postal_code") {
                this.dropOffPCode = foundAddress[x].long_name;
                this.selectDropOffDropDownSuburb(this.dropOffPCode);
              }
            }
          }
        }

        if (this.dropOffPCode == null) {
          this.promptPostalCode = "Please enter your area postal code below";
          $("#dropOffPostcalCode").modal("show");
        }
      }
    });
  }

  public getDropOffSuburb(code) {
    this.bookService.getDropOffSuburb(code).subscribe(
      (response) => {
        this.toSuburb = response.suburb;
      },
      (error) => {
        console.log(error);
      },
      () => {}
    );
  }

  public clickedDropOffpCode() {
    this.dropOffPCode = this.promptedDropOffPCode;
    this.getDropOffSuburb(this.dropOffPCode);
    this.selectDropOffDropDownSuburb(this.dropOffPCode);
  }
  // Drop off Addresses

  public geocodeLatLng(geocoder, userLat, userLong) {
    var latlng = { lat: userLat, lng: userLong };
    var self = this;
    geocoder.geocode({ location: latlng }, function (results, status) {
      if (status === "OK") {
        var len = results[0].address_components.length;
        var data;
        for (var i = 0; i < len; i++) {
          if (
            results[0].address_components[i].short_name.length === 2 &&
            results[0].address_components[i].long_name.length >= 7
          ) {
            data = results[0].address_components[i];
            self.validateProvince = data.long_name;
            if (data.long_name === "South Africa") {
              self.geocodeLatLngProvince(
                geocoder,
                results[1].address_components[1].long_name
              );
            }
            break;
          }
        }
        self.province = data.long_name;
        self.validateProvince = data.long_name;
        self.province = self.validateProvince;
      } else {
        self.province = "UNKNOWN";
        self.province = self.validateProvince;
      }
    });
  }

  public geocodeLatLngProvince(geocoder, city) {
    var self = this;
    geocoder.geocode({ address: city }, function (results, status) {
      if (status === "OK") {
        var len = results[0].address_components.length;
        var data;
        for (var i = 0; i < len; i++) {
          if (
            results[0].address_components[i].short_name.length === 2 &&
            results[0].address_components[i].long_name.length >= 7
          ) {
            data = results[0].address_components[i];
            self.validateProvince = data.long_name;
            break;
          }
        }
        self.province = data.long_name;
        self.province = self.validateProvince;
      } else {
        self.province = "UNKNOWN";
        self.province = self.validateProvince;
      }
    });
  }

  private getAdminArea(address_component: []) {
    this.addressComponent = address_component;
    let len = address_component.length;
    for (let i = 0; i < len; i++) {
      switch (this.addressComponent[i].long_name) {
        case "Gauteng": {
          this.province = "GAUTENG";
          this.validateProvince = "Gauteng";
          break;
        }
        case "KwaZulu-Natal": {
          this.province = "KWAZULU-NATAL";
          this.validateProvince = "KwaZulu-Natal";
          break;
        }
        case "kzn": {
          this.province = "kzn";
          this.validateProvince = "KWA_ZULU_NATAL";
          break;
        }
        case "Western Cape": {
          this.province = "WESTERN CAPE";
          this.validateProvince = "WESTERN CAPE";
          break;
        }
        case "Eastern Cape": {
          this.province = "EASTERN CAPE";
          this.validateProvince = "EASTERN CAPE";
          break;
        }
        case "Limpopo": {
          this.province = "LIMPOPO";
          this.validateProvince = "LIMPOPO";
          break;
        }
        case "Mpumalanga": {
          this.province = "MPUMALANGA";
          this.validateProvince = "MPUMALANGA";
          break;
        }
        case "Northern Cape": {
          this.province = "NORTHERN CAPE";
          this.validateProvince = "NORTHERN CAPE";
          break;
        }
        case "North West": {
          this.province = "NORTH WEST";
          this.validateProvince = "NORTH WEST";
          break;
        }
        case "Free State": {
          this.province = "FREE STATE";
          this.validateProvince = "FREE STATE";
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  public checkedDropOff(pickUp: any) {
    var object = JSON.parse(localStorage.getItem("userInfo"));
    if (pickUp == "YES") {
      this.disableDropOff = true;
      this.dropContact.name = object.owner.firstName;
      this.dropContact.email = object.owner.email;
      this.dropContact.mobile = object.owner.mobile;
      this.dropContact.surname = object.owner.surname;
    } else {
      this.disableDropOff = false;
      /*this.dropContact.name = "";
      this.dropContact.email = "";
      this.dropContact.mobile = "";
      this.dropContact.surname = "";*/
    }
  }

  // manual address
  public enterPickUpAddress() {
    $("#addressPopUp").modal("show");
  }

  public enterDropOffAddress() {
    $("#addressPopUp2").modal("show");
  }
  // manual address

  public selectedPickUpSuburb(fromSuburb) {
    this.fromSuburb = fromSuburb;
  }

  public selectPickUpDropDownSuburb(postalCode) {
    let myToken = JSON.parse(localStorage.getItem("userInfo")).token;
    if (this.pickUpPCode != null && this.pickUpPCode != undefined) {
      this.bookService.postalDetails(myToken, postalCode).subscribe(
        (response) => {
          this.pickUpExtraDetails = response;
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      this.pickUpExtraDetails = [];
    }
  }

  public getPickUpExtraDetails(postalCode) {
    let myToken = JSON.parse(localStorage.getItem("userInfo")).token;
    this.pickUpPCode = postalCode;
    if (this.pickUpPCode != null || this.pickUpPCode != undefined) {
      this.bookService.postalDetails(myToken, this.pickUpPCode).subscribe(
        (response) => {
          this.pickUpExtraDetails = response;
          if (this.pickUpExtraDetails.length == 0) {
            this.enteredPickUpPostalCodeMsg =
              "Invalid postal code, enter a valid postal code to select a suburb.";
          } else {
            this.enteredPickUpPostalCodeMsg = "";
          }
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      this.pickUpExtraDetails = [];
    }
  }

  public caturedPickpAddress() {
    if (this.enteredPickUpAddress == null || this.enteredPickUpAddress == "") {
      this.enteredPickUpAddressMsg = "Please enter the address";
    } else if (
      this.enteredPickUpPostalCode == null ||
      this.enteredPickUpPostalCode == ""
    ) {
      this.enteredPickUpPostalCodeMsg = "Please enter the postal code.";
    } else if (
      this.enteredPickUpSuburb == null ||
      this.enteredPickUpSuburb == ""
    ) {
      this.enteredPickUpSuburbMsg = "Please enter the suburb";
    } else if (
      this.enteredPickUpProvince == null ||
      this.enteredPickUpProvince == ""
    ) {
      this.enteredPickUpProvinceMsg = "Please enter the province.";
    } else {
      this.disableAddresses = false;
      this.enteredPickUpSuccessMsg = "Address successfully captured.";
      this.fromSuburb = this.enteredPickUpSuburb;
      this.pickUpPCode = this.enteredPickUpPostalCode;
      this.pickUpProvince = this.enteredPickUpProvince;
      this.province = this.enteredPickUpProvince;
      this.pickUpCoordinate = ["-25.98953", "28.12843"];
      this.pickUpAddress = this.enteredPickUpAddress
        .concat(", " + this.fromSuburb)
        .concat(", " + this.pickUpPCode)
        .concat(", " + this.province);
      this.pickUpCoordinateLocation = {
        coordinates: this.pickUpCoordinate,
        address: this.pickUpAddress,
      };

      // Clearing error messages
      this.enteredPickUpAddressMsg = "";
      this.enteredPickUpSuburbMsg = "";
      this.enteredPickUpPostalCodeMsg = "";
      this.enteredPickUpProvinceMsg = "";
    }
  }

  public closeCapturedDropOffAddress() {
    this.enteredDropOffAddressMsg = "";
    this.enteredDropOffSuburbMsg = "";
    this.enteredDropOffPostalCodeMsg = "";
    this.enteredDropOffProvinceMsg = "";
  }

  public selectedDropOffSuburb(toSuburb) {
    this.toSuburb = toSuburb;
  }

  public selectDropOffDropDownSuburb(postalCode) {
    let myToken = JSON.parse(localStorage.getItem("userInfo")).token;
    if (this.dropOffPCode != null && this.dropOffPCode != undefined) {
      this.bookService.postalDetails(myToken, postalCode).subscribe(
        (response) => {
          this.dropOffExtraDetails = response;
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      this.dropOffExtraDetails = [];
    }
  }

  public getDropOffExtraDetails(quote) {
    let myToken = JSON.parse(localStorage.getItem("userInfo")).token;
    this.dropOffPCode = quote;
    if (
      (this.dropOffPCode != null || this.dropOffPCode != undefined) &&
      this.dropOffPCode.toString().length == 4
    ) {
      this.bookService.postalDetails(myToken, this.dropOffPCode).subscribe(
        (response) => {
          this.dropOffExtraDetails = response;
          if (this.dropOffExtraDetails.length == 0) {
            this.enteredDropOffPostalCodeMsg =
              "Invalid postal code, enter a valid postal code to select a suburb.";
          } else {
            this.enteredDropOffPostalCodeMsg = "";
          }
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      this.dropOffExtraDetails = [];
    }
  }

  public capturedDropOffAddress() {
    if (
      this.enteredDropOffAddress == null ||
      this.enteredDropOffAddress == ""
    ) {
      this.enteredDropOffAddressMsg = "Please enter the address";
    } else if (
      this.enteredDropOffPostalCode == null ||
      this.enteredDropOffPostalCode == ""
    ) {
      this.enteredDropOffPostalCodeMsg = "Please enter the postal code";
    } else if (
      this.enteredDropOffSuburb == null ||
      this.enteredDropOffSuburb == ""
    ) {
      this.enteredDropOffSuburbMsg = "Please enter the suburb";
    } else if (
      this.enteredDropOffProvince == null ||
      this.enteredDropOffProvince == ""
    ) {
      this.enteredDropOffProvinceMsg = "Please enter the province";
    } else {
      this.disableDropOffAddress = false;
      this.enteredDropOffSuccessMsg = "Address successfully captured.";
      this.toSuburb = this.enteredDropOffSuburb;
      this.dropOffPCode = this.enteredDropOffPostalCode;
      this.dropOffProvince = this.enteredDropOffProvince;
      this.dropOffAddress = this.enteredDropOffAddress
        .concat(", " + this.toSuburb)
        .concat(", " + this.dropOffPCode)
        .concat(", " + this.dropOffProvince);
      this.dropOffCoordinate = ["-25.97521", "28.13954"];
      this.dropOffCoordinateLocation = {
        coordinates: this.dropOffCoordinate,
        address: this.dropOffAddress,
      };

      //Clear error messages
      this.enteredDropOffAddressMsg = "";
      this.enteredDropOffSuburbMsg = "";
      this.enteredDropOffPostalCodeMsg = "";
      this.enteredDropOffProvinceMsg = "";
    }
  }

  public closeCaturedPickpAddress() {
    this.enteredPickUpAddressMsg = "";
    this.enteredPickUpSuburbMsg = "";
    this.enteredPickUpPostalCodeMsg = "";
    this.enteredPickUpProvinceMsg = "";
  }

  validAddress:any={fromSuburb:null,pickUpPCode:null,toSuburb:null,dropOffPCode:null};

  validateAddress(pickUp: boolean) {
    if (!(this.usedGoogleAddressPickUp || this.usedGoogleAddressDropOff)) {
      this.validAddress.fromSuburb = this.enteredPickUpSuburb;
      this.validAddress.pickUpPCode = this.enteredPickUpPostalCode.trim();
      this.validAddress.toSuburb = this.enteredDropOffSuburb;
      this.validAddress.dropOffPCode = this.enteredDropOffPostalCode.trim();
      // localStorage.setItem('validAddress', JSON.stringify(this.validAddress));
      return;
    }
    this.loadservice.addloader();
    this.bookService.validateAddress(this.surbCheck).subscribe(
      response => {
        this.loadservice.removeloader();
        const r = response.json();
        if (pickUp && r.fromSuburbList && r.fromSuburbList.length > 0) {
          this.validAddress.fromSuburb = r.fromSuburbList[r.fromSuburbList.length - 1];
          this.validAddress.pickUpPCode = r.pickUpPCodeList[0];
          this.fromSuburb = this.validAddress.fromSuburb;
          this.surbCheck.fromSuburbList = [this.fromSuburb];
          this.surbCheck.pickUpPCodeList = [this.validAddress.pickUpPCode];
        }
        if (!pickUp && r.toSuburbList && r.toSuburbList.length > 0) {
          this.validAddress.toSuburb = r.toSuburbList[r.toSuburbList.length - 1];
          this.validAddress.dropOffPCode = r.dropOffPCodeList[0];
          this.toSuburb = this.validAddress.toSuburb;
          this.surbCheck.toSuburbList = [this.toSuburb];
          this.surbCheck.dropOffPCodeList = [this.validAddress.dropOffPCode];
        }
      }, error => {
        this.loadservice.removeloader();
        pickUp ? this.fromSuburb = undefined : this.toSuburb = undefined;
        pickUp ? this.surbCheck.fromSuburbList = [] : this.surbCheck.toSuburbList = [];
        pickUp ? this.surbCheck.pickUpPCodeList = [] : this.surbCheck.dropOffPCodeList = [];
        console.log(error);

      }, () => {
        this.loadservice.removeloader();
      }
    );
  }

  public selectedservicetype(type:any){
    // console.log(type)
    if(type==="SAME_DAY"){
      this.flashSelectedOption();
    }else if(type==="MULITPLE_DROPS"){
      this.multipleDrops();
    }
  }


  public flashSelectedOption() {
    this.resetPickedImages();
    this.controlPickedSelection();
    this.isExpress_ = true;
    this.isRoadPicked = true;
    this.isExpress = true;
    this.selectedOption = "SAME_DAY";
    this.pracelDims = [];
    $(".flashHighlight").addClass("expressChoices_");
    $(".flashHighlight").removeClass("expressChoices");
  }

  public multipleDrops() {
    this.resetPickedImages();
    this.controlPickedSelection();
    this.isExpress_ = true;
    this.isExpress = true;
    this.isCourierPicked = true;
    this.selectedOption = "MULITPLE_DROPS";
    this.pracelDims = [];
    $(".multipleDropsHighlight").addClass("expressChoices_");
    $(".multipleDropsHighlight").removeClass("expressChoices");
  }

  public fleetSelectedOption() {
    this.resetPickedImages();
    this.controlPickedSelection();
    this.isExpress_ = false;
    this.isExpress = false;
    this.isFleetPicked = true;
    this.selectedOption = "FLEET_OPTION";
    this.pracelDims = [];
    $(".fleetHighlight").addClass("expressChoices_");
    $(".fleetHighlight").removeClass("expressChoices");
  }
  // service displayed

  public controlPickedSelection() {
    $(".flashHighlight")
      .addClass("expressChoices")
      .removeClass("expressChoices_");
    $(".multipleDropsHighlight")
      .addClass("expressChoices")
      .removeClass("expressChoices_");
    $(".fleetHighlight")
      .addClass("expressChoices")
      .removeClass("expressChoices_");
  }

  public resetPickedImages() {
    this.isRoadPicked = false;
    this.isCourierPicked = false;
    this.isFleetPicked = false;
  }

  // Dims
  public enterDimensions() {
    this.fleetCanAddParcelError = '';
    $("#enterDimensions").modal("show");
  }

  numberOfPracels = [];
  exceededParcelsMsg: string = "";
  selectedCourierBox = '';

  public getSizes(size) {
    // console.log("sameMassParcels", this.sameMassParcels);

    this.selectedCourierBox = size;

    if (size === "envelope") {
      this.parcel_mass = 0.1;
      this.parcel_breadth = 11;
      this.parcel_height = 0.1;
      this.parcel_length = 22;
    } else if (size === "textBook") {
      this.parcel_mass = 0.2;
      this.parcel_breadth = 24;
      this.parcel_height = 1;
      this.parcel_length = 32;
    } else if (size === "shoeBox") {
      this.parcel_length = 35;
      this.parcel_breadth = 20;
      this.parcel_height = 15;
      this.parcel_mass = 9;
    } else if (size === "moveBox") {
      this.parcel_length = 32;
      this.parcel_breadth = 24;
      this.parcel_height = 25;
      this.parcel_mass = 12;
    }
  }

  public addDimensions() {
    this.checkingMsg = '';
    this.massMsg = '';
    this.breadthMsg = '';
    this.heightMsg = '';
    this.lengthMsg = '';
    if (this.parcel_mass == null || this.parcel_mass == "") {
      this.massMsg = "Please enter the mass.";
    } else if (this.parcel_breadth == null || this.parcel_breadth == "") {
      this.breadthMsg = "Please enter the breadth.";
    } else if (this.parcel_height == null || this.parcel_height == "") {
      this.heightMsg = "Please enter the height.";
    } else if (this.parcel_length == null || this.parcel_length == "") {
      this.lengthMsg = "Please enter the length.";
    } else if (this.parcel_mass > 70) {
      this.massMsg = "The max for mass is 70KG with courier service.";
    } else if (this.parcel_breadth > 150) {
      this.breadthMsg = "The max for breadth is 150cm with courier service.";
    } else if (this.parcel_height > 150) {
      this.heightMsg = "The max for height is 150cm with courier service.";
    } else if (this.parcel_length > 300) {
      this.lengthMsg = "The max for length is 300cm with courier service.";
    } else if (this.parcel_mass == 0) {
      this.massMsg = "The mass can not be 0.";
    } else if (this.parcel_breadth == 0) {
      this.breadthMsg = "The breadth can not be 0.";
    } else if (this.parcel_height == 0) {
      this.heightMsg = "The mass height not be 0.";
    } else if (this.parcel_length == 0) {
      this.lengthMsg = "The mass length not be 0.";
    } else if (this.parcel_mass < 0) {
      this.massMsg = "The mass can not be a negative number.";
    } else if (this.parcel_breadth < 0) {
      this.breadthMsg = "The breadth can not be a negative number.";
    } else if (this.parcel_height < 0) {
      this.heightMsg = "The mass height can not be a negative number.";
    } else if (this.parcel_length < 0) {
      this.lengthMsg = "The mass length can not be a negative number.";
    } else if (
      this.pracelDims.length >= 30 &&
      (this.selectedOption == "MULITPLE_DROPS" ||
        this.selectedOption == "SAME_DAY")
    ) {
      this.checkingMsg = "You can only add 30 parcels.";
    } else {
      // Adding parcels if # of dims are not the same
      // adding dims for skynet and to get quote
      let courierObj = {
        parcel_length: this.parcel_length,
        parcel_breadth: this.parcel_breadth,
        parcel_height: this.parcel_height,
        parcel_mass: this.parcel_mass,
      };
      this.pracelDims.push(courierObj);
      this.missingTextMsg = "";
      this.massMsg = "";
      this.breadthMsg = "";
      this.heightMsg = "";
      this.lengthMsg = "";

      this.parcel_length = "";
      this.parcel_height = "";
      this.parcel_mass = "";
      this.parcel_breadth = "";
      this.numOfBoxes = "";
      this.addTotalMassOfDims();
    }
  }

  public addDoneDimensions() {
    if (this.parcel_length !== undefined && this.parcel_height !== undefined && this.parcel_mass !== undefined && this.parcel_breadth !== undefined &&
      this.parcel_length !== '' && this.parcel_height !== '' && this.parcel_mass !== '' && this.parcel_breadth !== '') {
      this.addDimensions();
    }
  }

  // Adding parcels with the same # of dims
  public addDifferentDimensions() {
    this.checkingMsg = '';
    this.massMsg = '';
    this.breadthMsg = '';
    this.heightMsg = '';
    this.lengthMsg = '';
    this.numberOfPracels = new Array(this.numOfParcels);
    if (this.parcel_mass == null || this.parcel_mass == "") {
      this.massMsg = "Please enter the mass.";
    } else if (this.parcel_breadth == null || this.parcel_breadth == "") {
      this.breadthMsg = "Please enter the breadth.";
    } else if (this.parcel_height == null || this.parcel_height == "") {
      this.heightMsg = "Please enter the height.";
    } else if (this.parcel_length == null || this.parcel_length == "") {
      this.lengthMsg = "Please enter the length.";
    } else if (this.parcel_mass > 70) {
      this.massMsg = "The max for mass is 70KG with courier service.";
    } else if (this.parcel_breadth > 150) {
      this.breadthMsg = "The max for breadth is 150cm with courier service.";
    } else if (this.parcel_height > 150) {
      this.heightMsg = "The max for height is 150cm with courier service.";
    } else if (this.parcel_length > 300) {
      this.lengthMsg = "The max for length is 300cm with courier service.";
    } else if (this.parcel_mass == 0) {
      this.massMsg = "The mass can not be 0.";
    } else if (this.parcel_breadth == 0) {
      this.breadthMsg = "The breadth can not be 0.";
    } else if (this.parcel_height == 0) {
      this.heightMsg = "The mass height not be 0.";
    } else if (this.parcel_length == 0) {
      this.lengthMsg = "The mass length not be 0.";
    } else if (this.parcel_mass < 0) {
      this.massMsg = "The mass can not be a negative number.";
    } else if (this.parcel_breadth < 0) {
      this.breadthMsg = "The breadth can not be a negative number.";
    } else if (this.parcel_height < 0) {
      this.heightMsg = "The mass height can not be a negative number.";
    } else if (this.parcel_length < 0) {
      this.lengthMsg = "The mass length can not be a negative number.";
    } else if (
      this.pracelDims.length >= 30 &&
      (this.selectedOption == "MULITPLE_DROPS" ||
        this.selectedOption == "SAME_DAY")
    ) {
      this.checkingMsg = "You can only add 30 parcels.";
    } else if (parseInt(this.numOfParcels) > 150) {
      this.exceededParcelsMsg = "You may only process 150 parcels.";
    } else {
      for (let x = 0; x < this.numberOfPracels.length; x++) {
        let courierObj = {
          parcel_length: this.parcel_length,
          parcel_breadth: this.parcel_breadth,
          parcel_height: this.parcel_height,
          parcel_mass: this.parcel_mass,
        };
        this.pracelDims.push(courierObj);
      }
      this.addTotalMassOfDims();
      this.parcel_length = "";
      this.parcel_height = "";
      this.parcel_mass = "";
      this.parcel_breadth = "";
      this.numOfParcels = "";
      $("#enterDimensions").modal("hide");
    }
  }

  public removeDimensions(item) {
    this.pracelDims.splice(item, 1);
  }

  public addTotalMassOfDims() {
    var totalParcelMass = 0;
    this.pracelDims.forEach((element) => {
      totalParcelMass += element.parcel_mass;
    });
    this.totalMass = totalParcelMass;
  }

  public modelBreadthChanged(parcel_breadth) {
    if (this.selectedOption != "FLEET_OPTION") {
      if (parcel_breadth > 150) {
        this.breadthMsg = "Width for parcel cannot exceed 150";
      } else if (parcel_breadth == 0) {
        this.breadthMsg = "Width cannot be 0";
      } else if (parcel_breadth < 0) {
        this.breadthMsg = "Width cannot be a negative number";
      } else if (parcel_breadth == null) {
        this.breadthMsg = "Width cannot be empty";
      } else if (parcel_breadth < 150) {
        this.breadthMsg = " ";
      }
    }
  }

  public modelMassChanged(parcel_mass) {
    if (this.selectedOption != "FLEET_OPTION") {
      if (parcel_mass > 70) {
        this.massMsg = "Mass cannot exceed 70";
      } else if (parcel_mass == 0) {
        this.massMsg = "Mass cannot be 0";
      } else if (parcel_mass < 0) {
        this.massMsg = "Mass cannot be a negative number";
      } else if (parcel_mass == null) {
        this.massMsg = "Mass cannot be empty";
      } else if (parcel_mass < 70) {
        this.massMsg = " ";
      }
    }
  }

  public modelHeightChanged(parcel_height) {
    if (this.selectedOption != "FLEET_OPTION") {
      if (parcel_height > 150) {
        this.heightMsg = "Height for parcel cannot exceed 150";
      } else if (parcel_height == 0) {
        this.heightMsg = "Height cannot be 0";
      } else if (parcel_height < 0) {
        this.heightMsg = "Height cannot be a negative number";
      } else if (parcel_height == null) {
        this.heightMsg = "Height cannot be empty";
      } else if (parcel_height < 150) {
        this.heightMsg = " ";
      }
    }
  }

  public modelLengthChanged(parcel_length) {
    this.lengthMsg = '';
    if (this.selectedOption != "FLEET_OPTION") {
      if (parcel_length > 300) {
        this.lengthMsg = "Length for parcel cannot exceed 300cm";
      } else if (parcel_length == 0) {
        this.lengthMsg = "Length cannot be 0";
      } else if (parcel_length < 0) {
        this.lengthMsg = "Length cannot be a negative number";
      } else if (parcel_length == null) {
        this.lengthMsg = "Length cannot be empty";
      } else if (parcel_length < 150) {
        this.lengthMsg = " ";
      }
    }
  }

  public modelParcelExceedsChanged(numOfParcels) {
    if (numOfParcels > 150) {
      this.exceededParcelsMsg = "Number of parcels cannot exceed 150.";
    } else if (numOfParcels == 0) {
      this.exceededParcelsMsg = "Parcels cannot be 0.";
    } else if (numOfParcels < 0) {
      this.exceededParcelsMsg = "Parcels cannot be a negative number.";
    } else if (numOfParcels == null) {
      this.exceededParcelsMsg = "Parcels cannot be empty.";
    } else if (numOfParcels < 150) {
      this.exceededParcelsMsg = " ";
    }
  }

  public checkDimensionValidation(): Boolean {
    let check = false;
    for (let a = 0; a <= this.pracelDims.length; a++) {
      if (
        this.pracelDims[a].parcel_breadth == null ||
        this.pracelDims[a].parcel_height == null ||
        this.pracelDims[a].parcel_length == null ||
        this.pracelDims[a].parcel_mass == null
      ) {
        this.checkingMsg =
          "Please make sure that all the parcel details are filled in before proceeding.";
        $("#validationModal").modal("show");
        check = false;
        break;
      } else if (
        this.pracelDims[a].parcel_breadth == 0 ||
        this.pracelDims[a].parcel_height == 0 ||
        this.pracelDims[a].parcel_length == 0 ||
        this.pracelDims[a].parcel_mass == 0
      ) {
        this.checkingMsg =
          "Please make sure that none of the dimensions entered are 0.";
        $("#validationModal").modal("show");
        check = false;
        break;
      } else if (
        this.pracelDims[a].parcel_breadth > 150 ||
        this.pracelDims[a].parcel_height > 150 ||
        this.pracelDims[a].parcel_length > 150 ||
        this.pracelDims[a].parcel_mass > 150
      ) {
        this.checkingMsg =
          "Please make sure that none of the dimensions entered are greater than 150.";
        $("#validationModal").modal("show");
        check = false;
        break;
      } else if (
        this.pracelDims[a].parcel_breadth < 0 ||
        this.pracelDims[a].parcel_height < 0 ||
        this.pracelDims[a].parcel_length < 0 ||
        this.pracelDims[a].parcel_mass < 0
      ) {
        this.checkingMsg =
          "Please make sure that none of the dimensions entered are a negative number.";
        $("#validationModal").modal("show");
        check = false;
        break;
      } else {
        check = true;
      }
    }
    return check;
  }
  //Dims
//
  //Selected service
  public sameDaySelected() {
    let validated=this.validator();
    if (validated) {
      var d = new Date();
      var hours = d.getHours();
      // if (this.validateProvince === "KwaZulu-Natal" || this.validateProvince === "kzn" || this.validateProvince === "Western Cape" || this.validateProvince === "Gauteng") {
      if (this.termsControl) {
        if (this.pracelDims.length != 0) {
          this.disableAddresses = false;
          this.bringFlashPrices = false;
          this.disableDropOffAddress = false;
          var distanceResponse;
          var data = {
            destination: this.destinationCoordinate,
            departure: this.departureCoordinate,
          };
          this.bookService.getDistance(data).subscribe(
            (response) => {
              distanceResponse = response.json();
              this.distance = distanceResponse.rows[0].elements[0].distance.inMeters;
              this.distance = Math.round(this.distance / 1000);
              this.getSameDayRates();
            },
            (error) => {
              console.log(error);
            },
            () => {
            }
          );
        } else {
          alert("Please add dimensions before getting a quote.");
        }
      } else {
        alert(
          "Please click the checkbox next to the terms & conditions to proceed with the booking."
        );
      }
      // }
      // else {
      //   alert("Please note that SAME DAY service is only available in GAUTENG, WESTERN PROVINCE & KWAZULU-NATAL.");
      // }
    }
    else {
      $("#incompleteFieldModal").modal("show");
    }
  }

  public courierSelected() {
    // console.log(this.fromSuburb)
    // console.log(this.toSuburb)
    let validated=this.validator();
    if (validated) {
      if (this.fromSuburb != null && this.toSuburb != null) {
        if (this.termsControl) {
          if (this.pracelDims.length != 0) {
            this.disableAddresses = false;
            this.disableDropOffAddress = false;
            this.bringMultipleDrop = false;
            this.getCourierRates();
            this.getBookingDates();
          } else {
            alert("Please add dimensions before getting a quote.");
          }
        } else {
          alert(
            "Please click the checkbox next to the terms & conditions to proceed with the booking."
          );
        }
      } else {
        alert("Please select the pick up and drop off suburbs to proceed.");
      }
    } else {
      $("#incompleteFieldModal").modal("show");
    }
  }

  public fleetSelected() {
    if (this.pickUpAddress != null && this.dropOffAddress != null) {
      if (
        this.validateProvince === "KwaZulu-Natal" ||
        this.validateProvince === "kzn" ||
        this.validateProvince === "Western Cape" ||
        this.validateProvince === "Gauteng"
      ) {
        this.bringFleetPrices = false;
        this.disableAddresses = false;
        this.disableDropOffAddress = false;
        this.tempAmount = 0;
        this.price = 0;
      } else {
        this.provinceMessage =
          "Droppa Fleet is currently unavailable in " +
          this.pickUpProvince +
          ". Please use COURIER for your deliveries.";
        $("#provinceValidation").modal("show");
      }
    } else {
      this.hourMsg = "Please enter the pickup and drop off address.";
      $("#hourAheadValidation").modal("show");
    }
  }
  //Selected service

  public backToBookingDetails() {
    this.bringFlashPrices = true;
    this.bringMultipleDrop = true;
    this.disableAddresses = true;
    this.disableDropOffAddress = true;
  }

  isBike: false;
  mainCityOid: any = null;
  // Rates
  public getSameDayRates() {
    var quoteBody = {
      mass: this.totalMass,
      distance: this.distance,
      fromSuburb: this.fromSuburb,
      toSuburb: this.toSuburb,
      pickUpAddress: this.pickUpAddress,
      dropOffAddress: this.dropOffAddress,
      pickUpProvince: this.pickUpProvince,
      dropOffProvince: this.dropOffProvince,
      pickUpPCode: this.pickUpPCode,
      dropOffPCode: this.dropOffPCode,
      parcelDimensions: this.pracelDims,
      platform: "web",
      pickUpCoordinate:this.pickUpCoordinate
    };
    // console.log(quoteBody, "check");
    this.loadservice.addloader();
    this.bookService.getQuote(quoteBody).subscribe(
      (response) => {
        // console.log("response", response.json());
        this.quoteResponse = response.json();
        this.flashFlight = Math.round(response.json().totalFlight);
        this.airlineAmt = response.json().totalFlight;
        this.pickUpAmt = response.json().flashFlightFromPickUp;
        this.dropOffAmt = response.json().flashFlightToDropff;
        this.flashRoad = Math.round(response.json().flashRoad);
        this.sheshaMode = Math.round(response.json().sheshaAmount);
        this.isBike = response.json().isBike;
        this.mainCityOid = response.json().mainCityOid
        localStorage.setItem('isBike',String(this.isBike));
        localStorage.setItem('mainCityOid',String(this.mainCityOid));

         /*if (this.distance > 30) {
           this.sheshaMode = 150;
         }

         if (this.distance > 75) {
           this.sheshaMode = 0;
         } else
        if (
          this.validateProvince != "Gauteng" &&
          this.dropOffProvince != "Gauteng"
        ) {
          // this.sheshaMode = 0;
          this.onFlashRoadhSelected();
        }

        if (
          this.validateProvince === "Western Cape" ||
          this.validateProvince === "KwaZulu-Natal" ||
          this.validateProvince === "kzn"
        ) {
          // this.sheshaMode = 0;
          this.onFlashRoadhSelected();//remove
        }

        if (
          this.validateProvince === "KwaZulu-Natal" ||
          this.validateProvince === "kzn" ||
          this.validateProvince === "Western Cape" ||
          this.validateProvince === "Gauteng"
        ) {
          if (this.flashFlight == 0) {
            this.flightAvailable = false;
            this.flashRoadAvailable = true;//remove
          } else {
            var d = new Date();
            var hours = d.getHours();//remove
            if (hours < 12) {
              this.flightAvailable = true;
              this.flashRoadAvailable = true;
            } else {
              this.flashFlight = 0;
              this.flightAvailable = false;
              this.flashRoadAvailable = true;
            }
          }

          if (this.mass > 15) {
            this.sheshaAvailable = false;
            this.onFlashRoadhSelected();//remove
            // this.sheshaMode = 0;
          } else {
            this.onDashSelected();
          }

          if (this.sheshaMode == 0) {
            if (this.flightAvailable) {
              this.onFlashFlightSelected();
            } else {
              this.onFlashRoadhSelected();
            }
          } else {
            this.onDashSelected();
            this.dashAvailable = true;
          }
          this.loadservice.removeloader();
        } else {
          this.loadservice.removeloader();
        }*/
        this.loadservice.removeloader();
      },
      (error) => {
        console.log(error);
        this.loadservice.removeloader();
      }
    );
  }

  public getCourierRates() {
    var quoteBody = {
      mass: this.totalMass,
      distance: 1,
      fromSuburb: this.fromSuburb,
      toSuburb: this.toSuburb,
      pickUpAddress: this.pickUpAddress,
      dropOffAddress: this.dropOffAddress,
      pickUpProvince: this.pickUpProvince,
      dropOffProvince: this.dropOffProvince,
      pickUpPCode: this.pickUpPCode,
      dropOffPCode: this.dropOffPCode,
      parcelDimensions: this.pracelDims,
      platform: "web",
    };
    this.loadservice.addloader();
    this.bookService.getNewSkynetPrices(quoteBody).subscribe(
      (response) => {
        this.quoteResponse = response.json();

        if (response.json().expressCourier > 0) {
          this.loadservice.removeloader();
          this.isCourierExpress = false;
          this.expressCourier = Math.round(response.json().expressCourier);
          this.tempAmount = this.expressCourier;
          this.onExpressCourierSelected();
        }

        if (response.json().expressBudgetAmt > 0) {
          this.loadservice.removeloader();
          this.isExpressBudget = false;
          this.budgetCourier = Math.round(response.json().expressBudgetAmt);
        }

        if (response.json().tenderDocAmount > 0) {
          this.loadservice.removeloader();
          this.tenderDocument = Math.round(response.json().tenderDocAmount);
        }
      },
      (error) => {
        this.loadservice.removeloader();
        console.log(error);
      },
      () => {}
    );
  }

  public getNormalDroppaRates(vehicleType) {
    var object = JSON.parse(localStorage.getItem("userInfo"));
    if (this.pickUpAddress == null && this.dropOffAddress == null) {
      this.checkingMsg = "Please enter the addresses 1st before proceeding.";
      $("#validationModal").modal("show");
      return;
    }
    if (this.contact.name == null && this.contact.mobile == null) {
      this.checkingMsg =
        "Please enter in your pick up details before proceeding.";
      $("#validationModal").modal("show");
      return;
    }
    if (
      (this.dropContact.name === null && this.dropContact.mobile === null) ||
      (this.dropContact.name === undefined &&
        this.dropContact.mobile === undefined) ||
      (this.dropContact.name === "" && this.dropContact.mobile === "")
    ) {
      this.checkingMsg =
        "Please enter in your drop off details before proceeding.";
      $("#validationModal").modal("show");
      return;
    }

    this.vehicleType=vehicleType;

    var data = {
      destination: this.destinationCoordinate,
      departure: this.departureCoordinate,
    };
    this.loadservice.addloader();
    this.bookService.getDistance(data).subscribe((response) => {
      var distanceResponse = response.json();
      this.distance = distanceResponse.rows[0].elements[0].distance.inMeters;
      this.distance = Math.round(this.distance / 1000);
      this.bookService
        .getRetailVehicleRate(this.retail.oid, vehicleType)
        .subscribe(
          (res: Response) => {
            var bookingData = {
              distance: this.distance,
              retailOid: this.retail.oid,
              totalPrice: 0,
              vehicleType: vehicleType,
            };

            this.loadservice.removeloader();
            this.bookService
              .getRetailbookingPrice(bookingData, object.token)
              .subscribe(
                (response: Response) => {
                  this.bookingPrice = response.json();
                  this.tempAmount = Math.round(this.bookingPrice.totalPrice);
                  this.price = Math.round(this.bookingPrice.totalPrice);

                  this.loadservice.removeloader();
                  window.scrollTo(0, 0);
                },
                (err) => {
                  console.log(err);
                  this.loadservice.removeloader();
                }
              );
          },
          (err) => {
            console.log(err);
            this.bookingText = err.json().message;
            $("#results3").modal("show");
            this.loadservice.removeloader();
          }
        );
    }),
      (error) => {
        console.log(error);
        this.loadservice.removeloader();
      };
  }
  // Rates

  //Delivery date
  public getBookingDates() {
    this.bookService
      .getBookingDate(this.pickUpPCode, this.dropOffPCode)
      .subscribe(
        (response) => {
          if (response.pickUpDate != null) {
            this.dateObj = response;
            this.displayNewDate = this.dateObj.pickUpDate;
            this.workingDays = this.dateObj.workingDays;
          } else {
            var tate = new Date();
            var datePipe = new DatePipe("en-US");
            tate.setDate(tate.getDate());
            var dateObject = {
              workingDays: [],
              pickUpDate: datePipe.transform(tate, "yyyy-MM-dd"),
              dropOffSuburb: this.toSuburb,
              pickUpSuburb: this.fromSuburb,
            };
            this.dateObj = dateObject;
          }
        },
        (error) => {
          var tate = new Date();
          var datePipe = new DatePipe("en-US");
          tate.setDate(tate.getDate());
          var dateObject = {
            workingDays: [],
            pickUpDate: datePipe.transform(tate, "yyyy-MM-dd"),
            dropOffSuburb: this.toSuburb,
            pickUpSuburb: this.fromSuburb,
          };
          this.dateObj = dateObject;
          // console.log(error);
        },
        () => {}
      );
  }

  public checkingDeliveryDates(theDateFound: Date): boolean {
    var datePipe = new DatePipe("en-US");
    let valid = false;
    const pickUpDate = moment(theDateFound).toDate();
    let day = moment(pickUpDate).format("dddd");
    if (pickUpDate.getDay() == 6 || pickUpDate.getDay() == 0) {
      const foundDisplayDate = new Date(this.displayNewDate);
      if (foundDisplayDate > pickUpDate) {
        this.bookingDate = datePipe.transform(foundDisplayDate, "yyy-MM-dd");
        valid = true;
      }
      this.dateMessage =
        "Please note that Courier and Budget is not available on weekend.";
      valid = false;
    } else if (!this.workingDays.includes(day)) {
      var message = "";
      for (let element of this.workingDays) {
        message = message + "" + element + ", ";
      }
      this.dateMessage =
        "There is no pick up on " +
        day +
        " at " +
        this.fromSuburb +
        ", available " +
        message;
      valid = false;
    } else {
      this.bookingDate = datePipe.transform(pickUpDate, "yyyy-MM-dd");
      valid = true;
    }

    return valid;
  }

  public getBookingDate(pickUpDate: string): string {
    var currentDate = new Date(pickUpDate);
    let date =
      currentDate.getDate() <= 9
        ? "0" + currentDate.getDate()
        : currentDate.getDate();
    let monthNo =
      currentDate.getMonth() + 1 <= 9
        ? "0" + (currentDate.getMonth() + 1)
        : currentDate.getMonth() + 1;
    let hour =
      currentDate.getHours() <= 9
        ? "0" + currentDate.getHours()
        : currentDate.getHours();
    let minutes =
      currentDate.getMinutes() <= 9
        ? "0" + currentDate.getMinutes()
        : currentDate.getMinutes();
    var second =
      currentDate.getSeconds() <= 9
        ? "0" + currentDate.getSeconds()
        : currentDate.getSeconds();
    return (
      currentDate.getFullYear() +
      "-" +
      monthNo +
      "-" +
      date +
      "T" +
      hour +
      ":" +
      minutes +
      ":" +
      second
    );
  }
  //Delivery date

  // create booking
  public createSameDayBooking() {
    if(this.tempAmount===0){
      this.loadservice.removeloader();
      this.bookingText = "Can't Make booking of amount R0.00";
      $("#results3").modal("show");
      return
    }
    if (this.distance > 75 && this.type == "DASH") {
      alert("DASH service is only available within a 75KM radius.");
    } else {
      var object = JSON.parse(localStorage.getItem("userInfo"));
      var datePipe = new DatePipe("en-US");
      var timestamp = new Date().valueOf();
      var currentDate = new Date();
      let value = null;
      var timestamp = new Date().valueOf();
      var datePipe = new DatePipe("en-US");
      let valueTime = null;

      value = datePipe.transform(currentDate, "yyyy-MM-dd");
      valueTime = datePipe.transform(currentDate, "HH:mm a");

      var bookingData = {
        dropOff: {
          firstName: this.dropContact.name,
          lastName:
            this.dropContact.surname != null
              ? this.dropContact.surname
              : object.owner.surname,
          phone: this.dropContact.mobile,
          email: object.owner.email,
          companyName:
            this.companyDropOffName != null
              ? this.companyDropOffName
              : object.owner.firstName,
          complex: this.complexDropOff != null ? this.complexDropOff : null,
          unitNo: this.unitDropOff != null ? this.unitDropOff : null,
        },
        pickUp: {
          firstName: this.contact.name,
          lastName:
            this.contact.surname != null
              ? this.contact.surname
              : object.owner.surname,
          phone: this.contact.mobile,
          email: this.contact.email,
          companyName:
            this.companyPickUpName != null
              ? this.companyPickUpName
              : object.owner.firstName,
          complex: this.complexPickUp != null ? this.complexPickUp : null,
          unitNo: this.unitPickUp != null ? this.unitPickUp : null,
        },
        customer: object.owner,
        price: this.tempAmount,
        // vehicleType: this.isBike ? 'MOTO_BIKE' : this.vehicleType,
        vehicleType:  this.vehicleType,
        pickUpAddress: this.pickUpAddress,
        pickUpTime: valueTime,
        phoneNo: object.owner.mobile,
        pickUpDate: value,
        dropOffAddress: this.dropOffAddress,
        comment: this.comment,
        labour: this.contact.labours,
        timestamp: timestamp,
        status: this.isBike ? 'DASH' : 'BUCKET',
        dropFloors: this.dropOffFloors,
        pickUpFloors: this.floorsPickUp,
        pickUpCoordinate: this.pickUpCoordinateLocation,
        dropOffCoordinate: this.dropOffCoordinateLocation,
        load: this.load == 0 ? 1 : this.load,
        canopy: this.canopy,
        dropOid: null,
        distance: this.distance,
        province: this.validateProvince,
        transportMode: this.transportMode,
        type: this.type,
        itemMass: this.totalMass != null ? this.totalMass : null,
        isItemPicked: false,
        mainCityOid: this.mainCityOid,
        fromSuburb: this.fromSuburb,
        toSuburb: this.toSuburb,
        pickUpPCode: this.pickUpPCode,
        dropOffPCode: this.dropOffPCode,
        pickUpAmt: this.pickUpAmt,
        airlineAmt: this.airlineAmt,
        dropOffAmt: this.dropOffAmt,
        destinationProvince: this.dropOffProvince,
        isPayed: false,
        paymentType: "ONLINE_PAY",
        isExpress: true,
        customerReference: this.customer_reference!=null?this.customer_reference:this.customerRef,
        costCenter: this.cost_center_num?this.cost_center_num:this.costCentreNumber,
        parcelDimensions: this.pracelDims,
        parcels: null,
        platform: "web",
        isDashBike: this.isBike
      };

      // console.log(bookingData);

      localStorage.setItem("bookingObj", JSON.stringify(bookingData));
      this.router.navigateByUrl("/confirm-single-delivery");
    }
  }

  public createMultipleDrop() {
    if(this.tempAmount===0){
      this.loadservice.removeloader();
      this.bookingText = "Can't Make booking of amount R0.00";
      $("#results3").modal("show");
    }
    var object = JSON.parse(localStorage.getItem("userInfo"));
    var currentDate = new Date();
    // console.log(currentDate,"getFullYear")
    let date =
      currentDate.getDate() <= 9
        ? "0" + currentDate.getDate()
        : currentDate.getDate();
    let monthNo =
      currentDate.getMonth() + 1 <= 9
        ? "0" + (currentDate.getMonth() + 1)
        : currentDate.getMonth() + 1;
    let hour =
      currentDate.getHours() <= 9
        ? "0" + currentDate.getHours()
        : currentDate.getHours();
    let minutes =
      currentDate.getMinutes() <= 9
        ? "0" + currentDate.getMinutes()
        : currentDate.getMinutes();
    var second =
      currentDate.getSeconds() <= 9
        ? "0" + currentDate.getSeconds()
        : currentDate.getSeconds();
    var dropTime =
      currentDate.getFullYear() +
      "-" +
      monthNo +
      "-" +
      date +
      "T" +
      hour +
      ":" +
      minutes +
      ":" +
      second;
    var datePipe = new DatePipe("en-US");
    let value = null;
    var timestamp = new Date().valueOf();
    var datePipe = new DatePipe("en-US");
    let valueTime = null;

    if (this.bookingDate != null) {
      value = datePipe.transform(this.bookingDate, "yyyy-MM-dd");
      var dateHalf2 = new Date(
        this.bookingDate.getFullYear(),
        Number(monthNo),
        Number(date),
        14,
        30
      );
      valueTime = datePipe.transform(dateHalf2, "HH:mm a");
    } else {
      value = datePipe.transform(this.displayNewDate, "yyyy-MM-dd");
      var dateHalf2 = new Date(
        currentDate.getFullYear(),
        Number(monthNo),
        Number(date),
        14,
        30
      );
      valueTime = datePipe.transform(dateHalf2, "HH:mm a");
    }

    var bookingData = {
      dropOff: {
        firstName: this.dropContact.name,
        lastName:
          this.dropContact.surname != null
            ? this.dropContact.surname
            : object.owner.surname,
        phone: this.dropContact.mobile,
        email: object.owner.email,
        companyName:
          this.companyDropOffName != null
            ? this.companyDropOffName
            : object.owner.firstName,
        complex: this.complexDropOff != null ? this.complexDropOff : null,
        unitNo: this.unitDropOff != null ? this.unitDropOff : null,
      },
      pickUp: {
        firstName: this.contact.name,
        lastName:
          this.contact.surname != null
            ? this.contact.surname
            : object.owner.surname,
        phone: this.contact.mobile,
        email: this.contact.email,
        companyName:
          this.companyPickUpName != null
            ? this.companyPickUpName
            : object.owner.firstName,
        complex: this.complexPickUp != null ? this.complexPickUp : null,
        unitNo: this.unitPickUp != null ? this.unitPickUp : null,
      },
      customer: this.retail.owner,
      price: this.tempAmount,
      vehicleType: this.vehicleType,
      pickUpAddress: this.pickUpAddress,
      pickUpTime: valueTime,
      phoneNo: object.owner.mobile,
      pickUpDate: value,
      dropOffAddress: this.dropOffAddress,
      comment: this.comment,
      labour: this.contact.labours,
      timestamp: timestamp,
      status: "BUCKET",
      dropFloors: this.dropOffFloors,
      pickUpFloors: this.floorsPickUp,
      pickUpCoordinate: this.pickUpCoordinateLocation,
      dropOffCoordinate: this.dropOffCoordinateLocation,
      load: 1,
      canopy: this.canopy,
      dropOid: null,
      distance: 1,
      province: this.province,
      transportMode: this.transportMode,
      type: this.type,
      itemMass: this.mass != null ? this.mass : null,
      isItemPicked: false,
      mainCityOid: null,
      fromSuburb: this.fromSuburb,
      toSuburb: this.toSuburb,
      pickUpPCode: this.pickUpPCode,
      dropOffPCode: this.dropOffPCode,
      destinationProvince: this.dropOffProvince,
      isPayed: false,
      paymentType: "ONLINE_PAY",
      isExpress: true,
      customerReference: this.customer_reference!=null?this.customer_reference:this.customerRef,
      costCenter: this.cost_center_num!=null?this.cost_center_num:this.costCentreNumber,
      parcelDimensions: this.pracelDims,
      parcels: null,
      platform: "web",
      expectedDeliveryDate: null,
    };

    // console.log(bookingData);
    if (
      this.tenderDocumentSelected &&
      this.tenderDate !== undefined &&
      this.tenderTime !== undefined
    ) {
      // console.log("1");
      let valueTime = datePipe.transform(this.tenderTime, "HH:mm a");
      var currentDate = new Date(this.tenderDate);
      let date =
        currentDate.getDate() <= 9
          ? "0" + currentDate.getDate()
          : currentDate.getDate();
      let monthNo =
        currentDate.getMonth() + 1 <= 9
          ? "0" + (currentDate.getMonth() + 1)
          : currentDate.getMonth() + 1;
      var dropTime =
        currentDate.getFullYear() +
        "-" +
        monthNo +
        "-" +
        date +
        "T" +
        valueTime.split(" ")[0] +
        ":" +
        second +
        ".957Z";
      var tenderSelectedDate = dropTime;
      var valudeTender = datePipe.transform(this.tenderDate, "yyyy-MM-dd");
      let calDiffDays = moment(value).diff(moment(valudeTender), "days");
      if (calDiffDays < 0) {
        bookingData.expectedDeliveryDate = tenderSelectedDate
          ? tenderSelectedDate
          : null;
        var drop = {
          name: "Drop",
          notes: "be careful",
          cost: this.tempAmount * 100,
          clientOid: object.owner.oid,
          vehicleOid: "1521105441081",
          driverOid: "575ae6bd30bc5d5017477143",
          from: {
            coordinates: ["-26.002483", "28.099503"],
            address: this.pickUpAddress,
          },
          to: {
            coordinates: ["-26.002483", "28.099503"],
            address: this.dropOffAddress,
          },
          when: dropTime,
          atp: dropTime,
          eta: dropTime,
          ata: dropTime,
          droplets: [
            {
              itemOid: "575ae6bd30bc5d5017477143",
              notes: "new",
            },
          ],
          booking: bookingData,
        };


        let myToken = JSON.parse(localStorage.getItem("userInfo")).token;
        // if (this.checkingDeliveryDates(value)) {
        this.loadservice.addloader();
        this.pushCourierDrop(drop, myToken, bookingData);
        // } else {
        //   alert(this.dateMessage);
        // }
      } else {
        alert(
          "Please note that the Tender delivery date should be after the collection date."
        );
      }
    } else if (!this.tenderDocumentSelected) {
      var drop = {
        name: "Drop",
        notes: "be careful",
        cost: this.tempAmount * 100,
        clientOid: object.owner.oid,
        vehicleOid: "1521105441081",
        driverOid: "575ae6bd30bc5d5017477143",
        from: {
          coordinates: ["-26.002483", "28.099503"],
          address: this.pickUpAddress,
        },
        to: {
          coordinates: ["-26.002483", "28.099503"],
          address: this.dropOffAddress,
        },
        when: dropTime,
        atp: dropTime,
        eta: dropTime,
        ata: dropTime,
        droplets: [
          {
            itemOid: "575ae6bd30bc5d5017477143",
            notes: "new",
          },
        ],
        booking: bookingData,
      };

      let myToken = JSON.parse(localStorage.getItem("userInfo")).token;
      if (this.checkingDeliveryDates(value)) {
        this.loadservice.addloader();
        this.pushCourierDrop(drop, myToken, bookingData);
      } else {
        alert(this.dateMessage);
      }
    } else {
      alert(
        "Please select a delivery date & delivery time for your tender documents."
      );
      return false;
    }
  }

  public pushCourierDrop(drop: any, myToken: string, bookingData: any) {
    this.bookService.plainDrop(drop, myToken).subscribe(
      (res: Response) => {
        bookingData.dropOid = res.json().oid;
        var bucket = {
          bookings: bookingData,
          retailOid: this.retail.oid,
          price: bookingData.price,
          status: "BUCKET_AWAITING",
          vehicleType: bookingData.vehicleType,
          dropOid: res.json().oid,
          date: this.getBookingDate(bookingData.pickUpDate),
          time: bookingData.pickUpTime,
          province: this.province,
          type: this.type,
          isExpress: true,
          branch: {
            name: this.retailBranch != null ? this.retailBranch.name : null,
            phone: this.retailBranch != null ? this.retailBranch.phone : null,
            email: this.retailBranch != null ? this.retailBranch.email : null,
          },
        };
        localStorage.setItem("BookingData", JSON.stringify(bookingData));
        this.bookService.bucketsBook(bucket, myToken).subscribe(
          (response: Response) => {
            this.bucketBooking = response;
            localStorage.setItem(
              "bucketBooking",
              JSON.stringify(this.bucketBooking)
            );
            this.loadservice.removeloader();
            this.onHome();
          },
          (err) => {
            console.log(err);
            this.loadservice.removeloader();
            this.bookingText =
              "Error occured while making a booking, please try again.";
            $("#results2").modal("show");
          }
        );
      },
      (error) => {
        console.log(error);
        this.loadservice.removeloader();
        this.bookingText = "Unable to make a booking.";
        $("#results3").modal("show");
      }
    );
  }

  public createFleetDrop() {
    if(this.tempAmount===0){
      this.loadservice.removeloader();
      this.bookingText = "Can't Make booking of amount R0.00";
      $("#results3").modal("show");
    }
    if (!this.termsFleetControl) {
      this.checkingMsg =
        "Please click the checkbox below across the terms & conditions to proceed with the booking.";
      $("#validationModal").modal("show");
      return
    }
    if (this.comment === null) {
      this.checkingMsg =
        "Please enter in the comment section below for any further comments (instructions or tips regarding your delivery).";
      $("#validationModal").modal("show");
      return
    }


    var object = JSON.parse(localStorage.getItem("userInfo"));
    var currentDate = new Date();
    let date =
      currentDate.getDate() <= 9
        ? "0" + currentDate.getDate()
        : currentDate.getDate();
    let monthNo =
      currentDate.getMonth() + 1 <= 9
        ? "0" + (currentDate.getMonth() + 1)
        : currentDate.getMonth() + 1;
    let hour =
      currentDate.getHours() <= 9
        ? "0" + currentDate.getHours()
        : currentDate.getHours();
    let minutes =
      currentDate.getMinutes() <= 9
        ? "0" + currentDate.getMinutes()
        : currentDate.getMinutes();
    var second =
      currentDate.getSeconds() <= 9
        ? "0" + currentDate.getSeconds()
        : currentDate.getSeconds();
    var dropTime =
      currentDate.getFullYear() +
      "-" +
      monthNo +
      "-" +
      date +
      "T" +
      hour +
      ":" +
      minutes +
      ":" +
      second;
    var dateValue = this.fleetBookingDate;
    var datePipe = new DatePipe("en-US");
    let value = datePipe.transform(dateValue, "yyyy-MM-dd");
    var timestamp = new Date().valueOf();
    var datePipe = new DatePipe("en-US");
    var timeDate = new Date();
    var hours = this.fleetBookingTime.split(":");
    timeDate.setHours(hours[0], hours[1]);
    let valueTime = datePipe.transform(timeDate, "HH:mm a");
    var bookingData = {
      dropOff: {
        firstName: this.dropContact.name,
        lastName:
          this.dropContact.surname != null
            ? this.dropContact.surname
            : object.owner.surname,
        phone: this.dropContact.mobile,
        email: object.owner.email,
        companyName:
          this.companyDropOffName != null
            ? this.companyDropOffName
            : object.owner.firstName,
        complex: this.complexDropOff != null ? this.complexDropOff : null,
        unitNo: this.unitDropOff != null ? this.unitDropOff : null,
      },
      pickUp: {
        firstName: this.contact.name,
        lastName:
          this.contact.surname != null
            ? this.contact.surname
            : object.owner.surname,
        phone: this.contact.mobile,
        email: this.contact.email,
        companyName:
          this.companyPickUpName != null
            ? this.companyPickUpName
            : object.owner.firstName,
        complex: this.complexPickUp != null ? this.complexPickUp : null,
        unitNo: this.unitPickUp != null ? this.unitPickUp : null,
      },
      customer: object.owner,
      price: Math.round(this.tempAmount),
      vehicleType: this.vehicleType,
      pickUpAddress: this.pickUpAddress,
      pickUpTime: valueTime,
      phoneNo: object.owner.mobile,
      pickUpDate: value,
      dropOffAddress: this.dropOffAddress,
      comment: this.comment,
      labour: this.contact.labours,
      timestamp: timestamp,
      status: "BUCKET",
      dropFloors: this.dropOffFloors,
      pickUpFloors: this.floorsPickUp,
      pickUpCoordinate: this.pickUpCoordinateLocation,
      dropOffCoordinate: this.dropOffCoordinateLocation,
      load: this.load == 0 ? 1 : this.load,
      canopy: this.canopy,
      dropOid: null,
      distance: this.distance,
      province: this.validateProvince,
      transportMode: "ROAD",
      type: "DEDICATED",
      itemMass: this.totalMass != null ? this.totalMass : null,
      isItemPicked: false,
      mainCityOid: null,
      fromSuburb: this.fromSuburb,
      toSuburb: this.toSuburb,
      pickUpPCode: this.pickUpPCode,
      dropOffPCode: this.dropOffPCode,
      pickUpAmt: this.pickUpAmt,
      airlineAmt: this.airlineAmt,
      dropOffAmt: this.dropOffAmt,
      destinationProvince: this.dropOffProvince,
      isPayed: false,
      paymentType: "ONLINE_PAY",
      customerReference: this.customer_reference!=null?this.customer_reference:this.customerRef,
      costCenter: this.cost_center_num?this.cost_center_num:this.costCentreNumber,
      parcelDimensions: this.pracelDims,
      parcels: null,
      platform: "web",
    };

    // Add parcels
    if (this.pracels.length > 0) {
      bookingData.parcels = this.pracels;
    }

    var drop = {
      name: "Drop",
      notes: "be careful",
      cost: Math.round(this.tempAmount * 100),
      clientOid: object.owner.oid,
      vehicleOid: "1521105441081",
      driverOid: "575ae6bd30bc5d5017477143",
      from: {
        coordinates: ["-26.002483", "28.099503"],
        address: this.pickUpAddress,
      },
      to: {
        coordinates: ["-26.002483", "28.099503"],
        address: this.dropOffAddress,
      },
      when: dropTime,
      atp: dropTime,
      eta: dropTime,
      ata: dropTime,
      droplets: [
        {
          itemOid: "575ae6bd30bc5d5017477143",
          notes: "new",
        },
      ],
      booking: bookingData,
    };

    let myToken = JSON.parse(localStorage.getItem("userInfo")).token;

    this.loadservice.addloader();
    let todaysDate = datePipe.transform(currentDate, "yyyy-MM-dd");
    let TimeSeparatorIndex = valueTime.indexOf(":");
    this.hour = parseInt(valueTime.substring(0, TimeSeparatorIndex));
    var timeArray = valueTime.split(" ");
    var bookingTimeArray = timeArray[0].split(":");
    this.bookingDates = new Date(datePipe.transform(dateValue, "yyyy-MM-dd"));
    this.bookingDates.setHours(this.hour);
    this.bookingDates.setMinutes(parseInt(bookingTimeArray[1]));
    var validHour = moment(this.bookingDates).isAfter(moment().add(1, "hours"));

    if (value >= todaysDate) {
      this.validationMsg = "";
      if (this.hour > 7 && this.hour < 18) {
        this.hourMsg = "";
        if (validHour) {
          this.additionalHour = "";
          if (this.tempAmount != 0) {
            // console.log("1: ",drop)
            this.bookService.plainDrop(drop, myToken).subscribe(
              (res: Response) => {
                bookingData.dropOid = res.json().oid;
                var bucket = {
                  bookings: bookingData,
                  retailOid: this.retail.oid,
                  price: Math.round(bookingData.price),
                  status: "BUCKET_AWAITING",
                  vehicleType: bookingData.vehicleType,
                  dropOid: res.json().oid,
                  date: this.getBookingDate(value),
                  time: bookingData.pickUpTime,
                  province: this.validateProvince,
                  branch: {
                    name:
                      this.retailBranch != null ? this.retailBranch.name : null,
                    phone:
                      this.retailBranch != null
                        ? this.retailBranch.phone
                        : null,
                    email:
                      this.retailBranch != null
                        ? this.retailBranch.email
                        : null,
                  },
                };
                // console.log("booking data: ",bookingData)
                localStorage.setItem(
                  "BookingData",
                  JSON.stringify(bookingData)
                );
                this.bookService.bucketsBook(bucket, myToken).subscribe(
                  (response: Response) => {
                    this.bucketBooking = response;
                    $("#dateAndTime").modal("hide");
                    localStorage.setItem(
                      "bucketBooking",
                      JSON.stringify(this.bucketBooking)
                    );
                    this.loadservice.removeloader();
                    this.onHome();
                  },
                  (err) => {
                    console.log(err);
                    this.loadservice.removeloader();
                    this.bookingText =
                      "Error occured while making a booking, please try again.";
                    $("#results2").modal("show");
                  }
                );
              },
              (error) => {
                console.log(error);
                this.loadservice.removeloader();
                this.bookingText = "Unable to make a booking.";
                $("#results3").modal("show");
              }
            );
          } else {
            this.loadservice.removeloader();
            alert("Please select a vehicle type to proceed with the booking.");
          }
        } else {
          this.loadservice.removeloader();
          this.additionalHour = "Please increase the time by an hour!";
        }
      } else {
        this.loadservice.removeloader();
        this.hourMsg = "Working hours are from 07:00 AM to 18:00 PM.";
      }
    } else {
      this.loadservice.removeloader();
      this.validationMsg =
        "Error, invalid pick up date. The date already passed.";
    }
  }
  // create booking

  fleetCanAddParcelError = '';

  // Fleet options
  public DateAndTimePopUp() {

    if (this.canAddParcels && this.pracelDims.length < 1) {
      // Show error message at parcel section
      // console.log(this.pracelDims.length);
      this.fleetCanAddParcelError = 'Please add parcels to continue';
    } else {
      let validated = this.validator();
      if (validated) {
        $("#dateAndTime").modal("show");
      } else {
        window.scrollTo(1000, 1000);
        $("#fleetIncompleteField").modal("show");
      }
    }
  }
  public validator():boolean{
    this.resetFleetErrors();
    const patten=new RegExp("[0-9]{10}")
    let validated=true;

    /*if(!this.pickUpChecked){
      this.atPickupError=true;
      validated=false;
    }*/
    if(!this.pickUpAddress ){
      this.pickupaddressError=true;
      validated=false;
    }
    if(!patten.test(this.contact.mobile)){
      this.pickupPhoneNumberError=true;
      validated=false;
    }
    if(this.contact.name.length===0){
      this.pickupNameError=true;
      validated=false;
    }

    // dropp off
    if(!this.dropOffAddress ){
      this.dropoffaddressError=true;
      validated=false;
    }
    /*if(!this.dropOffChecked){
      this.atDroppoffError=true;
      validated=false;
    }*/
    if(this.dropContact.name.length===0){
      this.droppoffNameError=true;
      validated=false;
    }
    if(!patten.test(this.dropContact.mobile)){
      this.droppofPhoneNumberError=true;
      validated=false;
    }

    if(!this.comment||this.comment.length===0){
      this.commentError=true;
      validated=false;
    }
    if(!this.isCourierBtn){
      if(!this.fleetTerms){
        this.termsFleetError=true;
        validated=false;
      }
      if(this.tempAmount==0){
        this.truckError=true;
        validated=false;
      }
    }

    if(this.isCourierBtn){

        if(!this.terms){
          this.termsCourierError=true;
          validated=false;
        }
        if(this.selectedOption != 'SAME_DAY' && this.selectedOption != 'MULITPLE_DROPS'){
          this.serviceTypeError=true;
          validated=false;
        }
    }
    return validated;
  }
  public resetFleetErrors(){
    this.truckError=false;
    this.termsFleetError=false;
    this.pickupaddressError=false;
    this.atPickupError=false;
    this.pickupNameError=false;
    this.pickupPhoneNumberError=false;

    this.dropoffaddressError=false;
    this.atDroppoffError=false;
    this.droppoffNameError=false;
    this.droppofPhoneNumberError=false;
    this.commentError=false;

    this.termsCourierError=false;
    this.serviceTypeError=false;
  }
  // Fleet options

  labourFactor(labour) {
    if (labour > 0) {
      this.onLabour(labour);
    } else {
      this.onLabour(labour);
    }
  }

  public onLabour(labour: number) {
    if (labour != null) {
      if (labour >= 0) {
        this.tempAmount = this.price + this.contact.labours * 150;
      } else {
        this.tempAmount = this.price + this.contact.labours * 150;
      }
    }
  }

  public onHome() {
    this.router.navigateByUrl("/multiple-drop-booking");
  }

  public onFlashRoadhSelected() {
    this.controlSelection();
    this.resetImages();
    this.transportMode = "ROAD";
    this.type = "FLASH";
    this.vehicleType = "DROPPA_EXPRESS";
    this.tempAmount = Math.round(this.flashRoad);
    $(".road").addClass("expressChoices_");
    $(".road").removeClass("expressChoices");
    this.isRoad = true;
    this.tenderDocumentSelected = false;
  }

  public onDashSelected() {
    this.controlSelection();
    this.resetImages();
    this.transportMode = "ROAD";
    this.type = "DASH";
    this.vehicleType = "DASH";
    this.tempAmount = Math.round(this.sheshaMode);
    $(".dash").addClass("expressChoices_");
    $(".dash").removeClass("expressChoices");
    this.isDash = true;
    this.tenderDocumentSelected = false;
  }

  public onFlashFlightSelected() {
    this.controlSelection();
    this.resetImages();
    this.transportMode = "AIR";
    this.type = "FLASH";
    this.vehicleType = "DROPPA_EXPRESS";
    this.tempAmount = Math.round(this.flashFlight);
    this.airlineAmt = this.airlineAmt;
    this.pickUpAmt = this.pickUpAmt;
    this.dropOffAmt = this.dropOffAmt;
    $(".flight").addClass("expressChoices_");
    $(".flight").removeClass("expressChoices");
    this.isFlight = true;
    this.tenderDocumentSelected = false;
  }

  public onExpressCourierSelected() {
    this.controlSelection();
    this.resetImages();
    this.transportMode = "ROAD";
    this.type = "EXPRESS_COURIER";
    this.vehicleType = "DROPPA_EXPRESS";
    this.tempAmount = Math.round(this.expressCourier);
    $(".courierExp").addClass("expressChoices_");
    $(".courierExp").removeClass("expressChoices");
    this.isCourier = true;
    this.tenderDocumentSelected = false;
  }

  public onBudgetCourierSelected() {
    this.controlSelection();
    this.resetImages();
    this.transportMode = "ROAD";
    this.type = "BUDGET_COURIER";
    this.vehicleType = "DROPPA_EXPRESS";
    this.tempAmount = Math.round(this.budgetCourier);
    $(".budget").addClass("expressChoices_");
    $(".budget").removeClass("expressChoices");
    this.isBudget = true;
    this.tenderDocumentSelected = false;
  }

  public onTenderDocumentSelected() {
    this.controlSelection();
    this.resetImages();
    this.transportMode = "ROAD";
    this.type = "EXPRESS_COURIER";
    this.vehicleType = "DROPPA_EXPRESS";
    this.tempAmount = this.tenderDocument;
    $(".tender").addClass("expressChoices_");
    $(".tender").removeClass("expressChoices");
    this.isTender = true;
    this.tenderDocumentSelected = true;
  }

  private controlSelection() {
    $(".road").addClass("expressChoices").removeClass("expressChoices_");
    $(".flight").addClass("expressChoices").removeClass("expressChoices_");
    $(".courierExp").addClass("expressChoices").removeClass("expressChoices_");
    $(".budget").addClass("expressChoices").removeClass("expressChoices_");
    $(".dash").addClass("expressChoices").removeClass("expressChoices_");
    $(".tender").addClass("expressChoices").removeClass("expressChoices_");
  }

  private resetImages() {
    this.isBudget = false;
    this.isCourier = false;
    this.isRoad = false;
    this.isFlight = false;
    this.isDash = false;
    this.isTender = false;
  }

  public checkedTerms(terms) {
    if (terms) {
      this.termsControl = true;
    } else {
      this.termsControl = false;
    }
  }

  public openPopup() {
    var options = "location=no,clearcache=no,toolbar=no";
    var paymentWindow = window.open(
      "https://www.droppa.co.za/droppa/courier-terms",
      "_blank",
      options
    );
  }

  fleetTerms: any;
  termsFleetControl: boolean = false;
  public checkedFleetTerms(fleetTerms) {
    if (fleetTerms) {
      this.termsFleetControl = true;
    } else {
      this.termsFleetControl = false;
    }
  }

  public openFleetTerms() {
    var options = "location=no,clearcache=no,toolbar=no";
    var paymentWindow = window.open(
      "https://www.droppa.co.za/droppa/terms-and-conditions",
      "_blank",
      options
    );
  }

//   Pudo

  public goToL2L() {
    this.reservationType = 'L2L';
    this.resetPudoData();
    this.showPickUpLockerOption = true;
    this.showDropOffLockerOption = true;

    this.showPickUpDoorOption = false;
    this.showDropOffDoorOption = false;

    this.showPickUpLockerTab();
    this.showJourney = true;
  }

  public goToD2L() {
    this.reservationType = 'D2L';
    this.resetPudoData();
    this.showPickUpLockerOption = false;
    this.showDropOffLockerOption = true;

    this.showPickUpDoorOption = true;
    this.showDropOffDoorOption = false;

    this.showPickUpDoorTab();
    this.showJourney = true;
  }

  public goToL2D() {
    this.reservationType = 'L2D';
    this.resetPudoData();
    this.showPickUpLockerOption = true;
    this.showDropOffLockerOption = false;

    this.showPickUpDoorOption = false;
    this.showDropOffDoorOption = true;

    this.showPickUpLockerTab();
    this.showJourney = true;
  }

  public resetPudoData() {
    this.pickUpLocker = undefined;
    this.pickUpDoor = undefined;
    this.boxSize = undefined;
    this.fillPickUpContact = false;
    this.pickUpContact.name = '';
    this.pickUpContact.phone = '';
    this.pickUpContact.email = '';
    this.pudoPickUpDoorCompanyName = '';
    this.pudoPickUpDoorAdditional = '';
    this.dropOffLocker = undefined;
    this.dropOffDoor = undefined;
    this.summaryHasBeenViewed = false;
    this.fillDropOffContact = false;
    this.dropOffContact.name = '';
    this.dropOffContact.phone = '';
    this.dropOffContact.email = '';
    this.pudoDropOffDoorCompanyName = '';
    this.pudoDropOffDoorAdditional = '';
    this.selectedPickUpLocker = -1;
    this.selectedDropOffLocker = -1;
    this.selectedPudoBox = -1;
    this.pudoTerms = false;
    this.pudoError = false;
    this.pudoWalletInsufficient = false;
    this.pudoBooking = undefined;
  }

  public showPudoTab(selectedTab) {

    switch (selectedTab) {
      case 'pickUpLockerTab': this.showPickUpLockerTab(); break;
      case 'pickUpBoxSizeTab': this.checkPickupLocker(); break;
      case 'pickUpDoorTab': this.showPickUpDoorTab(); break;
      case 'pickUpContactTab': this.checkPickUpBoxSize(); break;
      case 'dropOffLockerTab': this.checkPickUpContact(); break;
      case 'dropOffBoxSizeTab': this.checkDropOffLocker(); break;
      // case 'dropOffDoorTab': this.checkPickUpContact(); break;
      case 'dropOffContactTab': this.checkDropOffBoxSize(); break;
      case 'summaryTab': this.checkDropOffContact(); break;
      case 'paymentTab': this.checkSummary(); break;
    }
  }

  private showPickUpLockerTab() {
    if (this.pudoLockers.length === 0) {
      this.loadservice.addloader();
      this.bookService.getListOfPudoLockers(this.user.token).subscribe(
        (response: any) => {
          this.pudoLockers = response;
          this.loadservice.removeloader();
        },
        (error) => {
          console.log(error);
          this.loadservice.removeloader();
        }
      );
    }
    this.pickUpDoorTab = false;
    this.pickUpBoxSizeTab = false;
    this.pickUpContactTab = false;
    this.dropOffLockerTab = false;
    this.dropOffBoxSizeTab = false;
    this.dropOffDoorTab = false;
    this.dropOffContactTab = false;
    this.summaryTab  = false;
    this.paymentTab = false;
    this.pickUpLockerTab = true;
  }

  private showPickUpDoorTab() {
    this.pickUpLockerTab = false;
    this.pickUpBoxSizeTab = false;
    this.pickUpContactTab = false;
    this.dropOffLockerTab = false;
    this.dropOffBoxSizeTab = false;
    this.dropOffDoorTab = false;
    this.dropOffContactTab = false;
    this.summaryTab  = false;
    this.paymentTab = false;
    this.pickUpDoorTab = true;
  }

  public selectPudoBox(index, box) {
    this.selectedPudoBox = index;
    this.boxSize = box;
    if (this.reservationType === 'L2L') {
      this.boxSize.name.toUpperCase() === 'V4-XS' ? this.boxSize.price = 50 : this.boxSize.price = 60;
    }
  }

  pickUpterminalId = '';
  pickUpLockerAddress : any;
  dropOffterminalId = '';
  dropOffLockerAddress : any;

  private checkPickupLocker() {
    if (this.pickUpLocker !== undefined) {
      this.invalidPickUpLocker = false;
      this.pickUpLockerTab = false;
      this.pickUpDoorTab = false;
      this.pickUpContactTab = false;
      this.dropOffLockerTab = false;
      this.dropOffBoxSizeTab = false;
      this.dropOffDoorTab = false;
      this.dropOffContactTab = false;
      this.summaryTab  = false;
      this.paymentTab = false;
      /*this.pickUpterminalId = this.pickUpLocker.id;
      this.pickUpLockerAddress = this.pickUpLocker;*/
      this.getPudoLockerAvailableBoxes(this.pickUpLocker.id, true);
    } else {
      this.invalidPickUpLocker = true;
      this.pudoMessage = 'Please select a sending locker';
      this.pudoError = true;
      $('#pudoMessage').modal('show');
    }
  }

  private getPudoLockerAvailableBoxes(terminalId, isPickUp) {
    this.loadservice.addloader();
    // Full locker test code
    /*this.pickUpLocker.lstTypesBoxes.length = 0;
    this.pickUpBoxSizeTab = true;
    this.loadservice.removeloader();
    return;*/
    this.bookService.getListOfAvaialablePudoLockerBoxes(this.user.token, terminalId).subscribe(
      response1 => {
        if (isPickUp) {
          this.pickUpLocker = response1;
          if (this.pickUpLocker.length === 0) {
            this.pickUpLocker.lstTypesBoxes = [];
          } else {
            this.pickUpLocker = response1[0];
            // console.log(this.pickUpLocker);
            this.pickUpLocker.lstTypesBoxes = this.pickUpLocker.lstTypesBoxes.filter((obj: any) => {
              // console.log(obj);
              return obj.free > 0;
            });
            this.pickUpLocker.lstTypesBoxes = this.pickUpLocker.lstTypesBoxes.sort((ob1, ob2) => ob1.price - ob2.price);
          }
          this.pickUpBoxSizeTab = true;
        } else {
          this.dropOffLocker = response1;
          if (this.dropOffLocker.length === 0) {
            this.dropOffLocker.lstTypesBoxes = [];
          } else {
            this.dropOffLocker = response1[0];
            this.pickUpLocker.lstTypesBoxes = this.pickUpLocker.lstTypesBoxes.filter((obj: any) => {
              // console.log(obj);
              return obj.free > 0;
            });
            this.dropOffLocker.lstTypesBoxes = this.dropOffLocker.lstTypesBoxes.sort((ob1, ob2) => ob1.price - ob2.price);

          }
          this.dropOffBoxSizeTab = this.showPickUpDoorOption;
        }
      },
      error1 => {
        console.log(error1);
      },
      () => {
        this.loadservice.removeloader();
      }
    );
  }

  private checkPickUpBoxSize() {
    if (this.showPickUpLockerOption) {
      if (this.boxSize !== undefined) {
        this.invalidBoxSize = false;
        this.pickUpLockerTab = false;
        this.pickUpDoorTab = false;
        this.pickUpBoxSizeTab = false;
        this.dropOffLockerTab = false;
        this.dropOffBoxSizeTab = false;
        this.dropOffDoorTab = false;
        this.dropOffContactTab = false;
        this.summaryTab = false;
        this.paymentTab = false;
        this.pickUpContactTab = true;
      } else {
        this.invalidBoxSize = true;
        this.pudoMessage = 'Please select a box size';
        this.pudoError = true;
        $('#pudoMessage').modal('show');
      }
    } else {
      if (this.pickUpDoor !== undefined) {
        this.invalidPickUpDoor = false;
        this.pickUpLockerTab = false;
        this.pickUpDoorTab = false;
        this.pickUpBoxSizeTab = false;
        this.dropOffLockerTab = false;
        this.dropOffBoxSizeTab = false;
        this.dropOffDoorTab = false;
        this.dropOffContactTab = false;
        this.summaryTab = false;
        this.paymentTab = false;
        this.pickUpContactTab = true;
      } else {
        this.invalidPickUpDoor = true;
        this.pudoMessage = 'Please select a sending address';
        this.pudoError = true;
        $('#pudoMessage').modal('show');
      }
    }
  }

  private checkPickUpContact() {
    if (this.pickUpContact.name !== '' && this.pickUpContact.phone !== '' && this.pickUpContact.email !== '') {
      /*if (this.needPickUpCompanyName && this.pickUpContact.companyName === '') {
        this.pudoMessage = 'Please enter a company name';
        this.pudoError = true;
        $('#pudoMessage').modal('show');
        return;
      }*/
      this.invalidPickUpContact = false;
      this.pickUpLockerTab = false;
      this.pickUpDoorTab = false;
      this.pickUpBoxSizeTab = false;
      this.pickUpContactTab = false;
      this.dropOffContactTab = false;
      this.dropOffBoxSizeTab = false;
      this.summaryTab  = false;
      this.paymentTab = false;
      if (this.pudoLockers.length === 0) {
        this.loadservice.addloader();
        this.bookService.getListOfPudoLockers(this.user.token).subscribe(
          (response: any) => {
            this.pudoLockers = response;
            this.loadservice.removeloader();
          },
          (error) => {
            console.log(error);
            this.loadservice.removeloader();
          }
        );
      }
      this.dropOffLockerTab = this.showDropOffLockerOption;
      this.dropOffDoorTab = this.showDropOffDoorOption;
    } else {
      this.invalidPickUpContact = true;
      this.pudoMessage = 'Please complete sender contact details';
      this.pudoError = true;
      $('#pudoMessage').modal('show');
    }
  }

  private checkDropOffLocker() {
    if (this.dropOffLocker !== undefined || this.dropOffDoor !== undefined) {
      this.invalidDropOffLocker = false;
      this.pickUpLockerTab = false;
      this.pickUpDoorTab = false;
      this.pickUpBoxSizeTab = false;
      this.pickUpContactTab = false;
      this.dropOffLockerTab = false;
      this.dropOffDoorTab = false;
      this.summaryTab  = false;
      this.paymentTab = false;
      // this.dropOffterminalId = this.dropOffLocker.id;
      // this.dropOffLockerAddress = this.dropOffLocker;
      if (this.showPickUpDoorOption) {
        this.dropOffBoxSizeTab = this.showPickUpDoorOption;
        // this.getPudoLockerAvailableBoxes(this.dropOffLocker.id, false);
      } else {
        this.dropOffContactTab = this.showPickUpLockerOption;
      }
    } else {
      this.invalidDropOffLocker = true;
      this.invalidDropOffDoor = true;
      this.pudoMessage = this.showDropOffLockerOption ? 'Please select a receiving locker' : 'Please select a receiving address';
      this.pudoError = true;
      $('#pudoMessage').modal('show');
    }
  }

  private checkDropOffBoxSize() {
    if (this.reservationType === 'L2L') {
      if (this.dropOffLocker !== undefined) {
        this.invalidDropOffLocker = false;
        this.pickUpLockerTab = false;
        this.pickUpDoorTab = false;
        this.pickUpBoxSizeTab = false;
        this.pickUpContactTab = false;
        this.dropOffLockerTab = false;
        this.dropOffBoxSizeTab = false;
        this.dropOffDoorTab = false;
        this.summaryTab = false;
        this.paymentTab = false;
        this.dropOffContactTab = true;
      } else {
        this.invalidDropOffLocker = true;
        this.pudoMessage = 'Please select a receiving locker';
        this.pudoError = true;
        $('#pudoMessage').modal('show');
      }
    } else if (this.reservationType === 'D2L') {
      if (this.boxSize !== undefined) {
        this.invalidBoxSize = false;
        this.pickUpLockerTab = false;
        this.pickUpDoorTab = false;
        this.pickUpBoxSizeTab = false;
        this.pickUpContactTab = false;
        this.dropOffLockerTab = false;
        this.dropOffBoxSizeTab = false;
        this.dropOffDoorTab = false;
        this.summaryTab = false;
        this.paymentTab = false;
        this.dropOffContactTab = true;
      } else {
        this.invalidBoxSize = true;
        this.pudoMessage = 'Please select a box size';
        this.pudoError = true;
        $('#pudoMessage').modal('show');
      }
    } else {
      if (this.dropOffDoor !== undefined) {
        this.invalidDropOffDoor = false;
        this.pickUpLockerTab = false;
        this.pickUpDoorTab = false;
        this.pickUpBoxSizeTab = false;
        this.pickUpContactTab = false;
        this.dropOffLockerTab = false;
        this.dropOffBoxSizeTab = false;
        this.dropOffDoorTab = false;
        this.summaryTab = false;
        this.paymentTab = false;
        this.dropOffContactTab = true;
      } else {
        this.invalidDropOffDoor = true;
        this.pudoMessage = 'Please select a receiving address';
        this.pudoError = true;
        $('#pudoMessage').modal('show');
      }
    }
  }

  private checkDropOffContact() {
    if (this.dropOffContact.name !== '' && this.dropOffContact.phone !== '' && this.dropOffContact.email !== '') {
      /*if (this.needDropOffCompanyName && this.dropOffContact.companyName === '') {
        this.pudoMessage = 'Please enter a company name';
        this.pudoError = true;
        $('#pudoMessage').modal('show');
        return;
      }*/
      this.invalidDropOffContact = false;
      this.pickUpLockerTab = false;
      this.pickUpDoorTab = false;
      this.pickUpBoxSizeTab = false;
      this.pickUpContactTab = false;
      this.dropOffLockerTab = false;
      this.dropOffBoxSizeTab = false;
      this.dropOffDoorTab = false;
      this.dropOffContactTab = false;
      this.paymentTab = false;
      this.summaryTab = true;
      this.summaryHasBeenViewed = true;
    } else {
      this.invalidDropOffContact = true;
      this.pudoMessage = 'Please complete receiver contact details';
      this.pudoError = true;
      $('#pudoMessage').modal('show');
    }
  }

  private checkSummary() {
    if (this.summaryHasBeenViewed) {
      this.invalidSummary = false;
      this.dropOffContactTab = false;
      this.pickUpLockerTab = false;
      this.pickUpBoxSizeTab = false;
      this.pickUpContactTab = false;
      this.dropOffLockerTab = false;
      this.summaryTab = false;
      this.paymentTab = true;
    } else {
      this.invalidSummary = true;
    }
  }

  public selectPickUpLocker(locker, index) {
    this.selectedPickUpLocker = index;
    this.pickUpLocker = locker;
    console.log(this.pickUpLocker);
  }

  public selectPickUpDoor(door) {
    this.pickUpDoor = door;
  }

  public selectDropOffLocker(locker, index) {
    this.selectedDropOffLocker = index;
    this.dropOffLocker = locker;
  }

  public selectDropOffDoor(door) {
    this.dropOffDoor = door;
  }

  public fillInUserDetails(option, pickOrDrop) {
    if (option && pickOrDrop === 'pickUp') {
      this.pickUpContact.name = this.user.owner.firstName;
      this.pickUpContact.phone = this.user.owner.mobile;
      this.pickUpContact.email = this.user.owner.email;
    }

    if (option && pickOrDrop === 'dropOff') {
      this.dropOffContact.name = this.user.owner.firstName;
      this.dropOffContact.phone = this.user.owner.mobile;
      this.dropOffContact.email = this.user.owner.email;
    }
  }

  public clearFill(pickOrDrop) {
    if (pickOrDrop === 'pickUp') {
      this.fillPickUpContact = false;
    }

    if (pickOrDrop === 'dropOff') {
      this.fillDropOffContact = false;
    }
  }

  public showPudoTerms() {
    window.open('/pudo-terms', '_blank');
  }

  public payWithPayfast() {
    if (this.pudoTerms) {
      this.loadservice.addloader();
      if (!this.isPudoCreated) {
        this.bookService.createPudoBooking(this.user.token, this.getPudoBody(), this.retail.oid).subscribe(
          (response1: any) => {
            this.pudoBooking = response1;
            this.isPudoCreated = true;
            const paymentData = {
              name_first: this.user.owner.firstName,
              name_last: this.user.owner.surname,
              email_address: this.user.owner.email,
              cell_number: this.user.owner.mobile,
              m_payment_id: this.pudoBooking.oid,
              amount: this.boxSize.price,
              customerReference: this.user.owner.oid,
              item_name: "Pudo Booking",
              item_description: "Payment For Pudo locker",
              email_confirmation: 1,
              confirmation_address: this.user.owner.email,
              reference: this.pudoBooking.oid,
              paymentType: "LOCKER_PAYMENT",
            };
            this.bookService.requestPayfastPayment(paymentData, this.user.token).subscribe(
              (response2) => {
                this.loadservice.addloader();
                localStorage.setItem('Payment', 'PUDO');
                localStorage.setItem('paidByRetail', 'true');
                this.redirectToPayFast(response2.json());

              }, (error) => {
                console.log(error);
                this.loadservice.removeloader();
              });
          },
          (error) => {
            this.loadservice.removeloader();
          },
          () => {
          }
        );
      } else {
        const paymentData = {
          name_first: this.user.owner.firstName,
          name_last: this.user.owner.surname,
          email_address: this.user.owner.email,
          cell_number: this.user.owner.mobile,
          m_payment_id: this.pudoBooking.oid,
          amount: this.boxSize.price,
          customerReference: this.user.owner.oid,
          item_name: "Pudo Booking",
          item_description: "Payment For Pudo locker",
          email_confirmation: 1,
          confirmation_address: this.user.owner.email,
          reference: this.pudoBooking.oid,
          paymentType: "LOCKER_PAYMENT",
        };
        this.bookService.requestPayfastPayment(paymentData, this.user.token).subscribe(
          (response3) => {
            this.loadservice.addloader();
            localStorage.setItem('Payment', 'PUDO');
            localStorage.setItem('paidByRetail', 'true');
            this.redirectToPayFast(response3.json());

          }, (error) => {
            console.log(error);
            this.loadservice.removeloader();
          });
      }
    } else {
      $('#pudoMessage').modal('show');
      this.pudoError = true;
      this.pudoMessage = 'Please accept terms and conditions.';
      this.loadservice.removeloader();
    }
  }

  public closeModal(modalName) {
    this.pudoError = false;
    $(`#${modalName}`).modal("hide");

  }

  public payWithWallet() {
    if (this.pudoTerms) {
      this.loadservice.addloader();
      this.retrieveWalletBalance();
      // Create booking
      if (!this.isPudoCreated) {
        this.bookService.createPudoBooking(this.user.token, this.getPudoBody(), this.retail.oid).subscribe(
          (response1) => {
            this.pudoBooking = response1;
            // console.log(this.getPudoBody());
            this.isPudoCreated = true;
            // Check Wallet balance
            this.bookService.checkAvailBalance(this.user.token, this.retail.owner.userAccountOid, this.pudoBooking.price).subscribe(
              (response2) => {

                // Pay for booking with Wallet
                if (response2.isBalancePositive) {
                  this.bookService.walletPayPudoBooking(this.user.token, this.pudoBooking.transactionId).subscribe(
                    (response3) => {

                      this.pudoError = false;
                      this.pudoMessage = 'Booking is being processed.';
                      if (this.reservationType !== 'D2L') {
                        this.pudoMessage += ' Once a Locker is confirmed, you will have 36 hours to place your parcel in the Locker. ' +
                          'Failure will result in a cancellation with NO REFUND.';
                      }
                      this.loadservice.removeloader();
                      $('#pudoMessage').modal({backdrop: 'static', keyboard: false});
                    },
                    (error3) => {
                      console.log(error3);
                      this.loadservice.removeloader();
                    }
                  );
                } else {
                  // console.log(this.walletBalance);
                  this.pudoMessage = 'Your Wallet balance of R' + this.walletBalance.toFixed(2) + ' is insufficient. Would you like to load wallet?';
                  this.pudoWalletInsufficient = true;
                  this.loadservice.removeloader();
                  $('#pudoMessage').modal('show');
                }
              },
              (error2) => {
                console.log(error2);
                this.loadservice.removeloader();
              }
            );
          },
          (error1) => {
            console.log(error1);
            this.loadservice.removeloader();
          }
        );
      } else {
        // Check Wallet balance
        this.bookService.checkAvailBalance(this.user.token, this.retail.owner.userAccountOid, this.pudoBooking.price).subscribe(
          (response2) => {
            // Pay for booking with Wallet
            if (response2.isBalancePositive) {
              this.pudoWalletInsufficient = false;
              this.bookService.walletPayPudoBooking(this.user.token, this.pudoBooking.transactionId).subscribe(
                (response3) => {

                  this.pudoMessage = 'Booking is being processed. \n ' +
                    'Once a Locker is confirmed, you will have 48 hours to place your parcel in the Locker. \n' +
                    'Failure will result in a cancellation with NO REFUND.';
                  this.loadservice.removeloader();
                  $('#pudoMessage').modal('show');
                },
                (error3) => {
                  console.log(error3);
                  this.loadservice.removeloader();
                }
              );
            } else {
              this.pudoMessage = 'Your Wallet balance of ' + this.walletBalance.toFixed(2) + ' is insufficient. Would you like to load wallet?';
              this.pudoWalletInsufficient = true;
              this.loadservice.removeloader();
              $('#pudoMessage').modal('show');
            }
          },
          (error2) => {
            console.log(error2);
            this.loadservice.removeloader();
          }
        );
      }
    } else {
      $('#pudoMessage').modal('show');
      this.pudoError = true;
      this.pudoMessage = 'Please accept terms and conditions.';
      this.loadservice.removeloader();
    }
  }

  public payWithMonthlyInvoice() {
    if (this.pudoTerms) {
      this.loadservice.addloader();
      this.bookService.createPudoBooking(this.user.token, this.getPudoBody(), this.retail.oid).subscribe(
        (response1) => {
          this.pudoBooking = response1;

          this.bookService.invoicePayPudoBooking(this.user.token, this.pudoBooking.transactionId).subscribe(
            (response2) => {
              this.sendNewRetailInvoices(this.pudoBooking);

              this.pudoError = false;
              this.pudoMessage = 'Booking is being processed. \n ' +
                'Once a Locker is confirmed, you will have 48 hours to place your parcel in the Locker. \n' +
                'Failure will result in a cancellation with NO REFUND.';
              this.loadservice.removeloader();
              $('#pudoMessage').modal('show');
            },
            (error2) => {
              console.log(error2);
              this.loadservice.removeloader();
            }
          );
        },
        (error1) => {
          console.log(error1);
          this.loadservice.removeloader();
        }
      );
    } else {
      $('#pudoMessage').modal('show');
      this.pudoError = true;
      this.pudoMessage = 'Please accept terms and conditions.';
      this.loadservice.removeloader();
    }
  }

  private getPudoBody() {
    const pudoBody = {
      reservationType: this.reservationType,
      sourceDevice: 0,
      price: this.boxSize.price,
      receiverName: this.dropOffContact.name,
      receiverCompanyName: this.reservationType === 'D2L' ? this.pudoPickUpDoorCompanyName :
        (this.reservationType === 'L2D' ? this.pudoDropOffDoorCompanyName : ''),
      receiverEmail: this.dropOffContact.email,
      receiverPhone: this.dropOffContact.phone,
      senderName: this.pickUpContact.name,
      senderEmail: this.pickUpContact.email,
      senderPhone: this.pickUpContact.phone,
      fromAddress: {
        Addressline1: this.showPickUpDoorOption ? this.pickUpDoor.data.description :
          (this.pickUpLocker.address !== '' ? this.pickUpLocker.address : 'Not available'),
        Addressline2: this.showPickUpDoorOption ? this.pudoPickUpDoorAdditional : ' ',
        Addressline3: this.showPickUpDoorOption ? this.pudoPickUpDoorAdditional : ' ',
        city: this.showPickUpDoorOption ? this.pickUpDoor.data.address_components[1].long_name : this.pickUpLocker.suburb,
        // city: 'my suburb',
        suburb: this.showPickUpDoorOption ? this.pickUpDoor.data.address_components[1].long_name : this.pickUpLocker.suburb,
        // suburb: 'my suburb',
        place: {
          placeNumber: this.showPickUpDoorOption ? 0 : this.pickUpLocker.place.placeNumber,
          town: this.showPickUpDoorOption ? this.pickUpDoor.data.address_components[1].long_name : this.pickUpLocker.place.town,
          postalCode: this.showPickUpDoorOption ? 'Google code' : this.pickUpLocker.place.postalCode
        }
      },
      toAddress: {
        Addressline1: this.showDropOffDoorOption ? this.dropOffDoor.data.description :
          (this.dropOffLocker.address !== '' ? this.dropOffLocker.address : 'Not available'),
        Addressline2: this.showDropOffDoorOption ? this.pudoDropOffDoorAdditional : ' ',
        Addressline3: this.showDropOffDoorOption ? this.pudoDropOffDoorAdditional : ' ',
        city: this.showDropOffDoorOption ? this.dropOffDoor.data.address_components[1].long_name : this.dropOffLocker.suburb,
        // city: 'my suburb',
        suburb: this.showDropOffDoorOption ? this.dropOffDoor.data.address_components[1].long_name : this.dropOffLocker.suburb,
        // suburb: 'my suburb',
        place: {
          placeNumber: this.showDropOffDoorOption ? 0 : this.dropOffLocker.place.placeNumber,
          town: this.showDropOffDoorOption ? this.dropOffDoor.data.address_components[1].long_name : this.dropOffLocker.place.town,
          postalCode: this.showDropOffDoorOption ? 'Google code' : this.dropOffLocker.place.postalCode
        }
      },
      // this.pickUpLocker.id,
      // this.dropOffLocker.id
      fromTerminalId: this.showPickUpDoorOption ? 'Google address ID' : this.pickUpLocker.id,
      toTerminalId: this.showDropOffDoorOption ? 'Google address ID' : this.dropOffLocker.id,
      weight: 0,
      boxType: this.boxSize.type,
      languageCode: 'EN'
    };
    // console.log(pudoBody);
    return pudoBody;
  }

  private sendNewRetailInvoices(booking) {
    const object = JSON.parse(localStorage.getItem('userInfo'));
    this.bookService.invoiceRetailBucketCustomer(object.token, booking.trackNo).subscribe(
      (response) => {
        //   Do nothing
      }, (error) => {
        console.log(error);
      }
    );
  }

  public showWalletOptions() {
    $('#pudoMessage').modal('hide');
    $('#walletTopUp').modal('show');
  }

  public retrieveWalletBalance() {
    this.userService.retrieveCurrentBalance(this.user.token, this.retail.owner.userAccountOid).subscribe(
      response => {
        this.walletBalance = response.json().amount;
      }, error => {
        console.log(error);
      }
    );
  }

  public creditWallet() {
    if (this.amountChosen < 0) {
      this.pudoWalletMsg = 'Enter a valid amount';
      this.invalidWalletAmt = true;
      return;
    }
    if (this.amountChosen > 0) {
      this.loadservice.addloader();
      this.invalidWalletAmt = false;
      const loadWallet = {
        userAccountID: this.retail.owner.userAccountOid,
        rechargeAmt: this.amountChosen
      };
      this.userService.loadWalletService(this.user.token, loadWallet).subscribe(
        response1 => {
          const paymentData = {
            "name_first": this.retail.owner.firstName,
            "name_last": this.retail.owner.surname,
            "email_address": this.retail.owner.email,
            "cell_number": this.retail.owner.mobile,
            "m_payment_id": response1.oid,
            "amount": this.amountChosen,
            "customerReference": this.retail.owner.oid,
            "item_name": 'Wallet Recharge',
            "item_description": 'Loading Wallet',
            "email_confirmation": 0,
            "confirmation_address": this.user.owner.email,
            "reference": response1.oid,
            "paymentType": "WALLET_PAYMENT"
          };
          this.loadservice.addloader();
          this.bookService.requestPayfastPayment(paymentData, this.user.token).subscribe(
            (response2) => {
              this.redirectToPayFast(response2.json());
            },
            (error2) => {
              console.log(error2);
              this.loadservice.removeloader();
            }
          );
        }, error1 => {
          console.log(error1);
          this.loadservice.removeloader();
        }
      );
    } else {
      this.pudoWalletMsg = 'Please enter a positive amount to credit your wallet.';
      this.invalidWalletAmt = true;
      // $('#emptyWallet').modal('show');
    }
  }

  searchSendingLocker(selectedData: any) {
    this.sendLockerMapCenterLat = selectedData.data.geometry.location.lat;
    this.sendLockerMapCenterLng = selectedData.data.geometry.location.lng;
    this.sendLockerZoomLevel = 14;
  }

  searchReceiveingLocker(selectedData: any) {
    this.receiveLockerMapCenterLat = selectedData.data.geometry.location.lat;
    this.receiveLockerMapCenterLng = selectedData.data.geometry.location.lng;
    this.receiveLockerZoomLevel = 14;
  }

//   Pudo
}

interface Contact {
  name: string;
  surname: string;
  mobile: string;
  email: string;
  labours: number;
}
