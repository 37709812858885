import { Component, OnInit } from '@angular/core';
import {Advert} from '../../models/advert';

@Component({
  selector: 'app-view-advert-details',
  templateUrl: './view-advert-details.component.html',
  styleUrls: ['./view-advert-details.component.css']
})
export class ViewAdvertDetailsComponent implements OnInit {

  name: string;
  title: string;
  startDate: Date;
  endDate: Date;
  advertToView: Advert;

  constructor() { }

  ngOnInit() {
    this.drawFirstGraph();
    this.drawSecondGraph();
    this.advertToView = JSON.parse(localStorage.getItem('advertToView'));
    this.name = this.advertToView.name;
    this.title = this.advertToView.title;
    this.startDate = this.advertToView.startDate;
    this.endDate = this.advertToView.endDate;
  }

  drawFirstGraph() {
    const doc = document.getElementById('firstGraph') as HTMLCanvasElement;
    const ctx = doc.getContext('2d');
    for (let i = 0; i < 5; i++) {
      for (let j = 0; j < (i + 3); j++) {
        ctx.strokeStyle = `rgb(0, ${Math.floor(255 - 42.5 * i)}, ${Math.floor(255 - 42.5 * j)})`;
        ctx.beginPath();
        ctx.arc(12.5 + j * 25, 12.5 + i * 25, 10, 0, 2 * Math.PI, true);
        ctx.stroke();
      }
    }
  }

  drawSecondGraph() {
    const doc = document.getElementById('secondGraph') as HTMLCanvasElement;
    const ctx = doc.getContext('2d');
    // Draw background
    ctx.fillStyle = 'rgb(255, 221, 0)';
    ctx.fillRect(0, 0, 150, 37.5);
    ctx.fillStyle = 'rgb(102, 204, 0)';
    ctx.fillRect(0, 37.5, 150, 37.5);
    ctx.fillStyle = 'rgb(0, 153, 255)';
    ctx.fillRect(0, 75, 150, 37.5);
    ctx.fillStyle = 'rgb(255, 51, 0)';
    ctx.fillRect(0, 112.5, 150, 37.5);

    // Draw semi transparent rectangles
    for (let i = 0; i < 10; i++) {
      ctx.fillStyle = `rgba(255, 255, 255, ${(i + 1) / 10})`;
      for (let j = 0; j < 4; j++) {
        ctx.fillRect(5 + i * 14, 5 + j * 37.5, 14, 27.5);
      }
    }
  }
}
