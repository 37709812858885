import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Response } from '@angular/http';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';


import * as firebase from 'firebase/app';

import { User } from '../../models/user';
import { Login } from '../../models/login';

import { HaversineService, GeoCoord } from "ng2-haversine";
import { BookingService } from '../../services/booking.service';
import { SharedService } from '../../services/shared.service';

declare var $: any;
import { LoginService } from '../../services/login.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.css']
})
export class BookingComponent implements OnInit {

  public userSettings2: any = {
    showRecentSearch: false,
    geoCountryRestriction: ['ZA'],
    inputPlaceholderText: 'Enter pick-up address.'
  };

  public userSettings: any = {
    showRecentSearch: false,
    geoCountryRestriction: ['ZA'],
    inputPlaceholderText: 'Enter drop-Off address.'
  };

  @ViewChild('pass', { static: true }) pass: ElementRef;
  @ViewChild('userN', { static: true }) userN: ElementRef;

  dropOffLat: number;
  dropOffLong: number;

  fullDate: any = [];
  distanceOfTrip: number;
  totalExpressPrice: number;

  vehicleTypeData: any;

  pickUpAddress: string;
  dropOffAddress: string;
  pickUpTime: string;
  pickUpDate: string;
  promoCode: string;
  vehicleType: string;
  mon: number;
  usedTotal: number;
  loader: boolean = false;
  terms: boolean;
  termsControl: boolean = false;
  error: string;
  user: User;
  login: Login;
  numberOTP: string = '';

  hour: number;
  loginPassword: string;
  loginUsername: string;
  errorMsg: string;
  promoStatus: string = '';
  public savedReturnedUrl: string = "";

  @ViewChild('userName', { static: false }) name: ElementRef;
  @ViewChild('userSurname', { static: false }) surname: ElementRef;
  @ViewChild('userEmail', { static: false }) email: ElementRef;
  @ViewChild('phoneNo', { static: false }) phone: ElementRef;
  @ViewChild('userPwd', { static: false }) userPasword: ElementRef;

  @ViewChild('otp', { static: false }) otpNum: ElementRef;

  Uname: string;
  Usurname: string;
  Uphone: string;
  Uemail: string;
  pwd: string;
  comments: string;

  OTPerror: string = '';
  confirming: boolean = false;

  contact: Contact = {
    name: '',
    surname: '',
    mobile: '',
    email: '',
    labours: 0
  };

  datePipe: DatePipe;

  bakkie: string = '';
  mini: string = '';
  truck: string = '';
  bigTruck: string = '';

  classContainer: string = 'col-md-11';

  dropContact: Contact = {
    name: '',
    surname: '',
    mobile: '',
    email: '',
    labours: 0
  };

  bookingText: string;
  disablePickUp: boolean = false;
  disableDropOff: boolean = false;

  tempAmount: number;
  vehicleOid: string;

  pickUpChecked: any;
  dropOffChecked: any;

  dropFloorNo: number = 0;
  floorNo: number = 0;

  quoteData: any = {};
  pickUpLat: number;
  pickUpLong: number;
  distance: number;
  pickUpNotFound: boolean = true;
  pickUpNotMsg: string = 'Location not found, Please enter nearest location.';

  dropOffNotFound: boolean = true;
  dropOffNotMsg: string = 'Location not found, Please enter nearest location.';

  pickUpCoordinate  = [];
  dropOffCoordinate = [];

  pickUpCoordinateLocation : any = {};
  dropOffCoordinateLocation : any = {};

  dropOid : string;
  bookingObj: any = {};

  constructor(private _haversineService: HaversineService,
    private bookService: BookingService, private loginService: LoginService,
    private sharedService: SharedService, private router: Router,private loadservice:LoaderService) {
    this.classContainer = 'col-md-12';


  }

  ngOnInit() {
    this.savedReturnedUrl = localStorage.getItem('payURef');
    console.log(localStorage.getItem('payURef'));
    if (location.href == this.savedReturnedUrl) {
      var object = JSON.parse(localStorage.getItem('bookingDataObject'));

      this.dropOid = localStorage.getItem("dropOid");
      console.log(this.dropOid);
      $('#myTab a[href="#step4"]').tab('show');
      $(".paying").addClass("linkDisabled");
      $(".sign-up-tab").addClass("successColor");
      $(".sign-up-icon").addClass("successIcon");
      $(".info-home").addClass("successColor");
      $(".info-icons").addClass("successIcon");

      this.classContainer = 'col-md-9'
      this.pickUpAddress = object.pickUpAddress;
      this.dropOffAddress = object.dropOffAddress;
      this.pickUpDate = object.pickUpDate;
      this.pickUpTime = object.pickUpTime;
      this.tempAmount = object.price;
      this.dropContact.name = object.dropOff.firstName;
      this.dropContact.surname = object.dropOff.lastName;
      this.contact.name = object.pickUp.firstName;
      this.contact.surname = object.pickUp.lastName;
      this.floorNo = object.pickUpFloors;
      this.dropFloorNo = object.dropFloors;
      this.contact.labours = object.labour;
      this.comments = object.comment;
      this.pickUpCoordinateLocation = object.pickUpCoordinate;
      this.dropOffCoordinateLocation = object.dropOffCoordinate;

       this.distance = parseInt(localStorage.getItem('distance'));
      this.vehicleType = object.vehicleType;
      this.syncPayment(localStorage.getItem('token'), localStorage.getItem('paymentReference'));

    }

  }

  autoCompleteCallback1(selectedData: any) {
    //do any necessery stuff.
    //console.log(selectedData);
    if (!selectedData) {
      this.pickUpNotMsg = 'Location not found, Please enter nearest location.';
      this.pickUpNotFound = false;
    } else {
      this.pickUpAddress = selectedData.description;
      this.pickUpLat = selectedData.geometry.location.lat;
      this.pickUpLong = selectedData.geometry.location.lng;

      this.pickUpCoordinate  = [selectedData.geometry.location.lat, selectedData.geometry.location.lng];
      this.pickUpCoordinateLocation = {
        "coordinates" : this.pickUpCoordinate,
        "address" : this.pickUpAddress
      };

      console.log(this.pickUpCoordinateLocation);
    }

  }

  autoCompleteCallback2(selectedData: any) {
    //do any necessery stuff.
    //console.log(selectedData);
    if (!selectedData) {
      this.dropOffNotMsg = 'Location not found, Please enter nearest location.';
      this.dropOffNotFound = false;
    } else {
      this.dropOffAddress = selectedData.description;
      this.dropOffLat = selectedData.geometry.location.lat;
      this.dropOffLong = selectedData.geometry.location.lng;
      this.dropOffCoordinate = [selectedData.geometry.location.lat, selectedData.geometry.location.lng];
      this.dropOffCoordinateLocation  = {
        "coordinates" : this.dropOffCoordinate,
        "address" : this.dropOffAddress
      };
      //console.log();
    }

  }

  getDistance(): number {

    let pickCordinate: GeoCoord = {
      latitude: this.pickUpLat,
      longitude: this.pickUpLong
    };

    let dropCordinate: GeoCoord = {
      latitude: this.dropOffLat,
      longitude: this.dropOffLong
    };

    //let meters = this._haversineService.getDistanceInMeters(madrid, bilbao);
    let kilometers = this._haversineService.getDistanceInKilometers(pickCordinate, dropCordinate);
    //let miles = this._haversineService.getDistanceInMiles(madrid, bilbao);

    //  console.log(`
    //      The distance between pickUp and DropOff is:
    //          - ${kilometers} kilometers

    //  `);
    return kilometers;
  }

  public getQuote() {


    var realTime = new Date(this.pickUpDate);
    var currentDate = new Date();
    var dateFormat = currentDate.toLocaleString('en-us', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');
    var pickUpdateFormat = realTime.toLocaleString('en-us', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');

    var dateSame = pickUpdateFormat == dateFormat;
    var dateDiffer = pickUpdateFormat > dateFormat;

    let TimeSeparatorIndex = this.pickUpTime.indexOf(":");
    this.hour = parseInt(this.pickUpTime.substring(0, TimeSeparatorIndex));

    if (this.pickUpTime != undefined && this.pickUpDate != undefined) {

      if (this.pickUpAddress != null && this.dropOffAddress != null) {

        if (dateSame == true || dateDiffer == true) {
          if (this.hour >= 7 && this.hour <= 17) {
            this.loadservice.addloader();
            this.distance = Math.round(this.getDistance());
            localStorage.setItem('distance', this.distance +"");
            this.bookService.getVehicleRate(this.vehicleType)
              .subscribe(
              (res: Response) => {
                this.vehicleTypeData = res.json();
                // console.log(this.vehicleTypeData);
                this.vehicleOid = this.vehicleTypeData.oid;
                if (Math.round(this.getDistance()) <= 10) {
                  this.distanceOfTrip = this.distance;
                  this.totalExpressPrice = this.vehicleTypeData.basePrice + (this.vehicleTypeData.kilometer * this.distance);
                } else {

                  this.distance -= 10;
                  this.distanceOfTrip = this.distance;
                  this.totalExpressPrice = this.vehicleTypeData.basePrice + (this.vehicleTypeData.kilometer * this.distance);
                }


                //this.fullDate = this.pickUpDate.split("/");
                this.mon = realTime.getMonth() + 1;
                //console.log(this.mon);

                let day = realTime.getDate() < 10 ? "0" + realTime.getDate() : realTime.getDate();
                let month = this.mon < 10 ? "0" + this.mon : this.mon;
                let year = realTime.getFullYear();

                let date = year + "-" + month + "-" + day;
                //console.log(date);
                this.quoteData = {
                  pickUpAddress: this.pickUpAddress,
                  dropOffAddress: this.dropOffAddress,
                  distance: Math.round(this.getDistance()),
                  pickUpDate: date,
                  pickUpTime: this.pickUpTime,
                  price: this.totalExpressPrice,
                  vehicleType: this.vehicleType
                };
                this.classContainer = 'col-md-9';
                // console.log(this.quoteData);
                this.sharedService.setTotalAmount(this.totalExpressPrice);
                this.tempAmount = this.totalExpressPrice;

                var today = new Date();
                var dd = String(today.getDate());
                var mm = String(today.getMonth() + 1); //January is 0!

                var yyyy = today.getFullYear();
                if (parseInt(dd) < 10) {
                  dd = '0' + dd;
                }
                if (parseInt(mm) < 10) {
                  mm = '0' + mm;
                }
                var todayDate = dd + '/' + mm + '/' + yyyy;

                var timestamp = new Date().valueOf();
                var bookQuote = firebase.database().ref('webBookingQuote');
                bookQuote.child(timestamp + "").set({
                  id: timestamp,
                  pickUpAddress: this.pickUpAddress,
                  dropOffAddress: this.dropOffAddress,
                  price: this.totalExpressPrice,
                  date: date,
                  time: this.pickUpTime,
                  vehicleType: this.vehicleType,
                  created: todayDate
                });

                this.loadservice.removeloader();
                //$(".signup").removeClass("linkDisabled");
                //$('#myTab a[href="#step2"]').tab('show');
                window.scrollTo(0, 0);
              },
              (err) => {
                this.loadservice.removeloader();
               // alert("Error occured, please contact Droppa.");
                $('#errorContactDroppa').modal('show');
              }
              )


          } else {
            this.loadservice.removeloader();
            $('#workingHours').modal('show');
            //alert("working hours are from 07:00 A.M to 17:00 PM");
          }
        } else {
          $('#InvalidPickupDate').modal('show');
          //alert("Error, invalid pick up date. The date already passed.");
        }

      } else {
        $('#addresses').modal('show');
        //alert("Please enter all addresses.");
      }
    } else {
      $('#confirmOtp').modal('show');
      //alert("Please fill the date and time.");
    }
  }

  public onLogIn() {

    if (this.userN.nativeElement.value != null && this.pass.nativeElement.value != null) {
      this.loadservice.addloader();
      this.loginService.login(this.pass.nativeElement.value, this.userN.nativeElement.value).subscribe(
        (response: any) => {

          localStorage.removeItem('userData');
          localStorage.setItem('userData', JSON.stringify(response));
          localStorage.setItem('token', response.token);
          //console.log(response.json());
          this.loadservice.removeloader();
          $(".info").removeClass("linkDisabled");
          $('#myTab a[href="#step3"]').tab('show');
          $(".signup").addClass("linkDisabled");

          //Add successful colors
          $(".sign-up-tab").addClass("successColor");
          $(".sign-up-icon").addClass("successIcon");
          //$(".sign-up-tab").removeClass("successColor");
          ////$(".sign-up-icon").removeClass("successIcon");
          window.scrollTo(0, 0);
        },
        (err) => {
          this.loadservice.removeloader();
          this.errorMsg = "Wrong password or Username";
        }
      );

    } else {
      this.loadservice.removeloader();
      this.errorMsg = "Enter password and username";
    }

  }

  public continues() {
    $(".signup").removeClass("linkDisabled");
    $('#myTab a[href="#step2"]').tab('show');
    window.scrollTo(0, 0);
  }

  public register() {

    this.error = '';
    this.loadservice.addloader();
    this.user = new User(this.name.nativeElement.value, this.surname.nativeElement.value,
      this.email.nativeElement.value, this.phone.nativeElement.value);
    this.login = new Login(this.userPasword.nativeElement.value, this.email.nativeElement.value);
    this.user.login = this.login;
    this.numberOTP = this.phone.nativeElement.value;
    this.loginService.register(this.user)
      .subscribe(
      (response: any) => {
        //console.log(response);
        //this.storage.add('userData', jsontext);
        this.confirming = true;
        this.loadservice.removeloader();
        localStorage.removeItem('userData');
        localStorage.removeItem('signedUser');
        localStorage.setItem('userData', JSON.stringify(response));
        localStorage.setItem("signedUser", JSON.stringify({
          email: this.email.nativeElement.value,
          password: this.userPasword.nativeElement.value
        }));
        //$(".info").removeClass("disabled");
        //$('#myTab a[href="#step3"]').tab('show');
      },
      (err) => {
        this.loadservice.removeloader();
        this.error = "Error while registering, mobile or email already exist.";
        console.log(err)
      }
      );
  }


  public confirmAcc() {
    this.loadservice.addloader();
    var object = JSON.parse(localStorage.getItem('signedUser'));
    //console.log(object);
    if (this.otpNum.nativeElement.value != null) {
      this.loginService.confirmOTP(this.otpNum.nativeElement.value, this.numberOTP)
        .subscribe(
        (response: Response) => {
          // console.log(response.json());
          if (response.status == 200) {
            if (response.json().confirmed) {

              this.loginService.login(object.password, object.email).subscribe(
                (response: any) => {
                  localStorage.setItem('userData', JSON.stringify(response));
                  localStorage.setItem('token', response.token);

                  this.loadservice.removeloader();
                  $(".info").removeClass("linkDisabled");
                  $('#myTab a[href="#step3"]').tab('show');
                  $(".signup").addClass("linkDisabled");

                  //Add successful colors
                  $(".sign-up-tab").addClass("successColor");
                  $(".sign-up-icon").addClass("successIcon");
                  window.scrollTo(0, 0);
                  //$(".sign-up-tab").removeClass("successColor");
                  ////$(".sign-up-icon").removeClass("successIcon");
                }
              );
            } else {
              this.loadservice.removeloader();
              this.OTPerror = "Error, the OTP number provided is invalid.";
            }

          } else {
            this.loadservice.removeloader();
            this.OTPerror = "Error, please enter valid OTP.";
          }
        },
        (err) => {
          this.loadservice.removeloader();
          this.OTPerror = "Error while registering, mobile or email already exist.";
        }
        );

    } else {
      this.loadservice.removeloader();
      alert("Error please enter the otp");
    }
  }

  public onInformation() {
    this.loadservice.addloader();

    var timestamp = new Date().valueOf();
    var object = JSON.parse(localStorage.getItem('userData'));
    var token = localStorage.getItem('token');

    var datePipe = new DatePipe("en-US");
    let value = datePipe.transform(this.pickUpDate, 'yyyy-MM-dd');

    var bookingData = {
      "dropOff": {
        "firstName": this.dropContact.name,
        "lastName": this.dropContact.surname,
        "phone": this.dropContact.mobile,
        "email": this.dropContact.email
      },
      "pickUp": {
        "firstName": this.contact.name,
        "lastName": this.contact.surname,
        "phone": this.contact.mobile,
        "email": this.contact.email
      },

      "customer": object.owner,
      "price": this.tempAmount,
      "vehicleType": this.vehicleType,
      "pickUpAddress": this.pickUpAddress,
      "pickUpTime": this.pickUpTime,
      "phoneNo": object.owner.mobile,
      "pickUpDate": value,
      "dropOffAddress": this.dropOffAddress,
      "comment": this.comments,
      "labour": this.contact.labours,
      "timestamp": timestamp,
      "status": "AWAITING_DRIVER",
      "dropFloors": this.dropFloorNo,
      "pickUpFloors": this.floorNo,
      "pickUpCoordinate" : this.pickUpCoordinateLocation,
      "dropOffCoordinate" : this.dropOffCoordinateLocation
    };
    localStorage.setItem('bookingDataObject', JSON.stringify(bookingData));
    this.createDrop();
    //  $(".info").removeClass("linkDisabled");
    // $('#myTab a[href="#complete"]').tab('show');
    // $(".info").addClass("linkDisabled");

    //Add successful colors
    $(".info-home").addClass("successColor");
    $(".info-icons").addClass("successIcon");

  }

  public onHome() {
    this.router.navigateByUrl('/');
  }

  public canceBooking() {
    if (window.confirm('Are you sure you want to cancel')) {
      // do things if OK
    }
  }

  public confirmBooking() {

    var timestamp = new Date().valueOf();
    var object = JSON.parse(localStorage.getItem('userData'));
    var token = localStorage.getItem('token');

    //console.log(this.pickUpDate.toString().slice(-4));

    //this.datePipe = new DatePipe("dd/MM/yyyy");
    //console.log(this.datePipe.transform);
    var datePipe = new DatePipe("en-US");
    let value = datePipe.transform(this.pickUpDate, 'yyyy-MM-dd');

    this.loadservice.addloader();
    var completeBooking = {
      "dropOff": {
        "firstName": this.dropContact.name,
        "lastName": this.dropContact.surname,
        "phone": this.dropContact.mobile,
        "email": this.dropContact.email
      },
      "pickUp": {
        "firstName": this.contact.name,
        "lastName": this.contact.surname,
        "phone": this.contact.mobile,
        "email": this.contact.email
      },

      "customer": object.owner,
      "price": this.tempAmount,
      "vehicleType": this.vehicleType,
      "pickUpAddress": this.pickUpAddress,
      "pickUpTime": this.pickUpTime,
      "phoneNo": object.owner.mobile,
      "pickUpDate": value,
      "dropOffAddress": this.dropOffAddress,
      "comment": this.comments,
      "labour": this.contact.labours,
      "timestamp": timestamp,
      "status": "AWAITING_DRIVER",
      "dropFloors": this.dropFloorNo,
      "pickUpFloors": this.floorNo,
      "pickUpCoordinate" : this.pickUpCoordinateLocation,
      "dropOffCoordinate" : this.dropOffCoordinateLocation,
      "dropOid" : this.dropOid
    };

    //this.bookingObj = localStorage.getItem('bookingDataObject')

    console.log(completeBooking);
    this.bookService.book(completeBooking, token)
      .subscribe(
      (data: any) => {
        this.loadservice.removeloader();
        this.bookingText = "Booking successful.";
        localStorage.removeItem('payUurl');
        localStorage.removeItem('bookingDataObject');
        localStorage.removeItem('distance');
        localStorage.removeItem('payURef');
        $('#results').modal('show');
        // console.log(data);
        window.scrollTo(0, 0);
      },
      (err: any) => {
        //console.log(err);
        this.loadservice.removeloader();
        this.bookingText = "Booking error, please contact Droppa Team.";
      }
      )
  }

  public onPickFloor(floor: number) {

    if (floor != null) {

      this.tempAmount = this.sharedService.getTotalAmt() + ((this.floorNo * 20) +
        (this.dropFloorNo * 20)) + (this.contact.labours * 50);
    } else {
      this.tempAmount = this.sharedService.getTotalAmt() + ((this.floorNo * 20) +
        (this.dropFloorNo * 20)) + (this.contact.labours * 50);

    }
  }

  public onDropFloor(floorNo: number) {
    if (floorNo != null) {
      this.tempAmount = this.sharedService.getTotalAmt() + ((this.floorNo * 20) +
        (this.dropFloorNo * 20)) + (this.contact.labours * 50);

    } else {
      this.tempAmount = this.sharedService.getTotalAmt() + ((this.floorNo * 20) +
        (this.dropFloorNo * 20)) + (this.contact.labours * 50);
    }
  }

  public onLabour(labour: number) {
    if (labour != null) {
      this.tempAmount = this.sharedService.getTotalAmt() + ((this.floorNo * 20) +
        (this.dropFloorNo * 20)) + (this.contact.labours * 50);
    } else {

      this.tempAmount = this.sharedService.getTotalAmt() + ((this.floorNo * 20) +
        (this.dropFloorNo * 20)) + (this.contact.labours * 50);
    }
  }

  public checkedDropUp(dropOff: any) {
    var object = JSON.parse(localStorage.getItem('userData'));
    if (dropOff) {
      this.dropOffChecked = true;
      this.dropContact.name = object.owner.firstName;
      this.dropContact.email = object.owner.email;
      this.dropContact.mobile = object.owner.mobile;
      this.dropContact.surname = object.owner.surname;
    } else {
      this.dropOffChecked = false;
      this.dropContact.name = '';
      this.dropContact.email = '';
      this.dropContact.mobile = '';
      this.dropContact.surname = '';
    }
  }

  public checkedPickup(pickUp: any) {
    var object = JSON.parse(localStorage.getItem('userData'));
    if (pickUp) {
      this.disablePickUp = true;
      this.contact.name = object.owner.firstName;
      this.contact.email = object.owner.email;
      this.contact.mobile = object.owner.mobile;
      this.contact.surname = object.owner.surname;
      //this.contact.

    } else {
      this.disablePickUp = false;
      this.contact.name = '';
      this.contact.email = '';
      this.contact.mobile = '';
      this.contact.surname = '';
    }
  }

  /**
   * promo code ng-on-change listener
   */
  public promo(promoValue) {

    if (promoValue != null) {
      if (promoValue.length == 6) {
        var promo = firebase.database().ref('promoCode/' + promoValue.toUpperCase());
        this.promoStatus = "Searching, please wait.";
        promo.on('value', (datasnapshot) => {
          if (datasnapshot.val()) {
            // console.log(datasnapshot.val().status + " " + datasnapshot.val().used);
            this.usedTotal = datasnapshot.val().used;
            if (datasnapshot.val().status == "Active") {
              this.tempAmount = (this.sharedService.getTotalAmt() + ((this.floorNo * 20) +
                (this.dropFloorNo * 20)) + (this.contact.labours * 50)) - datasnapshot.val().price;

              this.promoStatus = "Discount of R" + datasnapshot.val().price + " granted.";
              promo.off();

              var promoUpdate = firebase.database().ref('promoCode/' + promoValue.toUpperCase());
              promoUpdate.update({ used: this.usedTotal + 1 });
              promoUpdate.off();
            } else {
              this.promoStatus = "Promo code is deactivated.";
            }

          } else {
            this.promoStatus = "Invalid Code, no match found.";
          }

        });


        promo

      } else {
        this.promoStatus = "";
      }
    }

  }

  public backHome() {
    this.loadservice.addloader();
    $(".signup").addClass("linkDisabled");
    $(".info").addClass("linkDisabled");
    $(".paying").addClass("linkDisabled");
    //remove success
    $(".sign-up-tab").removeClass("successColor");
    $(".sign-up-icon").removeClass("successIcon");
    $(".info-home").removeClass("successIcon");
    $(".info-icons").removeClass("successIcon");
    $('#myTab a[href="#step1"]').tab('show');
    this.loadservice.removeloader();
  }

  public onPaymentComplete() {
    $(".paying-link").removeClass("linkDisabled");
    $('#myTab a[href="#complete"]').tab('show');
    $(".paying-link").addClass("linkDisabled");

    //Add successful colors
    $(".pay-home").addClass("successColor");
    $(".pay-icon").addClass("successIcon");
    window.scrollTo(0, 0);
  }

  public createDrop() {
    var object = JSON.parse(localStorage.getItem('userData'));
    var token = localStorage.getItem('token');

    var currentDate = new Date();
    let date = currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate();
    let monthNo = (currentDate.getMonth() + 1) <= 9 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
    let hour = currentDate.getHours() <= 9 ? '0'+currentDate.getHours() : currentDate.getHours();
    let minutes = currentDate.getMinutes() <= 9 ? '0' + currentDate.getMinutes() : currentDate.getMinutes();
    var second = currentDate.getSeconds() <= 9 ?  '0'+currentDate.getSeconds() : currentDate.getSeconds();
    //var dropTime = currentDate.getFullYear() + '-' + monthNo + '-' + date + 'T' + currentDate.getHours() + ':' + currentDate.getMinutes() + ':' + currentDate.getSeconds();

    var dropTime = currentDate.getFullYear() + '-' + monthNo + '-' + date + 'T' + hour + ':' + minutes + ':' + second;
    console.log(dropTime);

    var drop = {
      "name": "Drop",
      "notes": "be careful",
      "cost": this.tempAmount * 100,
      "clientOid": object.owner.oid,
      "vehicleOid": '1521105441081',
      "driverOid": "575ae6bd30bc5d5017477143",
      "from": {
        "coordinates": [
          "-26.002483",
          "28.099503"
        ],
        "address": this.pickUpAddress,
      },
      "to": {
        "coordinates": [
          "-26.002483",
          "28.099503"
        ],
        "address": this.dropOffAddress,
      },
      "when": dropTime,
      "atp": dropTime,
      "eta": dropTime,
      "ata": dropTime,
      "droplets": [
        {
          "itemOid": "575ae6bd30bc5d5017477143",
          "notes": "new"
        }
      ]
    };
    //var popup;
    this.loadservice.addloader();
    this.bookService.createDrop(drop, token)
      .subscribe(
      (res: Response) => {
        console.log(res.json());
        var paymentData = {
          oid: res.json().oid,
          dropReference: res.json().oid,
          transactionType: "RESERVE",
          paymentMethod: "CASH"
        };
        //console.log(paymentData);
        this.bookService.requestPayment(paymentData, token)
          .subscribe(
          (response: Response) => {
            var options = "width=500,height=500";
            //var paymentWindow = window.open(response.json().redirectBaseUrl + response.json().gatewayTransactionReference, '_blank', options);
            //$.initWindowMsg();
            // console.log(response.json().transactionReference);
            //location.href = response.json().redirectBaseUrl + response.json().gatewayTransactionReference;
            localStorage.removeItem('payURef');
            localStorage.removeItem('payUurl');
            localStorage.removeItem("dropOid");
            localStorage.removeItem('paymentReference');
            localStorage.setItem('payUurl', location.href + "?PayUReference=" + response.json().gatewayTransactionReference);
            localStorage.setItem('payURef', location.href + "?PayUReference=" + response.json().gatewayTransactionReference);
            localStorage.setItem('paymentReference', response.json().transactionReference);
            localStorage.setItem("dropOid", res.json().oid);
            // console.log(res.json().oid);

            location.replace(response.json().redirectBaseUrl + response.json().gatewayTransactionReference);
            this.loadservice.removeloader();
            //var popup = window.open(response.json().redirectBaseUrl + response.json().gatewayTransactionReference, "child", options);

            /*if (window.focus) {
              popup.focus();
              console.log(popup);
              console.log(window);
              popup.window.addEventListener('load', (event) => {
                console.log(event);
              });
            }

            if (popup.closed) {
              console.log(popup);
              console.log("I closed");
            }*/

            //popup.opener.
            /*if (window.addEventListener) {
              window.addEventListener('message', onMessageReceive, false);
            } else {
              // for IE6 - IE10
              //window. .attachEvent('onmessage', onMessageReceive);
            } */

            /*function onMessageReceive(event) {
              if (event.origin === response.json().redirectBaseUrl + response.json().gatewayTransactionReference) {
                alert("I am working");
              }else{
                console.log(event);
                alert("I am not working");
              }
            }*/


            //window.location.href = response.json().redirectBaseUrl + response.json().gatewayTransactionReference;
            /*popup.addEventListener('loadstop', (event) => {
              console.log(event);
            });*/


            //function SetName() {
            if (window.opener != null && !window.opener.closed) {
              //var txtName = window.opener.document.getElementById("txtName");
              // txtName.value = document.getElementById("ddlNames").value;
              // console.log("The popup closed");
            }
            // popup.close();
            //console.log(popup);

          },
          (err) => {
            this.loadservice.removeloader();
            console.log(err);
          }
        );

      },
      (error) => {
        this.loadservice.removeloader();
        console.log(error);
      }
      );

  }

  onSelectChange(vehicleType) {
    if (vehicleType == 'ONE_TON_TRUCK') {
      this.mini = "";
      this.bakkie = "glyphicon glyphicon-ok";
      this.truck = "";
      this.bigTruck = "";
    } else if (vehicleType == 'FOUR_TON_TRUCK') {
      this.mini = "";
      this.bakkie = "";
      this.truck = "";
      this.bigTruck = "glyphicon glyphicon-ok";
    } else if (vehicleType == 'ONE_HALF_TRUCK') {
      this.mini = "";
      this.bakkie = "";
      this.truck = "glyphicon glyphicon-ok";
      this.bigTruck = "";
    } else if (vehicleType == 'MINI_VAN') {
      this.mini = "glyphicon glyphicon-ok";
      this.bakkie = "";
      this.truck = "";
      this.bigTruck = "";
    }
  }

  public backButton() {
    this.loadservice.removeloader();
    $(".info").removeClass("linkDisabled");
    $('#myTab a[href="#step3"]').tab('show');
    $(".finish").addClass("linkDisabled");
    window.scrollTo(0, 0);
  }

  public gohome() {
    $(".sign-up-tab").removeClass("successColor");
    $(".sign-up-icon").removeClass("successIcon");
    $(".info-home").removeClass("successIcon");
    $(".info-icons").removeClass("successIcon");
    window.scrollTo(0, 0);
  }
  public checkedTerms(terms) {
    console.log(terms);
    if (terms) {
      $(".information").removeClass("linkDisabled");
      this.termsControl = true;
    } else {
      this.termsControl = false;
      $(".information").addClass("linkDisabled");
    }


  }

  public openPopup() {
    var options = "location=no,clearcache=no,toolbar=no";
    var paymentWindow = window.open("https://www.droppa.co.za/droppa/termsAndCondition", '_blank', options);
  }

  public syncPayment(token, ref) {
    this.bookService.retrievePayments(token, ref).subscribe(
      (res) => {
        console.log(res);
        var finalPayment = {
          oid : res.oid,
          transactionReference : res.transactionReference,
          gatewayTransactionReference : res.gatewayTransactionReference,
          transactionType : "FINALIZE"
        };

        this.bookService.finalizePayment(token, finalPayment).subscribe(
          (response) => {
            console.log(response);
          }
        )

      }
    );
  }

}

interface Contact {
  name: string;
  surname: string;
  mobile: string;
  email: string;
  labours: number;
}
