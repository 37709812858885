import {Component, OnInit, ViewChild} from '@angular/core';
import {Response} from '@angular/http';
import {NgForm} from '@angular/forms';
import {AdminService} from '../../services/admin.service';
import {UserService} from '../../services/user.service';
import {LoginService} from '../../services/login.service';
import {DatePipe} from '@angular/common';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {LoaderService} from 'src/app/services/loader.service';
import {BookingService} from '../../services/booking.service';
import {PageEvent} from "angular2-datatable";

declare var $: any;


@Component({
  selector: 'app-view-user',
  templateUrl: './view-user.component.html',
  styleUrls: ['./view-user.component.css']
})
export class ViewUserComponent implements OnInit {

  constructor(private adminService: AdminService, private router: Router, private partnerService: UserService, private loginService: LoginService, private loadservice: LoaderService, private bookingService: BookingService) {
    this.user = JSON.parse(localStorage.getItem('selectedUser'));
    // console.log(this.user);
    this.adminObj = JSON.parse(localStorage.getItem('userInfo'));
    this.walletUserDetails = JSON.parse(localStorage.getItem('walletDetails'));

    this.adminObj.roles.forEach(element => {
      if (element.code == 'finance_user') {
        this.financeAllowed = true;
      }
      if (element.code == 'super_admin') {
        this.superUserRole = true;
      }
    });

    this.userRoles = this.user.roles;
    this.user.roles.forEach(x => {
      this.roleValidation = x.code;
      if (this.roleValidation == 'partner') {
        this.partnerRole = true;
      }
      if (this.roleValidation == 'retail') {
        this.retailRole = true;
      }

      if (this.roleValidation == 'api') {
        this.apiRole = true;
      }

    });

    this.selectedTabID = localStorage.getItem('activeTab');
    localStorage.setItem('activeID', this.selectedTabID);
  }

  @ViewChild('companyForm', {static: true}) compaanyForm: NgForm;
  @ViewChild('bankForm', {static: false}) addVForm: NgForm;


  user: any = {};
  companyOwner: any = {};
  roles: any = [];
  temproles: any = [];
  selectedRole: any;
  responseMsg = '';
  loader = false;
  role;
  image: string;
  isBank: any;
  searchedUser: any;
  messege: string;
  msg: string;

  company: any = {};
  roleValidation: any;
  partnerRole: boolean;
  retailRole: boolean;
  apiRole: boolean;
  financeAllowed: boolean;
  superUserRole = false;

  companyVehicles: any = [];
  userCompany: any = {};
  hasCompany = false;

  diskExpiryDate: any;
  companyName = '';
  companyReg = '';
  make = '';
  model = '';
  registration = '';
  vinNo = '';
  Vcolor = '';
  vehicleType = '';
  error = '';
  province: string;
  responseTxt = '';
  canopy = false;
  ID: string;
  vehicleFeedback: any;

  vehiclesTypeRate: any = [];
  bank: any;
  accountNo: number;
  bankName: string;
  message = '';
  bankFeedBack = '';
  accountName: string;
  branchCode: string;
  vehicles: any;

  results: any;
  deliverys: any = [];
  deliveries: any = [];
  drivers: any = [];

  userRoles: any = [];

  total: number;
  page = 1;

  itemsPage = 1;
  totalVehicles: number;
  vehiclesPage = 1;
  mySubscription: any;

  retailValidator = true;
  retailCompanyName: any;
  retailCompanyReg: any;
  selectedTabID: any;

  provinceUpdate: any;

  updatedAmt: number;
  type: any;
  walletMsg: any;

  public adminObj: any;
  public walletUserDetails: any;
  public _displayAllSingleUserBookings: any; // Display all bookings to a selected user
  public transactionDetails: any;

  users: any = [];
  existingUserEmails = [];
  existingUserMobile = [];
  history: any = [];
  alertMessage;
  title;
  userAlert;

  allow;

  companyDetails: any;
  vehicleDetails: any;
  alertBtn = false;

  removalVehicleMsg = '';
  removalVehicleBoolean = false;

  userMobile = '';
  mobileMsg = '';
  displayRedMsg = false;
  displayGreenMsg = false;

  userEmail = '';
  emailMsg = '';

  showBookings = false;
  showTransactions = false;
  walletInfo: any;

  totalNumberOfBookings = 0;
  pageNumber = 0;
  itemsPerPage = 10;
  pageSizeOptions: number[] = [10, 25];
  pageEvent: PageEvent;

  totalNumberOfTransactions = 0;
  pageNumberTransactions = 0;
  itemsPerPageTransactions = 10;
  pageSizeOptionsTransactions: number[] = [10, 25];
  pageEventTransactions: PageEvent;


  fromDateTransactions: any;
  toDateTransactions: any;

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('selectedUser'));
    const object = JSON.parse(localStorage.getItem('userInfo'));

    this.adminService.getRoles(object.token).subscribe((res) => {
      if (this.superUserRole) {
        this.roles = res;
      } else {
        res.forEach(role => {
          if (role.code !== 'super_admin' && role.code !== 'finance_user') {
            this.roles.push(role);
          }
        });
      }
    });

    this.showBookings = true;
    // this.showTransactions = true;
    this.showAllBookingsToASelectedUser();
    this.getVehicleTypeRates();
    this.getUserCompany();
    this.getUsers();
    this.getAlertMessage();

    // this.getWallet();
  }

  public getUserCompany() {
    const object = JSON.parse(localStorage.getItem('userInfo'));
    this.partnerService.retrieveCompany(object.token, this.user.oid)
      .subscribe(
        (response: Response) => {
          response.json().forEach(element => {
            this.companyDetails = element;
          });
          this.loadservice.removeloader();
          if (response != null) {
            if (response.json()[0] != null) {
              this.company = response.json()[0];
              this.getOwner(this.company.ownerOid);
              this.hasCompany = false;
              this.getDeliveries(object.token, this.company.oid);
              this.partnerService.retrieveVehicles(object.token, this.company.oid)
                .subscribe(
                  (res: Response) => {
                    if (res.status == 200) {
                      this.companyVehicles = res.json();
                      this.companyVehicles.forEach(element => {
                        this.vehicleDetails = element;
                      });
                      for (const truck of this.companyVehicles) {
                        if (truck.driverOid != null) {
                          this.getDrivers(object.token, truck.driverOid);
                        }
                      }
                    }
                  }
                );
            } else {
              this.hasCompany = true;
              this.userCompany = null;
              this.loadservice.removeloader();
            }
          }
        }
      ), (error) => {

    };
  }

  public getDeliveries(token, companyOid) {
    this.partnerService.retrieveDeliveris(token, companyOid)
      .subscribe(
        (res: Response) => {
          if (res.status == 200) {
            this.deliverys = res.json();
            for (let m = 0; m < this.deliverys.length; m++) {
              this.deliveries.push(this.deliverys[m]);
              this.deliveries;
            }
          }
        },
        (err) => {
        }
      );
  }

  public getDrivers(token, driverOid) {
    this.partnerService.retrieveDrivers(token, driverOid)
      .subscribe(
        (res: Response) => {
          if (res.status == 200) {
            this.drivers.push(res.json());
          }
        },
        (err) => {
        }
      );
  }

  public saveAlert() {
    const alertObj = {
      to: this.user.oid,
      message: this.alertMessage,
      title: this.title,
      type: 'INDIVIDUAL'
    };
    this.loadservice.addloader();
    this.adminService.saveAlert(this.adminObj.token, alertObj).subscribe(
      (res) => {
        console.log(res);
        this.loadservice.removeloader();
        this.alertBtn = false;
      },
      (error) => {
        console.log(error);
        this.loadservice.removeloader();
      }
    );
  }

  public getAlertMessage() {
    this.loadservice.addloader();
    this.partnerService.getAlertMessage(this.adminObj.token, this.user.oid)
      .subscribe((res) => {

        this.userAlert = res.json();
        if (!this.userAlert.to) {
          this.alertBtn = true;
        } else if (this.userAlert.seen == false) {
          this.alertBtn = false;
        } else {
          this.alertBtn = false;
        }
        console.log(res.json());
        this.loadservice.removeloader();
      }, (error) => {
        console.log(error);
        this.loadservice.removeloader();
      }, () => {
        this.loadservice.removeloader();
      });
  }

  public getOwner(companyID) {
    const object = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.getOwner(object.token, companyID).subscribe(
      (res) => {
        this.companyOwner = res;
      }
    );
  }

  onActivateApiUser() {
    const object = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.activateApiUser(object.token, this.user.owner.oid).subscribe(
      (res) => {
        this.msg = 'Api user successfully activated.';
        $('#results').modal('show');
      },
      (err) => {
        this.msg = err.error.message;
        $('#results').modal('show');
      }
    );
  }

  public onAddCompany() {
    const object = JSON.parse(localStorage.getItem('userInfo'));
    const dateValue = this.diskExpiryDate;
    const realTime = new Date(dateValue);
    const diskDate = realTime.toLocaleString('en-us', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }).replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');
    this.loadservice.addloader();
    const companyInfo = {
      companyName: this.companyName,
      companyReg: this.companyReg,
      province: this.province,
      make: this.make,
      model: this.model,
      registration: this.registration,
      vinNo: this.vinNo,
      Vcolor: this.Vcolor,
      type: this.vehicleType,
      hasCanopy: this.canopy,
      diskDate: diskDate
    };
    this.adminService.companyInfo(companyInfo, this.user, object.token)
      .subscribe(
        (response: Response) => {
          this.compaanyForm.reset();
          if (response != null) {
            this.loadservice.removeloader();
            this.msg = 'Company and vehicle successfully added to the user.';
            $('#results').modal('show');
          } else {
            this.loadservice.removeloader();
            this.msg = 'Error while adding company please contact Droppa.';
            $('#results').modal('show');
          }
        },
        (err) => {
          console.log(err);
          this.loadservice.removeloader();
          this.msg = 'Error while adding company';
          $('#results').modal('show');
        }
      );
  }

  public getVehicleTypeRates() {
    this.adminService.vehicleTypeRates().subscribe(
      (res) => {
        this.vehiclesTypeRate = res;
      }
    );
  }

  public checkedCanopy(canopy: any) {
    if (canopy) {
      this.canopy = true;
    } else {
      this.canopy = false;
    }
  }

  public bankDetails(bank) {
    this.accountNo = bank.accountNo;
    this.bankName = bank.bankName;
    this.branchCode = bank.branchCode;
    this.accountName = bank.accountName;
  }

  public AddBankDetails() {
    const object = JSON.parse(localStorage.getItem('userInfo'));
    const account = {
      accountNo: this.accountNo,
      bankName: this.bankName,
      branchCode: this.branchCode,
      accountName: this.accountName,
      companyOid: this.company.oid
    };
    if (this.bank == null) {
      this.adminService.bankAccount(account, object.token).subscribe(
        (res) => {
          this.bank = res;
          this.msg = 'Bank account added';
          $('#results').modal('show');
          this.addVForm.reset();
          this.ngOnInit();
        },
        (error) => {
          this.msg = 'Error while add bank account added';
          $('#results').modal('show');
        }
      );
    } else {
      const accountUpdate = {
        oid: this.bank.oid,
        accountNo: this.accountNo,
        bankName: this.bankName,
        branchCode: this.branchCode,
        accountName: this.accountName,
        companyOid: this.company.oid
      };
      this.adminService.updateBankAccount(accountUpdate, this.bank.oid, object.token).subscribe(
        (res) => {
          this.bank = res;
          this.msg = 'Bank account updated';
          $('#results').modal('show');
          this.addVForm.reset();
          this.ngOnInit();
        },
        (error) => {
          this.msg = 'Error while add bank account added';
          $('#results').modal('show');
        }
      );
    }
  }

  public getBankAccount() {
    const object = JSON.parse(localStorage.getItem('userInfo'));
    if (this.company.oid != null) {
      this.adminService.getBankAccount(object.token, this.company.oid).subscribe(
        (res) => {
          this.bank = res;
          this.isBank = false;
        },
        (err) => {
          this.isBank = true;
        }
      );
    }
  }

  public onVehicle(vehicle) {
    localStorage.setItem('selectedVehicle', JSON.stringify(vehicle));
    this.router.navigate(['/update-vehicle']);
  }

  public onBooking(book) {
    localStorage.setItem('selectedBooking', JSON.stringify(book));
    this.router.navigate(['/view-user-bookings']);
  }

  select(selectedRole) {
    this.selectedRole = selectedRole;
  }

  onRoleUpdated() {
    if (this.selectedRole != null) {
      const object = JSON.parse(localStorage.getItem('userInfo'));
      this.adminService.getUserByEmail(object.token, this.user.owner.email).subscribe(
        (res) => {
          this.loadservice.addloader();
          this.adminService.addRole(object.token, res.oid, this.selectedRole.oid).subscribe(
            (data) => {
              this.loadservice.removeloader();
              this.msg = 'Role Successfully updated.';
              $('#results').modal('show');
              this.user = data;
              localStorage.setItem('selectedUser', JSON.stringify(this.user));
              this.userRoles = this.user.roles;
              if (this.selectedRole.code == 'partner') {
                this.partnerRole = true;
              }
              if (this.selectedRole.code == 'retail') {
                this.retailRole = true;
              }
              if (this.selectedRole.code == 'api') {
                this.apiRole = true;
              }
            },
            (error) => {
              this.loadservice.removeloader();
              this.msg = 'Role not updated.';
              $('#results').modal('show');
            }
          );
        },
        (error) => {
          this.loadservice.removeloader();
          this.msg = 'Error while retrieving the user, contact IT support..';
          $('#results').modal('show');
        }
      );
    } else {
      alert('Please select the role.');
    }

  }

  public blockUser() {
    const object = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.blockUser(object.token, this.user.owner.email).subscribe(
      (res) => {
        alert('User is blocked');
      },
      (error) => {
        alert('Error while blocking user..');
      }
    );
  }

  public blockUserMsg() {
    const data = {
      username: this.user.owner.email,
      message: this.messege
    };
    const object = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.blockUserMessege(object.token, data).subscribe(
      (res) => {
        this.user = res;
        localStorage.setItem('selectedUser', JSON.stringify(this.user));
        this.msg = 'User is blocked';
      },
      (error) => {
        this.msg = 'Error while blocking user';
      }
    );
  }

  public activateUser() {
    const object = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.activateUser(object.token, this.user.owner.email).subscribe(
      (res) => {
        this.user = res;
        localStorage.setItem('selectedUser', JSON.stringify(this.user));
        alert('User is activated');
      },
      (error) => {
        alert('Error while activating user..');
      }
    );
  }

  public addRetailCompany() {
    const token = localStorage.getItem('token');
    this.loadservice.addloader();
    const comppanyInfo = {
      companyName: this.retailCompanyName,
      companyReg: this.retailCompanyReg
    };

    this.loginService.company(comppanyInfo, token, this.user)
      .subscribe(
        (response: Response) => {
          if (response.status == 200) {
            this.retailValidator = false;
            this.loadservice.removeloader();
            this.msg = 'Retail company added successfully.';
            $('#results').modal('show');
            window.scrollTo(0, 0);
          } else {
            this.loadservice.removeloader();
            this.msg = 'Error while adding company please contact Droppa.';
            $('#results').modal('show');
          }
        },
        (err) => {
          this.loadservice.removeloader();
          this.msg = 'Error while adding company.';
          $('#results').modal('show');
        }
      );
  }

  public updateCompanyProvince() {
    const object = JSON.parse(localStorage.getItem('userInfo'));
    this.loadservice.addloader();
    this.adminService.updateCompanyProvice(object.token, this.company.oid, this.provinceUpdate).subscribe(
      (response) => {
        this.company = response;
        this.message = 'Province successfully updated.';
        this.loadservice.removeloader();
      }, error => {
        this.message = 'Could not update province.';
        this.loadservice.removeloader();
      }
    );
  }

  public updateWalletBalance() {
    const object = JSON.parse(localStorage.getItem('userInfo'));

    const walletBody = {
      accountOid: this.user.oid,
      amount: this.updatedAmt,
      actionType: this.type
    };

    this.adminService.updateWallet(object.token, walletBody).subscribe(
      (response) => {
        this.walletMsg = 'Wallet successfully updated.';
      }, (error) => {
        console.log(error);
        this.walletMsg = 'Error, unable to update/create the wallet.';
      }
    );
  }

  public showAllBookingsToASelectedUser() {

    this.showBookings = true;
    this.showTransactions = false;


    console.log(this.user.owner.oid);

    const filter = {
      oid: this.user.owner.oid,
      pageNumber: this.pageNumber,
      pageTotalItems: this.itemsPerPage
    };

    this.loadservice.addloader();
    console.log(filter)
    this.partnerService.retrieveBookingPaginated(this.adminObj.token, filter).subscribe(
      (displayAllBookings: any) => {
        console.log(displayAllBookings);
        this._displayAllSingleUserBookings = displayAllBookings.content;
        this.totalNumberOfBookings = displayAllBookings.totalElement;
        this.loadservice.removeloader();
      });
  }

  public activateUserAccount() {
    const object = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.enableUserAccount(object.token, this.user.owner.oid).subscribe(
      response => {
        alert('User profile has been activated');
      }, error => {
        console.log(error);
      }
    );
  }

  public removeVehicleModal(vehicle) {
    $('#removeVehicle').modal('show');
  }

  public removeVehicle() {
    const object = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.removeVehicleFromCompany(object.token, this.vehicleDetails.oid, this.companyDetails.oid).subscribe(
      response => {
        this.removalVehicleBoolean = true;
        this.removalVehicleMsg = 'Vehicle Successfully Removed';
        alert('Vehicle Successfully Removed');
      }, error => {
        console.log(error);
      }
    );
  }

  public checkExistingUserMobile(comparedString: string): boolean {
    const testUser = this.existingUserMobile.includes(comparedString, 0);
    return testUser;
  }

  public changeUserMobile() {
    const object = JSON.parse(localStorage.getItem('userInfo'));
    if (this.userMobile.length != 10 || this.userMobile == undefined || this.userMobile == '') {
      this.mobileMsg = 'Please enter the full 10 digits';
      this.displayRedMsg = true;
      this.displayGreenMsg = false;
    } else if (this.checkExistingUserMobile(this.userMobile)) {
      this.mobileMsg = 'This phone number already exists';
      this.displayRedMsg = true;
      this.displayGreenMsg = false;
    } else {
      this.adminService.changeUserMobileNum(object.token, this.user.owner.oid, this.userMobile).subscribe(
        response => {
          localStorage.setItem('selectedUser', JSON.stringify(this.user));
          // this.userMobile = '';
          this.mobileMsg = 'Mobile number updated.';
          this.displayRedMsg = false;
          this.displayGreenMsg = true;
        }, error => {
          console.log(error);
        });
    }
  }

  public checkExistingUserEmail(comparedString: string): boolean {
    const testUser = this.existingUserEmails.includes(comparedString, 0);
    return testUser;
  }

  public changeUserEmail() {
    const object = JSON.parse(localStorage.getItem('userInfo'));
    this.loadservice.addloader();
    const emailBody = {
      email: this.userEmail
    };
    if (this.userEmail == undefined || this.userEmail == '') {
      this.emailMsg = 'Please enter email before proceeding';
      this.displayRedMsg = true;
      this.displayGreenMsg = false;
    } else if (this.checkExistingUserEmail(this.userEmail)) {
      this.emailMsg = 'This email already exists.';
      this.displayRedMsg = true;
      this.displayGreenMsg = false;
    } else {
      this.adminService.updateUserEmail(object.token, this.user.owner.oid, emailBody).subscribe(
        response => {
          // this.userEmail = '';
          localStorage.setItem('selectedUser', JSON.stringify(response));
          this.emailMsg = 'Email updated.';
          this.displayRedMsg = false;
          this.displayGreenMsg = true;
          this.loadservice.removeloader()
        }, error => {
          console.log(error);
          this.loadservice.removeloader()
        }
      );
    }
  }

  public getUsers() {
    this.loadservice.addloader();
    const object = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.getUsers(object.token)
      .subscribe(
        (res) => {
          this.users = res.reverse();
          this.users.forEach(element => {
            this.existingUserEmails.push(element.owner.email);
            this.existingUserMobile.push(element.owner.mobile);
          });
          this.loadservice.removeloader()
        },
        (err) => {
          if (err.statusText == 'Unknown Error' || err.statusText == 'Internal Server Error') {
            this.router.navigate(['/logon']);
          }
          this.loadservice.removeloader()
        }
      );
  }

  public getWalletTransactions(userAccountID) {

    const filter = {
      oid: userAccountID,
      pageNumber: this.pageNumberTransactions,
      pageTotalItems: this.itemsPerPageTransactions
    };


    this.loadservice.addloader();
    console.log(this.user);
    this.bookingService.getTransactionsPaginate(this.adminObj.token, filter).subscribe(
      response => {
        console.log(response)
        this.history = response.content;
        this.totalNumberOfTransactions = response.totalElement;
        this.loadservice.removeloader()
      }, error => {
        console.log(error);
        this.loadservice.removeloader()
      });
  }

  public getWallet() {

    this.loadservice.addloader();
    this.showBookings = false;
    this.showTransactions = true;
    console.log(this.user);
    this.bookingService.getWalletInfo(this.adminObj.token, this.user.owner.userAccountOid).subscribe(
      response => {
        this.walletInfo = response;
        console.log(response);
        this.getWalletTransactions(response.oid);
        this.loadservice.removeloader()
      }, error => {
        console.log(error);
        this.loadservice.removeloader()
      });
  }


  getNewData(pageEvent?: any) {
    this.pageNumber = pageEvent.pageIndex;
    this.itemsPerPage = pageEvent.pageSize;
    this.showAllBookingsToASelectedUser();
  }

  getNewDataTransactions(pageEvent?: any) {
    this.pageNumberTransactions = pageEvent.pageIndex;
    this.itemsPerPageTransactions = pageEvent.pageSize;
    this.getWallet();
  }

  downloadTransactions() {
    const formattedFromDate = this.fromDateTransactions.toISOString().slice(0, 16);
    const formattedToDate = this.toDateTransactions.toISOString().slice(0, 16);

    const dumpBody = {
      personId: this.user.owner.oid,
      addBookings: false,
      addWalletTransactions: true,
      bookingsType: [],
      walletTypes: [],
      startTime: formattedFromDate,
      endTime: formattedToDate,
    }

    this.adminService.downloadWalletDumps(this.adminObj.token, dumpBody)
      .subscribe(
        response => {
          console.log(response);
          this.downloadFile(response.downloadPdfURL)
          this.loadservice.removeloader()
        }, error => {
          console.log(error);
          this.loadservice.removeloader()
        }
      );


    console.log(this.toDateTransactions)
  }

  public downloadFile(base64String: string) {
    var element = document.createElement('a');
    var currentDate = new Date();
    var datePipe = new DatePipe("en-US");
    let value = datePipe.transform(currentDate, 'yyyy-MM-dd');

    var mimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"; // Excel MIME type

    element.setAttribute('href', 'data:' + mimeType + ';base64,' + base64String);
    element.setAttribute('download', value + " - Transactions.xlsx");
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

}
