import { Component, OnInit, ViewChild,isDevMode } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Response } from "@angular/http";
import { Router } from "@angular/router";
import "rxjs/Rx";
import { LoginService } from "../../services/login.service";
import { User } from "../../models/user";
import { Login } from "../../models/login";
import { from } from "rxjs/observable/from";
import {UserLoggInService} from "../../services/loggedInUser.service";
import {UserService} from "../../services/user.service";
import { LoaderService } from "src/app/services/loader.service";
declare var $: any;

@Component({
  selector: "app-become-retail",
  templateUrl: "./become-retail.component.html",
  styleUrls: ["./become-retail.component.css"],
})
export class BecomeRetailComponent implements OnInit {
  @ViewChild("userForms", { static: true }) signupForm: NgForm;
  @ViewChild("otpForm", { static: true }) otpForm: NgForm;
  @ViewChild("infoForm", { static: true }) infoForm: NgForm;

  isDevMode=isDevMode()

  public passwordEye: string = "glyphicon glyphicon-eye-open";
  public passwordConfirmEye = "glyphicon glyphicon-eye-open";
  public toggleEye: boolean = false;
  public toggleConfirmEye = false;
  public passwordType: string = "password";
  public passwordConfirmType = 'password';

  name: string;
  surname: string;
  email: string;
  phone: string;
  landline: string;
  password: string;
  passwordConfirm: string;

  otpValidator: boolean = true;
  companyInfoValidator: boolean = true;
  documentValidator: boolean = true;

  OTPerror: string = "";
  numberOTP: string;
  loader: boolean = false;
  otploader: boolean = false;
  error: string;
  user: User;
  loginObj: Login;
  color: string;
  otp: number;

  companyName: string;
  companyReg: string;

  userObject: any;
  captcha: any;

  documentMsg: string = "";

  enteredPickUpAddress: string = "";
  enteredPickUpSuburb: string = "";
  enteredPickUpPostalCode: string = "";
  enteredCity: string = "";
  customFleetRates = false;
  successMsg: any;
  retailMsg: string = "";
  accountManagerDetails: any;
  regError:Boolean=false;
  maincountDown:any;
  step = 1;//change back to 1
  numMin=1;
  otpCountDown=59
  registered = false;
  diplayEmailtxt=false;
  countDownTodisplay:any;
  stopCount=false;

  isRobot=true
  capture: any;
  UAT_SITE_KEY:String
  captureError=""

  constructor(private loginService: LoginService, private router: Router, private userLogged: UserLoggInService,
              private userRetailService: UserService, private route: Router,private loadservice:LoaderService) {}

  ngOnInit() {
    window.scrollTo(0, 0);
    this.UAT_SITE_KEY=this.loginService.SITE_KEY
  }

  public countDown(){
    this.otpCountDown=this.otpCountDown-1;
    if(this.otpCountDown==0){
      this.numMin=this.numMin+1;
      this.otpCountDown=60*this.numMin;
      this.stopCount=true;
      this.stopInterval(this.maincountDown);
      if(!this.diplayEmailtxt){
        this.diplayEmailtxt=true
      }
    }
  }
  public sendOTPagain(){
    this.stopCount=false;
    this.startCountDown();
    //
    this.loginService.sendOTPagain(this.phone).subscribe(
      (response: any) =>{
        console.log(response);
      },
      (err) =>{
        console.log(err);
      }
    )
  }

  otpEmailSent = false;

  public sendOTPemail(){
    //
    this.loginService.sendOTPemail(this.phone).subscribe(
      (response: any) =>{
        console.log(response);
        this.otpEmailSent = true;
      },
      (err) =>{
        console.log(err);
      }
    )
  }
  public startCountDown(){
  this.maincountDown=  setInterval(() => {
      this.countDown()
    }, (1000));
  }
  public stopInterval(interval) {
    clearInterval(interval);
  }
  public checkNameValidation(firstname):Boolean{
    const namelist=firstname.split(" ");
    let isChecked=true;
    namelist.forEach(name => {
      if(!(/^[a-zA-Z]+$/.test(name))){
        isChecked=false;
      }
    });
    return isChecked;
  }

  public register() {

    this.documentMsg = '';
    this.regError = false;
    this.error = '';
    this.OTPerror = '';

    // this.otpValidator = false;
    // window.scrollTo(0, 0);
    // this.loadservice.re();
    if (this.name === '' || this.surname === '' || this.email === '' || this.phone === ''
      || this.password === '' || this.passwordConfirm === '') {
      this.documentMsg = 'Ensure that all fields are filled';
      return;
    }

    if ( this.phone.length < 10) {
      this.documentMsg = 'Ensure that mobile number is 10 (ten) characters';
      return;
    }

    if (this.password !== this.passwordConfirm) {
      this.documentMsg = 'The entered passwords do not match';
      return;
    }

    this.user = new User(
      this.name,
      this.surname,
      this.email.toLowerCase().trim(),
      this.phone
    );

    this.loginObj = new Login(this.password, this.email.toLowerCase().trim());
    this.user.login = this.loginObj;
    this.numberOTP = this.phone;

    if (this.checkNameValidation(this.name) && this.checkNameValidation(this.surname)) {

      this.loadservice.addloader()

      this.loginService.registerRetailUser(this.user).subscribe(
        (response: any) => {
          this.color = 'rgb(28, 193, 239)';
          this.loadservice.removeloader();
          localStorage.removeItem('userData');
          localStorage.removeItem('signedUser');
          localStorage.setItem('userData', JSON.stringify(response));
          localStorage.setItem('signedUser', JSON.stringify({email: this.email.toLowerCase().trim(), password: this.password, }));
          this.userObject = response;
          this.otpValidator = false;
          $('.info').removeClass('checkedTab');
          $('.info').addClass('btn_default');
          $('#myTab button[href="#otp"]').tab('show');
          this.setStep(3);
          this.startCountDown();
          // $(".otp").removeClass("checkedTab");
          // $(".otp").addClass("btn_default");
          // $('#myTab button[href="#otp"]').tab('show');
        },
        (err) => {
          // console.log(err);
          this.regError = true;
          this.loadservice.removeloader();
          this.color = 'red';
          this.error = 'Error while registering, mobile or email already exist.';

          // $("#results").modal("show");
        }
      );
    } else {
      this.loadservice.removeloader();
      this.regError = true;
      this.error = 'Name or surname should not contain special characters and numbers.';
      // $("#results").modal("show");
    }
  }

  public setStep(step) {
    this.step = step;
  }

  public confirmOTP() {

    this.documentMsg = '';
    this.regError = false;
    this.error = '';
    this.OTPerror = '';
    this.otploader=true;
    const object = JSON.parse(localStorage.getItem('signedUser'));
    if (this.otp != null) {
      // this.loadservice.a();
      this.loginService.confirmOTP(this.otp, this.numberOTP).subscribe(
        (response: Response) => {
          if (response.status === 200) {
            if (response.json().confirmed) {
              this.loginService.login(object.password, object.email.toLowerCase().trim()).subscribe(
                (resp: any) => {
                  localStorage.setItem('token', resp.token);
                  localStorage.removeItem('userData');
                  localStorage.setItem('userData2', resp);
                  localStorage.setItem('userData', resp);
                  this.userObject = resp;
                  this.sendCompanyInformation();


                  // this.companyInfoValidator = false;
                  // $(".info").removeClass("checkedTab");
                  // $(".info").addClass("btn_default");
                  // $('#myTab button[href="#companyDetails"]').tab('show');
                  $('.otp').removeClass('checkedTab');
                  $('.otp').addClass('btn_default');
                  $('#myTab button[href="#companyDetails"]').tab('show');
                  this.setStep(3);
                  this.diplayEmailtxt=false
                  // this.documentMsg="Registration successfully, our team will be in contact to verify the account. Thank you.";
                  // this.registered = true;
                  // $("#registerRetail").modal("hide");
                  // $("#results2").modal("show");

                  // window.scrollTo(0, 0);
                });
            } else {
              this.loadservice.removeloader();
              this.regError = true;
              this.OTPerror = 'Error, the OTP number provided is invalid.';
              this.error = 'Error, the OTP number provided is invalid.';
              this.loadservice.removeloader();
              this.otploader=false;
              this.otpValidator = false;
              // $("#results").modal("show");
            }
          } else {
            this.loadservice.removeloader();
            this.regError = true;
            this.loadservice.removeloader();
            this.otploader=false;
            this.otpValidator = false;
            this.OTPerror = 'Error, please enter valid OTP.';
            this.error = 'Error, please enter valid OTP.';
            // $("#results").modal("show");
          }
        },
        (err) => {
          this.loadservice.removeloader();
          this.regError = true;
          this.loadservice.removeloader();
          this.otploader=false;
          this.otpValidator = false;
          this.OTPerror = 'Error occurred, please contact Droppa.';
          this.error = 'Error occurred, please contact Droppa.';
          // $("#results").modal("show");
        }
      );
    } else {
      this.loadservice.removeloader();
      this.regError = true;
      this.error = 'Error please enter the OTP.';
      // $("#results").modal("show");
    }
  }

  sendCompanyInformation() {
    this.loginService.company2(this.companyDetails, this.userObject).subscribe(
      (response: Response) => {
        if (response.status === 200) {

          this.documentValidator = false;
          this.loadservice.removeloader();
          this.documentMsg = 'Business profile successfully created';
          this.otploader = false;
          this.otpValidator = false;
          this.diplayEmailtxt = false;
          this.registered = true;
          /*this.otpValidator = false;
          this.registered = false;
          this.companyInfoValidator = false;*/
          /* $(".otp").removeClass("checkedTab");
          $(".otp").addClass("btn_default");
          $('#myTab button[href="#otp"]').tab('show');*/
          // this.setStep(3);
          // this.companyInfoValidator = false;
          // $(".info").removeClass("checkedTab");
          // $(".info").addClass("btn_default");
          // $('#myTab button[href="#companyDetails"]').tab("show");
          //  $('#results2').modal('show');
        } else {
          this.loadservice.removeloader();
          this.regError = true;
          this.error = 'Error while adding company please contact Droppa.';
          // $("#results").modal("show");
        }
      },
      (err) => {
        this.loadservice.removeloader();
        this.regError=true;
        this.OTPerror = "Error while adding company";
        this.error = "Error while adding company.";
        // $("#results").modal("show");
      }
    );
  }

  public registerpopup() {
    $('#registerRetail').modal('show');
  }

  hasReferral = false;
  regReferralCode = '';
  referralMsg = '';

  public successFullRegister() {
    $('#successFullRegister').modal({backdrop: 'static', keyboard: false});
  }

  referralSuccess = false;
  referralFail = false;

  checkReferral() {
    this.referralSuccess = false;
    this.referralFail = false;
    this.referralMsg = '';
    // const object = JSON.parse(localStorage.getItem('userData'));
    // console.log(object);
    console.log(this.userObject);
    const referralCodeData = {
      code: this.regReferralCode,
      userID: this.userObject.owner.oid
    };

    console.log(referralCodeData);

    this.loginService.applyReferralCode(referralCodeData).subscribe(
      (response) => {
        console.log(response);
        this.referralMsg = 'Your wallet has been credited with R' + response.amount + '.';
        this.referralSuccess = true;
      },
      (error) => {
        console.log(error);
        this.referralMsg = error.error.message; // 'The entered referral code is invalid. Try again or click "Skip" to go to your profile page.';
        this.referralFail = true;
      }
    );
  }
  // public captureAddress(){
  //   this.adminService.captureAddressOnRetail(this.adminUser.token, this.retail.oid, addressBody).subscribe(
  //     response => {
  //       localStorage.setItem('selectedRetail', JSON.stringify(response.json()));
  //       this.retail = response.json();
  //       this.enteredPickUpAddress = '';
  //       this.enteredPickUpSuburb = '';
  //       this.enteredCity = '';
  //       this.enteredPickUpPostalCode = '';
  //       this.successMsg = "Address added successfully";
  //     }, error => {
  //       console.log(error);
  //       this.successMsg = "Error";
  //     }, () => {

  //     }
  //   )
  // }

  companyDetails: any;

  public companyInfo() {
    this.documentMsg = '';
    this.regError = false;
    this.error = '';
    this.OTPerror = '';
    if (
      this.enteredPickUpAddress === '' ||
      this.enteredPickUpSuburb === '' ||
      this.enteredCity === '' ||
      this.enteredPickUpPostalCode === ''
    ) {
      this.loadservice.removeloader();
      this.documentMsg = 'Please enter all the required information';
      return;
      // $("#results").modal("show");
    }
    const addressBody = {
      addressLine1: this.enteredPickUpAddress,
      locality: this.enteredPickUpSuburb,
      city: this.enteredCity,
      postalCode: this.enteredPickUpPostalCode,
    };

    // var token = localStorage.getItem('token');
    // this.loadservice.r();
    const comppanyInfo = {
      companyName: this.companyName,
      companyReg: this.companyReg,
      addressBody: addressBody,
    };
    this.captureError=""
    if(this.isRobot&&!this.isDevMode){
      this.captureError = 'Please click the checkbox to varify you are a human.';
    }else{
      this.companyDetails = comppanyInfo;
      this.companyInfoValidator = false;
      this.documentValidator = false;
      this.setStep(2);
    }



    // console.log(comppanyInfo, '<<<<<<<<');

    /*this.loginService.company2(comppanyInfo, this.userObject).subscribe(
      (response: Response) => {
        if (response.status === 200) {

          this.documentValidator = false;
          this.loadservice.r();
          this.documentMsg = 'Retail company added successfully.';
          this.otpValidator = false;
          this.registered = false;
          this.companyInfoValidator = false;
          /!* $(".otp").removeClass("checkedTab");
          $(".otp").addClass("btn_default");
          $('#myTab button[href="#otp"]').tab('show');*!/
          this.setStep(3);
          // this.companyInfoValidator = false;
          // $(".info").removeClass("checkedTab");
          // $(".info").addClass("btn_default");
          // $('#myTab button[href="#companyDetails"]').tab("show");
          //  $('#results2').modal('show');
        } else {
          this.loadservice.r();
          this.regError = true;
          this.error = 'Error while adding company please contact Droppa.';
          // $("#results").modal("show");
        }
      },
      (err) => {
        this.loadservice.r();
        this.regError=true;
        this.OTPerror = "Error while adding company";
        this.error = "Error while adding company.";
        // $("#results").modal("show");
      }
    );*/
  }

  public onHome() {
    this.router.navigateByUrl("/");
  }

  public onLogin() {
    this.router.navigateByUrl("/retail-Signin");
  }

  showPassword() {
    this.toggleEye = !this.toggleEye;
    if (this.toggleEye) {
      this.passwordType = "text";
      this.passwordEye = "glyphicon glyphicon-eye-close";
    } else {
      this.passwordType = "password";
      this.passwordEye = "glyphicon glyphicon-eye-open";
    }
  }

  showConfirmPassword() {
    this.toggleConfirmEye = !this.toggleConfirmEye;
    if (this.toggleConfirmEye) {
      this.passwordConfirmType = "text";
      this.passwordConfirmEye = "glyphicon glyphicon-eye-close";
    } else {
      this.passwordConfirmType = "password";
      this.passwordConfirmEye = "glyphicon glyphicon-eye-open";
    }
  }


  public omit_special_char(e) {
    var k;
    document.all ? (k = e.keyCode) : (k = e.which);
    return (
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      k == 8 ||
      k == 32 ||
      (k >= 48 && k <= 57)
    );
  }
  handleCorrectCaptcha(event) {


    if(event!=null){
      this.isRobot=false
    }else{
      this.isRobot=true

    }

  }
  goToRetailProfile() {
    this.loginService.login(this.password, this.userObject.username.toLowerCase().trim()).subscribe(
      (response: any) => {

        let valid = false;

        console.log(response);
        response.roles.forEach(role => {
          if (role.code === 'retail' || role.code === 'business_User') {
            valid = true;
          }
        });

        if (!valid) {
          // window.alert('You do not have access to this profile type.');
          $('#accessNotAllowed').modal('show');
        } else {
          localStorage.setItem('userInfo', JSON.stringify(response));
          const loginsData = {username: this.userObject.username.toLowerCase().trim(), pwd: response.pwd};
          localStorage.setItem('userInfoBooking', JSON.stringify(response));
          localStorage.setItem('userInfoBookingLogin', JSON.stringify(loginsData));
          this.userLogged.setUser(response);
          if (response.owner.retailId != null) {
            const userToken = response.token;
            this.userRetailService.retriveRetail(userToken, response.owner.retailId).subscribe(
              (res) => {
                this.route.navigateByUrl('/retail-profile');
              }, (error) => {
                this.route.navigateByUrl('/retail-not-active');
              }
            );
          } else {
            // this.route.navigateByUrl('/user');
            window.alert('You do not have access to this site.');
          }
        }
      },
      (err) => {
        // this.errorMsg = err.error.message; //'Invalid username or password.';
      });
  }
}
