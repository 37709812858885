import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';
import { AdminService } from '../../services/admin.service';
import { BookingService } from '../../services/booking.service';
import {PageEvent} from "angular2-datatable";


declare var $: any;

@Component({
  selector: 'app-all-bookings',
  templateUrl: './all-bookings.component.html',
  styleUrls: ['./all-bookings.component.css', '../admin-portal/admin-portal.component.css']
})

export class AllBookingsComponent implements OnInit {

  constructor(private adminService: AdminService, private router: Router, private bookService: BookingService, private loadservice: LoaderService) { }

  loader = false;

  allBookings: any;
  bookingData: any;
  bookings: any = [];
  adminUser: any;
  statusToSearch = '';
  vehicleTypeToSearch = '';
  searchQueryWaybill = 'Track Number';

  // Used to store which table page was last opened
  activePage: number;

  public trackAndWaybillNumberArray: any[] = [
    { value: '1', option: 'Track Number' },
    { value: '2', option: 'Waybill Number' }
  ];

  trackNumberToSearch = '';
  status = 'status';
  msg: any;

  totalBookings: any;
  totalBookingPrice: any;
  totalWeeklyBooking: any;
  gautentBooingCount: any;
  westerCapetBooingCount: any;
  mobileCount: any;
  webCount: any;
  todayCount: any;
  montlyCount: any;
  walletPayment: any;
  bookingFoundData: any;
  foundBooking: any;
  bookingArray: any = [];

  awaitingBookingMsg = '';
  columnWidth: any;

  totalNumberOfBookings = 0;
  pageNumber = 0;
  itemsPerPage = 25;
  pageSizeOptions: number[] = [10, 25];
  pageEvent: PageEvent;

  ngOnInit() {
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    this.trackNumberToSearch = localStorage.getItem('allBookingsPageSearch') !== null ? localStorage.getItem('allBookingsPageSearch') : '';
    this.activePage = localStorage.getItem('allBookingsPageActivePage') !== null ? Number(localStorage.getItem('allBookingsPageActivePage')) : 1;

    this.statusToSearch = localStorage.getItem('searchQueryStatus') !== null ? (localStorage.getItem('searchQueryStatus')) : '';
    this.vehicleTypeToSearch = localStorage.getItem('typeFilter') !== null ? (localStorage.getItem('typeFilter')) : '';
    this.trackNumberToSearch = localStorage.getItem('trackNumberToSearch') !== null ? (localStorage.getItem('trackNumberToSearch')) : '';
    this.pageNumber = localStorage.getItem('pageNumber') !== null ? Number(localStorage.getItem('pageNumber')) : 0;
    this.itemsPerPage = localStorage.getItem('itemsPerPage') !== null ? Number(localStorage.getItem('itemsPerPage')) : 25;
    // this.getBookings();
    this.getBookingsWithNewPagination(1);
    window.scrollTo(0, 0);
  }


  public getBookings() {
    this.loadservice.addloader()
    this.adminService.getAllBookings(this.adminUser.token)
      .subscribe(
        (res) => {
          this.loadservice.removeloader();
          this.bookings = res;
          console.log(this.bookings);
          this.allBookings = this.bookings;
          if (this.trackNumberToSearch !== null && this.trackNumberToSearch !== '') {
            this.searchBookings();
          }
        },
        (err) => {
          this.loadservice.removeloader();
          if (err.statusText === 'Unknown Error' || err.statusText === 'Internal Server Error') {
            this.router.navigate(['/logon']);
          }
        }
      );
  }

  resetPagination(){
    this.pageNumber = 0;
    this.itemsPerPage = 25;
  }
  getBookingsWithNewPagination(retrieveType: number) {
    this.loadservice.addloader();

    if(retrieveType===0)
      this.resetPagination();


    const filter = {
      status: this.statusToSearch,
      type: this.vehicleTypeToSearch,
      waybill: null,
      trackNo: this.trackNumberToSearch,
      pageNumber: this.pageNumber,
      pageTotalItems: this.itemsPerPage
    };

    //console.log(filter);


    this.adminService.getPaginatedBookings(this.adminUser.token, filter).subscribe(
      (res) => {
        this.loadservice.removeloader();
        this.bookings = res.content;
        this.totalNumberOfBookings = res.totalElement;
        this.allBookings = this.bookings;
        //console.log(res.content);
        // if (this.trackNumberToSearch !== null && this.trackNumberToSearch !== '') {
        //   this.searchBookings();
        // }
      },
      (err) => {
        this.loadservice.removeloader();
        if (err.statusText === 'Unknown Error' || err.statusText === 'Internal Server Error') {
          this.router.navigate(['/logon']);
        }
      }
    );
  }


  selectionChanged(){
    //console.log("something changed");
    //console.log(this.statusToSearch);
  }
  getNewData(pageEvent?: any) {
    this.pageNumber = pageEvent.pageIndex;
    this.itemsPerPage = pageEvent.pageSize;
    this.getBookingsWithNewPagination(1);
  }

  public onBooking(book) {
    this.activePage = Math.floor((this.allBookings.indexOf(book) / 25) + 1);
    // console.log(book);
    localStorage.setItem('selectedBooking', JSON.stringify(book));
    localStorage.setItem('allBookingsPageSearch', this.trackNumberToSearch);
    // localStorage.setItem('allBookingsPageActivePage', String(this.activePage));

    localStorage.setItem('searchQueryStatus',this.statusToSearch);
    localStorage.setItem('typeFilter',this.vehicleTypeToSearch);
    localStorage.setItem('trackNumberToSearch',this.trackNumberToSearch);
    localStorage.setItem('pageNumber', String(this.pageNumber));
    localStorage.setItem('itemsPerPage', String(this.itemsPerPage));
    this.router.navigate(['/view-booking']);
  }


  private getTotalBookings(token) {
    this.loadservice.addloader();
    this.adminService.getTotalAllBookings(token)
      .subscribe(
        (res) => {
          this.totalBookings = res.bookingMatrixDTO.count;
          this.totalBookingPrice = res.bookingMatrixDTO.total;
          this.totalWeeklyBooking = res.bookingMatrixDTO.weeklyBookings;
          this.montlyCount = res.bookingMatrixDTO.monthlyBooking;
          this.todayCount = res.bookingMatrixDTO.dailyBooking;
          this.gautentBooingCount = res.bookingMatrixDTO.totalGauteng;
          this.westerCapetBooingCount = res.bookingMatrixDTO.totalWesternCape;
          this.mobileCount = res.bookingMatrixDTO.totalMobile;
          this.webCount = res.bookingMatrixDTO.totalWeb;
          this.loadservice.removeloader()
        },
        (err) => {
          this.loadservice.removeloader()
          if (err.statusText === 'Unknown Error' || err.statusText === 'Internal Server Error') {
            this.router.navigate(['/logon']);
          }
        }
      );
  }
  /**
   * Use a track number to view a single booking
   */
  public searchBooking() {

    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));

    this.selectChangeHandler();
  }

  tempBookings() {
    this.bookings = this.allBookings;
  }

  searchBookings() {
    this.tempBookings();

    if (this.statusToSearch && this.statusToSearch.trim() !== '') {
      this.bookings = this.bookings.filter((item: any) => item.status.toUpperCase().includes(this.statusToSearch.toUpperCase()));
    } else if (this.trackNumberToSearch && this.trackNumberToSearch.trim() !== '') {
      this.bookings = this.bookings.filter((item: any) => item.trackNo.toUpperCase().includes(this.trackNumberToSearch.toUpperCase()));
    } else {
      //  Do nothing
      /*$('#searchBooking').modal('show');
      this.msg = 'Please enter a track number to search for a booking.';
      */
    }
  }

  filterByType() {
    this.tempBookings();

    if (this.vehicleTypeToSearch && this.vehicleTypeToSearch.trim() !== '') {
      this.bookings = this.bookings.filter((item: any) => item.type.toUpperCase().includes(this.vehicleTypeToSearch.toUpperCase()));
    }
  }

  /**
   * @description Model Change the results based on the Tracking or Waybill number
   * @returns null
   */
  public selectChangeHandler(): void {
    // console.log(this.searchUsers);
    if (this.searchQueryWaybill === 'Track Number' && (this.trackNumberToSearch !== undefined && this.trackNumberToSearch !== '')) {

      this.bookService.getBookingByTrackNum(this.trackNumberToSearch.toUpperCase()).subscribe((response: any) => {

        this.bookingArray = response.json();

        this.bookingArray.forEach((foundElement: any) => {
          if (foundElement.trackNo === this.trackNumberToSearch) {
            this.bookingFoundData = foundElement;
          }
        });

        this.loadservice.removeloader()
      }, (error) => {
        console.log(error);
        $('#searchBooking').modal('show');
        this.msg = 'The booking does not exist. Please make sure you entered the right track number.';
        this.loadservice.removeloader()
      });

    } else if (this.searchQueryWaybill === 'Waybill Number' && (this.trackNumberToSearch !== undefined && this.trackNumberToSearch !== '')) {

      this.bookService.getBookingByWaybillNumber(this.trackNumberToSearch.toUpperCase()).subscribe((response: any) => {
        this.bookingFoundData = response.json();
        this.loadservice.removeloader()
      }, (error) => {
        console.log(error);
        $('#searchBooking').modal('show');
        this.msg = 'The booking does not exist. Please make sure you entered the right waybill number.';
        this.loadservice.removeloader()
      });

    } else {
      // Do nothing
    }
  }

  public clearAwaitingPaymentBookings() {
    this.loadservice.addloader()
    this.adminService.removeBookings(this.adminUser.token)
      .subscribe(
        (res) => {
          this.loadservice.removeloader()
          console.log(res);
          this.awaitingBookingMsg = 'Awaiting payment bookings cleared.';
          $('#clearAwaitingPayment').modal('show');
        },
        (err) => {
          this.loadservice.removeloader()
          console.log(err);
          this.awaitingBookingMsg = err.error.message;
          $('#clearAwaitingPayment').modal('show');
          if (err.statusText === 'Unknown Error' || err.statusText === 'Internal Server Error') {
            this.router.navigate(['/logon']);
          }
        }
      );
  }

  shorten(columnValue) {
    if (columnValue) {
      if (columnValue.length > 10) {
        return columnValue.substr(0, 9) + '...';
      } else {
        return columnValue;
      }
    }
  }
  public showTabByRole(role):boolean{
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let result=false;
    userInfo.roles.map(r=>{
      if(role===r.code){
        result=true;
      }
    })
    return result;
  }

  ngOnDestroy(){

    //console.log(this.router.getCurrentNavigation().finalUrl.toString());

    if(this.router.getCurrentNavigation().finalUrl.toString() !== '/view-booking') {
      localStorage.removeItem('pageNumber');
      localStorage.removeItem('itemsPerPage');
      localStorage.removeItem('searchQueryStatus');
      localStorage.removeItem('typeFilter');
      localStorage.removeItem('trackNumberToSearch');
    }
  }
}
