import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Response } from '@angular/http';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { BookingService } from 'src/app/services/booking.service';
import { SharedService } from 'src/app/services/shared.service';
import { UserService } from 'src/app/services/user.service';
import {error} from "protractor";
import { LoaderService } from 'src/app/services/loader.service';
declare var $: any;

@Component({
  selector: 'app-select-payment',
  templateUrl: './select-payment.component.html',
  styleUrls: ['./select-payment.component.css']
})
export class SelectPaymentComponent implements OnInit {

  // Payfast Payment Elements
  @ViewChild('contactForms', { static: true }) contactForms: NgForm;
  @ViewChild('submitButton', { static: false }) submitButton: ElementRef;
  @ViewChild('m_id', { static: false }) merchant_id: ElementRef;
  @ViewChild('m_key', { static: false }) merchant_key: ElementRef;
  @ViewChild('return_url', { static: false }) return_url: ElementRef;
  @ViewChild('cancel_url', { static: false }) cancel_url: ElementRef;
  @ViewChild('notify_url', { static: false }) notify_url: ElementRef;
  @ViewChild('name_first', { static: false }) name_first: ElementRef;
  @ViewChild('name_last', { static: false }) name_last: ElementRef;
  @ViewChild('email_address', { static: false }) email_address: ElementRef;
  @ViewChild('cell_number', { static: false }) cell_number: ElementRef;
  @ViewChild('m_payment_id', { static: false }) m_payment_id: ElementRef;
  @ViewChild('amount', { static: false }) amount: ElementRef;
  @ViewChild('item_name', { static: false }) item_name: ElementRef;
  @ViewChild('item_description', { static: false }) item_description: ElementRef;
  @ViewChild('custom_int1', { static: false }) custom_int1: ElementRef;
  @ViewChild('confirmation_address', { static: false }) confirmation_address: ElementRef;

  // Ozow Payment Elements
  @ViewChild('SiteCode', { static: false}) SiteCode: ElementRef;
  @ViewChild('CountryCode', { static: false}) CountryCode: ElementRef;
  @ViewChild('CurrencyCode', { static: false}) CurrencyCode: ElementRef;
  @ViewChild('Amount', { static: false}) Amount: ElementRef;
  @ViewChild('TransactionReference', { static: false}) TransactionReference: ElementRef;
  @ViewChild('BankReference', { static: false}) BankReference: ElementRef;
  @ViewChild('Customer', { static: false }) Customer: ElementRef;
  @ViewChild('Optional1', { static: false }) Optional1: ElementRef;
  @ViewChild('CancelUrl', { static: false}) CancelUrl: ElementRef;
  @ViewChild('ErrorUrl', { static: false}) ErrorUrl: ElementRef;
  @ViewChild('SuccessUrl', { static: false}) SuccessUrl: ElementRef;
  @ViewChild('NotifyUrl', { static: false}) NotifyUrl: ElementRef;
  @ViewChild('IsTest', { static: false}) IsTest: ElementRef;
  @ViewChild('HashCheck', { static: false}) HashCheck: ElementRef;

  @ViewChild('paymentForm', { static: true }) paymentForm: NgForm;

  payFastUrl: string;
  ozowUrl: string;
  paymentMsg: string = '';
  loader: boolean = false;
  userObj: any;
  paymentOptions = [];
  bookingObj: any;
  message: string = '';
  isBalancePositive: boolean = false;
  // lastScreen: string = '';

  checkPayFast: boolean = false;
  checkOzow: boolean = false;
  checkWallet: boolean = false;
  checkPaygate: boolean = false;

  currentAmt: any;
  walletOID: any;
  walletDetails: boolean = false;

  promoCode: string = '';
  promoCodeMsg: string = '';
  promoCodeValid: boolean;
  promoCodeAmount: number = 0;

  bookingType: string = '';

  bookingCost: number = 0;
  dropCost: number = 0;

  switchPromoBtn: boolean = false;

  payFastResponse: any;

  constructor(private bookingService: BookingService, private sharedService: SharedService, private router: Router, private userService: UserService,private loadservice:LoaderService) { }

  ngOnInit() {
    this.userObj = JSON.parse(localStorage.getItem('userInfo'));
    this.payFastUrl = this.sharedService.getPayFastUrl();
    this.ozowUrl = this.sharedService.getOzowUrl();
    this.bookingObj = JSON.parse(localStorage.getItem('bookingObject'));
    // console.log(this.bookingObj);
    this.bookingType = this.bookingObj.booking.type;
    this.bookingCost = this.bookingObj.booking.price;
    this.dropCost = this.bookingObj.cost;
    this.getPaymentOptions();
    this.retrieveWalletBalance();
  }

  public getPaymentOptions() {
    this.bookingService.listAvailPaymentOptions(this.userObj.token).subscribe(response => {
        this.paymentOptions = response;
        this.paymentOptions.forEach(element => {
          if (element.optionType == 'PAYFAST') {
            this.checkPayFast = element.isActive;
          }
          else if (element.optionType == 'OZOW') {
            this.checkOzow = element.isActive;
          }
          else if (element.optionType == 'WALLET') {
            this.checkWallet = element.isActive;
          }
          else if (element.optionType == 'PAYGATE') {
            this.checkPaygate = element.isActive;
          }
        });
      },
      error => {console.log(error)}
    );
  }


  public payWithOzow() {
    var token = localStorage.getItem('token');
    this.loadservice.addloader();
    this.bookingObj.booking.paymentType = "ONLINE_PAY";

    let ozowAmt = 0;
    let dropAmount = 0;
    let dropObj = this.bookingObj;
    if (this.promoCodeValid) {
      // Normal Amount
      ozowAmt = this.bookingObj.booking.price - this.promoCodeAmount;
      // dropObj.booking.price = ozowAmt;
      // Drop Amount
      // dropAmount = (this.dropCost / 100) - this.promoCodeAmount;
      // dropObj.cost = dropAmount * 100;
      // Promo Applied
      dropObj.booking.promotionCode = this.promoCode.toUpperCase();
    }
    else {
      ozowAmt = this.bookingObj.booking.price;
    }

    this.bookingService.createDrop(dropObj, token).subscribe(response => {
      let ozowObj = {
        "CountryCode": "ZA",
        "CurrencyCode": "ZAR",
        "Amount": ozowAmt,
        "TransactionReference": response.json().oid,
        "BankReference": "",
        "Customer": this.userObj.owner.email,
        "IsTest": true,
        "paymentType": "BOOKING_PAYMENT"
      }
      this.bookingService.requestOzowPayment(ozowObj, token).subscribe((response) => {
        this.loadservice.removeloader();
        this.ozowRedirect(response.json());
      }, (error) => {
        console.log(error);
        this.loadservice.removeloader();
      });
    });
  }
  public ozowRedirect(ozowBody): void {
    localStorage.setItem('paidByRetail', 'false');
    this.SiteCode.nativeElement.value = ozowBody.SiteCode;
    this.CountryCode.nativeElement.value = ozowBody.CountryCode;
    this.CurrencyCode.nativeElement.value = ozowBody.CurrencyCode;
    this.Amount.nativeElement.value = ozowBody.Amount.toFixed(2);
    this.TransactionReference.nativeElement.value = ozowBody.TransactionReference;
    this.BankReference.nativeElement.value = ozowBody.BankReference;
    this.Customer.nativeElement.value = ozowBody.Customer;
    this.Optional1.nativeElement.value = ozowBody.Optional1;
    this.CancelUrl.nativeElement.value = ozowBody.CancelUrl;
    this.ErrorUrl.nativeElement.value = ozowBody.ErrorUrl;
    this.SuccessUrl.nativeElement.value = ozowBody.SuccessUrl;
    this.NotifyUrl.nativeElement.value = ozowBody.NotifyUrl;
    this.IsTest.nativeElement.value = ozowBody.IsTest;
    this.HashCheck.nativeElement.value = ozowBody.HashCheck;
    $("#ozowPayment").trigger("click");
  }


  public payWithPayfast() {
    var token = localStorage.getItem('token');
    const userAuth = JSON.parse(localStorage.getItem('userInfo'));
    this.loadservice.addloader();
    this.bookingObj.booking.paymentType = "ONLINE_PAY";

    let payfastAmt = 0;
    let dropAmount = 0;
    let dropObj = this.bookingObj;
    if (this.promoCodeValid) {
      // Normal Amount
      payfastAmt = this.bookingObj.booking.price - this.promoCodeAmount;
      // dropObj.booking.price = payfastAmt;
      // Drop Amount
      // dropAmount = (this.dropCost / 100) - this.promoCodeAmount;
      // dropObj.cost = dropAmount * 100;
      // Promo Applied
      dropObj.booking.promotionCode = this.promoCode.toUpperCase();
    }
    else {
      payfastAmt = this.bookingObj.booking.price;
    }

    // Check if booking is a new booking or comes from table using the 'paymentFromTable' variable
    const paymentFromTable = localStorage.getItem('paymentFromTable');

    if (paymentFromTable === 'false') {
      debugger;
      localStorage.setItem('paymentFromTable', 'false');
      this.bookingService.createDrop(dropObj, token).subscribe((res: Response) => {
        var paymentData = {
          "name_first": this.userObj.owner.firstName,
          "name_last": this.userObj.owner.surname,
          "email_address": this.userObj.owner.email,
          "cell_number": this.userObj.owner.mobile,
          "m_payment_id": res.json().oid,
          "amount": payfastAmt,
          "customerReference": this.userObj.owner.oid,
          "item_name": 'Droppa Booking',
          "item_description": 'Payment For Delivery',
          "email_confirmation": 0,
          "confirmation_address": this.userObj.owner.email,
          "reference": res.json().oid,
          "paymentType": "BOOKING_PAYMENT"
        };
        this.bookingService.requestPayfastPayment(paymentData, token).subscribe((response: Response) => {
          this.loadservice.removeloader();
          this.redirectToPayFast(response.json());
        }, (error) => {
          console.log(error);
          this.loadservice.removeloader();
        });
      }, (error) => {
        console.log(error);
        this.loadservice.removeloader();
      });
    }
    else {
      localStorage.setItem('paymentFromTable', 'false');
      this.bookingService.reCreateDrop(dropObj.oid, token)
        .subscribe(
          (res: any) => {
            // console.log('My book', book);
            var paymentData = {
              "name_first": this.userObj.owner.firstName,
              "name_last": this.userObj.owner.surname,
              "email_address": this.userObj.owner.email,
              "cell_number": this.userObj.owner.mobile,
              "m_payment_id": res.json().oid,
              "amount": payfastAmt,
              "customerReference": this.userObj.owner.oid,
              "item_name": 'Droppa Booking',
              "item_description": 'Payment For Delivery',
              "email_confirmation": 0,
              "confirmation_address": this.userObj.owner.email,
              "reference": res.json().oid,
              "paymentType": "BOOKING_PAYMENT"
            };
            this.bookingService.requestPayfastPayment(paymentData, token)
              .subscribe(
                (response) => {
                  this.payFastResponse = response.json();
                  this.redirectToPayFast(response.json());
                  this.loadservice.removeloader();
                },
                (err) => {
                  // console.log(err);
                  this.loadservice.removeloader();
                }
              );
          },
          (error) => {
            // console.log(error);
            this.loadservice.removeloader();
          }
        );
    }
  }

  public redirectToPayFast(response) {
    localStorage.setItem('paidByRetail', 'false');
    this.merchant_key.nativeElement;
    this.merchant_id.nativeElement.value = response.merchant_id;
    this.merchant_key.nativeElement.value = response.merchant_key;
    this.return_url.nativeElement.value = response.return_url;
    this.cancel_url.nativeElement.value = response.cancel_url;
    this.notify_url.nativeElement.value = response.notify_url;
    this.name_first.nativeElement.value = response.name_first;
    this.name_last.nativeElement.value = response.name_last;
    this.email_address.nativeElement.value = response.email_address;
    this.cell_number.nativeElement.value = response.cell_number;
    this.m_payment_id.nativeElement.value = response.m_payment_id;
    this.amount.nativeElement.value = response.amount;
    this.item_name.nativeElement.value = response.item_name;
    this.item_description.nativeElement.value = response.item_description;
    this.custom_int1.nativeElement.value = response.custom_int1;
    this.confirmation_address.nativeElement.value = response.confirmation_address;
    $('#btnMakePayment1').trigger('click');
  }


  public payWithWallet() {
    this.loadservice.addloader();
    let token = localStorage.getItem('token');
    let object = JSON.parse(localStorage.getItem('userInfo'));
    this.bookingObj.booking.paymentType = "WALLET_PAY";

    // let walletAmount = 0;
    // let dropAmount = 0;
    let dropObj = this.bookingObj;
    // console.log(dropObj);
    if (this.promoCodeValid) {
      // Normal Amount
      // walletAmount = this.bookingCost - this.promoCodeAmount;
      // dropObj.booking.price = walletAmount;
      // Drop Amount
      // dropAmount = (this.dropCost / 100) - this.promoCodeAmount;
      // dropObj.cost = dropAmount * 100;
      // Promo Applied
      dropObj.booking.promotionCode = this.promoCode.toUpperCase();
    }
    // else {
    //   walletAmount = this.bookingCost;
    // }

    // Check if booking is a new booking or comes from table using the 'paymentFromTable' variable
    const paymentFromTable = localStorage.getItem('paymentFromTable');

    this.bookingService.checkAvailBalance(token, object.oid, this.bookingCost).subscribe(response => {
        this.isBalancePositive = response.isBalancePositive;
        if (this.isBalancePositive) {
          if (paymentFromTable === 'false') {
            this.bookingService.walletDrop(dropObj, token).subscribe(
              (res) => {
                this.loadservice.removeloader();
                localStorage.setItem('dropDataObject', JSON.stringify(res.json()));
                this.router.navigateByUrl('/Confirm-wallet-order/' + this.bookingCost + '/send_parcel');
              },
              (errr) => {
                // console.log(errr);
                this.loadservice.removeloader();
                this.router.navigateByUrl('/Confirm-wallet-order/' + this.bookingCost + '/send_parcel');
                // this.message = 'An error occurred whilst making a booking.';
                // $('#walletPayment').modal('show');
              }
            );
          } else {
            this.bookingService.reCreateDrop(dropObj.oid, token).subscribe(
                (res: any) => {
                  // console.log("recreate data === ", res.json());
                  const dropData = res.json();
                  dropData.booking = dropObj.booking;

                  // console.log("modified dropObj ===", dropData);

                  this.bookingService.walletDrop(dropData, token).subscribe(
                    (resp) => {
                      // console.log('wallet booking =====', res.json());
                      this.loadservice.removeloader();
                      localStorage.setItem('paymentFromTable', 'false');
                      localStorage.setItem('dropDataObject', JSON.stringify(resp.json()));
                      this.bookingService.publishBook(dropData.booking.oid, token).subscribe(
                        (result) => {
                          // console.log("results is publish ===", result);
                          this.router.navigateByUrl('/Confirm-wallet-order');
                        },
                        (err) => {
                        console.log(err);
                        }
                      );
                    },
                    (erro) => {
                      console.log(erro);
                      this.loadservice.removeloader();
                      this.router.navigateByUrl('/Confirm-wallet-order');
                      /*this.message = 'An error occurred whilst making a booking.';
                      $('#walletPayment').modal('show');*/
                    });

                },
                (errors) => {
                  console.log(errors);
                  this.loadservice.removeloader();
                });
          }

        } else {
          this.loadservice.removeloader();
          this.message = 'Your wallet has insufficient funds. Please credit your wallet to proceed with the transaction.';
          $('#walletPayment').modal('show');
        }
      },
      (err) => {
        this.loadservice.removeloader();
        console.log(err);
      }
    );
  }

  public onHome() {
    this.router.navigateByUrl('/user');
  }

  public previousScreen() {
    this.router.navigateByUrl('/droppa-express');
  }

  public retrieveWalletBalance() {
    let object = JSON.parse(localStorage.getItem('userInfo'));
    this.userService.retrieveCurrentBalance(object.token, object.oid).subscribe(
      response => {
        if (response.json().oid != null) {
          this.walletDetails = response.json();
        }

        if (this.currentAmt != 0) {
          this.currentAmt = response.json().amount;
        }
        this.walletOID = response.json().oid;
      }, error => {
        console.log(error);
      }
    );
  }

  public processPromoCode() {
    if (this.promoCode == null || this.promoCode == '') {
      this.promoCodeMsg = 'Please enter a promo code.';
      $('#promoActive').modal('show');
    }
    else {
      let object = JSON.parse(localStorage.getItem('userData'));

      let UsePromoCodeDTO = {
        code:	this.promoCode.toUpperCase(),
        promoType: "",
        userID: object.owner.oid,
        targetAmount:this.bookingObj.booking.price
      }

      if (this.bookingObj.booking.type == 'EXPRESS_COURIER' || this.bookingObj.booking.type == 'BUDGET_COURIER' ) {
        UsePromoCodeDTO.promoType = 'COURIER';
      }
      else if (this.bookingObj.booking.type == 'FLASH') {
        UsePromoCodeDTO.promoType = 'FLASH';
      }

      this.loadservice.addloader();
      this.bookingService.addPromoCode(object.token, UsePromoCodeDTO).subscribe(response => {
        this.promoCodeAmount = response.amount;
        this.loadservice.removeloader();
        // Switch between the green and red buttons for applying promo
        this.switchPromoBtn = true;

        this.promoCodeValid = true;
        this.promoCodeMsg = 'PROMO CODE OF R' + this.promoCodeAmount +' APPLIED.';
        $('#promoActive').modal('show');
      }, error => {
        this.loadservice.removeloader();

        this.promoCodeValid = false;
        this.promoCodeMsg = error.error.message;
        if (this.promoCodeMsg == null || this.promoCodeMsg == undefined) {
          this.promoCodeMsg = 'The Promo Code Does Not Exist.';
        }
        $('#promoActive').modal('show');
      })
    }
  }

  public clearModalText(): string {
    return this.promoCodeMsg = '';
  }

  public removePromoCode() {
    this.switchPromoBtn = false;
    this.promoCode = '';
    this.promoCodeValid = false;
  }

}
