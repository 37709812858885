import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-booking-resert-password',
  templateUrl: './booking-resert-password.component.html',
  styleUrls: ['./booking-resert-password.component.css']
})
export class BookingResertPasswordComponent implements OnInit {

  email: string;
 

  sub : any;
  error : string = '';
  success : string = '';
  submitted : boolean = true;

  constructor(private loginService : LoginService, private router: Router) { }

  ngOnInit() {
  }

  public requestPassword() {
    this.submitted = false;
    this.error = '';
    this.success = '';
    this.loginService.bookingrequestPwdReset(this.email.toLowerCase().trim())
    .subscribe(
      (res : Response) => {
      
        this.router.navigateByUrl('/booking-otp');
        localStorage.setItem('username', this.email);
        if(res.status == 200) {
          this.success = "Email sent, Check your emails for verification.";
        }
      },
      (err)  => {
       
        this.submitted = false;
        this.error = "Email address doesn't exist."
      }
    )

  }

}
