import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-skynet-tracking-via-email',
  templateUrl: './skynet-tracking-via-email.component.html',
  styleUrls: ['./skynet-tracking-via-email.component.css']
})
export class SkynetTrackingViaEmailComponent implements OnInit {

  sub: any;
  bookingOid: any;
  wayBillNumber: string = '';
  status: any = [];

  constructor(private route: ActivatedRoute, private user: UserService, private onGetBookingStatus: UserService) {
    this.sub = this.route.params.subscribe(params => {
      this.bookingOid = params['trackNo'];
    });
   }

  ngOnInit() {
    this.onGetBookingStatus.getBookingTrackNO(this.bookingOid).subscribe(
      response => {
        console.log(response.json());
        this.user.retrieveWayBillStatus(response.json().waybillResponse.waybillNumber).subscribe(
          response => {
            this.status = response;
            console.log(this.status);
          }, error => {
            console.log(error);
          }
        )
      }, error => {
        console.log(error);
      }, () => {

      } 
    )
  }

}
