import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Login } from 'src/app/models/login';
import { User } from 'src/app/models/user';
import { LoaderService } from 'src/app/services/loader.service';
import { LoginService } from 'src/app/services/login.service';
declare var $: any;

@Component({
  selector: 'app-become-hmb-retail',
  templateUrl: './become-hmb-retail.component.html',
  styleUrls: ['./become-hmb-retail.component.css']
})
export class BecomeHmbRetailComponent implements OnInit {

  @ViewChild('userForms', { static: true }) signupForm: NgForm;
  @ViewChild('otpForm', { static: true }) otpForm: NgForm;
  @ViewChild('infoForm', { static: true }) infoForm: NgForm;

  public passwordEye: string = 'glyphicon glyphicon-eye-open';
  public toggleEye: boolean = false;
  public passwordType: string = 'password';

  name: string;
  surname: string;
  email: string;
  phone: string;
  password: string;
  otpValidator: boolean = true;
  companyInfoValidator: boolean = true;
  documentValidator: boolean = true;
  OTPerror: string = "";
  numberOTP: string;
  loader: boolean = false;
  error: string;
  user: User;
  loginObj: Login;
  color: string;
  otp: number;
  companyName: string;
  companyReg: string;
  userObject: any;
  captcha: any;
  documentMsg: string = '';

  constructor(private loginService: LoginService, private router: Router,private loadservice:LoaderService) { }

  ngOnInit() {
  }

  public register() {
    this.otpValidator = false;
    this.error = '';
    this.loadservice.addloader();
    this.user = new User(this.name, this.surname, this.email.toLowerCase().trim(), this.phone);
    this.loginObj = new Login(this.password, this.email.toLowerCase().trim());
    this.user.login = this.loginObj;
    this.numberOTP = this.phone;
    if (/^[a-zA-Z]+$/.test(this.name) && /^[a-zA-Z]+$/.test(this.surname)) {
      this.loginService.registerRetailUser(this.user)
        .subscribe(
          (response: any) => {
            this.color = "rgb(28, 193, 239)";
            this.loadservice.removeloader();
            localStorage.removeItem('userData');
            localStorage.removeItem('signedUser');
            localStorage.setItem('userData', JSON.stringify(response));
            localStorage.setItem("signedUser", JSON.stringify({ email: this.email.toLowerCase().trim(), password: this.password }));
            $(".otp").removeClass("checkedTab");
            $(".otp").addClass("btn_default");
            $('#myTab button[href="#otp"]').tab('show');
          },
          (err) => {
            // console.log(err);
            this.loadservice.removeloader();
            this.color = "red";
            this.error = "Error while registering, mobile or email already exist.";
            this.documentMsg = "Error while registering, mobile or email already exist.";
            $('#results').modal('show');
          }
        );
    } else {
      this.loadservice.removeloader();
      this.documentMsg = "Name or surname should not contain special characters and numbers.";
      $('#results').modal('show');
    }


  }

  public confirmOTP() {
    var object = JSON.parse(localStorage.getItem('signedUser'))
    if (this.otp != null) {
      this.loadservice.addloader();
      this.loginService.confirmOTP(this.otp, this.numberOTP)
        .subscribe(
          (response) => {
            if (response.status == 200) {
              if (response.json().confirmed) {
                this.loginService.login(object.password, object.email.toLowerCase().trim()).subscribe(
                  (response: any) => {
                    localStorage.setItem('token', response.token);
                    localStorage.removeItem('userData');
                    localStorage.setItem('userData2', response);
                    localStorage.setItem('userData', response);
                    this.userObject = response;
                    this.loadservice.removeloader();
                    this.companyInfoValidator = false;
                    $(".info").removeClass("checkedTab");
                    $(".info").addClass("btn_default");
                    $('#myTab button[href="#companyDetails"]').tab('show');
                    this.loadservice.removeloader();
                  }
                );
              } else {
                this.loadservice.removeloader();
                this.OTPerror = "Error, the OTP number provided is invalid.";
                this.documentMsg = "Error, the OTP number provided is invalid.";
                $('#results').modal('show');
              }
            } else {
              this.loadservice.removeloader();
              this.OTPerror = "Error, please enter valid OTP.";
              this.documentMsg = "Error, please enter valid OTP.";
              $('#results').modal('show');
            }
          },
          (err) => {
            this.loadservice.removeloader();
            this.OTPerror = "Error occurred, please contact Droppa.";
            this.documentMsg = "Error occurred, please contact Droppa.";
            $('#results').modal('show');
          }
        );

    } else {
      this.loadservice.removeloader();
      this.documentMsg = "Error please enter the otp.";
      $('#results').modal('show');
    }

  }

  public companyInfo() {
    var token = localStorage.getItem('token');
    this.loadservice.addloader();
    var comppanyInfo = {
      "companyName": "HMB_" + this.companyName,
      "companyReg": this.companyReg
    };
    // console.log(comppanyInfo);
    this.loginService.company(comppanyInfo, token, this.userObject)
      .subscribe(
        (response: Response) => {
          if (response.status == 200) {
            this.documentValidator = false;
            this.loadservice.removeloader();
           this.documentMsg = "Retail company added successfully.";
           $('#results2').modal('show');
          } else {
            this.loadservice.removeloader();
            this.documentMsg = "Error while adding company please contact Droppa.";
            $('#results').modal('show');
          }
        },
        (err) => {
          this.loadservice.removeloader();
          this.OTPerror = "Error while adding company";
          this.documentMsg = "Error while adding company.";
          $('#results').modal('show');
        }
      );
  }

  public onHome() {
    this.router.navigateByUrl('/');
  }

  public onLogin() {
    this.router.navigateByUrl('/retail-Signin');
  }

  showPassword() {
    this.toggleEye = !this.toggleEye;
    if (this.toggleEye) {
      this.passwordType = 'text';
      this.passwordEye = 'glyphicon glyphicon-eye-close';
    } else {
      this.passwordType = 'password';
      this.passwordEye = 'glyphicon glyphicon-eye-open';
    }
  }

  handleCorrectCaptcha(event) {
  }

  public omit_special_char(e) {
    var k;
    document.all ? k = e.keyCode : k = e.which;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

}
