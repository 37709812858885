import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {

  constructor(private route: Router) {
  }

  ngOnInit() {
  }

  toCustomer() {
    this.route.navigate(['/customer-signin']);
  }

  toPartner() {
    this.route.navigate(['/partner-signin']);
  }

  toRetail() {
    this.route.navigate(['/retail-Signin']);
  }

}
