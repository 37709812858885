import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../services/admin.service';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-uber-bookings',
  templateUrl: './uber-bookings.component.html',
  styleUrls: ['./uber-bookings.component.css', '../admin-portal/admin-portal.component.css']
})
export class UberBookingsComponent implements OnInit {

  loader = false;
  adminUser: any;
  uberBookings: any;
  uberBookingsToFilter: any;
  searchQueryStatus = '';

  constructor(private adminService: AdminService, private router: Router,private loadservice:LoaderService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    this.getBookings();
  }

  public getBookings() {
    this.loadservice.addloader();
    this.adminService.getUberBookings(this.adminUser.token).subscribe(
        (res) => {
          this.loadservice.removeloader();
          this.uberBookings = res.filter(
            (item: any) => item.pickup !== null);
          this.uberBookingsToFilter = this.uberBookings;
          console.log(this.uberBookings);
        },
        (err) => {
          this.loadservice.removeloader();
          if (err.statusText === 'Unknown Error' || err.statusText === 'Internal Server Error') {
            this.router.navigate(['/logon']);
          }
        }
      );
  }

  public deepSearch() {
  //   Use to search Uber bookings from the sever
  }

  searchBookings() {
    this.uberBookingsToFilter = this.uberBookings;

    this.uberBookingsToFilter = this.uberBookingsToFilter.filter(
      (item: any) => item.external_id.toUpperCase().includes(this.searchQueryStatus.toUpperCase()));
  }

  viewDetails(booking) {
    localStorage.setItem('selectedUberBooking', JSON.stringify(booking));
    this.router.navigate(['/uber-booking-details']);
  }

}
