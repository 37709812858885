import { Component, OnInit } from '@angular/core';
import {Advert} from '../../models/advert';

@Component({
  selector: 'app-edit-advert',
  templateUrl: './edit-advert.component.html',
  styleUrls: ['./edit-advert.component.css']
})
export class EditAdvertComponent implements OnInit {

  name: string;
  type: string;
  startDate: Date;
  endDate: Date;
  title: string;
  message: string;
  filePath: string;

  advertNumber: string;

  adverts: Advert[];
  advertToEdit: Advert;

  constructor() { }

  ngOnInit() {
    this.adverts = JSON.parse(localStorage.getItem('adverts'));
    this.advertToEdit = JSON.parse(localStorage.getItem('advertToEdit'));
    this.advertNumber = JSON.parse(localStorage.getItem('advertNumber'));

    this.name = this.advertToEdit.name;
    this.type = this.advertToEdit.type;
    this.startDate = this.advertToEdit.startDate;
    this.endDate = this.advertToEdit.endDate;
    this.title = this.advertToEdit.title;
    this.message = this.advertToEdit.message;
  }

  updateAdvert() {
    this.advertToEdit.name = this.name;
    this.advertToEdit.type = this.type;
    this.advertToEdit.startDate = this.startDate;
    this.advertToEdit.endDate = this.endDate;
    this.advertToEdit.title = this.title;
    this.advertToEdit.message = this.message;
    if (this.filePath) {
      this.advertToEdit.file.filePath = this.filePath;
    }

    this.adverts.splice(Number(this.advertNumber), 1, this.advertToEdit);

    localStorage.setItem('adverts', JSON.stringify(this.adverts));
  }
}
