
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { BookingService } from 'src/app/services/booking.service';
import { SharedService } from 'src/app/services/shared.service';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { LoaderService } from 'src/app/services/loader.service';
declare var google: any;
declare var $: any;

@Component({
  selector: 'app-fleet-serivce',
  templateUrl: './fleet-serivce.component.html',
  styleUrls: ['./fleet-serivce.component.css', '../../css/style.css', '../../css/v-bg-stylish.css', '../../css/custom.css', '../../css/v-shortcodes.css']
})
export class FleetSerivceComponent implements OnInit {

  @ViewChild('contactFormss', { static: true }) contactFormss: NgForm;
  @ViewChild('focus', { static: false }) input: ElementRef;
  @ViewChild('activeTab', { static: false }) inputActive: ElementRef;

  public userSettings2: any = {
    showRecentSearch: true,
    geoCountryRestriction: ['ZA'],
    inputPlaceholderText: 'Enter pick-up street address.',
    geoTypes: ['address', 'establishment']
  };

  public userSettings: any = {
    showRecentSearch: true,
    geoCountryRestriction: ['ZA'],
    inputPlaceholderText: 'Enter drop-off street address.',
    geoTypes: ['address', 'establishment']
  };

  email: string;
  msgBody: string;
  fullName: string;
  msgStatus = '';

  pickUpNotMsg: string;
  pickUpAddress: string;
  dropOffAddress: string;
  dropOffNotMsg = '';
  dropOffNotFound: boolean;
  pickUpNotFound: boolean;
  pickUpLat: any;
  pickUpLong: any;

  pickUpPlaceHolder: string;
  dropOffPlaceHolder: string;

  pickUpCoordinate = [];
  dropOffCoordinate = [];

  vehicleTypeRate: any = [];

  pickUpCoordinateLocation: any = {};
  dropOffCoordinateLocation: any = {};

  dropOffLat: number;
  dropOffLong: number;

  lat: number = parseFloat('-25.98953');
  lng: number = parseFloat('28.12843');

  vehicleType: any;

  userLat: number;
  userLong: number;

  location: any = {};
  dir = undefined;
  priceBox: boolean = false;
  dedicated: boolean = true;

  distance: number;
  distanceTest: number;
  loader = false;

  totalExpressPrice: number;
  distanceOfTrip: any;
  tempAmount: number;

  vehicleTypeData: any;
  vehicleOid: string;
  errorMessage: string;
  description = '';
  province = 'UNKNOWN';
  validateProvince: any;
  validatePickuProvince: string;
  context: any = this;

  address: string;
  city: any;
  Surbub: any;
  postalCode: any;
  streetName: any;
  address11: any;
  destination: any;
  matrix: any;
  vehicle: string;
  addressComponent: any = [];

  departureCoordinate: any = {};
  destinationCoordinate: any = {};

  mass: any;
  isExpress: boolean;

  miniVan: string = 'assets\\img\\home\\Group_1014@2x.png';
  oneHalfTon: string = 'assets\\img\\home\\Group_1016@2x.png';
  oneTon: string = 'assets\\img\\home\\Group_1015@2x.png';
  fourTon: string = 'assets\\img\\home\\Group_1017@2x.png';
  eightTon: string = 'assets\\img\\home\\Group_1018@2x.png';

  yes_value: any;
  yes_value1: any;
  yes_value2: any;
  yes_value3: any;
  yes_value4: any;
  yes_value5: any;

  no_value: any;
  no_value1: any;
  no_value2: any;
  no_value3: any;
  no_value4: any;
  no_value5: any;

  transportMode: any;
  isItemPicked: boolean = false;
  mainCityOid: any;
  isPayed: boolean = false;
  fromSuburb: any;
  toSuburb: any;
  pickUpPCode: any;
  dropOffPCode: any;
  pickUpProvince: any;
  dropOffProvince: string;
  type: any;
  destinationProvince: any;
  errorMsg: string = '';
  errorVehicle: string = '';
  mainCenter: any;
  userObject: any;
  outSideRange: boolean = false;
  distanceRadius: number = 0;
  usedDistance: number = 0;
  homeImg;
  vat:number=1.15;
  surCharge:number=0.15;

  includedType = ['MINI_VAN', 'ONE_TON_TRUCK', 'ONE_HALF_TRUCK', 'FOUR_TON_TRUCK', 'EIGHT_TON_TRUCK'];

  constructor(private router: Router, private bookService: BookingService, private http: HttpClient, private _sanitizer: DomSanitizer,private loadservice:LoaderService) { }

  ngOnInit() {
    // 'paymentFromTable' variable below is used to mark that this is a new booking
    localStorage.setItem('paymentFromTable', 'false');

    if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition((pos) => this.setPosition(pos));
    }
    // this.loadHomePageImage();
    this.getVehicleTypeRates();
    this.matrix = new google.maps.DistanceMatrixService();
    //this.getPointOfInterest();
    window.scrollTo(0, 0);
  }

  async loadHomePageImage(){
    await this.http.get('/assets/Homepage_banner.png', { responseType: 'blob' })
     .subscribe(res => {
       const reader = new FileReader();
       reader.onloadend = () => {
         var base64data = reader.result;
         this.homeImg = this._sanitizer.bypassSecurityTrustResourceUrl(''+base64data);
         // this.isLand = false;
       }
       reader.readAsDataURL(res);
     });
   }

  autoCompleteCallback1(selectedData: any) {
    debugger;
    if (!selectedData) {
      this.pickUpNotMsg = 'Location not found, Please enter nearest location.';
      this.pickUpNotFound = false;
    } else {
      for (let x = 0; x < selectedData.data.address_components.length; x++) {
        for (let y = 0; y < selectedData.data.address_components[x].types.length; y++) {
          for (let a = 0; a < selectedData.data.address_components[x].types.length; a++) {
            if (selectedData.data.address_components[x].types[a] == 'postal_code') {
              this.pickUpPCode = selectedData.data.address_components[x].long_name;
            }
            else if (selectedData.data.address_components[x].types[a] == 'administrative_area_level_1') {
              this.pickUpProvince = selectedData.data.address_components[x].long_name;
            }
          }
        }
      }

      if (this.pickUpProvince == 'Eastern Cape') {
        this.getPointOfInterest();
      }

      this.pickUpAddress = selectedData.data.description;
      this.pickUpLat = selectedData.data.geometry.location.lat;
      this.pickUpLong = selectedData.data.geometry.location.lng;
      this.pickUpCoordinate = [selectedData.data.geometry.location.lat, selectedData.data.geometry.location.lng];
      this.pickUpCoordinateLocation = { coordinates: this.pickUpCoordinate, address: this.pickUpAddress };
      this.departureCoordinate = { "lat": selectedData.data.geometry.location.lat, "lng": selectedData.data.geometry.location.lng };
      this.getAdminArea(selectedData.data.address_components);
    }
  }

  autoCompleteCallback2(selectedData: any) {
    if (!selectedData) {
      this.dropOffNotMsg = 'Location not found, Please enter nearest location.';
      this.dropOffNotFound = false;
    } else {
      for (let x = 0; x < selectedData.data.address_components.length; x++) {
        for (let y = 0; y < selectedData.data.address_components[x].types.length; y++) {
          for (let a = 0; a < selectedData.data.address_components[x].types.length; a++) {
            if (selectedData.data.address_components[x].types[a] == 'postal_code') {
              this.dropOffPCode = selectedData.data.address_components[x].long_name;
            }
            else if (selectedData.data.address_components[x].types[a] == 'administrative_area_level_1') {
              this.dropOffProvince = selectedData.data.address_components[x].long_name;
            }
          }
        }
      }
      this.dropOffAddress = selectedData.data.description;
      this.dropOffLat = selectedData.data.geometry.location.lat;
      this.dropOffLong = selectedData.data.geometry.location.lng;
      this.dropOffCoordinate = [selectedData.data.geometry.location.lat, selectedData.data.geometry.location.lng];
      this.dropOffCoordinateLocation = { coordinates: this.dropOffCoordinate, address: this.dropOffAddress };
      this.destinationCoordinate = { "lat": selectedData.data.geometry.location.lat, "lng": selectedData.data.geometry.location.lng };
    }
  }

  private getAdminArea(address_component: []) {

    this.addressComponent = address_component;
    let len = address_component.length;

    for (let i = 0; i < len; i++) {
      switch (this.addressComponent[i].long_name) {
        case 'Gauteng': {
          this.province = 'GAUTENG';
          this.validateProvince = 'Gauteng';
          break;
        }
        case 'KwaZulu-Natal': {
          this.province = 'KWAZULU-NATAL';
          this.validateProvince = 'KwaZulu-Natal';
          break;
        }
        case 'kzn': {
          this.province = 'kzn';
          this.validateProvince = 'KWA_ZULU_NATAL';
          break;
        }
        case 'Western Cape': {
          this.province = 'WESTERN CAPE';
          this.validateProvince = 'Western Cape';
          break;
        }
        case 'Eastern Cape': {
          this.province = 'EASTERN CAPE';
          this.validateProvince = 'Eastern Cape';
          break;
        }
        default: {

          break;
        }
      }
    }
  }


  public getVehicleTypeRates() {
    this.bookService.vehicleTypeRates().subscribe(
      (res) => {
        debugger;
        // console.log("vehicle type rate: ", res);
        // this.vehicleTypeRate = res;
        res.map( (type) => {
          if (this.includedType.includes(type.vehicleType)){
            this.vehicleTypeRate.push(type);
          }
        });
        this.onSelectChange(this.vehicleTypeRate);
      }
    );
  }

  onSelectChange(vehicleType) {
    this.vehicleType = vehicleType.vehicleType;
    this.description = vehicleType.description;
    for (let myChild of this.vehicleTypeRate) {
      myChild.BackgroundColour = "white";
    }
    if (vehicleType.vehicleType == 'MINI_VAN') {
      this.vehicle = this.miniVan;
      localStorage.setItem('vehicleType', this.vehicle);
      vehicleType.BackgroundColour = "#28a3c7";
    }
    else if (vehicleType.vehicleType == 'ONE_TON_TRUCK') {
      this.vehicle = this.oneTon
      localStorage.setItem('vehicleType', this.vehicle);
      vehicleType.BackgroundColour = "#28a3c7";
    }
    else if (vehicleType.vehicleType == 'ONE_HALF_TRUCK') {
      this.vehicle = this.oneHalfTon
      localStorage.setItem('vehicleType', this.vehicle);
      vehicleType.BackgroundColour = "#28a3c7";
    }
    else if (vehicleType.vehicleType == 'FOUR_TON_TRUCK') {
      this.vehicle = this.fourTon
      localStorage.setItem('vehicleType', this.vehicle);
      vehicleType.BackgroundColour = "#28a3c7";
    }
    else if (vehicleType.vehicleType == 'EIGHT_TON_TRUCK') {
      this.vehicle = this.eightTon
      localStorage.setItem('vehicleType', this.vehicle);
      vehicleType.BackgroundColour = "#28a3c7";
    }
  }

  public setPosition(position) {
    this.location = position.coords;
    this.userLat = parseFloat(this.location.latitude);
    this.userLong = parseFloat(this.location.longitude);
  }

  goToEntry() {
    this.priceBox = false;
    this.outSideRange = false;
  }

  public getPointOfInterest() {
    this.bookService.getPOI().subscribe(response => {
      for (let i = 0; i < response.length; i++) {
        this.distanceRadius = this.coordinateDistance(this.pickUpLat, this.pickUpLong, Number.parseFloat(response[i].location.coordinates[0]), Number.parseFloat(response[i].location.coordinates[1]), 'K');
        //console.log(this.distanceRadius);
        if (this.distanceRadius < 50) {
          this.outSideRange = true;
          //console.log('within range');
          break;
        }
      }
    }, error => {console.log(error)});
  }

  private coordinateDistance(lat1, lon1, lat2, lon2, unit): number {
    var radlat1 = Math.PI * lat1 / 180
    var radlat2 = Math.PI * lat2 / 180
    var theta = lon1 - lon2
    var radtheta = Math.PI * theta / 180
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist)
    dist = dist * 180 / Math.PI
    dist = dist * 60 * 1.1515
    if (unit == "K") { dist = dist * 1.609344 }
    if (unit == "N") { dist = dist * 0.8684 }
    return dist;
  }

  public getFleetQuote() {
    this.loadservice.addloader();
    this.priceBox = false;
    if ((this.pickUpAddress != null || this.pickUpAddress != undefined) && (this.dropOffAddress != null || this.dropOffAddress != undefined)) {
      if (this.vehicleType != null) {
        if ((this.validateProvince === 'KwaZulu-Natal' || this.validateProvince === 'kzn') ||
        this.validateProvince === 'Western Cape' ||
        this.validateProvince === 'Gauteng' ||
        (this.validateProvince === 'Eastern Cape' && this.outSideRange)) {
          var data = {
            "destination": this.destinationCoordinate,
            "departure": this.departureCoordinate
          };
          if (((data.departure.lat == null || data.departure.lat == '') || (data.departure.lng == null || data.departure.lng == '')) || ((data.destination.lat == null || data.destination.lat == '') || (data.destination.lng == null || data.destination.lng == ''))) {
            this.errorMessage = "Couldn't find the distance between the pick up and drop off address.";
            $('#errorDialog').modal('show');
          }
          else {
            this.bookService.getDistance(data).subscribe(
            (response) => {
              const distanceResponse = response.json();
              this.distance = distanceResponse.rows[0].elements[0].distance.inMeters;
              this.distance = Math.round(this.distance / 1000);
              localStorage.setItem('distance', this.distance + '');
              this.bookService.getVehicleRate(this.vehicleType)
                .subscribe(
                  (res: Response) => {
                    this.dir = {
                      origin: { lat: this.pickUpCoordinate[0], lng: this.pickUpCoordinate[1] },
                      destination: { lat: this.dropOffCoordinate[0], lng: this.dropOffCoordinate[1] }
                    };
                    this.vehicleTypeData = res.json();

                    this.totalExpressPrice = this.vehicleTypeData.basePrice + (this.vehicleTypeData.kilometer * this.distance);
                    this.tempAmount = this.totalExpressPrice*this.vat;
                    if (this.distance > 200) {
                      this.tempAmount = this.tempAmount + (this.tempAmount * this.surCharge);
                    }
                    this.tempAmount = Math.round(this.tempAmount);
                    this.priceBox = true;
                    this.loadservice.removeloader();
                    window.scrollTo(0, 0);
                  },
                  (err) => {
                    this.errorMessage = 'Error occurred, please contact Droppa.';
                    this.loadservice.removeloader();
                    $('#errorDialog').modal('show');
                  }
                );
            }, error => {
                console.log(error);
                this.loadservice.removeloader();
              });
          }
        }
        else {
          if (this.outSideRange) {
            this.errorMessage = 'Your address is outside the required range. Please enter an address within a 50KM radius of Queenstown or Port Elizabeth.';
            this.loadservice.removeloader();
            $('#errorDialog').modal('show');
          }
          else {
            this.errorMessage = 'Droppa Fleet is currently unavailable in ' + this.pickUpProvince + '. Please use COURIER for your deliveries.';
            this.loadservice.removeloader();
            $('#errorDialog').modal('show');
          }
        }
      }
      else {
        this.errorVehicle = 'Please select the vehicle type.';
        this.loadservice.removeloader();
        $('#vehicleValidation').modal('show');
      }
    }
    else {
      this.errorMsg = 'Please enter the addresses before proceeding with the booking.';
      this.loadservice.removeloader();
      $('#provinceValidation').modal('show');
    }

  }

  public confirmBookingDedicated() {
    let obect = {
      pickUpAddress: this.pickUpCoordinateLocation,
      dropOffAddress: this.dropOffCoordinateLocation,
      price: this.tempAmount,
      vehicleType: this.vehicleType,
      province: this.province.toUpperCase(),
      "mass": 0,
      "dropOffProvince": this.dropOffProvince.toUpperCase(),
      "transportMode": "ROAD",
      "type": "DEDICATED"
    };

    if ((localStorage.getItem('userInfoBookingLogin') != null && localStorage.getItem('userInfoBooking') != null)) {
      localStorage.setItem('addressData', JSON.stringify(obect));
      this.login(JSON.parse(localStorage.getItem('userInfoBookingLogin')));
    }
    else {
      localStorage.setItem('addressData', JSON.stringify(obect));
      this.router.navigate(['/Booking-SignUp']);
      window.scrollTo(0, 0);
    }
  }

  private login(object: any) {
    this.bookService.login(object.pwd, object.username).subscribe(
      (response: any) => {
        localStorage.setItem('userData', JSON.stringify(response));
        localStorage.setItem('token', response.token);
        localStorage.setItem('userInfo', JSON.stringify(response));
        localStorage.removeItem('userInfoBookingLogin');
        localStorage.removeItem('userInfoBooking');
        this.router.navigate(['/Select-Date']);
        window.scrollTo(0, 0);
      }
    );
  }

  toPartner() {
    $('#partnerRegistration').modal('show');
  }

  goToPartnerRegistration() {
    var ele = document.getElementsByTagName('input');
    var result = "success"
    var count = 0
    for (var i = 0; i < ele.length; i++) {
      if (ele[i].type = "radio") {
        if (ele[i].checked) {
          count = count + 1;
          if (ele[i].value == 'yes2') {
            result = "fail"
          }
        }
      }
    }
    if (count >= 6) {
      if (result == 'success') {
        this.router.navigate(['/Become-Droppa']);
      }
      else if (result == 'fail') {
        $('#criminalResModal').modal('show');
        this.msgStatus = "You cannot proceed with registration due to criminal record.";
      }
    }
    else {
      $('#partnerResModal2').modal('show');
      this.msgStatus = "Please answer all questions to proceed with the registration";
    }
  }

  toRetail() {
    $('#retailRegistration').modal('show');
  }

  goToRetailRagistration() {
    var ele = document.getElementsByTagName('input');
    var result = "success"
    var count = 0
    for (var i = 0; i < ele.length; i++) {
      if (ele[i].type = "radio") {
        if (ele[i].checked) {
          count = count + 1;
          if (ele[i].value == 'no') {
            result = "fail"
          }
        }
      }
    }
    if (count == 3) {
      // if (result == 'success') {
      //   this.router.navigate(['/Become-Retail']);
      // }
      // else if (result == 'fail') {
      //   $('#retailResModal').modal('show');
      //   this.msgStatus = "All answers must be YES to proceed with the registration";
      // }
      // this.router.navigate(['/Become-Retail']);
    }
    else {
      $('#retailResModal2').modal('show');
      this.msgStatus = "Please answer all the questions to proceed with the registration.";
    }
  }


}
