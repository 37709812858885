export class Advert {

  name: string;
  type: string;
  title: string;
  message: string;
  creationDate: Date;
  startDate: Date;
  endDate: Date;
  file: {
    fileType: string,
    filePath: string
  };
  lastEditDate: Date;
  dateDeleted: Date;


  constructor(
    name: string, type: string, title: string, message: string, creationDate: Date, startDate: Date, endDate: Date,
    file: { fileType: string; filePath: string }, lastEditDate: Date, dateDeleted: Date) {
    this.name = name;
    this.type = type;
    this.title = title;
    this.message = message;
    this.creationDate = creationDate;
    this.startDate = startDate;
    this.endDate = endDate;
    this.file = file;
    this.lastEditDate = lastEditDate;
    this.dateDeleted = dateDeleted;
  }
}
