import { Component, OnInit,isDevMode } from '@angular/core';
import { Response } from '@angular/http';
import { Subscription } from 'rxjs/Subscription';
import { Router } from '@angular/router';
import 'rxjs/Rx';
import { UserLoggInService } from '../../services/loggedInUser.service';
import { LoginService } from '../../services/login.service';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-log-in',
  templateUrl: './log-in.component.html',
  styleUrls: ['./log-in.component.css']
})


export class LogInComponent implements OnInit {

  isDevMode=isDevMode()

  password: string;
  username: string;
  errorMsg: string = '';
  valid: boolean = false;
  validAdmin: boolean = false;
  isRobot=true
  capture: any;
  loader: boolean;
  UAT_SITE_KEY:String


  constructor(private loginService: LoginService, private route: Router, private userLogged: UserLoggInService,
    private userService: UserLoggInService, private service: SharedService) { }

  ngOnInit() {
    this.UAT_SITE_KEY=this.loginService.SITE_KEY
  }

  public logins() {

    this.errorMsg = "";
    this.loginService.login(this.password, this.username.toLowerCase().trim()).subscribe(
      (response: any) => {
        var loginsData = { "username": this.username.toLowerCase().trim(), "pwd": this.password };
        localStorage.setItem('userInfo', JSON.stringify(response));
        localStorage.setItem('userInfoBooking', JSON.stringify(response));
        localStorage.setItem('userInfoBookingLogin', JSON.stringify(loginsData));

        this.userLogged.setUser(response);
        this.route.navigateByUrl('/developers-portal');
        this.service.setMessage("Sign In");

      },
      (err) => {
        this.errorMsg = "Invalid username or password.";
      }
    );

  }


  public login() {

    this.errorMsg = "";

    if(this.isRobot&&!this.isDevMode){
      this.errorMsg = 'Please click the checkbox to varify you are a human.';
    }else{
      //var pass = this.password.split('');
      this.loginService.login(this.password, this.username.toLowerCase().trim()).subscribe(
        (response: any) => {

          response.roles.forEach(role => {
            if (role.code == 'api') {
              localStorage.setItem('userInfo', JSON.stringify(response));
              this.valid = true;
            }
          });

          if (this.valid) {

            var loginsData = { "username": this.username.toLowerCase().trim(), "pwd": this.password };
            localStorage.setItem('userInfo', JSON.stringify(response));
            localStorage.setItem('userInfoBooking', JSON.stringify(response));
            localStorage.setItem('userInfoBookingLogin', JSON.stringify(loginsData));


            this.userLogged.setUser(response);
            this.route.navigateByUrl('/developers-portal');
            this.service.setMessage("Sign In");


          } else {
            window.alert("You do not have access to this site.");
          }

        },
        (err) => {
          this.errorMsg = "Invalid username or password.";
          // console.log(this.errorMsg);
        }
      );
    }



  }

  handleCorrectCaptcha(event) {

    this.errorMsg = "";
    if(event!=null){
      this.isRobot=false
    }else{
      this.isRobot=true

    }

  }

}
