import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';
import { NgForm } from '@angular/forms';
import { DataSnapshot } from '@firebase/database';

declare var $: any;

@Component({
  selector: 'app-updatedriverapp',
  templateUrl: './updatedriverapp.component.html',
  styleUrls: ['./updatedriverapp.component.css', '../admin-portal/admin-portal.component.css']
})
export class UpdatedriverappComponent implements OnInit, AfterViewInit {

  @ViewChild('mainForm', { static: false }) mainForm: NgForm;
  status: any;
  versionCode: any;
  VersionName: any;
  update = '';
  updateApp: any[];
  values: any;

  userstatus: any;
  userversionCode: any;
  userVersionName: any;

  // Object used to populate the values in the HTML
  appInfo = {
    status: "",
    versionCode: "",
    versionName: ""
  };

  userAppInfo = {
    status: "",
    versionCode: "",
    versionName: ""
  };

  constructor(public db: AngularFireDatabase) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    // Retrieving data from firebase
    this.db.database.ref('droppa/driverApp').once('value', (dataSnapshot) => {
      this.values = dataSnapshot.val();
      this.appInfo = this.values;
    });

    this.db.database.ref('droppa/userApp').once('value', (dataSnapshot) => {
      this.values = dataSnapshot.val();
      //console.log(this.values);
      this.userAppInfo = this.values;
    });
  }

  ngAfterViewInit() {
  }

  public updateAppInfo() {
    var valAppInfo = {
      status: this.status,
      versionCode: this.versionCode,
      versionName: this.VersionName
    };
    this.db.database.ref('droppa').child('driverApp').update(valAppInfo).then(
      () => {
        this.appInfo = valAppInfo;
        this.update = 'App Message Updated';
        $('#myModal').modal('show');
        this.status = "";
        this.versionCode = "";
        this.VersionName = "";
      }
    ).catch(
      (error) => {
        // console.log('Error');
        this.update = 'An error occured while trying to update the app';
        $('#myModal').modal('show');
      }
    );
  }

  public updateUserInfo() {
    var valAppInfo = {
      status: this.userstatus,
      versionCode: this.userversionCode,
      versionName: this.userVersionName
    };
    this.db.database.ref('droppa').child('userApp').update(valAppInfo).then(
      () => {
        this.userAppInfo = valAppInfo;
        this.update = 'App Message Updated';
        $('#myModal').modal('show');
        this.userstatus = "";
        this.userversionCode = "";
        this.userVersionName = "";
      }
    ).catch(
      (error) => {
        // console.log('Error');
        this.update = 'An error occured while trying to update the app';
        $('#myModal').modal('show');
      }
    );
  }
  public showTabByRole(role):boolean{
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let result=false;
    userInfo.roles.map(r=>{
      if(role===r.code){
        result=true;
      }
    })
    return result;
  }

}
