import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Response } from '@angular/http';
import { Router } from '@angular/router';
import 'rxjs/Rx';
import { DatePipe } from '@angular/common';
declare var $: any;

import { AdminService } from '../../services/admin.service';
import { UserService } from '../../services/user.service';
import { LoginService } from '../../services/login.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-edit-partner',
  templateUrl: './edit-partner.component.html',
  styleUrls: ['./edit-partner.component.css']
})
export class EditPartnerComponent implements OnInit {

  @ViewChild('companyForm', { static: true }) compaanyForm: NgForm;
  @ViewChild('documentForm', { static: false }) documentForm: NgForm;

  @ViewChild('IDdwnldLnk', { static: false }) itemId;

  user: any = {};
  userCompany: any = {};
  companyVehicles: any = [];
  vehiclesTypeRate: any = [];
  hasCompany: boolean = false;

  province: string;
  userDocuments: any;

  companyName: string = '';
  companyReg: string = '';
  make: string = '';
  model: string = '';
  registration: string = '';
  vinNo: string = '';
  Vcolor: string = '';
  vehicleType: string = '';
  loader: boolean = false;
  error: string = '';

  Id_available: boolean;
  cipc_available: boolean = true;
  disc_available: boolean;

  isDocument: boolean = true;
  insertDocuments: boolean = false;

  base64ID: any;
  base64CIPC: any;
  base64DISC: any;
  documentMsg: string;

  idName: string;
  diskName: string;
  cpicName: string;

  fullName: string = '';
  IDpdf: string;
  CIPCpdf: string;
  DISCpdf: string;
  responseTxt: string = "";
  canopy: boolean = false;
  ID: string;
  diskExpiryDate: any;
  vehicleFeedback: any;
  msg: string;
  selectedTabID: any;

  constructor(private adminService: AdminService, private partnerService: UserService,
    private loginService: LoginService, private router: Router,private loadservice:LoaderService) {
    this.user = JSON.parse(localStorage.getItem('selectedPartner'));
    this.fullName = this.user.owner.surname + "_" + this.user.owner.firstName;
  }

  ngOnInit() {
    this.loadservice.addloader();
    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.getVehicleTypeRates();
    this.partnerService.retrieveCompany(object.token, this.user.owner.oid)
      .subscribe(
        (response: Response) => {
          this.loadservice.removeloader();
          if (response != null) {
            if (response.json()[0] != null) {
              this.userCompany = response.json()[0];
              this.hasCompany = false;
              this.getVehicles(object.token, this.userCompany.oid);
            } else {
              this.hasCompany = true;
              this.userCompany = null;
            }
          }
        }
      );
    this.getAllDocuments(object.token, this.user.owner.oid);

    this.selectedTabID = localStorage.getItem('activeTab');
    localStorage.setItem('activeID', this.selectedTabID);
  }

  public onVehicle(vehicle) {
    localStorage.setItem('selectedVehicle', JSON.stringify(vehicle));
    this.router.navigate(['/update-vehicle']);
  }

  public onRegisterCompany() {

    var token = localStorage.getItem('token');
    var object = JSON.parse(localStorage.getItem('userInfo'));
    var currentDate = new Date();
    let date = currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate();
    let monthNo = (currentDate.getMonth() + 1) <= 9 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
    let hour = currentDate.getHours() <= 9 ? '0' + currentDate.getHours() : currentDate.getHours();
    let minutes = currentDate.getMinutes() <= 9 ? '0' + currentDate.getMinutes() : currentDate.getMinutes();
    var second = currentDate.getSeconds() <= 9 ? '0' + currentDate.getSeconds() : currentDate.getSeconds();


    var dateValue = this.diskExpiryDate;
    var datePipe = new DatePipe('en-US');
    let value = datePipe.transform(dateValue, 'yyyy-MM-dd');
    var timestamp = new Date().valueOf();
    var datePipe = new DatePipe('en-US');




    var realTime = new Date(dateValue);
    var currentDate = new Date();
    var dateFormat = currentDate.toLocaleString('en-us', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');
    var diskDate = realTime.toLocaleString('en-us', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');
    var dateSame = diskDate === dateFormat;
    var dateDiffer = diskDate > dateFormat;



    // JavaScript program to illustrate
    // calculation of no. of days between two date

    // To set two dates to two variables
    var dateStart = new Date(currentDate);
    var dateEnd = new Date(diskDate);


    // To calculate the time difference of two dates
    var Difference_In_Time = dateEnd.getTime() - dateStart.getTime();

    // To calculate the no. of days between two dates
    var Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));




    this.loadservice.addloader();
    var comppanyInfo = {
      "companyName": this.companyName,
      "companyReg": this.companyReg,
      "province": this.province,
      "make": this.make,
      "model": this.model,
      "registration": this.registration,
      "vinNo": this.vinNo,
      "Vcolor": this.Vcolor,
      "type": this.vehicleType,
      "hasCanopy": this.canopy,
      "diskDate": diskDate
    };



        this.adminService.companyInfo(comppanyInfo, this.user, object.token)
          .subscribe(
            (response: Response) => {

              this.compaanyForm.reset();

              if (response != null) {
                this.loadservice.removeloader();
                this.ngOnInit();
                this.msg = "Company and vehicle successfully added to the user.";
                $('#results').modal('show');

              } else {
                this.loadservice.removeloader();
                this.msg = "Error while adding company please contact Droppa.";
                $('#results').modal('show');
              }
            },
            (err) => {
              this.loadservice.removeloader();
              this.msg = "Error while adding company";
              $('#results').modal('show');
            }
          );

  }


  public checkedCanopy(canopy: any) {
    if (canopy) {
      this.canopy = true;
    } else {
      this.canopy = false;
    }
  }


  private getAllDocuments(token, personOid) {
    this.adminService.getAllDocument(token, personOid).subscribe(
      (res) => {

        this.userDocuments = res;
        if (res.length != 0) {
          for (var i = 0; i < res.length; i++) {
            if (res[i].type == "ID_COPY") {
              this.IDpdf = 'data:application/octet-stream;base64,' + res[i].document;
              this.Id_available = true;

            } else if (res[i].type == "CIPC_COPY") {
              this.CIPCpdf = 'data:application/octet-stream;base64,' + res[i].document;
              this.cipc_available = true;

            } else if (res[i].type == "DISC_COPY") {
              this.DISCpdf = 'data:application/octet-stream;base64,' + res[i].document;
              this.disc_available = true;
            }
          }

          if (res.length == 2) {
            this.cipc_available = false;
            this.isDocument = false;

          }
        } else {
          this.insertDocuments = true;
          this.cipc_available = false;
          this.isDocument = false;
        }

      }
    );
  }

  private getVehicles(token, companyOid) {
    this.loadservice.addloader();
    this.partnerService.retrieveVehicles(token, companyOid)
      .subscribe(
        (res: Response) => {
          this.loadservice.removeloader();
          if (res.status == 200) {
            this.companyVehicles = res.json();
          }
        }, error1 => {
          console.log(error1);
          this.loadservice.removeloader();
        }
      );
  }

  public downloadIDPDF() {
    this.downloadFile(this.IDpdf, this.fullName + "__ID.pdf");
  }

  downloadFile(data, fileName) {
    var a = document.createElement("a");
    document.body.appendChild(a);

    a.href = data;
    a.download = fileName;
    a.click();
  }

  public downloadCIPCPDF() {
    this.downloadFile(this.CIPCpdf, this.fullName + "__CIPC.pdf");
  }

  public downloadDISCPDF() {
    this.downloadFile(this.DISCpdf, this.fullName + "__DISC.pdf");
  }

  public uploadCIPC() {
    this.responseTxt = "";
    var CIPCData = {
      "personOId": this.user.owner.oid,
      "type": "CIPC_COPY",
      "document": this.base64CIPC
    };

    if (this.base64CIPC != null) {
      this.loadservice.addloader();
      var object = JSON.parse(localStorage.getItem('userInfo'));
      this.loginService.uploadCPIC(CIPCData, object.token).subscribe(
        (res) => {
          this.responseTxt = "CIPC document submitted successfully";
          this.loadservice.removeloader();
        },
        (error) => {
          this.responseTxt = "Error while submitting the document";
          this.loadservice.removeloader();
        }
      )
    } else {
      alert("Please attach CIPC document");
    }

  }

  public onDocument() {
    this.responseTxt = "";
    var IDData = {
      "personOId": this.user.owner.oid,
      "type": "ID_COPY",
      "document": this.base64ID
    };

    var CIPCData = {
      "personOId": this.user.owner.oid,
      "type": "CIPC_COPY",
      "document": this.base64CIPC
    };

    var DISCData = {
      "personOId": this.user.owner.oid,
      "type": "DISC_COPY",
      "document": this.base64DISC
    };

    if (this.base64ID != null && this.base64DISC != null) {
      this.loadservice.addloader();

      var object = JSON.parse(localStorage.getItem('userInfo'));
      this.loginService.uploadDocs(IDData, CIPCData, DISCData, object.token)
        .subscribe(
          (response: Response) => {


            if (response.status == 200) {
              this.loadservice.removeloader();
              this.responseTxt = 'You have successfully uploaded user documents.';

            } else {
              alert("Error while insertting company documents please contact Droppa.");
            }
          },
          (err) => {
            this.loadservice.removeloader();
            alert("Error while adding company documents");
          }
        );
    } else {
      alert("Please attach all document");
    }
  }

  handleFileSelect(evt, fileType: string) {
    var files = evt.target.files;
    var file = files[0];
    if (fileType == 'ID') {
      if (files && file) {
        this.idName = file.name;
        var reader = new FileReader();
        reader.onload = this._handleReaderLoaded.bind(this);
        reader.readAsBinaryString(file);
      }
    } else if (fileType == 'DISC') {

      if (files && file) {
        this.diskName = file.name;
        var reader = new FileReader();
        reader.onload = this._discFileLoaded.bind(this);
        reader.readAsBinaryString(file);
      }
    } else if (fileType == 'CPIC') {
      if (files && file) {
        this.cpicName = file.name;
        var reader = new FileReader();
        reader.onload = this._cpicFileLoaded.bind(this);
        reader.readAsBinaryString(file);
      }
    }

  }


  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64ID = btoa(binaryString);
  }

  _discFileLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64DISC = btoa(binaryString);
  }

  _cpicFileLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64CIPC = btoa(binaryString);
  }

  public getVehicleTypeRates() {
    this.adminService.vehicleTypeRates().subscribe(
      (res) => {
        this.vehiclesTypeRate = res;
      }
    )
  }

}
