import { Component, OnInit, HostListener } from '@angular/core';
import { Response } from '@angular/http';
import { PlatformLocation } from '@angular/common'
import { Router } from '@angular/router';
declare var $: any;

import { BookingService } from '../../services/booking.service';
import { SharedService } from '../../services/shared.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-confirm-retail-booking',
  templateUrl: './confirm-retail-booking.component.html',
  styleUrls: ['./confirm-retail-booking.component.css']
})
export class ConfirmRetailBookingComponent implements OnInit {

  addrressData: any;
  bookingData: any;
  dropData: any;
  retail : any = {};
  userLat: number;
  userLong: number;
  dir: any;
  location: any;

  termsControl: boolean = false;
  loader: boolean = false;
  bookingText: string;
  dropOid: string;
  lng: number;
  lat: number;

  @HostListener('window:unload', ['$event'])
  unloadHandler(event) {
    var inFormOrLink = true;
    var returnValue = undefined;
    alert('Not yet done with the booking.');
    if (!inFormOrLink) {
      returnValue = 'Do you really want to close?';
    }
    event.returnValue = returnValue;
    return returnValue;
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHander(event) {
    var inFormOrLink = true;
    var returnValue = undefined;
    if (!inFormOrLink) {
      returnValue = 'Do you really want to close?';
    }
    event.returnValue = returnValue;
    return returnValue;
  }

  constructor(private bookService: BookingService, private router: Router, location: PlatformLocation,private loadservice:LoaderService) {
    location.onPopState(() => {
      // console.log("The closed button");
      var r = confirm('You pressed a Back button! Are you sure?!');

      if (r === true) {
        // Call Back button programmatically as per user confirmation.
        history.back();
        // Uncomment below line to redirect to the previous page instead.
        // window.location = document.referrer // Note: IE11 is not supporting this.
      } else {
        // Stay on the current page.
        history.pushState(null, null, window.location.pathname);
      }
      history.pushState(null, null, window.location.pathname);
    });
  }

  ngOnInit() {
    this.addrressData = JSON.parse(localStorage.getItem('addressData'));
    // console.log(this.addrressData);
    this.bookingData = JSON.parse(localStorage.getItem('bookingDataObject'));
    this.dropData = JSON.parse(localStorage.getItem('dropObject'));
    this.retail = JSON.parse(localStorage.getItem('retail'));
    // console.log(this.bookingData);
    // console.log(this.dropData);
    this.dropOid = localStorage.getItem('dropOid');
    // this.syncPayment(localStorage.getItem('token'), localStorage.getItem('paymentReference'));
    if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition((pos) => this.setPosition(pos));
    }

    this.dir = {
      origin: { lat: this.addrressData.pickUpAddress.coordinates[0], lng: this.addrressData.pickUpAddress.coordinates[1] },
      destination: { lat: this.addrressData.dropOffAddress.coordinates[0], lng: this.addrressData.dropOffAddress.coordinates[1] }
    };
  }

  setPosition(position) {
    this.location = position.coords;
    this.userLat = parseFloat(this.location.latitude);
    this.userLong = parseFloat(this.location.longitude);
  }

  public confirmBooking() {
    this.loadservice.addloader();
    // this.bookingText = "Booking successful.";
    // $('#results').modal('show');
    var currentDate = new Date(this.bookingData.pickUpDate);
    let date = currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate();
    let monthNo = (currentDate.getMonth() + 1) <= 9 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
    let hour = currentDate.getHours() <= 9 ? '0' + currentDate.getHours() : currentDate.getHours();
    let minutes = currentDate.getMinutes() <= 9 ? '0' + currentDate.getMinutes() : currentDate.getMinutes();
    var second = currentDate.getSeconds() <= 9 ? '0' + currentDate.getSeconds() : currentDate.getSeconds();
    // var dropTime = currentDate.getFullYear() + '-' + monthNo + '-' + date + 'T' +
                   // currentDate.getHours() + ':' + currentDate.getMinutes() + ':' + currentDate.getSeconds();
    var dropTime = currentDate.getFullYear() + '-' + monthNo + '-' + date + 'T' + hour + ':' + minutes + ':' + second;

    let myToken = JSON.parse(localStorage.getItem('userInfo')).token;
    this.bookService.createDrop(this.dropData, myToken)
      .subscribe(
        (res: Response) => {
          this.bookingData.dropOid = res.json().oid;
          var bucket = {
            'bookings' : this.bookingData,
            'retailOid': this.retail.oid,
            'price': this.bookingData.price,
            'status': 'BUCKET_AWAITING',
            'vehicleType': this.bookingData.vehicleType,
            'dropOid': res.json().oid,
            'date': dropTime,
            'time': this.bookingData.pickUpTime//
            //"trackNo": "1"
          };
          // console.log(bucket);
          this.bookService.bucketsBook(bucket, myToken)
            .subscribe(
              (response: Response) => {
                this.loadservice.removeloader();
                // this.router.navigate(['/confirm-retail-booking']);
                this.bookingText = 'Bucket Booking created successfully.';
                $('#results').modal('show');
              },
              (err) => {
                this.loadservice.removeloader();
                this.bookingText = 'Error occured while making a booking, Please try again.';
                $('#results').modal('show');
              }
            );
        },
        (error) => {
          this.loadservice.removeloader();
          this.bookingText = 'Error occured while making a booking, Please try again.';
          $('#results').modal('show');
        }
      );
  }

  public onHome() {
    localStorage.removeItem('bookingDataObject');
    localStorage.removeItem('dropObject');
    localStorage.removeItem('distance');
    this.router.navigateByUrl('/retail-profile');
  }
}
