import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
declare var $: any;

@Component({
  selector: 'app-user-otp',
  templateUrl: './user-otp.component.html',
  styleUrls: ['./user-otp.component.css']
})
export class UserOtpComponent implements OnInit {

  otp: number;
  message: string = '';
  userMobile: string = '';
  user: {};

  constructor(private route: Router, private userService: LoginService) { }

  ngOnInit() {
    this.userMobile = localStorage.getItem('userMobile');
    console.log(this.userMobile);
    this.user = JSON.parse(localStorage.getItem('userInfo'));
    console.log(this.user);
  }

  public confirmNewMobile() {
    this.userService.confirmOTP(this.otp, this.userMobile).subscribe(
      response => {
        //console.log(response);
        this.message = 'New number successfully verified';
        $('#successmodal').modal('show');
      }, error => {
        console.log(error);
      }
    )
  }

  public goToHome() {
    this.route.navigateByUrl('/customer-signin');
  }

}
