import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { LoaderService } from 'src/app/services/loader.service';

declare var $: any;

@Component({
  selector: 'app-admin-activities',
  templateUrl: './admin-activities.component.html',
  styleUrls: ['./admin-activities.component.css', '../admin-portal/admin-portal.component.css']
})
export class AdminActivitiesComponent implements OnInit {

  loader: boolean = false;
  userObj: any = {};
  adminActivitiesLogs: any = [];
  fileredResults: any = [];
  foundActivity: any;
  searchActivity: string = '';

  totalNumberOfActivities = 0;
  pageNumber = 0;
  itemsPerPage = 10;
  pageSizeOptions: number[] = [10, 25];

  constructor(private adminService: AdminService,private loaderservice:LoaderService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.userObj = JSON.parse(localStorage.getItem('userInfo'));
    this.adminActivities(1);
  }

  public adminActivities(retrieveType: number) {
    window.scroll(0,0);

    const filter = {
      searchValue: this.searchActivity,
      pageNumber: this.pageNumber,
      pageTotalItems: this.itemsPerPage
    };

    //console.log(filter);
    this.loaderservice.addloader();

    if(retrieveType===0)
      this.resetPagination();

    this.adminService.getAdminActivitiesFilter(this.userObj.token, filter).subscribe(
      response => {
        //console.log(response);
        //this.adminActivitiesLogs = response.content;
        this.fileredResults = response.content;
        this.totalNumberOfActivities = response.totalElement;
        this.loaderservice.removeloader();
      },
      error => {
        this.loaderservice.removeloader();
        console.log(error);
      }
    )
  }

  resetPagination(){
    this.pageNumber = 0;
    this.itemsPerPage = 10;
  }
  // public filterActivities(value: string) {
  //   this.searchActivity = value;
  //   if (this.searchActivity.length > 0) {
  //     this.fileredResults = this.adminActivitiesLogs.filter((activity) => {
  //       // console.log('activity: ', activity['userName'].toLowerCase() == this.searchActivity.toLowerCase());
  //       // console.log('this.searchActivity: ....', this.searchActivity);
  //       // console.log('includes: ', activity['userName'].includes(this.searchActivity))
  //       return (activity['userName'].toLowerCase().includes(this.searchActivity.toLowerCase())
  //       || activity['trackNo'].toLowerCase().includes(this.searchActivity.toLowerCase())
  //       || activity['action'].toLowerCase().includes(this.searchActivity.toLowerCase()))
  //       //return activity['userName'].toLowerCase().includes(activity.toLowerCase())
  //     })
  //   }
  //   else {
  //     this.fileredResults = this.adminActivitiesLogs;
  //   }
  // }
  public showTabByRole(role):boolean{
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let result=false;
    userInfo.roles.map(r=>{
      if(role===r.code){
        result=true;
      }
    })
    return result;
  }

  getNewData(pageEvent?: any) {
    this.pageNumber = pageEvent.pageIndex;
    this.itemsPerPage = pageEvent.pageSize;
    //console.log(this.pageNumber);
    //console.log(this.itemsPerPage);
    this.adminActivities(1);
  }

}
