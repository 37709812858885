import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-retail-type-rate',
  templateUrl: './retail-type-rate.component.html',
  styleUrls: ['./retail-type-rate.component.css']
})
export class RetailTypeRateComponent implements OnInit {

  @ViewChild('typeRateForm', { static: true }) addVForm: NgForm;
  @ViewChild('typeRateUpdateForm', { static: true }) updateVForm: NgForm;

  vehiclesRates : any = [];
  vehicleTypeName : string;
  kilometres : number;
  capacity : number;
  user : any;
  updateKilometres :number;
  updateBasePrice : number;

  textMessage : string = "";
  updatedTypeRate : any;
  updatedTypeName : string;
  retail : any;

  constructor(private service : UserService) { }

  ngOnInit() {
    this.retail = JSON.parse(localStorage.getItem('retail'));
    this.vehiclesRates = this.retail.typeRates;
    // console.log(this.retail);
  }

  public addVehicleType() {

    this.textMessage = "";

    var typeRate = {
      "vehicleType" : this.vehicleTypeName ,
			"kilometer" : this.kilometres ,
      "basePrice": this.capacity,
      "ownerOid": this.retail.oid
    }

    var object = JSON.parse(localStorage.getItem('userInfo'));

    this.service.addTypeRate(object.token, typeRate).subscribe(
      (data) => {
        this.addVForm.reset();
        this.getRetails();
        this.textMessage = "Vehicle Type rate successfully added.";
      },
      (error) => {
        this.textMessage = "Error occured, vehicle type rate not added.";
      }
    );
  }

  public getRetails() {
    this.user = JSON.parse(localStorage.getItem('userInfo'));
     this.service.retriveRetail(this.user.token, this.user.owner.oid).subscribe(
       (res) => {
        this.retail =  res.json()[0];
        this.vehiclesRates = this.retail.typeRates;
        localStorage.setItem('retail', JSON.stringify(this.retail));
       }
     )
  }

  public getVehicleRate(rate){
    this.textMessage = "";
    this.addVForm.reset();
    //this.updateVForm.reset();
    this.updatedTypeRate = rate;
    this.updateKilometres = rate.kilometer;
    this.updateBasePrice = rate.basePrice;
    this.updatedTypeName = rate.vehicleType;
  }

  public updateVehicleType() {
    this.textMessage = "";
    // console.log(this.updatedTypeRate);
    this.updatedTypeRate.kilometer = this.updateKilometres;
    this.updatedTypeRate.basePrice = this.updateBasePrice; 

    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.service.updateTypeRate(object.token, this.updatedTypeRate).subscribe(
      (data) => {
        this.updateVForm.reset();
        this.getRetails();
        this.textMessage = "Vehicle Type rate successfully updated.";
      },
      (error) => {
        this.textMessage = "Error occured, vehicle type rate not updated.";
      }
    );
    // console.log(this.updatedTypeRate);
  }

}
