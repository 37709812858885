import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-plugin-terms-and-conditions',
  templateUrl: './plugin-terms-and-conditions.component.html',
  styleUrls: ['./plugin-terms-and-conditions.component.css']
})
export class PluginTermsAndConditionsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
