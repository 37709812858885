import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { BookingService } from '../../services/booking.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { SharedService } from '../../services/shared.service';
import { UserLoggInService } from '../../services/loggedInUser.service';

declare var $: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css', './v-nav-menu.css', './theme-responsive.css']
})
export class NavbarComponent implements OnInit, OnDestroy {

  userObject: any;
  isIn = false;   // store state
  public title = '';
  subscription: Subscription;
  valid = false;
  validAdmin = false;
  bookingOID: any;
  message: any;
  msgStatus = '';
  yes_value: any;
  yes_value1: any;
  yes_value2: any;
  yes_value3: any;
  yes_value4: any;
  yes_value5: any;
  yes_value6: any;
  no_value: any;
  booking: any = [];
  searcherror: string;

  canNavigateToRetail = false;

  constructor(private service: SharedService, private route: Router, private bookService: BookingService, private userService: UserLoggInService, private ngZone: NgZone) {

    this.subscription = this.service.message.subscribe(
      (message) => {
        this.userObject = JSON.parse(localStorage.getItem('userInfoBooking'));
        if (this.userObject == null) {
          this.title = 'Login';
        } else {
          this.title = this.userObject.owner.firstName;
        }
      }
    );

  }

  ngOnInit() {
    this.userObject = JSON.parse(localStorage.getItem('userInfoBooking'));
    if (this.userObject == null) {
      this.title = 'Login';
    } else {
      this.title = this.userObject.owner.firstName;
      this.userObject.roles.forEach(role => {
        if (role.code == 'retail') {
          this.canNavigateToRetail = true;
        }
      });
    }
  }

  openNav() {
    document.getElementById('mySidenav').style.width = '252px';
  }

  closeNav() {
    document.getElementById('mySidenav').style.width = '0';
  }

  collapse() {
    $(document).ready(function () {
      $('nav').find('li').on('click', 'a', function () {
        $('.navbar-collapse.in').collapse('hide');
      });
    });
  }

  signOut() {
    this.service.setMessage('Logout');
    localStorage.removeItem('userInfoBooking');
    this.title = 'Login';
    localStorage.clear();
    this.closeNav();
    this.ngZone.run(() => this.route.navigateByUrl('/'));
  }

  collapseSign() {
    this.closeNav();
    $(document).ready(function () {
      $('nav').find('li').on('click', 'a', function () {
        $('.navbar-collapse.in').collapse('hide');
      });
    });
    if (this.title != 'Login') {
      if (JSON.parse(localStorage.getItem('userInfoBookingLogin')) != null) {
        this.login(JSON.parse(localStorage.getItem('userInfoBookingLogin')));
      } else {
        localStorage.removeItem('userInfoBooking');
        this.title = 'Login';
        this.route.navigateByUrl('/logon');
      }
    } else {
      localStorage.removeItem('userInfoBooking');
      this.title = 'Login';
      this.route.navigateByUrl('/logon');
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private login(object: any) {
    this.bookService.login(object.pwd, object.username).subscribe(
      (response: any) => {
        localStorage.setItem('userData', JSON.stringify(response));
        localStorage.setItem('token', response.token);
        localStorage.setItem('userInfo', JSON.stringify(response));
        localStorage.setItem('userInfoBooking', JSON.stringify(response));
        response.roles.forEach(role => {
          if (role.code == 'retail') {
            localStorage.setItem('userInfo', JSON.stringify(response));
            this.valid = true;
          }
        });

        if (this.valid) {
          this.userService.setUser(response);
          this.route.navigateByUrl('/retail-profile');
        } else {
          this.userService.setUser(response);
          this.route.navigateByUrl('/user');
        }

      }
    );
  }

  public trackBooking() {
    if (this.bookingOID != null) {
      if (this.bookingOID.length > 10) {
        // read service for waybill
        this.searcherror = '';
        this.bookService.retrieveWayBillStatus(this.bookingOID).subscribe(
          response => {
            if (response.length === 0) {
              this.searcherror = 'No result for this waybill number ' + this.bookingOID;
            } else {

              $('#trackBooking').modal('hide');
              localStorage.setItem('waybillNumber',this.bookingOID);
              localStorage.setItem('retrievedWaybillNumber', JSON.stringify(response));
              this.route.navigate(['/skynet-tracking']);
            }
          },
          error => {
            console.log(error);
          },
          () => {
            this.bookingOID = '';
          }
        );
      } else {
      // read service for normal bookings
      this.bookService.getBookingByTrackNum(this.bookingOID.toUpperCase()).subscribe(
        (response) => {
          console.log(response.json());
          $('#trackBooking').modal('hide');
          this.booking = response.json();
          if (this.booking.length === 1) {
            if (this.booking[0].type === 'EXPRESS_COURIER' || this.booking[0].type === 'BUDGET_COURIER') {
              localStorage.setItem('retrievedTrackBooking', JSON.stringify(response.json()));
              this.route.navigate(['/skynet-tracking']);
            }
            else {
              if (this.booking[0].status === 'Awaiting driver to be assigned' || this.booking[0].status === 'AWAITING_DRIVER') {
                alert('Your booking has not been assigned a driver yet.');
              }
              else if (this.booking[0].status === 'Accepted' || this.booking[0].status === 'RESERVED' ||
              // else if (this.booking[0].status == "Booking taken already" || this.booking[0].status == "RESERVED" ||
                this.booking[0].status === 'Driver in transit' || this.booking[0].status === 'IN_TRANSACT' ||
                this.booking[0].status === 'Booking successfully complete' || this.booking[0].status === 'COMPLETE') {
                localStorage.setItem('retrievedTrackBooking', JSON.stringify(response.json()));
                this.route.navigate(['/internal-tracking']);
              }
            }
          } else if (this.booking.length > 1) {
            localStorage.setItem('retrievedFlightTrackBooking', JSON.stringify(response.json()));
            this.route.navigate(['/flight-tracking']);
          }
          this.bookingOID = '';
        }, (error) => {
          if (error.status === 400) {
            this.message = 'The track number is Invalid.';
            this.bookService.getBucketByTrackNum(this.bookingOID.toUpperCase()).subscribe((res) => {
              console.log(res);
              if (res.status === 'Booking taken already' || res.status === 'RESERVED' ||
                res.status === 'Driver in transit' || res.status === 'IN_TRANSACT' ||
                res.status === 'Booking successfully complete' || res.status === 'COMPLETE') {
                  $('#trackBooking').modal('hide');
                  localStorage.setItem('retrievedTrackBooking', JSON.stringify(res.bookings));
                  this.route.navigate(['Track-Driver-Retail/', res.trackNo]);
              } else {
                this.message = 'Booking has not yet been pushed out.';
              }
            });
          } else {
            this.message = error.error.message;
          }
        }
        );
      }
    } else {
      this.message = 'Please enter the tracking/waybill number.';
    }
  }

  toPartner() {
    $('#partnerRegistration').modal('show');
  }

  goToPartnerRegistration() {
    var ele = document.getElementsByTagName('input');
    var result = 'success';
    var count = 0;
    for (var i = 0; i < ele.length; i++) {
      if (ele[i].type = "radio") {
        if (ele[i].checked) {
          count = count + 1;
          //console.log(ele[i]);
          if (ele[i].value == 'no1' || ele[i].value == 'yes2') {
            result = "fail"
          }
          else if (ele[i].value == 'no3') {
            result = "canopy";
          }
        }
      }
    }
    if (count >= 7) {
      if (result == 'success') {
        this.route.navigate(['/Become-Droppa']);
        this.closeNav();
      }
      else if (result == 'fail') {
        $('#criminalResModal').modal('show');
        this.msgStatus = "You cannot proceed with registration due to criminal record.";
        for (var i = 0; i < ele.length; i++) {
          if (ele[i].type = "radio") {
            if (ele[i].checked) {
              ele[i].checked = false;
            }
          }
        }
      }
      else if (result == 'canopy') {
        $('#criminalResModal').modal('show');
        this.msgStatus = "Currently, registration is only open for vehicles with canopy's.";
        for (var i = 0; i < ele.length; i++) {
          if (ele[i].type = "radio") {
            if (ele[i].checked) {
              ele[i].checked = false;
            }
          }
        }
      }
    }
    else {
      $('#partnerResModal2').modal('show');
      this.msgStatus = "Please answer all questions to proceed with the registration.";
    }
  }

  toRetail() {
    $('#retailRegistration').modal('show');
  }

  goToRetailRagistration() {
    var ele = document.getElementsByTagName('input');
    var result = "success"
    var count = 0
    for (var i = 0; i < ele.length; i++) {
      if (ele[i].type = "radio") {
        if (ele[i].checked) {
          count = count + 1;
          if (ele[i].value == 'no') {
            result = "fail"
          }
        }
      }
    }
    if (count >= 3) {
      if (result == 'success') {
        this.route.navigate(['/Business-Registration']);
        this.closeNav();
      }
      else if (result == 'fail') {
        $('#retailResModal').modal('show');
        this.msgStatus = "All answers must be YES to proceed with the registration";
      }
    }
    else {
      $('#retailResModal2').modal('show');
      this.msgStatus = "Please answer all questions to proceed with the registration";
    }

  }

  onGoHome() {
    this.closeNav();
    this.route.navigateByUrl('/');
  }


}
