import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';
import { AdminService } from '../../services/admin.service';
import { NgForm } from '@angular/forms';

declare var $: any;

@Component({
  selector: 'app-view-vouchers',
  templateUrl: './view-vouchers.component.html',
  styleUrls: ['./view-vouchers.component.css', '../admin-portal/admin-portal.component.css']
})
export class ViewVouchersComponent implements OnInit {

  @ViewChild('mainForm', { static: true }) mainForm: NgForm;
  voucherCodes: any = [];
  firebaseFeedback = '';
  voucher: Voucher = { name: '', price: 0.0, id: '', status: '', vehicleType: '', numOfVouchers: 0.0 };
  addValue = '';
  randomNumber: number;

  constructor(private service: AdminService, public db: AngularFireDatabase) { }

  ngOnInit() {
    window.scroll(0, 0);
    this.db.list('vouchers').valueChanges().subscribe(
      (res) => {
        this.voucherCodes = res;
      }
    );
  }

  public registerVoucher(voucher_code: Voucher) {
    const timestamp = new Date().valueOf();

    for (let x = 1; x <= voucher_code.numOfVouchers; x++) {
      this.randomNumber = Math.floor((Math.random() * 900) + 100);
      var i = 0;
      i = x;
      this.db.database.ref('vouchers').child(voucher_code.name.toUpperCase() + this.randomNumber).set({
        id: timestamp,
        name: voucher_code.name.toUpperCase() + this.randomNumber,
        status: 'Active',
        price: voucher_code.price,
        vehicleType: voucher_code.vehicleType,
        numOfVouchers: voucher_code.numOfVouchers,
        used: 0
      });
    }
    this.voucher.name = "";
    this.voucher.price = 0;
    this.voucher.numOfVouchers = 0;
    this.firebaseFeedback = 'Voucher successfully registered.';
    this.mainForm.resetForm();
  }

  public updateVoucher(index: number, voucher_code: Voucher, status) {
    if (status !== '') {
      var updatedVoucher = voucher_code;
      updatedVoucher.status = status;
      this.db.database.ref('vouchers').child(voucher_code.name.toUpperCase()).update(updatedVoucher);
      this.addValue = 'Voucher updated';
      $('#myModal').modal('show');
      this.mainForm.reset();
    } else {
      this.addValue = 'Please select status';
      alert(this.addValue);
    }
  }

  // public getVouchers() {
  //   this.db.database.ref('vouchers').once('value', (dataSnapshot) => {
  //     dataSnapshot.forEach((childSnapshot) => {
  //       let voucher;
  //       voucher = childSnapshot.val();
  //       this.voucherCodes.push(voucher);
  //     });
  //   });
  // }

  public deleteVoucher(voucher_code: Voucher) {
    this.db.database.ref('vouchers').child(voucher_code.name).remove();
    this.addValue = 'Voucher deleted!';
    $('#confirm').modal('show');
  }

  public showTabByRole(role):boolean{
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let result=false;
    userInfo.roles.map(r=>{
      if(role===r.code){
        result=true;
      }
    })
    return result;
  }
}

interface Voucher {
  name: string;
  price: number;
  id: string;
  status: string;
  vehicleType: string;
  numOfVouchers: number;
}
