import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-all-rentals',
  templateUrl: './all-rentals.component.html',
  styleUrls: ['./all-rentals.component.css', '../admin-portal/admin-portal.component.css']
})
export class AllRentalsComponent implements OnInit {

  adminUser: any;
  allRentals: any = [];
  loader = true;

  totalNumberOfRentals = 0;
  pageNumber = 0;
  itemsPerPage = 25;
  pageSizeOptions: number[] = [10, 25];

  constructor(private adminService: AdminService, private router: Router,private loadservice:LoaderService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));

    this.pageNumber = localStorage.getItem('pageNumber') !== null ? Number(localStorage.getItem('pageNumber')) : 0;
    this.itemsPerPage = localStorage.getItem('itemsPerPage') !== null ? Number(localStorage.getItem('itemsPerPage')) : 25;

    this.getAllRentals();
  }

  public getAllRentals() {
    this.loadservice.addloader();

    const filter = {
      pageNumber: this.pageNumber,
      pageTotalItems: this.itemsPerPage
    };

    console.log("retrieving")

    this.adminService.getAllRentals(this.adminUser.token, filter).subscribe(
      response => {
        console.log(this.allRentals);
        console.log('kuohiu')

        this.allRentals = response.content;
        // this.allRentals = response.reverse();
        this.loadservice.removeloader();
      }, error => {
        console.log(error);
        this.loadservice.removeloader();
        if (error.statusText === 'Unknown Error' || error.statusText === 'Internal Server Error') {
          this.router.navigate(['/logon']);
        }
      }
    );
  }

  public viewRentalBooking(rental) {
    localStorage.setItem('selectedRental', JSON.stringify(rental));
    localStorage.setItem('pageNumber',String(this.pageNumber));
    localStorage.setItem('itemsPerPage', String(this.itemsPerPage));
    this.router.navigate(['/view-rentals']);
  }
  public showTabByRole(role):boolean{
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let result=false;
    userInfo.roles.map(r=>{
      if(role===r.code){
        result=true;
      }
    })
    return result;
  }

  getNewData(pageEvent?: any) {
    this.pageNumber = pageEvent.pageIndex;
    this.itemsPerPage = pageEvent.pageSize;
    this.getAllRentals();
  }

  ngOnDestroy() {
    if(this.router.getCurrentNavigation().finalUrl.toString() !== '/express-booking'){
      localStorage.removeItem('pageNumber');
      localStorage.removeItem('itemsPerPage');
    }

  }

}
