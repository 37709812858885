import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as firebase from 'firebase/app';
// import { FirebaseListObservable } from 'angularfire2/database-deprecated';
// import { AngularFireDatabase } from 'angularfire2/database';
import { AdminService } from '../../services/admin.service';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';
import { Observable, from } from 'rxjs';

declare var $: any;
@Component({
  selector: 'app-retail-promocode',
  templateUrl: './retail-promocode.component.html',
  styleUrls: ['./retail-promocode.component.css']
})
export class RetailPromocodeComponent implements OnInit {

  @ViewChild('mainForm', { static: true }) mainForm: NgForm;
  promoCodes: any = [];
  promo_Codes: any = {};
  firebaseFeedback = '';
  promo: Promo = { name: '', price: 0.0, id: '', status: '' };
  // promotion_codes: FirebaseListObservable<any[]>;
  items: Observable<any[]>;
  addValue = '';
  promoRefList: AngularFireList<any>;      // Reference to promo list, Its an Observable
  promoRefObject: AngularFireObject<any>;
  user: any;

  constructor(private service: AdminService, public db: AngularFireDatabase) {
  }

  ngOnInit() {
    // var promo = firebase.database().ref('promoCode');
    this.user = JSON.parse(localStorage.getItem('userInfo'));
    // console.log(this.user);
    this.db.list('promoCode').valueChanges().subscribe(
      (res) => {
        // console.log(res);
        this.promoCodes = res;
      }
    );
  }

  public registerPromo(promo_code: Promo) {
    const timestamp = new Date().valueOf();
    this.db.database.ref('promoCode').child(promo_code.name.toUpperCase()).set({
      id: timestamp,
      name: promo_code.name.toUpperCase(),
      status: 'Active',
      price: promo_code.price,
      used: 0
    });
    this.firebaseFeedback = 'Promo Code registered successfully';
  }

  public updatePromo(index: number, promo_code: Promo, status) {
    if (status !== '') {
      var updatedPromo = promo_code;
      updatedPromo.status = status;
      this.db.database.ref('promoCode').child(promo_code.name.toUpperCase()).update(updatedPromo);
      this.addValue = 'Promo code updated';
      $('#myModal').modal('show');
      this.mainForm.reset();
    } else {
      this.addValue = 'Please select status';
      alert(this.addValue);
    }
  }
}

interface Promo {
  name: string;
  price: number;
  id: string;
  status: string;
}
