import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';

import { AngularMaterialModule } from './angular-material.module';
import { DataTableModule } from "angular2-datatable";
import { Ng2SearchPipeModule } from 'ng2-search-filter';

import { routing } from '../routing/app.route';
import { Ng4GeoautocompleteModule } from 'ng4-geoautocomplete';
import { OrderModule } from 'ngx-order-pipe';

import { AdminService } from '../services/admin.service';
import { AdminPortalComponent } from '../admin/admin-portal/admin-portal.component';
import { EditPartnerComponent } from '../admin/edit-partner/edit-partner.component';
import { ViewUserComponent } from '../admin/view-user/view-user.component';
import { ViewPromoCodeComponent } from '../admin/view-promo-code/view-promo-code.component';
import { RequestViewComponent } from '../admin/request-view/request-view.component';
import { InvoiceAdminComponent } from '../admin/invoice-admin/invoice-admin.component';
import { QuoteAdminComponent } from '../admin/quote-admin/quote-admin.component';
import { VehicleRateComponent } from '../admin/vehicle-rate/vehicle-rate.component';
import { RetailsPaymentComponent } from '../admin/retails-payment/retails-payment.component';
//PIPES
import { FiltersPipe } from '../pipes/filters.pipe';
import { OrderByDatePipe } from '../pipes/order-by-date.pipe';
import { PipesPipe } from "../pipes/pipes.pipe";

import { SimpleFiltersPipe } from '../pipes/simple-filters.pipe';

import { ViewBoookingComponent } from '../admin/view-boooking/view-boooking.component';
import { UpdateVehicleComponent } from '../admin/update-vehicle/update-vehicle.component';
import { CompanyBankComponent } from '../admin/company-bank/company-bank.component';
import { ViewDriverComponent } from '../admin/view-driver/view-driver.component';
import { BankAccountsComponent } from '../admin/bank-accounts/bank-accounts.component';
import { DriverBookingsComponent } from '../admin/driver-bookings/driver-bookings.component';
import { BookingPaymentComponent } from '../admin/booking-payment/booking-payment.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { RetailViewComponent } from '../admin/retail-view/retail-view.component';
import { NgxPaginationModule } from 'ngx-pagination';

import { BundleBookingsComponent } from '../admin/bundle-bookings/bundle-bookings.component';
import { ViewRetailBookingsComponent } from '../admin/view-retail-bookings/view-retail-bookings.component'; // <-- import the module
import { SendBulkSmsComponent } from '../admin/send-bulk-sms/send-bulk-sms.component';
import { DriverTrackingComponent } from '../admin/driver-tracking/driver-tracking.component';

import { AllBookingsComponent } from '../admin/all-bookings/all-bookings.component';
import { AllUsersComponent } from '../admin/all-users/all-users.component';
import { AllBucketBookingsComponent } from '../admin/all-bucket-bookings/all-bucket-bookings.component';
import { AllRetailsComponent } from '../admin/all-retails/all-retails.component';
import { AllVehiclesComponent } from '../admin/all-vehicles/all-vehicles.component';
import { AllDriversComponent } from '../admin/all-drivers/all-drivers.component';
import { DroppaStatsComponent } from '../admin/droppa-stats/droppa-stats.component';
import { AllExpressBookingsComponent } from '../admin/all-express-bookings/all-express-bookings.component';
import { ViewExpressBookingComponent } from '../admin/view-express-booking/view-express-booking.component';
import { ViewWalletTransactionsComponent } from '../admin/view-wallet-transactions/view-wallet-transactions.component';
import {MatPaginatorModule} from "@angular/material/paginator";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatRadioModule,
        AngularMaterialModule,
        DataTableModule,
        Ng2SearchPipeModule,
        routing,
        Ng4GeoautocompleteModule.forRoot(), OrderModule, OwlDateTimeModule,
        OwlNativeDateTimeModule,
        NgxPaginationModule, MatPaginatorModule
    ],
    exports: [
        MatRadioModule,
        AngularMaterialModule,
        DataTableModule,
        Ng2SearchPipeModule,
        FiltersPipe
    ],
  declarations: [
    AdminPortalComponent,
    EditPartnerComponent,
    ViewUserComponent,
    ViewPromoCodeComponent,
    RequestViewComponent,
    InvoiceAdminComponent,
    QuoteAdminComponent,
    VehicleRateComponent,
    UpdateVehicleComponent,
    CompanyBankComponent,
    FiltersPipe,
    SimpleFiltersPipe,
    PipesPipe,
    OrderByDatePipe,
    ViewBoookingComponent,
    ViewDriverComponent,
    BankAccountsComponent,
    DriverBookingsComponent,
    BookingPaymentComponent,
    RetailViewComponent,
    RetailsPaymentComponent,
    ViewRetailBookingsComponent,
    BundleBookingsComponent,
    SendBulkSmsComponent,
    DriverTrackingComponent,
    AllBookingsComponent,
    AllUsersComponent,
    AllBucketBookingsComponent,
    AllRetailsComponent,
    AllVehiclesComponent,
    AllDriversComponent,
    DroppaStatsComponent,
    AllExpressBookingsComponent,
    ViewExpressBookingComponent,
    ViewWalletTransactionsComponent
  ],
  providers: [AdminService]
})
export class AdminPortalModule { }
