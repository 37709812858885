import { Component, OnInit } from '@angular/core';
import {AdminService} from '../../services/admin.service'
import {Router} from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';
@Component({
  selector: 'app-skynet-portal',
  templateUrl: './skynet-portal.component.html',
  styleUrls: ['./skynet-portal.component.css', '../admin-portal/admin-portal.component.css']
})
export class SkynetPortalComponent implements OnInit {

  // show contract owners by default
  contractOwners = true;
  agents = false;
  skyNetDrivers = false;

  listOfContractOwners = [];
  listOfAgents = [];
  listOfSkyNetDrivers = [];
  loader=false
  token
  user

  constructor(public adminservice:AdminService,private router: Router,private loadservice:LoaderService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.user=localStorage.getItem('userInfo')
    this.token=this.user.token

    this.getContractOwnersList()
    this.getAgentsList()
    this.getDriverList()
  }
  public getContractOwnersList(){
    this.loadservice.addloader()
    this.adminservice.getContractOwnersList(this.token).subscribe(
      (res)=>{
        this.listOfContractOwners=res
        this.loadservice.removeloader()
      },
      (error)=>{
        console.log(error);
        this.loadservice.removeloader()
      }
    )
  }
  public getAgentsList(){
    this.loadservice.addloader()
    this.adminservice.getAgentsList(this.token).subscribe(
      (res)=>{
        this.listOfAgents=res
        this.loadservice.removeloader()
      },
      (error)=>{
        console.log(error);
        this.loadservice.removeloader()
      }
    )
  }
  public getDriverList(){
    this.loadservice.addloader()
    this.adminservice.getDriverList(this.token).subscribe(
      (res)=>{
        this.listOfSkyNetDrivers=res
        this.loadservice.removeloader()

      },
      (error)=>{
        console.log(error);
        this.loadservice.removeloader()
      }
    )
  }

  public showcontractowner(partner) {
    localStorage.setItem('partner', JSON.stringify(partner));
    localStorage.setItem('prevUrl', "skynet-portal");
    this.router.navigate(['/view-partner']);
}

  showContractOwners() {
    this.contractOwners = true;
    this.agents = false;
    this.skyNetDrivers = false;
  }

  showAgents() {
    this.contractOwners = false;
    this.agents = true;
    this.skyNetDrivers = false;
  }

  showSkyNetDrivers() {
    this.contractOwners = false;
    this.agents = false;
    this.skyNetDrivers = true;
  }

}
