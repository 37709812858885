import { TrackDriverRetailComponent } from '../home/track-driver-retail/track-driver-retail.component';
import { TrackDriverComponent } from '../home/track-driver/track-driver.component';
import { Routes } from '@angular/router';
import { HomeComponent } from '../home/home.component';
import { FaqComponent } from '../home/faq/faq.component';
import { SignInComponent } from '../home/sign-in/sign-in.component';
import { CustomerLoginComponent } from '../home/customer-login/customer-login.component';
import { PasswordResetComponent } from '../home/password-reset/password-reset.component';
import { RegistrationComponent } from '../home/registration/registration.component';
import { PartnerLoginComponent } from '../home/partner-login/partner-login.component';
import { ResetPasswordComponent } from '../home/reset-password/reset-password.component';
import { BookingComponent } from '../home/booking/booking.component';
import { SignUpChoiceComponent } from '../home/sign-up-choice/sign-up-choice.component';
import { CustomerSignupComponent } from '../home/customer-signup/customer-signup.component';
import { ConfirmingOtpComponent } from '../home/confirming-otp/confirming-otp.component';
import { PromotionComponent } from '../home/promotion/promotion.component';
import { PrivacyPolicyComponent } from '../home/privacy-policy/privacy-policy.component';
import { AboutDroppaComponent } from '../home/about-droppa/about-droppa.component';
import { BookingSignUpComponent } from '../home/booking-sign-up/booking-sign-up.component';
import { BecomeDroppaComponent } from '../home/become-droppa/become-droppa.component';
import { SelectDateComponent } from '../home/select-date/select-date.component';
import { ErrorMessageComponent } from '../home/error-message/error-message.component';
import { ConfirmOrderComponent } from '../home/confirm-order/confirm-order.component';
import { OnlyLoggedInUsersGuard } from '../guards/onlyLoggedIn.guard';
import { AlwaysAuthGuard } from '../guards/alwaysAuth.guard';
import { BecomeRetailComponent } from '../home/become-retail/become-retail.component';
import { RetailSigninComponent } from '../home/retail-signin/retail-signin.component';
import { DroppaBlogComponent } from '../home/droppa-blog/droppa-blog.component';
import { ConfirmVoucherPaymentComponent } from '../user-portal/confirm-voucher-payment/confirm-voucher-payment.component';
import { BookingResertPasswordComponent } from '../home/booking-resert-password/booking-resert-password.component';
import { BookingOtpComponent } from '../home/booking-otp/booking-otp.component';
import { InternalTrackingComponent } from '../home/internal-tracking/internal-tracking.component';
import { RetailNotActiveComponent } from '../home/retail-not-active/retail-not-active.component';
import { DroppaExpressComponent } from '../home/droppa-express/droppa-express.component';
import { SkynethomeComponent } from '../home/skynethome/skynethome.component';
import { SkynetTrackingComponent } from '../home/skynet-tracking/skynet-tracking.component';
import { FlightTrackingComponent } from '../home/flight-tracking/flight-tracking.component';
import { ResetSkynetPasswordComponent } from '../home/reset-skynet-password/reset-skynet-password.component';
import { ResetSkynetUsersPasswordsComponent } from '../home/reset-skynet-users-passwords/reset-skynet-users-passwords.component';
import { ParcelDetailsComponent } from '../home/parcel-details/parcel-details.component';
import { CheckoutPageComponent } from '../home/checkout-page/checkout-page.component';
import { PluginTermsAndConditionsComponent } from '../home/plugin-terms-and-conditions/plugin-terms-and-conditions.component';
import { SkynetTrackingViaEmailComponent } from '../home/skynet-tracking-via-email/skynet-tracking-via-email.component';
import { FlightTrackingViaEmailComponent } from '../home/flight-tracking-via-email/flight-tracking-via-email.component';
import { ConfirmWalletOrderComponent } from '../home/confirm-wallet-order/confirm-wallet-order.component';
import { ElitePricesComponent } from '../home/elite-prices/elite-prices.component';
import { EliteSignUpComponent } from '../home/elite-sign-up/elite-sign-up.component';
import { EliteConfirmDetailsComponent } from '../home/elite-confirm-details/elite-confirm-details.component';
import { FleetSerivceComponent } from '../home/fleet-serivce/fleet-serivce.component';
import { CourierServiceComponent } from '../home/courier-service/courier-service.component';
import { EliteThankYouPageComponent } from '../home/elite-thank-you-page/elite-thank-you-page.component';
import { TermsCourierComponent } from '../home/terms-courier/terms-courier.component';
import { TermsEliteComponent } from '../home/terms-elite/terms-elite.component';
import { UserOtpComponent } from '../home/user-otp/user-otp.component';
import { BecomeHmbRetailComponent } from '../home/become-hmb-retail/become-hmb-retail.component';
import { TermsComponent } from '../home/terms/terms.component';
import { SelectPaymentComponent } from '../home/select-payment/select-payment.component';
import { SelectPaymentFleetComponent } from '../home/select-payment-fleet/select-payment-fleet.component';
import { SkynetTrackingWaybillComponent } from '../home/skynet-tracking-waybill/skynet-tracking-waybill.component';
import { TrackMyBookingComponent } from '../home/track-my-booking/track-my-booking.component';
import { ClientLoginComponent } from '../home/client-login/client-login.component';
import {PudoServiceComponent} from '../home/pudo-service/pudo-service.component';

export const MAIN_ROUTES: Routes = [

  {path: '', component: HomeComponent},
  {path: 'terms-and-conditions', component: TermsComponent},
  {path: 'aboutDroppa', component: AboutDroppaComponent},
  {path: 'logon', component: SignInComponent},
  {path: 'customer-signin', component: CustomerLoginComponent},
  // Client log in disabled until further notice
  /*{path: 'client-login', component: ClientLoginComponent},*/
  {path: 'forget-password', component: PasswordResetComponent},
  {path: 'signup', component: RegistrationComponent},
  {path: 'partner-signin', component: PartnerLoginComponent},
  {path: 'change-password/:email/:verificationCode', component: ResetPasswordComponent},
  {path: 'booking', component: BookingComponent},
  {path: 'register', component: SignUpChoiceComponent},
  {path: 'customer-signup', component: CustomerSignupComponent},
  {path: 'confirm-otp/:email/:oid', component: ConfirmingOtpComponent},
  {path: 'Confirm-Order/:dropOid/:value/:type', component: ConfirmOrderComponent},
  {path: 'promotion', component: PromotionComponent},
  {path: 'privacy-policy', component: PrivacyPolicyComponent},
  // { path : 'about-droppa', component: AboutDroppaComponent},
  {path: 'Booking-SignUp', component: BookingSignUpComponent},
  {path: 'Become-Droppa', component: BecomeDroppaComponent},
  {path: 'Track-Driver/:trackNo', component: TrackDriverComponent},
  {path: 'Track-Driver-Retail/:trackNo', component: TrackDriverRetailComponent},
  {path: 'Business-Registration', component: BecomeRetailComponent},
  {path: 'Select-Date', component: SelectDateComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  {path: 'Error-Message', component: ErrorMessageComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  {path: 'retail-Signin', component: RetailSigninComponent},
  {path: 'droppa-news-room', component: DroppaBlogComponent},
  {path: 'confirm-voucher-payment', component: ConfirmVoucherPaymentComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  {path: 'booking-forget-password', component: BookingResertPasswordComponent},
  {path: 'booking-otp', component: BookingOtpComponent},
  {path: 'internal-tracking', component: InternalTrackingComponent},
  {path: 'retail-not-active', component: RetailNotActiveComponent},
  {path: 'droppa-express', component: DroppaExpressComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  {path: 'skynet-droppa', component: SkynethomeComponent},
  {path: 'skynet-tracking', component: SkynetTrackingComponent},
  {path: 'skynet-tracking-waybill/:waybillNo', component: SkynetTrackingWaybillComponent},
  {path: 'skynet-tracking/:trackNo', component: SkynetTrackingViaEmailComponent},
  {path: 'flight-tracking', component: FlightTrackingComponent},
  {path: 'flight-tracking/:trackNo', component: FlightTrackingViaEmailComponent},
  {path: 'request-password-reset', component: ResetSkynetPasswordComponent},
  {path: 'reset-password/:accountOid', component: ResetSkynetUsersPasswordsComponent},
  {path: 'parcel-details', component: ParcelDetailsComponent},
  {path: 'checkout/:paymentOid', component: CheckoutPageComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  {path: 'plugin-terms-and-conditions', component: PluginTermsAndConditionsComponent},
  {path: 'Confirm-wallet-order/:value/:type', component: ConfirmWalletOrderComponent},
  {path: 'Elite-prices', component: ElitePricesComponent},
  {path: 'Elite-Sign-Up', component: EliteSignUpComponent},
  {path: 'Elite-Confirm-Details', component: EliteConfirmDetailsComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  {path: 'Droppa-Fleet-Service', component: FleetSerivceComponent},
  {path: 'Courier-Service', component: CourierServiceComponent},
  // Pudo on Home page to be used at a later date ...
  /*{path: 'pudo-service', component: PudoServiceComponent},*/
  {path: 'Elite-Thank-You/:rentalOid', component: EliteThankYouPageComponent},
  {path: 'courier-terms', component: TermsCourierComponent},
  {path: 'elite-terms', component: TermsEliteComponent},
  {path: 'confirm-otp', component: UserOtpComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  {path: 'hmb-business', component: BecomeHmbRetailComponent},
  {path: 'courier-payment', component: SelectPaymentComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  {path: 'fleet-payment', component: SelectPaymentFleetComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  {path: 'track-my-booking', component: TrackMyBookingComponent},
  {path: 'faq', component: FaqComponent}
];
