import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { LoaderService } from 'src/app/services/loader.service';
declare var $: any;

@Component({
  selector: 'app-web-hooks',
  templateUrl: './web-hooks.component.html',
  styleUrls: ['./web-hooks.component.css', '../admin-portal/admin-portal.component.css']
})
export class WebHooksComponent implements OnInit {

  userObj: any = {};
  webHookEv: any = [];
  loader: boolean = false;
  webhookResults: any;
  waybillevents: [];
  searchWaybills: string = '';
  foundWayBill: any;

  totalNumberOfHooks = 0;
  pageNumber = 0;
  itemsPerPage = 25;
  pageSizeOptions: number[] = [10, 25];

  constructor(private adminService: AdminService, private route: Router,private loadservice:LoaderService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.userObj = JSON.parse(localStorage.getItem('userInfo'));
    this.retrieveLocalStorageData();
    this.webHooks();
  }



  public webHooks() {

    const filter = {
      waybill: this.searchWaybills,
      pageNumber: this.pageNumber,
      pageTotalItems: this.itemsPerPage
    };


    this.loadservice.addloader();
    this.adminService.getShortFallsFiltered(this.userObj.token, filter).subscribe(
      response => {
        this.loadservice.removeloader();
        this.webHookEv = response.content;
        this.totalNumberOfHooks = response.totalElement;
        console.log(response);
      }, error => {
        this.loadservice.removeloader();
        console.log(error);
      }
    );
  }

  public viewWaybillEvents(book) {
    localStorage.setItem("webhookEvents", JSON.stringify(book));
    this.setLocalStorageData();
    this.route.navigateByUrl('/web-hook-events');
    this.adminService.getShortFallWaybillEvents(this.userObj.token, book.waybill).subscribe(response => {
      this.webhookResults = response;
      this.waybillevents = response.WaybillParcelDetail;
    }, error => {
      console.log(error);
    });
  }


  public changeStatusToPaid(shortFall) {
    this.adminService.changeShortfallStatus(this.userObj.token, shortFall.bookingOid).subscribe(
      (response) => {
        // console.log(response);
        shortFall.status = 'PAID';
        this.webHooks();
        // this.searchWaybill(shortFall.waybill);
      },
      (error) => {
        // console.log(error);
      }
    );
  }

  triggerGetHooks(type:number){
    if(type === 1)
      this.resetPagination();

    this.webHooks();
  }

  resetPagination(){
    this.pageNumber = 0;
    this.itemsPerPage = 25;
  }

  getNewData(pageEvent?: any) {
    this.pageNumber = pageEvent.pageIndex;
    this.itemsPerPage = pageEvent.pageSize;
    this.triggerGetHooks(0)
  }

  ngOnDestroy() {
    if(this.route.getCurrentNavigation().finalUrl.toString() !== '/web-hook-events')
      this.removeLocalStorageData();

  }


  retrieveLocalStorageData(){
    this.searchWaybills = localStorage.getItem('searchWaybills') !== null ? (localStorage.getItem('searchWaybills')) : '';
    this.pageNumber = localStorage.getItem('pageNumber') !== null ? Number(localStorage.getItem('pageNumber')) : 0;
    this.itemsPerPage = localStorage.getItem('itemsPerPage') !== null ? Number(localStorage.getItem('itemsPerPage')) : 25;
  }

  setLocalStorageData(){
    localStorage.setItem('searchWaybills', this.searchWaybills);
    localStorage.setItem('pageNumber', String(this.pageNumber));
    localStorage.setItem('itemsPerPage', String(this.itemsPerPage));
  }

  removeLocalStorageData(){
    localStorage.removeItem('searchWaybills');
    localStorage.removeItem('pageNumber');
    localStorage.removeItem('itemsPerPage');
  }
}
