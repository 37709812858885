import { Component, OnInit, AfterViewChecked, Output, OnDestroy, NgZone } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { BookingService } from '../../services/booking.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { SharedService } from '../../services/shared.service';
import { UserLoggInService } from '../../services/loggedInUser.service';
declare var $: any;
import { ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-developers-portal-navbar',
  templateUrl: './developers-portal-navbar.component.html',
  styleUrls: ['./developers-portal-navbar.component.css', './v-nav-menu.css',
    './theme-responsive.css']
})
export class DevelopersPortalNavbarComponent implements OnInit {


  userObject: any;
  isIn: boolean = false;   // store state
  public title: string = '';
  subscription: Subscription;
  valid: boolean = false;
  validAdmin: boolean = false;

  constructor(private service: SharedService, private route: Router, private bookService: BookingService,
    private userService: UserLoggInService, private ngZone: NgZone) {

    this.subscription = this.service.message.subscribe(
      (message) => {
        this.userObject = JSON.parse(localStorage.getItem('userInfoBooking'));
        if (this.userObject == null) {
          this.title = "Sign In";

        } else {
          this.title = this.userObject.owner.firstName;
        }

      }
    );
  }


  ngOnInit() {

    this.userObject = JSON.parse(localStorage.getItem('userInfoBooking'));
    if (this.userObject == null) {
      this.title = "Sign In";
    }
    else {
      this.title = this.userObject.owner.firstName;

    }

    this. collapseSign()

  }

  signOut() {
    this.service.setMessage("Sign Out");
    localStorage.removeItem('userInfoBooking');
    this.title = "Sign In";
    localStorage.clear();
    this.ngZone.run(() => this.route.navigateByUrl('/developers-portal'))
  }



  collapseSign() {
    if (this.title != "Sign In") {
      if (JSON.parse(localStorage.getItem('userInfoBookingLogin')) != null) {

      } else {
        localStorage.removeItem('userInfoBooking');
        this.title = "Sign In";
        this.route.navigateByUrl('/developers-sign-in');
      }
    } else {
      localStorage.removeItem('userInfoBooking');
      this.title = "Sign In";
      this.route.navigateByUrl('/developers-portal');

    }

  }

  hideDocumentation() {
    $('#listOfLocations').on('hidden.bs.collapse', function () {
    }).collapse('hide');
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


}
