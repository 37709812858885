import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { AdminService } from '../../services/admin.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoaderService } from 'src/app/services/loader.service';

declare var $: any;

@Component({
  selector: 'app-invoice-admin',
  templateUrl: './invoice-admin.component.html',
  styleUrls: ['./invoice-admin.component.css', '../admin-portal/admin-portal.component.css']
})
export class InvoiceAdminComponent implements OnInit {

  @ViewChild('detailsForm', { static: false }) contactForms: NgForm;

  reciptEmail: string;
  recipientName: string;
  price: string;
  time: string;
  date: string;
  vehicle_type: string;
  hour: number;
  msg = '';
  company: any;
  adminUser: any;
  companies: any = [];

  billType: any = [
    {
      "value": "invoice"
    },
    {
      "value": "quote"
    }
  ];

  radioSelected: any;

  receipentName: string;
  email: string;
  phone: string;
  ref: string;
  address: string;
  addressLocalty: string;
  addressCity: string;
  addressCode: string;
  description: string;
  qty: number;
  list: any = [];
  invoice: any;
  tax: number;
  userPortalObj: any;
  item: any;
  total = 0.0;
  discount: number;

  invoiceData: any;
  loader: boolean;
  priceAmt: number;
  listOfInvoice: any;
  subTotal = 0.0;
  myTotal = 0.0;
  tempTaxAmt = 0.0;
  tempTotal = 0.0;
  totalAmtToDiscount = 0.0;

  today: any;
  dd: any;
  mm: any;
  discountValue: number;
  vatValue: number;
  bankName: any;
  recipentEmail: any;
  accontNumber: any;
  addressLocality: any = [];
  branch: any;
  recipientPhone: any;
  reference: any;
  errorMessage: any;
  holder: any;
  resp: any;
  resultMsg = '';
  Billpdf = null;

  public userSettings: any = {
    showRecentSearch: false,
    geoCountryRestriction: ['ZA'],
    inputPlaceholderText: 'Enter address.',
    geoTypes: ['address', 'establishment']
  };
  pickUpNotMsg: string;
  pickUpNotFound: boolean;

  billAddress = {
    addressLine1: '',
    locality: '',
    city: '',
    postalCode: ''
  };
  choiceMessage: string;

  constructor(private adminService: AdminService, private router: Router,private loadservice:LoaderService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
  }

  onItemChange(item) {
    this.invoice = item.value;
  }

  public totalAmont() {
    let totalPrice = 0.0;
    for (let i = 0; i < this.list.length; i++) {
      totalPrice = totalPrice + (this.list[i].price * this.list[i].quantity);
    }
    return totalPrice;
  }

  public totalPrice(list) {
    let totalPrice = 0.0;
    for (let i = 0; i < list.length; i++) {
      totalPrice = totalPrice + (list[i].price * list[i].quantity);
    }
    return totalPrice;
  }

  public addItem() {
    if (this.list.length === 20) {
      this.msg = 'You can only add 20 items.';
      $('#duplicate').modal('show');
      return;
    }
    if (this.check(this.description)) {
      this.msg = 'Opps, the item description already exists';
      $('#duplicate').modal('show');
      return;
    }

    this.list.push(

      this.item =
      {
      description: this.description,
      price: Math.round(this.priceAmt),
      quantity: this.qty
      }

      );

    this.subTotal = Math.round(this.priceAmt) * this.qty;
    this.total = this.totalAmont();
    this.myTotal = this.totalAmont();

    this.tempTotal = this.myTotal;
    this.tempTaxAmt = this.total;

    this.totalAmtToDiscount = this.total + this.subTotal;

    if (this.tax > 0) {
      this.total = 0.0;
      this.total = ((this.tax / 100) * this.myTotal) + this.myTotal;
    }

    if (this.discount > 0) {
      if (this.tax > 0) {
        this.total = this.total - this.discount;
      } else {
        this.total = this.tempTotal - this.discount;
      }
    }

    this.description = '';
    this.priceAmt = 0;
    this.qty = 0;

    document.getElementById('description').focus();

    this.subTotal = 0.0;
  }

  public check(object) {
    let value = false;
    for (let i = 0; i < this.list.length; i++) {
      if (this.list[i].description === object) {
        value = true;
        break;
      }
    }
    return value;
  }

  public onTaxChange(value) {
    if (isNaN(value) === false) {
      this.total = 0;

      if (value > 0) {
        if (this.discount > 0) {
          this.total = (((value / 100) * this.totalAmont()) + this.totalAmont()) - (this.discount);
          this.tempTaxAmt = this.total;
        } else {
          this.total = ((value / 100) * this.totalAmont()) + this.totalAmont();
          this.tempTaxAmt = this.total;
        }
      } else {
        this.total = ((value / 100) * this.totalAmont()) + this.totalAmont();
        this.tempTaxAmt = this.total;
      }
    } else {
      if (this.discount > 0) {
        this.total = (this.totalAmont()) - (this.discount);
        this.tempTaxAmt = this.totalAmont() - this.discount;
      } else {
        this.total = this.totalAmont();
        this.tempTaxAmt = this.totalAmont();
      }
    }
  }

  onDiscountChange(value) {
    if (isNaN(value) === false) {
      if (value > 0) {
        if (this.tax > 0) {
          this.total = (((this.tax / 100) * this.totalAmont()) + this.totalAmont()) - value;
        } else {
          this.total = this.totalAmont() - value;
        }
      } else {
        this.total = this.totalAmont();
      }
    } else {
      if (this.tax > 0) {
        this.total = (((this.tax / 100) * this.totalAmont()) + this.totalAmont());
      } else {
        this.total = this.totalAmont();
      }
    }
  }

  public delete(item, index) {
    if (this.check(item.description)) {
      this.list.splice(index, 1);
      if (this.check(item.description)) {
        this.total = 0.0;
        if (this.discount > 0) {
          const currentTotal = (((this.tax / 100) * this.totalAmont()) + this.totalAmont());
          this.total = currentTotal - this.discount;
        } else {
          const currentTotal = (((this.tax / 100) * this.totalAmont()) + this.totalAmont());
          this.total = currentTotal;
        }
      } else {
        this.total = 0.0;
        if (this.discount > 0) {
          this.total = this.totalAmont() - this.discount;
        } else {
          this.total = this.totalAmont();
        }
      }
    }
  }

  public reviewInvoice(company) {
    this.today = new Date();
    this.dd = this.today.getDate();
    this.mm = this.today.getMonth() + 1;

    const yyyy = this.today.getFullYear();
    if (this.dd < 10) {
      this.dd = '0' + this.dd;
    }
    if (this.mm < 10) {
      this.mm = '0' + this.mm;
    }

    this.today = this.dd + '/' + this.mm + '/' + yyyy;
    this.vatValue = (this.tax == null) ? 0.0 : this.tax;
    this.discountValue = (this.discount == null) ? 0.0 : this.discount;

    this.listOfInvoice = this.list;
    const addr = {
      addressLine1: this.address,
      locality: this.addressLocalty,
      city: this.addressCity,
      postalCode: this.addressCode
    };

    const invoiceData = {
      companyOid: this.company.oid,
      generatedDate: this.today,
      reference: this.ref,
      recipientName: this.receipentName,
      recipientEmail: this.email,
      discountAmt: this.discountValue,
      salesTax: this.vatValue,
      actualRecipient: this.email,
      documentType: null,
      recipientPhone: this.phone,
      recipientAddress: addr,
      listOfInvoice: this.list,
      address:
      {
        addressLine1: this.address,
        locality: this.addressLocalty,
        city: this.addressCity,
        postalCode: this.addressCode
      }
    };
  }

  downloadInvoice() {
    this.loadservice.addloader();
    if (this.list.length !== 0) {
      this.loadservice.removeloader();

      const index = this.address.split(',');
      const billAddress = {
        addressLine1: this.billAddress.addressLine1 = index[0],
        locality: this.billAddress.locality = index[1],
        city: this.billAddress.city = index[2],
        postalCode: this.billAddress.postalCode = index[3]
      };

      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0');
      var yyyy = today.getFullYear();

      let todayDate = mm + '/' + dd + '/' + yyyy;

      if (this.invoice !== undefined) {
        const fieldType = this.invoice;
        const invoiceData = {
          generatedDate: todayDate,
          reference: this.reference,
          recipientName: this.recipientName,
          recipientEmail: this.recipentEmail,
          discountAmt: this.discount,
          salesTax: this.tax,
          listOfInvoice: this.list,
          actualRecipient: this.email,
          recipientPhone: this.recipientPhone,
          bankName: this.bankName,
          accountName: this.holder,
          accountNo: this.accontNumber,
          branchCode: this.branch,
          recipientAddress: billAddress,
          actionType: 'download',
          billDocumenType: fieldType
        };
        this.loadservice.addloader();
        // console.log(invoiceData);
        this.adminService.downloadInvoice(this.adminUser.token, invoiceData).subscribe(
          (response) => {
            // console.log(response);
            this.resp = response;
            if (this.resp.fileBytes != null) {
              // console.log(fieldType);
              this.Billpdf = 'data:application/octet-stream;base64,' + this.resp.fileBytes;
              const a = document.createElement('a');
              document.body.appendChild(a);
              a.setAttribute('id', 'qtlink');
              document.getElementById('qtlink').title = invoiceData.recipientName + '-' + invoiceData.reference + '.pdf';
              const elementTitle = document.getElementById('qtlink').title;
              a.setAttribute('download', elementTitle);
              a.href = this.Billpdf;
              a.click();
              $('#invoiceSuccess').modal('show');
              this.resultMsg = 'Successfully downloaded.';
            } else {
              $('#invoiceError').modal('show');
              this.errorMessage = 'Error while downloading.';
              this.loadservice.removeloader();
            }
          }, (error) => {
            // console.log(error);
          });
      } else {
        $('#choiceNull').modal('show');
        this.choiceMessage = 'Please choose whether its an invoice or a quotation.';
      }
    } else {
      this.loadservice.removeloader();
      $('#invoiceError').modal('show');
      this.errorMessage = 'Please enter the items.';
      return;
    }
  }

  autoCompleteCallback(selectedData: any) {
    if (!selectedData) {
      $('#searchError').modal('show');
      this.pickUpNotMsg = 'Location not found, Please enter nearest location.';
      this.pickUpNotFound = false;
    } else {
      this.address = selectedData.data.description;
      const index = this.address.split(',');
      const billAddress = {
        addressLine1: this.billAddress.addressLine1 = index[0],
        locality: this.billAddress.locality = index[1],
        city: this.billAddress.city = index[2],
        postalCode: this.billAddress.postalCode = index[3]
      };
      this.billAddress.addressLine1 = index[0];
      this.billAddress.locality = index[1];
      this.billAddress.city = index[2];
      this.billAddress.postalCode = index[3];
    }
  }
  public showTabByRole(role):boolean{
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let result=false;
    userInfo.roles.map(r=>{
      if(role===r.code){
        result=true;
      }
    })
    return result;
  }
}
