import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-retail-invoice-review',
  templateUrl: './retail-invoice-review.component.html',
  styleUrls: ['./retail-invoice-review.component.css', '../admin-portal/admin-portal.component.css']
})
export class RetailInvoiceReviewComponent implements OnInit {

  invoiceReview: any;
  admin: any;
  retialOid: any;
  invoiceNo: any;
  isPayed: any;
  message: any;

  constructor(private adminService: AdminService, private service: UserService, private route: Router) { }

  ngOnInit() {
    this.admin = JSON.parse(localStorage.getItem('userInfo'));
    this.invoiceReview = JSON.parse(localStorage.getItem('selectedRetailInvoice'));
    // console.log(this.invoiceReview);
  }

  public paidInvoice(review) {
    // console.log(review);
    this.invoiceReview = JSON.parse(localStorage.getItem('selectedRetailInvoice'));

    const invoiceBody = {
      retailOid: review.retail.oid,
      invoiceNo: review.number,
      isPayed: true
    };

    // console.log(invoiceBody);

    this.adminService.updateInvoice(this.admin.token, invoiceBody).subscribe(
      (response) => {
        // console.log(response);
        $('#confirmation').modal('show');
        this.message = 'Item has been paid!';
      }, (error) => {
        // console.log(error);
        $('#confirmation').modal('show');
        this.message = 'Item has NOT been paid!';
      }, () => {
        // console.log('Finally');
      }
    );
  }
  public showTabByRole(role):boolean{
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let result=false;
    userInfo.roles.map(r=>{
      if(role===r.code){
        result=true;
      }
    })
    return result;
  }
}
