import {Component, OnDestroy, OnInit} from '@angular/core';
import {AdminService} from '../../services/admin.service';
import {Router} from '@angular/router';
import {BookingService} from '../../services/booking.service';
import { LoaderService } from 'src/app/services/loader.service';

declare var $: any;

@Component({
  selector: 'app-operations',
  templateUrl: './operations.component.html',
  styleUrls: ['./operations.component.css']
})
export class OperationsComponent implements OnInit, OnDestroy {

  constructor(private adminService: AdminService, private router: Router, private bookService: BookingService,
              private loadservice: LoaderService) { }

  loader = false;

  allBookings: any;
  allBucketBookings: any;
  allExceptionBookings = [];
  allTenderBookings = [];
  allIndividualPriorityBookings = [];
  allBusinessPriorityBookings = [];
  allInsuredBookings = [];
  bookingData: any;
  bookings: any = [];
  bucketBookings: any = [];
  priorityBookings = [];
  adminUser: any;
  public searchQueryStatus: string;
  public searchBucketQueryStatus: string;
  public searchQueryWaybill: string;

  public trackAndWaybillNumberArray: any[] = [
    { value: '0', option: '' },
    { value: '1', option: 'Track Number' },
    { value: '2', option: 'Waybill Number' }
  ];

  searchUsers: any;
  searchBucketUsers: any;
  status = 'status';
  platforms = 'platform';
  msg: any;

  totalBookings: any;
  totalBookingPrice: any;
  totalWeeklyBooking: any;
  gautentBooingCount: any;
  westerCapetBooingCount: any;
  mobileCount: any;
  webCount: any;
  todayCount: any;
  montlyCount: any;
  walletPayment: any;
  bookingFoundData: any;
  foundBooking: any;
  bookingArray: any = [];

  awaitingBookingMsg = '';
  columnWidth: any;

  numMinutes = 0;
  numHours = 0;
  intervals = 60000 * this.numMinutes + 3600000 * this.numHours;

  changeColour: number;

  lastBookingsRefreshTime: Date;
  lastBucketRefreshTime: Date;

  bookingInterval: any;
  colorChangeInterVal:any;

  badWeather = false;
  lightWeather = false;

  pageNumber = 1;

  ngOnInit() {
    window.scrollTo(0, 0);
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    // this.getTotalBookings(this.adminUser.token);
    this.getBookings();
    this.getWeather();
    this.colorChangeInterVal = setInterval(() => this.runTheTime(), 1000);

    // Refresh tables and weather every 5 minutes
    this.bookingInterval = setInterval(() => {this.getBookings(); this.getWeather(); }, (1000 * 60 * 5));
  }

  private getTotalBookings(token) {
    this.loadservice.addloader();
    this.adminService.getTotalAllBookings(token)
      .subscribe(
        (res) => {
          this.totalBookings = res.bookingMatrixDTO.count;
          this.totalBookingPrice = res.bookingMatrixDTO.total;
          this.totalWeeklyBooking = res.bookingMatrixDTO.weeklyBookings;
          this.montlyCount = res.bookingMatrixDTO.monthlyBooking;
          this.todayCount = res.bookingMatrixDTO.dailyBooking;
          this.gautentBooingCount = res.bookingMatrixDTO.totalGauteng;
          this.westerCapetBooingCount = res.bookingMatrixDTO.totalWesternCape;
          this.mobileCount = res.bookingMatrixDTO.totalMobile;
          this.webCount = res.bookingMatrixDTO.totalWeb;
          this.loadservice.removeloader();
        },
        (err) => {
          this.loadservice.removeloader();
          if (err.statusText === 'Unknown Error' || err.statusText === 'Internal Server Error') {
            this.router.navigate(['/logon']);
          }
        }
      );
  }

  runTheTime() {
    this.changeColour = new Date().getSeconds();
  }

  public stopInterval() {
    clearInterval(this.bookingInterval);
    this.router.navigate(['/admin-home']);
  }

  public getBookings() {
    this.lastBookingsRefreshTime = new Date();
    this.loadservice.addloader();
    this.adminService.getAllCutomerBookings(this.adminUser.token)
      .subscribe(
        (res) => {
          this.loadservice.removeloader();
          // console.log('booking', res);
          this.filterBookings(res);
        },
        (err) => {
          this.loadservice.removeloader();
          if (err.statusText === 'Unknown Error' || err.statusText === 'Internal Server Error') {
            this.router.navigate(['/logon']);
          }
        }
      );
    this.getBucketBookings();
    this.getExceptionBookings();
    this.getTenderBookings();
    this.getIndividualPriorityBookings();
    this.getBusinessPriorityBookings();
  }

  filterBookings(bookings) {
    // console.log(bookings);
    this.bookings = [];
    bookings.forEach((booking) => {
      if ((booking.type !== 'EXPRESS_COURIER' && booking.type !== 'BUDGET_COURIER') &&
        (booking.status === 'Awaiting driver to be assigned' || booking.status === 'AWAITING_DRIVER' ||
        booking.status === 'Driver in transit' || booking.status === 'IN_TRANSACT' ||
        booking.status === 'Accepted' || booking.status === 'RESERVED' ||
        booking.status === 'Processing Waybill' || booking.status === 'PROCESSING_WAYBILL')) {
        this.bookings.push(booking);
      }
      if ((booking.type === 'EXPRESS_COURIER' || booking.type === 'BUDGET_COURIER') &&
        (booking.status === 'Processing Waybill' || booking.status === 'PROCESSING_WAYBILL')) {
        this.bookings.push(booking);
      }
    });
    this.tempBookings();
  }

  tempBookings() {
    this.allBookings = this.bookings;
  }

  getBucketBookings() {
    this.lastBucketRefreshTime = new Date();
    this.loadservice.addloader();
    this.adminService.getAllBucketBooking(this.adminUser.token)
      .subscribe(
        (res) => {
          this.loadservice.removeloader();
          this.filterBucketBookings(res);
        },
        (err) => {
          this.loadservice.removeloader();
          if (err.statusText === 'Unknown Error' || err.statusText === 'Internal Server Error') {
            this.router.navigate(['/logon']);
          }
        }
      );
  }

  filterBucketBookings(bookings) {
    // console.log(bookings);
    this.bucketBookings = [];
    bookings.forEach((bucket) => {
      if (bucket.status === 'AWAITING_DRIVER' || bucket.status === 'RESERVED' ||
        bucket.status === 'IN_TRANSACT' || bucket.status === 'AWAITING_AUTHORIZATION' ||
        bucket.status === 'PROCESSING_WAYBILL') {
        /*const myBook = {
          retailName: bucket.retail.name,
          date: bucket.date,
          time: bucket.time,
          vehicleType: booking.vehicleType,
          price: booking.price,
          status: booking.status,
          trackNo: booking.trackNo
        };*/
        this.bucketBookings.push(bucket);
      }
      /*console.log(bookings);
      bucket.bookings.forEach((booking) => {
        if (booking.status === 'AWAITING_DRIVER' || booking.status === 'RESERVED' ||
          booking.status === 'IN_TRANSACT' || booking.status === 'AWAITING_AUTHORIZATION' ||
          booking.status === 'PROCESSING_WAYBILL') {
          const myBook = {
            retailName: bucket.retail.name,
            date: bucket.date,
            time: bucket.time,
            vehicleType: booking.vehicleType,
            price: booking.price,
            status: booking.status,
            trackNo: booking.trackNo
          };
          this.bucketBookings.push(myBook);
        }
      });*/
    });
    // console.log(this.bucketBookings);
    this.tempBucketBookings();
  }

  tempBucketBookings() {
    this.allBucketBookings = this.bucketBookings;
  }

  getExceptionBookings() {
    this.loadservice.addloader();
    this.adminService.getExceptionBookings(this.adminUser.token)
      .subscribe(
        (res) => {
          this.loadservice.removeloader();
          // console.log('booking', res);
          this.filterExceptionBookings(res);
        },
        (err) => {
          this.loadservice.removeloader();
          if (err.statusText === 'Unknown Error' || err.statusText === 'Internal Server Error') {
            this.router.navigate(['/logon']);
          }
        }
      );
  }

  filterExceptionBookings(bookings) {
    this.allExceptionBookings = [];
    bookings.forEach((booking) => {
      // Should there be a need to filter, the 'if' below can be used
      if (booking.skynetStatus !== null && booking.skynetStatus.toLowerCase() === 'created waybill') {
        this.allExceptionBookings.push(booking);
      }
    });
  }

  getTenderBookings() {
    this.loadservice.addloader();
    this.adminService.getTenderBookings(this.adminUser.token)
      .subscribe(
        (res) => {
          this.loadservice.removeloader();
          this.filterTenderBookings(res);
        },
        (err) => {
          this.loadservice.removeloader();
          if (err.statusText === 'Unknown Error' || err.statusText === 'Internal Server Error') {
            this.router.navigate(['/logon']);
          }
        }
      );
  }

  filterTenderBookings(bookings) {
    this.allTenderBookings = [];
    bookings.forEach((booking) => {
      if (booking.status !== null && (booking.status.toLowerCase() === 'created waybill' || booking.status.toLowerCase() === 'waybill created')) {
        this.allTenderBookings.push(booking);
      }
    });
  }

  getIndividualPriorityBookings() {
    this.loadservice.addloader();
    this.adminService.getIndividualPriorityBookings(this.adminUser.token)
      .subscribe(
        (res: any) => {
          this.loadservice.removeloader();
          this.allIndividualPriorityBookings = res;
        },
        (err) => {
          this.loadservice.removeloader();
          if (err.statusText === 'Unknown Error' || err.statusText === 'Internal Server Error') {
            this.router.navigate(['/logon']);
          }
        }
      );
  }

  getBusinessPriorityBookings() {
    this.loadservice.addloader();
    this.adminService.getBusinessPriorityBookings(this.adminUser.token)
      .subscribe(
        (res: any) => {
          this.loadservice.removeloader();
          this.allBusinessPriorityBookings = res;
        },
        (err) => {
          this.loadservice.removeloader();
          if (err.statusText === 'Unknown Error' || err.statusText === 'Internal Server Error') {
            this.router.navigate(['/logon']);
          }
        }
      );
  }

  /*getInsuredBookings() {
    this.loadservice.addloader();
    this.adminService.getInsuredBookings(this.adminUser.token)
      .subscribe(
        (res: any) => {
          this.loadservice.removeloader();
          this.allInsuredBookings = res;
        },
        (err) => {
          this.loadservice.removeloader();
          if (err.statusText === 'Unknown Error' || err.statusText === 'Internal Server Error') {
            this.router.navigate(['/logon']);
          }
        }
      );
  }*/

  getWeather() {
    this.adminService.POIweatherStatus(this.adminUser.token, 'bad').subscribe(
      (res) => {
        // console.log(res)
        this.badWeather = res.length > 0;

      },
      (err) => {
        console.log(err);
      });

    this.adminService.POIweatherStatus(this.adminUser.token, 'light').subscribe(
      (res) => {
        // console.log(res)
        this.lightWeather = res.length > 0;
      },
      (err) => {
        console.log(err);
      });
  }

  goToPOI() {
    this.stopInterval();
    this.router.navigateByUrl('/points-of-interest');
  }

  viewPage(pageNumber: number) {
    this.pageNumber = pageNumber;

  }

  /**
   * Use a track number to view a single booking
   */
  public searchBooking() {

    // this.loadservice.r();
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));

    this.selectChangeHandler();
  }

  /**
   * @description Model Change the results based on the Tracking or Waybill number
   * @returns null
   */
  private selectChangeHandler(): void {
    if (this.searchQueryWaybill === 'Track Number') {

      this.bookService.getBookingByTrackNum(this.searchUsers.toUpperCase()).subscribe((response: any) => {

        this.bookingArray = response.json();

        this.bookingArray.forEach((foundElement: any) => {
          if (foundElement.trackNo === this.searchUsers) {
            this.bookingFoundData = foundElement;
          }
        });

        this.loadservice.removeloader();
      }, (error) => {
        console.log(error);
        // $('#searchBooking').modal('show');
        this.msg = 'The booking does not exist. Please make sure you entered the right track number.';
        this.loadservice.removeloader();
      });

    } else if (this.searchQueryWaybill === 'Waybill Number') {

      this.bookService.getBookingByWaybillNumber(this.searchUsers.toUpperCase()).subscribe((response: any) => {
        this.bookingFoundData = response.json();
        this.loadservice.removeloader();
      }, (error) => {
        console.log(error);
        // $('#searchBooking').modal('show');
        this.msg = 'The booking does not exist. Please make sure you entered the right waybill number.';
        this.loadservice.removeloader();
      });

    } else {
      // Do nothing
    }
  }

  searchBookings(ev: any) {
    // this.tempBookings();
    const valueToSearch = ev;

    if ((ev) && (ev.trim() !== '')) {
      this.bookings = this.allBookings;
      this.bookings = this.bookings.filter((item: any) => item.trackNo.includes(ev.toUpperCase()));
    } else {
      this.bookings = this.allBookings;
      // $('#searchBooking').modal('show');
      this.msg = 'Please enter a track number to search for a booking.';
      this.loadservice.removeloader();
    }
  }

  searchBucketBookings(ev: any) {
    // this.tempBookings();
    const valueToSearch = ev;

    if ((ev) && (ev.trim() !== '')) {
      this.bucketBookings = this.allBucketBookings;
      this.bucketBookings = this.bucketBookings.filter((item: any) => item.trackNo.includes(ev.toUpperCase()));
    } else {
      this.bucketBookings = this.allBucketBookings;
      // $('#searchBooking').modal('show');
      this.msg = 'Please enter a track number to search for a booking.';
      this.loadservice.removeloader();
    }
  }

  clearAwaitingPaymentBookings() {
    this.loadservice.addloader();
    this.adminService.removeBookings(this.adminUser.token)
      .subscribe(
        (res) => {
          this.loadservice.removeloader();
          console.log(res);
          this.awaitingBookingMsg = 'Awaiting payment bookings cleared.';
          $('#clearAwaitingPayment').modal('show');
        },
        (err) => {
          this.loadservice.removeloader();
          console.log(err);
          this.awaitingBookingMsg = err.error.message;
          // $('#clearAwaitingPayment').modal('show');
          if (err.statusText === 'Unknown Error' || err.statusText === 'Internal Server Error') {
            this.router.navigate(['/logon']);
          }
        }
      );
  }

  ngOnDestroy() {
    clearInterval(this.bookingInterval);
    clearInterval(this.colorChangeInterVal);
  }
}
