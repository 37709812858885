import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { SharedService } from './shared.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable()
export class UserService {

  constructor(private http: Http, private httpClient: HttpClient,
    private sharedService: SharedService) { }

  public retriveBooking(token, userOid): Observable<any> {

    var userToken = "Bearer " + token;
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Authorization", userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.get(this.sharedService.getUrl() + "drops/bookings/all/" + userOid, options);
  }

  public retriveBookingPaginated1(token, filter): Observable<any> {

    var userToken = "Bearer " + token;
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Authorization", userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + "drops/bookings/all/",filter, options);
  }

  public retrieveBookingPaginated(token, filter): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'drops/bookings/all/', filter,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }
  public getAlertMessage(token, userOid): Observable<any> {

    var userToken = "Bearer " + token;
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Authorization", userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.get(this.sharedService.getUrl() + "sessions/user/message/" + userOid, options);
  }


  public retriveRetail(token, userOid): Observable<any> {

    var userToken = "Bearer " + token;
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Authorization", userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.get(this.sharedService.getUrl() + "parties/persons/" + userOid + "/retails", options);
  }

  public getBucketBooking(token, retailOid): Observable<any> {
    var userToken = "Bearer " + token;
    return this.httpClient.get(this.sharedService.getUrl() + "bookings/buckets/retail/" + retailOid,
      {
        headers: new HttpHeaders().set("Accept", 'application/json').set('Content-Type', 'application/json')
          .set("Authorization", userToken)
      });
  }

  public getRetailUser(token, retailOid): Observable<any> {
    var userToken = "Bearer " + token;
    return this.httpClient.get(this.sharedService.getUrl() + "parties/retails/users/" + retailOid,
      {
        headers: new HttpHeaders().set("Accept", 'application/json').set('Content-Type', 'application/json')
          .set("Authorization", userToken)
      });
  }

  public assignNow(token, mobile, retailId): Observable<any> {
    var userToken = "Bearer " + token;
    return this.httpClient.post(this.sharedService.getUrl() + "parties/persons/add/" + mobile + "/" + retailId, "",
      {
        headers: new HttpHeaders().set("Accept", 'application/json').set('Content-Type', 'application/json')
          .set("Authorization", userToken)
      });
  }

  public updateTypeRate(token, typeRate): Observable<any> {
    var userToken = "Bearer " + token;
    return this.httpClient.post(this.sharedService.getUrl() + "parties/retails/update/typeRate", typeRate,
      {
        headers: new HttpHeaders().set("Accept", 'application/json').set('Content-Type', 'application/json')
          .set("Authorization", userToken)
      });
  }

  public retrieveCompany(token, userOid): Observable<any> {
    var userToken = "Bearer " + token;
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Authorization", userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.get(this.sharedService.getUrl() + "parties/companies/filter?ownerOid=" + userOid, options);
  }

  public retrieveVehicles(token, companyOid): Observable<any> {
    var userToken = "Bearer " + token;
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Authorization", userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.get(this.sharedService.getUrl() + "parties/companies/" + companyOid + "/vehicles", options);
  }
  public getFreightTransactionSummary(token, filter): Observable<any> {
    var userToken = "Bearer " + token;
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Authorization", userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + "owner/driver/filter/transaction/summary", filter, options);
  }
  public getFreightTransactionDetailByOwner(token, filter): Observable<any> {
    var userToken = "Bearer " + token;
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Authorization", userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + "owner/driver/company/transaction", filter, options);
  }
  public getPenaltiesList(token, filter): Observable<any> {
    var userToken = "Bearer " + token;
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Authorization", userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + "owner/driver/penalty/driver", filter, options);
  }
  public getRatesVehicle(token, vehicleOid): Observable<any> {
    var userToken = "Bearer " + token;
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Authorization", userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.get(this.sharedService.getUrl() + "owner/driver/vehicle/" + vehicleOid + "/rate", options);
  }
  public retrieveDrivers(token, driverOid): Observable<any> {
    var userToken = "Bearer " + token;
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Authorization", userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.get(this.sharedService.getUrl() + "parties/drivers/" + driverOid, options);
  }
  public getContractOwnerDrivers(token, contractOwnerKey): Observable<any> {
    var userToken = "Bearer " + token;
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Authorization", userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.get(this.sharedService.getUrl() + "parties/contract/drivers/" + contractOwnerKey, options);
  }

  public retrieveDeliveris(token, companyOid): Observable<any> {
    var userToken = "Bearer " + token;
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Authorization", userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.get(this.sharedService.getUrl() + "drops/filters?companyId=" + companyOid, options);
  }

  public retrievePayment(token, companyOid): Observable<any> {
    var userToken = "Bearer " + token;
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Authorization", userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.get(this.sharedService.getUrl() + "parties/drivers/" + companyOid, options);
  }

  public addVehicle(token, vehicleData): Observable<any> {
    var userToken = "Bearer " + token;
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Authorization", userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + "parties/companies/vehicles", vehicleData, options);
  }
  public updateDetails(token, updateDetails): Observable<any> {
    var userToken = "Bearer " + token;
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Authorization", userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + "parties/update/user/details", updateDetails, options);
  }

  public changeRetailOwner(retailID, mobile, token): Observable<any> {
    var userToken = "Bearer " + token;
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Authorization", userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + "parties/retails/owner/" + retailID + "/" + mobile, '', options);
  }

  public checkDriver(token, driverRsId): Observable<any> {
    var userToken = "Bearer " + token;
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Authorization", userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.get(this.sharedService.getUrl() + "parties/drivers/mobile/filter/" + driverRsId, options);
  }

  public addTypeRate(token, typeRate): Observable<any> {
    var userToken = "Bearer " + token;
    return this.httpClient.post(this.sharedService.getUrl() + "parties/retails/typeRate", typeRate,
      {
        headers: new HttpHeaders().set("Accept", 'application/json').set('Content-Type', 'application/json')
          .set("Authorization", userToken)
      });
  }

  public getBooking(bookingOid) {
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return this.http.get(this.sharedService.getUrl() + "bookings/selects/" + bookingOid, options);
  }

  public rateBookings(rating) {

    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + "parties/driver/ratings", rating, options);
  }

  public sendBulkSms(token, content): Observable<any> {
    var userToken = "Bearer " + token;
    return this.httpClient.post(this.sharedService.getUrl() + "/parties/bulk/sms", content,
      {
        headers: new HttpHeaders().set("Accept", 'application/json').set('Content-Type', 'application/json')
          .set("Authorization", userToken)
      });
  }

  public assignDriver(token, vehicleObjectOid, foundDriverOid): Observable<any> {
    var userToken = "Bearer " + token;

    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Authorization", userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + "vehicles/" + vehicleObjectOid
      + "/drivers/" + foundDriverOid, '', options);
  }

  public changeDriver(token, vehicleObjectOid, foundDriverOid): Observable<any> {
    var userToken = "Bearer " + token;

    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Authorization", userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + "vehicles/changes/" + vehicleObjectOid
      + "/drivers/" + foundDriverOid, '', options);
  }

  public canopy(token, canopy): Observable<any> {
    var userToken = "Bearer " + token;

    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Authorization", userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + "vehicles/canopy", canopy, options);
  }

  public getBookingTrackNO(trackNo): Observable<any> {
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return this.http.get(this.sharedService.getUrl() + "drops/booking/" + trackNo, options);
  }

  public onGetDriverNo(driverOid) {
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return this.http.get(this.sharedService.getUrl() + 'parties/drivers/mobile/' + driverOid, options)
  }

  onGetBucketBookingTrackNO(trackNo) {
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return this.http.get(this.sharedService.getUrl() + "bookings/buckets/bookings/track/" + trackNo, options);
  }

  public getBucketBookings(token, retailOid): Observable<any> {
    var userToken = "Bearer " + token;
    return this.httpClient.get(this.sharedService.getUrl() + "bookings/buckets/retail/" + retailOid,
      {
        headers: new HttpHeaders().set("Accept", 'application/json').set('Content-Type', 'application/json')
          .set("Authorization", userToken)
      });
  }

  public getAPIkEY(token, serviceId): Observable<any> {
    var userToken = "Bearer " + token;
    return this.httpClient.get(this.sharedService.getUrl() + "v1/credentials/" + serviceId,
      {
        headers: new HttpHeaders().set("Accept", 'application/json').set('Content-Type', 'application/json')
          .set("Authorization", userToken)
      });
  }

  public onGetTravelTime(driverLat, driverLng, userLat, userLng) {
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });

    const url = 'https://maps.googleapis.com/maps/api/distancematrix/json?origins=';
    const keyandMode = '&mode=driving&language=en-EN&key=AIzaSyB3UqCtg7neJzmVlc2FE4aEjFF-e0PvtuE';
    this.http.get(url + driverLat + ',' + driverLng + '&destinations=' + userLat + ',' + userLng + keyandMode, options).toPromise()
      .then((response) => {
        const driverInfo = response.json() ? response.json() : [];
        if (driverInfo.status == "OK") {
          return driverInfo.rows["0"].elements.duration.text;
        }
        else {
          const message = 'is on the way please wait';
          return message;
        }
      }).catch(err => {
        const driverInfoError = err.json() ? err.json() : [];
        const error = driverInfoError.message;
      });
    const message = 'on the way please wait';
    return message;
  }

  public getTrackBookingDriver(oid): Observable<any> {
    return this.httpClient.get(this.sharedService.getUrl() + 'parties/drivers/' + oid,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
      });
  }

  public expressRetail(token, userOid): Observable<any> {
    var userToken = "Bearer " + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.get(this.sharedService.getUrl() + 'bookings/express/' + userOid, options);
  }

  public trackBooking(token, trackNo): Observable<any> {
    var userToken = "Bearer " + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.get(this.sharedService.getUrl() + 'bookings/track/' + trackNo, options);
  }

  public rechargeWalletService(token, walletBody): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/create/drop/invoice', walletBody,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
      });
  }

  public loadWalletService(token, walletBody): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'payments/load/wallet', walletBody,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
      });
  }

  public executeWalletService(token, walletBody): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'payments/requests/recharge/wallet', walletBody,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken)
      });
  }

  public retrieveCurrentBalance(token, userAccountOid): Observable<any> {
    var userToken = "Bearer " + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.get(this.sharedService.getUrl() + 'payments/wallets/balance/' + userAccountOid, options);
  }

  public referenceAmount(token, reference): Observable<any> {
    var userToken = "Bearer " + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + 'payments/wallet/results/sync/' + reference, '', options);
  }

  public referenceBucketAmount(token, reference): Observable<any> {
    var userToken = "Bearer " + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + 'payments/buckets/results/sync/' + reference, '', options);
  }

  public checkBalance(token, userAccountOid): Observable<any> {
    var userToken = "Bearer " + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.get(this.sharedService.getUrl() + 'payments/wallets/balance/' + userAccountOid, options);
  }

  public checkTransactions(token, walletID): Observable<any> {
    var userToken = "Bearer " + token;
    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.get(this.sharedService.getUrl() + 'payments/wallets/transactions/' + walletID, options);
  }

  public retrieveWayBillStatus(waybillNum): Observable<any> {
    return this.httpClient.get(this.sharedService.getUrl() + 'bookings/track/skynet/' + waybillNum,
      {
        headers: new HttpHeaders()
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json')
      });
  }
  public getDocument(wayBillNum): Observable<any> {
    return this.httpClient.get(this.sharedService.getUrl() + 'parties/pod/skynet/' + wayBillNum,
      {
        headers: new HttpHeaders()
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json')
      });
  }

  public downloadSpreadsheet(token, object: any, oid: string): Observable<any> {
    var userToken = "Bearer " + token;
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Authorization", userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + "dumps/retail/" + oid, object, options)
  }

  public confirmUserNumber(token, userOid, phoneNumber): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'parties/' + userOid + '/change/' + phoneNumber, '', { headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken) });
  }

  public changeUserEmail(token, userOid, userEmail): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'parties/' + userOid + '/change/email', userEmail, { headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Authorization', userToken) });
  }

  public getUserDetails(token: string, userMobile: string): Observable<any> {
    var userToken = "Bearer " + token;
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Authorization", userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.get(this.sharedService.getUrl() + "parties/person/filters/" + userMobile, options);
  }

  public getPartnerBundleBookingPayment(token: string, companyOid): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'bookings/companies/payments/' + companyOid,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public downloadRetailWaybills(listOfBookingOids): Observable<any> {
    // var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'plugins/waybill/download/multiple/pdf', listOfBookingOids,
      { headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json') });
  }

  public addNewUserToRetail(token, newUserInfo): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'parties/add/business/user/' + newUserInfo.owner.retailId, newUserInfo,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public removeUserToRetail(token, userToRemover): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'parties/remove/business/user/' + userToRemover, '',
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public addOrdersTag(token, tagList, retailOid): Observable<any> {
    var userToken = `Bearer ${token}`;
    return this.httpClient.post(`${this.sharedService.getUrl()}parties/add/tags/${retailOid}`, tagList,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
          .set('Authorization', userToken)
      });
  }

  public deleteOrdersTag(token, tagList, retailOid): Observable<any> {
    var userToken = `Bearer ${token}`;
    const header: HttpHeaders = new HttpHeaders().
      set('Accept', 'application/json').
      set('Content-Type', 'application/json').
      set('Authorization', userToken);
    const httpOptions = {
      headers: header,
      body: tagList
    };
    return this.httpClient.delete(`${this.sharedService.getUrl()}parties/remove/tags/${retailOid}`,httpOptions);
  }

}
