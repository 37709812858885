import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { ActivatedRoute } from '@angular/router';
import * as firebase from 'firebase/app';
declare var google;
@Component({
  selector: 'app-track-driver-retail',
  templateUrl: './track-driver-retail.component.html',
  styleUrls: ['./track-driver-retail.component.css']
})
export class TrackDriverRetailComponent implements OnInit {

  lat: number; // = parseFloat('-25.98953');
  lng: number; // = parseFloat('28.12843');
  geocoder: any;
  status = 'Please wait patiently, while your request is being processed.';
  sub: any;
  bookingOid: string;
  userLat: any;
  userLong: any;
  travelTime: any;
  onDistanceuserLat: any;
  onDistanceuserLong: any;
  showMap: boolean = false;
  showMapBucket = false;
  bookingDropOffLoc: any;
  bookingPickUpLoc: any;
  dir_result: any;
  MAKER: any;
  link: string = 'assets/img/delivery-truck.png';
  origin = {};
  destination = {};
  trackingValue: boolean = true;
  bucketListDropOff: any = [];
  bucketDropOffLatLng: any = [];

  constructor(private onGetBookingStatus: UserService, private route: ActivatedRoute) {
    this.sub = this.route.params.subscribe(params => {
      this.bookingOid = params['trackNo'];
    });
  }

  ngOnInit() {
    this.getBookingStatusBucket(this.bookingOid);
  }

  public getBookingStatusBucket(trackNo) {
    let bookingData;
    let bookingStatus;
    let bookingRefNo;
    let driverContactOid;
    let bookingCoord = [];
    let bookings: any;
    this.onGetBookingStatus.onGetBucketBookingTrackNO(trackNo).subscribe(
      (response: any) => {
        bookingData = response.json();
        bookings = bookingData.bookings;
        bookingStatus = bookingData.status;
        bookingRefNo = bookingData.timestamp;
        driverContactOid = bookingData.driverOid;
        this.origin = { lat: parseFloat(bookings[0].pickUpCoordinate.coordinates[0]), lng: parseFloat(bookings[0].pickUpCoordinate.coordinates[1]) }
        bookings.forEach(element => {
          bookingCoord.push(element.dropOffCoordinate.coordinates);
        });
        this.bucketListDropOff = bookingCoord;
      },
      (err) => {
        // this.errorMsg = "Invalid username or password.";
      }, () => {
        if (bookingStatus == 'BUCKET_AWAITING') {
          this.status = "Please wait patently, a Droppa driver is being assigned."
        }
        // Use COMPLETE to see page
        else if (bookingStatus == 'IN_TRANSACT') {
          this.status = "Please wait patiently, a Droppa driver is in transit."
          let trackingData;
          this.onGetBookingStatus.onGetDriverNo(driverContactOid).subscribe(
            (res) => {
              trackingData = res.json();

              for (let i = 0, len = bookingCoord.length; i < len; i++) {
                this.bucketDropOffLatLng.push({ lat: parseFloat(bookingCoord[i][0]), lng: parseFloat(bookingCoord[i][1]) });
              }
              this.trackDriverLocationBucket(trackingData.mobile);

          }, (err) => {
              console.log(err);
              this.status = this.status + ' Live tracking not available at the moment.';
          }, () => {
          });
        }
        else if (bookingStatus == 'COMPLETE') {

          this.status = "This booking was successully completed"
        }
        else if (bookingStatus == 'RESERVED') {

          this.status = "A Droppa driver has accpeted your booking."
        }
        else if (bookingStatus == null) {

          this.status = "This booking was successully completed"
        }
      }
    );

  }

  trackDriverLocation(cellNumber: string) {
    const trackDriver = firebase.database().ref('driverTracking/' + cellNumber);
    trackDriver.set({ track: true });
    trackDriver.on('child_added', (childSnapshot) => {
      const driverLocationRef = firebase.database().ref('unavalibleDrivers/' + cellNumber);
      driverLocationRef.on('value', (DataSnapshot) => {
        if (this.trackingValue) {
          this.trackingValue = false;
          this.userLat = DataSnapshot.val().l[0];
          this.userLong = DataSnapshot.val().l[1];
          this.lat = DataSnapshot.val().l[0];
          this.lng = DataSnapshot.val().l[1];
          this.origin = { lat: this.lat, lng: this.lng }
          let driverLoc = new google.maps.LatLng(this.lat, this.lng);
          this.bookingPickUpLoc = driverLoc;
          this.onDirection(driverLoc, this.bookingDropOffLoc);
          this.showMap = true;
          this.travelTime = this.onGetBookingStatus.onGetTravelTime(this.userLat, this.userLong, this.onDistanceuserLat, this.onDistanceuserLong);
        }
        else {
          console.log('already tracking');
        }
      });
    });

  }

  trackDriverLocationBucket(cellNumber: string) {
    const trackDriver = firebase.database().ref('driverTracking/' + cellNumber);

    trackDriver.set({ track: true });
    trackDriver.on('child_added', (childSnapshot) => {
      const driverLocationRef = firebase.database().ref('unavalibleDrivers/' + cellNumber);
      driverLocationRef.on('value', (DataSnapshot) => {
        if (this.trackingValue) {
          this.trackingValue = false;
          this.userLat = DataSnapshot.val().l[0];
          this.userLong = DataSnapshot.val().l[1];
          this.lat = DataSnapshot.val().l[0];
          this.lng = DataSnapshot.val().l[1];
          let driverLoc = new google.maps.LatLng(this.lat, this.lng);
          this.bookingPickUpLoc = driverLoc;
          this.onDirection(driverLoc, this.bookingDropOffLoc);
          this.showMapBucket = true;
          this.travelTime = this.onGetBookingStatus.onGetTravelTime(this.userLat, this.userLong, this.onDistanceuserLat, this.onDistanceuserLong);
        } else {
          console.log('already tracking');
        }
      });
    });
  }

  onDirection(driverLoc, userLoc) {
    var ds = new google.maps.DirectionsService;
    ds.route({
      'origin': driverLoc,
      'destination': userLoc,
      'travelMode': "DRIVING"
    }, (directions, status) => {
      this.dir_result = directions.routes[0].overview_path;
      let routes = [];
      let e = 0;
      this.dir_result.forEach((value, key) => {
        routes.push({
          lat: value.lat(),
          lng: value.lng()
        });
      });

      this.MAKER = routes;
    })
  }

}
