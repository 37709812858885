import { Component, OnInit,isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { UserLoggInService } from '../../services/loggedInUser.service';
import 'rxjs/Rx';
import { LoginService } from '../../services/login.service';
declare var $: any;

@Component({
  selector: 'app-partner-login',
  templateUrl: './partner-login.component.html',
  styleUrls: ['./partner-login.component.css','../sign-in/sign-in.component.css','../customer-login/customer-login.component.css']
})
export class PartnerLoginComponent implements OnInit {
  password: string;
  username: string;
  errorMsg: string = '';
  valid: boolean = false;
  validAdmin: boolean = false;
  superAdmin: boolean = false;
  msgStatus: string;

  yes_value: any;
  yes_value1: any;
  yes_value2: any;
  yes_value3: any;
  yes_value4: any;
  yes_value5: any;
  no_value: any;
  loader: boolean;

  isRobot = true;
  capture: any;
  UAT_SITE_KEY: string;
  isDev=isDevMode()

  constructor(private loginService: LoginService, private route: Router, private userService: UserLoggInService) { }

  ngOnInit() {
    this.UAT_SITE_KEY = this.loginService.SITE_KEY;
  }

  public login() {
    // debugger;
    if (this.username === '' || this.username === null || this.username === undefined) {
      this.errorMsg = 'Please enter in your login credentials';
    } else if (this.password === '' || this.password === null || this.password === undefined) {
      this.errorMsg = 'Please enter in your password';
    } else if (this.isRobot&&!this.isDev) {
      this.errorMsg = 'Please click the checkbox to verify you are a human.';
    } else {
      this.loginService.login(this.password, this.username.toLowerCase().trim()).subscribe(
        (response: any) => {
          // debugger;
          response.roles.forEach(role => {
            if (role.code === 'partner') {
              localStorage.setItem('userInfo', JSON.stringify(response));
              this.valid = true;
            } else if (role.code === 'admin') {
              localStorage.setItem('userInfo', JSON.stringify(response));
              this.validAdmin = true;
            } else if (role.code === 'super_admin') {
              localStorage.setItem('userInfo', JSON.stringify(response));
              this.superAdmin = true;
            }
          });

          if (this.valid) {
            this.userService.setUser(response);
            localStorage.removeItem('individual');
            this.route.navigateByUrl('/driver-profile');
            this.errorMsg = '';
          } else if (this.validAdmin) {
            this.userService.setUser(response);
            localStorage.removeItem('individual');
            this.route.navigateByUrl('/admin-home');
            this.errorMsg = '';
          } else if (this.superAdmin) {
            this.userService.setUser(response);
            localStorage.removeItem('individual');
            this.route.navigateByUrl('/admin-home');
            this.errorMsg = '';
          } else {
            // window.alert("You do not have access to this site.");
            $('#accessNotAllowed').modal('show');
          }
        },
        (err) => {
          // console.log("login error: ",err)
          this.errorMsg = err.error.message; //"Invalid username or password.";
        }
      );
    }
  }

  toPartner() {
    $('#partnerRegistration').modal('show');
  }

  goToPartnerRagistrationn() {
    var ele = document.getElementsByTagName('input');
    var result = "success"
    var count = 0

    for (var i = 0; i < ele.length; i++) {
      if (ele[i].type = "radio") {
        if (ele[i].checked) {
          count = count + 1;
          if ( ele[i].value == 'no1') {
            result = "fail"
          }
        }
      }
    }

    if (count >= 6) {
      if (result == 'success') {
        this.route.navigate(['/Become-Droppa']);
      }
      else if (result == 'fail') {
        $('#partnerResModal').modal('show');
        this.msgStatus = "All answers must be YES to proceed with the registration";

      }
    }
    else {
      $('#partnerResModal2').modal('show');
      this.msgStatus = "Please answer all questions to proceed with the registration";
    }
  }

  onGoHome(){
    this.route.navigateByUrl('/');
  }
  handleCorrectCaptcha(event) {


    if(event!=null){
      this.isRobot=false
    }else{
      this.isRobot=true

    }

  }

}
