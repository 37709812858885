import { Component, OnInit, ViewChild,isDevMode  } from '@angular/core';
import { NgForm } from '@angular/forms'
import { Response } from '@angular/http';
import { Router } from '@angular/router';

import 'rxjs/Rx';
import { LoginService } from '../../services/login.service';
import { User } from '../../models/user';
import { Login } from '../../models/login';
import { from } from 'rxjs/observable/from';
import { LoaderService } from 'src/app/services/loader.service';
declare var $: any;


@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {

  @ViewChild('userForms', { static: false }) signupForm: NgForm;

  isDevMode=isDevMode()
  public passwordEye: string = 'glyphicon glyphicon-eye-open';
  public toggleEye: boolean = false;
  public passwordType: string = 'password';

  firstName: string;
  surname: string;
  email: string;
  phone: string;
  password: string;

  otpValidator: boolean = true;
  numberOTP: string;
  loader: boolean = false;
  error: string;
  user: User;
  loginObj: Login;
  color: string;
  otp: number;
  userInfo: any;

  isRobot=true
  capture: any;
  UAT_SITE_KEY:String






  documentMsg: string = '';
  constructor(private loginService: LoginService, private router: Router,private loadservice:LoaderService) { }

  ngOnInit() {
    this.UAT_SITE_KEY=this.loginService.SITE_KEY
  }


  public register() {


    this.otpValidator = false;

    this.error = '';
    this.loadservice.addloader();

    this.user = new User(this.firstName, this.surname,
    this.email.toLowerCase().trim(), this.phone);

    this.loginObj = new Login(this.password, this.email.toLowerCase().trim());
    this.user.login = this.loginObj;
    this.numberOTP = this.phone;

    // console.log(this.user);

    if(this.isRobot&&!this.isDevMode){
      this.error = 'Please click the checkbox to varify you are a human.';
    }
    else if (/^[a-zA-Z]+$/.test(this.firstName) && /^[a-zA-Z]+$/.test(this.surname)) {
      this.loginService.developerRegistration(this.user)
        .subscribe(
          (response: any) => {

            this.userInfo = response;
            // console.log(this.userInfo);
            this.color = "rgb(28, 193, 239)";
            this.loadservice.removeloader();
            // localStorage.removeItem('userData');
            // localStorage.removeItem('signedUser');

            localStorage.setItem('userData', JSON.stringify(response));
            localStorage.setItem("signedUser", JSON.stringify({
              email: this.email.toLowerCase().trim(),
              password: this.password
            }));

             this.router.navigateByUrl('/developers-confirm-otp');
          },
          (err) => {
            this.loadservice.removeloader();
            this.color = "red";
            this.error = "Error while registering, mobile or email already exist.";

            $('#results').modal('show');
            // console.log(err)
          }
        );

    } else {
      this.loadservice.removeloader();
      this.color = "white";
      this.error = "Name or surname should not contain special characters and numbers.";
      $('#results').modal('show');
      // console.log("err")
    }

  }


  showPassword() {
    this.toggleEye = !this.toggleEye;
    if (this.toggleEye) {
      this.passwordType = 'text';
      this.passwordEye = 'glyphicon glyphicon-eye-close';
    } else {
      this.passwordType = 'password';
      this.passwordEye = 'glyphicon glyphicon-eye-open';
    }

  }

  handleCorrectCaptcha(event) {

    this.error = ''
    if(event!=null){
      this.isRobot=false
    }else{
      this.isRobot=true

    }

  }

  public omit_special_char(e) {
    var k;
    document.all ? k = e.keyCode : k = e.which;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

}
