import {Component, OnInit, isDevMode, ViewChild, ElementRef} from '@angular/core';
import {ContractOwnerService} from "../../services/contract-owner.service";
import {UserInterface} from "../../interfaces/user.interface";
import {LoaderService} from "../../services/loader.service";
import {Router} from "@angular/router";
import {SharedService} from "../../services/shared.service";
import {FormControl} from "@angular/forms";


declare var $: any

@Component({
  selector: 'app-droppa-contract-owners-api',
  templateUrl: './droppa-contract-owners.component-api.html',
  styleUrls: ['./droppa-contract-owners.component-api.css', '../admin-portal/admin-portal.component.css']
})

export class DroppaContractOwnersComponentApi implements OnInit {


  @ViewChild('addVehicleModal', {static: false, read: ElementRef}) modalCreateVehicle: ElementRef;

  @ViewChild('deleteVehicleModal', {static: false, read: ElementRef}) modalDeleteVehicle: ElementRef;

  @ViewChild('fetchWaybillsModal', {static: false, read: ElementRef}) modalFetchWaybills: ElementRef;

  ngAfterViewInit() {
    // Initialize the modal to prevent it from auto-showing on page load
    $(this.modalCreateVehicle.nativeElement).modal({show: false});
  }

  openCreateVehicleModal() {
    $(this.modalCreateVehicle.nativeElement).modal('show');
  }

  closeCreateVehicleModal() {
    $(this.modalCreateVehicle.nativeElement).modal('hide');
  }

  openDeleteVehicleModal() {
    $(this.modalDeleteVehicle.nativeElement).modal('show');
  }

  closeDeleteVehicleModal() {
    $(this.modalDeleteVehicle.nativeElement).modal('hide');
  }

  openFetchWaybillsModal() {
    $(this.modalFetchWaybills.nativeElement).modal('show');
  }

  closeFetchWaybillsModal() {
    $(this.modalFetchWaybills.nativeElement).modal('hide');
  }

  vehicleRegistration: any;
  deliveries: any;
  collections: any;
  deliveryManifestNumber: any;
  collectionsManifestNumber: any;

  totalNumberOfVehicles: Number = 10;
  totalNumberOfBookings = 0;
  pageNumber = 0;
  itemsPerPage = 10;
  pageSizeOptions: number[] = [10, 25];

  vehicleLogs: any;

  searchVehicles: string = '';

  ownerRemittance: any;

  selectedBranch: string = '';

  branches = [
    "JNX",
    "PRY",
    "PTG",
    "KLD",
    "DBN",
    "PE",
    "EL",
    "BARRON",
    "CPT",
    "PZB",
    "NLP"
  ];


  totalNumberOfNonFetched = 0;
  pageNumberOfNonFetched = 0;
  itemsPerPageOfNonFetched = 10;
  pageSizeOptionsOfNonFetched: number[] = [10, 25];
  fetchesLeft=7;


  constructor(private ownerService: ContractOwnerService, private loader: LoaderService, private route: Router, private sharedService: SharedService) {
  }

  errorMsg = ""
  user: UserInterface
  remittenceTable = false;

  contractOwnerTab = true;

  earningsTab: boolean = false;

  newVehiclesTab: boolean = false;

  isDevMode = isDevMode()
  isDriver = false
  addingDriverHasError = false;
  addingDriverErrorMessage = "";
  isFiance: boolean;
  branchCode: any;

  parcelLogsFromDate: any;
  parcelLogsToDate: any;

  remittanceLogsFromDate: any;
  remittanceLogsToDate: any;


  nonFetchedParcelLogs: any;

  newVehicleReqistration: string = '';
  newVehicleBranch: string = '';

  newVehicleError: string = 'Something went wrong, please ensure you have entered all fields and vehicle registration does not exist already.';
  branchSelectError: string = 'Please select a branch.';
  dateRangeError: string = 'Please select a valid date range.';
  newVehicleErrorShow: boolean = false;
  newVehicleToDelete: any;
  newVehicleToFetch: any;


  fetchWaybillsFromDate: any;
  fetchWaybillsToDate: any;

  isAnimating: boolean = false;

  exportWaybillsError:boolean=false;
  exportWaybillsErrorText:string ='';

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem("userInfo"));
    this.isFiance = this.checkIfUserIsFinance(this.user);
    this.getContractOwnersAPI(false);

    this.getAllVehiclesNonFetched();
  }

  toppings = new FormControl();

  toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];

  public getContractOwnersAPI(date: boolean): void {


    var fromDate = new Date(this.parcelLogsFromDate);
    let fromdate = fromDate.getDate() <= 9 ? '0' + fromDate.getDate() : fromDate.getDate();
    let frommonthNo = (fromDate.getMonth() + 1) <= 9 ? '0' + (fromDate.getMonth() + 1) : (fromDate.getMonth() + 1);

    var toDate = new Date(this.parcelLogsToDate);
    let todate = toDate.getDate() <= 9 ? '0' + toDate.getDate() : toDate.getDate();
    let tomonthNo = (toDate.getMonth() + 1) <= 9 ? '0' + (toDate.getMonth() + 1) : (toDate.getMonth() + 1);

    var fDate = fromDate.getFullYear() + '-' + frommonthNo + '-' + fromdate + 'T' + '00' + ':' + '00' + ':' + '00';
    var tDate = toDate.getFullYear() + '-' + tomonthNo + '-' + todate + 'T' + '00' + ':' + '00' + ':' + '00';


    this.loader.addloader();
    const filter = {
      vehicleRegistration: this.searchVehicles,
      from: date ? fDate : "2020-10-01T00:00:00",
      to: date ? tDate : "2050-10-01T00:00:00",
      branch: this.selectedBranch,
      pageNumber: this.pageNumber,
      pageTotalItems: this.itemsPerPage
    }

    console.log(filter)

    this.ownerService.getContractOwnerApiLogs(this.user.token, filter).subscribe(
      (res) => {
        console.log(res)
        this.vehicleLogs = res.content;
        this.totalNumberOfVehicles = res.totalElement;
        this.loader.removeloader();
      },
      (error) => {
        this.loader.removeloader()
        console.log(error)
      },
      () => {
        this.loader.removeloader()
      }
    )
  }

  public openVehicleDetails(registration: string): void {
    this.route.navigate([`admin-contract-owner-details-api/${registration}`])
  }

  public selectOwner(owner): void {
    this.route.navigate([`detailed-contract-owner/${owner.oid}`])
  }

  public downloadLink(fileId): void {
    const url = this.sharedService.getUrl() + "owner/download/skynet/data/billing" + fileId
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    URL.revokeObjectURL(url);
  }


  public downloadSkynetDocument(type: number) {
    if ((!this.parcelLogsFromDate || !this.parcelLogsToDate) || (this.parcelLogsFromDate>this.parcelLogsToDate)) {
      this.exportWaybillsErrorText = this.dateRangeError;
      this.showExportWaybillsError();
      return
    }

    if(this.selectedBranch==='') {
      this.exportWaybillsErrorText = this.branchSelectError;
      this.showExportWaybillsError();
      console.log('show error')
      return;
    }

    var fromDate = new Date(this.parcelLogsFromDate);
    let fromdate = fromDate.getDate() <= 9 ? '0' + fromDate.getDate() : fromDate.getDate();
    let frommonthNo = (fromDate.getMonth() + 1) <= 9 ? '0' + (fromDate.getMonth() + 1) : (fromDate.getMonth() + 1);

    var toDate = new Date(this.parcelLogsToDate);
    let todate = toDate.getDate() <= 9 ? '0' + toDate.getDate() : toDate.getDate();
    let tomonthNo = (toDate.getMonth() + 1) <= 9 ? '0' + (toDate.getMonth() + 1) : (toDate.getMonth() + 1);

    var fDate = fromDate.getFullYear() + '-' + frommonthNo + '-' + fromdate + 'T' + '00' + ':' + '00' + ':' + '00';
    var tDate = toDate.getFullYear() + '-' + tomonthNo + '-' + todate + 'T' + '00' + ':' + '00' + ':' + '00';


    const filter = {
      startDate: fDate,
      endDate: tDate,
    };
    console.log(filter);

    this.loader.addloader();
    if (type == 1) {

      this.ownerService.downloadSkyNetRouteBillingAPI(this.user.token, filter)
        .subscribe(res => {
          console.log(res);
          this.downloadExcel(res.downloadPdfURL, 'skynetBilling');
          this.loader.removeloader();
        }, (error) => {
          this.loader.removeloader();
          this.errorMsg = error.message;
          console.log(error);
        }, () => {
          this.loader.removeloader();
        });

    } else {
      this.ownerService.downloadSkyNetWaybillReportBlob(this.user.token, this.selectedBranch === "" ? "all" : this.selectedBranch, filter)
        .subscribe(res => {

          console.log(res);
          this.downloadExcelBlob(res);
          this.loader.removeloader();
        }, (error) => {
          this.loader.removeloader();
          this.errorMsg = error.message;
          console.log(error);
        }, () => {
          this.loader.removeloader();
        });

    }

  }

  downloadExcel(base64String: string, fileName: string) {
    // Decode base64 string to binary data
    const binaryString = window.atob(base64String);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);

    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }

    // Create a Blob with the Excel MIME type
    const blob = new Blob([bytes], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});

    // Create a link element
    const link = document.createElement('a');
    const url = window.URL.createObjectURL(blob);
    link.href = url;
    link.download = fileName;

    // Append the link to the body
    document.body.appendChild(link);

    // Trigger the download by simulating a click
    link.click();

    // Clean up and remove the link
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  }

  downloadExcelBlob(blob: Blob) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = "SkynetReport.xlsx";
    document.body.appendChild(a);
    a.click();
    a.remove();
  }

  getNewData(pageEvent?: any): void {

    this.pageNumber = pageEvent.pageIndex;
    this.itemsPerPage = pageEvent.pageSize;

    if (!this.parcelLogsFromDate || !this.parcelLogsToDate) {
      this.getContractOwnersAPI(false);
      console.log('invalid dates');
    } else
      this.getContractOwnersAPI(true);
  }


  private checkIfUserIsFinance(user: any): boolean {
    for (const role of user.roles) {
      if (role.code === 'finance_user') {
        return true;
      }
    }
    return false;
  }


  search() {
    if (!this.parcelLogsFromDate || !this.parcelLogsToDate)
      this.getContractOwnersAPI(false);
    else
      this.getContractOwnersAPI(true);

  }


  viewOwner(registration: string): void {
    console.log(registration);
    this.loader.addloader();
    this.ownerService.getContractOwnerId(this.user.token, registration).subscribe(
      (res) => {
        console.log('response__________________________');
        console.log(res)
        if (res.oid !== null)
          this.route.navigate([`detailed-contract-owner/${res.oid}`])
        else
          console.log("response is null")
      },
      (error) => {
        this.loader.removeloader()
        console.log(error)
      },
      () => {
        this.loader.removeloader()
      }
    )
  }

  sendRemittence(remittence: any): void {

    var fromDate = new Date(this.remittanceLogsFromDate);
    let fromdate = fromDate.getDate() <= 9 ? '0' + fromDate.getDate() : fromDate.getDate();
    let frommonthNo = (fromDate.getMonth() + 1) <= 9 ? '0' + (fromDate.getMonth() + 1) : (fromDate.getMonth() + 1);

    var toDate = new Date(this.remittanceLogsToDate);
    let todate = toDate.getDate() <= 9 ? '0' + toDate.getDate() : toDate.getDate();
    let tomonthNo = (toDate.getMonth() + 1) <= 9 ? '0' + (toDate.getMonth() + 1) : (toDate.getMonth() + 1);

    var fDate = fromDate.getFullYear() + '-' + frommonthNo + '-' + fromdate + 'T' + '00' + ':' + '00' + ':' + '00';
    var tDate = toDate.getFullYear() + '-' + tomonthNo + '-' + todate + 'T' + '00' + ':' + '00' + ':' + '00';

    remittence.from = fDate;
    remittence.to = tDate;

    console.log(remittence);

    this.ownerService.sendVehicleRemittences(this.user.token, remittence).subscribe(
      (res) => {
        console.log('response__________________________');
        console.log(res)
      },
      (error) => {
        this.loader.removeloader()
        console.log(error)
      },
      () => {
        this.loader.removeloader()
      }
    )
  }


  public contractOwnerSwitch(active: string) {
    if (
      (active === 'api' && this.contractOwnerTab) ||
      (active === 'earnings' && this.earningsTab) ||
      (active === 'newVehicles' && this.newVehiclesTab)
    ) {
      return;
    }

    // Reset all tabs to false before activating the selected one
    this.contractOwnerTab = false;
    this.earningsTab = false;
    this.newVehiclesTab = false;

    if (active === 'api') {
      this.contractOwnerTab = true;
    } else if (active === 'earnings') {
      this.earningsTab = true;
    } else if (active === 'newVehicles') {
      this.newVehiclesTab = true;
      this.getAllVehiclesNonFetched();

    }

    console.log(
      `Contract Owner Tab: ${this.contractOwnerTab}, Earnings Tab: ${this.earningsTab}, New Vehicles Tab: ${this.newVehiclesTab}`
    );
  }

  public contractOwnerRemittance(): void {

    if (!this.remittanceLogsFromDate || !this.remittanceLogsToDate)
      return;

    var fromDate = new Date(this.remittanceLogsFromDate);
    let fromdate = fromDate.getDate() <= 9 ? '0' + fromDate.getDate() : fromDate.getDate();
    let frommonthNo = (fromDate.getMonth() + 1) <= 9 ? '0' + (fromDate.getMonth() + 1) : (fromDate.getMonth() + 1);

    var toDate = new Date(this.remittanceLogsToDate);
    let todate = toDate.getDate() <= 9 ? '0' + toDate.getDate() : toDate.getDate();
    let tomonthNo = (toDate.getMonth() + 1) <= 9 ? '0' + (toDate.getMonth() + 1) : (toDate.getMonth() + 1);

    var fDate = fromDate.getFullYear() + '-' + frommonthNo + '-' + fromdate + 'T' + '00' + ':' + '00' + ':' + '00';
    var tDate = toDate.getFullYear() + '-' + tomonthNo + '-' + todate + 'T' + '00' + ':' + '00' + ':' + '00';


    const filter = {
      "pageNumber": 0,
      "pageTotalItems": 50000,
      "registrationNumber": "",
      "startDate": fDate,
      "endDate": tDate
    }

    console.log(filter);

    this.loader.addloader();
    this.ownerService.getOwnerEarnings(this.user.token, filter).subscribe(
      (res) => {
        console.log('response__________________________');
        console.log(res)
        this.ownerRemittance = res;
        this.loader.removeloader()
      },
      (error) => {
        this.loader.removeloader()
        console.log(error)
      },
      () => {
        this.loader.removeloader()
      }
    )
  }


  fetchVehicleParcels() {

  }


  getAllVehiclesNonFetched() {

    const filter = {
      pageNumber: 0,
      pageTotalItems: 5
    }


    this.ownerService.getAllNonFetchedVehicles(this.user.token, filter).subscribe(
      (res: any) => {
        console.log('response__________________________');
        console.log(res)
        this.nonFetchedParcelLogs = res.content;
        this.totalNumberOfNonFetched = res.totalElement

        this.checkMustFetchAgain();
        //this.loader.removeloader()
      },
      (error) => {
        //this.loader.removeloader()
        console.log(error)
      },
      () => {
        //this.loader.removeloader()
      }
    )
  }

  onSubmitAddNewVehicle() {
    const vehicle = {
      registration: this.newVehicleReqistration,
      branch: this.newVehicleBranch,
      vehicleStatus: "",
      fetchStatus: "",
      createDate: ""
    }

    this.ownerService.addNewVehicle(this.user.token, vehicle).subscribe(
      (res: any) => {
        console.log('response__________________________');
        console.log(res)
        this.nonFetchedParcelLogs = res.content;
        this.totalNumberOfNonFetched = res.totalElement
        this.getAllVehiclesNonFetched();
        this.closeCreateVehicleModal();

        this.loader.removeloader()
      },
      (error) => {
        this.loader.removeloader()
        console.log(error)
        this.showNewVehicleError();
      },
      () => {
        this.loader.removeloader()
      }
    )
  }

  showNewVehicleError() {
    this.newVehicleErrorShow = true;
    setTimeout(() => {
      this.newVehicleErrorShow = false;
    }, 5000);
  }

  showExportWaybillsError() {
    this.exportWaybillsError = true;
    setTimeout(() => {
      this.exportWaybillsError = false;
    }, 2500);
  }


  getNewDataNonFetched($event) {

  }

  deleteVehicle(vehicle: any) {
    this.newVehicleToDelete = vehicle;

    this.openDeleteVehicleModal();
  }


  onSubmitDeleteNewVehicle(answer: boolean) {
    if (answer)
      this.deleteNewVehicle()
    else
      this.closeDeleteVehicleModal();
  }

  deleteNewVehicle() {
    this.ownerService.deleteNewVehicle(this.user.token, this.newVehicleToDelete).subscribe(
      (res: any) => {
        console.log('response__________________________');
        console.log(res)

        this.getAllVehiclesNonFetched();
        this.closeDeleteVehicleModal();

        this.loader.removeloader()
      },
      (error) => {
        this.loader.removeloader()
        console.log(error)
      },
      () => {
        this.loader.removeloader()
      }
    )
  }

  openfetchVehicleParcels(nonFetched: any) {
    this.newVehicleToFetch = nonFetched;
    this.openFetchWaybillsModal();
  }


  hasProcessing(): boolean {
    if (this.nonFetchedParcelLogs && this.nonFetchedParcelLogs.length > 0) {
      for (let i = 0; i < this.nonFetchedParcelLogs.length; i++) {
        const nonFetched = this.nonFetchedParcelLogs[i];
        if (nonFetched.fetchStatus === "FETCHING")
          return true;
      }
    }

    return false;
  }

  public checkMustFetchAgain() {
     if(this.hasProcessing()){
       setTimeout(() => {
         this.getAllVehiclesNonFetched();
       }, 2000);
     }
     else
       this.checkMustFetchAgainRetry();
  }

  public checkMustFetchAgainRetry() {
    if(this.fetchesLeft>0){
      this.fetchesLeft=this.fetchesLeft-1;
      setTimeout(() => {
        this.getAllVehiclesNonFetched();
      }, 2000);
    }
  }


  onSubmitFetchVehicleWaybills() {
    const formattedFromDate = this.fetchWaybillsFromDate.toISOString().slice(0, 16).replace("T"," ");
    const formattedToDate = this.fetchWaybillsToDate.toISOString().slice(0, 16).replace("T"," ");

    console.log(this.newVehicleToFetch);
    const fetch = {
      from: formattedFromDate,
      to: formattedToDate,
      branch: this.newVehicleToFetch.branch,
      vehicleRegistration: this.newVehicleToFetch.registration,
    }

    this.ownerService.fetchDataForNewVehicle(this.user.token, fetch).subscribe(
      (res: any) => {
        console.log('response__________________________');
        console.log(res)
        this.fetchesLeft=7;
        this.getAllVehiclesNonFetched();

        this.closeFetchWaybillsModal();

        this.loader.removeloader()
      },
      (error) => {
        this.loader.removeloader()
        console.log(error)
      },
      () => {
        this.loader.removeloader()
      }
    )
  }
}
