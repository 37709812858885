import { Component, OnInit, ViewChild } from '@angular/core';
import { AdminService } from '../../services/admin.service';
import { LoginService } from '../../services/login.service';
import { NgForm } from '@angular/forms';
import { LoaderService } from 'src/app/services/loader.service';
// import { $ } from 'protractor';

declare var $: any;

@Component({
  selector: 'app-send-bulk-sms',
  templateUrl: './send-bulk-sms.component.html',
  styleUrls: ['./send-bulk-sms.component.css', '../admin-portal/admin-portal.component.css']
})
export class SendBulkSmsComponent implements OnInit {

  @ViewChild('contentForm', { static: true }) contentForm: NgForm;

  adminUser: any;
  content: any;
  condition: any;
  text: string = 'Enter Phone number: ';
  message: string;
  Add: any;
  phone: any;
  commentForBulks: any;
  number: any = [];
  reply: any;
  vehiclesTypeRate: any = [];
  vehicle: boolean = false;
  type: string;
  loader: boolean;

  constructor(private adminService: AdminService, private loginService: LoginService,private loadservice:LoaderService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    this.getVehicleTypeRates();
  }

  public sendSms() {

    var contentData = {
      'content': this.content,
      'condition': this.condition,
      "location": "",
      "vehicleType": this.type
    };
    this.loadservice.addloader();
    // console.log({ contentData });
    this.adminService.sendBulkSms(this.adminUser.token, contentData)
      .subscribe(
        (res) => {
          // console.log({ res });
          this.message = 'SMS sent successfully!';
          this.loadservice.removeloader();
          this.contentForm.reset()
        },
        (error) => {
          this.message = 'Error, messege not sent!';
          this.loadservice.removeloader();
          this.contentForm.reset()
          // console.log({ error });
        }
      );
  }

  public sendSmsToALLUsers() {

    var contentData = {
      'content': this.content,
      'condition': this.condition,
      "location": "",
      "vehicleType": ""
    };
    // console.log({ contentData });
    this.loadservice.addloader();
    this.adminService.sendBulkSms(this.adminUser.token, contentData)
      .subscribe(
        (res) => {
          // console.log({ res });
          this.message = 'messege sent!';
          this.loadservice.removeloader();
          this.contentForm.reset()
        },
        (error) => {
          this.message = 'Error, messege not sent!';
          // console.log({ error });
          this.loadservice.removeloader();
          this.contentForm.reset()

        }
      );
  }

  public addNumberForBulkSMS(commentForPhoneBulks) {

    if (commentForPhoneBulks !== "" && this.phone.length === 10) {
      this.number.push(commentForPhoneBulks);
      this.phone = "";
    } else {
      this.message = 'Enter Phone number';
    }
  }

  public deleteNumber(index) {
    this.number.splice(index, 1);
    // console.log(this.number.length);
    // console.log(this.number);
  }

  sendBulkSms() {
    var sendBulkData = {
        "content": this.commentForBulks,
        "to": this.number
      };

    if (this.number.length >= 1 ) {
      // console.log(sendBulkData);
      // console.log(this.adminUser.token);
      this.adminService.sendBulkSMSByNumber(this.adminUser.token, sendBulkData).subscribe(
        (response: Response) => {
          this.reply = 'SMS successfully sent';
          this.commentForBulks = "";
          $('#status').modal('show');

        }, (error) => {
          this.reply = 'SMS not sent. Please try again';
          $('#status').modal('show');

        }, () => {

        }
      );
    } else {
      this.reply = 'Enter at least one number to send bulk SMS';
      $('#status').modal('show');
      // console.log(this.reply);
    }
  }

  public getVehicleTypeRates() {

    this.loginService.vehicleTypeRates().subscribe(
      (res) => {
        this.vehiclesTypeRate = res;
      }
    )
  }
  public showTabByRole(role):boolean{
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let result=false;
    userInfo.roles.map(r=>{
      if(role===r.code){
        result=true;
      }
    })
    return result;
  }

}
