import { Component, OnInit, NgModule } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';
declare var $: any;

@Component({
  selector: 'app-driver-tracking',
  templateUrl: './driver-tracking.component.html',
  styleUrls: ['./driver-tracking.component.css', '../admin-portal/admin-portal.component.css']
})
export class DriverTrackingComponent implements OnInit {

  adminUser: any;
  trackLocation: any;
  message: any;
  loader: boolean;
  findDriver: string = '';
  searchDriverQuery: string = 'PHONE';
  driverData: any
  msg: any;
  drivers: any = [];

  totalNumberOfTrackings = 0;
  pageNumber = 0;
  itemsPerPage = 10;
  pageSizeOptions: number[] = [5, 10];

  constructor(private adminService: AdminService, private loadservice: LoaderService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    this.getDriverLocation();
  }

  // public getDriverLocation() {
  //   this.adminService.trackDriver(this.adminUser.token).subscribe(
  //     (response) => {
  //       this.trackLocation = response;
  //     }, (error) => {
  //     }
  //   );
  // }

  fieldToFilterChanged() {
    this.findDriver = '';
    this.triggerGetTrackings(1);
  }

  public getDriverLocation() {

    const filter = {
      fieldToFilter: this.searchDriverQuery,
      searchValue: this.findDriver,
      pageNumber: this.pageNumber,
      pageTotalItems: this.itemsPerPage
    };


    this.loadservice.addloader();
    this.adminService.trackDriverFiltered(this.adminUser.token, filter).subscribe(
      (response) => {
        this.trackLocation = response.content;
        this.totalNumberOfTrackings = response.totalElement;
        this.loadservice.removeloader();
      }, (error) => {
        this.loadservice.removeloader();
      }
    );
  }

  public deleteDriver(deleteData) {
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.deleteDuplicateDrivers(this.adminUser.token, deleteData.oid).subscribe(
      (response) => {
        this.message = 'Driver deleted.';
        $('#delete').modal('show');
      }, (error) => {
        this.message = 'Driver not deleted.';
      }
    )
  }

  triggerGetTrackings(type: number) {
    if (type === 1)
      this.resetPagination();

    this.getDriverLocation();
  }

  resetPagination() {
    this.pageNumber = 0;
    this.itemsPerPage = 25;
  }

  getNewData(pageEvent?: any) {
    this.pageNumber = pageEvent.pageIndex;
    this.itemsPerPage = pageEvent.pageSize;
    this.triggerGetTrackings(0);
  }

  // public searchDriver() {
  //   this.loadservice.addloader();
  //   this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
  //   if (this.findDriver != null && this.searchDriverQuery !== 'undefined') {
  //     if (this.searchDriverQuery === 'driverPhone') {
  //       this.adminService.getDriverByNumber(this.findDriver, this.adminUser.token)
  //         .subscribe(
  //           (res) => {
  //             this.driverData = res;
  //             this.loadservice.removeloader();
  //           }, (error) => {
  //             console.log(error);
  //             $('#searchDriver').modal('show');
  //             this.msg = 'Mobile number does not exist!';
  //             this.loadservice.removeloader();
  //           });
  //     } else {
  //       $('#searchDriver').modal('show');
  //       this.msg = 'Please enter the phone number on the select input!';
  //       this.loadservice.removeloader();
  //     }
  //   } else {
  //     $('#searchDriver').modal('show');
  //     this.msg = 'Please enter driver mobile number and select number on the select input!';
  //     this.loadservice.removeloader();
  //   }
  // }
  // public showTabByRole(role):boolean{
  //   const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  //   let result=false;
  //   userInfo.roles.map(r=>{
  //     if(role===r.code){
  //       result=true;
  //     }
  //   })
  //   return result;
  // }

}
