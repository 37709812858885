import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  constructor() { }
  public loaderToggle(){
    const loader= window.document.getElementById("loader-background")
    const body = document.body
    if(body.classList.contains("no-scroll")){
     body.classList.remove("no-scroll")
     loader.classList.remove("loader-container")
     loader.classList.add("disappear-loader")
    }else{
     loader.classList.remove("disappear-loader")
     body.classList.add("no-scroll")
     loader.classList.add("loader-container")
    }

   }
   public addloader(){
     const loader= window.document.getElementById("loader-background")
     const body = document.body
     if(!body.classList.contains("no-scroll")){
       loader.classList.remove("disappear-loader")
       body.classList.add("no-scroll")
       loader.classList.add("loader-container")
     }
    }
   public removeloader(){
     const loader= window.document.getElementById("loader-background")
     const body = document.body
     if(body.classList.contains("no-scroll")){
       body.classList.remove("no-scroll")
       loader.classList.remove("loader-container")
       loader.classList.add("disappear-loader")
     }

    }
}
