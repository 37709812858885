import { Component, OnInit ,isDevMode} from '@angular/core';
import { Router } from '@angular/router';
import 'rxjs/Rx';
import { UserLoggInService } from '../../services/loggedInUser.service';
import { LoginService } from '../../services/login.service';
import { UserService } from 'src/app/services/user.service';
declare var $: any;

@Component({
  selector: 'app-retail-signin',
  templateUrl: './retail-signin.component.html',
  styleUrls: ['./retail-signin.component.css','../sign-in/sign-in.component.css','../customer-login/customer-login.component.css']
})
export class RetailSigninComponent implements OnInit {
  password: string;
  username: string;
  errorMsg: string = '';
  valid: boolean = false;
  validAdmin: boolean = false;
  msgStatus: string;
  isDevMode=isDevMode()

  yes_value: any;
  yes_value1: any;
  yes_value2: any;
  yes_value3: any;
  yes_value4: any;
  yes_value5: any;
  no_value: any;
  loader: boolean;

  isRobot=true
  capture: any;
  UAT_SITE_KEY:String

  constructor(private loginService: LoginService, private route: Router, private userLogged: UserLoggInService, private userRetailService: UserService) {
  }

  ngOnInit() {
    this.UAT_SITE_KEY=this.loginService.SITE_KEY
  }

  public loginByEnter(event){
    if (event.key === "Enter") {
      this.login();
    }
  }

  public login() {

    /*const url = 'https://business.droppa.co.za/';
    const link = document.createElement('a');
    link.href = url;
    // document.body.appendChild(link);
    link.click();
    // link.parentNode?.removeChild(link);
    URL.revokeObjectURL(url);
    return;*/

    this.errorMsg = '';
    if (this.username === '' || this.username === null || this.username === undefined) {
      this.errorMsg = 'Please enter in your login credentials.';
    }
    else if (this.password === '' || this.password === null || this.password === undefined) {
      this.errorMsg = 'Please enter in your password.';
    }else if(this.isRobot&&!this.isDevMode){
      this.errorMsg = 'Please click the checkbox to varify you are a human.';
    }
    else {
      this.loginService.login(this.password, this.username.toLowerCase().trim()).subscribe(
        (response: any) => {

          let valid = false;

          response.roles.forEach(role => {
            if (role.code === 'retail' || role.code === 'business_User') {
              valid = true;
            }
          });

          if (!valid) {
            // window.alert('You do not have access to this profile type.');
            $('#accessNotAllowed').modal('show');
          } else {
            localStorage.setItem('userInfo', JSON.stringify(response));
            const loginsData = {username: this.username.toLowerCase().trim(), pwd: response.pwd};
            localStorage.setItem('userInfoBooking', JSON.stringify(response));
            localStorage.setItem('userInfoBookingLogin', JSON.stringify(loginsData));
            this.userLogged.setUser(response);
            if (response.owner.retailId != null) {
              const userToken = response.token;
              this.userRetailService.retriveRetail(userToken, response.owner.retailId).subscribe(
                (res) => {
                  localStorage.removeItem('individual');
                  this.route.navigateByUrl('/retail-profile');
                }, (error) => {
                  this.route.navigateByUrl('/retail-not-active');
                }
              );
            } else {
              // this.route.navigateByUrl('/user');
              window.alert('You do not have access to this site.');
            }
          }
      },
      (err) => {
        this.errorMsg = err.error.message; //'Invalid username or password.';
      });
    }
  }

  toRetail() {
    $('#retailRegistration').modal('show');
  }

  goToRetailRagistration() {
    var ele = document.getElementsByTagName('input');
    var result = "success"
    var count = 0

    for (var i = 0; i < ele.length; i++) {
      if (ele[i].type = "radio") {
        if (ele[i].checked) {
          count = count + 1;
          if (ele[i].value == 'no') {
            result = "fail"
          }
        }
      }
    }

    if (count >= 4) {
      if (result == 'success') {
        this.route.navigate(['/Become-Retail']);
      }
      else if (result == 'fail') {
        $('#retailResModal').modal('show');
        this.msgStatus = "All answers must be YES to proceed with the registration";
      }
    }
    else {
      $('#retailResModal2').modal('show');
      this.msgStatus = "Please answer all questions to proceed with the registration";
    }
  }

  onGoHome() {
    this.route.navigateByUrl('/');
  }
  handleCorrectCaptcha(event) {


    if(event!=null){
      this.isRobot=false
    }else{
      this.isRobot=true

    }

  }

}
