import { Component, ComponentFactoryResolver, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { AdminService } from '../../services/admin.service';
import { BookingService } from 'src/app/services/booking.service';
import { LoaderService } from 'src/app/services/loader.service';

declare var $: any;

@Component({
  selector: 'app-admin-portal',
  templateUrl: './admin-portal.component.html',
  styleUrls: ['./admin-portal.component.css']
})
export class AdminPortalComponent implements OnInit {

  adminUser: any;
  users: any = [];
  partner: any = [];
  companies: any = [];
  vehicles: any = [];
  drivers: any = [];
  driver: any = {};
  payments: any = [];
  drops: any = [];
  items: any = [];
  categories: any = [];
  weightings: any = [];
  bookings: any = [];
  retails: any = [];
  buckets: any = [];
  expressBookings: any = [];

  loader: boolean;
  find: any;

  selectedCode: number;
  itemSelectedCode: number;
  updateCategoryRate: any;
  itemName: string;
  itemDescrip: string;

  findPartner;
  searchQuery;
  searchDriverQuery;
  searchQueryPartner;
  SearchQueryCompany;
  SearchQueryVehicle;
  SearchQueryRetail;

  searchQueryStatus: string;
  searchQueryPlatform: string;
  searchQueryBucket: string;

  status: string = 'status';
  platforms: string = 'platform';
  msg: any;

  updateWeightings: any;
  catName: string;
  catDescrip: string;
  textMessage: string = '';
  @ViewChild('typeRateForm', { static: false }) addCategoryForm: NgForm;
  @ViewChild('newItemForm', { static: true }) newItemForm: NgForm;
  @ViewChild('updateWeightForm', { static: true }) updateWeightForm: NgForm;

  searchUsers: any;
  findUser: any;
  findDriver: any;
  findCompany: string;
  findPlatform: any;
  findVehicle: any;
  findRetail: any;
  userData: any;
  driverData: any;
  companyData: any;
  bookingData: any
  allBookings: any;
  vehicleMsg: any;

  tabId: any;
  selectedTabID: any;

  totalBookings: any;
  totalAllBookingPrice: any;
  totalBookingPrice: any;
  totalWeeklyBooking: any;
  gautentBooingCount: any;
  westerCapetBooingCount: any;
  mobileCount: any;
  webCount: any;
  todayCount: any;
  montlyCount: any;

  allBucketAmt: any;
  totalbucketPrice: any;
  totalbucketCount: any;
  totalweeklybucket: any;
  montlyBucketCount: any;
  dailyBucketCount: any;

  totalUsers: number = 0;
  totalPartners: number = 0;
  totalDrivers: number = 0;
  totalVehicles: number = 0;
  totalActiveRetail: number = 0;
  totalAssignedVehicle: number = 0;
  totalAssignedDriver: number = 0;
  totalpartners: number = 0;
  gautengPartners: number = 0;
  westernCapePartners: number = 0;

  expressBookingsAmount: any;
  expressTotalBookings: any;
  expressMonthlyBookings: any;
  expressMonthlyBookingsAmount: number;
  expressWeeklyBookings: any;
  expressDailyBookings: any;

  totalDailyBookingValue: any;
  totalWeeklyBookingValue: any;
  totalMonthlyBookingValue: any;

  totalDailyBucketValue: any;
  totalWeeklyBucketValue: any;
  totalMonthlyBucketValue: any;

  rentalTotalAmt: any;
  rentalTotalHire: any;
  rentalMonthlyAmount: any;
  rentalMonthlyCount: any;
  rentalWeeklyAmount: any;
  rentalWeeklyCount: any;
  rentalDailyAmount: any;
  rentalDailyCount: any;

  pudoDailyAmount = 0;
  pudoDailyCount = 0;
  pudoMontlyAmount = 0;
  pudoMontlyCount = 0;
  pudoWeeklyAmount = 0;
  pudoWeeklyCount = 0;
  pudoTotalAmount = 0;
  pudoTotalCount = 0;

  typeOfSearch = 'Default';
  somethingToSearchFor: string;
  adminSearchNoResultMessage: string;
  adminFoundResult: any;
  thereIsAResult = false;
  tableToShow = '';
  showResultsTable = false;

  badweather:any=false;
  lightweather:any=false;

  activateMarketing = false;

  constructor(private adminService: AdminService, private router: Router, private bookService: BookingService,private loadservice:LoaderService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    // console.log(this.adminUser.roles);
    this.adminUser.roles.forEach(element => {
      if (element.code === 'super_admin') {
        // this.getTotalBookings(this.adminUser.token);
        // this.rentalStats(this.adminUser.token);
      }
      if (element.code === 'marketing') {
        this.activateMarketing = true;
      }
    });

    // this.POIweatherStatusBadWeather('bad');
    // this.POIweatherStatusLightWeather('light');
    this.setSelectedTabID();
    this.selectedTabID = localStorage.getItem('activeID');
    if (this.selectedTabID != null) {
      this.lastActiveTab();
    }

    this.somethingToSearchFor = null;
    this.adminSearchNoResultMessage = null;

    localStorage.setItem('adminRetailSearchResults', 'null');
    localStorage.setItem('adminUserSearchResults', 'null');
  }

  public rentalStats(token) {
    this.adminService.getAllRentalStats(token).subscribe(
      response => {

        this.rentalTotalAmt = response.totalAmount;
        this.rentalTotalHire = response.count;

        this.rentalMonthlyAmount = response.monthlyAmount;
        this.rentalMonthlyCount = response.monthlyCount;

        this.rentalWeeklyAmount = response.weeklyAmount;
        this.rentalWeeklyCount = response.weeklyCount;

        this.rentalDailyAmount = response.dailyAmount;
        this.rentalDailyCount = response.dailyCount;
      }, error => {
        console.log(error);
      }
    )
  }

  lastActiveTab() {

    if (this.selectedTabID == 'bookings') {
      $('#myTab a[href="#j"]').tab('show');
    }
    else if (this.selectedTabID == 'express') {
      $('#myTab a[href="#k"]').tab('show');
    }
    else if (this.selectedTabID == 'bucket') {
      $('#myTab a[href="#jj"]').tab('show');
    }
    else if (this.selectedTabID == 'retail') {
      $('#myTab a[href="#i"]').tab('show');
    }
    else if (this.selectedTabID == 'users') {
      $('#myTab a[href="#home"]').tab('show');
    }
    else if (this.selectedTabID == 'partners') {
      $('#myTab a[href="#a"]').tab('show');
    }
    else if (this.selectedTabID == 'companies') {
      $('#myTab a[href="#b"]').tab('show');
      this.getCompanies()
    }
    else if (this.selectedTabID == 'vehicles') {
      $('#myTab a[href="#c"]').tab('show');
    }
    else if (this.selectedTabID == 'drivers') {
      $('#myTab a[href="#d"]').tab('show');
    }
    else if (this.selectedTabID == 'wallet') {
      $('#myTab a[href="#m"]').tab('show');
    }
    else {
      $('#myTab a[href="#j"]').tab('show');
    }
  }

  setSelectedTabID() {
    $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
      var tabId = $(e.target).attr("id") // activated tab
      this.tabId = tabId;
      localStorage.setItem('activeTab', this.tabId);
    });
  }

  onUserSelected(user) {
    localStorage.removeItem('selectedPartner');
    localStorage.setItem('selectedUser', JSON.stringify(user));
    this.router.navigate(['/view-user']);
  }

  onSearchedUser() {
    localStorage.setItem('searchedUser', this.userData);
    this.router.navigate(['/view-searcher-user']);
  }

  onPartnerSelected(user) {
    localStorage.removeItem('selectedPartner');
    localStorage.setItem('selectedPartner', JSON.stringify(user));
    this.router.navigate(['/edit-partner']);
  }

  private getTotalBookings(token) {

    this.adminService.getTotalAllBookings(token)
      .subscribe(
        (res) => {
          // console.log( res);
          localStorage.setItem('bookingMetrix', JSON.stringify(res));

          this.totalDailyBookingValue = res.bookingMatrixDTO.dailyBookingAmount;
          this.totalWeeklyBookingValue = res.bookingMatrixDTO.weeklyBookingsAmount;

          this.totalDailyBucketValue = res.bookingMatrixDTO.dailyBucketAmount;
          this.totalWeeklyBucketValue = res.bookingMatrixDTO.weeklyBucketAmount;
          this.totalMonthlyBucketValue = res.bookingMatrixDTO.monthlyBucketAmount;

          this.totalBookings = res.bookingMatrixDTO.count;
          this.totalBookingPrice = res.bookingMatrixDTO.monthlyBookingTotal;
          this.totalAllBookingPrice = res.bookingMatrixDTO.total;
          this.totalWeeklyBooking = res.bookingMatrixDTO.weeklyBookings;
          this.montlyCount = res.bookingMatrixDTO.monthlyBooking
          this.todayCount = res.bookingMatrixDTO.dailyBooking;
          this.gautentBooingCount = res.bookingMatrixDTO.totalGauteng;
          this.westerCapetBooingCount = res.bookingMatrixDTO.totalWesternCape;
          this.mobileCount = res.bookingMatrixDTO.totalMobile;
          this.webCount = res.bookingMatrixDTO.totalWeb;

          this.totalbucketPrice = res.bookingMatrixDTO.monthlyBucketTotal;
          this.totalbucketCount = res.bookingMatrixDTO.bucketCount;
          this.totalweeklybucket = res.bookingMatrixDTO.weeklyBucketBookings;

          this.montlyBucketCount = res.bookingMatrixDTO.monthlyBucketBooking;
          this.dailyBucketCount = res.bookingMatrixDTO.dailyBucketBooking;
          this.allBucketAmt = res.bookingMatrixDTO.totalBucketBookings;

          this.expressBookingsAmount = res.expressBookingStatsDTO.totalAmt;
          this.expressTotalBookings = res.expressBookingStatsDTO.totalCount;
          this.expressMonthlyBookings = res.expressBookingStatsDTO.monthlyCount;
          this.expressMonthlyBookingsAmount = res.expressBookingStatsDTO.monthlyBookingAmount;
          this.expressWeeklyBookings = res.expressBookingStatsDTO.weeklyCount;
          this.expressDailyBookings = res.expressBookingStatsDTO.todayCount;

          this.pudoDailyCount = res.pudoMatrixDTO.numberOfBookingsToday;
          this.pudoDailyAmount = res.pudoMatrixDTO.todayBookingTotal;
          this.pudoWeeklyAmount = res.pudoMatrixDTO.weeklyBookingTotal;
          this.pudoWeeklyCount = res.pudoMatrixDTO.numberOfWeeklyBookings;
          this.pudoMontlyAmount = res.pudoMatrixDTO.monthlyBookingTotal;
          this.pudoMontlyCount = res.pudoMatrixDTO.numberOfMonthlyBookings;
          this.pudoTotalAmount = res.pudoMatrixDTO.totalCash;
          this.pudoTotalCount = res.pudoMatrixDTO.totalNumberOfBookings;
        },
        (err) => {
          console.log(err)
          this.loadservice.removeloader();
          if (err.statusText == 'Unknown Error' || err.statusText == 'Internal Server Error') {
            this.router.navigate(['/logon']);
          }
        }
      );

  }

  public getBookings() {
    localStorage.removeItem('allBookingsPageSearch');
    this.router.navigate(['/all-bookings']);
  }

  public getBucketBooking() {
    this.router.navigate(['/all-bucket-bookings']);
  }

  public getExpressBookings() {
    this.router.navigate(['/all-express-bookings']);
  }

  public getRentals() {
    this.router.navigateByUrl('/Rental-Elite');
  }

  public goToPudoBookings() {
    this.router.navigateByUrl('/admin-pudo-bookings');
  }
  public goToIndividualPudoBookings() {
    this.router.navigateByUrl('/individual-pudo-bookings');
  }

  goToInsuredBookings() {
    this.router.navigate(['/all-insured-bookings']);
  }

  public getRegails() {
    this.router.navigate(['/all-retails']);
  }

  public getPartners() {
    this.router.navigate(['/all-partners']);
  }

  public getUsers() {
    this.router.navigate(['/all-users']);
  }

  public getVehicle() {
    this.router.navigate(['/all-vehicles']);
  }

  public getDrivers() {
    this.router.navigate(['/all-drivers']);
  }

  public getCompanies() {
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.getAllCompanies(this.adminUser.token)
      .subscribe(
        (res) => {
          this.companies = res;
        }, (err) => {
          //console.log(err);
          this.loadservice.removeloader();
          if (err.statusText == 'Unknown Error' || err.statusText == 'Internal Server Error') {
            this.router.navigate(['/logon']);
          }
        }
      );
  }

  public getWalletTransactions() {
    this.router.navigate(['/transactions']);
  }

  public getOperations() {
    this.router.navigate(['/operations']);
  }

  public getMarketing() {
    this.router.navigate(['/marketing']);
  }

  public goToSkyNet() {
    this.router.navigate(['/skynet-portal']);
  }

  public goToUberBookings() {
    this.router.navigate(['/uber-bookings']);
  }

  public searchUser() {
    this.loadservice.addloader();
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    if (this.findUser != null && this.searchQuery != 'undefined') {
      if (this.searchQuery == 'mobile') {
        this.adminService.getUserByNumber(this.findUser, this.adminUser.token)
          .subscribe(
            (res) => {
              this.userData = res;
              this.partner.push(this.userData);
              this.loadservice.removeloader();
            }, (error) => {
              $('#searchUser').modal('show');
              this.msg = 'Mobile number Does not exist!';
              this.loadservice.removeloader();
            });
      } else {
        $('#searchUser').modal('show');
        this.msg = 'Please select number on the select input!';
        this.loadservice.removeloader();
      }
    } else {
      $('#searchUser').modal('show');
      this.msg = 'Please enter user mobile number and select number on the select input!';
      this.loadservice.removeloader();
    }
  }

  public searchBooking() {
    this.loadservice.addloader();
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    if (this.searchUsers != null) {
      this.bookService.getBookingByTrackNum(this.searchUsers.toUpperCase()).subscribe(
        (response) => {
          this.bookingData = response.json();
          this.loadservice.removeloader();
        }, (error) => {
          $('#searchBooking').modal('show');
          this.msg = 'The booking does not exist. Please make sure you entered the right track number.';
          this.loadservice.removeloader();
        }
      );
    } else {
      $('#searchBooking').modal('show');
      this.msg = 'Please enter a track number to search for a booking.';
      this.loadservice.removeloader();
    }
  }

  public searchPartner() {
    this.loadservice.addloader();
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    if (this.findPartner != null && this.searchQueryPartner != 'undefined') {
      if (this.searchQueryPartner == 'mobile') {
        this.adminService.getUserByNumber(this.findPartner, this.adminUser.token)
          .subscribe(
            (res) => {
              this.userData = res;
              this.partner.push(this.userData);
              this.loadservice.removeloader();
            }, (error) => {
              $('#searchPartner').modal('show');
              this.msg = 'Mobile number does not exist.';
              this.loadservice.removeloader();
            });
      } else {
        $('#searchPartner').modal('show');
        this.msg = 'Please select number field on the select input!';
        this.loadservice.removeloader();
      }
    } else {
      $('#searchPartner').modal('show');
      this.msg = 'Please enter the user mobile number & select the number field on the select input.';
      this.loadservice.removeloader();
    }
  }

  public searchDriver() {
    this.loadservice.addloader();
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    if (this.findDriver != null && this.searchDriverQuery !== 'undefined') {
      if (this.searchDriverQuery === 'mobile') {
        this.adminService.getDriverByNumber(this.findDriver, this.adminUser.token)
          .subscribe(
            (res) => {
              this.driverData = res;
              this.loadservice.removeloader();
            }, (error) => {
              $('#searchUser').modal('show');
              this.msg = 'Mobile number Does not exist!';
              this.loadservice.removeloader();
            });
      } else {
        $('#searchUser').modal('show');
        this.msg = 'Please select phone on the select input!';
        this.loadservice.removeloader();
      }
    } else {
      $('#searchUser').modal('show');
      this.msg = 'Please enter user mobile number and select  number on the select input!';
      this.loadservice.removeloader();
    }
  }

  public searchCompany() {
    this.loadservice.addloader();
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    if (this.findCompany != null && this.SearchQueryCompany !== 'undefined') {
      if (this.SearchQueryCompany === 'registrationNumber') {
        this.adminService.getCompanyByNumber(this.findCompany, this.adminUser.token)
          .subscribe(
            (res) => {
              this.companyData = res;
              this.loadservice.removeloader();
            }, (error) => {
              $('#searchUser').modal('show');
              this.msg = 'Registration number does not exist!';
              this.loadservice.removeloader();
            });
      } else if (this.SearchQueryCompany === 'ownerphonenumber') {
        this.adminService.searchCompanyByMobile(this.findCompany, this.adminUser.token).subscribe(
          (response) => {
            this.companyData = response;
            this.loadservice.removeloader();
          }, (error) => {

            $('#searchUser').modal('show');
            this.msg = 'Phone number owner does not exist.';
            this.loadservice.removeloader();
          }
        );
      } else {
        $('#searchUser').modal('show');
        this.msg = 'Please select the Phone Number Owner on the select input!';
        this.loadservice.removeloader();
      }
    } else {
      $('#searchUser').modal('show');
      this.msg = 'Please enter and select company registration number!';
      this.loadservice.removeloader();
    }
  }

  public searchCompanyByNo() {
    this.loadservice.addloader();
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    if (this.findCompany != null && this.SearchQueryCompany !== 'undefined') {
      if (this.SearchQueryCompany === 'registrationNumber') {
        this.adminService.searchCompanyByMobile(this.findCompany, this.adminUser.token)
          .subscribe(
            (res) => {
              this.companyData = res;
              this.loadservice.removeloader();
            }, (error) => {
              $('#searchUser').modal('show');
              this.msg = 'registration nurmber Does not exist!';
              this.loadservice.removeloader();
            });
      } else {
        $('#searchUser').modal('show');
        this.msg = 'Please select registration nurmber  on the select input!';
        this.loadservice.removeloader();
      }
    } else {
      $('#searchUser').modal('show');
      this.msg = 'Please enter and select company registration number!';
      this.loadservice.removeloader();
    }
  }

  public getPayments() {
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.getAllPayment(this.adminUser.token)
      .subscribe(
        (res) => {
          this.payments = res;
        }, (err) => {
          if (err.statusText == 'Unknown Error' || err.statusText == 'Internal Server Error') {

          }
        }
      );
  }
  public navigateToPOI() {

    this.router.navigate(['/points-of-interest']);
  }

  public POIweatherStatusBadWeather(status) {
    this.adminService.POIweatherStatus(this.adminUser.token, status).subscribe(
      (res) => {
      // console.log(res)
      if (res.length > 0) {
        this.badweather = true;
      }
    },
      (err) => {
      console.log(err);
    });
    return length;
  }

  public POIweatherStatusLightWeather(status) {
    this.adminService.POIweatherStatus(this.adminUser.token, status).subscribe(
      (res) => {
      // console.log(res)
      if (res.length > 0) {
        this.lightweather = true;
      }
    },
      (err) => {
      console.log(err);
    });
    return length;
  }

  public getDrops() {
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.getAllDrops(this.adminUser.token)
      .subscribe(
        (res) => {
          this.drops = res.reverse();
        }, (error) => {
          if (error.statusText == 'Unknown Error' || error.statusText == 'Internal Server Error') {
            this.router.navigate(['/logon']);
          }
        }
      );
  }

  public getItems() {
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.getAllItems(this.adminUser.token)
      .subscribe(
        (res) => {
          this.items = res;// .reverse();
        }, (error) => {
          if (error.statusText == 'Unknown Error' || error.statusText == 'Internal Server Error') {
            this.router.navigate(['/logon']);
          }
        }
      );
  }

  public getCategories() {
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.getAllCategories(this.adminUser.token)
      .subscribe(
        (res) => {
          this.categories = res;
        }, (error) => {
          if (error.statusText == 'Unknown Error' || error.statusText == 'Internal Server Error') {
            this.router.navigate(['/logon']);
          }
        }
      );
  }

  public getWeightings() {
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.getAllWeightings(this.adminUser.token)
      .subscribe(
        (res) => {
          this.weightings = res.reverse();
        }, (error) => {
          if (error.statusText == 'Unknown Error' || error.statusText == 'Internal Server Error') {
            this.router.navigate(['/logon']);
          }
        }
      );
  }

  addCategory(catDescrip, catName) {
    var code = Math.floor((Math.random() * (9000)) + 1000);
    var category = {
      categoryName: catName,
      code: code,
      description: catDescrip,
    };
    this.adminService.addCategory(this.adminUser.token, category).subscribe(
      (res) => {
        this.textMessage = 'Category added successfully';
        this.addCategoryForm.reset();
      }, (error) => {
        this.textMessage = 'Error occured, category not added.';
      }
    );
  }

  addItem(itemName, itemDescrip, itemSelectedCode) {
    this.textMessage = '';
    this.adminService.getWeighting(this.adminUser.token, this.selectedCode).subscribe(
      (data) => {

        var code = Math.floor((Math.random() * (9000)) + 1000);
        var itemData = {
          itemName: itemName,
          categoryCode: data.code,
          code: code,
          description: itemDescrip,
          weightingCode: itemSelectedCode
        };

        this.adminService.addItems(this.adminUser.token, itemData).subscribe(
          (res) => {
            this.textMessage = 'Successfully added a new item.';
          },
          (err) => {
            this.textMessage = 'Error occured, something went wrong.';
          }
        );

      },
      (error) => {
        this.textMessage = 'Error occured, Invalid category name.';
      }
    );
  }

  setCode(code) {
    this.selectedCode = code;
  }

  setCategoryCode(categoryCode) {
    this.itemSelectedCode = categoryCode;
  }

  public updateWeighting(weighting) {
    this.updateWeightings = weighting;
  }

  public onBooking(book) {
    localStorage.setItem('selectedBooking', JSON.stringify(book));
    this.router.navigate(['/view-booking']);
  }

  public onBucketBooking(book) {
    localStorage.setItem('bucketBooking', JSON.stringify(book));
    this.router.navigate(['/view-retail-bookings']);
  }

  public onVehicle(vehicle) {
    localStorage.setItem('selectedVehicle', JSON.stringify(vehicle));
    this.router.navigate(['/update-vehicle']);
  }

  public onCompany(company) {
    localStorage.setItem('selectedCompany', JSON.stringify(company));
    this.router.navigate(['/company-bank']);
  }

  public onRetail(retail) {
    localStorage.setItem('selectedRetail', JSON.stringify(retail));
    this.router.navigate(['/retail-review']);
  }

  public onDriver(driver) {
    localStorage.setItem('selectedDriver', JSON.stringify(driver));
    this.router.navigate(['/view-driver']);
  }

  tempBookings() {
    this.bookings = this.allBookings;
  }

  searchBookings(ev: any) {
    this.tempBookings();
    const val = ev;
    if (val && val.trim() != '') {
      this.bookings = this.bookings.filter((item) => {
        return (item.trackNo.includes(val.toUpperCase()));
      });
    }
  }

  public updateWeight(updateCategoryRate) {
    this.textMessage = '';
    this.adminService.updateWeighting(this.adminUser.token, this.updateWeightings.code, updateCategoryRate).subscribe(
      (data) => {
        this.textMessage = 'Weighting successfully updated';
      },
      (error) => {
        this.textMessage = 'Error occured, weight could not be updated.';
      }
    );
  }

  public downloadBookingsForSpreadsheet() {
    this.adminService.downloadBookingSpreadsheet(this.adminUser.token).subscribe(
      (response) => {
        var a = document.createElement("a");
        a.href = URL.createObjectURL(response);
        a.download = 'payment-spreadsheet.xlsx';
        a.click();
      }, (error) => {
      }, () => {
      }
    );
  }

  public suspendInactiveDrivers() {
    this.adminService.suspendDriverAccounts(this.adminUser.token).subscribe(
      (response) => {
        $('#driverSuspended').modal('show');
        this.msg = 'Inactive drivers suspended.';
        this.loadservice.removeloader();
      }, (error) => {
        $('#driverSuspended').modal('show');
        this.msg = 'Could not suspend drivers.';
        this.loadservice.removeloader();
      }
    );
  }

  public showTabByRole(role):boolean{
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let result=false;
    userInfo.roles.map(r=>{
      if(role===r.code){
        result=true;
      }
    })
    return result;
  }

  adminPortalSearch() {
    this.showResultsTable = true;
    this.adminSearchNoResultMessage = null;
    this.tableToShow = this.typeOfSearch;
    this.thereIsAResult = false;
    // this.foundUser = false;
    this.loadservice.addloader();
    console.log(this.somethingToSearchFor);

    // Search by track number
    if (this.typeOfSearch === 'Track number' && this.somethingToSearchFor !== null) {

      this.bookService.getBookingByTrackNum(this.somethingToSearchFor.toUpperCase()).subscribe(
        (response: any) => {

            this.adminFoundResult = response.json()[0];
            this.loadservice.removeloader();
            this.thereIsAResult = true;
      },
        (error) => {

            this.adminSearchNoResultMessage = 'The booking does not exist.';
            this.loadservice.removeloader();
      });

    // Search by email
    } else if (this.typeOfSearch === 'Email' && this.somethingToSearchFor !== null) {

      this.adminService.getUserByEmail(this.adminUser.token, this.somethingToSearchFor.toLowerCase()).subscribe(
        (response: any) => {

          console.log(response);
          this.adminFoundResult = response;
          this.loadservice.removeloader();
          this.thereIsAResult = true;
        },
        (error) => {

          this.adminSearchNoResultMessage = 'This user does not exist.';
          this.loadservice.removeloader();
        });

      // Search by phone number to find users/drivers
    } else if (this.typeOfSearch === 'Waybill number' && this.somethingToSearchFor !== null) {

      this.bookService.getBookingByWaybillNumber(this.somethingToSearchFor.toUpperCase()).subscribe(
        (response: any) => {

            this.adminFoundResult = response.json();
            this.loadservice.removeloader();
            this.thereIsAResult = true;
      },
        (error) => {

            this.adminSearchNoResultMessage = 'The booking does not exist.';
            this.loadservice.removeloader();
      });

    // Search by phone number to find users/drivers
    } else if (this.typeOfSearch === 'Phone number' && this.somethingToSearchFor !== null) {
      this.adminService.getUserByNumber(this.somethingToSearchFor, this.adminUser.token).subscribe(
        (res) => {

            this.adminFoundResult = res;
            // this.foundUser = true;
            this.loadservice.removeloader();
            this.thereIsAResult = true;
          },
        (error) => {

          this.adminSearchNoResultMessage = 'Mobile number does not exist.';
          this.loadservice.removeloader();
          });

    // Search by vehicle registration number
    } else if (this.typeOfSearch === 'Vehicle registration number' && this.somethingToSearchFor !== null) {
      this.adminService.getVehicleByVinOrReg(this.adminUser.token, this.somethingToSearchFor).subscribe(
        (res) => {

          this.adminFoundResult = res;
          console.log(this.adminFoundResult);
          this.loadservice.removeloader();
          this.thereIsAResult = true;
        },
        (error) => {

          this.adminSearchNoResultMessage = 'Vehicle registration number does not exist.';
          this.loadservice.removeloader();
        });

    // Do nothing
    } else {
      this.loadservice.removeloader();
      // Do nothing
    }
  }

  hideTable() {
    this.showResultsTable = !this.showResultsTable;
  }

  public showBooking(booking) {
    localStorage.setItem('selectedBooking', JSON.stringify(booking));
    this.router.navigate(['/view-booking']);
  }

  public showUser(user) {
    // localStorage.removeItem('selectedPartner');
    localStorage.setItem('selectedUser', JSON.stringify(user));
    this.router.navigate(['/view-user']);
  }

  public showVehicle(vehicle) {
    localStorage.setItem('selectedVehicle', JSON.stringify(vehicle));
    this.router.navigate(['/update-vehicle']);
  }
}

interface User {
  user: any;
  isPartner: boolean;
}
