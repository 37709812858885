import {Injectable} from '@angular/core';
import {Http} from "@angular/http";
import {HttpClient} from "@angular/common/http";
import {SharedService} from "./shared.service";
import {Observable} from "rxjs/Observable";

@Injectable({
  providedIn: 'root'
})
export class ContractOwnerService {

  constructor(private http: Http, private httpClient: HttpClient, private sharedService: SharedService) {
  }

  public getContractOwners(token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'owner',
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  public getContractOwner(token, ownerId): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'owner/' + ownerId,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  public getOwnerVehicle(token, ownerId): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'owner/vehicles/' + ownerId,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  public getOwnerDriver(token, ownerId): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'owner/drivers/' + ownerId,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  public getOwnerBankingDetails(token, ownerId): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'owner/banking/details/' + ownerId,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  public addContractOwner(token, contract): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'owner/add/contract', contract,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  public addBankingDetails(token, bankObj): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'owner/add/bank/details', bankObj,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  public deleteParcelById(token, parcelId): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'owner/parcels/delete/' + parcelId,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  public addVehicle(token, addVehicleObj): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'owner/add/vehicle', addVehicleObj,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  public addParcel(token, contractId, parcelObj): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'owner/add/parcel/' + contractId, parcelObj,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  public editParcel(token, parcel, parcelId): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'owner/edit/parcel/' + parcelId, parcel,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }


  public addDriver(token, contractId, driverObj): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'owner/add/contract/' + contractId + '/driver', driverObj,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  public getOwnerParcels(token, ownerId): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'owner/parcels/owner/' + ownerId,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  public assignDriver(token, contractDriverId, contractVehicleId): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'owner/assign/driver/' + contractDriverId + '/to/' + contractVehicleId, '',
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  public createVehicleRate(token, vehicleRateObj): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'owner/create/vehicle/rate', vehicleRateObj,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  public updateVehicleRate(token, vehicleRateObj): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'owner/update/vehicle/rate', vehicleRateObj,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  public getVehicleRate(token, vehicleRateId): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'owner/vehicle/' + vehicleRateId + '/rate',
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  public getRemittence(token, date): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'owner/filter/remittence', date,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  public sendRemittence(token, date): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'owner/send/remittence', date,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  public downloadSkyNetRouteBilling(token, dateFilter): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'owner/download/route/billing', dateFilter,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  public downloadSkyNetRouteBillingAPI(token, dateFilter): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'owner/download/skynet/data/billing', dateFilter,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  public downloadSkyNetWaybillReport(token, dateFilter): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'owner/download/skynet/data/waybill/report', dateFilter,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  public downloadSkyNetWaybillReportBlob(token: string, branch:string, dateFilter: any): Observable<Blob> {
    const userToken = 'Bearer ' + token;

    return this.httpClient.post(this.sharedService.getUrl() + 'owner/downloadExcel/'+branch, dateFilter, {
      headers: {
        'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'Content-Type': 'application/json',
        'Authorization': userToken
      },
      responseType: 'blob'
    });
  }

  public getContractOwnerApiLogs(token, filter): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'owner/fetch/owner/logs/date/range', filter,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  public getContractOwnerId(token, registration): Observable<any> {
    var userToken = 'Bearer ' + token;

    console.log("registration", registration);

    return this.httpClient.get(this.sharedService.getUrl() + 'owner/vehicle/' + registration,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      }
    )
  }

  public sendVehicleRemittences(token, remitence): Observable<any> {
    var userToken = 'Bearer ' + token;

    return this.httpClient.post(this.sharedService.getUrl()+ "owner/remittance/send", remitence,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      }
    )
  }

  public getOwnerEarnings(token, filter): Observable<any> {
    var userToken = 'Bearer ' + token;

    return this.httpClient.post(this.sharedService.getUrl() + 'owner/skynet/data/remittence',filter,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      }
    )
  }

  public getContractOwnerApiLogDetails(token, filter): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'owner/fetch/owner/logs/details', filter,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  public generateSkynetBill(token, date): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'owner/skynet/billing', date,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  public getRateStructures(token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'owner/get/rate/structure',
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  public createRateStructure(token, structureObj): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'owner/create/rate/structure', structureObj,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  public deleteRateStructure(token, structureObj): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'owner/create/rate/structure', structureObj,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  public removeRateStructures(token, structureId): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.delete(this.sharedService.getUrl() + 'owner/remove/rate/structure/' + structureId,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  public updateRateStructures(token, structureId, structureObj): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'owner/update/rate/structure' + structureId, structureObj,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  public createRoute(token, routeObj): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'owner/create/route', routeObj,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  public getRoute(token, ownerId): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'owner/routes/' + ownerId,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  public createRouteRate(token, rateObj): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'owner/create/route/rate', rateObj,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  public getFuelCharge(token): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'owner/fuel/charge',
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  public createFuelCharge(token, obj): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'owner/fuel/charge', obj,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  public vehicleBilling(token, vehicleId): Observable<any> {
    var userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'owner/vehicle/' + vehicleId + '/billing',
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  getRouteByFilter(token: string, obj: any): Observable<any> {
    const userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'owner/get/all/routes', obj,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      }
    );
  }

  assignVehicleToRoute(token: string, vehicleId, route: any) {
    const userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'owner/add/vehicle/route/' + vehicleId + '/' + route, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': userToken
      }
    });
  }

  addBranch(token: string, createBranch: any) {
    const userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'owner/add/branch', createBranch,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  getAllBranches(token: string) {
    const userToken = 'Bearer ' + token;
    return this.httpClient.get(this.sharedService.getUrl() + 'owner/get/branches',
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': userToken
        }
      });
  }

  makeOwnerPermanent(token: string, obj: any) {
    const userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'owner/add/person/to/contactor', obj,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: userToken
        }
      });
  }

  addDeductionToDriver(obj: any, token: string) {
    const userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'owner/add/deductions' , obj ,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: userToken
        }
      });
  }
  removeDeductions(driverId: any, deductionId: any, token: string) {
    const userToken = 'Bearer ' + token;
    const url = `${this.sharedService.getUrl()}owner/remove/deduction/${driverId}/${deductionId}`;

    return this.httpClient.delete(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: userToken
      }
    });
  }

  viewDrierDeductions(driverId: any, token: string) {
    const userToken = 'Bearer ' + token;
    const url = `${this.sharedService.getUrl()}owner/view/driver/deductions/${driverId}`;

    return this.httpClient.get(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: userToken
      }
    });
  }
}
