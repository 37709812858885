import { Routes } from "@angular/router";
import { UserPortalComponent } from "../user-portal/user-portal/user-portal.component";
import { HomeComponent } from "../home/home.component";
import { EditBookingComponent } from "../user-portal/edit-booking/edit-booking.component";
import { DriverProfileComponent } from "../user-portal/driver-profile/driver-profile.component";
import { ViewBookingComponent } from "../user-portal/view-booking/view-booking.component";
import { OnlyLoggedInUsersGuard } from "../guards/onlyLoggedIn.guard";
import { AlwaysAuthGuard } from "../guards/alwaysAuth.guard";
import { AddCompanyComponent } from "../user-portal/add-company/add-company.component";
import { RatingPageComponent } from "../user-portal/rating-page/rating-page.component";
import { WalletSuccessfulLoadComponent } from "../user-portal/wallet-successful-load/wallet-successful-load.component";
import { ViewWalletTransactionsComponent } from "../user-portal/view-wallet-transactions/view-wallet-transactions.component";
import { UserWalletTransactionsComponent } from "../user-portal/user-wallet-transactions/user-wallet-transactions.component";
import { ConfirmSingleCourierComponent } from "../user-portal/confirm-single-courier/confirm-single-courier.component";
import { RetailSuccessfulLoadComponent } from "../user-portal/retail-successful-load/retail-successful-load.component";
import { RetailSingleBookingSuccessfulLoadComponent } from "../user-portal/retail-single-booking-successful-load/retail-single-booking-successful-load.component";
import { RetailRentalSuccessPaymentComponent } from "../user-portal/retail-rental-success-payment/retail-rental-success-payment.component";
import { ViewRentalDetailsComponent } from "../user-portal/view-rental-details/view-rental-details.component";
import { ViewCustomerWaybillComponent } from '../user-portal/view-customer-waybill/view-customer-waybill.component';
import { DriverOwnerFinancialsComponent } from "../user-portal/driver-owner-financials/driver-owner-financials.component";

export const SECOND_ROUTES: Routes = [
  { path: "", component: HomeComponent },
  {
    path: "user",
    component: UserPortalComponent,
    canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard],
  },
  {
    path: "add-company",
    component: AddCompanyComponent,
    canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard],
  },
  {
    path: "edit-booking",
    component: EditBookingComponent,
    canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard],
  },
  {
    path: "driver-profile",
    component: DriverProfileComponent,
    canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard],
  },
  {
    path: "view-bookings",
    component: ViewBookingComponent,
    canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard],
  },
  { path: "ratings-page/:bookingOid", component: RatingPageComponent },
  {
    path: "wallet-load-successful/:paymentOid",
    component: WalletSuccessfulLoadComponent,
    canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard],
  },
  {
    path: "retail-transaction-history",
    component: ViewWalletTransactionsComponent,
    canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard],
  },
  {
    path: "user-transaction-history",
    component: UserWalletTransactionsComponent,
    canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard],
  },
  {
    path: "confirm-single-delivery",
    component: ConfirmSingleCourierComponent,
    canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard],
  },
  {
    path: "retail-load-successful/:bucketBookingOid/:value/:type",
    component: RetailSuccessfulLoadComponent,
    canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard],
  },
  {
    path: "retail-single-load-successful/:dropOid/:value/:type",
    component: RetailSingleBookingSuccessfulLoadComponent,
    canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard],
  },
  {
    path: "retail-rental-payment/:rentalOid",
    component: RetailRentalSuccessPaymentComponent,
    canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard],
  },
  {
    path: "view-rental-details",
    component: ViewRentalDetailsComponent,
    canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard],
  },
  {
    path: "view-customer-waybill",
    component: ViewCustomerWaybillComponent,
    canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard],
  },
  {
    path: "owner-financial",
    component: DriverOwnerFinancialsComponent,
    canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard],
  },
];
