import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-configure-skynet-rate',
  templateUrl: './configure-skynet-rate.component.html',
  styleUrls: ['./configure-skynet-rate.component.css']
})
export class ConfigureSkynetRateComponent implements OnInit {

  typeOfSalary = 'Blank';
  salaryAmountEnable = false;
  salaryAmount = 0;

  costPerWaybill = 0;

  numberOfTiers = 0;
  waybillCount = 0;

  listOfTiers = [];

  showTierError = false;
  disableEdit = false;

  tierCost = 0;
  tierCount = 0;

  tierToEdit = -1;

  fuelBase = 0;
  fuelCurrent = 0;
  fuelDifference = 0;
  fuelPercentageDifference = 0;
  percentageCost = 30;
  percentageCostValue = 0; // fuelDifferencePercentage * percentageCost / 100
  fuelSurchargeAmount = 0;

  showSubmitError = false;

  constructor() { }

  ngOnInit() {
  }

  disableSalaryAmount() {
    this.salaryAmountEnable = this.typeOfSalary === 'Fixed';
  }

  addTier() {
    if (this.costPerWaybill !== null && this.costPerWaybill !== 0 && this.costPerWaybill !== null && this.waybillCount !== 0) {
      this.showTierError = false;
      const tier = {
        number: this.numberOfTiers += 1,
        cost: this.costPerWaybill,
        count: this.waybillCount,
      };

      this.listOfTiers.push(tier);
    } else {
      this.showTierError = true;
    }
  }

  editTier(tierCost, tierCount, editRow) {
    this.tierToEdit = editRow;
    this.tierCost = tierCost;
    this.tierCount = tierCount;
    this.disableEdit = !this.disableEdit;
  }

  setTier(tierNumber) {
    if (this.tierCost !== undefined) {
      this.listOfTiers[tierNumber - 1].cost = this.tierCost;
    }

    if (this.tierCount !== undefined) {
      this.listOfTiers[tierNumber - 1].count = this.tierCount;
    }

    this.disableEdit = !this.disableEdit;
    this.tierToEdit = -1;
  }

  cancelTierEdit() {
    this.disableEdit = !this.disableEdit;
  }

  updateAmounts() {
    this.fuelDifference = this.fuelCurrent - this.fuelBase;
    this.fuelPercentageDifference = (this.fuelDifference / this.fuelBase) * 100;
    this.percentageCostValue = this.fuelPercentageDifference * this.percentageCost / 100;
    this.fuelSurchargeAmount = this.fuelBase * (1 + (this.percentageCostValue / 100));
  }

  sendConfiguration() {
    if (this.listOfTiers.length > 0 && this.typeOfSalary !== 'Blank') {
      this.showSubmitError = false;
      // creat data object and send
    } else {
      this.showSubmitError = true;
    }
  }
}
