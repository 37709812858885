import {Component, OnInit } from '@angular/core';
import {AdminService} from '../../services/admin.service';
import {Router} from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-view-driver',
  templateUrl: './view-driver.component.html',
  styleUrls: ['./view-driver.component.css']
})
export class ViewDriverComponent implements OnInit {

  driver: any = {};
  responseMsg: string = '';
  loader: boolean = false;
  image: string;
  location: string;
  ownerMobile: string;
  locationObj: any;
  retail: any;
  valid: boolean = false;
  assignStatus: string = '';
  err: string = '';
  province: string;
  ratings1:any = [];
  ratings:any = [];
  score: any;
  comment: any;
  bookings : any = [];

  itemsPage: number = 1;
  total: number;


  page: number = 1;


  selectedTabID : any;
  skynet: any;

  public userSettings2: any = {
    showRecentSearch: false,
    geoCountryRestriction: ['ZA'],
    inputPlaceholderText: 'Enter Driver location.'
  };

  provinceMsg: string;

  constructor(private adminService: AdminService, private router: Router) { }

  ngOnInit() {
    this.driver = JSON.parse(localStorage.getItem('selectedDriver'));
    if (this.driver.ratings !==  null) {
      this.ratings = this.driver.ratings.score;
    }
    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.getDriversBooking(this.driver.oid, object.token).subscribe(
      (res) => {
        this.bookings = res.reverse();
      }
    );

    this.selectedTabID = localStorage.getItem('activeTab');
    localStorage.setItem('activeID', this.selectedTabID);
  }

  autoCompleteCallback($event) {
    this.locationObj = $event;
    this.location = this.locationObj.data.name
  }

  public assignDriverSkynetTag() {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.skynet = "SKYNET";
    this.adminService.skynetDriver(object.token, this.driver.oid, this.skynet).subscribe(
      response => {
        this.responseMsg = 'Skynet tag sucessfully assigned to driver.';
        $('#feedbackDialog').modal('show');
      }, error => {
        console.log(error);
        this.responseMsg = 'Unable to assign tag to driver.';
        $('#feedbackDialog').modal('show');
      }, () => {

      }
    )
  }

  public addLocation() {
    if (this.location != null) {
      var object = JSON.parse(localStorage.getItem('userInfo'));
      this.adminService.updateDriver(this.location, this.driver.oid, object.token).subscribe(
        (response) => {
          this.responseMsg = 'Driver location updated successfully.';
          localStorage.setItem('selectedDriver', JSON.stringify(response));
          $('#feedbackDialog').modal('show');
        },
        (error) => {
          this.responseMsg = 'Error occured while adding location.';
          $('#feedbackDialog').modal('show');
        }
      );
    } else {
      this.responseMsg = 'Enter the location.';
      $('#feedbackDialog').modal('show');
    }
  }

  public viewBookings() {
    localStorage.setItem('selectedDriverBooking', JSON.stringify(this.driver));
    this.router.navigate(['/driver-bookings']);
  }

  public getRetail() {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.err = '';
    this.adminService.getRetailByOwner(object.token, this.ownerMobile).subscribe(
      (res) => {
        this.valid = true;
        this.retail = res;
      }, (err) => {
        this.err = err.error.message;
      }
    );
  }

  public assignNow() {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.assignToRetail(object.token, this.driver.oid, this.retail.oid).subscribe(
      (res) => {
        this.assignStatus = 'Driver successfully assigned to retail.';
        localStorage.setItem('selectedDriver', JSON.stringify(res));
        $('#assignDialog').modal('show');
      }, (err) => {
        this.assignStatus = 'Unexpected error occurred while assigning retail';
        $('#assignDialog').modal('show');
      }
    );
  }

  public addProvince() {
    if (this.province == null) {
      alert('Please select province');
    } else {
      var object = JSON.parse(localStorage.getItem('userInfo'));
      this.adminService.addProvince(object.token, this.driver.oid, this.province).subscribe(
        (response) => {
          this.provinceMsg = 'Driver successfully assigned a province.';
          $('#addProvinceDialog').modal('show');
          localStorage.setItem('selectedDriver', JSON.stringify(response));
        }, (err) => {
          this.provinceMsg = 'Unexpected error occurred while add driver to a province';
          $('#addProvinceDialog').modal('show');
        }
      );
    }
  }

  public viewDriverBooking(book) {
    localStorage.setItem('selectedBooking', JSON.stringify(book));
    this.router.navigate(['/view-booking']);
  }
}


