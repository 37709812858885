import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';



@Component({
  selector: 'app-RetailCheckoutComponent',
  templateUrl: './RetailCheckoutComponent.component.html',
  styleUrls: ['./RetailCheckoutComponent.component.css']
})
export class RetailCheckoutComponentComponent implements OnInit {


  productId: any;
  url: string = "https://sandbox.payfast.co.za/eng/process";


  paymentData : any

  cardPaymentData = {
    "merchant_id": "userPaymentData.owner.firstName",
    "merchant_key": "userPaymentData.owner.surname",
    "return_url": "userPaymentData.owner.email",
    "cancel_url": "userPaymentData.owner.mobile",
    "notify_url":  "this.bucketBooking.oid",
    "name_first": "this.tempAmount",
    "name_last": "userPaymentData.owner.oid",
    "email_address": "BUCKET BOOKING",
    "m_payment_id": "Transporting mutilpe Items from ",
    "amount": "userPaymentData.owner.email",
    "item_name": "userPaymentData.owner.email",
    "item_description": "this.bucketBooking.oid",
    "confirmation_address": "this.bucketBooking.oid",
    "custom_int1": "custom_int1",
    "signature": "",
    "cell_number": ""
  }

  constructor(private routing: Router, private route: ActivatedRoute) { 
    //console.log(this.payFastForm)
  }

  ngOnInit() {
    this.paymentData = JSON.parse(localStorage.getItem('payFastPaymentData'));
    console.log(this.paymentData)
    this.showPayFastPaymentData()
  
  }

  public showPayFastPaymentData(){

    if (this.paymentData != null){
      this.cardPaymentData = this.paymentData
      // this.cardPaymentData.merchant_key = "e4yoybglsbi75"
      // this.cardPaymentData.merchant_id = "10020910"
      // this.cardPaymentData.cell_number = "0671158390"
      console.log(this.cardPaymentData)
    } 

  }

  public testElement(){
    // console.log(this.payFastForm);
    // this.payFastForm.nativeElement["0"].value = "100209101"
    // console.log(this.payFastForm.nativeElement["0"]);
  }

}
