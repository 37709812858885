import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NgForm } from '@angular/forms';
import { Response } from '@angular/http';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { BookingService } from '../../services/booking.service';
import { SharedService } from '../../services/shared.service';
declare var $: any;
import * as firebase from 'firebase/app';
import { HttpClient } from '@angular/common/http';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-select-date',
  templateUrl: './select-date.component.html',
  styleUrls: ['./select-date.component.css']
})
export class SelectDateComponent implements OnInit {

  @ViewChild('contactForms', { static: true }) contactForms: NgForm;
  @ViewChild('submitButton', { static: true }) submitButton: ElementRef;
  @ViewChild('submitButtons', { static: true }) submitButtons: ElementRef;
  @ViewChild('submitButtonss', { static: true }) submitButtonss: ElementRef;

  // Payfast Payment Elements
  @ViewChild('m_id', { static: false }) merchant_id: ElementRef;
  @ViewChild('m_key', { static: false }) merchant_key: ElementRef;
  @ViewChild('return_url', { static: false }) return_url: ElementRef;
  @ViewChild('cancel_url', { static: false }) cancel_url: ElementRef;
  @ViewChild('notify_url', { static: false }) notify_url: ElementRef;
  @ViewChild('name_first', { static: false }) name_first: ElementRef;
  @ViewChild('name_last', { static: false }) name_last: ElementRef;
  @ViewChild('email_address', { static: false }) email_address: ElementRef;
  @ViewChild('cell_number', { static: false }) cell_number: ElementRef;
  @ViewChild('m_payment_id', { static: false }) m_payment_id: ElementRef;
  @ViewChild('amount', { static: false }) amount: ElementRef;
  @ViewChild('item_name', { static: false }) item_name: ElementRef;
  @ViewChild('item_description', { static: false }) item_description: ElementRef;
  @ViewChild('custom_int1', { static: false }) custom_int1: ElementRef;
  @ViewChild('confirmation_address', { static: false }) confirmation_address: ElementRef;

  addrressData: any;
  pickUpChecked = false;

  contact: Contact = {
    name: '',
    surname: '',
    mobile: '',
    email: '',
    labours: 0
  };

  dropContact: Contact = {
    name: '',
    surname: '',
    mobile: '',
    email: '',
    labours: 0
  };

  disablePickUp: boolean = false;
  dropOffChecked: boolean = false;

  laboursChecked: boolean = false;
  bookingDateDisale: boolean = false;
  bookinTimeDisable: boolean = false;
  loadDisabale: boolean = false;
  promoDisable: boolean = false;

  datePipe: DatePipe;

  bookingTime: any;
  bookingDate: any;
  comment: string;
  labour: number = 0;
  tempAmount: number;
  loader: boolean = false;
  hour: number;

  promoStatus: string;
  voucherStatus: string;
  voucherCode: string;
  terms: boolean;

  termsControl: boolean = false;
  today = new Date();
  yesterday = new Date(this.today);
  promoCode: string;
  promoCodeAmnt: number = 0.0;
  voucherAmnt: number = 0.0;
  temporaryAmount: number;
  msg: string;
  message: string;
  load: number = 1;
  canopy: boolean = false;
  province: string;
  hourDiff: any;
  bookingDates: any;
  theBookingDate: any;
  dropOid: string;
  promocodeUsed: boolean = false;
  voucherUsed: boolean = false;
  createdDrop: any;
  voucherUpdate: any;
  distance: any;
  paymentMsg: any;
  vehicle: any;
  ipAddress = '';
  isExpress: boolean = false

  transportMode: any;
  isItemPicked: boolean = false;
  mainCityOid: any;
  isPayed: boolean = false;
  fromSuburb: any;
  toSuburb: any;
  pickUpPCode: any;
  dropOffPCode: any;
  pickUpProvince: any;
  dropOffProvince: any;
  type: any;
  destinationProvince: any;
  pickUplat: any;
  pickUplng: any;
  mainCenter: any;

  public min = new Date(this.yesterday.setDate(this.today.getDate() - 1));
  public max = new Date(2025, 3, 21, 20, 30);
  isBalancePositive: boolean = false;
  usedTotal: number;

  payFastResponse: any;
  url: string;
  customerRef: any;
  costCentreNumber: any;
  checkingMsg: string = '';
  atPickUpMsg: string = 'Will you be at the pick-up?';
  atDropOffMsg: string = 'Will you be at the drop-off?';

  ozowUrl: string = '';
  paymentOptions = [];
  testBtn: boolean = true;

  constructor(private nameService: SharedService, private bookingService: BookingService, private router: Router, private http: HttpClient,private loadservice:LoaderService) {
    this.url = this.nameService.getPayFastUrl();
  }

  ngOnInit() {
    this.addrressData = JSON.parse(localStorage.getItem('addressData'));
    this.distance = JSON.parse(localStorage.getItem('distance'));
    this.tempAmount = this.addrressData.price;
    this.temporaryAmount = this.tempAmount;
    this.vehicle = this.addrressData.vehicleType.vehicleType;
    this.checkedPickup(true);
    this.checkedDropUp(true);
    this.getIPAddress();
  }

  public checkedDropUp(dropOff: any) {
    var object = JSON.parse(localStorage.getItem('userData'));
    if (dropOff) {
      this.dropOffChecked = true;
      this.dropContact.name = object.owner.firstName;
      this.dropContact.email = object.owner.email;
      this.dropContact.mobile = object.owner.mobile;
      this.dropContact.surname = object.owner.surname;
      this.atDropOffMsg = 'I will be at the drop off.';
    } else {
      this.dropOffChecked = false;
      /*this.dropContact.name = '';
      this.dropContact.email = '';
      this.dropContact.mobile = '';
      this.dropContact.surname = '';*/
      this.atDropOffMsg = 'Will you be at drop off';
    }
  }

  public checkedPickup(pickUp: any) {
    var object = JSON.parse(localStorage.getItem('userData'));
    if (pickUp) {
      this.pickUpChecked = true;
      this.disablePickUp = true;
      this.contact.name = object.owner.firstName;
      this.contact.email = object.owner.email;
      this.contact.mobile = object.owner.mobile;
      this.contact.surname = object.owner.surname;
      this.atPickUpMsg = 'I will be at the pick up.';
    } else {
      this.pickUpChecked = false;
      this.disablePickUp = false;
      /*this.contact.name = '';
      this.contact.email = '';
      this.contact.mobile = '';
      this.contact.surname = '';*/
      this.atPickUpMsg = 'I will be at the pick up.';
    }
  }

  labourFactor(labour) {
    if (labour > 0) {
      this.onLabour(labour);
    }
    else {
      this.onLabour(labour);
    }
  }

  decrementLabour(labour) {
    if (labour > 0) {
      this.contact.labours -= 1;
      this.onLabour(this.contact.labours);
    } else {
      this.contact.labours = 0;
      this.onLabour(this.contact.labours);
    }
  }

  incrementLabour(labour) {
    if (this.addrressData.vehicleType == 'DROPPA_EXPRESS') {
      this.contact.labours = 0;
      this.onLabour(this.contact.labours);
    }
    else {
      if (labour >= 0) {
        this.contact.labours += 1;
        this.onLabour(this.contact.labours);
      } else {
        this.contact.labours = 0;
        this.onLabour(this.contact.labours);
      }
    }
  }

  public onLabour(labour: number) {
    if (labour != null) {
      if (labour >= 0) {
        this.tempAmount = ((this.addrressData.price + (this.contact.labours * 150)) * this.load) - this.promoCodeAmnt;
      }
    } else {
      this.tempAmount = ((this.addrressData.price + (this.contact.labours * 150)) * this.load) - this.promoCodeAmnt;
    }
  }

  public promo(promoValue) {
    if (promoValue != null) {
      if (promoValue.length === 6) {
        var promo = firebase.database().ref('promoCode/' + promoValue.toUpperCase());
        this.promoStatus = 'Searching, please wait...';
        promo.on('value', (datasnapshot) => {
          if (datasnapshot.val()) {
            this.usedTotal = datasnapshot.val().used;
            if (datasnapshot.val().status === 'Active') {
              this.tempAmount = ((this.addrressData.price + (this.contact.labours * 150)) * this.load) - datasnapshot.val().price;
              this.promoCodeAmnt = datasnapshot.val().price;
              this.promoStatus = 'Discount of R' + datasnapshot.val().price + ' granted.';
              promo.off();
              var promoUpdate = firebase.database().ref('promoCode/' + promoValue.toUpperCase());
              promoUpdate.update({ used: this.usedTotal + 1 });
              this.promocodeUsed = true;
              promoUpdate.off();
            } else {
              this.promoStatus = 'Promo code is deactivated.';
            }
          } else {
            this.promoStatus = 'Invalid Code, no match found.';
          }
        });
        promo;
      } else {
        this.promoStatus = '';
      }
    }
  }

  public cashPayment() {

    if (!this.termsControl) {
      this.checkingMsg = 'Please click the checkbox below across the terms & conditions to proceed with the booking.';
      $('#validationModal').modal('show');

    } else if (this.contact.name == '') {
      this.checkingMsg = 'Please enter the name of the person at the pick up (Or select the small box to indicate you will be at the pick up).';
      $('#validationModal').modal('show');
    } else if (this.contact.mobile == '') {
      this.checkingMsg = 'Please enter the number of the person at the pick up (Or select the small box to indicate you will be at the pick up).';
      $('#validationModal').modal('show');
    } else if (this.comment == null) {
      this.checkingMsg = 'Please enter in the comment section below for any further comments (instructions or tips regarding your delivery).';
      $('#validationModal').modal('show');
    } else if (this.bookingDate == null) {
      this.checkingMsg = 'Please select a date to proceed with the booking.';
      $('#validationModal').modal('show');
    } else if (this.bookingTime == null) {
      this.checkingMsg = 'Please select a time to proceed with the booking.';
      $('#validationModal').modal('show');
    } else if (this.dropContact.name == '') {
      this.checkingMsg = 'Please enter the name of the person at the drop off (Or select the small box to indicate you will be at the pick up).';
      $('#validationModal').modal('show');
    } else if (this.dropContact.mobile == '') {
      this.checkingMsg = 'Please enter the number of the person at the drop off (Or select the small box to indicate you will be at the pick up).';
      $('#validationModal').modal('show');
    }
    else {

      this.paymentMsg = "Processing...";
      var object = JSON.parse(localStorage.getItem('userData'));
      var token = localStorage.getItem('token');
      var currentDate = new Date(this.bookingDate);
      let date = currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate();
      let monthNo = (currentDate.getMonth() + 1) <= 9 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
      let hour = currentDate.getHours() <= 9 ? '0' + currentDate.getHours() : currentDate.getHours();
      let minutes = currentDate.getMinutes() <= 9 ? '0' + currentDate.getMinutes() : currentDate.getMinutes();
      var second = currentDate.getSeconds() <= 9 ? '0' + currentDate.getSeconds() : currentDate.getSeconds();
      var dropTime = currentDate.getFullYear() + '-' + monthNo + '-' + date + 'T' + hour + ':' + minutes + ':' + second;

      var dateValue = this.bookingDate;
      var datePipe = new DatePipe('en-US');
      let value = datePipe.transform(dateValue, 'yyyy-MM-dd');

      var timestamp = new Date().valueOf();
      var datePipe = new DatePipe('en-US');
      let valueTime = datePipe.transform(this.bookingTime, 'HH:mm a');

      var timeD = currentDate.getFullYear() + '-' + monthNo + '-' + date + ' ' + valueTime.split(" ")[0];
      this.promoCodeAmnt = this.promoCodeAmnt;

      var bookingData = {
        'dropOff': {
          'firstName': this.dropContact.name,
          'lastName': this.dropContact.surname != null ? this.dropContact.surname : object.owner.surname,
          'phone': this.dropContact.mobile,
          'email': object.owner.email
        },
        'pickUp': {
          'firstName': this.contact.name,
          'lastName': this.contact.surname != null ? this.contact.surname : object.owner.surname,
          'phone': this.contact.mobile,
          'email': object.owner.email
        },
        'customer': object.owner,
        'price': this.tempAmount + this.promoCodeAmnt,
        'vehicleType': this.addrressData.vehicleType,
        'pickUpAddress': this.addrressData.pickUpAddress.address,
        'pickUpTime': valueTime,
        'phoneNo': object.owner.mobile,
        'pickUpDate': value,
        'dropOffAddress': this.addrressData.dropOffAddress.address,
        'comment': this.comment,
        'labour': this.contact.labours,
        'timestamp': timestamp,
        'status': 'AWAITING_DRIVER',
        'pickUpCoordinate': this.addrressData.pickUpAddress,
        'dropOffCoordinate': this.addrressData.dropOffAddress,
        'load': this.load === 0 ? 1 : this.load,
        'province': this.addrressData.province != null ? this.addrressData.province : 'UNKNOWN',
        'canopy': this.canopy,
        'promotionCode': this.promoCode,
        'platform': 'web',
        "isExpress": this.isExpress,
        'transportMode': 'ROAD',
        'isItemPicked': false,
        'mainCityOid': this.mainCenter != null ? this.mainCenter.oid : null,
        'isPayed': false,
        'type': 'DEDICATED',
        'destinationProvince': this.addrressData.dropOffProvince,
        'paymentType': "CASH_PAY",
        "customerReference": this.customerRef,
        "costCenter": this.costCentreNumber
      };

      localStorage.setItem('bookingDataObject', JSON.stringify(bookingData));

      var drop = {
        'name': 'Booking',
        'notes': 'be careful',
        'cost': (this.tempAmount * 100) + this.promoCodeAmnt,
        'clientOid': object.owner.oid,
        'vehicleOid': '1521105441081',
        'driverOid': '575ae6bd30bc5d5017477143',
        'booking': bookingData,
        'from': {
          'coordinates': [
            '-26.002483',
            '28.099503'
          ],
          'address': this.addrressData.pickUpAddress.address,
        },
        'to': {
          'coordinates': [
            '-26.002483',
            '28.099503'
          ],
          'address': this.addrressData.dropOffAddress.address,
        },
        'when': dropTime,
        'atp': dropTime,
        'eta': dropTime,
        'ata': dropTime,
        'platform': 'web',
        'droplets': [
          {
            'itemOid': '575ae6bd30bc5d5017477143',
            'notes': 'new'
          }
        ]
      };

      this.createdDrop = drop;
      var realTime = new Date(dateValue);
      var currentDate = new Date();
      var dateFormat = currentDate.toLocaleString('en-us', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');
      var pickUpdateFormat = realTime.toLocaleString('en-us', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');
      var dateSame = pickUpdateFormat === dateFormat;
      var dateDiffer = pickUpdateFormat > dateFormat;
      let TimeSeparatorIndex = valueTime.indexOf(':');
      this.hour = parseInt(valueTime.substring(0, TimeSeparatorIndex));
      var timeArray = valueTime.split(' ');
      var bookingTimeArray = timeArray[0].split(':');
      this.bookingDates = new Date(datePipe.transform(dateValue, 'yyyy-MM-dd'));
      let minute = valueTime.substring(TimeSeparatorIndex);
      this.bookingDates.setHours(this.hour);
      this.bookingDates.setMinutes(parseInt(bookingTimeArray[1]));

      var obect = {
        'pickUpAddress': this.addrressData.pickUpAddress,
        'dropOffAddress': this.addrressData.dropOffAddress,
        'price': this.tempAmount + this.promoCodeAmnt,
        'vehicleType': this.addrressData.vehicleType
      };

      localStorage.setItem('addressData', JSON.stringify(obect));
      var validHour = moment(this.bookingDates).isAfter(moment().add(1, 'hours'));
      if (validHour) {
        if (dateSame === true || dateDiffer === true) {

          var dD = new Date(timeD);
          var todaysDate = new Date();

          var Difference_In_Time = dD.getTime() - todaysDate.getTime();
          var Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));
          if (this.hour >= 7 && this.hour <= 17) {
            if (this.distance > 200 && Difference_In_Days < 1) {
              this.msg = 'For long distance bookings, please give us at least 24 hours.';
              $('#distanceValidation').modal('show');
            } else {
              if (this.hour < 9 || this.hour > 15) {
                this.message = 'High traffic! Please bear with our driver.';
              }
              if (this.tempAmount < 1000) {
                if (this.promocodeUsed === false) {
                  this.submitButtons.nativeElement.disabled = true;
                  this.loadservice.addloader();
                  this.bookingService.cash(drop, token)
                    .subscribe(
                      (res: Response) => {
                        this.bookingService.cashPay(res.json().oid, token)
                          .subscribe(
                            (response: Response) => {
                              this.loadservice.removeloader();
                              this.router.navigateByUrl('/user');
                            },
                            (err) => {
                              console.log(err);
                              this.loadservice.removeloader();
                              this.submitButtons.nativeElement.disabled = false;
                            }
                          );
                      },
                      (error) => {
                        this.loadservice.removeloader();
                        this.submitButtons.nativeElement.disabled = false;
                      }
                    );
                } else {
                  this.promocodeUsed = false;
                  $('#cashPromocode').modal('show');
                  this.msg = 'NOTE: For cash payment promo code is not applicable.';
                  this.tempAmount = this.tempAmount + this.promoCodeAmnt;
                  this.promoCodeAmnt = 0.00;
                }
              } else {
                this.msg = 'Cash payment cannot exceed R1000.';
                $('#results').modal('show');
              }
            }
          } else {
            this.msg = 'Working hours are from 07:00 AM to 18:00 PM.';
            $('#results').modal('show');
          }
        } else {
          this.msg = 'Error, invalid pick up date. The date already passed.';
          $('#results').modal('show');
        }
      } else {
        this.msg = 'Please increase the time by an hour from now!.';
        $('#hourAheadValidation').modal('show');
      }
    }

  }

  public onHome() {
    this.router.navigateByUrl('/user');
  }

  public proceed() {
    this.submitButtonss.nativeElement.disabled = true;
    var token = localStorage.getItem('token');
    this.loadservice.addloader();
    this.bookingService.cash(this.createdDrop, token)
      .subscribe(
        (res: Response) => {
          this.bookingService.cashPay(res.json().oid, token)
            .subscribe(
              (response: Response) => {
                this.loadservice.removeloader();
                this.router.navigateByUrl('/user');
              },
              (err) => {
                this.loadservice.removeloader();
              }
            );
          this.loadservice.removeloader();
        },
        (error) => {
          this.loadservice.removeloader();
        }
      );
  }

  getIPAddress() {
    this.http.get("http://api.ipify.org/?format=json").subscribe(
      (res: any) => {
        this.ipAddress = res.ip;
      });
  }

  public isNumeric(val) {
    return /^-?\d+$/.test(val);
  }

  public createDrop() {

    if (!this.termsControl) {
      this.checkingMsg = 'Please click the checkbox below across the terms & conditions to proceed with the booking.';
      $('#validationModal').modal('show');
    } else if (this.contact.name === '') {
      this.checkingMsg = 'Please enter the name of the person at the pick up (Or select the small box to indicate you will be at the pick up).';
      $('#validationModal').modal('show');
    } else if (this.contact.mobile === '') {
      this.checkingMsg = 'Please enter the number of the person at the pick up (Or select the small box to indicate you will be at the pick up).';
      $('#validationModal').modal('show');
    } else if (this.comment == null) {
      this.checkingMsg = 'Please enter in the comment section below for any further comments (instructions or tips regarding your delivery).';
      $('#validationModal').modal('show');
    } else if (this.bookingDate == null) {
      this.checkingMsg = 'Please select a date to proceed with the booking.';
      $('#validationModal').modal('show');
    } else if (this.bookingTime == null) {
      this.checkingMsg = 'Please select a time to proceed with the booking.';
      $('#validationModal').modal('show');
    } else if (this.dropContact.name === '') {
      this.checkingMsg = 'Please enter the name of the person at the drop off (Or select the small box to indicate you will be at the drop off).';
      $('#validationModal').modal('show');
    } else if (this.dropContact.mobile === '') {
      this.checkingMsg = 'Please enter the number of the person at the drop off (Or select the small box to indicate you will be at the drop off).';
      $('#validationModal').modal('show');
    } else if (this.dropContact.mobile.length !== 10) {
      this.checkingMsg = 'Please enter 10 digits (no spaces) of the person at drop off (Or select the small box to indicate you will be at the drop off).';
      $('#validationModal').modal('show');
    } else if (this.contact.mobile.length !== 10) {
      this.checkingMsg = 'Please enter 10 digits (no spaces) of the person at pick up (Or select the small box to indicate you will be at the pick up).';
      $('#validationModal').modal('show');
    } else if (!this.isNumeric(this.contact.mobile)) {
      this.checkingMsg = 'Please enter numbers, not letters or special characters of the person at pick up (Or select the small box to indicate you will be at the pick up).';
      $('#validationModal').modal('show');
    } else if (!this.isNumeric(this.dropContact.mobile)) {
      this.checkingMsg = 'Please enter numbers, not letters or special characters of the person at drop off (Or select the small box to indicate you will be at the drop off).';
      $('#validationModal').modal('show');
    }
    else {
      this.paymentMsg = 'Please wait while directing to PayFast. After making payments, wait to confirm your booking before exiting.';
      var object = JSON.parse(localStorage.getItem('userData'));
      var token = localStorage.getItem('token');
      var currentDate = new Date(this.bookingDate);

      let date = currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate();
      let monthNo = (currentDate.getMonth() + 1) <= 9 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
      let hour = currentDate.getHours() <= 9 ? '0' + currentDate.getHours() : currentDate.getHours();
      let minutes = currentDate.getMinutes() <= 9 ? '0' + currentDate.getMinutes() : currentDate.getMinutes();
      var second = currentDate.getSeconds() <= 9 ? '0' + currentDate.getSeconds() : currentDate.getSeconds();
      var dropTime = currentDate.getFullYear() + '-' + monthNo + '-' + date + 'T' + hour + ':' + minutes + ':' + second;
      var dateValue = this.bookingDate;
      var datePipe = new DatePipe('en-US');
      let value = datePipe.transform(dateValue, 'yyyy-MM-dd');
      var timestamp = new Date().valueOf();
      var datePipe = new DatePipe('en-US');
      let valueTime = datePipe.transform(this.bookingTime, 'HH:mm a');
      var timeD = currentDate.getFullYear() + '-' + monthNo + '-' + date + ' ' + valueTime.split(" ")[0];
      var bookingData = {
        'dropOff': {
          'firstName': this.dropContact.name,
          'lastName': this.dropContact.surname != null ? this.dropContact.surname : object.owner.surname,
          'phone': this.dropContact.mobile,
          'email': object.owner.email
        },
        'pickUp': {
          'firstName': this.contact.name,
          'lastName': this.contact.surname != null ? this.contact.surname : object.owner.surname,
          'phone': this.contact.mobile,
          'email': object.owner.email
        },
        'customer': object.owner,
        'price': this.tempAmount,
        'vehicleType': this.addrressData.vehicleType,
        'pickUpAddress': this.addrressData.pickUpAddress.address,
        'pickUpTime': valueTime,
        'phoneNo': object.owner.mobile,
        'pickUpDate': value,
        'dropOffAddress': this.addrressData.dropOffAddress.address,
        'comment': this.comment,
        'labour': this.contact.labours,
        'timestamp': timestamp,
        'status': 'AWAITING_DRIVER',
        'pickUpCoordinate': this.addrressData.pickUpAddress,
        'dropOffCoordinate': this.addrressData.dropOffAddress,
        'load': this.load == 0 ? 1 : this.load,
        'province': this.addrressData.province != null ? this.addrressData.province : 'UNKNOWN',
        'canopy': this.canopy,
        'promotionCode': this.promoCode,
        'platform': 'web',
        "isExpress": this.isExpress,
        'transportMode': 'ROAD',
        'isItemPicked': false,
        'mainCityOid': this.mainCenter != null ? this.mainCenter.oid : null,
        'isPayed': false,
        'type': 'DEDICATED',
        'destinationProvince': this.addrressData.dropOffProvince,
        'paymentType': null,
        "customerReference": this.customerRef,
        "costCenter": this.costCentreNumber
      };

      //localStorage.setItem('bookingDataObject', JSON.stringify(bookingData));

      var drop = {
        'name': 'Booking',
        'notes': 'be careful',
        'cost': (this.tempAmount * 100),
        'clientOid': object.owner.oid,
        'vehicleOid': '1521105441081',
        'driverOid': '575ae6bd30bc5d5017477143',
        'booking': bookingData,
        'from': {
          'coordinates': [
            '-26.002483',
            '28.099503'
          ],
          'address': this.addrressData.pickUpAddress.address,
        },
        'to': {
          'coordinates': [
            '-26.002483',
            '28.099503'
          ],
          'address': this.addrressData.dropOffAddress.address,
        },
        'when': dropTime,
        'atp': dropTime,
        'eta': dropTime,
        'ata': dropTime,
        'platform': 'web',
        'droplets': [
          {
            'itemOid': '575ae6bd30bc5d5017477143',
            'notes': 'new'
          }
        ]
      };

      var realTime = new Date(dateValue);
      var currentDate = new Date();
      var dateFormat = currentDate.toLocaleString('en-us', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');
      var pickUpdateFormat = realTime.toLocaleString('en-us', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');
      var dateSame = pickUpdateFormat == dateFormat;
      var dateDiffer = pickUpdateFormat > dateFormat;
      let TimeSeparatorIndex = valueTime.indexOf(':');
      this.hour = parseInt(valueTime.substring(0, TimeSeparatorIndex));
      var timeArray = valueTime.split(' ');
      var bookingTimeArray = timeArray[0].split(':');
      this.bookingDates = new Date(datePipe.transform(dateValue, 'yyyy-MM-dd'));
      this.bookingDates.setHours(this.hour);
      this.bookingDates.setMinutes(parseInt(bookingTimeArray[1]));

      var obect = {
        'pickUpAddress': this.addrressData.pickUpAddress,
        'dropOffAddress': this.addrressData.dropOffAddress,
        'price': this.tempAmount,
        'vehicleType': this.addrressData.vehicleType
      };

      //localStorage.setItem('addressData', JSON.stringify(obect));

      var validHour = moment(this.bookingDates).isAfter(moment().add(1, 'hours'));

      if (validHour) {
        if (dateSame === true || dateDiffer === true) {
          if (this.hour >= 7 && this.hour <= 17) {
            if (this.hour < 9 || this.hour > 15) {
              this.message = 'High traffic! Please bear with our driver.';
            }
            this.goToPaymentScreen(drop);
          } else {
            this.msg = 'Working hours are from 07:00 AM to 18:00 PM.';
            $('#results').modal('show');
          }
        } else {
          this.msg = 'Error, invalid pick up date. The date already passed.';
          $('#results').modal('show');
        }
      } else {
        this.msg = 'Please increase the time by an hour!';
        $('#hourAheadValidation').modal('show');
      }
    }

  }

  public checkedTerms(terms) {
    if (terms) {
      this.termsControl = true;
    } else {
      this.termsControl = false;
    }
  }

  public openPopup() {
    var options = 'location=no,clearcache=no,toolbar=no';
    var paymentWindow = window.open('https://www.droppa.co.za/droppa/terms-and-conditions', '_blank', options);
  }

  enterPromoCode() {
    $('#promoCodeModal').modal('show');
  }

  decrementLoads() {
      if (this.load > 1) {
        this.load -= 1;
        this.onLoads(this.load);
      } else if (this.load == 1) {
        this.load = 1;
        this.onLoads(this.load);
      }
  }

  incrementLoads() {
      if (this.load >= 0) {
        this.load += 1;
        this.onLoads(this.load);
      } else {
        this.load = 0;
        this.onLoads(this.load);
      }
  }

  loadFactor(loads) {
    if (loads > 0) {
      this.onLoads(loads);
    }
  }

  public onLoads(loadNo: number) {
    if (loadNo != null) {
      if (loadNo > 0) {
        this.tempAmount = ((this.addrressData.price + (this.contact.labours * 150)) * loadNo) - this.promoCodeAmnt;
      }
    } else
      this.tempAmount = ((this.addrressData.price + (this.contact.labours * 150)) * loadNo) - this.promoCodeAmnt;
  }

  public goToPaymentScreen(data) {
    localStorage.setItem("bookingObject", JSON.stringify(data));
    this.router.navigateByUrl('/fleet-payment');
  }

}

interface Contact {
  name: string;
  surname: string;
  mobile: string;
  email: string;
  labours: number;
}

