import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AdminService } from '../../services/admin.service';
import { UserService } from '../../services/user.service';
import { DatePipe } from '@angular/common';
import {Router} from "@angular/router";
declare var $: any;


@Component({
  selector: 'app-update-vehicle',
  templateUrl: './update-vehicle.component.html',
  styleUrls: ['./update-vehicle.component.css']
})
export class UpdateVehicleComponent implements OnInit {

  vehicle: any;
  type: string;
  regNo: string;
  errorMessage: string;
  driverMobile: string;
  valid: boolean = false;
  foundDriver: any;
  err: string = "";
  assignStatus: string = "";
  canopy: any = {};
  vehiclesTypeRate: any = [];
  adminUser: any;
  drivers: any;
  driver: any;

  driverInfo = {
    deviceId: "",
    documentOid: null,
    email: "",
    emailVerified: false,
    firstName: "",
    mobile: "",
    mobileVerified: false,
    oid: "",
    retailId: null,
    rsaId: "",
    surname: ""
  };

  diskExpiryDate: string;
  selectedTabID: any;
  removeDriverMsg: string = "";

  constructor(private adminService: AdminService, private userService: UserService, private router: Router) { }

  ngOnInit() {
    this.vehicle = JSON.parse(localStorage.getItem('selectedVehicle'));
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    this.getADriverForVehicle();
    this.getVehicleTypeRates();
    this.selectedTabID = localStorage.getItem('activeTab');
    localStorage.setItem('activeID', this.selectedTabID);
  }

  public updateVehicle() {
    if (this.type != null) {
      var object = JSON.parse(localStorage.getItem('userInfo'));
      this.adminService.updateVehicle(object.token, this.vehicle.oid, this.type).subscribe(
        (res) => {
          this.errorMessage = "Vehicle updated successful.";
          $('#errorDialog').modal('show');
        },
        (error) => {
          this.errorMessage = "Error occured while updating the vehicle.";
          $('#errorDialog').modal('show');
        }
      );
    } else {
      this.errorMessage = "Please select the vehicle type.";
      $('#errorDialog').modal('show');
    }
  }

  public updateVehicleReg() {
    if (this.regNo != null) {
      var object = JSON.parse(localStorage.getItem('userInfo'));
      this.adminService.updateVehicleReg(object.token, this.vehicle.oid, this.regNo).subscribe(
        (res) => {
          this.regNo = "";
          this.errorMessage = "Vehicle updated successful.";
          $('#errorDialog').modal('show');
        },
        (error) => {
          this.errorMessage = "Error occured while updating the vehicle.";
          $('#errorDialog').modal('show');
        }
      )
    } else {
      this.errorMessage = "Please enter the vehicle registration.";
      $('#errorDialog').modal('show');
    }
  }

  public updateDiskDate() {
    var dateValue = this.diskExpiryDate;
    var realTime = new Date(dateValue);
    var diskDate = realTime.toLocaleString('en-us', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');
      var object = JSON.parse(localStorage.getItem('userInfo'));
      this.adminService.updateTDiskDate(object.token, this.vehicle.oid, diskDate).subscribe(
        (res) => {
          this.errorMessage = "Vehicle disk date updated successful.";
          $('#errorDialog').modal('show');
        },
        (error) => {
          this.errorMessage = "Error occured while updating the vehicle.";
          $('#errorDialog').modal('show');
        }
      );
  }

  findDriver() {
    this.err = "";
    this.valid = false;
    if (this.driverMobile != null) {
      var object = JSON.parse(localStorage.getItem('userInfo'));
      this.adminService.getDriverByMobile(object.token, this.driverMobile).subscribe(
        (res) => {
          this.valid = true;
          this.foundDriver = res;
          this.err = this.foundDriver.vehicle != null ? "Driver already have a vehicle." : "";
        }, (error) => {
          this.err = error.error.message;
        }
      );
    } else {
      alert("Please enter mobile numbers.");
    }
  }

  public assignNow() {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.userService.changeDriver(object.token, this.vehicle.oid, this.foundDriver.oid)
      .subscribe(
        (res: Response) => {
          if (res.status === 200) {
            this.assignStatus = "Driver successfully assigned a vehicle.";
          }
        },
        (err) => {
          this.assignStatus = err.json().message; // "Error occured, Please contact Droppa.";
        }
      );
  }

  public updateVehicleCanopy() {
    if (this.vehicle.hasCanopy) {
      this.canopy = {
        "hasCanopy": false,
        "vehicleOid": this.vehicle.oid
      };
    } else {
      this.canopy = {
        "hasCanopy": true,
        "vehicleOid": this.vehicle.oid
      };
    }
    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.userService.canopy(object.token, this.canopy).subscribe(
      (res) => {
        this.errorMessage = "The vehicle canopy updated.";
        $('#errorDialog').modal('show');
      }, (err) => {
        this.errorMessage = "Error while update the canopy";
        $('#errorDialog').modal('show');
      }
    );
  }

  public getVehicleTypeRates() {
    this.adminService.vehicleTypeRates().subscribe(
      (res) => {
        this.vehiclesTypeRate = res;
      }
    );
  }

  driverAvail: boolean = false;

  public getADriverForVehicle() {
    if (this.vehicle.driverOid != null) {
      this.adminService.getDriversForVehicle(this.adminUser.token, this.vehicle.driverOid).subscribe(
        (response: Response) => {
          this.drivers = response;
          this.driverInfo = this.drivers.person;
          if (this.driverInfo != null) {
            this.driverAvail = true;
          }
        }, (error) => {
        }, () => {
        }
      );
    } else {
      this.errorMessage = "Vehicle does not have a driver!";
    }
  }

  public removeDriver() {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.removeDriverFromVehicle(object.token, this.vehicle.driverOid).subscribe(
      response => {
        this.removeDriverMsg = "Vehicle updated successful.";
        $('#driverDialog').modal('show');
      }, error => {
        console.log(error);
      }
    );
  }

  public allVehiclesOrViewPartner() {
    const decision = localStorage.getItem('vehicleFromAllVehicles');

    if (decision !== null) {
      localStorage.removeItem('vehicleFromAllVehicles');
      this.router.navigate(['/all-vehicles']);
    } else {
      this.router.navigate(['/view-partner']);
    }
  }

}
