import { Component, ViewChild, OnInit, Inject ,isDevMode} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgForm } from '@angular/forms';
import { ReCaptchaComponent } from 'angular2-recaptcha';

import { User } from '../../models/user';
import { Login } from '../../models/login';

import { LoginService } from '../../services/login.service';
import { LoaderService } from 'src/app/services/loader.service';
declare var $: any;

@Component({
  selector: 'app-customer-signup',
  templateUrl: './customer-signup.component.html',
  styleUrls: ['./customer-signup.component.css', '../../css/style.css','../sign-in/sign-in.component.css','../customer-login/customer-login.component.css']
})
export class CustomerSignupComponent implements OnInit {

  //@ViewChild(ReCaptchaComponent) captcha: ReCaptchaComponent;
  @ViewChild('userForm', { static: true }) signupForm: NgForm;
  isDevMode=isDevMode()
  public passwordEye: string = 'glyphicon glyphicon-eye-open';
  public toggleEye: boolean = false;
  public passwordType: string = 'password';
  firstName: string = '';
  email: string = '';
  lastname: any;
  surname: string = '';
  pwd: string = '';
  phone: string = '';
  error: string = '';
  user: User;
  login: Login;
  capture: any;
  loader: boolean = false;
  captcha: any;
  isRobot=true
  UAT_SITE_KEY:String



  constructor(private loginService: LoginService,
    public dialog: MatDialog,private loadservice:LoaderService) {
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.UAT_SITE_KEY=this.loginService.SITE_KEY
  }

  showPassword() {
    this.toggleEye = !this.toggleEye;
    if (this.toggleEye) {
      this.passwordType = 'text';
      this.passwordEye = 'glyphicon glyphicon-eye-close';
    } else {
      this.passwordType = 'password';
      this.passwordEye = 'glyphicon glyphicon-eye-open';
    }

  }

  public onSubmit() {

    var recaptcha = $("#recaptcha").val();
    this.user = new User(this.firstName, this.surname, this.email.toLowerCase().trim(), this.phone);
    this.login = new Login(this.pwd, this.email.toLowerCase().trim());
    this.user.login = this.login;
    var pass = this.pwd.split('');
    //this.openDialog();
    this.loadservice.addloader();

    // console.log("___",this.user)

    if(this.isRobot&&!isDevMode){
      this.error = 'Please click the checkbox to varify you are a human.';
    }
    else if (/^[a-zA-Z ]+$/.test(this.firstName) && /^[a-zA-Z ]+$/.test(this.surname)) {
      this.loginService.registerUser(this.user)
        .subscribe(
          (response: any) => {
            // console.log(response,"<<<<<<<<<<<<<<<<<<")
            this.error = "Information captured. Please check your email to activate your account";
            this.signupForm.reset();
            this.loadservice.removeloader();
            //this.captcha.reset();
          },
          (err) => {
            console.log(err);
            this.error = "Error while registering, mobile or email already exist.";
            this.loadservice.removeloader();
            // this.captcha.reset();
            this.dialog.closeAll();
          }
        );
    } else {
      this.loadservice.removeloader();
      this.error = "Firstname or surname should not contain special characters or numbers.";
    }

  }

  refresh(): void {
    window.location.reload();
  }

  public omit_special_char(e) {
    var k;
    document.all ? k = e.keyCode : k = e.which;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }
  handleCorrectCaptcha(event) {
    if(event!=null){
      this.isRobot=false
    }else{
      this.isRobot=true

    }
  }
}
