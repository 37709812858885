import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { DatePipe } from '@angular/common';
import { BookingService } from '../../services/booking.service';
import { SharedService } from 'src/app/services/shared.service';
import { Parcel } from 'src/app/models/parcel';
import { ParcelDimensions } from 'src/app/models/parcelDimensions';
import { Input } from '@angular/core';
import { LoaderService } from 'src/app/services/loader.service';
import {NgForm} from '@angular/forms';
declare var google: any;
declare var $: any;

@Component({
  selector: 'app-user-portal',
  templateUrl: './user-portal.component.html',
  styleUrls: ['./user-portal.component.css']
})
export class UserPortalComponent implements OnInit {

  @ViewChild('m_id', { static: false }) merchant_id: ElementRef;
  @ViewChild('m_key', { static: false }) merchant_key: ElementRef;
  @ViewChild('return_url', { static: false }) return_url: ElementRef;
  @ViewChild('cancel_url', { static: false }) cancel_url: ElementRef;
  @ViewChild('notify_url', { static: false }) notify_url: ElementRef;
  @ViewChild('name_first', { static: false }) name_first: ElementRef;
  @ViewChild('name_last', { static: false }) name_last: ElementRef;
  @ViewChild('email_address', { static: false }) email_address: ElementRef;
  @ViewChild('cell_number', { static: false }) cell_number: ElementRef;
  @ViewChild('m_payment_id', { static: false }) m_payment_id: ElementRef;
  @ViewChild('amount', { static: false }) price: ElementRef;
  @ViewChild('item_name', { static: false }) item_name: ElementRef;
  @ViewChild('item_description', { static: false }) item_description: ElementRef;
  @ViewChild('custom_int1', { static: false }) custom_int1: ElementRef;
  @ViewChild('confirmation_address', { static: false }) confirmation_address: ElementRef;

  user: any;
  bookings: any = [];
  tempCourierBookings: any = [];
  tempFleetBookings: any = [];
  bookingList: any = [];
  awaitBooking: any = [];
  bucketBooking: any = [];
  retail: any;
  assignStatus: string = '';
  userMobile: string;
  retailOwner: boolean;
  retailAvailable: boolean = false;
  totalItems: number;
  rentalTotal: number;
  addressTotal: number;
  itemsPage: number = 1;
  total: number;
  page: number = 1;
  rentalPage: number = 1;
  addressPage: number = 1;
  transactionsPage: number = 1;
  lat: number = -25.990886;
  lng: number = 28.129811999999998;
  today = new Date();
  addrressData: any;
  tempAmount: number;
  loader: boolean = false;
  hour: number;
  promoStatus: string;
  terms: boolean;
  termsControl: boolean = false;
  yesterday = new Date(this.today);
  promoCode: string;
  promoCodeAmnt: number = 0.0;
  msg: string;
  message: string;
  load: number = 1;
  bookingDate: any;
  canopy: boolean = false;
  province: string;
  hourDiff: any;
  bookingDates: any;
  theBookingDate: any;
  bookingTime: any;
  dropOid: string;
  amount: number = 0;
  userDetails: boolean = false;
  showTopUpModal: boolean = false;
  slideIndex:number = 1;
  disablePickUpAddresses: boolean = true;
  displayPickUpAddress: any;
  public title: string = '';
  vehicle: string;
  sameMassParcels: boolean = false;
  numOfParcels: number;
  msgStatus = '';
  vehicleTypeRate: any = [];
  disableDropOffAddress: boolean = true;
  dropOffPCode: any;
  promptedPickUpPCode: any;
  promptedDropOffPCode: any;
  dropOffNotMsg = '';
  dropOffNotFound: boolean;
  pickUpNotFound: boolean;
  pickUpLat: any;
  pickUpLong: any;
  transportMode: any;
  isItemPicked: boolean = false;
  mainCityOid: any;
  isPayed: boolean = false;
  fromSuburb: any;
  toSuburb: any;
  pickUpPCode: any;
  pickUpProvince: any;
  dropOffProvince: string;
  promptPostalCode: any;
  displayDropOffAddress: any;
  dropOffAddress: string;
  dropOffCoordinateLocation: any = {};
  dropOffLat: number;
  dropOffLong: number;
  dropOffCoordinate = [];
  showParcels: boolean = true;
  redirectToFleetMsg: string = '';
  errorMessage: string;
  priceBox: boolean = false;
  numberOfPracels = [];
  pracel: Parcel;
  pracels: ParcelDimensions[] = [];
  destinationCoordinate: any = {};

  single_parcel_mass: any;
  single_parcel_breadth: any;
  single_parcel_height: any;
  single_parcel_length: any;
  droppaMainCities: any = [];
  distanceRadius: number = 0;
  maincityDistanceDash: number = 0;
  totalMass: any = 0;
  totalLength: any = 0;
  totalBreadth: any = 0;
  totalHeight: any = 0;
  breadthMsg: string = '';
  lengthMsg: string = '';
  heightMsg: string = '';
  massMsg: string = '';
  checkingMsg: string = '';
  enteredPickUpAddress: any;
  enteredPickUpSuburb: any;
  enteredPickUpPostalCode: any;
  enteredPickUpProvince: any;
  enteredDropOffAddress: any;
  enteredDropOffSuburb: any;
  enteredDropOffPostalCode: any;
  enteredDropOffProvince: any;
  pickUpExtraDetails: any = [];
  enteredPickUpAddressMsg: string = '';
  pickUpCoordinateLocation: any = {};
  enteredPickUpSuburbMsg: string = '';
  enteredPickUpSuccessMsg: string = '';
  enteredPickUpPostalCodeMsg: string = '';
  enteredPickUpProvinceMsg: string = '';
  termsMsg: string;
  metricTotal: number = 0;
  overload: boolean = false;
  dateObj: any;
  usedSavedAddress: boolean = false;
  isExpressBudget: boolean = true;
  isCourierExpress: boolean = true;
  expressCourierMode: any;
  budgetCourierMode: any;
  expressCourier: number = 0;
  budgetCourier: number = 0;
  isBudget: boolean = false;
  isCourier: boolean = false;
  isRoad: boolean = false;
  isFlight: boolean = false;
  isDash: boolean = false;
  isTender: boolean = false;
  tenderDocumentSelected: boolean = false;
  tenderDocument: number = 0;
  distance: number;
  vehicleType: any;
  flashFlight: number = 0;
  flashRoad: number = 0;
  sheshaMode: number = 0;
  flightAvailable: boolean = false;
  flashRoadAvailable: boolean = false;
  sheshaAvailable: boolean = true;
  pickUpAmt: number = 0.0;
  airlineAmt: number = 0.0;
  dropOffAmt: number = 0.0;
  type: any;
  dropOffExtraDetails: any = [];
  enteredDropOffAddressMsg: string = '';
  enteredDropOffSuburbMsg: string = '';
  enteredDropOffPostalCodeMsg: string = '';
  enteredDropOffProvinceMsg: string = '';
  enteredDropOffSuccessMsg: string = '';
  selectedPickUpSuburb: any;
  dashVehiceType: any;
  selectedVehicleT: any;

  viewUserTransactions = false;
  history: any = [];

  amountType: any = [
    {
      amount: 500
    },
    {
      amount: 1000
    },
    {
      amount: 2000
    },
    {
      amount: 5000
    },
    {
      amount: 10000
    }
  ];

  amountChosen: number = 0;
  currentAmt: any = 0;
  walletOID: any;
  payFastResponse: any;

  url: string;
  ozowUrl: string;
  rentalBookings: any = [];
  savedAddresses: any = [];
  headingStatus: string = 'Booking History';
  capturePickUpAddressLabel: any;
  pickUpAddress: string;
  checkingPickUpMsg: string = '';

  public userSettings2: any = {
    showRecentSearch: false,
    geoCountryRestriction: ['ZA'],
    inputPlaceholderText: 'Enter your street address.',
    geoTypes: ['address', 'establishment']
  };

  public userSettings: any = {
    showRecentSearch: true,
    geoCountryRestriction: ['ZA'],
    inputPlaceholderText: 'Enter drop-off street address.',
    geoTypes: ['address', 'establishment']
  };

  validateProvince: any;
  validatePickuProvince: string;
  addressComponent: any = [];
  postalCode: any;
  pickUpNotMsg: string;
  pickUpCoordinate: any = [];
  departureCoordinate: any = {};
  displayManualAddress: boolean = false;
  suburb: any;
  suburbList: any = [];
  alertMessage

  // Payfast
  @ViewChild('m_id_rental', { static: false }) merchant_id_rental: ElementRef;
  @ViewChild('m_key_rental', { static: false }) merchant_key_rental: ElementRef;
  @ViewChild('return_url_rental', { static: false }) return_url_rental: ElementRef;
  @ViewChild('cancel_url_rental', { static: false }) cancel_url_rental: ElementRef;
  @ViewChild('notify_url_rental', { static: false }) notify_url_rental: ElementRef;
  @ViewChild('name_first_rental', { static: false }) name_first_rental: ElementRef;
  @ViewChild('name_last_rental', { static: false }) name_last_rental: ElementRef;
  @ViewChild('email_address_rental', { static: false }) email_address_rental: ElementRef;
  @ViewChild('cell_number_rental', { static: false }) cell_number_rental: ElementRef;
  @ViewChild('m_payment_id_rental', { static: false }) m_payment_id_rental: ElementRef;
  @ViewChild('amount_rental', { static: false }) price_rental: ElementRef;
  @ViewChild('item_name_rental', { static: false }) item_name_rental: ElementRef;
  @ViewChild('item_description_rental', { static: false }) item_description_rental: ElementRef;
  @ViewChild('custom_int1_rental', { static: false }) custom_int1_rental: ElementRef;
  @ViewChild('confirmation_address_rental', { static: false }) confirmation_address_rental: ElementRef;

  // Ozow
  @ViewChild('SiteCode', { static: false}) SiteCode: ElementRef;
  @ViewChild('CountryCode', { static: false}) CountryCode: ElementRef;
  @ViewChild('CurrencyCode', { static: false}) CurrencyCode: ElementRef;
  @ViewChild('Amount', { static: false}) Amount: ElementRef;
  @ViewChild('TransactionReference', { static: false}) TransactionReference: ElementRef;
  @ViewChild('BankReference', { static: false}) BankReference: ElementRef;
  @ViewChild('Customer', { static: false }) Customer: ElementRef;
  @ViewChild('Optional1', { static: false }) Optional1: ElementRef;
  @ViewChild('CancelUrl', { static: false}) CancelUrl: ElementRef;
  @ViewChild('ErrorUrl', { static: false}) ErrorUrl: ElementRef;
  @ViewChild('SuccessUrl', { static: false}) SuccessUrl: ElementRef;
  @ViewChild('NotifyUrl', { static: false}) NotifyUrl: ElementRef;
  @ViewChild('IsTest', { static: false}) IsTest: ElementRef;
  @ViewChild('HashCheck', { static: false}) HashCheck: ElementRef;

  @ViewChild('paymentForm', { static: true }) paymentForm: NgForm;
  url_rental: any;
  service: string;
  waybillDownloadLink;

  showOwnAmountInput: boolean = false;

  constructor(private nameService: SharedService, private userService: UserService, private route: Router,
              private bookingService: BookingService, private router: Router, private sharedServices: SharedService,
              private loadservice: LoaderService) {
    this.url = this.nameService.getPayFastUrl();
    this.url_rental = this.nameService.getPayFastUrl();
    this.service = sharedServices.getUrl();
  }

  @Input() selectedPracel = {};

  atSelectAmount = false;
  showSelectWalletAmountError = false;

  ngOnInit() {
    window.scroll(0, 0);
    // Use to show public announcements on login
    this.ozowUrl = this.sharedServices.getOzowUrl();
    this.user = JSON.parse(localStorage.getItem('userInfo'));
    this.button();
    this.getAllBookings();
    this.retrieveWalletBalance();
    this.retailOwner = this.user.owner.oid === this.user.owner.retailId;
    // this.openCity(this, "User Details")
    // this.showSlides(this.slideIndex);
    $('#parcelModal').modal('show');
    this.waybillDownloadLink = this.service + 'plugins/waybill/download/pdf/';
    // console.log(this.waybillDownloadLink);
    this.getAlertMessage()
  }
  public getAlertMessage(){
    this.loadservice.addloader()
    this.userService.getAlertMessage(this.user.token,this.user.oid)
    .subscribe((res)=>{

      this.alertMessage=res.json()
      console.log(res.json());

      if(this.alertMessage&&this.alertMessage.message&&!this.alertMessage.seen){
        $("#alertmessage").modal("show")
      }
      this.loadservice.removeloader()
    },(error)=>{
      console.log(error);
      this.loadservice.removeloader()
    },()=>{
      this.loadservice.removeloader()
    })
  }

  autoCompleteCallback1(selectedData: any) {
    this.postalCode = null;
    //this.promptedPickUpPCode = null;
    if (!selectedData) {
      this.pickUpNotMsg = 'Location not found, Please enter nearest location.';
      //this.pickUpNotFound = false;
    } else {
      for (let x = 0; x < selectedData.data.address_components.length; x++) {
        for (let y = 0; y < selectedData.data.address_components[x].types.length; y++) {
          for (let a = 0; a < selectedData.data.address_components[x].types.length; a++) {
            if (selectedData.data.address_components[x].types[a] == 'postal_code') {
              this.postalCode = selectedData.data.address_components[x].long_name;
              this.getPickUpSuburb(this.postalCode);
            }
            else if (selectedData.data.address_components[x].types[a] == 'administrative_area_level_1') {
              this.pickUpProvince = selectedData.data.address_components[x].long_name;
            }
          }
        }
      }
      if (this.postalCode == null) {
        this.pickUpPostalCodeService(selectedData);
      }
      this.pickUpAddress = selectedData.data.description;
      this.pickUpLat = selectedData.data.geometry.location.lat;
      this.pickUpLong = selectedData.data.geometry.location.lng;
      this.pickUpCoordinate = [selectedData.data.geometry.location.lat, selectedData.data.geometry.location.lng];
      this.pickUpCoordinateLocation = { coordinates: this.pickUpCoordinate, address: this.pickUpAddress };
      this.departureCoordinate = { "lat": selectedData.data.geometry.location.lat, "lng": selectedData.data.geometry.location.lng };
      this.getAdminArea(selectedData.data.address_components);
    }
  }

  public pickUpPostalCodeService(selectedData) {
    let latTest = selectedData.data.geometry.location.lat;
    let lngTest = selectedData.data.geometry.location.lng;

    let geocoder = new google.maps.Geocoder;
    const latlng = {
      lat: latTest,
      lng: lngTest,
    };
    geocoder.geocode(
      { location: latlng }, (results) => {
        if (results[0]) {
          let foundAddress = results[0].address_components;

          for (let x = 0; x < foundAddress.length; x++) {
            for (let y = 0; y < foundAddress[x].types.length; y++) {
              for (let a = 0; a < foundAddress[x].types.length; a++) {
                if (foundAddress[x].types[a] == 'postal_code') {
                  this.postalCode = foundAddress[x].long_name;
                  this.getPickUpSuburb(this.postalCode);
                }
              }
            }
          }

          if (this.postalCode == null) {
            //this.promptPostalCode = "Please enter your area postal code below";
            $('#pickUpPostcalCode').modal('show');
          }

        }
      }
    );
  }

  public getPickUpSuburb(postcalCode) {
    this.bookingService.getPickUpSuburb(postcalCode).subscribe(
      response => {
        this.suburb = response.suburb;
      }, error => {
        console.log(error);
      }, () => {
      }
    )
  }

  private getAdminArea(address_component: []) {

    this.addressComponent = address_component;
    let len = address_component.length;

    for (let i = 0; i < len; i++) {
      switch (this.addressComponent[i].long_name) {
        case 'Gauteng': {
          this.province = 'GAUTENG';
          this.validateProvince = 'Gauteng';
          break;
        }
        case 'KwaZulu-Natal': {
          this.province = 'KWAZULU-NATAL';
          this.validateProvince = 'KwaZulu-Natal';
          break;
        }
        case 'kzn': {
          this.province = 'kzn';
          this.validateProvince = 'KWA_ZULU_NATAL';
          break;
        }
        case 'Western Cape': {
          this.province = 'WESTERN CAPE';
          this.validateProvince = 'Western Cape';
          break;
        }
        case 'Eastern Cape': {
          this.province = 'EASTERN CAPE';
          this.validateProvince = 'Eastern Cape';
          break;
        }
        case 'Limpopo': {
          this.province = 'LIMPOPO';
          this.validateProvince = 'Limpopo';
          break;
        }
        case 'Mpumalanga': {
          this.province = 'MPUMALANGA';
          this.validateProvince = 'Mpumalanga';
          break;
        }
        case 'Northern Cape': {
          this.province = 'NORTHERN CAPE';
          this.validateProvince = 'Northern Cape';
          break;
        }
        case 'North West': {
          this.province = 'NORTH WEST';
          this.validateProvince = 'North West';
          break;
        }
        case 'Free State': {
          this.province = 'FREE STATE';
          this.validateProvince = 'Free State';
          break;
        }
        default: {

          break;
        }

      }
    }
  }

  public proceedWithElite() {
    $('#elite').modal('show');
  }

  public proceedWithFleet() {
    this.router.navigateByUrl('/Droppa-Fleet-Service');
  }

  public proceedWithCourier() {
    this.router.navigateByUrl('/Courier-Service');
  }

  // Displays the manual address column
  public enterManualAddress() {
    this.displayManualAddress = true;
  }

  public enterManualAddress2() {
    $('#addressPopUp2').modal('show');
  }

  // Brings back the Google address component
  public backToGoogleAddress() {
    this.displayManualAddress = false;
  }

  public getListOfSuburbs(postalCode) {
    this.postalCode = postalCode;
    if (this.postalCode != null || this.postalCode != undefined) {
      this.bookingService.postalForHomeDetails(this.postalCode).subscribe(
        response => {
          this.suburbList = response;
          if (this.suburbList.length == 0) {
            this.enteredPickUpPostalCodeMsg = 'Invalid postal code, enter a valid postal code to select a suburb.';
          }
          else {
            this.enteredPickUpPostalCodeMsg = '';
          }
        }, error => {
          console.log(error);
        }
      );
    }
    else {
      this.suburbList = [];
    }
  }

  public capturePickUpAddress() {
    $('#capturePickUpAddress').modal('show');
  }

  public savePickUpAdddress() {

    // If its displaying manual addresses
    if (this.displayManualAddress) {

      var addressData = {
        "label": this.capturePickUpAddressLabel,
        "latitude": this.pickUpLat,
        "longitude": this.pickUpLong,
        "address": this.pickUpAddress,
        "personId": this.user.oid,
        "suburb": this.suburb,
        "province": this.province,
        "postalCode": this.postalCode
      }

      if (addressData.address == null || addressData.address == '') {
        this.checkingPickUpMsg = 'Please enter your address to proceed.';
      }
      else if (addressData.label == null || addressData.label == '') {
        this.checkingPickUpMsg = 'Please enter the label of your address.';
      }
      else if (addressData.suburb == null || addressData.suburb == '') {
        this.checkingPickUpMsg = 'Please enter the name of your suburb.';
      }
      else if (addressData.province == null || addressData.province == '') {
        this.checkingPickUpMsg = 'Please enter the province.';
      }
      else if (addressData.postalCode == null || addressData.postalCode == '') {
        this.checkingPickUpMsg = 'Please enter the postal code.';
      }
      else if (addressData.address != null && addressData.label != null && addressData.suburb != null && addressData.province != null && addressData.postalCode != null) {
        this.bookingService.storeAddresses(this.user.token, addressData).subscribe(
          response => {
            this.getSavedAddress();
            this.checkingPickUpMsg = 'Pick Up address successfully saved.';
          }, error => {
            console.log(error);
          }
        );
      }
      else {
        this.checkingPickUpMsg = 'Please enter the label or the capture the address first before saving it.';
      }

    }
    // If its NOT displaying manual addresses
    else {

      var addressData = {
        "label": this.capturePickUpAddressLabel,
        "latitude": this.pickUpLat,
        "longitude": this.pickUpLong,
        "address": this.pickUpAddress,
        "personId": this.user.oid,
        "suburb": this.suburb,
        "province": this.province,
        "postalCode": this.postalCode
      }

      if (addressData.address == null || addressData.address == '') {
        this.checkingPickUpMsg = 'Please enter your address to proceed.';
      }
      else if (addressData.label == null || addressData.label == '') {
        this.checkingPickUpMsg = 'Please enter the label of your address.';
      }
      else if (addressData.address != null && addressData.label != null && addressData.suburb != null && addressData.province != null && addressData.postalCode != null) {
        this.bookingService.storeAddresses(this.user.token, addressData).subscribe(
          response => {
            this.getSavedAddress();
            this.checkingPickUpMsg = 'Pick Up address successfully saved.';

          }, error => {
            console.log(error);
          }
        );
      }
      else {
        this.checkingPickUpMsg = 'Please enter the label or the capture the address first before saving it.';
      }

    }

  }

  public getSavedAddress() {
    this.headingStatus = 'Saved Addresses';
    this.bookingService.retrieveAddresses(this.user.token, this.user.oid).subscribe(
      response => {
        this.savedAddresses = response;
      }, error => {
        console.log(error);
      }, () => {

      }
    )
  }

  public getAllBookings() {
    this.headingStatus = 'Booking History';
    this.userService.retriveBooking(this.user.token, this.user.owner.oid).subscribe(
      (response: any) => {
        if (response.status === 200) {
          this.bookingList = response.json().reverse();
          this.bookingList.forEach(book => {
            if (book.vehicleType === 'DROPPA_EXPRESS' || book.vehicleType === 'DASH') {
              // courier
              this.bookings.push(book);
            } else {
              // fleet
              this.awaitBooking.push(book);
            }
          });
          // This tempCourierBookings is used with filtering
          this.tempCourierBookings = this.bookings;
          this.tempFleetBookings = this.awaitBooking;
        }
      },
      (err) => {
        console.log(err);
        if (err.statusText === 'Unknown Error' || err.statusText === 'Internal Server Error') {
          this.route.navigate(['/customer-signin']);
        }
      }
    );
  }

  courierSearchQuery = '';

  public filterCourierBookings() {
    this.bookings = this.tempCourierBookings;
    if (this.courierSearchQuery !== '') {
      this.bookings = this.bookings.filter((book) => book.trackNo.toLowerCase().includes(this.courierSearchQuery.toLowerCase()));
    } else {
      this.bookings = this.tempCourierBookings;
    }
  }

  fleetSearchQuery = '';

  public filterFleetBookings() {
    this.awaitBooking = this.tempFleetBookings;
    if (this.courierSearchQuery !== '') {
      this.awaitBooking = this.awaitBooking.filter((book) => book.trackNo.toLowerCase().includes(this.courierSearchQuery.toLowerCase()));
    } else {
      this.awaitBooking = this.tempCourierBookings;
    }
  }

  public viewElite(rentals) {
    localStorage.setItem('selectedRental', JSON.stringify(rentals));
    this.route.navigate(['/view-rental-details']);
  }

  public getAllRentals() {
    this.headingStatus = 'Rental History';
    this.bookingService.getEliteBookingByCustomer(this.user.token, this.user.owner.oid).subscribe(
      response => {
        this.rentalBookings = response.reverse();
      }, error => {
        console.log(error);
        if (error.statusText === 'Unknown Error' || error.statusText === 'Internal Server Error') {
          this.route.navigate(['/customer-signin']);
        }
      }, () => {
      }
    )
  }

  public onBooking(book) {
    localStorage.setItem('selectedBooking', JSON.stringify(book));
    this.route.navigate(['/view-bookings']);
  }

  public onBookingAwaiting(booking) {
    localStorage.setItem('selectedBooking', JSON.stringify(booking));
    this.route.navigate(['/view-bookings']);
  }

  public trackDriver(trackNo) {
    this.route.navigate(['/Track-Driver/' + trackNo]);
  }

  public navigate(booking) {
    localStorage.setItem('selectedBooking', JSON.stringify(booking));
    this.route.navigate(['/edit-booking']);
  }

  public book() {
    if (this.retail == null) {
      this.route.navigate(['/']);
    } else {
      localStorage.setItem('retail', JSON.stringify(this.retail));
      this.route.navigate(['/retail-booking']);
    }
  }

  public typeRate() {
    localStorage.setItem('retail', JSON.stringify(this.retail));
    this.route.navigate(['/retail-typeRate']);
  }

  public promocode() {
    localStorage.setItem('userInfo', JSON.stringify(this.user));
    this.route.navigate(['/retail-promo']);
  }

  public getRetails() {
    this.user = JSON.parse(localStorage.getItem('userInfo'));
    if (this.user.owner.retailId != null) {
      this.userService.retriveRetail(this.user.token, this.user.owner.retailId).subscribe(
        (res) => {
          this.retail = res.json();
          this.getBucketBookings();
        }
      );
    } else {
      this.user.roles.forEach(role => {
        if (role.code === 'retail') {
          this.retailAvailable = true;
        }
      });
    }
  }

  public getBucketBookings() {
    this.userService.getBucketBooking(this.user.token, this.retail.oid).subscribe(
      (res) => {
        this.bucketBooking = res;
      }, err => {
        console.log(err);
      }
    );
  }

  public assignUser() {
    this.user = JSON.parse(localStorage.getItem('userInfo'));
    this.userService.assignNow(this.user.token, this.userMobile, this.user.owner.retailId).subscribe(
      (res) => {
        this.assignStatus = res.firstName + ' ' + res.surname + ' added to retail';
      }, (error) => {
        console.log(error);
        this.assignStatus = error.error.message;
      }
    );
  }

  public addRetail() {
    this.route.navigate(['/add-retail']);
  }

  public onBucketSelected(bucket) {
    localStorage.setItem('selectedRetailBucketBooking', JSON.stringify(bucket));
    localStorage.setItem('retail', JSON.stringify(this.retail));
    this.route.navigate(['/bucket-booking-review']);
  }

  public redirectToPayFast(response) {
    localStorage.setItem('paidByRetail', 'false');
    this.merchant_id.nativeElement.value = response.merchant_id;
    this.merchant_key.nativeElement.value = response.merchant_key;
    this.return_url.nativeElement.value = response.return_url;
    this.cancel_url.nativeElement.value = response.cancel_url;
    this.notify_url.nativeElement.value = response.notify_url;
    this.name_first.nativeElement.value = response.name_first;
    this.name_last.nativeElement.value = response.name_last;
    this.email_address.nativeElement.value = response.email_address;
    this.cell_number.nativeElement.value = response.cell_number;
    this.m_payment_id.nativeElement.value = response.m_payment_id;
    this.price.nativeElement.value = response.amount;
    this.item_name.nativeElement.value = response.item_name;
    this.item_description.nativeElement.value = response.item_description;
    this.custom_int1.nativeElement.value = response.custom_int1;
    this.confirmation_address.nativeElement.value = response.confirmation_address;
    $("#btnMakePayment1").trigger("click");
  }

  public ozowRedirect(ozowBody): void {
    localStorage.setItem('paidByRetail', 'false');
    this.SiteCode.nativeElement.value = ozowBody.SiteCode;
    this.CountryCode.nativeElement.value = ozowBody.CountryCode;
    this.CurrencyCode.nativeElement.value = ozowBody.CurrencyCode;
    this.Amount.nativeElement.value = ozowBody.Amount.toFixed(2);
    this.TransactionReference.nativeElement.value = ozowBody.TransactionReference;
    this.BankReference.nativeElement.value = ozowBody.BankReference;
    this.Customer.nativeElement.value = ozowBody.Customer;
    this.Optional1.nativeElement.value = ozowBody.Optional1;
    this.CancelUrl.nativeElement.value = ozowBody.CancelUrl;
    this.ErrorUrl.nativeElement.value = ozowBody.ErrorUrl;
    this.SuccessUrl.nativeElement.value = ozowBody.SuccessUrl;
    this.NotifyUrl.nativeElement.value = ozowBody.NotifyUrl;
    this.IsTest.nativeElement.value = ozowBody.IsTest;
    this.HashCheck.nativeElement.value = ozowBody.HashCheck;
    $("#ozowPayment").trigger("click");
  }

  public reCreateDrop(book) {
    var currentDate = new Date();
    var dateValue = book.pickUpDate;
    var bookingCost = book.price;
    var datePipe = new DatePipe('en-US');
    var realTime = new Date(dateValue);
    var dateFormat = currentDate.toLocaleString('en-us', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');
    var pickUpdateFormat = realTime.toLocaleString('en-us', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');
    var dateSame = pickUpdateFormat == dateFormat;
    var dateDiffer = pickUpdateFormat > dateFormat;
    localStorage.setItem('bookingDataObject', JSON.stringify(book));
    this.bookingDates = new Date(datePipe.transform(dateValue, 'yyyy-MM-dd'));

    this.bookingDates.setHours(this.hour);
    this.dropOffCoordinateLocation = {
      'coordinates': book.dropOffCoordinate.coordinates,
      'address': book.dropOffAddress
    };

    this.pickUpCoordinateLocation = {
      'coordinates': book.pickUpCoordinate.coordinates,
      'address': book.pickUpAddress
    };

    var obect = {
      'pickUpAddress': this.pickUpCoordinateLocation,
      'dropOffAddress': this.dropOffCoordinateLocation,
      'price': book.price,
      'vehicleType': book.vehicleType
    };

    localStorage.setItem('addressData', JSON.stringify(obect));
    localStorage.setItem('token', this.user.token);

    if (dateSame == true || dateDiffer == true) {
      if (this.hour < 9 || this.hour > 15) {
        this.message = 'High traffic! Please bear with our driver.';
      }
      this.loadservice.removeloader();
      /*this.bookingService.reCreateDrop(book.dropOid, this.user.token)
        .subscribe(
          (res: any) => {
            console.log('My book', book);
            var paymentData = {
              "name_first": this.user.owner.firstName,
              "name_last": this.user.owner.surname,
              "email_address": this.user.owner.email,
              "cell_number": this.user.owner.mobile,
              "m_payment_id": res.json().oid,
              "amount": bookingCost,
              "customerReference": this.user.owner.oid,
              "item_name": 'Droppa Booking',
              "item_description": 'Payment For Delivery',
              "email_confirmation": 0,
              "confirmation_address": this.user.owner.email,
              "reference": res.json().oid,
              "paymentType": "BOOKING_PAYMENT"
            };
            this.bookingService.requestPayfastPayment(paymentData, this.user.token)
              .subscribe(
                (response) => {
                  this.payFastResponse = response.json();
                  this.redirectToPayFast(response.json());
                  this.loadservice.r();
                },
                (err) => {
                  // console.log(err);
                  this.loadservice.r();
                }
              );
          },
          (error) => {
            // console.log(error);
            this.loadservice.r();
          }
        );*/
    } else {
      this.msg = 'Error, invalid pick up date. The date already passed.';
      $('#results').modal('show');
    }
  }

  public recreateRental(rentals) {
    let payObj = {
      "from": {
        "coordinates": ["-25.8770951", "28.1787793"],
        "address": rentals.from.address
      },
      "branchOid": rentals.branchDTO.oid,
      "in": rentals.in,
      "out": rentals.out,
      "rateUnit": rentals.rateUnit,
      "category": rentals.category,
      "assistance": rentals.assistance,
      "includedDistance": rentals.includedDistance,
      "comments": rentals.comments,
      "customerOid": this.user.owner.oid,
      "total": rentals.total,
      "platform": 'web'
    }
    this.loadservice.addloader();
    this.bookingService.saveEliteVehicles(this.user.token, payObj).subscribe(response => {
      this.loadservice.removeloader();
      var paymentData = {
        "name_first": this.user.owner.firstName,
        "name_last": this.user.owner.surname,
        "email_address": this.user.owner.email,
        "cell_number": this.user.owner.mobile,
        "m_payment_id": response.oid,
        "amount": rentals.total,
        "customerReference": this.user.owner.oid,
        "item_name": 'Elite Rental',
        "item_description": 'Payment For Rental Truck',
        "email_confirmation": 0,
        "confirmation_address": this.user.owner.email,
        "reference": response.oid,
        "paymentType": "RENTAL_PAYMENT"
      };
      this.bookingService.requestPayfastPayment(paymentData, this.user.token).subscribe(response => {
        this.loadservice.removeloader();
        this.rentalPayFast(response.json());
      }, error => {
        this.loadservice.removeloader();
        console.log(error);
      }, () => {
        this.loadservice.removeloader();
      }
      )
    })

  }

  public rentalPayFast(response) {
    this.merchant_id_rental.nativeElement.value = response.merchant_id;
    this.merchant_key_rental.nativeElement.value = response.merchant_key;
    this.return_url_rental.nativeElement.value = response.return_url;
    this.cancel_url_rental.nativeElement.value = response.cancel_url;
    this.notify_url_rental.nativeElement.value = response.notify_url;
    this.name_first_rental.nativeElement.value = response.name_first;
    this.name_last_rental.nativeElement.value = response.name_last;
    this.email_address_rental.nativeElement.value = response.email_address;
    this.cell_number_rental.nativeElement.value = response.cell_number;
    this.m_payment_id_rental.nativeElement.value = response.m_payment_id;
    this.price_rental.nativeElement.value = response.amount;
    this.item_name_rental.nativeElement.value = response.item_name;
    this.item_description_rental.nativeElement.value = response.item_description;
    this.custom_int1_rental.nativeElement.value = response.custom_int1;
    this.confirmation_address_rental.nativeElement.value = response.confirmation_address;
    $("#btnRentalPay").trigger("click");
  }

  public viewTransactions() {
    // this.route.navigate(['/user-transaction-history']);
    localStorage.setItem('walletID', this.walletOID);
    this.viewUserTransactions = true;
    this.retrieveTransaction();
  }

  public loadWallet(paymentType: string) {
    this.loadservice.addloader();
    if (this.amountChosen !== 0) {
      var loadWallet = {
        "userAccountID": this.user.oid,
        "rechargeAmt": this.amountChosen
      }
      this.userService.loadWalletService(this.user.token, loadWallet).subscribe(
        response => {
          this.loadservice.addloader();
          if (paymentType === 'payfast') {
            var paymentData = {
              "name_first": this.user.owner.firstName,
              "name_last": this.user.owner.surname,
              "email_address": this.user.owner.email,
              "cell_number": this.user.owner.mobile,
              "m_payment_id": response.oid,
              "amount": this.amountChosen,
              "customerReference": this.user.owner.oid,
              "item_name": 'Droppa Booking',
              "item_description": 'Payment For Delivery',
              "email_confirmation": 0,
              "confirmation_address": this.user.owner.email,
              "reference": response.oid,
              "paymentType": "WALLET_PAYMENT"
            };


            this.bookingService.requestPayfastPayment(paymentData, this.user.token)
              .subscribe(
                (res) => {
                  this.payFastResponse = res.json();
                  this.redirectToPayFast(res.json());
                  this.loadservice.removeloader();
                },
                (err) => {
                  // console.log(err);
                  this.loadservice.removeloader();
                }
              );
          } else {
            // ozow
            let ozowObj = {
              "CountryCode": "ZA",
              "CurrencyCode": "ZAR",
              "Amount": this.amountChosen,
              "TransactionReference": response.oid,
              "BankReference": "",
              "Customer": this.user.owner.email,
              "IsTest": true,
              "paymentType": "WALLET_PAYMENT"
            };
            this.bookingService.requestOzowPayment(ozowObj, this.user.token).subscribe(
              (resp) => {
                // console.log(resp);
                this.loadservice.removeloader();
                this.ozowRedirect(resp.json());
            }, (errr) => {
              console.log(errr);
              this.loadservice.removeloader();
            });
          }
        }, error => {
          console.log(error);
          this.loadservice.removeloader();
        }
      );
    } else {
      this.loadservice.removeloader();
      this.message = 'Please choose an amount to credit your wallet.';
      // $('#emptyWallet').modal('show');
    }
  }

  payWithOzow() {

  }

  public retrieveWalletBalance() {
    this.userService.retrieveCurrentBalance(this.user.token, this.user.oid).subscribe(
      response => {
        // console.log(response);

        if (response.json().amountAmt != 0) {
          this.currentAmt = response.json().amount;
        }
        this.walletOID = response.json().oid;
      }, error => {
        console.log(error);
      }
    );
  }

  public openCity(evt, cityName) {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(cityName).style.display = "block";
    evt.currentTarget.className += " active";
  }

  /*public onItemChange(item) {
    this.amountChosen = item.amount;
  }*/

  public onItemChange(item) {
    this.showOwnAmountInput = false;
    this.amountChosen = item.amount;
  }

  public ownAmountTrigger() {
    this.showOwnAmountInput = true
  }

  public button() {
    var header = document.getElementById("myTab");
    var btns = header.getElementsByClassName("tabs");
    for (var i = 0; i < btns.length; i++) {
      btns[i].addEventListener("click", function () {
        var current = document.getElementsByClassName("activee");
        current[0].className = current[0].className.replace(" activee", "");
        this.className += " activee";
      });
    }
  }

  userEmail: string = '';
  emailMsg: string = '';

  public updateEmail() {
    if (this.userEmail == '' || this.userEmail == undefined) {
      this.emailMsg = 'Please enter email before proceeding';
    }
    else {
      let email = {
        "email": this.userEmail
      }
      this.userService.changeUserEmail(this.user.token, this.user.oid, email).subscribe(
        response => {
          //console.log(response);
          //localStorage.setItem("userInfo", JSON.stringify(response));
          this.emailMsg = 'Please check your new email address to proceed with registration.';
        },
        error => {
          console.log(error);
        }
      )
    }
  }

  mobileMsg: string = '';

  public updateMobile() {
    if (this.userMobile.length != 10 || this.userMobile == undefined || this.userMobile == '') {
      this.mobileMsg = 'Please enter the full 10 digits';
    }
    else {
      this.userService.confirmUserNumber(this.user.token, this.user.owner.oid, this.userMobile).subscribe(
        response => {
          //console.log(response);
          localStorage.setItem("userMobile", this.userMobile);
          this.route.navigateByUrl('/confirm-otp');
        }, error => {
          console.log(error);
        }
      )
    }
  }

  public openTopUpModal() {
    this.showTopUpModal = true;
  }

  public showSlides(n) {
    let i;
    let slides = document.getElementsByClassName("mySlides");
    let dots = document.getElementsByClassName("dot");
    if (n > slides.length) {this.slideIndex = 1}
    if (n < 1) {this.slideIndex = slides.length}
    for (i = 0; i < slides.length; i++) {
      slides[i]["style"]["display"] = "none";
    }
    for (i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace("activeDot", "");
    }
    slides[`${this.slideIndex-1}`]["style"]["display"] = "block";
    dots[this.slideIndex-1].className += " activeDot";
  }

  public plusSlides(n) {
    console.log('prev index: ',n)
    this.showSlides(this.slideIndex += n);
  }

  public currentSlide(n) {
    console.log('current index: ',n)
    this.showSlides(this.slideIndex = n);
  }

  public selectPickUpSavedAddress() {
    $('#showPickUpSavedAddressModal').modal('show');
  }

  autoCompleteCallback2(selectedData: any) {
    this.dropOffPCode = null;
    this.promptedDropOffPCode = null;
    if (!selectedData) {
      this.dropOffNotMsg = 'Location not found, Please enter nearest location.';
      this.dropOffNotFound = false;
    } else {
      for (let x = 0; x < selectedData.data.address_components.length; x++) {
        for (let y = 0; y < selectedData.data.address_components[x].types.length; y++) {
          for (let a = 0; a < selectedData.data.address_components[x].types.length; a++) {
            if (selectedData.data.address_components[x].types[a] == 'postal_code') {
              this.dropOffPCode = selectedData.data.address_components[x].long_name;
              this.getDropOffSuburb(this.dropOffPCode);
            }
            else if (selectedData.data.address_components[x].types[a] == 'administrative_area_level_1') {
              this.dropOffProvince = selectedData.data.address_components[x].long_name;
            }
          }
        }
      }
      if (this.dropOffPCode == null) {
        this.dropOffPostalCodeService(selectedData);
      }
      this.dropOffAddress = selectedData.data.description;
      this.dropOffLat = selectedData.data.geometry.location.lat;
      this.dropOffLong = selectedData.data.geometry.location.lng;
      this.dropOffCoordinate = [selectedData.data.geometry.location.lat, selectedData.data.geometry.location.lng];
      this.dropOffCoordinateLocation = { coordinates: this.dropOffCoordinate, address: this.dropOffAddress };
      this.destinationCoordinate = { "lat": selectedData.data.geometry.location.lat, "lng": selectedData.data.geometry.location.lng };
    }
  }

  public dropOffPostalCodeService(selectedData) {
    let latTest = selectedData.data.geometry.location.lat;
    let lngTest = selectedData.data.geometry.location.lng;

    let geocoder = new google.maps.Geocoder;
    const latlng = {
      lat: latTest,
      lng: lngTest,
    };
    geocoder.geocode(
      { location: latlng }, (results) => {
        if (results[0]) {
          let foundAddress = results[0].address_components;

          for (let x = 0; x < foundAddress.length; x++) {
            for (let y = 0; y < foundAddress[x].types.length; y++) {
              for (let a = 0; a < foundAddress[x].types.length; a++) {
                if (foundAddress[x].types[a] == 'postal_code') {
                  this.dropOffPCode = foundAddress[x].long_name;
                  this.getDropOffSuburb(this.dropOffPCode);
                }
              }
            }
          }

          if (this.dropOffPCode == null) {
            this.promptPostalCode = "Please enter your area postal code below";
            $('#dropOffPostcalCode').modal('show');
          }

        }
      }
    );
  }

  public getDropOffSuburb(postcalCode) {
    this.bookingService.getDropOffSuburb(postcalCode).subscribe(
      response => {
        this.toSuburb = response.suburb;
      }, error => {
        console.log(error);
      }, () => {
      }
    )
  }

  public showParcelScreen() {
    debugger;
    if ((this.pickUpAddress != null && this.pickUpAddress != undefined) && (this.dropOffAddress != null && this.dropOffAddress != undefined)) {

      if (this.sameMassParcels) {
        if (this.numOfParcels == 0) {
          this.msgStatus = 'Number of parcels cannot be 0.';
        }
        else if (this.numOfParcels < 0) {
          this.msgStatus = 'Parcels cannot be a negative number.';
        }
        else if (this.numOfParcels == null) {
          this.msgStatus = 'Number of parcels cannot be empty.';
        }
        else if (this.numOfParcels > 150) {
          this.msgStatus = 'You can only transport up to 150 parcels.';
        }
        else {
          this.msgStatus = '';
          this.showParcels = false;
          this.getMultipleParcels();
          $('#parcelModal').modal('show');
          $('#skyNetModal').modal('hide');
        }
      }
      else {
        if (this.numOfParcels == 0) {
          this.msgStatus = 'Number of parcels cannot be 0.';
        }
        else if (this.numOfParcels > 8) {
          this.redirectToFleetMsg = 'You can only transport up to 8 parcels if dimensions are different.';
          $('#navigateToFleet').modal('show');
        }
        else if (this.numOfParcels < 0) {
          this.msgStatus = 'Parcels cannot be a negative number.';
        }
        else if (this.numOfParcels == null) {
          this.msgStatus = 'Number of parcels cannot be empty.';
        }
        else {
          this.redirectToFleetMsg = '';
          this.msgStatus = '';
          this.showParcels = false;
          this.getMultipleParcels();
          $('#parcelModal').modal('show');
          $('#skyNetModal').modal('hide');
        }
      }

    } else {
      this.errorMessage = 'Please enter all addresses.';
      $('#errorDialog').modal('show');
    }
  }

  public getMultipleParcels() {
    this.priceBox = true;

    // If user did not tick the checkbox
    if (!this.sameMassParcels) {
      this.numberOfPracels = new Array(this.numOfParcels);
      this.pracels = [];

      for (let x = 0; x < this.numberOfPracels.length; x++) {
        let pracel = { parcel_length: '', parcel_breadth: '', parcel_height: '', parcel_mass: '' };
        this.pracels.push(pracel);
      }

      this.onSelect(this.pracels[0]);
    }
  }

  public selectDropOffSavedAddress() {
    $('#showDropOffSavedAddressModal').modal('show');
  }

  onSelect(pracel): void {
    this.selectedPracel = pracel;
    this.pracel = pracel;
  }

  public single_mass_change(single_parcel_mass) {
    this.totalMass = single_parcel_mass * this.numOfParcels;
  }

  public modelChanged(parcel) {

    if (parcel.parcel_mass > 70) {
      this.massMsg = "Mass cannot exceed 70";
    }
    else if (parcel.parcel_mass == 0) {
      this.massMsg = "Mass cannot be 0";
    }
    else if (parcel.parcel_mass < 0) {
      this.massMsg = "Mass cannot be a negative number";
    }
    else if (parcel.parcel_mass == null) {
      this.massMsg = "Mass cannot be empty";
    }
    else if (parcel.parcel_mass < 70) {
      this.massMsg = " ";
    }

    if (parcel.parcel_breadth > 150) {
      this.breadthMsg = "Width for parcel cannot exceed 150";
    }
    else if (parcel.parcel_breadth == 0) {
      this.breadthMsg = "Width cannot be 0";
    }
    else if (parcel.parcel_breadth < 0) {
      this.breadthMsg = "Width cannot be a negative number";
    }
    else if (parcel.parcel_breadth == null) {
      this.breadthMsg = "Width cannot be empty";
    }
    else if (parcel.parcel_breadth < 150) {
      this.breadthMsg = " ";
    }

    if (parcel.parcel_height > 150) {
      this.heightMsg = "Height for parcel cannot exceed 150";
    }
    else if (parcel.parcel_height == 0) {
      this.heightMsg = "Height cannot be 0";
    }
    else if (parcel.parcel_height < 0) {
      this.heightMsg = "Height cannot be a negative number";
    }
    else if (parcel.parcel_height == null) {
      this.heightMsg = "Height cannot be empty";
    }
    else if (parcel.parcel_height < 150) {
      this.heightMsg = " ";
    }

    if (parcel.parcel_length > 150) {
      this.lengthMsg = "Length for parcel cannot exceed 150";
    }
    else if (parcel.parcel_length == 0) {
      this.lengthMsg = "Length cannot be 0";
    }
    else if (parcel.parcel_length < 0) {
      this.lengthMsg = "Length cannot be a negative number";
    }
    else if (parcel.parcel_length == null) {
      this.lengthMsg = "Length cannot be empty";
    }
    else if (parcel.parcel_length < 150) {
      this.lengthMsg = " ";
    }
  }

  public clearDetails(pracel) {
    pracel.parcel_length = 0;
    pracel.parcel_breadth = 0;
    pracel.parcel_height = 0;
    pracel.parcel_mass = 0;
  }

  public envelopeSize(pracel) {
    pracel.parcel_length = 22;
    pracel.parcel_breadth = 11;
    pracel.parcel_height = 0.1;
    pracel.parcel_mass = 0.1;
  }

  public textBookSize(pracel) {
    pracel.parcel_length = 32;
    pracel.parcel_breadth = 24;
    pracel.parcel_height = 1;
    pracel.parcel_mass = 0.2;
  }

  public shoeBoxSize(pracel) {
    pracel.parcel_length = 35;
    pracel.parcel_breadth = 20;
    pracel.parcel_height = 15;
    pracel.parcel_mass = 9;
  }

  public movingBoxSize(pracel) {
    pracel.parcel_length = 32;
    pracel.parcel_breadth = 24;
    pracel.parcel_height = 25;
    pracel.parcel_mass = 12;
  }

  public singleParcelEnvelopeSize() {
    this.single_parcel_length = 22;
    this.single_parcel_breadth = 11;
    this.single_parcel_height = 0.1;
    this.single_parcel_mass = 0.1;
  }

  public singleParcelTextBookSize() {
    this.single_parcel_length = 32;
    this.single_parcel_breadth = 24;
    this.single_parcel_height = 1;
    this.single_parcel_mass = 0.2;
  }

  public singleParcelShoeBoxSize() {
    this.single_parcel_length = 35;
    this.single_parcel_breadth = 20;
    this.single_parcel_height = 15;
    this.single_parcel_mass = 9;
  }

  public singleParcelMovingBoxSize() {
    this.single_parcel_length = 32;
    this.single_parcel_breadth = 24;
    this.single_parcel_height = 25;
    this.single_parcel_mass = 12;
  }

  public checkedTickBox(terms) {
    if (terms) {
      this.termsControl = true;
    } else {
      this.termsControl = false;
    }
  }

  public backToHome() {
    this.pracels = [];
    this.numOfParcels = 0;
    this.numberOfPracels = [];
    this.priceBox = false;
    this.sameMassParcels = false;
    this.single_parcel_length = "";
    this.single_parcel_breadth = "";
    this.single_parcel_height = "";
    this.single_parcel_mass = "";
    this.termsControl = false;
  }

  public checkingParcelOverLimit() {
    for (let a = 0; a < this.pracels.length; a++) {
      if (this.pracels[a].parcel_mass > 70) {
        this.overload = true;
        break;
      }
      else {
        this.overload = false;
      }
    }
  }

  public calculateParcelDimensions() {
    var totalParcelMass = 0;

    this.pracels.forEach(element => {
      totalParcelMass += element.parcel_mass;
    });
    this.totalMass = totalParcelMass;
  }

  public calculateMetricFactor() {
    var metricTotal = 0;
    var volumeMetric = 0;
    this.pracels.forEach(element => {
      metricTotal = (element.parcel_breadth * element.parcel_height * element.parcel_length) / 5000;
      volumeMetric = volumeMetric + metricTotal;
    });
    return volumeMetric;
  }

  public calculateMetricVolume() {
    for (let i = 0; i < this.pracels.length; i++) {
      this.metricTotal = this.metricTotal + Math.floor(this.calculateMetricFactor());
    }
  }

  getBookingDates() {
    this.bookingService.getBookingDate(this.pickUpPCode, this.dropOffPCode).subscribe(
      response => {
        if (response.pickUpDate != null) {
          this.dateObj = response;
        } else {
          var tate = new Date();
          var datePipe = new DatePipe('en-US');
          tate.setDate(tate.getDate());
          var dateObject = {
            "workingDays": [],
            "pickUpDate": datePipe.transform(tate, 'yyyy-MM-dd'),
            "dropOffSuburb": this.toSuburb,
            "pickUpSuburb": this.fromSuburb
          }
          this.dateObj = dateObject;
        }
      }, error => {
        var tate = new Date();
        var datePipe = new DatePipe('en-US');
        tate.setDate(tate.getDate());
        var dateObject = {
          "workingDays": [],
          "pickUpDate": datePipe.transform(tate, 'yyyy-MM-dd'),
          "dropOffSuburb": this.toSuburb,
          "pickUpSuburb": this.fromSuburb
        }
        this.dateObj = dateObject;
        console.log(error);
      }, () => {
      }
    )
  }

  public postMultipleParcels() {
    this.priceBox = true;
  }

  private controlSelection() {
    $(".road").addClass("expressChoices").removeClass("expressChoices_");
    $(".flight").addClass("expressChoices").removeClass("expressChoices_");
    $(".courierExp").addClass("expressChoices").removeClass("expressChoices_");
    $(".budget").addClass("expressChoices").removeClass("expressChoices_");
    $(".dash").addClass("expressChoices").removeClass("expressChoices_");
    $(".tender").addClass("expressChoices").removeClass("expressChoices_");
  }

  private resetImages() {
    this.isBudget = false;
    this.isCourier = false;
    this.isRoad = false;
    this.isFlight = false;
    this.isDash = false;
    this.isTender = false;
  }

  public onExpressCourierSelected() {

    var quoteBody = {
      "mass": this.totalMass,
      "distance": 1,
      "fromSuburb": this.fromSuburb,
      "toSuburb": this.toSuburb,
      "pickUpAddress": this.pickUpAddress,
      "dropOffAddress": this.dropOffAddress,
      "pickUpProvince": this.pickUpProvince,
      "dropOffProvince": this.dropOffProvince,
      "pickUpPCode": this.pickUpPCode,
      "dropOffPCode": this.dropOffPCode,
      "parcelDimensions": this.pracels,
      "platform": "web",
    }
    this.bookingService.getNewSkynetPrices(quoteBody).subscribe(
      response => {
        if (response.json().expressCourier > 0) {
          this.isCourierExpress = false;
          this.expressCourier = Math.round(response.json().expressCourier);
          this.tempAmount = this.expressCourier;
        }
      }, error => {
        this.loadservice.removeloader();
      }, () => { }
    );

    this.controlSelection();
    this.resetImages();
    this.transportMode = 'ROAD';
    this.type = 'EXPRESS_COURIER';
    $(".courierExp").addClass("expressChoices_");
    $(".courierExp").removeClass("expressChoices");
    this.isCourier = true;
    this.tenderDocumentSelected = false;
  }

  public getSkynetPrincings() {
    var quoteBody = {
      "mass": this.totalMass,
      "distance": 1,
      "fromSuburb": this.fromSuburb,
      "toSuburb": this.toSuburb,
      "pickUpAddress": this.pickUpAddress,
      "dropOffAddress": this.dropOffAddress,
      "pickUpProvince": this.pickUpProvince,
      "dropOffProvince": this.dropOffProvince,
      "pickUpPCode": this.pickUpPCode,
      "dropOffPCode": this.dropOffPCode,
      "parcelDimensions": this.pracels,
      "platform": "web",
    }
    //console.log(quoteBody);
    this.bookingService.getNewSkynetPrices(quoteBody).subscribe(
      response => {
        if (response.json().expressCourier > 0) {
          this.isCourierExpress = false;
          this.expressCourier = Math.round(response.json().expressCourier);
        }
        if (response.json().expressBudgetAmt > 0) {
          this.isExpressBudget = false;
          this.budgetCourier = Math.round(response.json().expressBudgetAmt);
        }
        if (response.json().tenderDocAmount > 0) {
          this.tenderDocument = Math.round(response.json().tenderDocAmount);
        }
      }, error => {
        this.loadservice.removeloader();
        console.log(error);

      }, () => { }
    );
  }

  private coordinateDistance(lat1, lon1, lat2, lon2, unit): number {
    var radlat1 = Math.PI * lat1 / 180
    var radlat2 = Math.PI * lat2 / 180
    var theta = lon1 - lon2
    var radtheta = Math.PI * theta / 180
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist)
    dist = dist * 180 / Math.PI
    dist = dist * 60 * 1.1515
    if (unit == "K") { dist = dist * 1.609344 }
    if (unit == "N") { dist = dist * 0.8684 }
    return dist;
  }

  async getMainCity(){

    await this.bookingService.getPOI().subscribe((res)=>{
      if (res){
        this.droppaMainCities = res;
        for (let i = 0; i < this.droppaMainCities.length; i++) {
          this.distanceRadius = this.coordinateDistance(this.pickUpLat, this.pickUpLong, Number.parseFloat(this.droppaMainCities[i].location.coordinates[0]), Number.parseFloat(this.droppaMainCities[i].location.coordinates[1]), 'K');
          var dist = Math.round(this.distanceRadius);
          if (dist <= 70) {
            console.log(dist);
            this.maincityDistanceDash = dist;
            break;
          }
        }
      }
    })
    // return distanceFound
  }

  public onDashSelected() {
    this.controlSelection();
    this.resetImages();
    this.transportMode = 'ROAD';
    this.type = 'DASH';
    this.tempAmount = this.sheshaMode;
    $(".dash").addClass("expressChoices_");
    $(".dash").removeClass("expressChoices");
    this.isDash = true;
    this.tenderDocumentSelected = false;
  }

  public onFlashRoadhSelected() {
    this.controlSelection();
    this.resetImages();
    this.transportMode = 'ROAD';
    this.type = 'FLASH';
    this.tempAmount = this.flashRoad;
    $(".road").addClass("expressChoices_");
    $(".road").removeClass("expressChoices");
    this.isRoad = true;
    this.tenderDocumentSelected = false;
  }

  public onFlashFlightSelected() {
    this.controlSelection();
    this.resetImages();
    this.transportMode = 'AIR';
    this.type = 'FLASH';
    this.tempAmount = this.flashFlight;
    this.airlineAmt = this.airlineAmt;
    this.pickUpAmt = this.pickUpAmt;
    this.dropOffAmt = this.dropOffAmt;
    $(".flight").addClass("expressChoices_");
    $(".flight").removeClass("expressChoices");
    this.isFlight = true;
    this.tenderDocumentSelected = false;
  }


  async getExpressPricings() {

    if ((this.validateProvince === 'KwaZulu-Natal' || this.validateProvince === 'kzn') ||
      this.validateProvince === 'Western Cape' || this.validateProvince === 'Gauteng' || this.validateProvince === "Eastern Cape") {
      var quoteBody = {
        "mass": this.totalMass,
        "distance": this.distance,
        "fromSuburb": this.fromSuburb,
        "toSuburb": this.toSuburb,
        "pickUpAddress": this.pickUpAddress,
        "dropOffAddress": this.dropOffAddress,
        "pickUpProvince": this.pickUpProvince,
        "dropOffProvince": this.dropOffProvince,
        "pickUpPCode": this.pickUpPCode,
        "dropOffPCode": this.dropOffPCode,
        "parcelDimensions": this.pracels,
        "platform": "web",
      }

      await this.getMainCity();

      localStorage.setItem('quoteBody', JSON.stringify(quoteBody));
      this.bookingService.getQuote(quoteBody).subscribe(
        response => {

          this.flashFlight = Math.round(response.json().totalFlight);
          this.airlineAmt = response.json().totalFlight;
          this.pickUpAmt = response.json().flashFlightFromPickUp;
          this.dropOffAmt = response.json().flashFlightToDropff;
          this.flashRoad = Math.round(response.json().flashRoad);
          this.sheshaMode = Math.round(response.json().sheshaAmount);


          if (this.validateProvince === 'Western Cape' || (this.validateProvince === 'KwaZulu-Natal' || this.validateProvince === 'kzn' || this.validateProvince === "Eastern Cape")) {
            if(quoteBody.pickUpProvince == this.validateProvince && quoteBody.dropOffProvince == this.validateProvince){
              console.log("quote body....", quoteBody);

              console.log("dash distnce is....", this.maincityDistanceDash);
              console.log("dash distnce is....", this.maincityDistanceDash);
              if (this.maincityDistanceDash > 50){
                this.sheshaMode = 0;
                console.log("dash distnce is....", this.maincityDistanceDash);
              }
            }
          }

          if (this.flashFlight == 0) {
            this.flightAvailable = false;
            this.flashRoadAvailable = true;
          } else {
            var d = new Date();
            var hours = d.getHours();
            if (hours < 12) {
              this.flightAvailable = true;
              this.flashRoadAvailable = true;
            } else {
              this.flashFlight = 0;
              this.flightAvailable = false;
              this.flashRoadAvailable = true;
            }
          }

          if (this.distance > 30) {
            this.sheshaMode = 150;
          }

          if (this.distance > 75) {
            this.onFlashRoadhSelected();
            this.sheshaMode = 0;
          }
          else {
            this.onDashSelected();
          }

          if (this.totalMass > 15) {
            this.sheshaAvailable;
            this.onFlashRoadhSelected();
            this.sheshaMode = 0;
          }
          else {
            this.onDashSelected();
            this.sheshaAvailable = false;
          }

          if (this.flightAvailable) {
            this.onFlashFlightSelected();
          } else {
            this.onFlashRoadhSelected();
          }

          this.loadservice.removeloader();
        }, error => {
          this.loadservice.removeloader();
          console.log(error);
        }
      );
    }
    else {
      this.onExpressCourierSelected();
    }
  }

  public getCourierQuote() {

    this.loadservice.addloader();
    let self = this;
    this.priceBox = false;
    this.showParcels = true;

    var data = {
      "destination": this.destinationCoordinate,
      "departure": this.departureCoordinate
    };

    this.checkingParcelOverLimit();
    this.calculateParcelDimensions();
    this.calculateMetricVolume();

    if (this.overload) {
      this.checkingMsg = 'Please check that none of your individual parcel exceeds 70KGs.';
      $('#validationModal').modal('show');
      this.priceBox = true;
      this.showParcels = false;
      this.loadservice.removeloader()
    }
    else {
      this.getBookingDates();
      this.postMultipleParcels();
      if (this.usedSavedAddress == true) {
        this.priceBox = true;
        self.priceBox = true;
        this.onExpressCourierSelected();
        this.getSkynetPrincings();
      }
      else {
        this.bookingService.getDistance(data).subscribe(
          (response) => {
            var distanceResponse = response.json();
            this.distance = distanceResponse.rows[0].elements[0].distance.inMeters;
            this.distance = Math.round(this.distance / 1000);
            this.bookingService.getVehicleRate(this.vehicleType)
              .subscribe(
                (res: Response) => {
                  this.priceBox = true;
                  self.priceBox = true;
                  this.getExpressPricings();
                  this.getSkynetPrincings();
                  this.loadservice.removeloader();
                  window.scrollTo(0, 0);
                },
                (err) => {
                  this.errorMessage = 'Error occured, please contact Droppa.';
                  $('#errorDialog').modal('show');
                  this.loadservice.removeloader();
                }
              );
          });
      }
    }

  }

  public getQuote() {
    if (!this.sameMassParcels) {
      if (!this.termsControl) {
        $('#termsAndConditionsModal').modal('show');
        this.termsMsg = "Please read the text beneath the boxes & click on the checkbox to proceed.";
      }
      else if (this.checkDimensionValidation()) {
        this.getCourierQuote();
      }
    }
    else {
      if (this.single_parcel_mass == null) {
        alert('mass is missing');
      }
      else if (this.single_parcel_breadth == null) {
        alert('breadth is missing');
      }
      else if (this.single_parcel_height == null) {
        alert('height is missing');
      }
      else if (this.single_parcel_length == null) {
        alert('length is missing');
      }
      else if (!this.termsControl) {
        $('#termsAndConditionsModal').modal('show');
        this.termsMsg = "Please read the text beneath the boxes & click on the checkbox to proceed.";
      }
      else {
        this.numberOfPracels = new Array(this.numOfParcels);
        for (let x = 0; x < this.numberOfPracels.length; x++) {
          let pracel = {
            parcel_length: this.single_parcel_length,
            parcel_breadth: this.single_parcel_breadth,
            parcel_height: this.single_parcel_height,
            parcel_mass: this.single_parcel_mass
          };
          this.pracels.push(pracel);
        }
        this.getCourierQuote();
      }
    }
  }

  public checkDimensionValidation(): Boolean {

    let check = false;

    for (let a = 0; a < this.pracels.length; a++) {
      if (this.pracels[a].parcel_breadth == null || this.pracels[a].parcel_height == null || this.pracels[a].parcel_length == null || this.pracels[a].parcel_mass == null) {
        this.checkingMsg = 'Please make sure that none of the parcels details are empty.';
        $('#validationModal').modal('show');
        check = false;
        break;
      }
      else if (this.pracels[a].parcel_breadth == 0 || this.pracels[a].parcel_height == 0 || this.pracels[a].parcel_length == 0 || this.pracels[a].parcel_mass == 0) {
        this.checkingMsg = 'Please make sure that the entered numbers are not 0.';
        $('#validationModal').modal('show');
        check = false;
        break;
      }
      else if (this.pracels[a].parcel_breadth > 150 || this.pracels[a].parcel_height > 150 || this.pracels[a].parcel_length > 150 || this.pracels[a].parcel_mass > 150) {
        this.checkingMsg = 'Parcel details entered cannot exceed 150cm.';
        $('#validationModal').modal('show');
        check = false;
        break;
      }
      else if (this.pracels[a].parcel_breadth < 0 || this.pracels[a].parcel_height < 0 || this.pracels[a].parcel_length < 0 || this.pracels[a].parcel_mass < 0) {
        this.checkingMsg = 'Parcel details entered cannot be a negative number.';
        $('#validationModal').modal('show');
        check = false;
        break;
      }
      else {
        check = true;
      }
    }

    return check;

  }

  public getPickUpExtraDetails(postalCode) {
    this.pickUpPCode = postalCode;
    if (this.pickUpPCode != null || this.pickUpPCode != undefined) {
      this.bookingService.postalForHomeDetails(this.pickUpPCode).subscribe(
        response => {
          this.pickUpExtraDetails = response;
          if (this.pickUpExtraDetails.length == 0) {
            this.enteredPickUpPostalCodeMsg = 'Invalid postal code, enter a valid postal code to select a suburb.';
          }
          else {
            this.enteredPickUpPostalCodeMsg = '';
          }
        }, error => {
          console.log(error);
        }
      );
    }
    else {
      this.pickUpExtraDetails = [];
    }
  }

  public validatePostalCode(promptedPickUpPCode) {
    this.pickUpPCode = promptedPickUpPCode;
    if (this.pickUpPCode != null || this.pickUpPCode != undefined) {
      this.bookingService.postalForHomeDetails(this.pickUpPCode).subscribe(
        response => {
          this.pickUpExtraDetails = response;
          if (this.pickUpExtraDetails.length == 0) {
            this.enteredPickUpPostalCodeMsg = 'Invalid postal code, enter a valid postal code to select a suburb.';
          }
          else {
            this.enteredPickUpPostalCodeMsg = '';
          }
        }, error => {
          console.log(error);
        }
      );
    }
    else {
      this.pickUpExtraDetails = [];
    }
  }

  closePickUpAddressModal() {
    return $('#addressPopUp').modal('hide');
  }

  public enteredAddressInfo() {

    if (this.enteredPickUpAddress == null || this.enteredPickUpAddress == '') {
      this.enteredPickUpAddressMsg = 'Please enter the address';
    }
    else if (this.enteredPickUpPostalCode == null || this.enteredPickUpPostalCode == '') {
      this.enteredPickUpPostalCodeMsg = 'Please enter the postal code.';
    }
    else if (this.enteredPickUpSuburb == null || this.enteredPickUpSuburb == '') {
      this.enteredPickUpSuburbMsg = 'Please enter the suburb';
    }
    else if (this.enteredPickUpProvince == null || this.enteredPickUpProvince == '') {
      this.enteredPickUpProvinceMsg = 'Please enter the province.';
    }
    else {

      this.fromSuburb = this.enteredPickUpSuburb;
      this.pickUpPCode = this.enteredPickUpPostalCode;
      this.pickUpProvince = this.enteredPickUpProvince;
      this.province = this.enteredPickUpProvince;
      this.pickUpCoordinate = ['-25.98953', '28.12843'];
      this.enteredPickUpSuccessMsg = 'Address successfully captured.';
      this.disablePickUpAddresses = false;
      this.displayPickUpAddress = this.enteredPickUpAddress.concat(', ' + this.fromSuburb).concat(', ' + this.pickUpPCode).concat(', ' + this.province.charAt(0).toUpperCase() + this.province.slice(1));
      this.pickUpAddress = this.enteredPickUpAddress.concat(', ' + this.fromSuburb).concat(', ' + this.pickUpPCode).concat(', ' + this.province.charAt(0).toUpperCase() + this.province.slice(1));
      this.pickUpCoordinateLocation = { coordinates: this.pickUpCoordinate, address: this.pickUpAddress };
      this.usedSavedAddress = true;
      setTimeout(this.closePickUpAddressModal(),5000)
    }

  }



  public getDropOffExtraDetails(quote) {
    this.dropOffPCode = quote;
    if (this.dropOffPCode != null || this.dropOffPCode != undefined) {
      this.bookingService.postalForHomeDetails(this.dropOffPCode).subscribe(
        response => {
          this.dropOffExtraDetails = response;
          if (this.dropOffExtraDetails.length == 0) {
            this.enteredDropOffPostalCodeMsg = 'Invalid postal code, enter a valid postal code to select a suburb.';
          }
          else {
            this.enteredDropOffPostalCodeMsg = '';
          }
        }, error => {
          console.log(error);
        }
      );
    }
    else {
      this.dropOffExtraDetails = [];
    }
  }

  public validateDropOffPostalCode(quote) {
    this.dropOffPCode = quote;
    if (this.dropOffPCode != null || this.dropOffPCode != undefined) {
      this.bookingService.postalForHomeDetails(this.dropOffPCode).subscribe(
        response => {
          this.dropOffExtraDetails = response;
          if (this.dropOffExtraDetails.length == 0) {
            this.enteredDropOffPostalCodeMsg = 'Invalid postal code, enter a valid postal code to select a suburb.';
          }
          else {
            this.enteredDropOffPostalCodeMsg = '';
          }
        }, error => {
          console.log(error);
        }
      );
    }
    else {
      this.dropOffExtraDetails = [];
    }
  }

  closeDropOffModal() {
    return $('#addressPopUp2').modal('hide');
  }

  public enteredAddressInfo2() {

    if (this.enteredDropOffAddress == null || this.enteredDropOffAddress == '') {
      this.enteredDropOffAddressMsg = 'Please enter the address';
    }
    else if (this.enteredDropOffPostalCode == null || this.enteredDropOffPostalCode == '') {
      this.enteredDropOffPostalCodeMsg = 'Please enter the postal code';
    }
    else if (this.enteredDropOffSuburb == null || this.enteredDropOffSuburb == '') {
      this.enteredDropOffSuburbMsg = 'Please enter the suburb';
    }
    else if (this.enteredDropOffProvince == null || this.enteredDropOffProvince == '') {
      this.enteredDropOffProvinceMsg = 'Please enter the province';
    }
    else {
      this.toSuburb = this.enteredDropOffSuburb;
      this.dropOffPCode = this.enteredDropOffPostalCode;
      this.dropOffProvince = this.enteredDropOffProvince;
      this.dropOffCoordinate = ['-25.97521', '28.13954'];
      this.enteredDropOffSuccessMsg = 'Address successfully captured.'
      this.disableDropOffAddress = false;
      this.displayDropOffAddress = this.enteredDropOffAddress.concat(', ' + this.toSuburb).concat(', ' + this.dropOffPCode).concat(', ' + this.dropOffProvince.charAt(0).toUpperCase() + this.dropOffProvince.slice(1));
      this.dropOffAddress = this.enteredDropOffAddress.concat(', ' + this.toSuburb).concat(', ' + this.dropOffPCode).concat(', ' + this.enteredDropOffProvince.charAt(0).toUpperCase() + this.enteredDropOffProvince.slice(1));
      this.dropOffCoordinateLocation = { coordinates: this.dropOffCoordinate, address: this.dropOffAddress };
      this.usedSavedAddress = true;
      setTimeout(this.closeDropOffModal(),5000)
    }

  }

  public onTenderDocumentSelected() {
    this.controlSelection();
    this.resetImages();
    this.transportMode = 'ROAD';
    this.type = 'EXPRESS_COURIER';
    this.tempAmount = this.tenderDocument;
    $(".tender").addClass("expressChoices_");
    $(".tender").removeClass("expressChoices");
    this.isTender = true;
    this.tenderDocumentSelected = true;
  }

  goToEntry() {
    this.metricTotal = 0;

    this.enteredPickUpAddress = null;
    this.enteredPickUpSuburb = null;
    this.enteredPickUpPostalCode = null;
    this.enteredPickUpProvince = null;
    this.enteredPickUpProvince = null;

    this.enteredDropOffAddress = null;
    this.enteredDropOffSuburb = null;
    this.enteredDropOffPostalCode = null;
    this.enteredDropOffProvince = null;
    this.enteredDropOffProvince = null;

    this.priceBox = false;
    this.expressCourier = 0;
    this.sheshaMode = 0;
    this.flashFlight = 0;
    this.flashRoad = 0;
    this.budgetCourier = 0;

    this.msgStatus = '';
    this.disableDropOffAddress = true;
    this.disablePickUpAddresses = true;

    this.isCourierExpress = true;

    this.termsControl = false;
    this.sameMassParcels = false;
    this.single_parcel_length = "";
    this.single_parcel_breadth = "";
    this.single_parcel_height = "";
    this.single_parcel_mass = "";

    this.pracels = [];
    this.numOfParcels = 0;
    this.numberOfPracels = [];
  }

  public onBudgetCourierSelected() {
    this.controlSelection();
    this.resetImages();
    this.transportMode = 'ROAD';
    this.type = 'BUDGET_COURIER';
    this.tempAmount = this.budgetCourier;
    $(".budget").addClass("expressChoices_");
    $(".budget").removeClass("expressChoices");
    this.isBudget = true;
    this.tenderDocumentSelected = false;
  }

  public confirmBookingCourier() {
    var selectedVT;

    if (this.type == "DASH") {
      selectedVT = this.dashVehiceType;
    } else {
      selectedVT = this.selectedVehicleT;
    }

    let obect = {
      pickUpAddress: this.pickUpCoordinateLocation,
      dropOffAddress: this.dropOffCoordinateLocation,
      price: this.tempAmount,
      vehicleType: selectedVT,
      province: this.province.toUpperCase(),
      "mass": this.totalMass,
      "dropOffProvince": this.dropOffProvince,
      "transportMode": this.transportMode,
      "type": this.type,
      "distance": this.distance != null ? this.distance : 1,
      "fromSuburb": this.fromSuburb,
      "toSuburb": this.toSuburb,
      "pickUpProvince": this.pickUpProvince,
      "pickUpPCode": this.pickUpPCode,
      "dropOffPCode": this.dropOffPCode,
      "pickUpAmt": this.pickUpAmt,
      "airlineAmt": this.airlineAmt,
      "dropOffAmt": this.dropOffAmt,
      "parcelDetails": this.pracels,
      "tenderSelected": this.tenderDocumentSelected
    };

    var d = new Date();
    var hours = d.getHours();

    if (this.type == "EXPRESS_COURIER" || this.type == "BUDGET_COURIER") {
      if ((localStorage.getItem('userInfoBookingLogin') != null && localStorage.getItem('userInfoBooking') != null)) {
        localStorage.setItem('addressData', JSON.stringify(obect));
        localStorage.setItem('newDateObj', JSON.stringify(this.dateObj));
        this.loginExpress(JSON.parse(localStorage.getItem('userInfoBookingLogin')));
      }
      else {
        localStorage.setItem('newDateObj', JSON.stringify(this.dateObj));
        localStorage.setItem('addressData', JSON.stringify(obect));
        this.router.navigate(['/Booking-SignUp']);
      }
    }
    else {
      if (hours >= 7 && hours <= 17) {
        if ((localStorage.getItem('userInfoBookingLogin') != null && localStorage.getItem('userInfoBooking') != null)) {
          localStorage.setItem('addressData', JSON.stringify(obect));
          this.loginExpress(JSON.parse(localStorage.getItem('userInfoBookingLogin')));
        }
        else {
          localStorage.setItem('addressData', JSON.stringify(obect));
          this.router.navigate(['/Booking-SignUp']);
        }
      }
      else {
        alert("Service only available from 06:00 AM to 18:00 PM. Please select Express or Budget Courier services.");
        this.router.navigateByUrl('/Courier-Service');
      }
    }

  }

  private loginExpress(object: any) {
    this.bookingService.login(object.pwd, object.username).subscribe(
      (response: any) => {
        localStorage.setItem('userData', JSON.stringify(response));
        localStorage.setItem('token', response.token);
        localStorage.setItem('userInfo', JSON.stringify(response));
        localStorage.removeItem('userInfoBookingLogin');
        localStorage.removeItem('userInfoBooking');
        this.router.navigate(['/droppa-express']);
        window.scrollTo(0, 0);
      }
    );
  }

  public retrieveTransaction() {
    this.bookingService.getTransactions(this.user.token, this.walletOID).subscribe(
      response => {
        // console.log(response);
        this.history = response.reverse();
      }, error => {
        console.log(error);
      }
    );
  }

  public makePayment(unpaidBooking) {
    let bookingToPay;
    localStorage.setItem('token', this.user.token);

    // Mark that this booking comes from a table (ie. it already exists)
    localStorage.setItem('paymentFromTable', 'true');

    // Courier booking
    if (unpaidBooking.vehicleType === 'DROPPA_EXPRESS' || unpaidBooking.vehicleType === 'DASH') {
      bookingToPay = {
        oid : unpaidBooking.dropOid,
        name: 'Booking',
        notes: 'be careful',
        cost: (unpaidBooking.price * 100),
        clientOid: unpaidBooking.customer.oid // "6009191828743460bde4d4a8"
        ,
        vehicleOid: '1521105441081',
        driverOid: unpaidBooking.driverOid // "575ae6bd30bc5d5017477143"
        ,
        booking: {
          dropOff: unpaidBooking.dropOff
          /*{
            "firstName": "Jack",
            "lastName": "Manamela",
            "phone": "0737648788",
            "email": "jackm@droppa.co.za",
            "companyName": "Jack",
            "complex": null,
            "unitNo": null
          }*/
          ,
          pickUp: unpaidBooking.pickUp
          /*{
          "firstName": "Jack",
          "lastName": "Manamela",
          "phone": "0737648788",
          "email": "jackm@droppa.co.za",
          "companyName": "Jack",
          "complex": null,
          "unitNo": null
        }*/
          ,
          customer: unpaidBooking.customer
          /* {
           "oid": "6009191828743460bde4d4a8",
           "businessKey": "{\"deviceId\":\"1611208984571\",\"mobile\":\"0737648788\"}",
           "deviceId": "1611208984571",
           "mobile": "0737648788",
           "mobileVerified": false,
           "firstName": "Jack",
           "surname": "Manamela",
           "rsaId": "0737648788123",
           "email": "jackm@droppa.co.za",
           "emailVerified": false,
           "documentOid": null,
           "creationStamp": "2021-01-21T08:03:04.138",
           "retailId": "6009191828743460bde4d4a8",
           "userAccountOid": "6009191828743460bde4d4a9"
         }*/
          ,
          price: unpaidBooking.price,
          vehicleType: unpaidBooking.vehicleType,
          pickUpAddress: unpaidBooking.pickUpAddress,
          pickUpTime: unpaidBooking.pickUpTime,
          phoneNo: unpaidBooking.phoneNo,
          pickUpDate: unpaidBooking.pickUpDate,
          dropOffAddress: unpaidBooking.dropOffAddress,
          comment: unpaidBooking.comment,
          labour: unpaidBooking.labour,
          timestamp: unpaidBooking.timestamp,
          status: unpaidBooking.status,
          pickUpCoordinate: {
            coordinates: unpaidBooking.pickUpCoordinate.coordinates,
            address: unpaidBooking.pickUpCoordinate.address
          },
          dropOffCoordinate: {
            coordinates: unpaidBooking.dropOffCoordinate.coordinates,
            address: unpaidBooking.dropOffCoordinate.address
          },
          load: unpaidBooking.load,
          province: unpaidBooking.province,
          canopy: unpaidBooking.canopy,
          platform: unpaidBooking.platform,
          isExpress: unpaidBooking.isExpress,
          distance: unpaidBooking.distance,
          itemMass: unpaidBooking.itemMass,
          transportMode: unpaidBooking.transportMode,
          isItemPicked: unpaidBooking.isItemPicked,
          mainCityOid: unpaidBooking.mainCityOid,
          fromSuburb: unpaidBooking.fromSuburb,
          toSuburb: unpaidBooking.toSuburb,
          pickUpPCode: unpaidBooking.pickUpPCode,
          dropOffPCode: unpaidBooking.dropOffPCode,
          type: unpaidBooking.type,
          pickUpAmt: unpaidBooking.pickUpAmt,
          airlineAmt: unpaidBooking.airlineAmt,
          dropOffAmt: unpaidBooking.dropOffAmt,
          destinationProvince: unpaidBooking.destinationProvince,
          isPayed: unpaidBooking.isPayed,
          paymentType: unpaidBooking.paymentType,
          parcelDimensions: unpaidBooking.parcelDimensions,
          oid: unpaidBooking.oid
        },

        from: {
          coordinates: unpaidBooking.pickUpCoordinate.coordinates
          /*[
          "-26.002483",
          "28.099503"
          ]*/
          ,
          address: unpaidBooking.pickUpCoordinate.address
        },
        to: {
          coordinates: unpaidBooking.dropOffCoordinate.coordinates
          /*[
          "-26.002483",
          "28.099503"
        ]*/
          ,
          address: unpaidBooking.dropOffCoordinate.address
        },
        // Delivery date
        when: unpaidBooking.bookingCreatedDate,
        atp: unpaidBooking.bookingCreatedDate,
        eta: unpaidBooking.bookingCreatedDate,
        ata: unpaidBooking.bookingCreatedDate,
        platform: unpaidBooking.platform,
        droplets: [
          {
            itemOid: '575ae6bd30bc5d5017477143',
            notes: 'new'
          }
        ]
      };

      localStorage.setItem('bookingObject', JSON.stringify(bookingToPay));
      this.router.navigateByUrl('/courier-payment');
    }
    // Fleet booking
    else {
      bookingToPay = {
        oid : unpaidBooking.dropOid,
        name: 'Booking',
        notes: 'be careful',
        cost: (unpaidBooking.price * 100),
        clientOid:  unpaidBooking.customer.oid,
        vehicleOid: '1521105441081',
        driverOid: '575ae6bd30bc5d5017477143',
        booking: {
          dropOff: unpaidBooking.dropOff
          /*{
            "firstName": "Jack",
            "lastName": "Manamela",
            "phone": "0737648788",
            "email": "jackm@droppa.co.za",
            "companyName": "Jack",
            "complex": null,
            "unitNo": null
          }*/
          ,
          pickUp: unpaidBooking.pickUp
          /*{
          "firstName": "Jack",
          "lastName": "Manamela",
          "phone": "0737648788",
          "email": "jackm@droppa.co.za",
          "companyName": "Jack",
          "complex": null,
          "unitNo": null
        }*/
          ,
          customer: unpaidBooking.customer
          /* {
           "oid": "6009191828743460bde4d4a8",
           "businessKey": "{\"deviceId\":\"1611208984571\",\"mobile\":\"0737648788\"}",
           "deviceId": "1611208984571",
           "mobile": "0737648788",
           "mobileVerified": false,
           "firstName": "Jack",
           "surname": "Manamela",
           "rsaId": "0737648788123",
           "email": "jackm@droppa.co.za",
           "emailVerified": false,
           "documentOid": null,
           "creationStamp": "2021-01-21T08:03:04.138",
           "retailId": "6009191828743460bde4d4a8",
           "userAccountOid": "6009191828743460bde4d4a9"
         }*/
          ,
          price: unpaidBooking.price,
          vehicleType: unpaidBooking.vehicleType,
          pickUpAddress: unpaidBooking.pickUpAddress,
          pickUpTime: unpaidBooking.pickUpTime,
          phoneNo: unpaidBooking.phoneNo,
          pickUpDate: unpaidBooking.pickUpDate,
          dropOffAddress: unpaidBooking.dropOffAddress,
          comment: unpaidBooking.comment,
          labour: unpaidBooking.labour,
          timestamp: unpaidBooking.timestamp,
          status: unpaidBooking.status,
          pickUpCoordinate: {
            coordinates: unpaidBooking.pickUpCoordinate.coordinates,
            address: unpaidBooking.pickUpCoordinate.address
          },
          dropOffCoordinate: {
            coordinates: unpaidBooking.dropOffCoordinate.coordinates,
            address: unpaidBooking.dropOffCoordinate.address
          },
          load: unpaidBooking.load,
          province: unpaidBooking.province,
          canopy: unpaidBooking.canopy,
          platform: unpaidBooking.platform,
          isExpress: unpaidBooking.isExpress,
          transportMode: unpaidBooking.transportMode,
          isItemPicked: unpaidBooking.isItemPicked,
          mainCityOid: unpaidBooking.mainCityOid,
          isPayed: unpaidBooking.isPayed,
          type: unpaidBooking.type,
          destinationProvince: unpaidBooking.destinationProvince,
          paymentType: unpaidBooking.paymentType,
          oid: unpaidBooking.oid
        },
        from: {
          coordinates: unpaidBooking.pickUpCoordinate.coordinates,
          address: unpaidBooking.pickUpCoordinate.address
        },
        to: {
          coordinates: unpaidBooking.dropOffCoordinate.coordinates,
          address: unpaidBooking.dropOffCoordinate.address
        },
        when: unpaidBooking.bookingCreatedDate,
        atp: unpaidBooking.bookingCreatedDate,
        eta: unpaidBooking.bookingCreatedDate,
        ata: unpaidBooking.bookingCreatedDate,
        platform: unpaidBooking.platform,
        droplets: [
          {
            itemOid: '575ae6bd30bc5d5017477143',
            notes: 'new'
          }
        ]
      };

      localStorage.setItem('bookingObject', JSON.stringify(bookingToPay));
      this.router.navigateByUrl('/fleet-payment');
    }
  }

  initialLoadWalletView() {
    this.atSelectAmount = true;
    this.amountChosen = 0;
  }

  showLoadWalletViewOptions() {
    if (this.amountChosen > 0) {
      this.atSelectAmount = false;
      this.showSelectWalletAmountError = false;
    } else {
      this.showSelectWalletAmountError = true;
    }
  }
}
